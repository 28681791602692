
<div 
  *ngIf="shouldRenderBorderless"
  class="borderless-header">
  <div 
    class="title-line"
    [ngClass]="[hideBorderClass, smallSizeClass]"
  >
    <h4
      *ngIf="!isSmallSize"
      class="main-title"
      style="font-weight: 700;"
    > Mensagens para o usuário > Eventos
    </h4>  
    <div 
    *ngIf="isSmallSize"
    class="main-title"
    [ngClass]="[hideBorderClass, smallSizeClass]">
      Clique aqui para adicionar
    </div>      
    <mat-menu #availableEventsMenu="matMenu">
      <button
        *ngFor="let eventType of availableEvents"
        mat-menu-item
        (click)="openEventModal(initBotEvent(eventType))"
        [attr.title]="translations[eventType] ? translations[eventType].value : eventType">
        {{ translations[eventType] ? translations[eventType].value : eventType }}
      </button>
    </mat-menu>
    <button
      class="borderless-add-event-button"
      [ngClass]="[hideBorderClass, smallSizeClass]"
      mat-icon-button
      color="primary"
      [matTooltip]="translations.add"
      [matMenuTriggerFor]="availableEventsMenu"
      [disabled]="!availableEvents.length"
      >
        <mat-icon
          class="add-event-icon"
          [ngClass]="[hideBorderClass, smallSizeClass]"
        >
          add
        </mat-icon>
    </button>
  </div>
  <hr
    *ngIf="!isSmallSize"
    class="custom-hr"
    style="font-weight: 700;">
</div>

<div 
  class="ordenable-list"
  [ngClass]="[hideBorderClass, smallSizeClass]">

  <div 
    class="ordenable-item ordenable-header"
    *ngIf="!shouldRenderBorderless">
    <div class="ordenable-item-content">
      <div class="row-title">{{ translations.events }}</div>
    </div>
    <div class="ordenable-item-actions">
      <button
        mat-mini-fab
        color="primary"
        [matTooltip]="translations.add"
        [matMenuTriggerFor]="availableEventsMenu"
        [disabled]="!availableEvents.length"
      >
          <mat-icon>
            add
          </mat-icon>
      </button>
      <mat-menu #availableEventsMenu="matMenu">
        <button
          *ngFor="let eventType of availableEvents"
          mat-menu-item
          (click)="openEventModal(initBotEvent(eventType))"
          [attr.title]="translations[eventType] ? translations[eventType].value : eventType">
          {{ translations[eventType] ? translations[eventType].value : eventType }}
        </button>
      </mat-menu>
    </div>
  </div>

  <ng-container
    *ngIf="!shouldRenderBorderless">
    <div *ngFor="let event of botEvents" class="ordenable-item">
      <div class="ordenable-item-content">
        <div class="row-name">{{ getEventName(event) }}</div>
      </div>
      <div class="ordenable-item-actions">
        <button 
          [ngClass]="{'small-touch-area': isSmallSize}"
          mat-icon-button color="primary" 
          (click)="openEventModal(event, true)">
          <mat-icon>edit</mat-icon>
        </button>
  
        <button 
          [ngClass]="{'small-touch-area': isSmallSize}"
          mat-icon-button color="primary" 
          (click)="removeEvent(event)">
          <mat-icon>delete</mat-icon>
        </button>
  
      </div>
    </div>
  </ng-container>

  <ng-container
    *ngIf="shouldRenderBorderless">    
    <div *ngFor="let event of botEvents" 
      class="ordenable-item"
      [ngClass]="[hideBorderClass, smallSizeClass]">
        <div class="row-line-wrapper">
          <div 
            class="ordenable-item-content"
            [ngClass]="[hideBorderClass, smallSizeClass]"
          >
            <div class="row-name">{{ getEventName(event) }}</div>
          </div>
          <div 
            class="ordenable-item-actions"
            [ngClass]="[hideBorderClass, smallSizeClass]">
            <button 
              mat-icon-button color="primary" 
              matTooltip="Editar"
              (click)="openEventModal(event, true)"
              class="action-button"
              [ngClass]="[hideBorderClass, smallSizeClass, smallTouchAreaClass]">
              <mat-icon class="edit-icon" [ngClass]="smallSizeClass">edit</mat-icon>
            </button>
      
            <button 
              mat-icon-button color="primary" 
              matTooltip="Remover"
              (click)="removeEvent(event)"
              class="action-button remove-event-button"
              [ngClass]="[hideBorderClass, smallSizeClass, smallTouchAreaClass]">
              <mat-icon class="delete-icon" [ngClass]="smallSizeClass">delete</mat-icon>
            </button>
      
          </div>           
        </div>

        <div class="visual-feedback-icons-wrapper">
          <div 
            class="row-contents-container nice-scroll">
              <ng-container
                *ngFor="let content of getContentsArray(event)">
                <div 
                  class="row-content-wrapper"
                  [ngClass]="smallSizeClass"
                >
                  <app-bbcode-viewer 
                    *ngIf="content?.content" 
                    [text]="content.content"
                    [smallSizeClass]="smallSizeClass">
                  </app-bbcode-viewer>
                  <div 
                    class="fb-icon inside-message-compare-icon"
                    *ngIf="doesAContentHaveCondition(content)">
                    <mat-icon
                      style="font-size: 15px;">visibility
                    </mat-icon>              
                  </div>  
                </div>
            </ng-container>            
          </div>
        </div>

        <ng-container *ngIf="!isSmallSize">
          <div
            class="vs-fb-icons">
              <div class="icons-wrapper">
                <div class="fb-icon">
                  <mat-icon
                    style="font-size: 18px;"
                    [matTooltip]="getEventUfnName(event)"
                    [ngClass]="{ 'disabled-icon': !doesEventHaveUfn(event) }">code
                  </mat-icon>              
                </div>
                <div class="fb-icon">
                  <mat-icon
                    [ngClass]="{ 'disabled-icon': !doesEventHaveExibitionCondition(event) }"
                    style="font-size: 18px;">visibility
                  </mat-icon>              
                </div>
              </div>
            </div>
        </ng-container>

    </div>
  </ng-container>

</div>