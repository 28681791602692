import { WhatsAppFunctions } from "@colmeia/core/src/shared-business-rules/social-media/whatsapp-functions";
import { EDelivery360Action } from "../../comm-interfaces/interaction-interfaces";
import { BBCodeDelivery360ActionParsersDefinitionItem, BBCodeDelivery360ActionParsersDefinitions, BBCodeStyleTags, BBCodeTagsDB, EBBCodeStyles } from "./bbcode-types";
import { bbcodeHTMLParserDefinitions, createLiMarkup, createOrderedList, createRepeatedMarkupGenerator, createUnorderedList, markupNOOP, noopMarkupStyles, textMenuMarkupGenerator, whatsAppMenuMarkupGenerator } from "./functions/bbcode-functions-simple";

export const bbCodeTagsDB: BBCodeTagsDB = {
    [EBBCodeStyles.Italic]: { showOnClientEditor: true, },
    [EBBCodeStyles.Bold]: { showOnClientEditor: true, },
    [EBBCodeStyles.Strikethrough]: { showOnClientEditor: true, },
    [EBBCodeStyles.Code]: { showOnClientEditor: true, },
    [EBBCodeStyles.Monospace]: {},
    [EBBCodeStyles.Menu]: {},
    [EBBCodeStyles.Link]: {},
    [EBBCodeStyles.Heading1]: {},
    [EBBCodeStyles.Heading2]: {},
    [EBBCodeStyles.Heading3]: {},
    [EBBCodeStyles.Heading4]: {},
    [EBBCodeStyles.Heading5]: {},
    [EBBCodeStyles.Heading6]: {},
    [EBBCodeStyles.UnorderedList]: {},
    [EBBCodeStyles.OrderedList]: {},
    [EBBCodeStyles.ListItem]: {},
    [EBBCodeStyles.Base64Image]: {},
    [EBBCodeStyles.Table]: {},
    [EBBCodeStyles.TableRow]: {},
    [EBBCodeStyles.TableHeadingCell]: {},
    [EBBCodeStyles.TableContentCell]: {},
}

export const bbCodeTagPattern: RegExp = /\[([^\]]+)?\]/;

const messenger: BBCodeStyleTags = ({
    [EBBCodeStyles.Strikethrough]: createRepeatedMarkupGenerator(`~`),
    [EBBCodeStyles.Italic]: createRepeatedMarkupGenerator(`_`),
    [EBBCodeStyles.Bold]: createRepeatedMarkupGenerator(`*`),
    [EBBCodeStyles.Code]: createRepeatedMarkupGenerator('```'),
    [EBBCodeStyles.Monospace]: createRepeatedMarkupGenerator('`'),
    [EBBCodeStyles.Menu]: textMenuMarkupGenerator,
    [EBBCodeStyles.Link]: markupNOOP,
    [EBBCodeStyles.Heading1]: markupNOOP,
    [EBBCodeStyles.Heading2]: markupNOOP,
    [EBBCodeStyles.Heading3]: markupNOOP,
    [EBBCodeStyles.Heading4]: markupNOOP,
    [EBBCodeStyles.Heading5]: markupNOOP,
    [EBBCodeStyles.Heading6]: markupNOOP,
    [EBBCodeStyles.UnorderedList]: markupNOOP,
    [EBBCodeStyles.OrderedList]: markupNOOP,
    [EBBCodeStyles.ListItem]: markupNOOP,
    [EBBCodeStyles.Base64Image]: markupNOOP,
    [EBBCodeStyles.Table]: markupNOOP,
    [EBBCodeStyles.TableRow]: markupNOOP,
    [EBBCodeStyles.TableHeadingCell]: markupNOOP,
    [EBBCodeStyles.TableContentCell]: markupNOOP,
})


function createParseDefinitionItem(tags: BBCodeStyleTags): BBCodeDelivery360ActionParsersDefinitionItem {
    return {
        tags,
    }
}

export function getParsersDefinition(delivery: EDelivery360Action): BBCodeDelivery360ActionParsersDefinitionItem | undefined {
    return parsersDefinitions[delivery];
}

export const parsersDefinitions: BBCodeDelivery360ActionParsersDefinitions = {
    [EDelivery360Action.Delivery360WhatsApp]: {
        interceptor(text) {
            // WhatsAppFunctions.escape
            return (text);
        },
        tags: ({
            [EBBCodeStyles.Italic]: createRepeatedMarkupGenerator('_'),
            [EBBCodeStyles.Bold]: createRepeatedMarkupGenerator('*'),
            [EBBCodeStyles.Strikethrough]: createRepeatedMarkupGenerator(`~`),
            [EBBCodeStyles.Monospace]: createRepeatedMarkupGenerator('```'),
            [EBBCodeStyles.Code]: createRepeatedMarkupGenerator('```'),
            [EBBCodeStyles.Menu]: whatsAppMenuMarkupGenerator,
            [EBBCodeStyles.Link]: markupNOOP,
            [EBBCodeStyles.Heading1]: markupNOOP,
            [EBBCodeStyles.Heading2]: markupNOOP,
            [EBBCodeStyles.Heading3]: markupNOOP,
            [EBBCodeStyles.Heading4]: markupNOOP,
            [EBBCodeStyles.Heading5]: markupNOOP,
            [EBBCodeStyles.Heading6]: markupNOOP,
            [EBBCodeStyles.UnorderedList]: createUnorderedList,
            [EBBCodeStyles.OrderedList]: createOrderedList,
            [EBBCodeStyles.ListItem]: createLiMarkup,
            [EBBCodeStyles.Base64Image]: markupNOOP,
            [EBBCodeStyles.Table]: markupNOOP,
            [EBBCodeStyles.TableRow]: markupNOOP,
            [EBBCodeStyles.TableHeadingCell]: markupNOOP,
            [EBBCodeStyles.TableContentCell]: markupNOOP,
        })
    },

    [EDelivery360Action.DeliveryInstagram]: createParseDefinitionItem({
        [EBBCodeStyles.Strikethrough]: markupNOOP,
        [EBBCodeStyles.Italic]: markupNOOP,
        [EBBCodeStyles.Bold]: markupNOOP,
        [EBBCodeStyles.Code]: markupNOOP,
        [EBBCodeStyles.Monospace]: markupNOOP,
        [EBBCodeStyles.Menu]: textMenuMarkupGenerator,
        [EBBCodeStyles.Link]: markupNOOP,
        [EBBCodeStyles.Heading1]: markupNOOP,
        [EBBCodeStyles.Heading2]: markupNOOP,
        [EBBCodeStyles.Heading3]: markupNOOP,
        [EBBCodeStyles.Heading4]: markupNOOP,
        [EBBCodeStyles.Heading5]: markupNOOP,
        [EBBCodeStyles.Heading6]: markupNOOP,
        [EBBCodeStyles.UnorderedList]: markupNOOP,
        [EBBCodeStyles.OrderedList]: markupNOOP,
        [EBBCodeStyles.ListItem]: markupNOOP,
        [EBBCodeStyles.Base64Image]: markupNOOP,
        [EBBCodeStyles.Table]: markupNOOP,
        [EBBCodeStyles.TableRow]: markupNOOP,
        [EBBCodeStyles.TableHeadingCell]: markupNOOP,
        [EBBCodeStyles.TableContentCell]: markupNOOP,
    }),

    [EDelivery360Action.Delivery360FBMessenger]: createParseDefinitionItem(messenger),
    [EDelivery360Action.Delivery360Email]: createParseDefinitionItem(bbcodeHTMLParserDefinitions),

    [EDelivery360Action.DeliveryTelegram]: createParseDefinitionItem({
        ...bbcodeHTMLParserDefinitions,
        [EBBCodeStyles.Monospace]: markupNOOP,
        [EBBCodeStyles.Menu]: textMenuMarkupGenerator,
    }),
    [EDelivery360Action.Delivery360SMS]: createParseDefinitionItem(noopMarkupStyles),
    [EDelivery360Action.Delivery360Voice]: createParseDefinitionItem(noopMarkupStyles),
    [EDelivery360Action.DeliveryColmeia]: createParseDefinitionItem({
        ...noopMarkupStyles,
        [EBBCodeStyles.Menu]: textMenuMarkupGenerator,
    }),
    [EDelivery360Action.Delivery360RCS]: createParseDefinitionItem({
        [EBBCodeStyles.Strikethrough]: markupNOOP,
        [EBBCodeStyles.Italic]: markupNOOP,
        [EBBCodeStyles.Bold]: markupNOOP,
        [EBBCodeStyles.Code]: markupNOOP,
        [EBBCodeStyles.Monospace]: markupNOOP,
        [EBBCodeStyles.Menu]: textMenuMarkupGenerator,
        [EBBCodeStyles.Link]: markupNOOP,
        [EBBCodeStyles.Heading1]: markupNOOP,
        [EBBCodeStyles.Heading2]: markupNOOP,
        [EBBCodeStyles.Heading3]: markupNOOP,
        [EBBCodeStyles.Heading4]: markupNOOP,
        [EBBCodeStyles.Heading5]: markupNOOP,
        [EBBCodeStyles.Heading6]: markupNOOP,
        [EBBCodeStyles.UnorderedList]: markupNOOP,
        [EBBCodeStyles.OrderedList]: markupNOOP,
        [EBBCodeStyles.ListItem]: markupNOOP,
        [EBBCodeStyles.Base64Image]: markupNOOP,
        [EBBCodeStyles.Table]: markupNOOP,
        [EBBCodeStyles.TableRow]: markupNOOP,
        [EBBCodeStyles.TableHeadingCell]: markupNOOP,
        [EBBCodeStyles.TableContentCell]: markupNOOP,
    })
};
