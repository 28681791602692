import { ChangeDetectorRef, Component, EventEmitter, Input, Optional, Output, ViewChild } from '@angular/core';
import { RootComponent } from 'app/components/foundation/root/root.component';
import {
    EActionOnComplete,
    EGeneratorTransactionType,
    IBotTransaction,
    INLPTransactionServer,
    INLPTransaction,
    ITransactionServer,
} from '@colmeia/core/src/shared-business-rules/knowledge-base/bot-transaction/bot-transaction';
import { IKnowledgeDBServer } from '@colmeia/core/src/shared-business-rules/knowledge-base/kb-inferfaces';
import {
    IConnectionRouteServer,
    IConnectionServer,
    IEmbeddingConnectionServer,
    ILLMConnectionServer,
    TConnectionsArray
} from '@colmeia/core/src/shared-business-rules/connections/endpoint-model';
import { ConnectionsService } from 'app/services/connections.service';
import { isInvalid, isInvalidArray, isValidArray, isValidRef, isValidString, isValidTrimmedString, values } from '@colmeia/core/src/tools/utility';
import { MatSelectChange } from '@angular/material/select';
import { ActionOnCompleteTranslations, BotTransactionTypeTranslations } from 'app/model/bot-transaction.model';
import { NgForm } from '@angular/forms';
import { MatCheckboxChange } from "@angular/material/checkbox";
import { NSPickerHandler, INSPickerHandlerParameter, INSPickerHandlerClientCallback } from 'app/handlers/ns-picker/ns-picker.handler';
import { ENonSerializableObjectType, INonSerializable } from '@colmeia/core/src/shared-business-rules/non-serializable-id/non-serializable-id-interfaces';
import { GenericNonSerializableService } from 'app/services/generic-ns.service';
import { RoutingService } from 'app/services/routing.service';
import { RequestBuilderServices } from 'app/services/request-builder.services';
import { ServerCommunicationService } from 'app/services/server-communication.service';
import { SessionService } from 'app/services/session.service';
import { ENextGenBotElementType } from '@colmeia/core/src/shared-business-rules/bot/bot-model';
import { SchemaPropertyServer } from '@colmeia/core/src/shared-business-rules/files/files';
import { gTranslations } from "@colmeia/core/src/shared-business-rules/const-text/translations";
import { DashBoardService } from 'app/services/dashboard/dashboard.service';
import { EDefaultTag } from '@colmeia/core/src/shared-business-rules/colmeia-tags/tags';
import { ColmeiaWindowRef } from '../../dashboard-foundation/colmeia-window/colmeia-window-ref';
import { IGeneralFileMetadata } from '@colmeia/core/src/request-interfaces/files-interfaces';
import { EFunctionContext } from '@colmeia/core/src/shared-business-rules/user-function/user-function-model';
import { useClientPredicates } from '@colmeia/core/src/tools/utility-types';
import { EnumPickerHandler, IEnumPickerClientCallback, EEnumPickerMode } from 'app/components/foundation/enum-picker/enum-picker.handler';
import { pickTranslations } from '@colmeia/core/src/shared-business-rules/const-text/all-serializables';
import { EOmniSenseMethods, IGenAIProviderOptions, genAIProviderOptions } from '@colmeia/core/src/shared-business-rules/knowledge-base/clu-core-interfaces';
import { ColmeiaDialogService } from 'app/services/dialog/dialog.service';
import { ILLMConnectionAuth } from '@colmeia/core/src/shared-business-rules/connections/connections-auth-model';
import { uniqueId } from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import { LookupService } from 'app/services/lookup.service';


type PickFromNSPicker<T extends keyof INSPickerHandlerParameter> = Pick<INSPickerHandlerParameter, T>;
type IRequiredGetNSPicker = Required<PickFromNSPicker<'title' | 'nsType' | 'disabledTitle' | 'nonSerializablesIds'>>;
type IOptionalGetNSPicker = Partial<PickFromNSPicker<'demandedTag' | 'allowCreateButton' | 'useColmeiaWindowSvc' | 'colmeiaWindowConfig' | 'disable' | 'forceShowClear' | 'filter'>>;

type IGetNSPicker = IRequiredGetNSPicker & IOptionalGetNSPicker;


@Component({
    selector: 'bot-transaction-generation-data',
    templateUrl: './bot-transaction-generation-data.component.html',
    styleUrls: ['./bot-transaction-generation-data.component.scss']
})
export class BotTransactionGenerationDataComponent extends RootComponent<
    'metadata'
    | 'route'
    | 'fieldRequired'
    | 'knowledgeBase'
    | 'generationDataTitle'
    | 'connection'
    | 'type'
    | 'submitOnComplete'
    | 'actionOnComplete'
    | 'return'
    | 'kUntC'
    | 'formTransaction'
    | 'nlpTransaction'
    | 'botLevel'
    | 'add'
    | 'actions'
    | 'strategiesList'
    | 'strategy'
    | 'strategyEnabled'
    | 'strategyWeight'
    | 'createStrategyConfig'
    | 'editStrategyConfig'
    | EOmniSenseMethods
> implements INSPickerHandlerClientCallback {

    @Input() transaction: IBotTransaction;
    @Input() allowPickBotLevel: boolean = false;
    @Output() typeChange = new EventEmitter();
    @Output() schemaChange = new EventEmitter();
    @Output() knowledgeBaseChange = new EventEmitter();

    @Output() isValidDatabasePicker = new EventEmitter();

    @ViewChild(NgForm) form: NgForm;

    ///@@hugo favor checar
    formType: EGeneratorTransactionType = ENextGenBotElementType.formTransaction;

    types: string[] = [ENextGenBotElementType.formTransaction, ENextGenBotElementType.nlpTransaction];
    actions: string[] = Object.values(EActionOnComplete);

    selectedConnection: IConnectionServer;

    connections: TConnectionsArray = [];
    routes: IConnectionRouteServer[] = [];

    knowledBasePicker: NSPickerHandler;
    schemaPicker: NSPickerHandler;
    idDatabaseContentGeneratorPicker: NSPickerHandler;

    ENextGenBotElementType: typeof ENextGenBotElementType = ENextGenBotElementType;

    public enumPickerHandler: EnumPickerHandler<ENextGenBotElementType>;

    strategiesDisplayedColumns = ['osTatic', 'isOn', 'weight', 'actions'];

    get nlpTransaction() {
        return this.transaction as INLPTransaction;
    }

    public set rerunGreetings(value: boolean) {
        if (!value) {
            delete (this.transaction as INLPTransaction).rerunGreetings;
            return;
        }

        (this.transaction as INLPTransaction).rerunGreetings = value;
    }
    public get rerunGreetings(): boolean {
        return (this.transaction as INLPTransaction).rerunGreetings;
    }
    public set rerunConfirmation(value: boolean) {
        if (!value) {
            delete (this.transaction as INLPTransaction).rerunConfirmation;
            return;
        }

        (this.transaction as INLPTransaction).rerunConfirmation = value;
    }

    userFunctionPicker: NSPickerHandler<ENonSerializableObjectType.userFunction>;

    initUserFunctionPicker() {
        this.userFunctionPicker = new NSPickerHandler({
            title: 'Função para alteração dinâmica de conteúdo',
            disabledTitle: '',
            nsType: ENonSerializableObjectType.userFunction,
            nonSerializablesIds: [this.nlpTransaction.idUserFunction],
            shouldUseCurrentDemandedTag: false,
            clientCallback: {
                onSaveNSCallback: (ns) => {
                    this.nlpTransaction.idUserFunction = ns?.idNS;
                },
            },
            predicateFilter: {
                context: EFunctionContext.AI,
            },
        });
    }

    public get submitOnComplete(): boolean {
        return this.transaction.submitOnComplete;
    }

    public set submitOnComplete(value: boolean) {
        if (!value) {
            delete this.transaction.submitOnComplete;
            return;
        }

        this.transaction.submitOnComplete = value;
    }
    public get rerunConfirmation(): boolean {
        return (this.transaction as INLPTransaction).rerunConfirmation;
    }

    get isEdit(): boolean {
        return isValidRef((this.transaction as ITransactionServer).idNS);
    }

    constructor(
        private connectionSvc: ConnectionsService,
        private session: SessionService,
        private api: ServerCommunicationService,
        private rbs: RequestBuilderServices,
        private routeSvc: RoutingService,
        private dashboardSvc: DashBoardService,
        @Optional() private colmeiaWindowRef: ColmeiaWindowRef,
        private dialogSvc: ColmeiaDialogService,
        private cdr: ChangeDetectorRef,
        private lookUpSvc: LookupService
    ) {
        super({
            metadata: gTranslations.common.metadata,
            route: gTranslations.common.route,
            fieldRequired: gTranslations.common.fieldRequired,
            knowledgeBase: gTranslations.kb.knowledgeBase,
            generationDataTitle: gTranslations.common.name,
            connection: gTranslations.common.connection,
            type: gTranslations.common.type,
            botLevel: gTranslations.bot.botLevel,
            ...ActionOnCompleteTranslations,
            ...BotTransactionTypeTranslations,
            submitOnComplete: gTranslations.bot.submitOnComplete,
            ...pickTranslations(gTranslations.common, [
                'add',
                'actions'
            ]),
            ...pickTranslations(gTranslations.kb, [
                'strategiesList',
                'strategy',
                'strategyEnabled',
                'strategyWeight',
                'createStrategyConfig',
                'editStrategyConfig',
                ...values(EOmniSenseMethods),
            ])
        });

        this.genericNonSerializableService = new GenericNonSerializableService(null, null, {
            api,
            rbs,
            routeSvc,
            session
        });
    }

    genericNonSerializableService: GenericNonSerializableService;

    set botLevel(value: EGeneratorTransactionType) {
        this.transaction.botLevel = value;

        this.loadNLP();
    }

    get botLevel(): EGeneratorTransactionType {
        return this.transaction.botLevel;
    }

    async ngOnInit() {
        this.checkType(this.botLevel, false);
        this.loadNLP();
        this.initMetadataPicker();
        this.initHandlers();
        this.loadDbNsDataOnInit();
    }

    initHandlers() {
        this.initMetadataPicker()
        this.initIdDatabaseContentGeneratorPicker();
        this.initEnumPickerHandler();
    }

    private async loadDbNsDataOnInit() {
        if(!isValidTrimmedString(this.transaction.idDatabaseContentGenerator)) {
            return;
        }
        const dbNs: INonSerializable = await this.lookUpSvc.getNS(this.transaction.idDatabaseContentGenerator);
        this.databaseNs = dbNs;
        this.isValidDatabaseUpdate();
    }

    private initMetadataPicker() {
        let nsPickerParams: IGetNSPicker = {
            title: 'Selecione um formulário',
            disabledTitle: '',
            nsType: ENonSerializableObjectType.formSchemma,
            nonSerializablesIds: [this.transaction.idSchemma],
            demandedTag: EDefaultTag.smartFlow
        };

        if (this.colmeiaWindowRef) {
            nsPickerParams = {
                ...nsPickerParams,
                useColmeiaWindowSvc: true,
                allowCreateButton: true,
                colmeiaWindowConfig: {
                    windowGroup: this.colmeiaWindowRef.title || 'Gerador de Conteúdo',
                    windowIdentifier: uuidv4(),
                    parentIdentifier: this.colmeiaWindowRef.windowIdentifier,
                }
            };
        }

        this.schemaPicker = this.getNSPicker(nsPickerParams);
    }

    private initIdDatabaseContentGeneratorPicker() {
        this.idDatabaseContentGeneratorPicker = this.getNSPicker({
            title: 'Selecione um Banco de Dados',
            nsType: ENonSerializableObjectType.fileMetadata,
            nonSerializablesIds: this.transaction.idDatabaseContentGenerator ? [this.transaction.idDatabaseContentGenerator] : [],
            disable: !!!this.transaction.idSchemma,
            disabledTitle: 'Para selecionar um banco de dados, primeiro selecione um Metadado',
            forceShowClear: true,
            filter: (ns: INonSerializable) => {
                return (<IGeneralFileMetadata>ns).idSchemma === this.transaction.idSchemma
            },
            colmeiaWindowConfig: {
                parentIdentifier: this.colmeiaWindowRef?.windowIdentifier,
            windowGroup: this.colmeiaWindowRef?.title || this.transaction.nName,
            windowIdentifier: uuidv4()
            },
            useColmeiaWindowSvc: isValidRef(this.colmeiaWindowRef),
        });
    }

    public initEnumPickerHandler(): void {
        this.enumPickerHandler = new EnumPickerHandler({
            client: {
                onSingleEnumSelection: level => {
                    this.botLevel = level as EGeneratorTransactionType
                    this.checkType(this.botLevel, true);
                },
            } as IEnumPickerClientCallback<ENextGenBotElementType>,
            mode: EEnumPickerMode.Single,
            appearance: 'outline',
            translations: gTranslations.bot,
            inputTitle: 'Nível do Bot',
            enum: ENextGenBotElementType,
            ignoreValues: [
                ENextGenBotElementType.atendanceIsland,
                ENextGenBotElementType.botMenuItem,
                ENextGenBotElementType.menuContainer,
                ENextGenBotElementType.metadata,
                ENextGenBotElementType.root
            ],
            clientCallback: null,
            current: this.transaction.botLevel,
            buttonMode: true
        })
    }

    public showKnowledBasePicker(): boolean {
        return this.botLevel !== ENextGenBotElementType.formTransaction;
    }

    public get isNLP(): boolean {
        return this.botLevel === ENextGenBotElementType.nlpTransaction;
    }

    public loadNLP(): void {
        this.loadNlpIdKbIfNecessary();
        this.initLLMNSPickerHandler();
    }

    public loadNlpIdKbIfNecessary() {
        if (!this.isNLP) return;
        this.checkType(this.botLevel, false);

        this.knowledBasePicker = this.getNSPicker({
            title: 'Selecione uma base de conhecimento',
            disabledTitle: 'Base de conhecimento selecionada',
            nsType: ENonSerializableObjectType.knowledgeBase,
            nonSerializablesIds: [(this.transaction as INLPTransaction) && (this.transaction as INLPTransaction).nlp.idKB],
            colmeiaWindowConfig: {
                parentIdentifier: this.colmeiaWindowRef?.windowIdentifier || this.transaction.idParent,
                windowGroup: this.colmeiaWindowRef?.title || this.transaction.nName,
                windowIdentifier: uuidv4()
            },
            useColmeiaWindowSvc: isValidRef(this.colmeiaWindowRef),
        });
        this.initUserFunctionPicker();
    }
    public llmHandler: NSPickerHandler;

    private initLLMNSPickerHandler() {
        if (!this.isNLP) return;
        const nlpTransaction = this.transaction as INLPTransaction;
        const nsPickerParameter: INSPickerHandlerParameter = {
            title: "Selecione um provedor de Large Language Model (LLM)",
            nsType: ENonSerializableObjectType.connection,
            clientCallback: {
                onSaveNSCallback: (ns: | IConnectionServer | undefined): void => {
                    const auth = ns ? ns.auth as ILLMConnectionAuth : undefined;

                    if (isInvalid(nlpTransaction.nlp.providers)) nlpTransaction.nlp.providers = {};

                    if (isInvalid(auth)) {
                        return;
                    }

                    this.nlpTransaction.nlp.providers!.llm = {
                        connectionType: auth.type,
                        idConnection: ns?.idNS // will be written below
                    };
                },
                onClearCallback: () => {nlpTransaction.nlp.providers!.llm!.idConnection = undefined}
            },
            maxSelections: 1,
            nonSerializablesIds:  isValidString(nlpTransaction.nlp.providers?.llm?.idConnection) ? [nlpTransaction.nlp.providers!.llm!.idConnection] : [],

            //TODO - perguntar ao fe
            demandedTag: EDefaultTag.communication,

            match: [useClientPredicates<ILLMConnectionServer>()($ => ({
                [$.isLLM]: true,
                [$.nsType]: ENonSerializableObjectType.connection }
            ))],
            filter: (ns: IEmbeddingConnectionServer) => genAIProviderOptions.includes(ns.auth.type)
        };

        this.llmHandler = new NSPickerHandler(nsPickerParameter);
        console.log({llmHandler: this.llmHandler, isNLP:this.isNLP})
    }



    public getNSPicker(parameters: IGetNSPicker): NSPickerHandler {
        const { title, disabledTitle, nsType, demandedTag, nonSerializablesIds, allowCreateButton, useColmeiaWindowSvc, colmeiaWindowConfig, disable, forceShowClear, filter } = parameters;

        const nsPickerParameter: INSPickerHandlerParameter = {
            title,
            nsType,
            idParent: null,
            nonSerializablesIds,
            disabledTitle,
            disable,
            forceShowClear,
            clientCallback: this,
            demandedTag,
            genericNonSerializableService: this.genericNonSerializableService,
            allowCreateButton,
            useColmeiaWindowSvc,
            colmeiaWindowConfig,
            filter
        };

        const nsPickerHandler: NSPickerHandler = new NSPickerHandler(nsPickerParameter);

        return nsPickerHandler;
    }

    private _databaseNs: INonSerializable;
    private get databaseNs() {
        return this._databaseNs;
    }
    private set databaseNs(value: INonSerializable) {
        this._databaseNs = value;
    }

    // private resetIdDatabaseContentGeneratorHandler() {
    //     this.idDatabaseContentGeneratorPicker = undefined;
    //     this.transaction.idDatabaseContentGenerator = undefined;
    //     this.onKnowledgeBaseChange(undefined);
    //     this.initIdDatabaseContentGeneratorPicker();
    // }

    private isValidDatabase(): boolean { 
        return isValidTrimmedString(this.transaction.idSchemma)
            && this.transaction.idSchemma === (this.databaseNs as IGeneralFileMetadata)?.idSchemma;
    }

    onSaveNSCallback: INSPickerHandlerClientCallback['onSaveNSCallback'] = (ns: INonSerializable, nsType: ENonSerializableObjectType) => {

        switch (nsType) {
            case ENonSerializableObjectType.connectionRoute: {
            }
                break;
            case ENonSerializableObjectType.formSchemma: {
                const nser: SchemaPropertyServer = (ns as SchemaPropertyServer);

                this.transaction.idSchemma = nser?.schemma?.idSchemma;

                // if(isValidTrimmedString(this.transaction.idDatabaseContentGenerator)) {
                //     if(!this.isValidDatabase()) {
                //         this.resetIdDatabaseContentGeneratorHandler();
                //         this.onSchemaChange(nser && nser.idNS);
                //         break;
                //     }
                // } 
                this.initIdDatabaseContentGeneratorPicker();
                this.onSchemaChange(nser && nser.idNS);
                this.isValidDatabaseUpdate();
            }
                break;
            case ENonSerializableObjectType.knowledgeBase: {
                const nser: IKnowledgeDBServer = (ns as IKnowledgeDBServer);
                const idKB: string = nser && nser.idNS;

                (this.transaction as INLPTransaction).nlp.idKB = idKB;
                this.onKnowledgeBaseChange(idKB);
            }
                break;
            case ENonSerializableObjectType.fileMetadata: {
                this.databaseNs = ns;
                this.transaction.idDatabaseContentGenerator = ns?.idNS
                this.isValidDatabaseUpdate();
            }
                break;
        }


        return;
    }

    async getConnectionByIDDomain(idDomain: string): Promise<IConnectionServer> {
        return;
    }
    async getRouteByID(idNS: string): Promise<IConnectionRouteServer> {
        return;
    }
    async getConnectionRoutes(conn: IConnectionServer): Promise<IConnectionRouteServer[]> {
        return;
    }

    async connectionChosen(change: MatSelectChange) {
    }

    get type() {
        return (<any>this.transaction).type;
    }

    checkType(type: EGeneratorTransactionType, emit: boolean = true) {
        if (type !== this.formType && isInvalid((<INLPTransactionServer>this.transaction).nlp)) {
            (<INLPTransactionServer>this.transaction).nlp = {
                // accuracy: {
                //     confirmAccuracyUntil: 0,
                //     confirmMessage: '',
                //     ignoreAccuracyUntil: 0,
                //     ignoredMessage: ''
                // },
                assets: {},
                idKB: undefined
            }
        }

        if (isInvalidArray(this.transaction.variableTags)) {
            this.transaction.variableTags = [];
        }
        if (emit) {
            this.typeChange.emit(type);
        }
    }

    onSelectSubmitOnComplete(event: MatCheckboxChange): void {

    }

    onSchemaChange(idNS: string) {
        this.schemaChange.emit(idNS);
    }

    onKnowledgeBaseChange(idNS: string) {
        this.knowledgeBaseChange.emit(idNS);
    }
    
    isValidDatabaseUpdate() {
        this.isValidDatabasePicker.emit(this.isValidDatabase());
    }

    isInvalid(): boolean {
        this.form.onSubmit(null);
        return this.form.invalid;
    }
}
