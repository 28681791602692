import { coerceBooleanProperty } from "@angular/cdk/coercion";
import {
    AfterViewInit,
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild,
    ViewContainerRef,
} from "@angular/core";
import { MatButton } from "@angular/material/button";
import { IConnectionVariable, TIConnectionVariableArray } from "@colmeia/core/src/shared-business-rules/connections/endpoint-model";
import {
    insertContentAt,
    isInvalidString,
    isValidRef,
    isValidString,
} from "@colmeia/core/src/tools/utility";
import { OnChange } from "app/model/client-utility";
import { ColmeiaVariableInserterService, IVarInserterHandler } from "app/services/dashboard/colmeia-variable-inserter.service";
import { Position } from "codejar";
import * as MonacoApi from "monaco-editor/esm/vs/editor/editor.api.d";
import { ReplaySubject } from "rxjs";
import { isNewVar, removeCurlyBraces, TParamVariable } from "../params-editor/mat-quill-utility";
import { VarInserterDialogComponent } from "../var-inserter-dialog/var-inserter-dialog.component";
import { TCodeEditorLibConfig, TEditorOptions } from "./code-editor.model";
import { uniqBy } from "lodash";
import { safeParseJSON } from "@colmeia/core/src/shared-business-rules/connections/connections-functions";

enum EContentType {
    Code = "code",
    JSON = "json",
    html = 'html'
}

interface IMonacoPosition extends Pick<MonacoApi.Position, "lineNumber" | "column"> {
    lineNumber: number,
    column: number
}
export interface CodeEditorParameters {
    content: string | object;
    language: string;

    label?: string;
    fileName?: string;
    type?: EContentType;
    hideLines?: boolean;
    hasErrors?: boolean;
    readonly?: boolean;
    minimap?: boolean;
    theme?: string;
    editorOptions?: TEditorOptions;
    extraTSLibs?: TCodeEditorLibConfig[];
    contentChange?(value: string | object): void;
    blur?(value: MonacoApi.editor.IStandaloneCodeEditor): void;
    onInit?(value: MonacoApi.editor.IStandaloneCodeEditor): void;
    onHasErrorsChange(value?: boolean): void;
}

// @CheckProperties()
@Component({
    selector: "app-code-editor",
    template: `
        <div class="editor-header">
            <h2 *ngIf="label" class="title">{{ label }}</h2>
            <button
                *ngIf="useVariables"
                class="editor-header--add-button"
                #inserVarTrigger
                mat-mini-fab
                (click)="openTemplate(inserVarTrigger, $event)"
                matTooltip="Clique para adicionar uma variável."
                [matTooltipPosition]="'above'"
                color="accent"
            >
                <mat-icon>add</mat-icon>
            </button>
        </div>
        <mat-spinner
            *ngIf="loadingMonaco"
            color="accent"
            diameter="24"
        ></mat-spinner>
        <ngx-monaco-editor
            #ngxMonaco
            style="height: 100%"
            [options]="_editorOptions"
            [(ngModel)]="_editorContent"
            (init)="onEditorInit($event)"
        >
        </ngx-monaco-editor>
    `,
    styleUrls: ["./code-editor.component.scss"],
})
export class CodeEditorComponent implements OnInit, OnDestroy, AfterViewInit {
    public loadingMonaco: boolean = true;
    @Input() label?: string;
    @Input() fileName?: string;
    @Input() content!: string | object;
    @Input() type?: EContentType = EContentType.Code;
    @Output() contentChange?= new EventEmitter<string | object>();
    @Output() blur?=
        new EventEmitter<MonacoApi.editor.IStandaloneCodeEditor>();
    @Output() onInit?=
        new EventEmitter<MonacoApi.editor.IStandaloneCodeEditor>();
    @Output() onHasErrorsChange = new EventEmitter<boolean>();

    @Input() useVariables: boolean;
    @Input()
    set variables(value: Array<IConnectionVariable>) {
        if (value !== this._variables) {
            this._variables = value
            this.initParamVariables();

        };
    }
    @Output() variablesChange: EventEmitter<Array<IConnectionVariable>> = new EventEmitter();

    _variables: Array<IConnectionVariable> = [];

    @Input() variablesOptions: TParamVariable[] = [{ id: "${auth}", value: "auth" }]
    @Output() variablesOptionsChange: EventEmitter<TParamVariable[]> = new EventEmitter();

    paramsVariables: Array<TParamVariable> = [];

    @ViewChild('ngxMonaco') ngxMonaco;

    _hideLines: boolean = false;

    @Input()
    set hideLines(value: boolean) {
        this._hideLines = coerceBooleanProperty(value);
    }
    get hideLines() {
        return this._hideLines;
    }

    _readonly: boolean = false;
    @Input()
    set readonly(value: boolean) {
        this._readonly = coerceBooleanProperty(value);
        this.bindState();
    }
    get readonly() {
        return this._readonly;
    }

    _showMinimap: boolean = false;
    @Input()
    set minimap(value: boolean) {
        this._showMinimap = value === true || value !== false;
    }
    get minimap() {
        return this._showMinimap;
    }

    __content: string | object = "";
    public get _content(): string | object {
        return this.__content;
    }

    public set _content(value: string | object) {
        this.contentChange.emit(value);
        this.__content = value;

        if ((typeof value !== 'string') && (this.type === EContentType.JSON)) {
            value = JSON.stringify(value);
        }

        this.__editorContent = value as string;
    }

    private __editorContent: string;

    public get _editorContent(): string {
        return this.__editorContent;
    }

    public set _editorContent(value: string) {
        if (this.type == EContentType.JSON) {
            this.__editorContent = value;

            try {
                value = JSON.parse(value);

                this.contentChange.emit(value);
                this.__content = value;
                if (this._variables) {
                    this.handleVariablesInContent()
                }
            } catch (err) {
                if (!(err instanceof SyntaxError)) {
                    throw err;
                }
            }

            return;
        }

        this._content = value;
    }

    @OnChange()
    public hasErrors: boolean = false;

    public lastCursorPosition: Position = { start: 0, end: 0 };
    private lastPosition: IMonacoPosition = { lineNumber: 1, column: 1 };

    @Input()
    language: string = "typescript";

    @Input()
    theme?: string = "vs-dark";

    _editorOptions: TEditorOptions;
    @Input()
    editorOptions?: TEditorOptions;

    @Input()
    extraTSLibs?: TCodeEditorLibConfig[] = [];

    private _editor: MonacoApi.editor.IStandaloneCodeEditor;
    public editor: ReplaySubject<MonacoApi.editor.IStandaloneCodeEditor> =
        new ReplaySubject(1);

    private monacoEvents: MonacoApi.IDisposable[] = [];

    public monaco: typeof MonacoApi;

    async onEditorInit(editor: MonacoApi.editor.IStandaloneCodeEditor) {
        const monaco = window.monaco;
        this.monaco = monaco;

        this.loadingMonaco = false;
        this._editor = editor;
        this.editor.next(editor);
        this.onInit.next(editor);

        this.monaco.languages.typescript.typescriptDefaults.setCompilerOptions({
            ...this.monaco.languages.typescript.typescriptDefaults.getCompilerOptions(),
            strict: true,
            //
            strictNullChecks: true,
        })

        for (const extraLib of this.extraTSLibs) {
            this.monaco.languages.typescript.typescriptDefaults.addExtraLib(
                extraLib.content,
                extraLib.fileName
            );
        }

        this.registryMonacoEvents();
        this.updateEditorOptions();

        setTimeout(() => {
            editor.layout();
        }, 200);
    }

    public layout() {
        this._editor.layout();
    }

    public onChangeHasErrors(): void {
        this.onHasErrorsChange.emit(this.hasErrors);
    }

    private registryMonacoEvents() {
        // update decorations
        this.monacoEvents.push(
            this._editor.onDidChangeModelDecorations(() => {
                const allDecorations = this._editor
                    .getModel()
                    .getAllDecorations();
                this.hasErrors = allDecorations.some(
                    (d) => d.options.className === "squiggly-error"
                );
            })
        );

        // update cursor
        this.monacoEvents.push(
            this._editor.onDidChangeCursorPosition((e) => {
                this.saveCodeEditorCursor();
            })
        );

        // Blur
        this.monacoEvents.push(
            this._editor.onDidBlurEditorText(() => {
                this.blur.next(this._editor);
            })
        );
    }

    constructor(
        private varInserter: ColmeiaVariableInserterService,
        private viewContainerRef: ViewContainerRef,
    ) { }

    ngOnInit() {
        this.initParamVariables();
        this.initContent();

        this._editorOptions = {
            theme: this.theme,
            language: this.language,
        };

        if (isValidRef(this.editorOptions)) {
            this._editorOptions = this.editorOptions;
        }

        this.bindState();
    }

    ngOnDestroy() {
        this.removeMonacoEventListeners();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.content?.currentValue !== this.__content) {
            this.initContent();
            this.replaceContent(this.getContentString());
        }

        this.bindState();

    }

    ngAfterViewInit() {
        this.updateEditorOptions();
    }

    private initParamVariables() {
        if (this._variables) {
            this._variables = uniqBy(
                this._variables,
                (variable) => variable.varName
            );
            this.paramsVariables = [
                ...this._variables.map((variable) => {
                    return {
                        id: variable.varName,
                        value:
                            variable.varId ||
                            removeCurlyBraces(variable.varName),
                    };
                }),
            ];
        }
    }

    initContent() {
        if (typeof this.content == "object") {
            if (this.type == EContentType.JSON) {
                this._editorContent = JSON.stringify(this.content, null, 4);
            } else {
                this._content = JSON.stringify(this.content, null, 4);
            }
        } else {
            this._content = this.content;
        }
    }

    setCursorOffset(offset: number) {
        const model = this._editor.getModel();
        const currentPosition = this._editor.getPosition();
        const currentOffset = model.getOffsetAt(currentPosition);
        model.modifyPosition(currentPosition, offset - currentOffset);

        this.saveCodeEditorCursor();
    }

    private bindState() {
        this.updateEditorOptions();
    }

    private updateEditorOptions() {
        if (!isValidRef(this._editor)) return;

        const options = this._editor.getOptions();

        if (options.get(79 /** readOnly */) !== this._readonly) {
            this._editor.updateOptions({
                readOnly: this._readonly,
            });
        }

        if (options.get(63 /** minimap */).enabled !== this._showMinimap) {
            this._editor.updateOptions({
                minimap: {
                    enabled: this._showMinimap,
                },
            });
        }

        this._editor.updateOptions({
            wordWrap: "on",
        });
    }

    private removeMonacoEventListeners() {
        for (const listener of this.monacoEvents) {
            listener.dispose();
        }
    }

    private saveCodeEditorCursor() {
        const model = this._editor.getModel();
        const currentPosition = this._editor.getPosition();
        const currentOffset = model.getOffsetAt(currentPosition);

        this.lastPosition = currentPosition
        this.lastCursorPosition.start = currentOffset;
        this.lastCursorPosition.end = currentOffset;
    }

    public replaceContent(content: string): string {
        this.updateContent(content);
        return content;
    }

    public appendContent(content: string) {
        this.updateContent(`${this._content}${content}`);
    }

    public insertContentAt(content: string, offset: number) {
        this.updateContent(
            insertContentAt(this.getContentString(), offset, content)
        );
    }

    public insertContentAtCursor(content: string) {
        this.insertContentAtCursorByMonaco(content)
    }

    private updateContent(content: string): void {
        const listener = this._editor?.onDidChangeModelContent(async () => {
            const model = this._editor.getModel();

            model.pushStackElement();

            await this._editor.getAction("editor.action.formatDocument").run();
            const formated =
                this.type === EContentType.JSON
                    ? JSON.parse(model.getValue())
                    : model.getValue();

            this.__content = formated;
            this.contentChange.next(formated);

            listener.dispose();
        });

        if (this.type === EContentType.JSON && isValidString(content)) {
            this._content = JSON.parse(content);
        } else {
            this._content = content;
        }
    }

    public getContentString(): string {
        if (this.type == EContentType.JSON) {
            return JSON.stringify(this._content);
        }

        return this._content as string;
    }

    public openTemplate(buttonTrigger: MatButton, event: MouseEvent) {
        const varInserterHandler: IVarInserterHandler = {
            subscriptionCallback: (value: TParamVariable) => {
                if (isNewVar(value.value, this.paramsVariables)) {
                    this.variablesOptions.push(value);
                    this.variablesOptionsChange.emit(this.variablesOptions);
                }
                this.insertContentAtCursor(value.id);

            },
            popUpEventEmitterProperty: "varToInsert",
            popUpComponent: VarInserterDialogComponent,
            containerRef: this.viewContainerRef,
            data: {
                variablesOptions:  this.variablesOptions,
                updateVariables: (updatedVariables: TParamVariable[]) => {
                    this.variablesOptions = updatedVariables    ;
                    this.variablesOptionsChange.emit(this.variablesOptions);
                }
            },
            componentDataProp: "paramsVariables",
        };

        this.varInserter.openPopUp(buttonTrigger, event, varInserterHandler);
    }

    private insertContentAtCursorByMonaco(content: string) {
        const { lineNumber, column } = this.lastPosition;
        const range = new this.monaco.Range(lineNumber, column, lineNumber, column)
        const id = { major: 1, minor: 1 };
        const op = { identifier: id, range, text: content, forceMoveMarkers: true };
        this.ngxMonaco.editor.executeEdits("custom-code", [op])
    }

    private handleVariablesInContent() {
        const bodyValues: Record<string, any> = safeParseJSON(this.__editorContent)

        const mappedVariables: TIConnectionVariableArray = [];

        let startIdx: number | undefined;
        let endIdx: number | undefined;
        let possibleVar: string | undefined;

        for (let fieldKey in bodyValues) {
            if (isInvalidString(bodyValues[fieldKey])) continue;

            bodyValues[fieldKey].split("").forEach((char: string, i: number, arr: string[]) => {
                if (char === "$" && arr[i + 1] === "{") {
                    startIdx = i;
                    endIdx = undefined;
                    return;
                }
                if (char === "}" && isValidRef(startIdx)) {
                    endIdx = i + 1;
                    possibleVar = arr.slice(startIdx, endIdx).join("");
                    const isVar = this.paramsVariables.find(
                        (variable) => variable.id === possibleVar
                    );

                    if (isVar) {
                        mappedVariables.push({
                            varName: isVar.id,
                            varId: isVar.value,
                            startIdx,
                            endIdx,
                            fieldName: fieldKey
                        });
                    }
                    startIdx = undefined;
                    endIdx = undefined;
                    possibleVar = undefined;
                }
            });
        }
        this._variables = mappedVariables;
        this.variablesChange.emit(mappedVariables);
    }
}
