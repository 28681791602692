import { Component, Input } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";
import { EBPMType } from "@colmeia/core/src/shared-business-rules/BPM/bpm-model";
import { isActionWithTypeAndWithoutMandatoryOtherFields } from '@colmeia/core/src/shared-business-rules/bot/asset-functions';
import { BotIntefaceFactory } from '@colmeia/core/src/shared-business-rules/bot/bot-interface-factory';
import {
    ENextGenBotElementType,
    IBotRootServer, INaviTracker, INextGenBotServer
} from "@colmeia/core/src/shared-business-rules/bot/bot-model";
import { BotHostNode } from "@colmeia/core/src/shared-business-rules/graph-transaction/host/bot/bot.host";
import { HostNodeFactory } from "@colmeia/core/src/shared-business-rules/graph-transaction/host/factory/host-node.factory";
import { BasicElementInterfaceFactory } from "@colmeia/core/src/shared-business-rules/graph/essential/basic-element-interface.factory";
import { IBasicElementClient, IBasicElementServer, TIBasicElementServerArray } from "@colmeia/core/src/shared-business-rules/graph/essential/graph-basic-element-interfaces";
import {
    EEnvironmentType,
    EMigrationOperationType,
    ENonSerializableObjectType, INonSerializable
} from "@colmeia/core/src/shared-business-rules/non-serializable-id/non-serializable-id-interfaces";
import { isInvalid, isValidArray, isValidRef, resetAndUpdateObject, typedClone } from "@colmeia/core/src/tools/utility";
import { DynamicComponentHandler, IDynamicComponentParameter } from "app/components/dashboard/dashboard-data-extractor/cm-dynamic-component/cm-dynamic-component.handler";
import {
    EIconButtonStyles, iconButtonStyles
} from "app/components/dashboard/icon-button/icon-button.component";
import { GenericDashboardEditHandler, IGenericDashboardEditPageClientCallback } from "app/handlers/generic-dashboard-edit.handler";
import { MainHandler } from "app/handlers/main-handler";
import { ENsMoreOptions, INSMoreOptionsCustomOption, NsMoreOptionsHandler, TNSMoreOptionsCustomOptionsArray } from "app/handlers/ns-more-options.handler";
import { BpmApiService } from "app/services/bpm/api/bpm-bot-api.service";
import { BpmSaveHostedService } from "app/services/bpm/bpm-save-hosted.service";
import { BpmService } from "app/services/bpm/bpm.service";
import { DashBoardService } from "app/services/dashboard/dashboard.service";
import { NSValidationService } from "app/services/nser-validation.service";
import { RoutingService } from "app/services/routing.service";
import { SessionService } from "app/services/session.service";
import { EAppAlertTypes, SnackMessageService } from "app/services/snack-bar";
import { environment } from "environments/environment-client";
import { BpmGraphViewerService } from "../../bpm-graph-viewer/services/bpm-graph-viewer.service";
import { BotGraphElementInfoBotComponent } from "../bot-graph-element-info-bot/bot-graph-element-info-bot.component";
import { BotGraphElementInfoBotHandler } from "../bot-graph-element-info-bot/bot-graph-element-info-bot.handler";
import { BpmGraphElementInfoHandler, EBpmGraphElementInfoMode, IBpmGraphElementInfoEntity, IBpmGraphElementInfoEntityEdit } from "./bpm-graph-element-info.handler";
import { copyToClickBoardSync } from "app/utils/copy";
import { GraphElement } from "@colmeia/core/src/shared-business-rules/graph/essential/graph-element";
import { AddToPatchService } from "app/services/add-to-patch.service";
import { IBPMActionAsset } from "@colmeia/core/src/shared-business-rules/BPM/bpm-action-model";

@Component({
    selector: "app-bpm-graph-element-info",
    templateUrl: "./bpm-graph-element-info.component.html",
    styleUrls: ["./bpm-graph-element-info.component.scss"],
})
export class BpmGraphElementInfoComponent {
    @Input()
    public _handler: BpmGraphElementInfoHandler;
    public get handler(): BpmGraphElementInfoHandler {
        return this._handler;
    }
    public set handler(value: BpmGraphElementInfoHandler) {
        this._handler = value;
        this.init();
    }

    public hasSaved: boolean = false;

    public get hostedEntity(): INonSerializable {
        return this.parameters?.workElement?.entity;
    }

    public set hostedEntity(value: INonSerializable) {
        this.parameters.workElement.entity = value;
    }

    public get parameters() {
        return this.handler.getComponentParameter();
    }

    public get ns(): INonSerializable {
        return this.parameters.workElement.entity;
    }

    public set ns(value: INonSerializable) {
        this.parameters.workElement.entity = value;
    }

    public get mode(): EBpmGraphElementInfoMode {
        return this.parameters.workElement.mode;
    }

    public $mode: typeof EBpmGraphElementInfoMode = typedClone(EBpmGraphElementInfoMode);
    public get graphElementName(): string { return this.hostedEntity.nName }
    public set graphElementName(value: string) { this.hostedEntity.nName = value }

    public get name(): string {
        return this.hostedEntity.nName
    }

    public set name(value: string) {
        this.hostedEntity.nName = value;
    }
    public entityHandler: DynamicComponentHandler;

    public nsMoreOptionsHandler: NsMoreOptionsHandler;

    constructor(
        private bpmSaveHostedSvc: BpmSaveHostedService,
        private bpmSvc: BpmService,
        private validator: NSValidationService,
        private snackSvc: SnackMessageService,
        private routingSvc: RoutingService,
        private bpmApiService: BpmApiService,
        private bpmViewerSvc: BpmGraphViewerService,
        private sessionSvc: SessionService,
        private dashboardSvc: DashBoardService,
        private dialogRef: MatDialogRef<BpmGraphElementInfoComponent>,
        private patchSvc: AddToPatchService,
    ) { }

    public async init() {
        this.bpmSaveHostedSvc.setValidator(this.validator);
        this.initEntity();
        this.initEntityHandler();
        this.backupEntity();
        this.initMoreOptionsHandler();
        this.initGenericDashboardEdit();
    }


    genericDashboardEdit: GenericDashboardEditHandler
    public initGenericDashboardEdit(): void {
        this.genericDashboardEdit = new GenericDashboardEditHandler({
            nsType: ENonSerializableObjectType.bot,
            autoValidate: true,
            clientCallback: <IGenericDashboardEditPageClientCallback>{
                onGenericSaveButtonPressed: this.blockDialogCloseWhile(this.onSaveElement),
                onGenericBackButtonPressed: () => {
                    this.dialogRef.close();
                }
            },
            nser: this.ns,
            allowEditTitleInHeader: true,
            moreOptionsHandler: this.nsMoreOptionsHandler,
            disableSNCheck: true
        });
    }

    private initMoreOptionsHandler() {
        const customOptions: TNSMoreOptionsCustomOptionsArray = [];
        const graphElement = this.bpmSvc.getCurrentStrategy().getGraphRulesProcessor().findNodeElementByHostedId(this.ns.idNS);

        if (graphElement) {
            customOptions.push(this.getAddToPathOption(graphElement));
            customOptions.push(this.getCopyGraphElementIDOption(graphElement));
        }

        this.nsMoreOptionsHandler = new NsMoreOptionsHandler({
            nser: this.ns,
            clientCallback: {
                onNsMoreOptionsDeleteClicked(_nser: INonSerializable) { },
                onNsMoreOptionsCopyClicked: (_nser: INonSerializable) => {
                    this.copyID();
                }
            },
            options: {
                [ENsMoreOptions.CopyID]: true,
                [ENsMoreOptions.CheckDependencies]: true,
                [ENsMoreOptions.Archive]: false
            },
            customOptions
        });
    }

    public entityCopy: INonSerializable;
    public backupEntity(): void {
        if (isValidRef(this.hostedEntity)) {
            this.entityCopy = typedClone(this.hostedEntity);
        }
    }

    public ngOnDestroy(): void {
        if (!this.hasSaved) this.restoreInitialEntity();
    }

    public restoreInitialEntity(): void {
        if (isValidRef(this.hostedEntity)) {
            resetAndUpdateObject(this.hostedEntity, this.entityCopy);
        }
    }

    public initEntity() {
        if (this.isCreateMode()) {
            this.hostedEntity = this.getNewBot();
        }
    }

    public initEntityHandler(): void {
        if (isInvalid(this.hostedEntity))
            this.snackSvc.openDefaultMessage(EAppAlertTypes.Error)(
                "Você não pode inserir esse elemento"
            );

        switch (this.hostedEntity.nsType) {
            case ENonSerializableObjectType.bot:
                {
                    this.initBotHandler();
                }
                break;
        }
    }

    public getNewBot(): INextGenBotServer {
        const botType = this.parameters.workElement.botType as ENextGenBotElementType;
        const idParentNode: string = botType === ENextGenBotElementType.menuContainer
            ? this.bpmSvc.getCurrentStrategy().getGraphRulesProcessor().getRootElement().getHostedID()
            : this.parameters.workElement.idParentHosted;

        return BotIntefaceFactory.create(
            botType,
            { idParentNode })
    }

    public initBotHandler(): void {
        const bot: INextGenBotServer = this.hostedEntity as INextGenBotServer;
        const selectedTabs = this.isEditMode(this.parameters.workElement)
            ? this.parameters.workElement.tabToOpen
            : null;

        const handlerData: IDynamicComponentParameter<MainHandler, any> = {
            handler: BotGraphElementInfoBotHandler.factory({
                entity: bot,
                tab: selectedTabs,
                preSelectedAction: this.parameters.workElement.preSelectedAction,
                clientCallback: {},
            }),
            component: BotGraphElementInfoBotComponent
        }
        this.entityHandler = DynamicComponentHandler.factory(handlerData);
    }

    public get iconButtonCreateStyle(): Partial<CSSStyleDeclaration> {
        return iconButtonStyles[EIconButtonStyles.Create];
    }

    public blockDialogCloseWhile<A extends unknown[], B>(doAction: (...a: A) => Promise<B>): (...a: A) => Promise<B> {
        return async (...a: A) => {
            const ref = this.handler.getDialogRef();
            try {
                ref.disableClose = true;
                const computed: B = await doAction(...a)
                ref.disableClose = false;
                return computed;
            } catch (err) {
                ref.disableClose = false;
                throw err;
            }
        }
    }

    public onSaveElement = async (): Promise<void> => {
        const elementToSave: BotHostNode = <BotHostNode>HostNodeFactory.create({
            bpmType: this.bpmSvc.getType(),
            ns: this.hostedEntity
        });

        const actionSelected = elementToSave.getActionContainer()[0].action;
        const isSelectedActionWithoutTargetElementId = isActionWithTypeAndWithoutMandatoryOtherFields(actionSelected)

        if (isSelectedActionWithoutTargetElementId) {
            this.snackSvc.openDefaultMessage(EAppAlertTypes.Error)("Você não pode deixar uma acao selecionada sem elemento alvo");
            return;
        }

        const posAction = (elementToSave.getHostedObject() as IBotRootServer).posAction?.advancedConditions;
        const actionSelectedAdvancedConditions = (actionSelected?.advancedConditions ? actionSelected.advancedConditions : posAction) || [];

        for (let i = 0; i < actionSelectedAdvancedConditions?.length; i++) {
            if ((<IBPMActionAsset>actionSelectedAdvancedConditions[i].action).action.type === undefined) {
                this.snackSvc.openDefaultMessage(EAppAlertTypes.Error)("Você deve selecionar uma Ação para a Ação do Bot ao usar condições");
                return;
            }
        }

        const botElement = this.hostedEntity as INextGenBotServer;
        // const botElement = this.hostedEntity as IBotActionAsset;
        // IBotActionAsset

        if (botElement.trackerRule) {
            const fulfilled = Object.keys(botElement.trackerRule).every(key => botElement.trackerRule![key as keyof INaviTracker]);
            if (!fulfilled) {
                this.snackSvc.openDefaultMessage(EAppAlertTypes.Error)("Se a opção de Trackeamento estiver marcada preencha os dois campos abaixo de Trackeamento");
                return;
            }
        }

        const savedElements: INonSerializable[] = await this.bpmSaveHostedSvc.saveBot({
            element: this.hostedEntity as IBotRootServer,
            idGraphRoot: this.bpmSvc.getCurrentStrategy().getGraphRulesProcessor()?.getRootElementId()
        });

        this.hasSaved = isValidArray(savedElements); //!!savedElements?.[0];

        if (this.hasSaved) {

            const graphElement = this.bpmSvc.getCurrentStrategy().getGraphRulesProcessor().findNodeElementByHostedId(this.ns.idNS);

            graphElement?.setName(this.ns.nName);

            const [self, ...othersSavedElements] = savedElements;
            this.hostedEntity.lastTouch = self.lastTouch;

            this.bpmSvc.getCurrentStrategy().updateAllNodesHostedObjects([self, ...othersSavedElements]);
        }

        // console.log("🚀 ~ file: bpm-graph-element-info.component.ts ~ line 182 ~ BpmGraphElementInfoComponent ~ onSaveElement ~ this.hostedEntity", this.hostedEntity)
        if (!this.hasSaved) {
            return this.handler.getDialogRef().close({ userHasClickedSave: false, nonSerializable: undefined });
        }

        if ((this.hostedEntity as INextGenBotServer).botLevel === ENextGenBotElementType.root && !this.bpmSvc.getCurrentStrategy().hasRoot()) {
            const graphRootJSON: IBasicElementClient = BasicElementInterfaceFactory.createEmptyRoot();
            graphRootJSON.element.idHostedObject = this.hostedEntity.idNS;
            graphRootJSON.nName = this.hostedEntity.nName;
            // addTagToNS(graphRootJSON, this.sessionSvc.getAvatarID(), this.dashboardSvc.defaultTag, []);

            const elements: TIBasicElementServerArray = await this.bpmApiService.persistGraphElementsByJSON(
                EBPMType.bot,
                [graphRootJSON]
            );
            const rootElementSaved: IBasicElementServer = elements[0];
            this.bpmViewerSvc.storeBpmGraphRoot(rootElementSaved);

            this.routingSvc.goToRootGraphElement(
                rootElementSaved.idNS,
                EBPMType.bot,
                false,
                rootElementSaved.element.idHostedObject,
                true,
            );
        }

        this.handler.getDialogRef().close({ userHasClickedSave: this.hasSaved, nonSerializable: this.hostedEntity });
    }

    isCreateMode(): boolean {
        return this.parameters.workElement.mode === EBpmGraphElementInfoMode.Create;
    }

    isEditMode(workElement: IBpmGraphElementInfoEntity): workElement is IBpmGraphElementInfoEntityEdit {
        return this.parameters.workElement.mode === EBpmGraphElementInfoMode.Edit
    }

    canShowDebugInfo() {
        return this.hostedEntity && environment.production == false
    }

    async copyID() {
        await window.navigator.clipboard.writeText(this.ns.idNS);

        this.snackSvc.open({
            message: `Copiado`,
            duration: 2000
        });
    }

    private getCopyGraphElementIDOption(graphElement: GraphElement): INSMoreOptionsCustomOption {
        return {
            label: 'Copiar ID do BPM',
            matIcon: 'file_copy',
            callback: () => {
                copyToClickBoardSync(graphElement.getGraphElementID());

                this.snackSvc.open({
                    duration: 2000,
                    type: EAppAlertTypes.Success,
                    message: 'Copiado para clipboard'
                });
            }
        };
    }

    private getAddToPathOption(graphElement: GraphElement): INSMoreOptionsCustomOption {
        return {
            label: 'Adicionar ao patch',
            matIcon: 'cloud_upload',
            callback: () => {
                this.patchSvc.add([
                    {
                        nsType: graphElement.getNserType(),
                        idNS: graphElement.getGraphElementID(),
                        nName: graphElement.getName(),
                        envType: EEnvironmentType.local,
                        operationType: EMigrationOperationType.create
                    }
                ]);
            }
        };
    }
}
