import { I360Media, T360MediaArray } from "../../../core-constants/bot";
import { IBotActionAsset } from "../../bot/bot-action-model";
import { NSGenericHost } from "../../graph-transaction/host/ns/ns.host";
import { IAddedConnection, INodeDeletedConnection } from "../../graph-transaction/host/ns/ns.host.types";
import { TGraphActionContainer, TReferencedObject, TReferencedObjectArray } from "../../graph-transaction/toolbar/config-toolbar.types";
import { INonSerializable } from "../../non-serializable-id/non-serializable-id-interfaces";
import { GraphElement } from "./graph-element";
import { TGraphPropertyArray, TGraphPropertyData, TIGraphConnectionDataArray } from "./graph-types";
import { Property } from "./property";

export interface IBPMScreenEvents {
    onDragIntoMe(hostObject: IHostObjectInterface): void;
    onDragged(host: IHostObjectInterface): void;
}
export interface IHostObjectInterface extends IBPMScreenEvents {
    isDummy(): boolean;
    getHostedObject(): Object;
    getHostedID(): string;
    getMedias(): T360MediaArray;
    getHostedMedia(): I360Media;
    getHostedType(): string;
    getHostedSubType(): TReferencedObject;
    getHostedName(): string;
    getHostedValue(): TGraphPropertyData;
    isEditable(element: GraphElement): boolean;
    addProperty(property: Property): void;
    getPropertyArray(): TGraphPropertyArray;
    toJSON(): Object;
    rehydrate(json: Object): void;
    isDraggableIntoMe(nodeTypeToDrag: TReferencedObject, edgesToOthersCount: number): boolean;
    onRemove(hostObject: IHostObjectInterface): void;
    isPredicateDrawable(targetType: TReferencedObject, action: TReferencedObject): boolean;
    canConnectTo(toType: TReferencedObject): boolean;
    getActionContainer(): TGraphActionContainer;
    setAction(newMenuItemActionAsset: IBotActionAsset): void;
    isActionAvailableByConnectingManuallyToOtherNode(): boolean;
    getActionAvailableByConnectingManuallyToOtherNode(): TReferencedObject;
    getSequencialPosition(): number | null,
    deleteConnectionTo(anotherHost: NSGenericHost, connectionType: TReferencedObject): INodeDeletedConnection;
    addElementAction(type: TReferencedObject, hostedId: string): void;
    addConnectionTo(target: NSGenericHost, predicateType: TReferencedObject): IAddedConnection;
    mustUpdateConnections(connectionType: TReferencedObject): boolean;
    getConnectionTargetData(isExternal?: boolean): TIGraphConnectionDataArray
}



export abstract class HostObject implements IHostObjectInterface {
    private object: Object;
    private properties: TGraphPropertyArray;

    constructor(object: Object) {
        this.object = object;
        this.properties = [];
    }

    public setHostedObject(ns: INonSerializable) {
        this.object = ns
    }
    public getHostedObject<T extends Object>(): T { return <T>this.object };
    public isDummy(): boolean { return false; };

    public abstract addElementAction(type: TReferencedObject, hostedId: string): void;
    public abstract mustUpdateConnections(connectionType: TReferencedObject): boolean;
    public abstract getConnectionTargetData(isExternal?: boolean): TIGraphConnectionDataArray;
    public abstract isActionAvailableByConnectingManuallyToOtherNode(): boolean;
    public abstract getActionAvailableByConnectingManuallyToOtherNode(): TReferencedObject;
    public abstract isPredicateDrawable(targetType: TReferencedObject, action: TReferencedObject): boolean;
    public abstract isParenthoodPredicateDrawable(targetType: TReferencedObject): boolean;
    public abstract isActionPredicateDrawable(targetType: TReferencedObject, action: TReferencedObject): boolean;
    public abstract getPredicateTypesFromState(): TReferencedObjectArray;
    public abstract deleteConnectionTo(anotherHost: NSGenericHost, connectionType: TReferencedObject, subElementId?: string): INodeDeletedConnection;
    public abstract addConnectionTo(target: NSGenericHost, predicateType: TReferencedObject): IAddedConnection;
    public abstract getHostedID(): string;
    public abstract getMedias(): T360MediaArray;
    public abstract getHostedMedia(): I360Media;
    public abstract getHostedType(): string;
    public abstract getHostedSubType(): TReferencedObject;
    public abstract getHostedName(): string;
    public abstract getHostedValue(): TGraphPropertyData;
    public abstract toJSON(): Object;
    public abstract rehydrate(json: Object): void;
    public abstract isDraggableIntoMe(nodeTypeToDrag: TReferencedObject, edgesToOthersCount: number): boolean;
    public abstract onDragIntoMe(hostObject: IHostObjectInterface): void;
    public abstract onRemove(hostObject: IHostObjectInterface): void
    public abstract isEditable(element: GraphElement): boolean;
    public addProperty(property: Property) { this.properties.push(property) };
    public getPropertyArray(): TGraphPropertyArray { return this.properties; };
    public abstract onDragged(host: IHostObjectInterface): void;
    // ATENÇÃO ISSO É ABSTRACT
    public externalChangeData(dragedHosted: Object, aux: Object): void {
        // faz as altracaoes
        // will change what need to be changed mainly in the graph itself
    }
    public abstract getSequencialPosition(): number | null;
    public abstract handleRemoveSubTree(element: GraphElement): INonSerializable[];
    public abstract getActionContainer(): TGraphActionContainer;
    public abstract canConnectTo(toType: TReferencedObject): boolean;
    public abstract setAction(newMenuItemActionAsset: IBotActionAsset): void;

    // public abstract is(host: Object): boolean;
    // public abstract iss(idHost: string): boolean;
};
