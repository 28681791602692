import { TAnalyticsBiReportItem, IAnalyticsBiReportItemServer } from '@colmeia/core/src/shared-business-rules/analytics-bi-report-item/analytics-bi-report-item.model';
import { TGlobalUID, TPostgresPrimaryKey } from '../core-constants/types';
import { IGeneralFormAnswerServer, TGeneralFieldArray } from '../general-form/general-form-answer';
import { EJobStatus, EJOBType, IJobCampaignExecuteLastPredicates, IJobDeleteAvatarFromSocialNetworkData, IJobDeleteAvatarFromSocialNetworkServer, IJobFileUploadProcessorData, IJobFileUploadProcessorServer, IJobMachineLearningProcessingServer, IJobPatchServer, IJobProcessElasticSearchDatabaseData, IJobProcessElasticSearchDatabaseServer, IJobServer, TMapAllJobTypes } from '../shared-business-rules/jobs/jobs-model';
import { TComputedInfo } from '../shared-business-rules/metadata/metadata-utils';
import { EEnvironmentType, EMigrationOperationType, ENonSerializableObjectType, INonSerializable, INonSerializableHeader, INonSerializableIdentification, NonSerializable } from '../shared-business-rules/non-serializable-id/non-serializable-id-interfaces';
import { NsTypeToInterface } from '../shared-business-rules/non-serializable-id/non-serializable-interface-mapper';
import { IDeployedItemProductionServer, IDeployedProductionServer, IPatchItemServer, IProdPatchModelServer } from '../shared-business-rules/production-deploy/prod-deploy-model';
import { INewDeployProdDesription } from '../shared-business-rules/production-deploy/prod-deploy-req-res';
import { getClock, getReadableUniqueID } from '../tools/utility';
import { Differences, SimpleDifferences, cast } from '../tools/utility-types';
import { IntersectPartialExplicit } from '../tools/utility/types';

export function initNserClientNoCheck<NsType extends ENonSerializableObjectType, Source extends {}>(
    nsType: NsType,
    name: string,
    source: Source
): NsTypeToInterface[NsType] {
    return {
        ...getBlankNonSerializableHeader(name, false),
        ...source,
        nsType,
    } as unknown as NsTypeToInterface[NsType];
}

export function initNserClient<NsType extends ENonSerializableObjectType>(
    nsType: NsType,
    name: string,
    source: Omit<SimpleDifferences<INonSerializable, NsTypeToInterface[NsType]>, keyof Pick<INonSerializable, 'nsType'>>
): NsTypeToInterface[NsType] {
    return {
        ...getBlankNonSerializableHeader(name, false),
        ...source,
        nsType,
    } as unknown as NsTypeToInterface[NsType];
}

export function initNserServer<NsType extends ENonSerializableObjectType, Nser extends NsTypeToInterface[NsType]>(
    nsType: NsType,
    name: string,
    source: Omit<SimpleDifferences<INonSerializable, Nser>, keyof Pick<INonSerializable, 'nsType'>>
): Nser {
    return {
        ...getBlanckNoIdentifiedNSHelper(nsType, name, false),
        ...source,
        nsType,
    } as unknown as Nser;
}

export function getBlankNonSerializableHeader(nName: string, isVirtual: boolean): INonSerializableHeader {
    return {
        nsType: undefined,
        isVirtual,
        nName,
    }
}


export function getBlanckNoIdentifiedNSHelper(nsType: ENonSerializableObjectType, nName: string, isVirtual: boolean): INonSerializable {
    const now: number = getClock();
    return {
        idNS: getReadableUniqueID(),
        isVirtual,
        ident: undefined,
        nsType,
        nName,
        clockTick: now,
        lastTouch: now,
    };
}

export function getBlanckNoIdentifiedNS<NSType extends ENonSerializableObjectType>(nsType: NSType, nName: string, isVirtual: boolean): NonSerializable<NSType>
export function getBlanckNoIdentifiedNS(nsType: ENonSerializableObjectType, nName: string, isVirtual: boolean): INonSerializable {
    return getBlanckNoIdentifiedNSHelper(nsType, nName, isVirtual);
}

export function getIdentifiedNS<NSType extends ENonSerializableObjectType>(nsType: NSType, nName: string, ident: INonSerializableIdentification): NonSerializable<NSType> {
    return {
        ...getBlanckNoIdentifiedNS(nsType, nName, false),
        ident
    }
}


export function getBlankPatchItem(idParent: string, idNSToBeDeployed: string,
    itemNsType: ENonSerializableObjectType, name: string,
    ident: INonSerializableIdentification, envType: EEnvironmentType, operationType: EMigrationOperationType,
    versionName: string, versionID: TPostgresPrimaryKey
): IPatchItemServer {
    return {
        ...getIdentifiedNS(ENonSerializableObjectType.patchItem, name, ident),
        idParent, idDeployedNSItem: idNSToBeDeployed, itemNsType, notes: '',
        versionName,
        envType,
        operationType,
        versionID
    }
}


export function getBlankDeployProduction(patchHeader: IProdPatchModelServer, newDeployDescription: INewDeployProdDesription, ident: INonSerializableIdentification): IDeployedProductionServer {
    return {
        ...getIdentifiedNS(ENonSerializableObjectType.deployHeader, newDeployDescription.name, ident),
        description: newDeployDescription.description,
        patchHeader,
    }
}

export function getBlankDeployItem(idParent: string, name: string, ident: INonSerializableIdentification, rollback: INonSerializable, toProd: INonSerializable): IDeployedItemProductionServer {
    return {
        ...getIdentifiedNS(ENonSerializableObjectType.deployProdItem, name, ident),
        idParent, rollback, toProd, toBeDeleted: []
    }
}

export function getFormServerAnswer(idSchemma: string, answer: TComputedInfo, resp: TGeneralFieldArray): IGeneralFormAnswerServer {
    return {
        ...getBlanckNoIdentifiedNS(ENonSerializableObjectType.clientGeneralAnswer, 'answ', true),
        idSchemma,
        json: answer,
        responses: resp,
        primaryID: undefined
    }
}

export function getNewAnalyticsBIItem(header: TAnalyticsBiReportItem, ident: INonSerializableIdentification): IAnalyticsBiReportItemServer {
    return {
        ...getIdentifiedNS(ENonSerializableObjectType.analyticsBiReportItem, header.reportId, ident),
        reportData: header
    }
}

export function getJob(description: string, ident: INonSerializableIdentification, idPlayer: TGlobalUID): IJobServer {
    return {
        ...getIdentifiedNS(ENonSerializableObjectType.job, description, ident),
        nsType: ENonSerializableObjectType.job,
        status: EJobStatus.tobestarted,
        scheduleAt: 0,
        started: 0,
        ended: 0,
        jobType: undefined,
        lastJobTouch: getClock(),
        phaseName: 'Será iniciado',
        isError: false,
        error: undefined,
        idPlayerStartedList: [idPlayer],
        isJobStillInProgress: true,
    }
}

export function getNewJobToBeReescheduled(currentJob: IJobServer, scheduleAt: number): IJobServer {
    return {
        ...currentJob,
        status: EJobStatus.tobestarted,
        started: 0,
        ended: 0,
        lastJobTouch: getClock(),
        phaseName: 'to be started',
        isError: false,
        error: undefined,
        isJobStillInProgress: true,
        scheduleAt,
    }
}

export function getPatchJob(idPatch: string, idProvider: string, description: string, ident: INonSerializableIdentification, idPlayer: TGlobalUID): IJobPatchServer {
    return {
        ...getJob(description, ident, idPlayer),
        idPatch,
        idProvider,
        jobType: EJOBType.patchApply,
        newDeployDescription: {
            name: '',
            description: ''
        }
    }
}

export function getJobMachineLearning(idKB: string, description: string, ident: INonSerializableIdentification, idPlayer: TGlobalUID): IJobMachineLearningProcessingServer {
    return {
        ...getJob(description, ident, idPlayer),
        jobType: EJOBType.machineLearningTrainingProcessing,
        idKB,
    }
}

export function initSpecificJob<JOBType extends EJOBType>(jobType: JOBType, description: string, ident: INonSerializableIdentification, idPlayer: TGlobalUID, diff: SimpleDifferences<IntersectPartialExplicit<IJobServer, { jobType: JOBType }>, TMapAllJobTypes[JOBType]>): TMapAllJobTypes[JOBType] {
    return {
        ...getJob(description, ident, idPlayer),
        jobType,
        ...diff,
    } as IJobServer as TMapAllJobTypes[JOBType];
}


export function getJobCampaignExecute(idKB: string, description: string, ident: INonSerializableIdentification, idPlayer: TGlobalUID) {
    return {
        ...getJob(description, ident, idPlayer),
        jobType: EJOBType.campaignExecute,

    }
}
export function initJobCampaignExecuteLastPredicates(override: Partial<IJobCampaignExecuteLastPredicates> = {}): IJobCampaignExecuteLastPredicates {
    const lastPredicates: IJobCampaignExecuteLastPredicates = {
        isFailure: false,
        amountProcessed: 0,
        tries: 0,
        failures: 0,
        failureTries: 0,
        ...override,
    }
    return lastPredicates;
}


export function getAvatarDeleteFromSocialNetworkJob(description: string, ident: INonSerializableIdentification, idPlayer: TGlobalUID,
    jobData: IJobDeleteAvatarFromSocialNetworkData): IJobDeleteAvatarFromSocialNetworkServer {
    return {
        ...getJob(description, ident, idPlayer),
        jobType: EJOBType.deleteAvatarFromSocialNetwork,
        ...jobData,
    }
}

export function getFileUploadProcessorFileJob(
    description: string, ident: INonSerializableIdentification,
    idPlayer: TGlobalUID, jobData: IJobFileUploadProcessorData
): IJobFileUploadProcessorServer {
    return {
        ...getJob(description, ident, idPlayer),
        jobType: EJOBType.fileUploadProcessor,
        ...jobData,
    }
}

export function createJobServerObj<HE>(
    jobType: EJOBType, description: string, ident: INonSerializableIdentification,
    idPlayer: TGlobalUID, jobData: HE
): IJobServer {
    return {
        ...getJob(description, ident, idPlayer),
        jobType,
        ...jobData,
    }
}

export class JobServerFactory {
    public create(jobType: EJOBType) {

    }
}
