<div 
  class="ordenable-list"
  [ngClass]="[hideBorderClass, smallSizeClass]">

  <div 
    class="ordenable-item ordenable-header"
    [ngClass]="[hideBorderClass, smallSizeClass]">
    <div 
      class="ordenable-item-content title-row"
      [ngClass]="[hideBorderClass, smallSizeClass]">
      <div 
        class="row-name"
        [ngClass]="[hideBorderClass, smallSizeClass]">
        {{ isSmallSize ? 'Clique aqui para adicionar ' : translations.variables }}
      </div>
    </div>
    <div 
      class="ordenable-item-actions"
      [ngClass]="[hideBorderClass, smallSizeClass]">
      <app-tag-picker 
        *ngIf="tagPickerHandler" 
        [handler]="tagPickerHandler"
        class="tag-picker"
        matTooltip="Adicionar"
        [ngClass]="[hideBorderClass, smallSizeClass]">
      </app-tag-picker>
    </div>
  </div>

  <hr 
    *ngIf="shouldRenderBorderless && !isSmallSize"
    class="custom-hr">

  <div 
    class="items-container nice-scroll"
    [ngClass]="[hideBorderClass, smallSizeClass]">
    <div 
      *ngFor="let variable of selectedVariableTags" 
      class="ordenable-item"
      [ngClass]="[hideBorderClass, smallSizeClass]">

      <ng-container
        *ngIf="!shouldRenderBorderless">
        <div 
          class="ordenable-item-content">
          <div class="row-name">{{ variable.nName }}</div>
          <div class="row-value">{{ variable.value }}</div>
        </div>
        <div class="ordenable-item-actions">
          <button *ngIf="canCopyVariable" mat-icon-button color="primary" matTooltip="Copiar" (click)="copyVariable(variable)">
            <mat-icon>file_copy</mat-icon>
          </button>
    
          <button mat-icon-button color="primary" matTooltip="Remover" (click)="removeTag(variable)">
            <mat-icon
              class="delete-icon">delete</mat-icon>
          </button>
        </div>  
      </ng-container>

      <ng-container
        *ngIf="shouldRenderBorderless">
        <div 
          class="ordenable-item-content"
          [ngClass]="[hideBorderClass, smallSizeClass]">
          <div
            class="name-and-actions-wrapper">
            <div class="row-name">{{ variable.nName }}</div>
            <div 
              class="ordenable-item-actions"
              [ngClass]="[hideBorderClass, smallSizeClass]">
              <button 
                *ngIf="canCopyVariable"
                class="action-button small-touch-area" 
                mat-icon-button color="primary" 
                matTooltip="Copiar" 
                (click)="copyVariable(variable)"
                [ngClass]="[hideBorderClass, smallSizeClass]">
                <mat-icon
                  class="copy-icon"
                  [ngClass]="[hideBorderClass, smallSizeClass]">file_copy</mat-icon>
              </button>
        
              <button 
                mat-icon-button 
                color="primary"
                class="action-button delete-button small-touch-area" 
                matTooltip="Remover" 
                (click)="removeTag(variable)"
                [ngClass]="[hideBorderClass, smallSizeClass]">
                <mat-icon
                  class="delete-icon"
                  [ngClass]="[hideBorderClass, smallSizeClass]">delete</mat-icon>
              </button>
            </div>  
          </div>
          <div class="item-padding-wrapper" [ngClass]="[hideBorderClass, smallSizeClass]">
            <div 
              class="row-value"
              [ngClass]="[hideBorderClass, smallSizeClass]">{{ variable.value }}</div>
          </div>
        </div>
      </ng-container>
      
    </div>
  </div>
  

</div>
