import { Injectable } from '@angular/core'
import { MatDialogRef } from '@angular/material/dialog'
import { constant } from '@colmeia/core/src/business/constant'
import { PlayerCachedInfo } from '@colmeia/core/src/business/player-cached'
import { IInteractionJSON } from "@colmeia/core/src/comm-interfaces/interaction-interfaces"
import { socketConfig } from '@colmeia/core/src/core-constants/socket.conf'
import { TGlobalUID } from '@colmeia/core/src/core-constants/types'
import { IVersionWarning } from '@colmeia/core/src/core-constants/version-control'
import {
    ELocalQueueName,
    IClientSemaphoreConfig,
    ILocalQueuEntry
} from '@colmeia/core/src/core-queue/core-queue-service'
import { Interaction } from '@colmeia/core/src/interaction/interaction'
import {
    EColmeiaSource,
    ISingleInteractionRequest,
    ISocketPlayerConnectedRequest
} from "@colmeia/core/src/request-interfaces/request-interfaces"
import {
    EArakiriReason,
    IConfirmationInfo, IConfirmReadMessage, IKillOtherMachines, IOnlineConfirmationMessage,
    IResponse,
    ISentInteraction,
    ISignUpResponse,
    ISocketInteractionClientComm,
    ISocketInteractionResponse, ISocketReadyEvent, ISocketReceiveConfirmation, TConfirmationInfoArray
} from "@colmeia/core/src/request-interfaces/response-interfaces"
import { getSessionIdentifier } from '@colmeia/core/src/rules/aux-function'
import { TNotificationPayload } from '@colmeia/core/src/shared-business-rules/new-notifications/new-notification-model'
import { ISendNotificationsSocketResponse } from '@colmeia/core/src/shared-business-rules/new-notifications/new-notification-req-res'
import { minToMS } from '@colmeia/core/src/time/time-utl'
import { delay, getClock, getUniqueStringID, isValidArray, isValidRef, printObject } from '@colmeia/core/src/tools/utility'
import { createServiceLogger } from 'app/model/client-utility'
import { isDevEnvironment } from 'app/model/is-dev-env';
import { environment } from 'environments/environment-client'
import { Observable, ReplaySubject, Subject } from 'rxjs'
import { filter, first, skip } from 'rxjs/operators'
import { io, Socket } from 'socket.io-client'
import { clientConstants } from "../model/constants/client.constants"
import { ISystemLogoutListener } from "../model/signal/ps-interfaces"
import { SocketReadySignal } from "../model/signal/socket-ready-sign"
import { AuthService } from './auth/auth.service'
import { ColmeiaDialogService } from './dialog/dialog.service'
import { EmbeddedChatService } from './embedded-chat.service'
import { GroupChatServices } from "./group-chat.services"
import { HardwareLayerService } from "./hardware"
import { QueuService } from "./queue.service"
import { RecoverySystemService } from "./recovery-system.service"
import { RequestBuilderServices } from "./request-builder.services"
import { SempaphoreService } from "./semaphore-service"
import { SessionService } from "./session.service"
import { SignalListenerService } from "./signal/signal-listener"
import { SignalPublisherService } from "./signal/signal-publisher"
import { SocketFunctionsService } from './socket-functions.service'


interface IClientSessionInfo {
    idPlayer: TGlobalUID
    response: ISignUpResponse
    cached: PlayerCachedInfo
};


const semaphoreBatchSend: IClientSemaphoreConfig = {
    expirationLockTime: 5000,
    numberOfTries: 0,
    throwErrorIfNotLocked: false,
    retryInterval: 300
}

const semaphoreBatchDispatcher: IClientSemaphoreConfig = {
    expirationLockTime: minToMS(2),
    numberOfTries: 3,
    throwErrorIfNotLocked: false,
    retryInterval: 300
}


const waitSemaphore: IClientSemaphoreConfig = {
    expirationLockTime: 6000,
    numberOfTries: 5,
    throwErrorIfNotLocked: false,
    retryInterval: 1000
}

function getM(interaction: IInteractionJSON): string {
    return interaction ? (interaction.primaryID + ' ' + Interaction.getJText(interaction, constant.serializableField.chat_text) + ' ' + interaction.idInteractionType)
        : ' no Int'
}
interface IClientQueue {
    idQueue: string
    idPlayer: string
}

@Injectable({ providedIn: 'root' })
export class SocketService implements ISystemLogoutListener {
    private log = createServiceLogger('SocketService', '#c37bea');

    private socket: Socket;
    private emmitedLoginSignal: boolean = false
    private sendControlDB: { [idInteraction: string]: boolean } = {}
    private sessionService: SessionService
    private dialogService: ColmeiaDialogService
    private socketFunctions: SocketFunctionsService
    private rbs: RequestBuilderServices
    private hw: HardwareLayerService
    private recoveryServices: RecoverySystemService
    private idClientQueue: string
    private connRequest: ISocketPlayerConnectedRequest
    private testConnResult: boolean
    private clientSessionInfo: IClientSessionInfo
    private lastClockReceivedInteraction: number
    private dialogRef: MatDialogRef<any, any>
    private authService: AuthService
    private newNotifications$: Subject<TNotificationPayload> = new Subject<TNotificationPayload>()
    private _lastSocketConnStatus$: Subject<string> = new ReplaySubject(1);
    private socketStateEvents: string[] = [
        "disconnect",
        "restored",
        "connected",
        "reconnecting",
        "socketready"
    ];
    public socketRefresher?: () => unknown;

    constructor(
        private emissor: SignalPublisherService,
        private listener: SignalListenerService,
        private semaphore: SempaphoreService,
        private chatsvs: GroupChatServices,
        private queueService: QueuService,
        private embeddedChatSvc: EmbeddedChatService,
        private hardwareSvc: HardwareLayerService,
    ) {
        this.listener.listenToLogoutEvent(this);

        this.listenVisibilityChange();
    };

    listenVisibilityChange() {
        this.hardwareSvc.onVisibilityChange().subscribe((focusInfo) => {
            return this.emit(socketConfig.socketEventNames.receive_focus_info, focusInfo)
        });
    }

    getNewNotificationsListener(): Observable<TNotificationPayload> {
        return this.newNotifications$.asObservable()
    }

    public get socketReconnect$(): Observable<string> {
        return this.socketConnStatusChange$.pipe(filter(e => e === "restored"));
    }

    public get socketDisconnect$(): Observable<string> {
        return this.socketConnStatusChange$.pipe(filter(e => e === "disconnect"));
    }
    public get socketConnStatusChange$(): Observable<string> {
        return this.lastSocketConnStatus$.pipe(skip(1));
    }

    public get lastSocketConnStatus$(): Observable<string> {
        return this._lastSocketConnStatus$.asObservable();
    }

    private gtrack(event: string, sign: string = '-', interaction: IInteractionJSON = null): void {
        if (this.socketStateEvents.includes(event.toLowerCase())) {
            this._lastSocketConnStatus$.next(event.toLowerCase())
        }

        if (environment.allDevFeatures)
            this.log('SX#' + sign + '  ' + event + '  s: ' + this.idClientQueue + ' p: ' +
                (this.clientSessionInfo ? this.clientSessionInfo.idPlayer : ' no player'))
                + getM(interaction)
    }

    private gItrack(event: string, sign: string = '-', interaction: Interaction = null): void {
        if (environment.allDevFeatures)
            this.log('SX#' + sign + '  ' + event + '  s: ' + this.idClientQueue + ' p: ' +
                (this.clientSessionInfo ? this.clientSessionInfo.idPlayer : ' no player'))
                + (interaction ? (interaction.getInteractionID() + ' ' + interaction.getMessage()) : ' no M')
    }

    private async newSocket(idPlayer: TGlobalUID, response: ISignUpResponse, cached: PlayerCachedInfo): Promise<void> {
        this.gtrack('new socket')
        this.lastClockReceivedInteraction = getClock()

        const isMobile: boolean = this.hw.isMobile()

        const queue: IClientQueue = this.hw.getStorage().getItem<IClientQueue>(socketConfig.client.queuName)
        let lastIDQueue: string = ''

        if (isMobile && queue && queue.idPlayer === idPlayer) {
            this.idClientQueue = queue.idQueue
        } else {
            const queue = this.renewQueueRecord(idPlayer)
            if (isMobile) {
                this.saveRecord(queue)
            };
        };

        const pnToken: string = await this.hw.getPNClientID();
        await this.hw.UID$();

        this.connRequest = {
            ...this.rbs.createRequest(socketConfig.responseTypes.initSocket, idPlayer, null),
            idDeviceQueue: this.idClientQueue,
            token: response.authorization,
            isMobile,
            lastDeviceQueue: lastIDQueue,
            pnToken,
            version: this.hw.getVersion(),
            source: this.embeddedChatSvc.isAnEmbedInstance() ? EColmeiaSource.webchat : EColmeiaSource.app
        };

        this.socket = null

        this.socketRefresher = () => {
            this.socket = io(
                this.getSocketURL(),
                {
                    path: this.getSocketPath(),
                    transports: ['websocket'],
                    reconnection: true,
                    ...socketConfig.client.socketConfig,
                    query: { data: JSON.stringify(this.connRequest) }
                }
            );

            this.clientSessionInfo = { idPlayer, response, cached }

            const manager = this.socket.io
            manager.reconnectionDelay(socketConfig.client.socketConfig.reconnectionDelay)
            manager.timeout(socketConfig.socketTimeout)

            this.socketClientServer(idPlayer, response, cached)
        };

        this.socketRefresher();
    };

    private getSocketPath() {
        return this.embeddedChatSvc.isAnEmbedInstance() ? socketConfig.clienConnection.bot : socketConfig.clienConnection.server;
    }

    /**
     * Environment do embed é diferente do cliente normal portanto o BOT_URL e o ROOT_URL são distintos.
     */
    private getSocketURL(): string {
        return this.getBotURL() ?? environment.ROOT_URL;
    }

    private getBotURL(): string | undefined {
        if (isDevEnvironment() && this.embeddedChatSvc.isAnEmbedInstance()) return environment.BOT_URL;
    }

    public refreshSocket() {
        this.reset();
        this.socketRefresher?.();
    }

    private renewQueueRecord(idPlayer: TGlobalUID): IClientQueue {
        this.idClientQueue = getSessionIdentifier(idPlayer, this.hw.isMobile())
        const queue: IClientQueue = { idQueue: this.idClientQueue, idPlayer: idPlayer }
        return queue
    };

    private saveRecord(queue: IClientQueue): void {
        this.hw.getStorage().putItem(socketConfig.client.queuName, queue)

    }

    public startSocket(idPlayer: TGlobalUID, response: ISignUpResponse, cached: PlayerCachedInfo): void {
        this.newSocket(idPlayer, response, cached)
    };

    /// Basic SEND Operactions

    public sendSocketInteraction(interaction: Interaction): void {
        const sendInteraction: ISingleInteractionRequest = this.rbs.getInteractionRequest(interaction, null)
        this.emitSocketData(interaction, sendInteraction)
    };

    public async sendControlledSocketInteraction(interaction: Interaction, controll: ELocalQueueName): Promise<void> {
        this.addToControlDB(interaction.getPrimaryID())
        this.sendSocketInteraction(interaction)
    };

    public async sendJSONInteraction(interaction: IInteractionJSON): Promise<void> {
        this.addToControlDB(interaction.primaryID)
        const sendInteraction: ISingleInteractionRequest = this.rbs.getJSONInteractionRequest(interaction, null)
        this.emit(socketConfig.socketEventNames.sendDataToServer, sendInteraction)
    }

    public addToControlDB(idInteraction: TGlobalUID): void {
        this.sendControlDB[idInteraction] = true
    };

    public isOnControlDB(idInteraction: TGlobalUID): boolean {
        return isValidRef(this.sendControlDB[idInteraction])
    };

    public removeFromControlDB(idInteraction: TGlobalUID): void {
        delete this.sendControlDB[idInteraction]
    };


    // EMIT DE SOCKET
    private async emitSocketData(interaction: Interaction, requestJson: ISingleInteractionRequest): Promise<void> {
        interaction.dataSent()
        this.emit(socketConfig.socketEventNames.sendDataToServer, requestJson)
    };

    private emit(event: string, data: any): void {
        if (this.socket) {
            this.socket.emit(event, data)
        }
    }


    private async dispatchIncommingMessage(message: ISocketInteractionResponse): Promise<void> {
        const text: string = Interaction.getJText(message.interaction, constant.serializableField.chat_text)
        await this.socketFunctions.handleSocketMessage(message)
    };

    private async dispatchBatchIncomming(message: ISocketInteractionClientComm): Promise<void> {
        message.messages.forEach((message) => {
            this.queueService.enqueue(ELocalQueueName.batchDeliveryIncomingMessage, { data: message, id: message.idReceiverIdentifier })
        })
        this.semaphore.runClientWithLock(async () => {
            let enqeued: ILocalQueuEntry
            do {
                enqeued = this.queueService.getNextEntry(ELocalQueueName.batchDeliveryIncomingMessage)
                if (enqeued) {
                    this.dispatchIncommingMessage(<ISocketInteractionResponse>enqeued.data)
                    await delay(clientConstants.delays.refreshUI)
                }

            } while (enqeued)

        }, this.idClientQueue, semaphoreBatchDispatcher)

    }


    /////// BASIC OPERATIONS


    public emmitLoginSignalAgain(): void { this.emmitedLoginSignal = false };
    public receiveLogoutEventCallback(): void { this.emmitedLoginSignal = false }
    public reset(): void {
        this.gtrack('Commanded Disconnection', '!')
        this.disconnect()
    };

    public disconnect(): void {
        if (this.socket) {
            this.socket.disconnect()
            this.socket = null
        };
    };

    private async testConnection(): Promise<boolean> {
        try {
            this.testConnResult = false
            this.sendAliveMessage()
            await delay(socketConfig.client.forceRecover.waitForMessage)
            return this.testConnResult
        } catch (err) { }
        return false
    };

    private async sendAliveMessage(): Promise<void> {
        try {
            this.emit(socketConfig.socketEventNames.testServer.toServer, 'ping')
        } catch (err) {
            this.log(err)
        }
    };

    public async sendConfirmation(confirmation: IConfirmationInfo): Promise<void> {
        this.queueService.enqueue(ELocalQueueName.readConfirmation, { data: confirmation, id: getUniqueStringID(5) })

        this.semaphore.runClientWithLock(async () => {
            await delay(socketConfig.client.receiveParameters.accumulatorWait)
            let confirmations: TConfirmationInfoArray = []
            do {
                confirmations = this.queueService.getFirstXElements(ELocalQueueName.readConfirmation, socketConfig.client.receiveParameters.batchSize)
                    .map((e) => { return <IConfirmationInfo>e.data })
                this.sendReadConfirmation(confirmations)
                await delay(socketConfig.client.receiveParameters.waitBeforeNewBatch)

            } while (isValidArray(confirmations))

        }, ELocalQueueName.readConfirmation, semaphoreBatchSend)
    };

    public sendReadConfirmation(confirmations: TConfirmationInfoArray): void {
        if (isValidArray(confirmations)) {
            const read: IConfirmReadMessage = {
                idPlayer: this.sessionService.getPlayerID(),
                confirmations
            }
            this.log(printObject(read))
            this.emit(socketConfig.socketEventNames.readConfirmation, read)
        }
    }

    private forceSyncFromServer() {
        this.emit(socketConfig.socketEventNames.forceSynch, null)
    };



    ///////////////////
    ///// Server //////
    ///////////////////
    private socketClientServer(idPlayer: TGlobalUID, response: ISignUpResponse, cached: PlayerCachedInfo): void {

        // Server sends me every info to socket stream which will be caught by socket functions service
        this.socket.on(socketConfig.socketEventNames.receiveDataFromServer, async (message: IResponse, serverCallback: Function) => {
            if (serverCallback) {
                serverCallback(this.getReceiveConfirmation(message, message.responseType))
            };
            this.lastClockReceivedInteraction = getClock()
            if (message.responseType === socketConfig.responseTypes.batchSentInteraction) {
                this.dispatchBatchIncomming(<ISocketInteractionClientComm>message)

            } else {
                this.dispatchIncommingMessage(<ISocketInteractionResponse>message)
            }

        })


        this.socket.on("reconnecting", () => {
            this.gtrack('Reconnecting', 'E');
        });

        // disconnected
        this.socket.on(socketConfig.socketEventNames.disconnect, async (aux, aux2) => {
            this.gtrack('Disconnect', 'E')
            this.emmitedLoginSignal = false
            this.hw.setSocketConnectionOnline(false)
        })

        // Fired upon a successful reconnection.
        this.socket.on(socketConfig.socketEventNames.reconnect, async (aux, aux2) => {
            this.gtrack('Restored', 'E')
            this.hw.setSocketConnectionOnline(true)
        })

        // Fired upon a connection including a successful reconnection
        this.socket.on(socketConfig.socketEventNames.connect, async () => {
            this.gtrack('Connected', 'E')
            this.hw.setSocketConnectionOnline(true)
        })

        // when socket client receives connection from server, no need to ack this messge, no rabbit involved
        this.socket.on(socketConfig.socketEventNames.socketReady, async (event: ISocketReadyEvent) => {
            this.gtrack('SocketReady', 'E')
            cached.setClientInfo(event.clientInfo)
            this.hw.setCachedPlayer(cached)
            if (!this.emmitedLoginSignal && !this.sessionService.isStrucuturesReady()) {
                this.gtrack('RedoStrutures', 'E')
                this.emissor.specificSignalEmissorOnGenericStream(new SocketReadySignal(true, response, cached))
            }
            this.emmitedLoginSignal = true
        })



        this.socket.on(socketConfig.socketEventNames.receiveFromSocketConfirmation, async (idInteraction: string) => {
            this.removeFromControlDB(idInteraction)
        })

        this.socket.on(socketConfig.socketEventNames.forceClientSync, async (aux, aux2) => {
            this.gtrack('Server Forced SYNC', '!')
            await this.recoverySession()
        })

        this.socket.on(socketConfig.socketEventNames.versionControl, async (warn: IVersionWarning) => {
            if (this.embeddedChatSvc.isAnEmbedInstance()) return;

            this.hw.getUpdater().update(warn)
            this.gtrack('Server VersionWarning', '!')
        })

        //// Confirmação que usuário está On-Line
        this.socket.on(socketConfig.socketEventNames.onlineConfirmation, (data: IOnlineConfirmationMessage, callback) => {
            this.gtrack('Online Confirmation', '-')
            callback(data)
        })


        this.socket.on(socketConfig.socketEventNames.testServer.toClient, (aux, aux2) => {
            this.testConnResult = true
        })

        this.socket.on(socketConfig.socketEventNames.reconnectFailed, async (aux, aux2) => {
            this.gtrack('Disaster', '!!!!!!')
        })

        this.socket.on(socketConfig.socketEventNames.notificationMessages, async (notificationMessage: ISendNotificationsSocketResponse, callBack) => {
            this.safelyRunCallback(callBack, notificationMessage)
            this.newNotifications$.next(notificationMessage.payload)
            this.log("🚀 ~ file: socket.service.ts ~ line 438 ~ SocketService ~ this.socket.on ~ jobProgress", { jobProgress: notificationMessage })
        })

        this.socket.on(socketConfig.socketEventNames.arakiri, async (arakiri: IKillOtherMachines, callback) => {
            this.safelyRunCallback(callback, arakiri)

            // this.log({ arakirireason: arakiri.reason });
            // if (environment.production === false) {
            //     return
            // }

            if (this.dialogRef) {
                this.disconnect();
                return
            }

            if (isDevEnvironment()) return;

            this.dialogRef = this.dialogService.open({
                title: 'Atenção!',
                contentText: arakiri.reason === EArakiriReason.licenceRestrictedUser ?
                    "O mesmo usuário foi utilizado em outra sessão. Essa sessão será deslogada" :
                    "Por favor, altere sua senha. Prazo de alteração excedido",
                componentRef: undefined
            })
            this.dialogRef.beforeClosed().pipe(first()).subscribe(() => {
                this.dialogRef = undefined
                this.authService.logout()
            })
        })
    };

    private safelyRunCallback(callBack, data) {
        try {
            callBack(data)
        } catch (err) {

        }
    }



    public async checkIFRecevedBySocket(sent: ISentInteraction): Promise<void> {
        this.queueService.enqueue(ELocalQueueName.sendInteractionByAPI, { data: sent, id: sent.idInteraction })
        await delay(socketConfig.client.delay.waitForSocketConfirmation)
        if (this.queueService.removeFromQueue(ELocalQueueName.sendInteractionByAPI, sent.idInteraction)) {
            this.gtrack('Sincronization Begin', '!')
            this.forceSyncFromServer()
        };
    };

    public isSocketOnline(): boolean {
        return isValidRef(this.socket) && !this.socket.disconnected
    }

    private getReceiveConfirmation(response: IResponse, responseType: string): ISocketReceiveConfirmation {
        const resp: ISocketReceiveConfirmation = {
            ackCode: response.ackCode,
            idPlayer: this.clientSessionInfo.idPlayer,
            isOnFocus: this.hw.isFocused(),
            responseType: response.responseType,
            pubGroups: []
        }
        if (responseType === socketConfig.responseTypes.batchSentInteraction) {
            const aux = <ISocketInteractionClientComm>response
            resp.pubGroups = aux.messages.map((p) => { return p.idPubGroup })
        }
        return resp

    }


    private async recoverySession(): Promise<void> {
        try {
            this.gtrack('Sincronization Begin')
            await this.recoveryServices.syncronizeGap(this.idClientQueue)
            //this.sendRabbitRepairMessage();
            this.gtrack('Sincronization End')
        } catch (err) {
        } finally {

        };
    };

    ///////// DEPENDENCIES //////////////
    public setDependSessionService(sessionService: SessionService): void { this.sessionService = sessionService };
    public setDependencyRecoveryService(recovery: RecoverySystemService): void { this.recoveryServices = recovery };
    public setDependSocketFunctions(socketFunctions: SocketFunctionsService): void { this.socketFunctions = socketFunctions }
    public setDependRequestBuilderServices(rbs: RequestBuilderServices): void { this.rbs = rbs }
    public setHardwareServices(hw: HardwareLayerService): void { this.hw = hw };
    public setDialogServices(di: ColmeiaDialogService): void { this.dialogService = di }
    public setAuthService(authService: AuthService): void {
        this.authService = authService
    }
}
