import { INonSerializableHeader, ENonSerializableObjectType, INonSerializable } from '../../non-serializable-id/non-serializable-id-interfaces';
import { IBasicElementJSON } from './graph-interfaces';
import { EBPMType } from '../../BPM/bpm-model';

export type TIBasicElementClientArray = IBasicElementClient[]
export type TIBasicElementServerArray = IBasicElementServer[]

export interface IBasicElementClient extends INonSerializableHeader {
    nsType: ENonSerializableObjectType.graphElement;
    element: IBasicElementJSON;
}
export interface IBasicElementServer extends IBasicElementClient, INonSerializable {
    bpmType: EBPMType;
    nsType: ENonSerializableObjectType.graphElement;
    isGraphRoot?: boolean;
}

export enum EBasicElementRootVersion {
    _1 = '1',
    // IMPORTANT: update the latest version constant bellow after adding a new version
}

export const basicElementRootLatestVersion = EBasicElementRootVersion._1;
