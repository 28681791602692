import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Route, Router, RouterStateSnapshot, UrlSegment } from "@angular/router";
import { TGlobalUID } from '@colmeia/core/src/business/constant';
import { Serializable, TSubMenuControl } from '@colmeia/core/src/business/serializable';
import { TSerializableArray } from '@colmeia/core/src/persistency/uber-cache';
import { apiRequestType } from '@colmeia/core/src/request-interfaces/message-types';
import { EScreenGroups } from '@colmeia/core/src/shared-business-rules/visual-constants';
import { DeepFindValuesOfType } from '@colmeia/core/src/tools/utility-types';
import { mapCurrentDashboardClient } from 'app/model/dashboard/db/dashboard-db-mapper';
import { IMapCurrentDashboardClient } from 'app/model/dashboard/db/dashboard-db-types';
import { routeID, routeList } from "../../model/routes/route-constants";
import { DashBoardService } from '../dashboard/dashboard.service';
import { RoutingService } from "../routing.service";
import { GlobalWarningService } from '../global-warning.service';

const invalidIdAngularRoutePattern: RegExp = /\//;
@Injectable({providedIn: 'root'})
export class AuthGuard  {

    private isUserLogged: boolean = false;
    public mapPrimaryIdToIdMenu: Map<TGlobalUID, string>;
    public mapHasIdMenuAccess: Map<DeepFindValuesOfType<typeof apiRequestType, string>, boolean> = new Map();

    constructor(
        private routingService: RoutingService,
        private dashboardSvc: DashBoardService,
        private router: Router,
        private warningSvc: GlobalWarningService,
    ) {
    }

    /**
     * Checks if we can navigate to route
     * @param  {ActivatedRouteSnapshot} route
     * @param  {RouterStateSnapshot} state
     * @returns boolean
     */
    public async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
        const activatedRoot: ActivatedRouteSnapshot = route.root;

        const firstRootRouteChildPath: string = activatedRoot.root.firstChild.routeConfig.path;
        const isDashboard: boolean = firstRootRouteChildPath === routeList.dashboard.path;

        const isLogged = await this.isAuthorized();

        if (isDashboard && isLogged) {
            const dashboardRoute: string = route.routeConfig.path; // ai, communication ...
            const idAngularRoute: string = route.firstChild.routeConfig.path;

            const { allowedScreenGroupItems, isAllowed } = await this.dashboardSvc.getRouteAccessInfo(
                dashboardRoute, // conversational-commerce
                idAngularRoute, // catalog
            );

            const firstAllowedItem: Serializable = allowedScreenGroupItems[0];

            if (!isAllowed && this.isValidIdAngularRoute(firstAllowedItem?.getAngularRouteID())) {
                const currentMenu = this.getCurrentMenuFriendlyName(dashboardRoute, idAngularRoute)

                this.warningSvc.showWarning(`Você não tem permissão para acessar o menu "${currentMenu}".`)
                this.routingService.navigateToId(routeList.dashboard.path, dashboardRoute, ...firstAllowedItem.getAngularRouteID().split('/'));
            }

            return isAllowed;
        }

        if (!isLogged) {
            this.router.navigate([`${routeID.auth.parent}/${routeID.auth.signIn.id}`], { queryParams: { 'redirectURL': state.url } });
        }

        return isLogged;
    }


    public isValidIdAngularRoute(idAngularRoute: string): boolean {
        if (!idAngularRoute) return false;
        if (invalidIdAngularRoutePattern.test(idAngularRoute)) return false;
        return true;
    }



    public setIsUserLogged(isLogged: boolean): void {
        this.isUserLogged = isLogged;
    }

    /**
     * Checks if we can navigate to child modules
     * @param {ActivatedRouteSnapshot} route
     * @param {RouterStateSnapshot} state
     * @returns boolean
     */
    public async canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
        return this.canActivate(route, state);
    }

    /**
     * Checks if module can be loaded(downloaded from server)
     * @param  {Route} route
     * @returns boolean
     */
    public async canLoad(route: Route, segments: UrlSegment[]): Promise<boolean> {
        return this.isAuthorized();
    }

    //
    private matchesWithDashboard(segments: UrlSegment[]): boolean {
        const notDashboardOnly: boolean = Boolean(segments.length);
        return notDashboardOnly && segments[0].path === (routeList.dashboard.path);
    }

    public getCurrentIdMenu(fakeCurrentDashboard: string, fakeCurrentIdAngularRoute: string): string {
        const screenGroupId: EScreenGroups = this.getTargetScreenGroup(fakeCurrentDashboard);
        const items: TSerializableArray = Serializable.getVisualElementFromScreenGroup(screenGroupId);
        const item: Serializable = items.find(item => item.getAngularRouteID() === fakeCurrentIdAngularRoute);
        this.mapPrimaryIdToIdMenuFromScreenGroupItems(screenGroupId);
        const idMenu: string = this.mapPrimaryIdToIdMenu.get(item.getPrimaryID());
        return idMenu;
    }

    public getTargetScreenGroup(fakeCurrentDashboard: string): EScreenGroups {
        const screenGroup: EScreenGroups = this.dashboardSvc.slowGetDashboardInfoFromCurrentDashboard(fakeCurrentDashboard).screenGroup.name;
        return screenGroup;
    }

    //


    public mapPrimaryIdToIdMenuFromScreenGroupItems(screenGroupId: string): void {
        const subMenus: TSubMenuControl = Serializable.getAllSubMenus(screenGroupId);
        this.mapPrimaryIdToIdMenu = new Map();
        for (let subMenu of subMenus) this.mapPrimaryIdToIdMenu.set(subMenu.primaryID, subMenu.idMenu);
    }

    public async isAuthorized(): Promise<boolean> {
        const authToken: string = window._COLMEIA_.getToken();

        if (authToken) return true;

        this.routingService.navigateToId(routeID.landing);
        return false;
    }

    public getCurrentMenuFriendlyName(dashboardRoute: string, idAngularRoute: string): string {
        const info: IMapCurrentDashboardClient = Object.values(mapCurrentDashboardClient).find((item: IMapCurrentDashboardClient) => item.route === dashboardRoute);
        const screenGroupName: EScreenGroups = info.screenGroup.name;
        const allowedScreenGroupItems: Serializable[] = Serializable.getVisualElementFromScreenGroup(screenGroupName)
        const currentSerializableMenu = allowedScreenGroupItems.find((item) => item.getAngularRouteID() === idAngularRoute)

        return currentSerializableMenu.getName()
    }

}
