import { IFormSchemmaAndEngagement, IFormSchemmaAndEngagementWithMedia } from "app/model/chat-options.model";

export class AttendantDynamicListRegistrationHandler {

    constructor(private metadatas: IFormSchemmaAndEngagementWithMedia[]){}

	getMetadatas(): IFormSchemmaAndEngagementWithMedia[] {
		return this.metadatas;
	}

}

