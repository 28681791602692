import { EMimeTypes } from '@colmeia/core/src/multi-media/file-interfaces';
import { EBBCodeStyles } from '@colmeia/core/src/shared-business-rules/bbcode/bbcode-types';
import { IMKTSendMessage } from '@colmeia/core/src/shared-business-rules/colmeia-apis/colmeia-api-model';
import { IDocReaderState, TIDocReaderStateArray, TIReadDataWithIdPropety } from "@colmeia/core/src/shared-business-rules/doc-reader-v2/strategies/doc-types";
import { IMLCLUPredictIntent, IntentId, IntentName } from '@colmeia/core/src/shared-business-rules/knowledge-base/clu-core-interfaces';
import { Hours, MS } from '@colmeia/core/src/time/time-utl';
import { EValidFormatFinal, IDateUtils, TISODateString } from '@colmeia/core/src/tools/date/date-utils.types';
import { CharacterSplitter } from '@colmeia/core/src/tools/utility/functions/CharacterSplitter';
import { Define } from '@colmeia/core/src/tools/utility/types/entities/define';
import { TGlobalUID } from '../../business/constant';
import { EDelivery360Action } from '../../comm-interfaces/interaction-interfaces';
import { I360Media } from '../../core-constants/bot';
import { DeliveryProvider, EConfirmationType, ESequenceRenewPolicy, NamedNumber, TArrayID } from '../../core-constants/types';
import { IAddressOnField, IGeneralFormAnswer, IGeneralFormAnswerJson, TGeneralFieldArray } from '../../general-form/general-form-answer';
import { SchemaProperty } from '../../general-form/general-form-interface';
import { IFileField } from '../../request-interfaces/files-interfaces';
import { TArrayIElementPWD } from '../../tools/utility';
import { ReMapObject, UnionToIntersection } from '../../tools/utility-types';
import { EBotRunningType } from '../attendance-island/attendance-island';
import { TConversationMessageItemArray } from '../attendent-service-pack/conversation-history/conversation-history.model';
import { KBAssetType } from '../bot/bot-asset-model';
import { EBotEventType } from '../bot/bot-event-model';
import { TBusinessKeyArray, TIBotLogContentArray } from '../bot/bot-interfaces';
import { TIToBeSentBotMessageArray } from '../bot/bot-model';
import { IMassCommCallbackRowSent, IMassCommEntry } from '../campaigns/campaing-interfaces';
import { IServerColmeiaTag } from '../colmeia-tags/tags';
import { EHttpResponseType, EHTTPStatusCode } from '../connections/endpoint-model';
import { EDOCSegment } from '../doc-reader-v2/field-extractors/shared-types';
import { IFileRowLine, SchemaPropertyServer } from '../files/files';
import { ECustomerVisitMetrics } from '../metadata/meta-engagement';
import { TComputedInfo } from '../metadata/metadata-utils';
import { ENonSerializableObjectType, INonSerializable, INonSerializableHeader } from '../non-serializable-id/non-serializable-id-interfaces';
import { IdDep } from '../non-serializable-id/non-serializable-types';
import { IPaymentGatewayTransactionCustom } from '../payments/payments.gateway.types';
import { IPaymentCustomController, IPaymentDiscount, IPaymentItem, IPaymentMap, IPaymentPayer, IPaymentShoppingCartItemController } from '../payments/payments.types';
import { IPollingResult } from '../polling/polling.type';
import { IWAFEphemerals } from '../waf/waf.public';
import { EAsyncProcessorIdentification, EAsyncProcessorType } from '../../async-processors/async-processor.model';

export const BRAZILIAN_CHARS_REGEXP = /[A-Za-zàáâãäåòóôõöøèéêëçìíîïùúûüÿñ]/;
export const BRAZILIAN_PHRASE_REGEXP = /^[A-Za-zàáâãäåòóôõöøèéêëçìíîïùúûüÿñ\s]*$/;


/**
 * trim: defaults to true.
 * validCharacterRegex: defaults to /^[A-Za-zàáâãäåòóôõöøèéêëçìíîïùúûüÿñ\s]*$/
*/
export interface IStringRuleValidator {
    minWords: number // quantidade minima de palavaras
    trim?: boolean; //default true
    validCharacterRegex?: RegExp // default /[A-Za-zàáâãäåòóôõöøèéêëçìíîïùúûüÿñ]/
    minWordLength?: number,
    maxCharsSequenceRepeated?: number
}

export enum EUserFunctionRunType {
    fromParams = 'fromParams',
    fromDatabase = 'fromDatabase',
}
export type TUserFunctionCode = string

export type TVirtualMenuFields = Array<IFileField>;
export type TVirtualMenuRepository = {
    [idCorporateSearch in string]: TVirtualMenuFields
};


export interface ISimpleSearchFieldCell {
    idProperty: string,
    value: string
}

export interface ISearchResultObjectColumn extends ISimpleSearchFieldCell {
    propertyName: string;
}

export type TSearchResultObjectRow = Array<ISearchResultObjectColumn>;

export type TISimpleSearchFieldCellArray = ISimpleSearchFieldCell[]
export interface ISimpleSearchLine {
    row: TSearchResultObjectRow,
}
export interface ISimpleSearchResult {
    [propertyName: string]: string
}
export type TISimpleSearchLinesArray = ISimpleSearchResult[]

export interface IBasicTrackingElement {
    idTracker: string;
    idParent: string;
    name: string;
    parameter: string;
}

export interface ITrackingElement extends IBasicTrackingElement {
    clocTick: number;
}

export type TITrackingElementArray = Array<ITrackingElement>;



export interface IFunctionCustomerDW {
    businessID?: string;
    text?: string;
    virtualTable: string,
    data: Object;
}



export type TIFunctionCustomerDWArray = Array<IFunctionCustomerDW>;

export type TSequenceId = string

export interface IMktExecutionInfo {
    idCampaign: string;
    idCampainAction: string;
    idRunCampaign: string;
    idMassEntry: string;
    templateText?: string;
    templateClickedBtnLabel?: string;
    templateSentClocktick?: number;
    userInput?: string;
    userSentIdMedia?: string;
    sourceAddress: string;
    targetAddress: string;
    referral?: string;
    referralObj?: HookReferral;
    mktRow: IFileField;
    rtfRow?: IFileField;
    idBot?: string;
    massCommEntry?: IMassCommEntry;
    idUserWhoSent?: string;
}


export interface ReturnWithCustomerVirtualDW {
    customerVirtualDW?: IFunctionCustomerDW[];
}

export interface IPaymentCardItemMap {
    value: number | string;
    name: string;
}
export interface IPaymentCardShoppingItemMap {
    [idProperty: string]: IPaymentCardItemMap;
}

export interface IPaymentCardShopping {
    items: IPaymentCardShoppingItemMap[]
    discount?: IPaymentDiscount
}

export interface IPaymentCustomProvider {
    responseCustomProvider?: IPaymentGatewayTransactionCustom,
    responseCustomPolling?: IPollingResult,
}

export interface IChunkToAdd {
    chunkName: string;
    chunkValue: string;
}

export interface IGenerativeVectorControlResult {
    chunksToAdd: Array<IChunkToAdd>;
    rawContents: string[];
}

export interface ITicketData {
    ticketOwner: string;
}

export interface TVLReturn extends ReturnWithCustomerVirtualDW {
    replaceBotContent?: Array<string>;
    newIntentScore?: IUserBasicFunctionAIContextIntentPrediction[];

    isValid?: boolean;
    errorDescription?: string;
    compilationErrors?: string;
    returnValue?: string;
    rtf?: IFileField;
    properties?: IFileField;
    userMessage?: string;
    typedMessage?: VLReturnTypedMessage;

    excludeFromRTF?: Array<string>;

    // caseSequenceMap: Record<string, number>;
    virtualMenuRepository?: TVirtualMenuRepository;
    shakingHands?: IUsedShakeHands;
    idBot?: string;
    alwaysChangeBot?: boolean;
    haltCommand?: IHaltBotParameter;
    logs?: string[];
    businessKeys?: TBusinessKeyArray;
    ocrResult?: string

    messagesQueue?: IQueuedMessage[];

    paymentCardShopping?: IPaymentCardShopping;
    paymentCustomProvider?: IPaymentCustomProvider;

    ticketData?: ITicketData;

    generativeVectorControl?: IGenerativeVectorControlResult;
    generativeProcessorControl?: IGenerativeProcessorControlResult;

    trackingTags?: Array<{ tag: IServerColmeiaTag, source: TNSSourceTrackingTypes }>;
}

export interface IQueuedMessage {
    text: string,
    highPriority: boolean,
}

export interface TVLInternalReturn extends TVLReturn {
    isError?: boolean;
    isUserDefinedError?: boolean;
}


export interface IClientCustomerCall {
    idSocialContext: TGlobalUID;
}

export interface IUserFunctionSequence {
    persistBusinessIdIntoCase(businessID: string): void;
    getNextSequence(sequenceName: string, renewPolicy: ESequenceRenewPolicy, leftPaddingSize?: number): Promise<string>;
}

export interface IMultipleFormData {
    metadata: SchemaPropertyServer;
    responses: Array<TGeneralFieldArray>;
    hasFinishedForm: boolean;
    last: IGeneralFormAnswer;
    lastClock: number;
}

export interface IMultipleFormCache {
    [idForm: string]: IMultipleFormData
}

export type TUserAttTransactionalMetrics = {
    [key in ECustomerVisitMetrics]: number

}

export enum EMetaHookReferralSourceType {
    Ad = 'ad',
    Post = 'post'
}

export type TNSSourceTrackingTypes = IdDep<ENonSerializableObjectType.formSchemma> | IdDep<ENonSerializableObjectType.graphElement> | IdDep<ENonSerializableObjectType.deployedServices>;

export interface HookReferral {
    source_type?: EMetaHookReferralSourceType;
    /**
     * url do anuncio do facebook/instagram ou url do post no facebook/instagram
     */
    source_url: string;
    /**
     * id do anuncio do facebook/instagram ou id do post no facebook/instagram
     */
    source_id: string;
    /**
     * primary_key do anuncio colmeia armazenado no postgresql(tabela `ad`)
     */
    ad_id_colmeia?: string;
    /**
     * click-to-whatsapp ID
     * id dos clientes que clickaram no link do anuncio do tipo click-to-whatsapp
     */
    ctwa_clid?: string;
}

export interface IAPIExecutionInjectionClientBasic {
    idSocialContext: TGlobalUID;
    idConversation: string;
    idSocialKey: string;
    provider: DeliveryProvider;
    idAvatar: TGlobalUID;
    idAvatarAgent?: TGlobalUID;
    /** Address nesse contexto é o endereço do CLIENTE */
    address: string;
    channel: EDelivery360Action;
    channelAddress: string;
    islandData?: IAPIExecutionAttendenceIslandData,
    allFieldMap: TComputedInfo;
    allFieldMapID?: TComputedInfo;
    referral?: HookReferral;
    multipleFormInfo: IMultipleFormCache;
    mediaInfo?: I360Media;
    trackerParameter?: string;
    trackingHistory?: TITrackingElementArray;
    context?: EFunctionContext;
    userFunctionName?: string;
    idBot?: string;
    toBeSentMessages?: TIToBeSentBotMessageArray;
    mktExecutionInfo?: IMktExecutionInfo;
    /**
     * Evento que acionou a função.
     */
    botEvent?: EBotEventType;
    idTicket?: TGlobalUID;
    flowTokens?: TGlobalUID[];
    target?: string,
}

export interface IPaymentIjectionData {
    mapping?: IPaymentMap;
    items?: IPaymentItem[];
    payer?: IPaymentPayer;
    orderId?: string
}
export interface IAssyncProcessorInjection {
    idAssyncProcessor: string;
    asyncType: EAsyncProcessorType;


}

export interface IAssyncTicket extends IAssyncProcessorInjection {



}

export interface ICustomUserFunctionParams extends IAPIExecutionInjectionClientBasic {
    customerFirstMessage: string;
    value: string,
    intents?: IMLCLUPredictIntent[];
    idUserFunction?: IdDep<ENonSerializableObjectType.userFunction>;
    paymentData?: IPaymentIjectionData;
};


export interface IUserFunctionParamsVirtualMenu extends ICustomUserFunctionParams {
    idCorporateSearch: IdDep<ENonSerializableObjectType.corporateSearchHeader>
}

export interface IUserFunctionParamsMarketing extends ICustomUserFunctionParams {
    callbackData: IMassCommCallbackRowSent
}
export interface IUserFunctionParamsAI extends ICustomUserFunctionParams {
    intents: IMLCLUPredictIntent[];
}

export interface IUserFunctionParamsClient extends ICustomUserFunctionParams {
}
export interface IAPIExecutionAttendenceIslandData {
    islandName: string;
    islandID: string;
    currentServiceBeginTimestamp: number;
    currentServiceTimeTimestamp: number;
    customerName: string;
    customerAvatarID: TGlobalUID;
    attendentEmail: string;
}

export interface IUsedShakeHands {
    [idMetadata: string]: IFileField;
}

export interface IIUserFunctionValidatorLog {
    name: string,
    isSuccess: boolean,
    info: Object,
    error?: any
}
export enum EDiscagemDiretaInternacional {
    brazil = '55'
}
export interface IDateOperations {
    isInsideInterval: boolean,
    isWithinPersonalizedLastMinutes?: boolean;
    errorMessage?: string;
}
export interface IUserFunctionValidatorRuntime {
    submitLogs: () => Promise<void>;
    isValidCountryStateCode: (ddi: EDiscagemDiretaInternacional, input: string | undefined) => boolean;
    isValidFloatNumber: (
        value: number | string | undefined,
        decimalSign?: ',' | '.',
        demandGrouping?: boolean,
        decimalSize?: number
    ) => boolean;
    addToRealTimeFusionIfValid: (key: string,
        value: | number
            | string
            | boolean
            | undefined
            | null
    ) => void;
    isACityValidAndHasOnlyBrazilianCharacters: (userInput: string | undefined, matchingCitiesList: string[]) => boolean;
    removeFromRTF: (id: string) => void;
    getColumnValueFromDB: (
        idDataBase: string,
        predicates: IGeneralFormAnswerJson,
        returningColumn: string | undefined
    ) => Promise<string | undefined>;
    extractDDD: (phoneNumber: string | number | undefined) => string | undefined;
    dateNormalizer: (dateString: string | undefined) => string | undefined;
    fromBrValueStrToNumber: (value: string | undefined) => number | undefined;
    checksIfTimeIsInASpecificInterval: (
        idCalendar: IdDep<ENonSerializableObjectType.attendanceCalendar>,
        dateTarget: TISODateString,
        lastMinutesInterval?: number
    ) => Promise<IDateOperations>;
    stringRuleValidator: (
        input: string,
        rules: IStringRuleValidator
    ) => boolean;
    setGenerateLog: (input: boolean) => void;
}

export interface IUserFunctionShakeHands {
    addToShakeHands(row: IFileField): void
    resetShakeHands(): void
    getShakingHands(): IUsedShakeHands
}

export interface IUserFunctionMediaData extends I360Media {
    hasMedia: boolean
    mediaUrl?: string
}

// export interface IReferral extends WhatsApp.Message.Hook.Referral {
export interface IReferral {
    hasReferral: boolean;
    source_url: string;
    source_id: string;
}

export interface IUserFunctionParamsVirtualMenuShakingHands extends ICustomUserFunctionParams {

}
export interface IUserFunctionBotParams extends ICustomUserFunctionParams {

}

export type TVLFunctionType = (fnParams: IUserFunctionInjection) => Promise<TVLReturn>;
export type TVLBasicFunctionType = (fnParams: string) => Promise<boolean>;


export enum EGlobalFunction {
    companyDocValidation = 'companyDocValidation',
    docValidation = 'docValidation',
    emailValidation = 'emailValidation',
    zipCodeValidation = 'zipCode',
    phoneValidation = 'phoneValidation',
    dateValidation = 'dateValidation'
}

export enum EUserFunctionType {
    custom = 'custom',
    global = 'global',
    api = 'api'
}


export enum EFunctionContext {
    BOTContext = 'botCtx',
    MKTContext = 'mktCtx',
    ShakingHands = 'shakeCtx',
    ClientContext = 'CliCtx',
    AI = 'aiCtx',
}

export interface IUserFunctionModel extends INonSerializableHeader {
    type: EUserFunctionType;
    idAPIRoute: string;
    context?: EFunctionContext;
    customFunctionJS: string;
    customFunctionTS?: string;
    globalFunction?: EGlobalFunction;
    colmeiaApproved?: boolean;
    approveCode: string;
    idCanonicals: TArrayID;
    testData?: ICustomUserFunctionParams,
    // zipCode: string; // ZIP Code da Funcao Padrão
    // useZipCode: boolean;
    // findCountryByDDD: boolean;
}

export interface ISimpleBotConversation {
    idAvatar: string,
    avatarName: string,
    idConversation: string,
    clientAddress: string;
    channelAddress: string;
    providerType: EDelivery360Action;
    conversation: TIBotLogContentArray
}

export type TIUserFunctionModelServerArray = IUserFunctionModelServer[]
export interface IUserFunctionModelServer extends IUserFunctionModel, INonSerializable {
    nsType: ENonSerializableObjectType.userFunction,
}

export interface IColmeiaAprovedData {
    onlyColmeiaApproved?: boolean
}

export interface IOwnCustomerDBPayload {
    id: string;
    clockTick: number;
    payload: Object;
}

export interface IUserFunctionDatabaseRuntimeParams {
    databaseName: string
    clientAddress: string
    idSocialContext: string
}



export interface IUserBasicFunctionInjection {
    context?: EFunctionContext;

    isWhatsapp(): boolean;
    isChannelType(type: EDelivery360Action): boolean; // NOVA
    getClientAddress(): string;
    getChannelAddress(): string;
    getIDConversation(): string;
    getIDAvatarByEmail(email: string): Promise<TGlobalUID>
    getExecutionState(): TComputedInfo;
    createRequest(idRoute: string): IUserFunctionRequestCreator;
    createDate(date: string, format?: EValidFormatFinal): IUserFunctionDateInjection;

    // Helpers para usuários
    isValidEmail(email: string): boolean;
    isValidCPF(cpf: string): boolean;
    isValidCNPJ(cnpj: string): boolean;
    logData(type: string, description: string, data: object): Promise<void>;
    isValidCEP(cep: string): boolean;
    getCepDigityOnly(cep: string): string;
    generateNormalizedPWD(elements: TArrayIElementPWD, validSpecials?: string): string;

    setValidReturn(): void;
    setInvalidReturn(): void;
    setErrorMessage(errorMessage: string): void;

    addToTrackingTagsEvent({ tag, source }: { tag: IServerColmeiaTag, source: TNSSourceTrackingTypes }): void;

    getResults(): TVLReturn;

    createDatabase(database: string): IUserFunctionCustomerDatabase
    toString(obj: object): string
    getUniqueStringID(size?: number): string;

    isThisOneOfThat(is: any, ...idArray: Array<any>): boolean;
    getNormalizedValue(value: string, toLowerCase?: boolean): string;
    isValidRef(value: any): boolean;

};

export interface IUserFunctionCustomerDatabase {
    upsertCustomerDatabase(object: Object, clientAddress?: string): Promise<void>;
    getFromCustomerDatabase<T extends Object>(clientAddress?: string): Promise<T>;
    toString?: unknown;
}

export interface IUserFunctionDocReaderRuntime {
    getReadStateByIdProperty(idProperty: string): IDocReaderState;
    getAllReadStates(): TIDocReaderStateArray;
    getLastReadState(): IDocReaderState;
    getAlreadyReadDataBySegment(docSegment: EDOCSegment): TIReadDataWithIdPropety;
    processMedia(params: IUserFunctionDocReaderProcessMediaParams): Promise<IUserFunctionDocReaderRuntime>
}

export interface IUserFunctionDocReaderProcessMediaParams {
    media: I360Media;
    idProperty: string;
    docSegment: EDOCSegment;
    idTransformer?: string;
}

export interface IUserFunctionTrackingRuntime {
    sendEvent(idTrackingTag: IdDep<ENonSerializableObjectType.colmeiaTags>, idSourceNS: TNSSourceTrackingTypes): void;
    sendConversionsAPI(idTrackingTag: IdDep<ENonSerializableObjectType.colmeiaTags>): void;
}

export interface IUserFunctionTriggerInfo {
    getServiceEvent(): EBotEventType;
}

export interface IUserFunctionDynamicList {
    upsertRow(data: IGeneralFormAnswerJson): Promise<IFileRowLine>;
    fetchRow(data: IGeneralFormAnswerJson): Promise<IFileRowLine>;
}

export interface IUserFunctionTrackerProcessor {
    getFunctionTrackerParameter(): string;

    hasNavTracker(idNS: string): boolean;
    getNavTrackerName(idNS: string): string;
    isLastClassNavTracker(idNS: string): boolean;
    getLastParameterOfClass(idParent: string): string;
}

export interface IUserFunctionFormAccesInjection {
    // Introspeção no Estado
    getCurrentFormValue(): string;
    getCurrentFormValueNormalized(): string;
    /**
     * @deprecated use o método safeGetFieldValueByCanonical ao invés deste
     */
    getFieldValueByCanonical(idCanonical: string): string;
    getFieldValueByProperty(idProperty: string): string;
    /**
     * @deprecated use o método safeGetFieldValueByCanonicalNormalized ao invés deste
     */
    getFieldValueByCanonicalNormalized(idCanonical: string): string;
    getFieldValueByPropertyNormalized(idProperty: string): string;
    setFieldByProperty(idProperty: string, value: string): void;
    getCodeOrValueOfProperty(idProperty: string): string
    getCodeOrValueOfPropertyNormalized(idProperty: string): string
}

export enum EGenerativeAIPluginModel {
    imageGenerator = 'imageGenerator',
    analyticData = 'analyticData',
    financialAnalysis = 'financialAnalysis',
}

export interface IUserFunctionVectorAIController {
    /**
     * Adiciona um "chunk" (fragmento de dados) ao vetor de IA.
     *
     * @param {string} chunkName - O nome identificador do fragmento.
     * @param {string} content - O conteúdo do fragmento.
     *
     * @example
     * vector.addChunk('chunkName', 'This is a sample text.');
     */
    addChunk(name: string, chunk: string): void
    /**
     * Adiciona um conteúdo bruto diretamente ao vetor de IA, sem nomear como um chunk.
     *
     * @param {string} content - O conteúdo textual bruto a ser adicionado.
     *
     * @example
     * vector.addRawContent('This is raw content.');
     */
    addRawContent(content: string): void
}

export interface IGenerativeTable<T = any> {
    tableName: string;
    promptDirections?: string;
    items: T[]
}

export interface IGenerativeProcessorControlResult {
    replaceAllPromptsWith?: string;
    tablesSetted: IGenerativeTable<any>[];
    appends: string[];
    prepends: string[];
    intentReplacement: { [intentId in string]: number };
}


/**
 * Interface para processamento de entrada do usuário no contexto de uma IA generativa.
 * Permite manipulação do prompt, gerenciamento de intenções e envio de dados para análise ou geração.
 */
export interface IUserFunctionGenerativeAIProcessor {
    /**
     * Define uma tabela associada ao processador de IA generativa.
     *
     * @template T O tipo genérico dos dados contidos na tabela.
     * @param {IGenerativeTable<T>} table - A tabela que será associada ao processador.
     *
     * @example
     * processor.setTable(myTable);
     */
    setTable<T>(table: IGenerativeTable<T>): void;

    /**
     * Adiciona um valor ao início do prompt atual.
     *
     * @param {string} value - O texto a ser adicionado ao início do prompt.
     *
     * @example
     * processor.prependToPrompt('Introduction: ');
     */
    prependToPrompt(value: string): void;

    /**
     * Adiciona um valor ao final do prompt atual.
     *
     * @param {string} value - O texto a ser adicionado ao final do prompt.
     *
     * @example
     * processor.appendToPrompt(' This is the conclusion.');
     */
    appendToPrompt(value: string): void;

    /**
     * Substitui completamente o conteúdo do prompt atual.
     *
     * @param {string} value - O novo texto para o prompt.
     *
     * @example
     * processor.replaceAllPromptWith('This is the new prompt.');
     */
    replaceAllPromptWith(value: string): void;

    /**
     * Define a pontuação de intenção para um identificador específico.
     * Pode ser usado para ajustar a relevância de uma intenção identificada no contexto do processamento.
     *
     * @param {string} intentId - O identificador único da intenção.
     * @param {number} score - A pontuação atribuída à intenção.
     *
     * @example
     * processor.setIntentScore('greeting', 0.9);
     */
    setIntentScore(intentId: string, score: number): void;

    /**
     * Envia o prompt atual e outras configurações para processamento pela IA generativa.
     *
     * @returns {Promise<void>} Uma promessa que é resolvida quando o processamento é concluído.
     *
     * @example
     * await processor.submit();
     */
    submit(): Promise<void>;
}

export interface IUserFunctionAIIntentProcessor {
    replaceIntentScore(intentId: string, score: number, othersScore?: number): void;
}

export interface IUserFunctionVirtualMenuParams {
    getAllMenus(): TVirtualMenuFields;
    resetMenus(): void;
    addToVirtualMenu(menu: IFileField): void;
    getMenuSize(): number;
    getICCoportateSearch(): string;
    getRepository(): TVirtualMenuRepository;
}


export interface IUserFunctionVirtualMenuExecutor {
    createVirtualMenu(idCorporateSearch: string): IUserFunctionVirtualMenuParams;
    getVirtualMenuRepository(): TVirtualMenuRepository;
    saveVirtualMenu(virtualMenu: IUserFunctionVirtualMenuParams): void;
}

export interface ICustomerFormController {
    getIDPropertyValuesFor(idProperty: string): TArrayID;
    getJSONArray(): Array<IFileField>;
    getMetadata(): SchemaPropertyServer;
    getFieldNameOf(idProperty: string): string;
    getProperty(idProperty: string): SchemaProperty;
    getLastResponses(): TGeneralFieldArray,
}


export interface IUserFunctionInjection extends IUserBasicFunctionInjection, IUserFunctionFormAccesInjection {
    // Aceleradores de retorno
    // getUserFunctionName(): string;
    /**
     * @deprecated use `queueMessage` instead
     */
    addMessageToUser(userMessage: string): void;
    setRealTimeFusion(object: Object);
    addToRealTimeFusion(key: string, value: string): void;
    removeFromRealTimeFusion(key: string): void;
    changeAccountOwner(idAvatarAgent: string);
    getConversationHistoryUrl(): string;
    getConversationHistory(idInteractionConversation?: string): Promise<string>
    getConversationHistoryWithBot(idInteractionConversation?: string): Promise<ISimpleBotConversation>;
    getFullConversationHistory(): Promise<TConversationMessageItemArray>;

    getOnlyDigitsFrom(value: string): string;
    createCorporateSearch(idCorporateSearch: IdDep<ENonSerializableObjectType.corporateSearchBody>): IUserFunctionCorporateSearchRuntime
    hasFormOfMetadata(idMetadata: string): boolean;
    getCustomerFormOfMetadata(idMetadata: string): ICustomerFormController | undefined;
    getMultimediaSignedUrl(idMedia: string): Promise<string>;
    getMultimediaMetadata(expireTimeInMilisseconds?: number): Promise<IUserFunctionMediaData>;
    getOCRDataContent(): string[];
    setOCRResult(result: string): void;
    initDynamicDBHandle(idDatabase: string): Promise<IUserFunctionDynamicList>;
    getCustomerMetrics(metric: ECustomerVisitMetrics): number;
    getFirstCustomerMessage(): string;
    getReferral(): IReferral;
    addToVirtualDW(data: IFunctionCustomerDW): void;
    getTrackerProcessor(): IUserFunctionTrackerProcessor;
    getUserFunctionValidator(): IUserFunctionValidatorRuntime;
    setHaltCommand(halt: IHaltBotParameter): void; // Pause a função até o recebimento de callback
    safeStringifyJSON<T extends object>(obj: T): string;
    safeParseJSON<T extends object>(jsonString: string): T;
    getNewFunctionExecutor<Out>(idUserFunction: IdDep<ENonSerializableObjectType.userFunction>, name?: string): ICoreUserFunctionsExecutor<Out>

    safeGetFieldValueByCanonical(idCanonical: string): string | undefined;
    safeGetFieldValueByCanonicalNormalized(idCanonical: string): string | undefined;

    getBusinessRuntime(offsetZone?: Hours): IUserFunctionSequence;

    sendMessageToUser: ((message: string) => Promise<void>) & { styles: DefineTemplateTags; };
    getMessageEngine(): IUserFunctionMessageEngine;
    isProduction(): boolean;

    /**
     * @deprecated use dentro do request creator
     */
    setApiCallPolicy(apiCallPolicy: UserFunctionApiCallPolicy | undefined): void;
    delay(miliseconds: number): Promise<void>
    charsSplitter(input: string, config: CharacterSplitter.Config): Required<CharacterSplitter.Item>[];
    getToBeSentMessages(type?: KBAssetType): TIToBeSentBotMessageArray
    getCampaignAction(config: IGetCampaignActionConfig): IUserFunctionMarketingAction;
    getMktExecutionInfo(): IMktExecutionInfo | undefined;
    getMktRuntime(): IUserFunctionMarketingFunctions | undefined;
    getTriggerInfo(): IUserFunctionTriggerInfo;

    popEphemeralProperties(): Promise<IWAFEphemerals>;
    getEphemeralProperties(): Promise<IWAFEphemerals>;
    getPaymentShoppingCartItemController(): IPaymentShoppingCartItemController;
    getPaymentCustomController(): IPaymentCustomController;

    sha256(data: Buffer): Promise<string>;
    sha256Str(str: string): Promise<string>;

    encodeBase64(str: string, encoding?: BufferEncoding): string;
    decodeBase64(str: string): string;

    getDocReaderRuntime(idTransformer?: string): IUserFunctionDocReaderRuntime;

    addToTrackingTagsEvent({ tag, source }: { tag: IServerColmeiaTag, source: TNSSourceTrackingTypes }): void;
    getTracking(): IUserFunctionTrackingRuntime;

    /**
     * Obtém uma instância do sistema de vetores de IA configurada no ambiente de runtime.
     * Permite a manipulação de vetores relacionados a textos para análise ou execução de funções customizadas.
     *
     * @returns {Object} Instância do sistema de vetores de IA.
     */
    getVectorAI(): IUserFunctionVectorAIController;


    /**
     * Obtém o idAvatar do atendente
     */
    getAttendantIdAvatar(): string;
    /**
     * Obtém o nome do atendente
     */
    getAttendantName(): Promise<string>;

    setTicketData(ticketAgentOwner: string): void;
};


export type Nullish = | undefined | null;
export interface IUserFunctionUtility {
    isValidRef<T>(value: T | Nullish): value is T;
    isInvalid(value: any): value is Nullish;
    typedCloneLodash: <T>(source: T) => T;
    dateNormalizer(dateStr: string): string;
    delay: (ms: number) => Promise<unknown>;
}


export interface IUserFunctionMessageEngine {
    getUtility(): IUserFunctionUtility;
    escape(text: string): string;
    getLodash(): { [name in string]?: (...params: unknown[]) => any };

    queueMessage(message: string): number;
    removeQueuedMessage(messageIndex: number): IQueuedMessage[] | undefined;
    clearMessagesQueue(): void;
}

type TemplateStringInput = [message: TemplateStringsArray | string, ...inputs: string[]];
export type DefineTemplateTags = { [key in keyof typeof EBBCodeStyles]: (...inputs: TemplateStringInput) => `[${typeof EBBCodeStyles[key]}]${string}[/${typeof EBBCodeStyles[key]}]` };

export interface ICoreUserFunctionsExecutor<Output = unknown> {
    runFullResults(input?: {}): Promise<TVLReturn>
    runCustomResults(input?: {}): Promise<Output>
    setCustomResults<T>(value: T): void;
    getCallerName(): string | undefined;
    isCallerName(name: string): boolean;
}

export interface ICoreUserFunctionsExecutorInside {
    setCustomResults<T>(value: T): void;
    getCallerName(): string | undefined;
    isCallerName(name: string): boolean;
}
export interface ICoreUserFunctionsExecutorOutside<Output = unknown> {
    runFullResults(input?: unknown): Promise<TVLReturn>
    runCustomResults(input?: unknown): Promise<Output>
}


export type TIHaltBotParameterArray = Array<IHaltBotParameter>;


export enum EHaltEvents {
    onExpire = 'exp',
    onMessageWhileHalted = 'msgHlted',
    onFailCallback = 'failCb',
    onStartHalt = 'strtHlt',
    onCallback = 'onCb',
    customResumeMessage = 'rsmeMsg',
}


export enum AxiosErrorCode {
    ERR_FR_TOO_MANY_REDIRECTS = 'ERR_FR_TOO_MANY_REDIRECTS',
    ERR_BAD_OPTION_VALUE = 'ERR_BAD_OPTION_VALUE',
    ERR_BAD_OPTION = 'ERR_BAD_OPTION',
    ERR_NETWORK = 'ERR_NETWORK',
    ERR_DEPRECATED = 'ERR_DEPRECATED',
    ERR_BAD_RESPONSE = 'ERR_BAD_RESPONSE',
    ERR_BAD_REQUEST = 'ERR_BAD_REQUEST',
    ERR_CANCELED = 'ERR_CANCELED',
    ECONNABORTED = 'ECONNABORTED',
    ETIMEDOUT = 'ETIMEDOUT',
    ECONNRESET = "ECONNRESET"
}


export interface UserFunctionApiCallPolicy {
    /**
     * Número máximo de tentativas. Se não definido, os outros parâmetros não são verificados.
     */
    maximumRetries: number;

    /**
     * Função opcional que recebe um erro do Axios e retorna um booleano indicando se a chamada deve ser repetida.
     */
    shouldRetry?: (error: LocalAxios.AxiosError) => boolean;

    /**
     * Se verdadeiro, a chamada será repetida apenas em caso de tempo limite.
     */
    onlyRetryOnTimeout: boolean;

    /**
     * Se verdadeiro, a chamada será repetida até obter sucesso.
     */
    retryUntilSuccess: boolean;

    /**
     * Atenção: Os parâmetros 'retryIfAxiosCode' e 'retryIfHTTPStatus' são mutuamente exclusivos.
     */

    /**
     * Código do Axios opcional para verificar se a chamada deve ser repetida.
     */
    retryIfAxiosCode?: `${AxiosErrorCode}`;

    /**
     * Status HTTP opcional para verificar se a chamada deve ser repetida.
     */
    retryIfHTTPStatus?: number;

    /**
     * Tempo limite em milissegundos (MS) para uma chamada de API.
     */
    timeout: MS;

    /**
     * Tempo de espera em milissegundos (MS) entre tentativas.
     */
    retryDelay: MS;

    /**
     * Função opcional que, se verdadeira, lança um erro em caso de falha após todas as tentativas.
     */
    shouldThrowOnError?: boolean;
}

export namespace LocalAxios {
    export type AxiosResponseHeaders = Record<string, string> & {
        'set-cookie'?: string[];
    };

    export type AxiosRequestConfig<D = any> = {
        url?: string | undefined;
        method?: string | undefined;
        baseURL?: string | undefined;
        transformRequest?: any;
        transformResponse?: any;
        headers?: Record<string, string | number | boolean>;
        params?: any;
        paramsSerializer?: ((params: any) => string) | undefined;
        data?: D;
        timeout?: number | undefined;
        timeoutErrorMessage?: string | undefined;
        withCredentials?: boolean | undefined;
        adapter?: any;
        auth?: any;
        responseType?: any;
        responseEncoding?: string | undefined;
        xsrfCookieName?: string | undefined;
        xsrfHeaderName?: string | undefined;
        onUploadProgress?: ((progressEvent: any) => void) | undefined;
        onDownloadProgress?: ((progressEvent: any) => void) | undefined;
        maxContentLength?: number | undefined;
        validateStatus?: ((status: number) => boolean) | null | undefined;
        maxBodyLength?: number | undefined;
        maxRedirects?: number | undefined;
        beforeRedirect?: ((options: Record<string, any>, responseDetails: { headers: Record<string, string>; }) => void) | undefined;
        socketPath?: string | null | undefined;
        httpAgent?: any;
        httpsAgent?: any;
        proxy?:
        | false
        | {
            host: string;
            port: number;
            auth?: {
                username: string;
                password: string;
            };
            protocol?: string;
        }
        | undefined
        ;
        cancelToken?: any;
        decompress?: boolean | undefined;
        transitional?: any;
        signal?: AbortSignal | undefined;
        insecureHTTPParser?: boolean | undefined;
        env?: { FormData?: (new (...args: any[]) => object) | undefined; } | undefined;
        raxConfig?: any;
    }

    export type AxiosError<T = unknown, D = any> = {
        config: AxiosRequestConfig
        code?: string | undefined;
        request?: any;
        response?:
        | {
            data: T;
            status: number;
            statusText: string;
            headers: AxiosResponseHeaders;
            config: AxiosRequestConfig<D>;
            request?: any;
        }
        | undefined
        ;
        isAxiosError: boolean;
        status?: string | undefined;
        toJSON: () => object;
        name: string;
        message: string;
        stack?: string | undefined;
    };
}

enum Time {
    MicroSeconds = 1 / 1000,
    Miliseconds = Time.MicroSeconds * 1000,
    Seconds = Time.Miliseconds * 1000,
    Minutes = Time.Seconds * 60,
    Hours = Time.Minutes * 60,
    Days = Time.Hours * 24,
}

type MapToTimeType = ReMapObject<{
    [key in keyof typeof Time]: { [$key in typeof Time[key]]: NamedNumber<key> }
}>;

export function convertTime<From extends Time, To extends Time>(input: MapToTimeType[From], from: From, to: To): MapToTimeType[To]
export function convertTime<From extends Time, To extends Time>(input: number, from: From, to: To): number {
    return (input * from) / to;
}


export interface IHaltedBotEvent {
    events: {
        [event in EHaltEvents]?: {
            message: string;
            resumeBot?: boolean;
        };
    };
};

export interface IHaltBotParameter extends IHaltedBotEvent {
    idCallback: string;
    expireMS: number;
    returnToCallerImmediately?: boolean;
}


export interface IUserBasicFunctionAIContextIntentPrediction {
    id: IntentId;
    score: number;

}

export interface IUserFunctionAIContextIntentPrediction extends IUserBasicFunctionAIContextIntentPrediction {
    name: IntentName;
}

export interface IUserFunctionAIContext {
    getTopIntent(): IUserFunctionAIContextIntentPrediction;
    getAllIntents(): IUserFunctionAIContextIntentPrediction[];
    getGenerativeProcessor(): IUserFunctionGenerativeAIProcessor;
    getIntentsProcessor(): IUserFunctionAIIntentProcessor
}

export interface IUserFunctionAIInjection extends IUserFunctionInjection { // , IUserFunctionVirtualMenuExecutor
    // context?: EFunctionContext.AI
    getContentGeneratorAI(): IUserFunctionAIContext;
    replaceBotMessages(messages: string[]): void;
}

export interface IUserFunctionBotInjection extends IUserFunctionInjection, IUserFunctionVirtualMenuExecutor {
    getFieldAsAddress(idMetadata: string, idProperty: string): IAddressOnField;
    getBotID(): string;
    getBotRunningType(): EBotRunningType;
    getDocumentReaderState(idProperty: string): IDocReaderState;
}

export interface IUserFunctionChangeBotOptions {
    alwaysChange?: boolean;
}

export interface IUserFunctionShakingHandsInjection extends IUserFunctionInjection, IUserFunctionVirtualMenuExecutor {
    /**
     * Altera o bot de destino para uma operação específica.
     *
     * Por padrão, esta função segue as regras de alteração do bot baseadas no contexto atual.
     * Contudo, uma propriedade adicional no objeto de opções, `alwaysChange`, pode ser utilizada
     * para forçar a mudança do bot, ignorando qualquer restrição existente.
     *
     * @param {string} botId - O identificador do bot de destino para o qual será feita a mudança.
     * @param {Object} options - Objeto com as opções para a alteração do bot.
     * @param {boolean} [options.alwaysChange=false] - Opcional. Se `true`, a mudança de bot será feita
     *        ignorando quaisquer regras ou restrições. O padrão é `false`.
     *
     * @returns {boolean} - Retorna `true` se o bot foi alterado com sucesso, caso contrário, `false`.
     *
     * @example
     * // Exemplo de uso
     * changeBot('bot123');
     * // Altera o bot seguindo as regras padrão.
     *
     * changeBot('bot789', { alwaysChange: true });
     * // Altera o bot, ignorando as regras padrão.
     */
    changeBot(idBot: string, opts?: IUserFunctionChangeBotOptions): void;
    createNewShakingHands(idMetadata: string): IUserFunctionShakeHands
    saveShakingHands(shakeHands: IUserFunctionShakeHands): void;
    setHaltCommand(halt: IHaltBotParameter): void; // Pause a função até o recebimento de callback
}

export interface IUserFunctionClientInjection extends IUserFunctionInjection {
    getIslandName(): string;
    getIslandID(): string;
    getCurrentServiceBegin(): number;
    getCurrentServiceTime(): number;
    getCustomerName(): string;
    getCustomerAvatarID(): TGlobalUID;
    getAttendentEmail(): string;
    addTypedMessage(type: `${EUserMessageType}`, message: string): void;
}

export interface VLReturnTypedMessage {
    type: EUserMessageType;
    message: string;
}

export enum EUserMessageType {
    Standard = 'Standard',
    Error = 'Error',
    Warning = 'Warning',
}

export interface IUserFunctionMarketingAction {
    run(input: | IMKTSendMessage | IMKTSendMessage[]): Promise<void>;
}

export interface IGetCampaignActionConfig {
    idCampaignAction: string;
}

export interface IUserFunctionMarketingFunctions {
    getActionID(): string;
    getCampaignID(): string;
    getRunID(): string;
    getActionName(): Promise<string>;
    getBotID(): string;
    getTargetAddress(): string;
    getSourceAddress(): string;
    getMktState(): Promise<IMassCommEntry>;
}



export interface IUserFunctionMarketingInjection extends
    IUserFunctionInjection,
    IUserFunctionFormAccesInjection,
    IUserFunctionMarketingFunctions {
    getRowSentToClient(): IMassCommCallbackRowSent;
    /**
     * Status que gerou a chamada do callback
     */
    getCallbackStatus(): EConfirmationType;
    getMktExecutionInfo(): IMktExecutionInfo;
    getStatus(): Promise<EConfirmationType>;
}

export interface IUserFunctionDateInjection {
    getDate(): IDateUtils
}

//

export interface IUserFunctionRequestCreator {
    setApiCallPolicy(apiCallPolicy: UserFunctionApiCallPolicy): void;
    submit(): Promise<IUserFunctionAPIReturn>;
    setFileDownloadType(fileType: EMimeTypes, fileDownloadType?: EHttpResponseType): void;
    setFileDownloadExpirationTimeInMicroseconds(time: MS): void;
    setFileDownloadName(fileName: string): void;
    addObjectToBody(body: object): void;
    addObjectToHeader(header: object): void;
    addObjectToQueryString(query: object): void;
    concatToURI(data: string): void;
    replaceVariableOnURI(varName: string, newValue: string): void
}

export interface IUserFunctionAPIReturn {
    isError(): boolean;
    isSuccess(): boolean;
    isOk(): boolean;
    isCode(code: number): boolean;
    isResponseValue(property: string, value: any): boolean;
    getStatus(): EHTTPStatusCode | undefined;
    getResponseData(): any;
    getHeaders(): object;
    getFileUrl(): string | undefined;
}

export interface IUserFunctionFileDownloadResult {
    url: string
}
export interface IUserFunctionCorporateSearchRuntime {
    resetParams(): void
    addSearchParam(searchParam: ISimpleSearchFieldCell): void
    performSingleRowSearch(): Promise<ISimpleSearchResult> | undefined
    performMultipleRowSearch(): Promise<TISimpleSearchLinesArray>
}


export type TAllUserFunctionRuntimeFunctionsUnion =
    | IUserFunctionBotInjection
    | IUserFunctionShakingHandsInjection
    | IUserFunctionClientInjection
    | IUserFunctionMarketingInjection
    | IUserFunctionCustomerDatabase
    | IUserFunctionAIInjection
    ;

export type MapUserFunctionContextToInterface = Define<{ [key in EFunctionContext]: IUserBasicFunctionInjection }, {
    [EFunctionContext.BOTContext]: IUserFunctionBotInjection,
    [EFunctionContext.MKTContext]: IUserFunctionMarketingInjection,
    [EFunctionContext.ShakingHands]: IUserFunctionShakingHandsInjection,
    [EFunctionContext.ClientContext]: IUserFunctionClientInjection,
    [EFunctionContext.AI]: IUserFunctionAIInjection,
}>


export type TAllUserFunctionRuntimeFunctionsIntersection = UnionToIntersection<TAllUserFunctionRuntimeFunctionsUnion>;


export type TAllUserFunctionRuntimeFunctions = keyof TAllUserFunctionRuntimeFunctionsIntersection;


export const gUserFunctionDeclarationsStr = `

declare type IdDep<NsType extends ENonSerializableObjectType = ENonSerializableObjectType> =
    & string
    & $IdDep<NsType>
;
declare class $IdDep<NsType extends ENonSerializableObjectType> {
    static readonly type: unique symbol;
    protected [$IdDep.type]?: NsType;
}

export interface ICoordinates {
    latitude: number;
    longitude: number;
}


export interface ICoreCommonAddressInfo { // São os campos comuns as consultas a APIs de endereços Google e ViaCEP (@colmeai/bot -> ICommonAddressInfo)
    streetNumber: string | undefined;
    street: string | undefined;
    neighborhood: string | undefined;
    city: string | undefined;
    country: string | undefined;
    uf: string | undefined;
}

export interface IAddressOnField {
    geoLocation?: ICoordinates; // Se o usuário mandou geo, coloque aqui
    googleIDPlace?: string; // mesmo caso acima.. só que no caso da consulta de endereco autoComplete, o GPS não vem
    ZIP?: string;  // Aqui pega o CEP
    commonFields?: Partial<ICoreCommonAddressInfo> // Campos retornados pela busca separados por tipo
}


export enum ERandonCharType {
    allReadable = 'all',
    alphabetOnly = 'letterOnly',
    numberOnly = 'number',
    numberAndChar = 'numberAndLetter',
    lowCaseChar = 'lowcase',
    specialChars = 'special',
    upperCaseChar = 'upperCase'
}

declare interface IElementPWD {
    type: ERandonCharType;
    times: EMaxPerElement;
}

declare type TArrayIElementPWD=Array<IElementPWD>;

declare type EMaxPerElement = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9

export enum EHTTPStatusCode {
    ok = 200,
    created = 201,
    notFound = 404,
    badRequest = 400,
    internalError = 500,
    lastHttpErrorCode = 599
}


export enum ENonSerializableObjectType {

    backupPackage = 'backupPkg',
    backupSnapshot = 'backupSnapshot',

    patchHeader = 'patchProd', // JC
    patchItem = 'patchItem', // JC
    securityControl = 'securityControl',
    knowledgeBase = 'kbase', // GAB
    serviceIsland = 'srvIsland', // GAB
    utterance = 'utter', // DAN
    connection = 'conn', // DAN
    connectionRoute = 'connRoute', // DAN
    deployedServices = 'depServ', //DAN
    colmeiaTags = 'colmTag', // FAB
    contentGenerator = 'botTrans', // JC OK
    attendentServicePack = 'attPack', // JC OK
    clientGeneralAnswer = 'clAns',  // JC OK
    bot = 'bot',  // JC OK
    campaing = 'camp',  // JC OK
    attendantAllowedAvatar = 'attIsland', // JC OK
    runCampaing = 'runCampaign',  // JC OK
    fileMetadata = 'fMeta', // GAB #OK
    canonical = 'canon', // GAB #NO DEPENDENCIES
    socialMediaConnection = 'socConnec', // GAB #NO DEPENDENCIES
    waba = 'whatsappBusinessAccount',
    formSchemma = 'fchemma', // GAB # PRONTO, FAVOR VERIFICAR
    deployTransformer = 'depTrans',
    featureDeployedGroup = 'groupDep',
    deployHeader = 'dePHeader',
    deployProdItem = 'dePItem',
    faceCompany = 'nsFaceC',
    presenceStatus = 'prStatus',
    callcenterAtt = 'ccAtt',
    NLPActivity = 'nlpAct', // registra a atividade de NLP
    activeCallBatch = 'activeCallBatch',
    activeCall1x1 = 'activeCall1x1',

    /**
     * @description configuração de contrato de social network, armazena idPartition e outras informações importantes de configuração
     */
    socialNetworkConfig = 'socialNetworkContract',
    socialNetworkUserSettings = 'snUserSettings',

    attendanceCalendar = 'attCalnd',
    realTimeFusion = 'namedAcc',
    customEvents = 'attendanceEvents',

    userApprovalProfile = 'usrAprvlPrfl',

    job = 'job',
    graphElement = 'bpmGrapM',
    userFunction = 'userFunction',
    colmeiaAPIModel = 'colmeiaAPIModel',

    crmHeaderCloseState = 'crmCloseState',  // ECloseState
    crmItemCloseState = 'crmItemCloseState',

    crmHeaderPhase = 'crmPhase', //EServiceManagementPhases
    crmItemPhase = 'crmItemPhase',

    crmHeaderSeverity = 'crmSeverity', //ESeverityLevel
    crmItemSeverity = 'crmItemSeverity',

    crmHeaderState = 'crmState', //ETicketState
    crmItemState = 'crmItemState',

    crmHeaderSupportLevel = 'crmHeaderSupportLevel',
    crmItemSupportLevel = 'crmItemSupportLevel',

    crmHeaderUrgencyHeaderLevel = 'crmHeaderUrgencyLevel',
    crmItemUrgencyLevel = 'crmItemUrgencyLevel',

    crmConfigPackage = 'crmConfigPackage', //Pacote

    crmServiceConfigRoot = 'crmGraphServiceConfigRoot',
    crmServiceConfigAgentEvent = 'crmGraphServiceConfigUserEvent',
    crmServiceConfigNotification = 'crmGraphServiceConfigNotification',
    crmServiceConfigProcessingNode = 'crmGraphServiceConfigProcessingNode',

    corporateSearchHeader = 'CSHeader',
    corporateSearchBody = 'CSBody',

    smartFlowRTFParamHeader = 'sf-RTFParamH',
    smartFlowRTFParamBody = 'sf-RTFParamB',

    channelTemplate = 'channelTemplate',

    configurationSet = 'configSet',

    // Objetos da Execução do CRM
    crmExecution = 'crmExec',

    bpmMarketingRoot = 'bpmMarketingRoot',
    bpmMarketingAction = 'bpmMarketingAction',
    bpmMarketingPause = 'bpmMarketingPause',
    bpmMarketingAcknowledge = 'bpmMarketingAcknowledge',
    bpmMarketingListener = 'bpmMktListener',
    bpmMarketingEnd = 'bpmMktEnd',
    catalog = 'catlg',
    metaBusinessPages = 'metaBusinessPages',


    /**
     * @description contrato de cliente da colmeia
     */
    billingContract = 'billingContrct',
    /**
     * @description item de contrato da colmeia
     */
    billingContractItem = 'prodctSKU',
    /**
     * @description produto colmeia do menu produtos(template da colmeia de item de contrato)
     */
    product = 'prodct',
    productModule = 'prodctModl',
    yourTasks = 'yourTasks',
    kbCorporateEntity = 'kbCorporateEntity',


    /**
     * colmeia invoice
     */
    billingClosing = 'billClose',
    /**
     * colmeia invoice item
     */
    billingItem = 'billItem',


    analyticsBiReportItem = 'analyticsBiReportItem',

    contentVector = 'contentVector',
    contentVectorChunk = 'contentVectorChunk',
    generativePrompt = 'generativePrompt',

    contactList = 'ctcList',
    contactListItem = 'ctcListItem',

    dataExtractionQuery = 'dataExtracQuery',
    wafSchema = 'wafSchema',
    waf = 'waf',

    attCopilot = 'attCopilot',

    transformer = 'trnsfmr',

    paymentAccount = 'paymentAccount',

    paymentGateway = 'paymentGateway',

    supremacyAgent = 'supremacyAgent',
};

export type TGeneralFormFieldAnswerValue = string | number | IGeneralFormAnswerJson | TGeneralFormFieldAnswerValue[];

export interface IGeneralFormAnswerJson {
    [propertyName: string]: TGeneralFormFieldAnswerValue
}

export interface IFileRowLine {
    idRow: string;
    idFile: string;
    genealogy: TArrayID;
    rows: TGeneralFieldArray;
    row: number;
    frow: number;
}

export interface IUserFunctionDynamicList {
    upsertRow(data: IGeneralFormAnswerJson): Promise<IFileRowLine>;
    fetchRow(data: IGeneralFormAnswerJson): Promise<IFileRowLine>;
}

export interface ISchemaVisibilty {
    myself?: boolean;
    idGroup?: TGlobalUID;
    roles?: TArrayID;
}

export type TSchemaPropertyArray = Array<SchemaProperty>;


export interface IFormSchema {
    idSchemma: string;
    visibility: ISchemaVisibilty;
    name: string;
    form: TSchemaPropertyArray;
}

export interface INonSerializableIdentification {
    idAvatar: TGlobalUID;
    idGroup: TGlobalUID;
    genealogy: TArrayID; // Genealogia de grupos
    target?: string; // Target - id do canal (ex celular do cliente)
}

export interface IReferral {
    hasReferral: boolean;
    source_url: string;
    source_id: string;
}

export enum EHTTPMethod {
    get = 'GET',
    post = 'POST',
    put = 'PUT',
    delete = 'DELETE',
    patch = 'PATCH'
}

declare type TComputedInfo = { [idNS: string]: string }

export interface IDurationObject {
    years?: number | undefined;
    quarters?: number | undefined;
    months?: number | undefined;
    weeks?: number | undefined;
    days?: number | undefined;
    hours?: number | undefined;
    minutes?: number | undefined;
    seconds?: number | undefined;
    milliseconds?: number | undefined;
}

export interface IUserFunctionFileDownloadResult {
    url: string
}

export enum EMimeTypes {
    Jpeg = 'image/jpeg',
    Png = 'image/png',
    Webp = 'image/webp',
    Mp4 = 'video/mp4',
    Wav = 'audio/wav',
    Mp3 = 'audio/mp3',
    mpeg = 'audio/mpeg',
    Webm = 'audio/webm',
    json = 'application/json',
    Csv = 'text/csv',
    PDF = 'application/pdf',
    XLS = 'application/vnd.ms-excel',
    XLSX = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    DOC = 'application/msword',
    DOCX = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',

    CorelEnvoy = 'application/envoy',
    FractalImageFile = 'application/fractals',
    WindowsPrintSpoolFile = 'application/futuresplash',
    HTMLApplication = 'application/hta',
    AtariSTProgram = 'application/internet-property-stream',
    BinHexEncodedFile = 'application/mac-binhex40',
    BinaryFile = 'application/octet-stream',
    BinaryDiskImage = 'application/octet-stream',
    JavaClassFile = 'application/octet-stream',
    DiskMasherImage = 'application/octet-stream',
    ExecutableFile = 'application/octet-stream',
    LHARCCompressedArchive = 'application/octet-stream',
    LZHCompressedFile = 'application/octet-stream',
    CALSRasterImage = 'application/oda',
    ActiveXScript = 'application/olescript',
    OutlookProfileFile = 'application/pics-rules',
    CertificateRequestFile = 'application/pkcs10',
    CertificateRevocationListFile = 'application/pkix-crl',
    AdobeIllustratorFile = 'application/postscript',
    PostscriptFile = 'application/postscript',
    RichTextFormatFile = 'application/rtf',
    SetPaymentInitiation = 'application/set-payment-initiation',
    SetRegistrationInitiation = 'application/set-registration-initiation',
    OutlookMailMessage = 'application/vnd.ms-outlook',
    SerializedCertificateStoreFile = 'application/vnd.ms-pkicertstore',
    WindowsCatalogFile = 'application/vnd.ms-pkiseccat',
    StereolithographyFile = 'application/vnd.ms-pkistl',
    PowerPointTemplate = 'application/vnd.ms-powerpoint',
    PowerPointSlideShow = 'application/vnd.ms-powerpoint',
    PowerPointPresentation = 'application/vnd.ms-powerpoint',
    MicrosoftProjectFile = 'application/vnd.ms-project',
    WordPerfectMacro = 'application/vnd.ms-works',
    MicrosoftWorksDatabase = 'application/vnd.ms-works',
    MicrosoftWorksSpreadsheet = 'application/vnd.ms-works',
    MicrosoftWorksWordProcessorDocument = 'application/vnd.ms-works',
    WindowsHelpFile = 'application/winhlp',
    BinaryCPIOArchive = 'application/x-bcpio',
    ComputableDocumentFormatFile = 'application/x-cdf',
    UnixCompressedFile = 'application/x-compress',
    GzippedTarFile = 'application/x-compressed',
    UnixCPIOArchive = 'application/x-cpio',
    PhotoshopCustomShapesFile = 'application/x-csh',
    KodakRAWImageFile = 'application/x-director',
    AdobeDirectorMovie = 'application/x-director',
    MacromediaDirectorMovie = 'application/x-director',
    DeviceIndependentFormatFile = 'application/x-dvi',
    GnuTarArchive = 'application/x-gtar',
    GnuZippedArchive = 'application/x-gzip',
    HierarchicalDataFormatFile = 'application/x-hdf',
    InternetSettingsFile = 'application/x-internet-signup',
    IISInternetServiceProviderSettings = 'application/x-internet-signup',
    ARCPlusArchitecturalFile = 'application/x-iphone',
    JavaScriptFile = 'application/x-javascript',
    LaTexDocument = 'application/x-latex',
    MicrosoftAccessDatabase = 'application/x-msaccess',
    WindowsCardSpaceFile = 'application/x-mscardfile',
    CrazyTalkClipFile = 'application/x-msclip',
    DynamicLinkLibrary = 'application/x-msdownload',
    MicrosoftMediaViewerFile = 'application/x-msmediaview',
    Steuer2001File = 'application/x-msmediaview',
    MultimediaViewerBookSourceFile = 'application/x-msmediaview',
    WindowsMetaFile = 'application/x-msmetafile',
    MicrosoftMoneyFile = 'application/x-msmoney',
    MicrosoftPublisherFile = 'application/x-mspublisher',
    TurboTaxTaxScheduleList = 'application/x-msschedule',
    FTRMediaFile = 'application/x-msterminal',
    MicrosoftWriteFile = 'application/x-mswrite',
    MSXComputersArchiveFormat = 'application/x-perfmon',
    PerformanceMonitorCounterFile = 'application/x-perfmon',
    ProcessMonitorLogFile = 'application/x-perfmon',
    AvidPersistentMediaRecordFile = 'application/x-perfmon',
    PegasusMailDraftStoredMessage = 'application/x-perfmon',
    PersonalInformationExchangeFile = 'application/x-pkcs12',
    PKCS12CertificateFile = 'application/x-pkcs12',
    CertificateRequestResponseFile = 'application/x-pkcs7-certreqresp',
    PKCS7CertificateFile = 'application/x-pkcs7-mime',
    DigitallyEncryptedMessage = 'application/x-pkcs7-mime',
    DigitallySignedEmailMessage = 'application/x-pkcs7-signature',
    BashShellScript = 'application/x-sh',
    UnixSharArchive = 'application/x-shar',
    FlashFile = 'application/x-shockwave-flash',
    StuffitArchiveFile = 'application/x-stuffit',
    System5Release4CPIOFile = 'application/x-sv4cpio',
    System5Release4CPIOChecksumData = 'application/x-sv4crc',
    ConsolidatedUnixFileArchive = 'application/x-tar',
    TclScript = 'application/x-tcl',
    LaTeXSourceDocument = 'application/x-tex',
    LaTeXInfoDocument = 'application/x-texinfo',
    UnformattedManualPage = 'application/x-troff',
    TuringSourceCodeFile = 'application/x-troff',
    TomeRaider2EbookFile = 'application/x-troff',
    UnixManual = 'application/x-troff-man',
    ReadmeTextFile = 'application/x-troff-me',
    ThreedsMaxScriptFile = 'application/x-troff-ms',
    UniformStandardTapeArchiveFormatFile = 'application/x-ustar',
    SourceCode = 'application/x-wais-source',
    InternetSecurityCertificate = 'application/x-x509-ca-cert',
    SecurityCertificate = 'application/x-x509-ca-cert',
    DERCertificateFile = 'application/x-x509-ca-cert',
    PublicKeySecurityObject = 'application/ynd.ms-pkipko',
    ZippedFile = 'application/zip',
}

declare interface IUserFunctionRequestCreator {
    setApiCallPolicy(apiCallPolicy: UserFunctionApiCallPolicy): void;
    submit(): Promise<IUserFunctionAPIReturn>;
    setFileDownloadType(fileType: EMimeTypes, fileDownloadType?: EHttpResponseType): void;
    setFileDownloadExpirationTimeInMicroseconds(time: MS): void;
    setFileDownloadName(fileName: string): void;
    addObjectToBody(body: object): void;
    addObjectToHeader(header: object): void;
    addObjectToQueryString(query: object): void;
    concatToURI(data: string): void;
    replaceVariableOnURI(varName: string, newValue: string): void
}

declare class DateUtils {
    static now(): DateUtils;
    isValid(): boolean;
    getISODate(): string;
    isToday(): boolean;
    isBigger(anotherDate: DateUtils): boolean;
    isBiggerYearThan(year: number): boolean;
    isSmaller(anotherDate: DateUtils): boolean;
    isSmallerYearThan(year: number): boolean;
    isFuture(): boolean;
    isPast(): boolean;
    add(duration: IDurationObject): DateUtils;
    subtract(duration: IDurationObject): DateUtils;
    diff(anotherDate: DateUtils): IDurationObject;
}
declare type TVirtualMenuFields = Array<Object>;
declare type TVirtualMenuRepository = {[idCorporateSearch: string]: TVirtualMenuFields};


declare interface IDateParsedSuccess {
    isValid: true,
    date: DateUtils
}

declare interface IDateParsedError {
    isValid: false
}

declare interface IDateUtils {
    isValid(): boolean;
    getISODate(): string;
    isToday(): boolean;
    isBigger(anotherDate: IDateUtils): boolean;
    isBiggerYearThan(year: number): boolean;
    isSmaller(anotherDate: IDateUtils): boolean;
    isSmallerYearThan(year: number): boolean;
    isFuture(): boolean;
    isPast(): boolean;
    add(duration: IDurationObject): IDateUtils;
    subtract(duration: IDurationObject): IDateUtils;
    diff(anotherDate: IDateUtils): IDurationObject;
}

declare interface IUserFunctionDateInjection {
    getDate(): IDateUtils
}

declare interface IFileField {
    [field: string]: string;
}

declare interface IBasicRequestData {
    url: string;
    method: EHTTPMethod | string;
}

declare interface IBasicRequestWithBodyAndHeaderObject extends IBasicRequestData {
    headers?: object;
    body?: object;
}

declare interface IUserFunctionAPIReturn {
    isError(): boolean;
    isSuccess(): boolean;
    isOk(): boolean;
    isCode(code: number): boolean;
    isResponseValue(property: string, value: any): boolean;
    getStatus(): EHTTPStatusCode | undefined;
    getResponseData(): any;
    getHeaders(): object;
    getFileUrl(): string;
}

export interface VLReturnTypedMessage {
    type: EUserMessageType;
    message: string;
}

declare interface TVLReturn {
    isValid?: boolean;
    errorDescription?: string;
    compilationErrors?: string;
    returnValue?: string;
    rtf?: IFileField;
    userMessage?:string;
    typedMessage?: VLReturnTypedMessage;
}

export interface IQueuedMessage {
    text: string,
    highPriority: boolean,
}

declare interface IUserFunctionVirtualMenuParams {
    getAllMenus(): TVirtualMenuFields;
    resetMenus(): void;
    addToVirtualMenu(menu: IFileField): void;
    getMenuSize(): number;
    getICCoportateSearch(): string;
    getRepository(): TVirtualMenuRepository;
}

export enum EValidFormatFinal {
    ddmmyyyy = 'ddMMyyyy',
    yyyymmdd = 'yyyyMMdd',
    ddmmyy = 'ddMMyy',
    mmyy = 'MMyy',
    ISO8601 = 'ISO8601'
}

declare type TDateUtilsFormatType = EValidFormatFinal | string;

declare type TGlobalUID = string

export interface ISimpleSearchFieldCell {
    idProperty: string,
    value: string
}
export interface ISimpleSearchResult {
    [propertyName: string]: string
}
export type TISimpleSearchLinesArray = ISimpleSearchResult[]

export interface IUserFunctionCorporateSearchRuntime {
    resetParams(): void
    addSearchParam(searchParam: ISimpleSearchFieldCell): void
    performSingleRowSearch(): Promise<ISimpleSearchResult> | undefined
    performMultipleRowSearch(): Promise<TISimpleSearchLinesArray>
}

export interface IUserFunctionCustomerDatabase {
    upsertCustomerDatabase(object: Object, clientAddress?: string): Promise<void>;
    getFromCustomerDatabase<T extends Object>(clientAddress?: string): Promise<T>;
}

export interface IUsedShakeHands {
    [idMetadata: string]: IFileField;
}

export interface IUserFunctionShakeHands {
    addToShakeHands(row: IFileField): void
    resetShakeHands(): void
    getShakingHands(): IUsedShakeHands
}

export enum EDelivery360Action {
    Delivery360WhatsApp = 'WhatsApp',
    Delivery360SMS = 'SMS',
    Delivery360FBMessenger = 'FBMessenger',
    DeliveryInstagram = 'Instagram',
    Delivery360Email = 'Email',
    Delivery360Voice = 'Phone',
    DeliveryColmeia = 'colmeia',
    DeliveryTelegram = 'Telegram',
};

export interface I360MediaMetadata {
    isThumb?: boolean;
    isVoiceMessage?: boolean;
    isExternalMedia?: boolean;
}

export interface I360Media extends I360MediaMetadata {
    mymeType: string;
    idMedia: string;
    idMediaType: TGlobalUID;
    size: number;
}

export enum ECustomerVisitMetrics {
    botToday = 'bt0',
    bot7Days = 'bt7',
    bot30Days = 'bt30',
    bot90Days= 'bt90D',
    bot180Days = 'bt180',
    bot360Days = 'bt360',
    serviceToday = 'sr0',
    service7Days = 'sr7',
    service30Days = 'sr30',
    service90Days= 'sr90',
    service180days = 'sr180',
    serviceYear = 'service360',
}

export type T360MediaArray = Array<I360Media>;

export enum EInteractionSentType {
    onQueue = 'oQ',
}

export type TIBotLogContentArray = Array<IBotLogContent>;
export interface IBotLogContent {
    clockTick: number;
    medias?: T360MediaArray;
    text?: string;
    form?: {
        idForm: string;
        idAnswer: string;
    };
    idElement?: string;
    isInvalidOption?: true;
    isDesambiguation?: true;
    menuTitle?: string;
    isReturn?: true;
    menuItem?: string;
    isLookup?: boolean;
    sentType?: EInteractionSentType;
}

export interface ISimpleBotConversation {
    idAvatar: string,
    avatarName: string,
    idConversation: string,
    clientAddress: string;
    channelAddress: string;
    providerType: EDelivery360Action;
    conversation: TIBotLogContentArray
}

export type TArrayID = Array<TGlobalUID>;

export interface INonSerializableMedia {
    medias?: T360MediaArray;
}

export interface ITagAssignment {
    clockTick: number;
    idAvatar?:string;
    idTag: string;
}

export type TTagAssignmentArray=Array<ITagAssignment>;

export interface ITaggable {
    tags?: TTagAssignmentArray;
}

export interface INonSerializableHeader extends INonSerializableMedia, ITaggable {
    nName: string;
    genealogy?: TArrayID; // Genealogia de NonSerializable
    idParent?: string;
    position?: number;
    isVirtual?: boolean;
}

export interface INonSerializable extends INonSerializableHeader {
    idNS?: string;
    clockTick?: number;
    lastTouch?: number;
    nsType: ENonSerializableObjectType;
    isRoot?: boolean;
    searchTags?: TArrayID;
    searchTokens?: TArrayID;
    ident: INonSerializableIdentification;
}

export type TSchemmaPropertyServerArray=Array<SchemaPropertyServer>;

export interface ICSVColumn {
    pos: number;
    name: string;
}

export interface ICSVToSchemma {
    [idProperty: string]: ICSVColumn;
}

export interface ISchemaPropertyClient extends INonSerializableHeader {
    allowCorporateSearch?: boolean;
    schemma: IFormSchema;
    mapper?: ICSVToSchemma;
}

export interface ITagAssignment {
    clockTick: number;
    idAvatar?:string;
    idTag: string;
}

export interface IFormChoice {
    text: string; //texto da opção
    internalBehavior?: ITagAssignment; // valor da tag
}

export enum EUnitTypeID {
    dateType    = 'date_type',
    logicalType = 'logical_type',
    multipleChoiceType = 'multiple_choice_type',
    numberType  = 'number_type',
    imageType  = 'image_type',
    stringType  = 'string_type',
    objectType = 'object_type',
    geoReferencingOrZipType = 'georeferencingorzip_type',
    groupInput  = 'group_type',
    avatarInput = 'avatar_type'
};

export interface SchemaProperty {
    idProperty: string; //id PAra as respostas
    propertyName: string;
    idUnity: EUnitTypeID; // todas as possibilidades, verificar: /workspace/colmeia/client/src/app/model/general-form.model.ts
    prompt: string; // pergunta
    idLocalCanonical?: string;
    nestedSchema?: TSchemaPropertyArray;
    multipleAnswers?: boolean;
    required?: boolean;
    isPrimaryKey?: boolean;
    isLGPDSensitive?: boolean;
    isNotVisibleByAgent?: boolean;
    choices?: IFormChoice[]; // multipla escolha
}


export interface SchemaPropertyServer extends INonSerializable, ISchemaPropertyClient {}

export interface IFunctionCustomerDW {
    businessID?: string;
    text?:string;
    virtualTable: string,
    data: Object;
}

export interface INonSerializableMedia {
    medias?: T360MediaArray;
}

export interface IRFieldResponseBasis {
    idGlobalCanonical: string;
    raw: string;
}
export interface IRFieldResponseBasic {
    idProperty: string;
    value: number|string;
}
export interface IRFieldResponse extends INonSerializableMedia, IRFieldResponseBasis, IRFieldResponseBasic, IAddressOnField {
    row?: number;

    clockTick?: number; // preenchido em caso de que seja uma data
    offset?: number; // preenchido em caso de que seja uma data
    idLocalCanonical: string;
    propertyName?: string;

    isLGPDSensitive?: boolean;

    // Quando em um corporate search, pode se ter o display diferente do que irá retornar efetivamente ao form
    // Neste caso, guardamos o ID aqui
    returningID?: string;

    lineVersion?: string; // Para fazer a carga incremental de um arquivo para o CorporateSearch

    isNotVisibleByAgent?: boolean;

    wasIgnored?: boolean; // Pergunta foi ignorada devido a regras de visualização condicional
}

export type TGeneralFieldArray = Array<IRFieldResponse>;

export interface ICustomerFormController {
    getIDPropertyValuesFor(idProperty: string): TArrayID;
    getJSONArray(): Array<IFileField>;
    getMetadata(): SchemaPropertyServer;
    getFieldNameOf(idProperty: string): string;
    getProperty(idProperty: string): SchemaProperty,
    getLastResponses(): TGeneralFieldArray,
}

export interface IUserFunctionMediaData extends I360Media {
    hasMedia: boolean,
    mediaUrl?: string
}

export enum EHttpResponseType {
    stream = 'stream',
    ArrayBuffer = 'arraybuffer'
}

enum EBBCodeStyles {
    Italic = 'i',
    Bold = 'b',
    Strikethrough = 's',
    Code = 'c',
    Monospace = 'm',
    Menu = 'menu',
}
type TemplateStringInput = [message: TemplateStringsArray | string, ...inputs: string[]];
export type DefineTemplateTags = { [key in keyof typeof EBBCodeStyles]: (...inputs: TemplateStringInput) => \`[\${typeof EBBCodeStyles[key]}]\${string}[/\${typeof EBBCodeStyles[key]}]\`};

export enum ESequenceRenewPolicy {
    never = 'never',
    dailyRenewed = 'daily',
    weekly = 'weekly',
    monthly = 'montlhy',
    yearly = 'yearly'
};

export interface IUserFunctionSequence {
    persistBusinessIdIntoCase(businessID: string): void;
    getNextSequence(sequenceName: string, renewPolicy: ESequenceRenewPolicy, leftPaddingSize?: number): Promise<string>;
}

declare interface IUserFunctionInjection {
    isWhatsapp(): boolean;
    isChannelType(type: EDelivery360Action): boolean;
    getClientAddress(): string;
    getChannelAddress(): string;
    getIDConversation(): string;
    getIDAvatarByEmail(email: string): Promise<TGlobalUID>
    getExecutionState(): TComputedInfo;
    createRequest(idRoute: string): IUserFunctionRequestCreator;
    createDate(date: string, format?: EValidFormatFinal): IUserFunctionDateInjection;
    initDynamicDBHandle(idDatabase: string): Promise<IUserFunctionDynamicList>;
    getCustomerMetrics(metric: ECustomerVisitMetrics): number
    getFirstCustomerMessage(): string;
    getReferral(): IReferral;
    addToVirtualDW(data: IFunctionCustomerDW): void

    // Helpers para usuários
    isValidEmail(email: string): boolean;
    isValidCPF(cpf: string): boolean;
    isValidCNPJ(cnpj: string): boolean;
    submitRequest(idRoute: string): Promise<IUserFunctionAPIReturn>;
    addQueryStringToRequest(idRoute: string, query: object): void;
    logData(type: string, description: string, data: object): Promise<void>
    isValidCEP(cep: string): boolean;
    getCepDigityOnly(cep: string): string;
    generateNormalizedPWD(elements: TArrayIElementPWD, validSpecials?: string): string;

    getBusinessRuntime(offsetZone?: Hours): IUserFunctionSequence;

    // Introspeção no Estado
    getCurrentFormValue(): string;
    getCurrentFormValueNormalized(): string;
    /**
     * @deprecated use o método safeGetFieldValueByCanonical ao invés deste
     */
    getFieldValueByCanonical(idCanonical: string): string;
    getFieldValueByProperty(idProperty: string): string;
    setFieldByProperty(idProperty: string, value: string): void;
    /**
     * @deprecated use o método safeGetFieldValueByCanonicalNormalized ao invés deste
     */
    getFieldValueByCanonicalNormalized(idCanonical: string): string;
    getFieldValueByPropertyNormalized(idProperty: string): string;
    getCodeOrValueOfProperty(idProperty): string
    getCodeOrValueOfPropertyNormalized(idProperty: string): string

    // Aceleradores de retorno
    /**
     * @deprecated use \`queueMessage\` instead
     */
    addMessageToUser(userMessage: string): void;
    setRealTimeFusion(object: Object);
    setValidReturn(): void;
    setInvalidReturn(): void;
    setErrorMessage(errorMessage: string): void;
    getResults(): TVLReturn
    addToRealTimeFusion(key: string, value: string): void;
    removeFromRealTimeFusion(key: string): void;
    getConversationHistory(idInteractionConversation?: string): Promise<string>;
    getOnlyDigitsFrom(value: string): string;

    createCorporateSearch(idCorporateSearch: string): IUserFunctionCorporateSearchRuntime
    getConversationHistoryUrl(): string;
    getConversationHistoryWithBot(idInteractionConversation?: string): Promise<ISimpleBotConversation>
    getFullConversationHistory(): Promise<TConversationMessageItemArray>;
    createDatabase(database: string): IUserFunctionCustomerDatabase
    toString(obj: object): string

    hasFormOfMetadata(idMetadata: string): boolean;
    getCustomerFormOfMetadata(idMetadata: string): ICustomerFormController;
    getMultimediaSignedUrl(idMedia: string): Promise<string>;
    getMultimediaMetadata(expireTimeInMilisseconds?: number): Promise<IUserFunctionMediaData>;
    getOCRDataContent(): string[];
    setOCRResult(result: string): void;

    getTrackerProcessor(): IUserFunctionTrackerProcessor;
    getUserFunctionValidator(): IUserFunctionValidatorRuntime;
    setHaltCommand(halt: IHaltBotParameter): void; // Pause a função até o recebimento de callback
    getUniqueStringID(): string;
    safeStringifyJSON<T extends object>(obj: T): string;
    safeParseJSON<T extends object>(jsonString: string): T;
    getNewFunctionExecutor<Out>(idUserFunction: IdDep<ENonSerializableObjectType.userFunction>, name?: string): ICoreUserFunctionsExecutor<Out>

    safeGetFieldValueByCanonical(idCanonical: string): string | undefined;
    safeGetFieldValueByCanonicalNormalized(idCanonical: string): string | undefined;

    sendMessageToUser: ((message: string) => Promise<void>) & { styles: DefineTemplateTags; };
    getMessageEngine(): IUserFunctionMessageEngine;
    isProduction(): boolean;
    /**
     * @deprecated Defina dentro da conexão ou da requisição
     */
    setApiCallPolicy(apiCallPolicy: UserFunctionApiCallPolicy): void;

    isThisOneOfThat(is: any, ...idArray: Array<any>): boolean;
    getNormalizedValue(value: string, toLowerCase?: boolean): string;
    isValidRef(value: any): boolean;
    delay(miliseconds: number): Promise<void>
    charsSplitter(input: string, config: CharacterSplitterConfig): Required<CharacterSplitterItem>[];
    getToBeSentMessages(type?: KBAssetType): TIToBeSentBotMessageArray;
    getCampaignAction(config: IGetCampaignActionConfig): IUserFunctionMarketingAction;
    getMktExecutionInfo(): IMktExecutionInfo | undefined;
    getMktRuntime(): IUserFunctionMarketingFunctions | undefined;
    getTriggerInfo(): IUserFunctionTriggerInfo;

    /**
     * Pega os dados sensíveis preenchidos no WhatsApp flow, e os deleta.
     * Estes dados possuem o prazo de um dia.
     * CUIDADO: este método, só retornara os dados uma vez, após o uso, os dados são removidos.
     */
    popEphemeralProperties(): Promise<IWAFEphemerals>;

    /**
     * Pega os dados sensíveis preenchidos no WhatsApp flow.
     * Estes dados possuem o prazo de um dia.
     */
    getEphemeralProperties(): Promise<IWAFEphemerals>;

    /**
     * Pega os itens preenchidos no bot para criação do pagamento
     */
    getPaymentShoppingCartItemController(): IPaymentShoppingCartItemController;

    /**
     * Pega o Controlador para Pagamentos Custom
     */
    getPaymentCustomController(): IPaymentCustomController;

    /**
     * O método sha256 gera o hash SHA-256 de um Uint8Array
     */
    sha256(data: Uint8Array): Promise<string>;

    /**
     * O método sha256 gera o hash SHA-256 de uma String
     */
    sha256Str(str: string): Promise<string>;


    /**
     * Codifica uma string em Base64.
     *
     * A função \`encodeBase64\` recebe uma string e a codifica no formato Base64.
     * Este processo é útil para representar dados binários como uma string legível.
     *
     * @param input A string a ser codificada em Base64.
     * @returns A string codificada em Base64.
     *
     * @example
     * const encoded = encodeBase64('Olá, Mundo!');
     * console.log(encoded); // b2xhLCBNdW5kbyE=
     */
    encodeBase64(str: string, encoding?: BufferEncoding): string;

    /**
     * Decodifica uma string Base64 para o seu formato original.
     *
     * A função \`decodeBase64\` recebe uma string codificada em Base64 e a converte de volta para o formato original.
     *
     * @param input A string codificada em Base64.
     * @returns A string decodificada, que era originalmente representada em Base64.
     *
     * @example
     * const decoded = decodeBase64('b2xhLCBNdW5kbyE=');
     * console.log(decoded); // Olá, Mundo!
     */
    decodeBase64(str: string): string;


    /**
     * Retorna uma instância de \`IUserFunctionDocReaderRuntime\` para leitura e gerenciamento de estados de documentos.
     *
     * Essa instância permite acessar informações sobre os estados de leitura de documentos, fornecendo métodos
     * para buscar estados específicos, obter todos os estados ou recuperar o último estado lido, além de acessar
     * dados já lidos de um segmento específico do documento.
     *
     * Os métodos disponíveis são:
     * - \`getReadStateByIdProperty(idProperty: string)\`: Retorna o estado de leitura associado ao identificador
     *   de propriedade informado.
     * - \`getAllReadStates()\`: Recupera um array contendo todos os estados de leitura registrados.
     * - \`getLastReadState()\`: Obtém o estado de leitura mais recente.
     * - \`getAlreadyReadDataBySegment(docSegment: EDOCSegment)\`: Recupera dados já lidos de um segmento específico do documento,
     *   identificados pelo tipo \`EDOCSegment\`. Retorna os dados de leitura com o identificador da propriedade.
     *
     * @param idTransformer - (Opcional) Um identificador do transformador que pode ser utilizado para processar
     * os dados dos documentos antes da leitura. Se não for especificado, será utilizado o comportamento padrão.
     *
     * @returns Uma instância de \`IUserFunctionDocReaderRuntime\` configurada conforme o identificador de transformador
     * especificado, se aplicável.
     */
    getDocReaderRuntime(idTransformer?: string): IUserFunctionDocReaderRuntime;

    addToTrackingTagsEvent({ tag, source}: {tag: IServerColmeiaTag, source: TNSSourceTrackingTypes}): void;
    getTracking(): IUserFunctionTrackingRuntime;
    /**
     * Obtém uma instância do sistema de vetores de IA configurada no ambiente de runtime.
     * Permite a manipulação de vetores relacionados a textos para análise ou execução de funções customizadas.
     *
     * @returns {Object} Instância do sistema de vetores de IA.
     */
    getVectorAI(): IUserFunctionVectorAIController;

    /**
     * Obtém o idAvatar do atendente
     */
    getAttendantIdAvatar(): string;
    /**
     * Obtém o nome do atendente
     */
    getAttendantName(): Promise<string>;

    setTicketData(ticketAgentOwner: string): void;
};

export interface IUserFunctionDocReaderProcessMediaParams {
    media: I360Media;
    idProperty: string;
    docSegment: EDOCSegment;
    idTransformer?: string;
}

export type TNSSourceTrackingTypes = IdDep<ENonSerializableObjectType.formSchemma> | IdDep<ENonSerializableObjectType.graphElement> | IdDep<ENonSerializableObjectType.deployedServices>;

export interface IUserFunctionTrackingRuntime {
    sendEvent(idTrackingTag: IdDep<ENonSerializableObjectType.colmeiaTags>, idSourceNS: TNSSourceTrackingTypes): void;
    sendConversionsAPI(idTrackingTag: IdDep<ENonSerializableObjectType.colmeiaTags>): void;
}

export interface IUserFunctionDocReaderRuntime {
    getReadStateByIdProperty(idProperty: string): IDocReaderState;
    getAllReadStates(): TIDocReaderStateArray;
    getLastReadState(): IDocReaderState;
    getAlreadyReadDataBySegment(docSegment: EDOCSegment): TIReadDataWithIdPropety;
    processMedia(params: IUserFunctionDocReaderProcessMediaParams): Promise<IUserFunctionDocReaderRuntime>
}

export enum AxiosErrorCode {
    ERR_FR_TOO_MANY_REDIRECTS = 'ERR_FR_TOO_MANY_REDIRECTS',
    ERR_BAD_OPTION_VALUE = 'ERR_BAD_OPTION_VALUE',
    ERR_BAD_OPTION = 'ERR_BAD_OPTION',
    ERR_NETWORK = 'ERR_NETWORK',
    ERR_DEPRECATED = 'ERR_DEPRECATED',
    ERR_BAD_RESPONSE = 'ERR_BAD_RESPONSE',
    ERR_BAD_REQUEST = 'ERR_BAD_REQUEST',
    ERR_CANCELED = 'ERR_CANCELED',
    ECONNABORTED = 'ECONNABORTED',
    ETIMEDOUT = 'ETIMEDOUT',
}

namespace LocalAxios {
    export type AxiosResponseHeaders = Record<string, string> & {
        'set-cookie'?: string[];
    };

    export type AxiosRequestConfig<D = any> = {
        url?: string | undefined;
        method?: string | undefined;
        baseURL?: string | undefined;
        transformRequest?: any;
        transformResponse?: any;
        headers?: Record<string, string | number | boolean>;
        params?: any;
        paramsSerializer?: ((params: any) => string) | undefined;
        data?: D;
        timeout?: number | undefined;
        timeoutErrorMessage?: string | undefined;
        withCredentials?: boolean | undefined;
        adapter?: any;
        auth?: any;
        responseType?: any;
        responseEncoding?: string | undefined;
        xsrfCookieName?: string | undefined;
        xsrfHeaderName?: string | undefined;
        onUploadProgress?: ((progressEvent: any) => void) | undefined;
        onDownloadProgress?: ((progressEvent: any) => void) | undefined;
        maxContentLength?: number | undefined;
        validateStatus?: ((status: number) => boolean) | null | undefined;
        maxBodyLength?: number | undefined;
        maxRedirects?: number | undefined;
        beforeRedirect?: ((options: Record<string, any>, responseDetails: { headers: Record<string, string>; }) => void) | undefined;
        socketPath?: string | null | undefined;
        httpAgent?: any;
        httpsAgent?: any;
        proxy?:
            | false
            | {
                host: string;
                port: number;
                auth?: {
                    username: string;
                    password: string;
                };
                protocol?: string;
            }
            | undefined
        ;
        cancelToken?: any;
        decompress?: boolean | undefined;
        transitional?: any;
        signal?: AbortSignal | undefined;
        insecureHTTPParser?: boolean | undefined;
        env?: { FormData?: (new (...args: any[]) => object) | undefined; } | undefined;
        raxConfig?: any;
    }

    export type AxiosError<T = unknown, D = any> = {
        config: AxiosRequestConfig
        code?: string | undefined;
        request?: any;
        response?:
            | {
                data: T;
                status: number;
                statusText: string;
                headers: AxiosResponseHeaders;
                config: AxiosRequestConfig<D>;
                request?: any;
            }
            | undefined
        ;
        isAxiosError: boolean;
        status?: string | undefined;
        toJSON: () => object;
        name: string;
        message: string;
        stack?: string | undefined;
    };
}

export interface UserFunctionApiCallPolicy {
    /**
     * Número máximo de tentativas. Se não definido, os outros parâmetros não são verificados.
     */
    maximumRetries: number;

    /**
     * Função opcional que recebe um erro do Axios e retorna um booleano indicando se a chamada deve ser repetida.
     */
    shouldRetry?: (error: LocalAxios.AxiosError) => boolean;

    /**
     * Se verdadeiro, a chamada será repetida apenas em caso de tempo limite.
     */
    onlyRetryOnTimeout: boolean;

    /**
     * Se verdadeiro, a chamada será repetida até obter sucesso.
     */
    retryUntilSuccess: boolean;

    /**
     * Atenção: Os parâmetros 'retryIfAxiosCode' e 'retryIfHTTPStatus' são mutuamente exclusivos.
     */

    /**
     * Código do Axios opcional para verificar se a chamada deve ser repetida.
     */
    retryIfAxiosCode?: ${"`${AxiosErrorCode}`"};

    /**
     * Status HTTP opcional para verificar se a chamada deve ser repetida.
     */
    retryIfHTTPStatus?: number;

    /**
     * Tempo limite em milissegundos (MS) para uma chamada de API.
     */
    timeout: MS;

    /**
     * Tempo de espera em milissegundos (MS) entre tentativas.
     */
    retryDelay: MS;

    /**
     * Função opcional que, se verdadeira, lança um erro em caso de falha após todas as tentativas.
     */
    shouldThrowOnError?: boolean;
}



export interface IUserFunctionMessageEngine {
    getUtility(): IUserFunctionUtility;
    escape(text: string): string;
    getLodash(): { [name in string]?: (...params: unknown[]) => any };


    /**
     * Adiciona uma mensagem à fila de mensagens a serem enviadas ao cliente.
     * @param message A mensagem a ser adicionada à fila.
     * @param highPriority [false] Define a prioridade de envio, highPriority = true adiciona no começo da fila.
     * @returns O índice/posição, da mensagem recém-adicionada na fila. Se a mensagem for inválida retorna -1.
     */
    queueMessage(message: string, highPriority?: boolean): number;
    /**
     * Remove uma mensagem da fila com base no índice fornecido.
     * @param messageIndex O índice da mensagem a ser removida.
     * @returns A nova fila de mensagens após a remoção. Se o índice for invalido retorna undefined.
     */
    removeQueuedMessage(messageIndex: number): IQueuedMessage[] | undefined;
    /**
     * Limpa a fila de mensagens.
     */
    clearMessagesQueue(): void;
}

type Nullish = | undefined | null;
export interface IUserFunctionUtility {
    isValidRef<T>(value: T | Nullish): value is T;
    isInvalid(value: any): value is Nullish;
    typedCloneLodash: <T>(source: T) => T;
    dateNormalizer(dateStr: string): string;
    delay: (ms: number) => Promise<unknown>;
    charsSplitter(input: string, config: CharacterSplitterConfig): Required<CharacterSplitterItem>[]
}

export interface ICoreUserFunctionsExecutor<Output = unknown> {
    runFullResults(input?: {}): Promise<TVLReturn>
    runCustomResults(input?: {}): Promise<Output>
    setCustomResults<T>(value: T): void;
    getCallerName(): string | undefined
    isCallerName(name: string): boolean
}


export enum EHaltEvents {
    onExpire = 'exp',
    onMessageWhileHalted = 'msgHlted',
    onFailCallback = 'failCb',
    onStartHalt = 'strtHlt',
    onCallback = 'onCb',
    customResumeMessage = 'rsmeMsg'
}

export interface IHaltedBotEvent {
    events: {
        [event in EHaltEvents]?: {
            message: string;
            resumeBot?: boolean;
        }
    }
}

export interface IHaltBotParameter extends IHaltedBotEvent {
    idCallback: string;
    expireMS: number;
    returnToCallerImmediately?: boolean;
}

export interface IUserFunctionTrackerProcessor {
    getFunctionTrackerParameter(): string;

    hasNavTracker(idNS: string): boolean;
    getNavTrackerName(idNS: string): string;
    isLastClassNavTracker(idNS: string): boolean;
    getLastParameterOfClass(idParent: string): string;
}

export interface IUserFunctionVirtualMenuExecutor {
    createVirtualMenu(idCorporateSearch: string): IUserFunctionVirtualMenuParams;
    getVirtualMenuRepository(): TVirtualMenuRepository;
    saveVirtualMenu(virtualMenu: IUserFunctionVirtualMenuParams): void;
}

// //

namespace DeclareCustom {
    export declare class Type<Information> {
        static readonly type: unique symbol;
        protected [Type.type]?: Information;
    }

    export declare class Required<Information> extends Type<Information> {
        protected [Type.type]: Information;
    }

    export type RequiredOf<Content extends Type<any>> = Content extends Type<infer Information>
        ? Required<Information>
        : never
    ;
}
import CCustomType = DeclareCustom.Type;
export type NamedType<Type, Name extends string> = Type & CCustomType<Name>
export type NamedNumber<Name extends string> = NamedType<number, Name>;
export type NamedString<Name extends string> = NamedType<string, Name>;

export type IntentName = NamedString<'IntentName'>;
export type IntentId = NamedString<'IntentId'>;


export type MicroSeconds = NamedNumber<'MicroSeconds'>;
export type Miliseconds = NamedNumber<'Miliseconds'>;
export type MS = Miliseconds;
export type Seconds = NamedNumber<'Seconds'>;
export type Minutes = NamedNumber<'Minutes'>;
export type Hours = NamedNumber<'Hours'>;
export type Days = NamedNumber<'Days'>;
export type Years = NamedNumber<'Years'>;

export type BufferEncoding =
    | "ascii"
    | "utf8"
    | "utf-8"
    | "utf16le"
    | "utf-16le"
    | "ucs2"
    | "ucs-2"
    | "base64"
    | "base64url"
    | "latin1"
    | "binary"
    | "hex";

export enum EFunctionContext {
    BOTContext = 'botCtx',
    MKTContext = 'mktCtx',
    ShakingHands = 'shakeCtx',
    ClientContext = 'CliCtx',
    AI = 'aiCtx',
}
// //

export interface IUserFunctionAIContextIntentPrediction {
    id: IntentId;
    name: IntentName;
    score: number;
}

export interface IUserFunctionAIContext {
    getTopIntent(): IUserFunctionAIContextIntentPrediction;
    getAllIntents(): IUserFunctionAIContextIntentPrediction[];
    getGenerativeProcessor(): IUserFunctionGenerativeAIProcessor;
    getIntentsProcessor(): IUserFunctionAIIntentProcessor
}

export interface IUserFunctionAIInjection extends IUserFunctionInjection { // , IUserFunctionVirtualMenuExecutor
    context?: EFunctionContext.AI
    getContentGeneratorAI(): IUserFunctionAIContext;
    replaceBotMessages(messages: string[]): void;
}

export interface IUserFunctionVectorAIController {
    /**
     * Adiciona um "chunk" (fragmento de dados) ao vetor de IA.
     *
     * @param {string} chunkName - O nome identificador do fragmento.
     * @param {string} content - O conteúdo do fragmento.
     *
     * @example
     * vector.addChunk('chunkName', 'This is a sample text.');
     */
    addChunk(name: string, chunk: string): void
    /**
     * Adiciona um conteúdo bruto diretamente ao vetor de IA, sem nomear como um chunk.
     *
     * @param {string} content - O conteúdo textual bruto a ser adicionado.
     *
     * @example
     * vector.addRawContent('This is raw content.');
     */
    addRawContent(content: string): void
}

export interface IGenerativeTable<T = any> {
    tableName: string;
    promptDirections?: string;
    items: T[]
}

/**
 * Interface para processamento de entrada do usuário no contexto de uma IA generativa.
 * Permite manipulação do prompt, gerenciamento de intenções e envio de dados para análise ou geração.
 */
export interface IUserFunctionGenerativeAIProcessor {
    /**
     * Define uma tabela associada ao processador de IA generativa.
     *
     * @template T O tipo genérico dos dados contidos na tabela.
     * @param {IGenerativeTable<T>} table - A tabela que será associada ao processador.
     *
     * @example
     * processor.setTable(myTable);
     */
    setTable<T>(table: IGenerativeTable<T>): void;

    /**
     * Adiciona um valor ao início do prompt atual.
     *
     * @param {string} value - O texto a ser adicionado ao início do prompt.
     *
     * @example
     * processor.prependToPrompt('Introduction: ');
     */
    prependToPrompt(value: string): void;

    /**
     * Adiciona um valor ao final do prompt atual.
     *
     * @param {string} value - O texto a ser adicionado ao final do prompt.
     *
     * @example
     * processor.appendToPrompt(' This is the conclusion.');
     */
    appendToPrompt(value: string): void;

    /**
     * Substitui completamente o conteúdo do prompt atual.
     *
     * @param {string} value - O novo texto para o prompt.
     *
     * @example
     * processor.replaceAllPromptWith('This is the new prompt.');
     */
    replaceAllPromptWith(value: string): void;

    /**
     * Define a pontuação de intenção para um identificador específico.
     * Pode ser usado para ajustar a relevância de uma intenção identificada no contexto do processamento.
     *
     * @param {string} intentId - O identificador único da intenção.
     * @param {number} score - A pontuação atribuída à intenção.
     *
     * @example
     * processor.setIntentScore('greeting', 0.9);
     */
    setIntentScore(intentId: string, score: number): void;

    /**
     * Envia o prompt atual e outras configurações para processamento pela IA generativa.
     *
     * @returns {Promise<void>} Uma promessa que é resolvida quando o processamento é concluído.
     *
     * @example
     * await processor.submit();
     */
    submit(): Promise<void>;
}

export interface IUserFunctionAIIntentProcessor {
    replaceIntentScore(intentId: string, score: number, othersScore?: number): void;
}


declare interface IUserFunctionBotInjection extends IUserFunctionInjection, IUserFunctionVirtualMenuExecutor {
    getFieldAsAddress(idMetadata: string, idProperty: string): IAddressOnField;
    getBotID(): string;
    getBotRunningType(): EBotRunningType;
    getDocumentReaderState(idProperty: string): IDocReaderState;
}

export interface IUserFunctionChangeBotOptions {
    alwaysChange?: boolean;
}

export interface IUserFunctionShakingHandsInjection extends IUserFunctionInjection, IUserFunctionVirtualMenuExecutor {
    /**
     * Altera o bot de destino para uma operação específica.
     *
     * Por padrão, esta função segue as regras de alteração do bot baseadas no contexto atual.
     * Contudo, uma propriedade adicional no objeto de opções, \`alwaysChange\`, pode ser utilizada
     * para forçar a mudança do bot, ignorando qualquer restrição existente.
     *
     * @param {string} botId - O identificador do bot de destino para o qual será feita a mudança.
     * @param {Object} options - Objeto com as opções para a alteração do bot.
     * @param {boolean} [options.alwaysChange=false] - Opcional. Se \`true\`, a mudança de bot será feita
     *        ignorando quaisquer regras ou restrições. O padrão é \`false\`.
     *
     * @returns {boolean} - Retorna \`true\` se o bot foi alterado com sucesso, caso contrário, \`false\`.
     *
     * @example
     * // Exemplo de uso
     * changeBot('bot123');
     * // Altera o bot seguindo as regras padrão.
     *
     * changeBot('bot789', { alwaysChange: true });
     * // Altera o bot, ignorando as regras padrão.
     */
    changeBot(idBot: string, opts?: IUserFunctionChangeBotOptions): void;
    createNewShakingHands(idMetadata: string): IUserFunctionShakeHands
    saveShakingHands(shakeHands: IUserFunctionShakeHands): void
}

declare interface IUserFunctionClientInjection extends IUserFunctionInjection {
    getIslandName(): string;
    getIslandID(): string;
    getCurrentServiceBegin(): number;
    getCurrentServiceTime(): number;
    getCustomerName(): string;
    getCustomerAvatarID(): TGlobalUID;
    getAttendentEmail(): string;
    addTypedMessage(type: ${'`${EUserMessageType}`'}, message: string): void;
}

export interface VLReturnTypedMessage {
    type: EUserMessageType;
    message: string;
}

export enum EUserMessageType {
    Standard = 'Standard',
    Error = 'Error',
    Warning = 'Warning',
}

export interface IMKTSendMessage {
    [idPropertyName: string]: string
}

export interface IUserFunctionMarketingAction {
    run(input: | IMKTSendMessage | IMKTSendMessage[]): Promise<void>;
}

export interface IGetCampaignActionConfig {
  idCampaignAction: string;
}

export type TGraphPropertyData = number | string;

export interface IExtraInformation {
  [name: string]: TGraphPropertyData;
}

export interface IMassCommCallbackRowSent {
  clockTick: number,
  success: boolean,
  status: EConfirmationType,
  rowSent: IExtraInformation,
  idUserWhoSent?: string;
}

export interface IUserFunctionMarketingFunctions {
    getActionID(): string;
    getCampaignID(): string;
    getRunID(): string;
    getActionName(): Promise<string>;
    getBotID(): string;
    getTargetAddress(): string;
    getSourceAddress(): string;
    getMktState(): Promise<IMassCommEntry>;
}

export interface IUserFunctionMarketingInjection extends
    IUserFunctionInjection,
    IUserFunctionMarketingFunctions {
    getRowSentToClient(): IMassCommCallbackRowSent;
    /**
     * Status que gerou a chamada do callback
     */
    getCallbackStatus(): EConfirmationType;
    getMktExecutionInfo(): IMktExecutionInfo;
    getStatus(): Promise<EConfirmationType>;
}

export enum EConfirmationType {
    colmeiaSent = 'csent', // Colmeia enviou
    sent = 'sen', // Provedor enviou
    read = 'rea', // ESTE PARA WA, FB e EMAIL, VOZ
    receive = 'rec', //ESTE para TODOS
    failed = 'fail', // falha na entrega
    queued = 'qued', // Sinch nos informa quando colocou na fila deles
    viewMedia = 'vm',
    channelClosed = 'cclo',
    span = 'spam',
    respond = 'respond',
    invalidNumber = 'invalidNumber',
    clicked = 'clicked',
    bounce = 'bounce',
    ignoredByActiveConversation = 'ignByAcC',
    ignoreByFilter = 'ignByFilter',
    noProcessFromColmeia = 'noProcssFrmColm',
    notRead = 'notRead',
    notAnswered = 'notAnswered',
    brokerOptOut = 'brokerOptOut',
    invalidSession = 'invalidSession',
    ignored = 'ignored',
    error = 'error'
};

declare const parameters: IUserFunctionInjection;

export enum EGenerativeAIPluginModel {
    imageGenerator = 'imageGenerator',
    analyticData = 'analyticData',
    financialAnalysis = 'financialAnalysis',
}

export interface CharacterSplitterConfig {
    size: number;
    look: number;
    rightDelimiters: (| string | RegExp)[];
    leftDelimiters: (| string | RegExp)[];
}

export interface CharacterSplitterItem {
    start: number;
    end: number;
    text?: string;
    size?: number;
}

export enum KBAssetType {
    media = 'media',
    content = 'content',
    contentAPICall = 'apiCall',
    generativo = 'generativo',
}

export interface IToBeSentBotMessage {
    message?: string
    idMedia?: string;
    type: KBAssetType;
}

export type TIToBeSentBotMessageArray = Array<IToBeSentBotMessage>

export enum EDiscagemDiretaInternacional {
    brazil = '55'
}
export interface IDateOperations {
    isInsideInterval: boolean;
    isWithinPersonalizedLastMinutes?: boolean;
    errorMessage?: string;
}
export type TISODateString = string;
export enum EAttendanceDaysHash {
    sunday = 0,
    monday = 1,
    tuesday = 2,
    wednesday = 3,
    thursday = 4,
    friday = 5,
    saturday = 6,
}

export type AttendanceDaysHash = {
    [key in EAttendanceDaysHash]: IInterval[]
}
export interface IInterval extends INoOffsetInterval {
    /**
     * Timezone
     */
    offset: number;
}
export interface INoOffsetInterval {
    begin: number;
    end: number;
}

/**
 * trim: defaults to true.
 * validCharacterRegex: defaults to /^[A-Za-zàáâãäåòóôõöøèéêëçìíîïùúûüÿñ\s]*$/
*/
export interface IStringRuleValidator {
    minWords: number // quantidade minima de palavaras
    trim?: boolean; //default true
    validCharacterRegex?: RegExp // default /[A-Za-zàáâãäåòóôõöøèéêëçìíîïùúûüÿñ]/
    minWordLength?: number,
    maxCharsSequenceRepeated?: number
}

export interface IUserFunctionValidatorRuntime {
    submitLogs: () => Promise<void>;
    isValidCountryStateCode: (ddi: EDiscagemDiretaInternacional, input: string | undefined) => boolean;
    /**
     *
     * @param value - The number or string to be validated.
     * @param decimalSign - The decimal sign, ',' or '.'. Default is ','.
     * @param demandGrouping - Whether to demand grouping separator. Default is true.
     * @param decimalSize - The number of decimal places. Default is 2.
     * @returns boolean True if the value is a valid float number, false otherwise.
     */
    isValidFloatNumber: (
        value: number | string | undefined,
        decimalSign?: ',' | '.',
        demandGrouping?: boolean,
        decimalSize?: number
    ) => boolean;
    addToRealTimeFusionIfValid: (
        key: string,
        value: | number
            | string
            | boolean
            | undefined
            | null
    ) => void;
    /**
     * @param userInput - City name
     * @param matchingCitiesList - A array of strings with city names
     * @returns boolean
     */
    isACityValidAndHasOnlyBrazilianCharacters: (userInput: string | undefined, matchingCitiesList: string[]) => boolean;
    removeFromRTF: (id: string) => void;
    /**
     * @param idDataBase
     * @param predicates - An object containing one or more propeties with keys and values. The object keys must contain all primary keys property code.
     * @param returningColumn
     * @returns The value of the column if the ID is from a valid DB and the predicates (primary keys) are fulfilled correctly.
     *
     * @example getColumnValueFromDB('q17ezpUrTIv8On6ff6Ajmg2ajblEng', { "telefone": "82988447880" }, "nome")
     */
    getColumnValueFromDB: (
        idDataBase: string,
        predicates: IGeneralFormAnswerJson,
        dbColumnName: string | undefined
    ) => Promise<string | undefined>;
    /**
     * @param phoneNumber - string or number that must has the International code (DDI). Ex: '551133445566', '5571988774455', 5511988776655, 557133445566
     * @returns The DDD - local code for brazilian numbers. Ex: '11', '71'
     */
    extractDDD: (phoneNumber: string | number | undefined) => string | undefined;
    /**
     * @param dateString  ex: '2024-02-07T15:55:46.499Z'
     * @returns  ex: '12/01/2023'
     */
    dateNormalizer: (dateString: string | undefined) => string | undefined;
    /**
     * @param value receives a string that may be a valida number or not. Ex: '120.000,30'
     * @returns returns the inputed value as a Number if the input is a valid number: 120000.30
     */
    fromBrValueStrToNumber: (value: string | undefined) => number | undefined;
    /**
     * @param idCalendar
     * @param dateTarget TISODateString ex: 2024-01-05T19:50:47.367Z
     * @param lastMinutesInterval - Optional parameter. Number of minutes. It can be either a positive or negative number.
     * @returns It returns a object which has isInsideInterval property and if the lastMinutesInterval parameters is passed the returned object will also contain a property called isWithinPersonalizedLastMinutes which states if the target date is inside this lastMinutesInterval.
     *
     * Usage example: checksIfTimeIsInASpecificInterval('XWy4go72TYqYrwyxkrQa8BQ7coR7CArf', new Date().toISOString(), 10)
     */
    checksIfTimeIsInASpecificInterval: (
        idCalendar: IdDep<ENonSerializableObjectType.attendanceCalendar>,
        dateTarget: TISODateString,
        lastMinutesInterval?: number
    ) => Promise<IDateOperations>;
    /**
     * @param input - A string to be validate. It can be one word or a phrase.
     * @param rules - A object containing the rules which will be applied to the input to be validate.
     * @returns - Returns a boolean if the input match the rules passed.
     *
     *
     * Rules default values if rules are not passed.
     *  {
     *      trim: true,
     *      maxCharsSequenceRepeated: 3,
     *      minWordLength: undefined,
     *      validCharacterRegex: /[A-Za-zàáâãäåòóôõöøèéêëçìíîïùúûüÿñ]/,
     *      minWords: 2
     *  }
     */
    stringRuleValidator: (input: string, rules: IStringRuleValidator) => boolean;
    setGenerateLog: (input: boolean) => void;
}
export interface IMktExecutionInfo {
    idCampaign: string;
    idCampainAction: string;
    idRunCampaign: string;
    idMassEntry: string;
    templateText?: string;
    templateClickedBtnLabel?: string;
    templateSentClocktick?: number;
    userInput: string;
    sourceAddress: string;
    targetAddress: string;
    referral?: string;
    mktRow: IFileField;
    rtfRow: IFileField;
    idBot?: string;
}

export interface ICRMTicketActionRuntimeInfo {
    bpmActionId: string;
    idInteraction?: string;
}

export interface ITicketAttachedInfo {
    idTicket: string;
    idProject?: string;
    actionData?: ICRMTicketActionRuntimeInfo;
}

export interface IMassCommEntry {
    idFiredCampaing: string;  // idRunCampaign
    idMassEntry: string;
    idSocialContext: TGlobalUID;
    idCampaing: string;
    idCampaigAction: string;
    idBot: string;
    clockTick: number;
    target: string;
    scheduledTo: number;
    strategy: TCommExecution;
    sendToAllChannels: boolean;
    lastChannel: EDelivery360Action;
    isDelivered: boolean;
    idAvatar: TGlobalUID;
    responded: number;
    dontSendMessageIfTemplateSent?: boolean;
    isError: boolean;
    error: Error[];
    form: TSchemaPropertyArray;
    templateSent: IMassOpenChannel;
    messageSent: IMassOpenChannel;
    billedTemplate: IMassOpenChannel;
    openChannel: IMassOpenChannel;
    activeCallAvatar: TGlobalUID;
    idConversation: string;
    engagementSuccess?: EConfirmationType;
    variables: TICampaingVariableConfigArray;
    idAgentAvatar?: TGlobalUID;

    idSocialKey?: string;

    rtfForm?: IRTF1x1Form;
    // campos setados caso haja callback
    callbackInfo?: IExecuteCallbackInfo;
    idBPMMKT?: string;
    idCBMessage?: string; // Será o ID da mensagem que efetivamente irá ser processado quando houver callback (lido)

    cancel?: IEntryCancelReason; // Tirar do index pf

    idWaitingCampaignAsMessageEvent? : string

    template?: {
        idTemplate: IdDep<ENonSerializableObjectType.channelTemplate>;
        hashVariables: IFileField;
    }

    templateText?: string;
    templateSentClocktick?: number;

    ticketInfo?: ITicketAttachedInfo

}

export interface TCommExecution {
    elements: TCommElementArray
}

export type TCommElementArray = Array<ICommElement>;

export interface ICommElement {
    target: string;
    channel: EDelivery360Action;
    idChannel?: IdDep<ENonSerializableObjectType.socialMediaConnection>;
    clockTick: number;
}

export interface IRTF1x1Form {
    form: TSchemaPropertyArray;
    answer: TGeneralFieldArray;
    idMetadata: string;
}

export type IMassOpenChannel = {
    [key in EDelivery360Action]?: boolean
}
export interface ICampaingVariableConfig {
    idLocalCanonical: IdDep<ENonSerializableObjectType.canonical>
    isGreetings?: boolean;
    value?: string;
}

export type TICampaingVariableConfigArray = Array<ICampaingVariableConfig>;

export type IEntryCancelReason = {
    cancelFilter: boolean;
    cancelOptOut: boolean;
    cancelRecency: boolean;
    cancelActiveChat: boolean;
    cancelEngagement: boolean;
    cancelSchedule: boolean;
    cancelByUser?: boolean;
    cancelReschedule?: boolean;
}

export interface ICallbackInfo {
    type: ECallbackType;
    /**
     * Se o type for ECallbackType.defaultRoute a propriedade idRouteCallback torna-se obrigatória.
     * Se o type for ECallbackType.custom a propriedade idFunction e confirmationTypeList tornam-se obrigatória.
     */
    idRouteCallback?: string;
    idFunction?: string;

    /**
     * mustUseConfirmationCallbacksSubset só é visível se idFunction for preenchido.
     */
    mustUseConfirmationCallbacksSubset?: boolean
    /**
     * A confirmationTypeList é brigatória se o mustUseConfirmationCallbacksSubset for true.
     */
    confirmationTypeList?: EConfirmationType[];
}


export interface IExecuteCallbackInfo extends ICallbackInfo {
    row: TGeneralFieldArray
}

export enum ECallbackType {
    defaultRoute = 'defroute',
    custom = 'custom',
}

export interface IUserFunctionTriggerInfo {
    getServiceEvent(): EBotEventType;
}

export enum EBotEventType {
    customerExpire = 'actType.custExp',
    commandText = 'actType.commandText',
    onFinish = 'actType.onFinish',
    onQueueAdd = 'onQueueAdd',
    onQueueOnBegin = 'onQueueOnBegin',
    onQueueOnFinish = 'onQueueOnFinish',
    onAgentNotAvailable = 'onAgtNotAvail',
}

export enum EBotRunningType {
    normal = 'normal',
    survey = 'survey'
}

export type TWAFFieldDataType = TGraphPropertyData | boolean | Array<TWAFFieldDataType>

export interface IWAFRawPayload {
    value: TWAFFieldDataType,
    rawWasNull: boolean
};

export interface INormalizedWaf {
    [idProperty: string]: IWAFRawPayload;
}

export interface IWAFEphemerals {
    [flow_token: string]: INormalizedWaf;
}

export interface IDocReaderState {
  idTransformer: string;
  docType: EDocTypes;
  document: IDocumentInfo;
  lastSegment: EDOCSegment;
  alreadyRead: IReadInfo;
}

export type TIDocReaderStateArray = Array<IDocReaderState>;

export interface IDocumentInfo {
    [propertyName: string]: string;
}

export interface IReadData {
  clockTick: Miliseconds;
  segment: EDOCSegment;
  read: IDocumentInfo;
  score: number;
}
export interface IReadDataWithIdPropety extends IReadData {
  idProperty: string
}

export type TIReadData = Array<IReadData>;
export type TIReadDataWithIdPropety = Array<IReadDataWithIdPropety>;

export interface IReadInfo {
  [idProperty: string]: IReadData;
}

export interface IDocumentSegment {
    score: number;
    doc: IDocumentInfo;
}

export interface IModelExtraction {
    clockTick: Miliseconds;
    segment: EDOCSegment;
    read: {
        [modelName: string]: IDocumentSegment;
    }
}

export enum EDocTypes {
  RG = "RG",
  CNH = "CNH",
}

export enum EDOCSegment {
  front = "front",
  back = "back",
}

export enum EPaymentItemAttributes {
  idProduct = 'idProduct',
  price = 'price',
  description = 'description',
  name = 'name',
  quantity = 'quantity',
}

export interface IPaymentItem {
  [EPaymentItemAttributes.idProduct]: string;
  [EPaymentItemAttributes.price]: number;
  [EPaymentItemAttributes.description]: string;
  [EPaymentItemAttributes.name]: string;
  [EPaymentItemAttributes.quantity]: number;
}

export interface IPaymentShoppingCartItemController {
  isValidItem(item: IPaymentItem): boolean;
  addItem(items: IPaymentItem): void;
  delItem(): boolean;
  hasItem(item: IPaymentItem): boolean;
  dehydrate(): IPaymentItem[];
  addDiscount(value: number, description: string): void
}

export interface IPaymentCustomController {
    setCustomResponse(inputdata: IPaymentGatewayTransactionCustom): void
    getItems(): IPaymentItem[];
    getIdOrder(): string;
    getPayer(): IPaymentPayer;
    setPollingResult(result: IPollingResultPayment);
}

export interface IPaymentGatewayTransactionCustom {
    externalGatewayId: string;
    urlCheckout?: string;
    pixCode?: string;
    barcode?: string;
}

export interface IPaymentPayer {
  fullName?: string;
  cpfOrCnpj?: string;
  email?: string;
}

export enum EPaymentOrderStatus {
  pending = 'pending',
  processing = 'processing',
  completed = 'completed',
  canceled = 'canceled',
  expired = 'expired',
  failed = 'failed',
  refunded = 'refunded',
  chargedBack = 'charged_back',
}

export interface IPollingResultPayment {
    continueRun: boolean,
    statusColmeia: EPaymentOrderStatus,
}

export enum ETagType {
    colmeia = 'tagColmeia',
    socialNetwork = 'tagSN',
    variable = 'tagVariable',
    tagGroup = "tagGroup",
    marker = 'tagMarker',
}

export interface ICTagAdProvider {
    key: string;
    useProviderAPI: boolean;
    idTokenEntity: IdDep<ENonSerializableObjectType.waba>;
    idFunction: string;
}

export interface ICTagAdEvent {
    /**
     * Qual é o [Colmeia Form] que vai triggerar esse AdEvent */
    idMetadata: IdDep<ENonSerializableObjectType.formSchemma>;
    /** */
    idColmeiaAdEvent: string;
    /** */
    providers: ICTagAdProvider[]
}

export interface IColmeiaTagAdEvent {
    isFunnelTag: boolean;
    hasLeadScore?: boolean;
    leadScore?: number;
    adEvent?: ICTagAdEvent;
}

export interface IColmeiaTag extends INonSerializableHeader, IColmeiaTagAdEvent {
    nsType: ENonSerializableObjectType.colmeiaTags,
    tagType: ETagType;
    value?: string;
}

export interface IServerColmeiaTag extends IColmeiaTag, INonSerializable {
    nsType: ENonSerializableObjectType.colmeiaTags,
    idNS?: IdDep<ENonSerializableObjectType.colmeiaTags>;
}
export enum EConversationMessageType {
    Human = 'human',
    Bot = 'bot',
    Template = 'template',
    IslandTransfer = 'islandTransfer',
}


export type IdCampaign = IdDep<ENonSerializableObjectType.campaing>;
export type IdCampaignAction = NamedString<'IdCampaignAction'>;

export interface IBotAnswerDSRegisterCampaign {
    id: IdCampaign;
    idAction: IdCampaignAction;
    isTimelyReturned: boolean;
}

export interface IConversationMessageItem {
    iterationID: string,
    type: EConversationMessageType;
    content: string;
    readableDate: string;
    clockTick: number;
    isCustomerMessage: boolean;

    userName: string;
    idMultimedia?: TGlobalUID;
    idMedias?: TArrayID;
    sentType?: EInteractionSentType;

    isTranscription?: boolean;

    isTemplate?: boolean;
    /**
     * Template message only
     * type = EConversationMessageType.Template
     */
    templateClickedBtnLabel?: string

    /**
    * Island Transferece only
    * type = EConversationMessageType.IslandTransfer
    */
    islandName?: string
    sourceIslandName?: string

    agentName?: string;

    campaign?: IBotAnswerDSRegisterCampaign;

    hasPayment?: boolean;
    paymentTransactionId?: string;
}

export type TConversationMessageItemArray = IConversationMessageItem[];

`;


