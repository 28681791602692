<section class="chat-people-filter">

  <section class="loader" *ngIf="loading; else avatarList">
    <mat-progress-spinner
      mode="indeterminate"
      diameter="30">
    </mat-progress-spinner>
  </section>

  <ng-template #avatarList>
    <div class="person" *ngFor="let item of avatars">

      <div class="avatar-data" *ngIf="item">
        <mat-checkbox
          [checked]="selectedAvatars.includes(item.getPrimaryID())"
          (change)="peopleFilterChange($event, item)"
        ></mat-checkbox>
        <hexagon [hexagonHandler]="handlers[item.getPrimaryID()]"></hexagon>
        <div class="avatar-name">{{ item.getNickName() }}</div>
      </div>
    </div>
  </ng-template>
   
</section>
