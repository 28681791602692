import { ChangeDetectorRef, Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { Serializable } from '@colmeia/core/src/business/serializable';
import { I360Media } from '@colmeia/core/src/core-constants/bot';
import { MMconstant, MultimediaInstance, MultimediaObject } from '@colmeia/core/src/multi-media/barrel-multimedia';
import { IServerColmeiaTag, ITaggable } from "@colmeia/core/src/shared-business-rules/colmeia-tags/tags";
import { gTranslations } from '@colmeia/core/src/shared-business-rules/const-text/translations';
import { nonSerializableFriendlyNameTranslations } from '@colmeia/core/src/shared-business-rules/const-text/views/non-serializable-friendly';
import { isNSServer } from '@colmeia/core/src/shared-business-rules/non-serializable-id/non-serializable-functions';
import {
    INonSerializable, INonSerializableHeader
} from "@colmeia/core/src/shared-business-rules/non-serializable-id/non-serializable-id-interfaces";
import { getSNFromNS, safeGetSNFromNS } from '@colmeia/core/src/shared-business-rules/non-serializable-id/ns-client-functions';
import { secToMS } from '@colmeia/core/src/time/time-utl';
import { isInvalid, isInvalidArray, isValidArray, isValidFunction, isValidRef, isValidString, isValidTrimmedString, jsonDifferences, keys, objectShallowReplace, typedCloneLodash } from '@colmeia/core/src/tools/utility';
import { ButtonAlertComponent } from 'app/components/button-alert/button-alert.component';
import { HexagonUploaderParameterDefaults } from 'app/components/foundation/hexagon-uploader/hexagon-uploader.model';
import { RootComponent } from 'app/components/foundation/root/root.component';
import { HexagonUploaderHandler } from 'app/handlers/hexagon-uploader-handler';
import { EHexagonSizes } from 'app/handlers/hexagono.handler';
import { NavigatorServices } from 'app/services/controllers-services/navigator/navigator.service';
import { DateService } from 'app/services/date.service';
import { GlobalWarningService, INTERACTIVE_OK } from 'app/services/global-warning.service';
import { LookupService } from 'app/services/lookup.service';
import { MultimediaService } from 'app/services/multimedia.service';
import { ENserDeleteResult, NsDeleterService } from 'app/services/ns-deleter-service';
import { RoutingService } from 'app/services/routing.service';
import { ServerCommunicationService } from 'app/services/server-communication.service';
import { SessionService } from 'app/services/session.service';
import { EAppAlertTypes, SnackMessageService } from 'app/services/snack-bar';
import { TitleService } from 'app/services/title.service';
import { copyToClickBoardSync } from 'app/utils/copy';
import { debounce } from 'lodash';
import {
    GenericDashboardEditHandler,
    IGenericDashboardEditComponent, IGenericDashboardEditCustomButton, IGenericDashboardEditHandlerParameter
} from "../../../../handlers/generic-dashboard-edit.handler";
import {
    AllNsOptionsBoolean,
    NsMoreOptionsClientCallback,
    NsMoreOptionsHandler
} from "../../../../handlers/ns-more-options.handler";
import { ITaggableHandlerClientCallback, TaggableHandler } from "../../../../handlers/taggable.handler";
import { NSValidationService } from "../../../../services/nser-validation.service";
import { environment } from './../../../../../environments/environment-client';

export enum EDesynchronizationResponse {
    Cancel = 'cancel',
    Sync = 'sync',
    Save = 'save',
}

@Component({
    selector: 'cm-generic-dashboard-edit',
    templateUrl: './generic-dashboard-edit.component.html',
    styleUrls: ['./generic-dashboard-edit.component.scss'],
    // changeDetection: ChangeDetectionStrategy.OnPush
})
export class GenericDashboardEditComponent
    extends RootComponent<'remove'>
    implements
    OnInit,
    NsMoreOptionsClientCallback,
    ITaggableHandlerClientCallback,
    IGenericDashboardEditComponent {
    static loadingIndicatorTimeout: number = secToMS(5);
    public nsTypeName: string;
    public _hasMoreOptions: boolean;
    lastTouchClocktick: number;


    get nser() { return this.parameters.nser; }
    backup: INonSerializableHeader

    private _handler: GenericDashboardEditHandler;
    validationErrors: string[] = [];
    isUsingTags: boolean = true;
    botElementHexagonHandler: HexagonUploaderHandler;
    saving: boolean = false;
    _titleName: string;
    initialNSName: string;

    @ViewChild("dialogErrors")
    dialogErrorsTemplateRef: TemplateRef<unknown>;

    @ViewChild("saveButtonAlert", { static: false })
    saveButtonAlert: ButtonAlertComponent;

    private previousTitle: string = this.titleSvc.getTitle();

    @Input()
    set handler(val: GenericDashboardEditHandler) {
        console.log({GenericDashboardEditHandler: val, parameters: val.getComponentParameter()})
        this._handler = val;

        this.preventObjectMigrationByOpenedInAnotherSocialnetwork();

        this._handler.setSlave(this);

        if (isValidRef(this.parameters.nser)) {
            this._titleName = this.parameters.nser.nName
        }

        if (this.hasTaggable) {
            this.generateTagPickerHandler();
        }

        const { serializableId, idField } = nonSerializableFriendlyNameTranslations[this.parameters.nsType];
        this.nsTypeName = Serializable.staticFactory(serializableId).getSerializableText(idField);
        console.log({serializableId, idField, nsType: this.parameters.nsType, nsTypeName:this.nsTypeName, serializable: Serializable.staticFactory(serializableId) })

        this._handler.setNsTypeName(this.nsTypeName);


        if (!this.parameters.removeHexagon) {
            this.botElementHexagonHandler = this.generateHexagonUploaderHandler();
        }

        if (this.parameters.allowEditTitleInHeader) {
            this.initialNSName = this.parameters.nser.nName;
        }

        if (this.parameters.removeMoreOptions) {
            this._hasMoreOptions = false;
        } else {
            this._hasMoreOptions = (isValidRef(this.parameters.moreOptions) && !!keys(this.parameters.moreOptions).length) || isValidRef(this.parameters.moreOptionsHandler);
            this._hasMoreOptions && this.initializeMoreOptionsHandler();
        }

        if (this.shouldUpdateTitle()) {
            this.titleSvc.setTitle((this.parameters.nser.nName || `Novo: ${this.nsTypeName}`))
        }

        if (this.parameters.isWindowMode && isValidRef(this.parameters.dialogRef)) {
            this.parameters.backButtonIcon = 'close';
            this.parameters.windowButtons = [
                {
                    icon: "minimize",
                    onClick: () => {
                        this.parameters.dialogRef?.minimize();
                    }
                }
            ];
        }

        this.lastTouchClocktick = (this.parameters.nser as INonSerializable).lastTouch;
        this.backup = typedCloneLodash(this.parameters.nser);
    }

    get handler(): GenericDashboardEditHandler {
        return this._handler;
    }

    nsMoreOptionsHandler: NsMoreOptionsHandler;

    @ViewChild("removalDialog") removalDialog: TemplateRef<any>;

    constructor(
        private multimediaSvc: MultimediaService,
        private cdr: ChangeDetectorRef,
        protected validator: NSValidationService,
        private snack: SnackMessageService,
        private warning: GlobalWarningService,
        private nsDeleter: NsDeleterService,
        private navigatorSvc: NavigatorServices,
        private sessionSvc: SessionService,
        private bottomSheet: MatBottomSheet,
        private titleSvc: TitleService,
        private lookupSvc: LookupService,
        private dateSvc: DateService,
        private serverComSvc: ServerCommunicationService,
        private routingSvc: RoutingService,
    ) {
        super({
            remove: gTranslations.common.remove
        })
    }

    get differences() {
        const out = jsonDifferences(this.backup, typedCloneLodash(this.nser));
        return out;
    }

    taggableHandler: TaggableHandler;

    private preventObjectMigrationByOpenedInAnotherSocialnetwork() {
        if (this.parameters.disableSNCheck) return;

        if (isValidRef(this.parameters.nser) && isNSServer(this.parameters.nser) && isValidString(this.parameters.nser.idNS)) {
            const idSN = getSNFromNS(this.parameters.nser);
            if (idSN !== this.sessionSvc.getCurrentSocialNetworkID()) {
                this.warnAndChangeSocialNetwork();
            }
        }
    }

    private generateTagPickerHandler(): void {
        this.taggableHandler = new TaggableHandler({
            clientCallback: this,
            compact: true,
            shouldUseDebouncer: this.parameters.shouldUseDebouncer,
            taggable: (isValidRef(this.parameters.customTaggableElement)) ? this.parameters.customTaggableElement : this.parameters.nser
        })

        this.handler._taggableHandler.next(this.taggableHandler);
        this.cdr.markForCheck();
    }

    onChangeTaggableCallback(taggable: ITaggable): void {
        this.cdr.markForCheck();
    }

    onFinishSelectionCallback(selectedTags?: IServerColmeiaTag[]) {
        if (isValidFunction(this.parameters?.clientCallback?.onGenericSaveTags)) {
            this.parameters.clientCallback.onGenericSaveTags(selectedTags);
        }

        this.cdr.markForCheck();
    }

    public get removeGoBackButton(): boolean {
        return this.parameters.removeGoBackButton;
    }

    public get customSaveButton(): IGenericDashboardEditCustomButton {
        return this.parameters.customSaveButton;
    }

    public get buttons(): IGenericDashboardEditCustomButton[] {
        return this.parameters.buttons;
    }

    public get headerless(): boolean {
        return this.parameters.headerless;
    }

    private initializeMoreOptionsHandler(): void {
        if (isValidRef(this.parameters.moreOptionsHandler)) {
            this.nsMoreOptionsHandler = this.parameters.moreOptionsHandler;
            return;
        }

        const nser: INonSerializable = <INonSerializable>this.parameters.nser;
        const options: Partial<AllNsOptionsBoolean> = this.parameters.moreOptions;
        this.nsMoreOptionsHandler = new NsMoreOptionsHandler({
            clientCallback: this,
            options,
            nser: nser
        });

        this._handler._moreOptionsHandler.next(this.nsMoreOptionsHandler);
        this.cdr.markForCheck();
    }

    public onNsMoreOptionsCopyClicked(ns: INonSerializable) {
        if (isValidFunction(this.parameters?.clientCallback?.onGenericCopyClicked)) {
            this.parameters.clientCallback.onGenericCopyClicked(ns);
            return;
        }
        copyToClickBoardSync(ns.idNS);
        this.snack.open({
            duration: 2000,
            type: EAppAlertTypes.Success,
            message: 'Copiado para clipboard'
        });
    }


    async onNsMoreOptionsDeleteClicked(nser: INonSerializable): Promise<boolean | void> {
        if (isValidFunction(this.parameters.clientCallback.onGenericDeleteClicked)) {
            await this.parameters.clientCallback.onGenericDeleteClicked();
        } else {
            const { result } = await this.nsDeleter.delete(nser);
            if (ENserDeleteResult.Success === result) {
                this.snack.open({
                    message: `${nser.nName} removido`,
                    action: "Fechar",
                    duration: 4000
                })

                return true;
            }
        }
    }

    onNsMoreOptionNSArchived() {
        this.emitSave();
    }

    ngOnInit() {
    }

    ngOnDestroy() {
        if (!this.shouldUpdateTitle()) return;

        this.titleSvc.resetTitle();
    }

    get hasMoreOptions(): boolean {
        return this._hasMoreOptions;
    }

    async remove() {
        if (this.parameters.requestRemovalConfirmation) {
            if (!(await this.warning.askSureNSerRemoval(this.parameters.nser.nName))) {
                return
            }
        }

        this.parameters.clientCallback.onGenericDeleteClicked();
    }

    public get hasTitle(): boolean {
        return isValidRef(this.parameters.title);
    }

    get parameters(): IGenericDashboardEditHandlerParameter {
        return this.handler.getComponentParameter();
    }


    async goBack(): Promise<void> {
        const { onGenericBackButtonPressed } = this.parameters.clientCallback;

        if (isValidFunction(onGenericBackButtonPressed)) {
            this.parameters.clientCallback.onGenericBackButtonPressed();
            return;
        }

        if (this.parameters.isWindowMode && isValidRef(this.parameters.dialogRef)) {
            this.parameters.dialogRef.close();
            return;
        }
    }

    private isValidWithOrWithoutPosValidationsCb(): boolean {
        const posValidation = this.parameters.posValidation;
        const isValidPosValidation = typeof posValidation === 'function' ? posValidation() : undefined;
        const isUndefined = isValidPosValidation === undefined;

        const isValidResult: boolean = isUndefined 
            || isValidPosValidation;

        if(!isValidResult) {
            const message = isValidTrimmedString(this.parameters.posValidationErrMsg)
                ? this.parameters.posValidationErrMsg
                : "Verifique todos os campos preenchidos, há um erro de configuração.";
            this.snack.openError(message, 3000);
        }
    
        return isValidResult;
    }

    async emitSave(saveButtonAlert?: ButtonAlertComponent): Promise<void> {
        const { onGenericSaveButtonPressed } = this.parameters.clientCallback;

        if (
            !isValidFunction(onGenericSaveButtonPressed) 
                ||  (this.parameters.autoValidate 
                    && !this.handler.validateBeforeSave() 
                )
                || !this.isValidWithOrWithoutPosValidationsCb()
        ) {
            await this.parameters.clientCallback.onValidationRejected?.(this.validationErrors);
            return;
        }

        try {
            this.saving = true;
            this.cdr.markForCheck();

            const shouldStop: boolean = false //await this.shouldStopSave();

            if (shouldStop) {
                this.saving = false;
                saveButtonAlert?.hide();
                this.cdr.markForCheck();
                return;
            }

            this.loadingStateTimeoutHandler();

            const response = await this.parameters.clientCallback.onGenericSaveButtonPressed?.();
            if (response) {
                saveButtonAlert?.show({
                    message: "Salvo!",
                    duration: 3000,
                    theme: "success"
                });
                this.initialNSName = this.parameters.nser.nName;

            }

            const nser = this.parameters.nser as INonSerializable;

            if (isValidString(nser.idNS)) {
                const fromServer: INonSerializable = await this.lookupSvc.getNS(nser.idNS);

                if (fromServer) {
                    this.lastTouchClocktick = fromServer.lastTouch;
                    this.backup = fromServer;
                }
            }

        } finally {
            this.saving = false;
            this.cdr.markForCheck();
        }
    }

    private async shouldStopSave(): Promise<boolean> {
        const nser = this.parameters.nser as INonSerializable;

        if (!isValidString(nser.idNS)) {
            return false;
        }

        const fromServer: INonSerializable = await this.lookupSvc.getNS(nser.idNS);

        if (fromServer?.lastTouch === nser.lastTouch) return false;

        const action = await this.alertChangeDesynchronization(nser, fromServer);

        if (action === EDesynchronizationResponse.Sync) {
            this.initialNSName = fromServer.nName;
            objectShallowReplace(nser, fromServer);
            return true;
        }

        return action === EDesynchronizationResponse.Cancel;
    }

    private async alertChangeDesynchronization(
        localNS: INonSerializable,
        fromServerNS: INonSerializable,
    ): Promise<EDesynchronizationResponse> {

        const localDateReadable: string = this.dateSvc.getDateAndTimeString(localNS.lastTouch);
        const serverDateReadable: string = this.dateSvc.getDateAndTimeString(fromServerNS.lastTouch);

        const message: string = `Esse item está desatualizado.\n\n` +
            `Última atualização do item no servidor:\n📅 [b]${serverDateReadable}[/b]\n\n` +
            `Data da versão local:\n📅 [b]${localDateReadable}[/b]\n\n\n`;

        return this.warning.showInteractivePrompt<EDesynchronizationResponse>(
            {
                title: "Atenção!", message, options: [
                    {
                        option: EDesynchronizationResponse.Cancel,
                        text: "Fechar",
                        color: "primary",
                        style: "stroked",
                        matIcon: "cancel",
                    },
                ], disableClose: true, matIcon: {
                    icon: "warning_amber",
                    color: "#c7c700"
                }
            }        );
    }

    loadingStateTimeoutHandler = debounce(() => {
        this.saving = false;
        this.cdr.markForCheck();
    }, GenericDashboardEditComponent.loadingIndicatorTimeout);

    get hasSaveButton(): boolean {
        return isInvalid(this.parameters.removeSaveButton);
    }

    get hasTaggable(): boolean {
        return isInvalid(this.parameters.removeTaggable);
    }

    get preventSave(): boolean {
        return isValidRef(this.parameters.clientCallback.preventSave) && this.parameters.clientCallback.preventSave;
    }

    get hasHexagon(): boolean {
        return isInvalid(this.parameters.removeHexagon);
    }

    get hasBox(): boolean {
        return isInvalid(this.parameters.removeEntireBox);
    }

    private generateHexagonUploaderHandler(): HexagonUploaderHandler {

        if (isInvalid(this.parameters.nser)) {
            this.parameters.nser = <INonSerializable>{
                nsType: null,
                medias: [],
                ident: null
            }
        }

        return HexagonUploaderHandler.createNonSerializable({
            ...HexagonUploaderParameterDefaults,
            size: EHexagonSizes.xsm,
            idTag: MMconstant.tag.hexagonon,
            onMultimediaObjectChange: () => this.cdr.markForCheck(),
            idMediaEditing: (isValidArray(this.parameters.nser.medias)) ? this.parameters.nser.medias[0].idMedia : undefined,
            multimediaObject: MultimediaObject.getNewMultimediaObjectFrom360(this.parameters.nser.medias || []),
            onFileSelected: async (mm: MultimediaInstance) => {
                if (isInvalidArray(this.parameters.nser?.medias)) {
                    this.parameters.nser.medias = [];
                }

                await this.multimediaSvc.genericUploadFile(mm, MMconstant.tag.photo);

                const media: I360Media = {
                    ...mm.getI360Media(),
                    isThumb: true,
                }

                if (isInvalidArray(this.parameters.nser.medias)) {
                    this.parameters.nser.medias = [media]
                } else {
                    this.parameters.nser.medias[0] = media;
                }
                this.cdr.markForCheck();
            },
            onMediaDeleted: (mm: MultimediaInstance) => {
                this.parameters.nser.medias = [];
                this.cdr.markForCheck();
            }
        });
    }

    onSaveClickedValidation(): boolean {
        return this.validateNonSerializable();
    }

    validateNonSerializable(): boolean {
        this.validationErrors = this.validator.getErrorsAsStringFrom(
            this.parameters.nser,
            this.parameters.nsType
        );

        if (isValidArray(this.validationErrors)) {
            this.bottomSheet.open(this.dialogErrorsTemplateRef, {
                panelClass: "ns-errors-display-bottom-sheet"
            });
        }

        return this.validationErrors.length === 0
    }

    get backButtonIcon(): string {
        return this.parameters.backButtonIcon || 'keyboard_backspace'
    }

    titleNameReset() {
        this.parameters.nser.nName = this.initialNSName;
        this.cdr.markForCheck();
    }

    hasTitleChange(): boolean {
        return this.initialNSName !== this.parameters.nser?.nName;
    }

    isAllDev(): boolean {
        return environment.allDevFeatures
    }

    public getCustomButtonIcon(button: IGenericDashboardEditCustomButton): string {
        return isValidFunction(button.icon) ? button.icon() : button.icon;
    }

    public get blockSave(): boolean {
        return !!this.handler.getComponentParameter().blockSave?.();
    }

    public hasChange(): boolean {
        return (!!this.handler.getComponentParameter().hasChange?.()) || false;
    }

    private async warnAndChangeSocialNetwork() {

        const hasChangeNetworkError: boolean = this.serverComSvc.lastFriendlyMessageError?.containsErrorId('mustBeAParticipantToExecute');

        if (hasChangeNetworkError) {
            this.warning.closeAll();
            await this.warning.showInteractivePrompt(
                {
                    title: "Ops", message: "Você não tem acesso à essa rede social", options: [INTERACTIVE_OK], disableClose: true, matIcon: {
                        icon: 'priority_high',
                        color: 'yellow',
                    }
                });
            this.routingSvc.goToGroupHome(this.sessionSvc.getCurrentSocialNetworkID());
            return;
        }

        await this.warning.showInteractivePrompt(
            {
                title: "Atenção", message: "[b]Esse item pertence a outra rede social.[/b]\n\n[i]Iremos alterar para a rede social do item em edição.[/i]", options: [INTERACTIVE_OK], disableClose: true, matIcon: {
                    icon: 'priority_high',
                    color: 'yellow',
                }
            }        );

        const nser: INonSerializable = (this.parameters.nser as INonSerializable);

        await this.navigatorSvc.changeSocialNetwork(safeGetSNFromNS(nser));
    }

    get helpTipKey(): string {
        return this.parameters.helpTipKey;
    }

    private shouldUpdateTitle(): boolean {
        return !(this.parameters.isWindowMode || this.parameters.disableTitleChange);
    }
}
