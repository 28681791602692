<div class="custom-var-inserter">
  <div>
    <div class="search-selector-container">
      <div class="search-selector-wrapper">
        <mat-icon>search</mat-icon>
        <input
          #insertVarInput
          class="search-selector"
          (keyup.enter)="addTokenValueByInput(insertVarInput)"
          (input)="filterVariables(insertVarInput)"
          placeholder="Nome da variável"
        />
      </div>
      <button
        mat-flat-button
        color="primary"
        style="text-align: center"
        (click)="addTokenValueByInput(insertVarInput)"
        class="custom-var-create-button"
      >
        <mat-icon style="height: 20px; width: 20px; font-size: 20px"
          >add</mat-icon
        >
        Criar
      </button>
    </div>
    <div *ngIf="hint" class="hint">{{ hint }}</div>
  </div>

  <div class="line"></div>
  <mat-selection-list
    *ngIf="!filteredParamsVariables"
    #selectVar
    [multiple]="false"
    (selectionChange)="insertVariable(selectVar)"
    class="dense nice-scroll"
    style="padding: 0px; max-height: 180px; overflow: auto;"
  >
    <mat-list-option
      *ngFor="let paramVar of _paramVariables; let index = index"
      [value]="paramVar"
      togglePosition="before"
      style="width: 100%"
    >
      <div style="display: flex; align-items: center; justify-content: space-between; width: 100%">

        {{ paramVar.value }}

        <button mat-icon-button color="primary" (click)="removeVariableFromList(index)">
          <mat-icon  class="mat-18">delete</mat-icon>
        </button>
      </div>
    </mat-list-option>
  </mat-selection-list>
  <mat-selection-list
    *ngIf="filteredParamsVariables"
    #selectVarFil
    [multiple]="false"
    (selectionChange)="insertVariable(paramValueInput, selectVarFil)"
    class="dense nice-scroll"
    style="padding: 0px; max-height: 180px; overflow: auto;"
  >
    <mat-list-option
      *ngFor="let paramVar of filteredParamsVariables; let index = index"
      [value]="paramVar"
      togglePosition="before"
      style="width: 100%"
    >
      <div style="display: flex; align-items: center; justify-content: space-between; width: 100%">
        {{ paramVar.value }}

        <button mat-icon-button color="primary" (click)="removeVariableFromList(index)">
          <mat-icon class="mat-18">delete</mat-icon>
        </button>
      </div>
    </mat-list-option>
  </mat-selection-list>
</div>
