<button mat-flat-button
  color="accent"
  (click)="selectFilter(null)"
  [class.not-active]="!isFilterActive(null)"
>Todas</button>

<button mat-flat-button
  color="accent"
  (click)="selectFilter(EAttendanceChannelStatusTypeFilter.Active)"
  [class.not-active]="!isFilterActive(EAttendanceChannelStatusTypeFilter.Active)"
>Ativas</button>

<button mat-flat-button
  color="accent"
  (click)="selectFilter(EAttendanceChannelStatusTypeFilter.ChannelClosed)"
  [class.not-active]="!isFilterActive(EAttendanceChannelStatusTypeFilter.ChannelClosed)"
>
  Fila de Espera

  <span class="channel-closed-count">
    {{ channelClosedCount }}
  </span>
</button>
