import React, {
    MouseEventHandler,
    useCallback,
    useEffect,
    useState,
} from "react";
import { Handle, Position, useEdges } from "reactflow";
import { IBasicEdgeData, IBasicNodeData } from "../react-nodes.model";
import { INLPTransaction } from "@colmeia/core/src/shared-business-rules/knowledge-base/bot-transaction/bot-transaction";
import { IMLCLUIntent } from "@colmeia/core/src/shared-business-rules/knowledge-base/clu-core-interfaces";
import {
    isInvalid,
    isInvalidArray,
    isValidRef,
} from "@colmeia/core/src/tools/barrel-tools";
import { KBAssetType } from "@colmeia/core/src/shared-business-rules/bot/bot-asset-model";
import { KnowledgeBaseService } from "app/services/knowledge-base.service";
import { EBotActionType } from "@colmeia/core/src/shared-business-rules/bot/new-bot-action";
import { BpmRulesNestedAIService } from "app/services/bpm/bpm-subject-rules/bpm-rules-nested-ai.service";

export interface INestedAIRootCustomNodeData {
    kbSvc: KnowledgeBaseService;
    getClickFunctions: (
        intents: Array<IMLCLUIntent>
    ) => Map<string, MouseEventHandler>;
}

export interface INestedAIRootNodeData
    extends IBasicNodeData,
        INestedAIRootCustomNodeData {}

export function NestedAIRootNode({ data }: { data: INestedAIRootNodeData }) {
    const MAX_INTENTS = 5;
    const { graphElement, htmlHandler, kbSvc, getClickFunctions } = data;
    const hostedObjectNS = graphElement.getHostedObject() as INLPTransaction;
    const iconUrl = htmlHandler.getIconUrlByType(graphElement.getHostedType());

    const [hostedObject, setHostedObject] = useState<INLPTransaction>(null);
    const [intents, setIntents] = useState<Array<IMLCLUIntent>>([]);
    const [clickFunctions, setClickFunctions] =
        useState<Map<string, MouseEventHandler>>(null);
    const [maxIntents, setMaxIntents] = useState(MAX_INTENTS);
    const [expandButton, setExpandButton] = useState({
        currentState: "collapsed",
        customClass: "more",
        text: "Mostrar mais",
    });

    const edges = useEdges<IBasicEdgeData>();
    const isGraphRoot = (
        data.bpmSvc.getCurrentStrategy() as BpmRulesNestedAIService
    ).isGraphRoot(graphElement);

    const handleExpandButton = (shouldExpand: boolean = true) => {
        if (shouldExpand)
            setExpandButton({
                currentState: "expanded",
                customClass: "less",
                text: "Mostrar menos",
            });
        else
            setExpandButton({
                currentState: "collapsed",
                customClass: "more",
                text: "Mostrar mais",
            });
    };

    const toggleExpandButton = () => {
        if (expandButton.currentState === "collapsed") {
            setMaxIntents(intents.length);
            handleExpandButton(true);
        } else {
            setMaxIntents(MAX_INTENTS);
            handleExpandButton(false);
        }
    };

    const fetchData = useCallback(async () => {
        if (isInvalid(hostedObjectNS)) return;
        const respose = await kbSvc.getKBInfo(hostedObjectNS.nlp.idKB);
        const intents = respose.kb.app.intents.filter(
            (intent) => intent.intentName !== "None"
        );

        setHostedObject(hostedObjectNS);
        setIntents(intents);
        setClickFunctions(getClickFunctions(intents));

        if (intents.length < MAX_INTENTS) setMaxIntents(intents.length);
        else setMaxIntents(MAX_INTENTS);

        handleExpandButton(false);
    }, [hostedObjectNS, kbSvc]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    return (
        <>
            <div className="nestedAIRoot-reactflow-node-wrapper">
                <div
                    className="element-container bpmNestedAI"
                    id={htmlHandler.getElementsIDs().container}
                >
                    <div className="node-type">Gerador NLP</div>
                    <div className="node-header">
                        <div className="content top">
                            <img src={iconUrl} className="title-icon" />
                            <h5 className="title">
                                {graphElement.getHostedName()}
                            </h5>
                        </div>
                        <div className="content bottom">
                            {
                                <span
                                    dangerouslySetInnerHTML={{
                                        __html: htmlHandler.renderHTMLAssetsList(),
                                    }}
                                />
                            }
                            {graphElement.isEditable() && (
                                <span
                                    dangerouslySetInnerHTML={{
                                        __html: htmlHandler.getItemContainer(
                                            htmlHandler.getElementsIDs()
                                                .editButton,
                                            "Editar",
                                            `<i class="material-icons">create</i>`,
                                            ["edit-icon"]
                                        ),
                                    }}
                                />
                            )}
                        </div>
                        {isValidRef(hostedObject) && !isGraphRoot && (
                            <IntentHandleComponent
                                hostedObject={hostedObject}
                                intentId={undefined}
                                edges={edges}
                                graphElement={graphElement}
                                handleType="target"
                            />
                        )}
                    </div>

                    <div className="content body">
                        <ul className="intent-list">
                            {intents.length > 0 &&
                                intents
                                    .slice(0, maxIntents)
                                    .map((intent) => (
                                        <IntentAsListItem
                                            key={intent.intentId}
                                            intent={intent}
                                            clickFunctions={clickFunctions}
                                            hostedObject={hostedObject}
                                            edges={edges}
                                            graphElement={graphElement}
                                        />
                                    ))}
                            {intents.length === 0 && (
                                <p>Base de Conhecimento sem Intenções!</p>
                            )}
                        </ul>
                        {intents.length > MAX_INTENTS && (
                            <button
                                className={`btn-show ${expandButton.customClass}`}
                                onClick={toggleExpandButton}
                            >
                                {expandButton.text}
                                <i className="material-icons">expand_more</i>
                                {intents
                                    .slice(maxIntents, intents.length)
                                    .map((intent) => (
                                        <IntentHandleComponent
                                            key={intent.intentId + "source"}
                                            hostedObject={hostedObject}
                                            intentId={intent.intentId}
                                            edges={edges}
                                            graphElement={graphElement}
                                            handleType="source"
                                            isConnectable={false}
                                        />
                                    ))}
                            </button>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
}

const IntentAsListItem = ({
    intent,
    clickFunctions,
    hostedObject,
    edges,
    graphElement,
}) => (
    <li id={intent.intentId} onClick={clickFunctions.get(intent.intentId)}>
        <div className="intent">
            <span className="intent-name">{intent.intentName}</span>
            {isValidRef(hostedObject) && (
                <ContentListComponent
                    hostedObject={hostedObject}
                    intentId={intent.intentId}
                />
            )}
        </div>
        {isValidRef(hostedObject) && (
            <IntentHandleComponent
                hostedObject={hostedObject}
                intentId={intent.intentId}
                edges={edges}
                graphElement={graphElement}
                handleType="source"
            />
        )}
    </li>
);

const ContentListComponent = ({ hostedObject, intentId }) => {
    const intentAssets = hostedObject.nlp.assets[intentId];
    let assetContentsCount = {
        [KBAssetType.content]: 0,
        [KBAssetType.media]: 0,
        [KBAssetType.generativo]: 0,
        [EBotActionType.goHuman]: 0,
    };
    if (isInvalidArray(intentAssets)) return <></>;
    intentAssets.forEach((asset) => {
        assetContentsCount[asset.type] += 1;
    });
    return (
        !!intentAssets.length && (
            <ul className="content-list">
                {assetContentsCount[KBAssetType.content] > 0 && (
                    <li>
                        <i className="material-icons">article</i>
                        <span className="custom-badge">
                            {assetContentsCount[KBAssetType.content]}
                        </span>
                    </li>
                )}
                {assetContentsCount[KBAssetType.media] > 0 && (
                    <li>
                        <i className="material-icons">attach_file</i>
                        <span className="custom-badge">
                            {assetContentsCount[KBAssetType.media]}
                        </span>
                    </li>
                )}
                {assetContentsCount[KBAssetType.generativo] > 0 && (
                    <li>
                        <i className="material-icons">polyline</i>
                        <span className="custom-badge">
                            {assetContentsCount[KBAssetType.generativo]}
                        </span>
                    </li>
                )}
                {assetContentsCount[EBotActionType.goHuman] > 0 && (
                    <li>
                        <i className="material-icons">face</i>
                    </li>
                )}
            </ul>
        )
    );
};

const IntentHandleComponent = ({
    hostedObject,
    intentId,
    edges,
    graphElement,
    handleType,
    ...restProps
}) => {
    const targetType = EBotActionType.contentGenerator;
    const intentAssets = hostedObject.nlp.assets[intentId];
    const actionAsset = intentAssets?.find(
        (intentAsset) =>
            intentAsset.type === EBotActionType.goActionTree ||
            intentAsset.type === EBotActionType.contentGenerator
    );

    let className = `${targetType}`;
    if (isValidRef(actionAsset)) className = `${actionAsset.type}`;

    let isConnected = false;
    if (handleType === "source") {
        isConnected = edges.some((edge) => {
            return (
                edge.sourceHandle === intentId &&
                edge.source === graphElement.getGraphElementID()
            );
        });
    } else {
        isConnected = edges.some((edge) => {
            return (
                edge.targetHandle === targetType &&
                edge.target === graphElement.getGraphElementID()
            );
        });
    }
    if (isConnected) className += " has-connection";

    const sourcePortID = handleType === "source" ? intentId : targetType;
    return (
        <Handle
            key={sourcePortID + handleType}
            id={sourcePortID}
            type={handleType}
            position={handleType === "source" ? Position.Right : Position.Left}
            className={className}
            {...restProps}
        />
    );
};
