import { TGlobalUID } from '../business/constant';
import { IRequest } from '../request-interfaces/request-interfaces';
import { IResponse } from '../request-interfaces/response-interfaces';
import { EIslandType } from '../shared-business-rules/attendance-island/attendance-island';
import { EIntegrationTypes } from '../shared-business-rules/metadata/metadata-interface';
import { TAvailableChannelForServiceArray } from '../shared-business-rules/social-cc/social-cc-types';
import { FindKeysWithValueOf } from '../tools/utility-types';
import { ExcludeFromIntersection } from '../tools/utility/types/intersection';
import { $Extends } from '../tools/utility/types/validate';
import { IBasicUniversalInfo } from './aux-interfaces';
import { IChainedInteractionJSON, IInteractionJSON } from './interaction-interfaces';




export enum EGetServiceOpenStatus {
    open = 1, close  // ATENÇÃO NÃO MUDAR. PERSISTIDO NO DATABASE
}

export enum EGroupConversationStatus {
    none = 'none',
    dueDate = 'dd',
    openResponse = 'ope',
}

export enum EServiceChatType {
    panic = 'panic',
    service = 'service',
    socialReceptiveCallcenter = 'socRec',
    internalSupport = 'intSupport',
    inactives = 'inactives',
    embeddedColmeiaChat = 'embedded'
};

export enum ECustomerServiceGroup {
    shared = 'gShared',
    onePerCustomer = 'onePerCustomer',
    onePerService = 'onePerService'
};

export interface IServiceConfig {
    idBotRootInteraction: TGlobalUID;
    concurrentAppointments?: number;  // numero atendimebtis
    expireBotComm?: number;

    createCustomerGroup?: ECustomerServiceGroup; // Depois

    botInfo?: IBasicUniversalInfo;

    idMacroPackage?: string;
}

export interface IServiceParameters {
    serviceType: EServiceChatType;
    openFeature?: IBasicUniversalInfo;
    serviceGroup: IBasicUniversalInfo;
    config?: IServiceConfig;
    isActive?: boolean;
    comm?: TAvailableChannelForServiceArray;
    idWhatsAppServiceRouting?: string;
    welcomeText?: string;
    isDefault?: boolean;
};




// Status sobre perspectiva da Interacao (cliente)
// Mensagem a mensagem
export enum EConversationStatusCC {
    startService = 'start',
    byAttendent = 'finishedByAtt',
    finishBySupervisor = 'finishedBySup',
    expireAccess = 'finishedExpiredAccess',
    expireCustomerActivity = 'finishedExpireCustomer',
    clientMessage = 'clientMessage',
    attMessage = 'attMessage',
    supervisorMessage = 'superMessage',
    closedByManager = 'closedByManager',
    openByCustomer = 'openByCustomer',
    dispatched = 'dispatched',
    reuseOFOpenQueue = 'resuseOfOpenQueue',
    returnToQueue = 'returnQueueByAgt',
    transferToAnotherQueue = 'transferQueue',
    closedByCustomer = 'closedByCustomer',
    closedByCustomerEmbedded = 'closedByCustomerEmbedded',
    closedByCustomerPageExit = 'closedByCustomerPageExit',
    returnDueToNamedAccount = 'returnDueToNamedAccount',
    noQueueRegisterOnDS = 'noQueueRegister',
    cantProcessQueueAfterTimeout = 'queueTimeoutExpired',
    loginTimeExceeded = 'loginTimeExceeded',
    agentExpire = 'agentExpire',
    finishByCampaign = 'finishedByCampaign',
    transferBeforeAtt = 'transferBeforeAtt',
    finishByServiceExpire = 'finishByServiceExpire',
}

// Status sobre perspectiva do atendente
export enum EAttendantStatus {
    login = 'login',
    logout = 'logout',
    online = 'online',
    absent = 'absent',
    ocuppied = 'ocuppied',
    resume = 'resume',
    forceStop = 'forceStop',
};

export function allShowOnPresenceStatuses(): EAttendantStatus[] {
    return Object.entries(allAtendeeStatusDB)
        .filter(([key, value]) => {
            return value.showOnPresenceStatus;
        })
        .map(([key, value]) => {
            return key as EAttendantStatus;
        });
}

export enum EAttendantStatusColor {
    green = 'var(--success-default)',
    red = 'var(--warn-default)',
    yellow = 'var(--alert-default)',
    gray = 'var(--primary-100)',
    // red = '#f44336',
    // yellow = 'yellow',
    // gray = 'gray',
}

type TAllAtendeeStatusDB = { [key in EAttendantStatus]: IAttendantStatusDescription }

function defineAllAtendeeStatusDB<T extends $Extends<T, TAllAtendeeStatusDB>>(source: T & TAllAtendeeStatusDB) {
    return source;
}



export const _allAtendeeStatusDB = defineAllAtendeeStatusDB({
    [EAttendantStatus.online]: {
        consuming: true,
        status: EAttendantStatus.online,
        color: EAttendantStatusColor.green,
        showCharOrderSelector: true,
    },
    [EAttendantStatus.absent]: {
        consuming: false,
        status: EAttendantStatus.absent,
        showOnPresenceStatus: true,
        showCharOrderSelector: true,
        color: EAttendantStatusColor.yellow,
        removeAgentExpire: true,
    }, // red
    [EAttendantStatus.resume]: {
        consuming: true,
        status: EAttendantStatus.online,
        showOnPresenceStatus: true,
        color: EAttendantStatusColor.green
    },
    [EAttendantStatus.ocuppied]: {
        consuming: false,
        status: EAttendantStatus.ocuppied,
        showCharOrderSelector: true,
        showOnPresenceStatus: true,
        color: EAttendantStatusColor.red
    },
    [EAttendantStatus.forceStop]: {
        consuming: false,
        status: EAttendantStatus.absent,
        color: EAttendantStatusColor.red,
        removeAgentExpire: true,
    },
    [EAttendantStatus.login]: {
        consuming: true,
        status: EAttendantStatus.online,
        color: EAttendantStatusColor.green
    },
    [EAttendantStatus.logout]: {
        consuming: false,
        status: EAttendantStatus.absent,
        color: EAttendantStatusColor.gray,
        removeAgentExpire: true,

    },
})
export const allAtendeeStatusDB: TAllAtendeeStatusDB = _allAtendeeStatusDB;


type TColorToStatus = { [key in EAttendantStatusColor]: ExcludeFromIntersection<FindKeysWithValueOf<typeof _allAtendeeStatusDB, { color: key }> & {}, {}> }
function defineColorToStatus<T extends $Extends<T, TColorToStatus>>(source: T) {
    return source;
}

export const colorToStatusDB = defineColorToStatus({
    [EAttendantStatusColor.gray]: EAttendantStatus.logout,
    [EAttendantStatusColor.yellow]: EAttendantStatus.absent,
    [EAttendantStatusColor.red]: EAttendantStatus.ocuppied,
    [EAttendantStatusColor.green]: EAttendantStatus.online,
})


export interface IFinishChatService extends IChainedInteractionJSON {
    statusCC: EConversationStatusCC;
};


export type TCharacteristicArray = Array<ICharacteristic>;

export interface ICharacteristic {
    idCharacteristic: TGlobalUID;
}



export interface IServiceChatData {
    serviceAvatar: IBasicUniversalInfo;
    idServiceGroup: TGlobalUID;
    serviceType: EIslandType;
    id360Identifier?: string;
    idSubscriberEntry?: string;
    isOpenCase?: boolean;
    idSourceIsland?: string;
    idMacroPackage?: string;
    isExternalCRM?: boolean;
    idAgentAvatar?: TGlobalUID;
    idAgentPlayer?: TGlobalUID;
    idPublishQueue?: TGlobalUID;
    idIsland?: string;
    idTicket?: string;
}

export type TServiceInteractionChatJSONArray = Array<IServiceChatInitiatedInteractionJSON>;

export interface IServiceChatInitiatedInteractionJSON extends IInteractionJSON, IServiceChatData {
    idInternalSupportGroup?: TGlobalUID;
    agentName?: string; // Nome fantasia
    agentAvatarName?: string // nome do avatar no momento;
    hasQueueMsg?: boolean;
};



export interface IAttendantStatusDescription {
    consuming: boolean;
    status: EAttendantStatus;
    showOnPresenceStatus?: boolean;
    showCharOrderSelector?: boolean;
    color: EAttendantStatusColor;
    removeAgentExpire?: boolean;
}

export type TAttendentStatusChangeLogArray = Array<IAttendentStatusChangeLog>;

export interface IAttendentStatusChangeLog {
    status: IAttendantStatusDescription;
    clockTick: number;
    endClock: number;

}

export interface IAllAttendantStatusDB {
    [status: string]: IAttendantStatusDescription;
}

// requests / responses

export interface IServiceGroupChatCommRequest extends IRequest {
    idGroupOfService: TGlobalUID;
    serviceType: EServiceChatType;
    idGeneratedInteraction: TGlobalUID;
};


export interface IServiceGroupResponse extends IResponse {
    idServiceGroup: TGlobalUID;
};


export interface ITreePosition {
    idTreeInteraction: TGlobalUID;
    position: number;
}

export type TUpdateTreePosition = Array<ITreePosition>;

export interface IUpdateTreePositionRequest extends IRequest {
    positions: TUpdateTreePosition;
};

export interface IBotRootCRMIntegration {
    integrationType: EIntegrationTypes;
    transactionName: string;
    idConnection: string;
}

export interface IBotLeafCRMIntegration {
    queueName: string;
}


export interface IAttendeesServiceRequest extends IRequest {
    idServiceAvatarID: string;
    status: EGetServiceOpenStatus;
}

export interface IAttendeesServiceResponse extends IResponse {
    services: TServiceInteractionChatJSONArray;
}

