import { DatePipe } from '@angular/common';
import {
    ChangeDetectorRef,
    Component,
    ElementRef,
    Inject, Injector, OnDestroy,
    OnInit, ViewChild
} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { constant, TGlobalUID } from '@colmeia/core/src/business/constant';
import { EGeolocationIntType } from '@colmeia/core/src/business/constant.enums';
import { Group } from '@colmeia/core/src/business/group';
import { Participant } from '@colmeia/core/src/business/participant';
import { Serializable, TSerializableArray } from '@colmeia/core/src/business/serializable';
import { PaymentStatus, PaymentStatusStructures } from "@colmeia/core/src/comm-interfaces/billing-interfaces";
import { EBillType, EWalletOperation } from '@colmeia/core/src/comm-interfaces/finance-interfaces';
import { EDelivery360Action, EInteractionSentType, IFeedbackInteractionJSON } from "@colmeia/core/src/comm-interfaces/interaction-interfaces";
import { EConversationStatusCC } from "@colmeia/core/src/comm-interfaces/service-group-interfaces";
import { EChatBtnsVisualElements } from "@colmeia/core/src/core-constants/constant-visual-elements.enums";
import { errorCodes } from "@colmeia/core/src/error-control/error-definition";
import { PurchaseConfirmation } from '@colmeia/core/src/interaction/billable/purchase-confirmation';
import { ChainedInteraction } from '@colmeia/core/src/interaction/chained-interaction';
import { Feedback } from '@colmeia/core/src/interaction/feedback';
import { Interaction, TInteractionArray } from '@colmeia/core/src/interaction/interaction';
import { InteractionType } from '@colmeia/core/src/interaction/interaction-type';
import { FeedbackInteraction } from '@colmeia/core/src/interaction/interactions/feedback-interaction';
import { RealTimeGPSTrackerOn } from '@colmeia/core/src/interaction/interactions/location/realtime-tracker-on';
import { MenuInteraction } from '@colmeia/core/src/interaction/menu-interaction/menu-interaction';
import { FinishServiceCall } from '@colmeia/core/src/interaction/service-group/finish-service';
import { StartServiceChat } from "@colmeia/core/src/interaction/service-group/start-service-chat";
import { ConnectionToSerializable } from '@colmeia/core/src/interaction/subscription/to-serializable';
import { WalletOperationInteraction } from "@colmeia/core/src/interaction/wallet/wallet-operation";
import { WrapperInteraction } from '@colmeia/core/src/interaction/wrapper-interaction';
import { MMconstant, TMultimediaInstanceArray } from '@colmeia/core/src/multi-media/barrel-multimedia';
import { MultimediaInstance } from '@colmeia/core/src/multi-media/multi-media-instance';
import { getEngagementOfType } from '@colmeia/core/src/shared-business-rules/bot/engagement-function';
import { gTranslations } from '@colmeia/core/src/shared-business-rules/const-text/translations';
import { ELayoutElement, EMetadataEngagementType, IFieldMapper, ILayoutFieldMapper } from '@colmeia/core/src/shared-business-rules/metadata/meta-engagement';
import { ITranslationConfig } from '@colmeia/core/src/shared-business-rules/translation/translation-engine';
import { isInvalid, isValidArray, isValidObject, isValidRef, isValidString } from '@colmeia/core/src/tools/utility';
import { IHashSet } from '@colmeia/core/src/tools/utility-types';
import { BBCodeViewModalHandler } from 'app/handlers/bbcode-view.handler';
import { ITimedMessageHandler } from 'app/handlers/timed-message-handler';
import { IGroupServiceParameter } from 'app/model/group-service.model';
import { getMessageInstanceOptionsConfig, IMessageInstanceOptionsConfig } from 'app/model/message-instance-config-db';
import { Subscription } from 'rxjs';
import { serializableWithPhotoToHexagonHandler } from "../../../../handlers/business-handlers";
import {
    BackboneInputDataToken, IChatBackboneInputMsgInstance
} from "../../../../handlers/chat-backbone.handler";
import { ICitedParticipantsListHandler } from "../../../../handlers/cited-participant";
import { HandlerFactoryService } from "../../../../handlers/handler-factory.service";
import { EHexagonSizes, HandlerHexagonon } from "../../../../handlers/hexagono.handler";
import { IMapsHandler } from "../../../../handlers/maps-handler";
import {
    EMessageIteratorAnimationState, IMessageEndPointCallback,
    MessageInstanceHandler, TMessageInstanceHandlerArray
} from "../../../../handlers/message-instance-handler/message-instance-handler";
import { MoreOptionsThreeDotsHandler } from "../../../../handlers/more-options-three-dots.handler";
import { ChatActionBarStateServiceID, IChatBarResetedListener } from "../../../../model/chat-action-bar";
import { formatTextToClient, formatTextToServer, setCitationsOnText } from "../../../../model/citation-helper";
import { clientConstants } from "../../../../model/constants/client.constants";
import { IFilteredChained } from "../../../../model/signal/ps-interfaces";
import { FeatureStateEmissorService } from "../../../../services/features-emissor.service";
import { HardwareLayerService } from "../../../../services/hardware";
import { ReadableClocktickService } from "../../../../services/readable-clocktick.service";
import { RoutingService } from "../../../../services/routing.service";
import { SessionService } from "../../../../services/session.service";
import { SignalListenerService } from "../../../../services/signal/signal-listener";
import { SnackMessageService } from "../../../../services/snack-bar";
import { UserSettingsService } from "../../../../services/user-settings.service";
import { MessageInstanceIteratorComponent } from "../message-instance-iterator/message-instance-iterator.component";
import { FeatureCarrierHandler, IFeatureCarrierParameter } from "./feature-carrier/feature-carrier.handler";
import { IRealTimeGPSInteractionOwner } from './gps-message/gps-message.component';
import { HighLevelMessage } from './high-level-message';
import { MessageInstanceService } from './message-instance.service';
import { EMultimediaPreviewType, MultimediaPreviewHandler } from './multimedia-preview/multimedia-preview.handler';
import { ReactBarDisplayComponent } from './react-bar-display/react-bar-display.component';
import { ReadConfirmationComponent } from './read-confirmation/read-confirmation.component';
import { StatusIconsHandler } from './status-icons/status-icons.handler';
import { EBotEventType } from '@colmeia/core/src/shared-business-rules/bot/bot-event-model';
import { botEventEnum } from "@colmeia/core/src/shared-business-rules/const-text/enums";
import { getEnumOption } from '@colmeia/core/src/shared-business-rules/enum-db';
import { AttendanceService } from 'app/services/attendance.service';
import { chain } from 'lodash';

type MaterialIcons = 'refresh' | 'refresh' | 'check' | 'done_all' | '';

@Component({
    selector: 'app-message-instance',
    templateUrl: './message-instance.component.html',
    styleUrls: ['./message-instance.component.scss'],
    // changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MessageInstance extends HighLevelMessage implements OnInit, OnDestroy, IMessageEndPointCallback {
    private static marginPixels: number = 20;
    public ELayoutElement: typeof ELayoutElement = ELayoutElement;

    @ViewChild('msgIterator')
    msgIterator: MessageInstanceIteratorComponent;
    private labelSources: IHashSet<ITranslationConfig> = {
        bulkGroupToInvite: gTranslations.fragments.bulkGroupToInvite,
        bulkTemplateLabel: gTranslations.fragments.bulkTemplateLabel,
        bulkFieldLabel: gTranslations.fragments.bulkFieldLabel,
    };
    private datePipe: DatePipe;
    private labels: IHashSet<string>;
    private filtered: IFilteredChained;
    private hexagonHandler: HandlerHexagonon;
    public multimediaPreviewHandler: MultimediaPreviewHandler;
    private chatBarResetedListenerSubscription: Subscription;
    private moreOptionsHandler: MoreOptionsThreeDotsHandler;
    public canShowAllTranscription: boolean = false;
    public transcription: string;
    private maxCharacters: number = clientConstants.UI.message.transcription.maxCharacters;
    public readMoreLabels = {
        readMore: '',
        readLess: '',
    };
    public isChildrenMinimized: boolean = false;
    public groupServiceParameter: IGroupServiceParameter;
    public extraClasses: Array<string> = [];
    public extraStatusClasses: Array<string> = []
    public extraReplyClasses: Array<string> = []
    public extraContainerReplyReactClasses: Array<string> = []
    public _ReactBarDisplay: typeof ReactBarDisplayComponent = ReactBarDisplayComponent;
    public reactBarInjectorHandler: Injector;
    public _ReadConfirmation: typeof ReadConfirmationComponent = ReadConfirmationComponent;
    public readConfirmationInjectorHandler: Injector;
    messageInstanceOptionsConfig: IMessageInstanceOptionsConfig;
    locationMapsHandler: IMapsHandler;
    public layoutSettings: ILayoutFieldMapper;
    public isThisWebChannel: boolean

    constructor(
        private cdr: ChangeDetectorRef,
        private routingSvc: RoutingService,
        private sessionSvc: SessionService,
        private listener: SignalListenerService,
        private featuresSvc: FeatureStateEmissorService,
        private settingsSvc: UserSettingsService,
        private handlerFactorySvc: HandlerFactoryService,
        private elementRef: ElementRef<HTMLElement>,
        private msgInstanceSvc: MessageInstanceService,
        private hw: HardwareLayerService,
        private snack: SnackMessageService,
        private sanitizer: DomSanitizer,
        private _injector: Injector,
        private clocktick: ReadableClocktickService,
        @Inject(ChatActionBarStateServiceID) private chatBarResetProviderSvc: IChatBarResetedListener,
        public messageHandler: MessageInstanceHandler,
        @Inject(BackboneInputDataToken) public chatBackboneInputData: IChatBackboneInputMsgInstance,
        private attendanceSvc: AttendanceService
    ) {
        super({ currentCDR: cdr, messageHandler: messageHandler, listener: listener });
        this.datePipe = new DatePipe(this.settingsSvc.getSelectedLocale());

    }

    ngOnInit(): void {
        this.filtered = {
            idGroup: this.getMessageHandler().getGroupID(),
            idInteractionParent: this.getInteraction().getInteractionID(),
        };

        this.hexagonHandler = this.buildHexagonHandler();
        this.groupServiceParameter = this.getGroupServiceParameter();

        this.initLabels();
        this.initHandlers();
        this.resetMessageStatus();

        // this.showChildrenInteractions = true;
        this.chatBarResetedListenerSubscription = this.chatBarResetProviderSvc
            .chatBarResetedListener()
            .subscribe((interactionID: TGlobalUID) => {
                this.moreOptionsHandler = this.newMoreOptionsHandler();
                if (this.isMsgBeingEdited()) {
                    this.onMsgHighLightComplete()
                }
            });

        if (this.isTranscription())
            this.setMessageTranscription();


        this.addExtraClasses();
        this.addExtraStatusClasses()
        this.addExtraReplyClasses()
        this.addExtraContainerReplyReactClasses()
        this.reactBarInjectorHandler = this.getReactBarInjectorHandler();
        this.readConfirmationInjectorHandler = this.getReadConfirmationInjectorHandler();
        this.messageInstanceOptionsConfig = getMessageInstanceOptionsConfig(this.getInteraction().getInteractionType().getPrimaryID());
        if (this.isLocationInteraction()) {
            this.buildSentLocationHandler();
        }

        this.layoutSettings = this.getInteraction().getParticipant().getAvatar().getAvatarID() === this.sessionSvc.getAvatarID()
            ? this.getLayoutSettings()
            : null;
    }

    get generatedByBotEvent(): EBotEventType {
        return this.getInteraction().getGeneratedByBotEvent()
    }

    get generatedByBotEventName(): string | undefined {
        return isValidString(this.generatedByBotEvent) ? getEnumOption(this.generatedByBotEvent, botEventEnum.fields, botEventEnum.idSerializable) : undefined;
    }

    get _chatBackboneInputData(): IChatBackboneInputMsgInstance {
        return this.chatBackboneInputData;
    }

    private createdAtTranslactions: Record<EInteractionSentType, string> = {
        [EInteractionSentType.onQueue]: "Em fila"
    }

    get createdAt() {
        return this.getInteraction().getSentType();
    }

    get createdAtTranslation(): string | undefined {
        return this.createdAt && this.createdAtTranslactions[this.createdAt];
    }

    private markIteratorForCheck(): boolean {
        const iterator = this.msgIterator;
        if (iterator) {
            iterator.markForCheck();
            return true;
        }
        return false;
    }

    onNewInteractionArrivedCallback(isChildInteraction: boolean): void {
        this.markIteratorForCheck();
    }

    changeDectionChildren(): boolean {
        return this.markIteratorForCheck();
    }

    removeInteraction(interaction: Interaction): void {
        this.markIteratorForCheck();
    }

    private addExtraClasses() {
        this.extraClasses = [];

        if (!this.isBillable && this.isMyMessage()) {
            this.extraClasses.push('message--mine');
        }

        if (!this.isBillable && !this.isMyMessage()) {
            this.extraClasses.push('message--others');
        }

        if (this.isMessageToMe()) {
            this.extraClasses.push('message--private');
        }

        if (this.isSuicidal()) {
            this.extraClasses.push('message--suicidal');
        }

        if (this.isBillable) {
            this.extraClasses.push('message--buyable')
        }

        if (this.hasCitations()) {
            this.extraClasses.push('message--withCitations');
        }

        if (this.isFromDelivery) {
            this.extraClasses.push('message--from-delivery');
        } else if (this.isToDelivery) {
            this.extraClasses.push('message--to-delivery');
        }

        if (this.hasNextLevel()) {
            this.extraClasses.push('message--has-next-level');
        }

        if (this.hasPreviousLevel()) {
            this.extraClasses.push('message--has-previous-level');
        }

        if (this.getInteraction().isInterlocutorASupervisor()) {
            this.extraClasses.push('message--from-supervisor');
        }
    }

    private addExtraStatusClasses(): void {
        this.extraStatusClasses = []
        if (!this.isBillable && this.isMyMessage()) {
            this.extraStatusClasses.push('status--mine')
        }
        else if (!this.isBillable && !this.isMyMessage()) {
            this.extraStatusClasses.push('status--others')
        }

        else if (!this.isBillable && this.getInteraction().isInterlocutorASupervisor) {
            this.extraStatusClasses.push('status--supervisor')
        }
    }

    ngOnDestroy(): void {
        if (this.chatBarResetedListenerSubscription)
            this.chatBarResetedListenerSubscription.unsubscribe();
        super.finish();
    }

    private initHandlers(): void {
        this.moreOptionsHandler = this.newMoreOptionsHandler();
        this.initMultimediaPreviewHandler();
    }

    private initMultimediaPreviewHandler(): void {
        if (!isValidRef(this.getInteraction().getMultimediaObject())) return;

        this.multimediaPreviewHandler = this.handlerFactorySvc.newMultimediaPreview({
            type: EMultimediaPreviewType.All,
            canOpenMediaModal: this.canOpenMediaModal(),
            maxNumberMultimediaToShow: clientConstants.multimedia.maxDisplayedMediaNumber,
            messageDate: this.getMessageDate(),
            messageTitle: this.getFormatedMessageTitle(),
            mmObject: this.getInteraction().getMultimediaObject(),
            missingMediaNumber: this.getMissingMediaNumber(),
            msgContainerInstance: this.chatBackboneInputData.getMsgContainerInstance(),
            multimediaList: [],
            useThumbnails: !this.isFromDelivery
        })
    }

    getMultimediaPreviewHandler(): MultimediaPreviewHandler {
        return this.multimediaPreviewHandler
    }

    private initLabels(): void {
        this.labels = {};
        for (const labelSource in this.labelSources) {
            const translationConfig: ITranslationConfig = this.labelSources[labelSource];
            this.labels[labelSource] = Serializable.getTranslation(translationConfig);
        }
        this.readMoreLabels.readMore = Serializable.getTranslation(gTranslations.fragments.readMore);
        this.readMoreLabels.readLess = Serializable.getTranslation(gTranslations.fragments.readLess);
    }

    public getLabel(idField: string): string {
        if (!this.labels[idField])
            return '<' + idField + '>';

        return this.labels[idField];
    }

    // visibility section
    public canShowAvatarName(): boolean {
        // const isMyMessageAndResponse = this.isMyMessage();
        return this.canShowAvatar()

        // const isMyMessageAndResponse = this.isMyMessage() && this.isResponse();
        // return !isMyMessageAndResponse
        //     && (this.canShowAvatar() || this.isResponse());
    }

    public canOpenMediaModal(): boolean {
        return (this.getMultimediaInstanceLength() > clientConstants.multimedia.maxDisplayedMediaNumber);
    }

    public canShowAvatar(): boolean {
        // const isMessageLeft = !this.isMsgShownRight();
        const previousParticipantDiffers = this.isShowAvatar();
        const isChatMode = this.getMessageHandler().getChatBackbone().isChatViewMode();
        const canShow = isChatMode
            ? previousParticipantDiffers
            : (previousParticipantDiffers || this.isRootInteractionWithReply());
        return canShow;
        // return isMessageLeft && canShow;
    }

    isRootInteractionWithReply(): boolean {
        return this.isRootInteraction() && this.hasReply();
    }

    public canPutAvatarInHTML(): boolean {
        const isChatMode = this.getMessageHandler().getChatBackbone().isChatViewMode();
        return isChatMode ? !this.isMsgShownRight() : this.isRootInteraction();
    }

    public canShowMultimedia() {
        return this.hasMultimedia();
    }

    public containsOnlyOneMedia(): boolean {
        return this.getAllMultimedia(MMconstant.tag.thumbnail).length === 1;
    }

    public getMessageContentStyles(): Object {
        return {
            'margin-left': this.canHaveStyles() ? `${MessageInstance.marginPixels}px` : 'unset'
        };
    }

    canHaveStyles(): boolean {
        const isChatMode = this.getMessageHandler().getChatBackbone().isChatViewMode();
        return this.getInteractionLevel()
            && !isChatMode;
    }

    public canHaveMarginTop(): boolean {
        return this.isRootInteraction();
    }

    public canShowMessage(): boolean {
        return this.isValidMessage() || this.hasMultimedia();
    }

    public getAvatarName(): string {
        return this.getInteraction().getParticipant().getAvatar().getName();
    }

    public getMessageDate(date: Date = this.getInteraction().getDate()): string {
        return new DatePipe(this.settingsSvc.getSelectedLocale())
            .transform(
                date,
                this.settingsSvc.getDateHourFormat());
    }

    public getDateHours(): string {
        return new DatePipe(this.settingsSvc.getSelectedLocale())
            .transform(
                this.getInteraction().getDate(),
                this.settingsSvc.getTwentyFourTimeFormat());
    }

    public getReadableDate(): string {
        return this.clocktick.getReadable(this.getInteraction().getClockTick());
    }

    public getAllMultimedia(mmTag: string): TMultimediaInstanceArray {
        const hasMultimediaObject = this.getInteraction().getMultimediaObject();
        return !hasMultimediaObject
            ? []
            : this.getInteraction()
                .getMultimediaObject()
                .getAllMultimediaInstanceWithTag(
                    mmTag
                );
    }

    public isThumbnail(media: MultimediaInstance): boolean {
        return media.getMultimediaTag().is(MMconstant.tag.thumbnail);
    }

    public containsAudio(): boolean {
        return this.getAllMultimedia(MMconstant.tag.voiceMessage) &&
            !!this.getAllMultimedia(MMconstant.tag.voiceMessage).length;
    }

    public isMessageExists(): boolean {
        return !!this.getMessage() && !!this.getMessage().length;
    }

    public isMessageBig(): boolean {
        return this.isMessageExists() && this.getMessage().length > 26;
    }

    bbCodeMessageHandlers: { [key in string]: BBCodeViewModalHandler };

    public getBBCodeMessageHandler(message: string): BBCodeViewModalHandler {
        if (isInvalid(this.bbCodeMessageHandlers[message])) {
            this.bbCodeMessageHandlers[message] = BBCodeViewModalHandler.factory({
                text: message,
                clientCallback: this,
            });
        }
        return this.bbCodeMessageHandlers[message];
    }

    private getLayoutSettings(): ILayoutFieldMapper {
        const descriptor: IFieldMapper[] = this.getInteraction().getWebChatConfig()?.descriptor || [];
        return getEngagementOfType<ILayoutFieldMapper>(descriptor, EMetadataEngagementType.layout);
    }

    public getMessage(): string {
        return this.getInteraction().getMessage();
    }

    public getMessageAsSecret(): string {
        const message: string = this.getInteraction().getMessage();

        return Array.from(new Array(message.length)).map(() => "*").join('');
    }

    public hasCitations(): boolean {
        return this.getInteraction().getCitations()
            && this.getInteraction().getCitations().length > 0
    }

    getCitedParticipantsListHandler(): ICitedParticipantsListHandler {
        return {
            citedParticipantsList: this.getInteraction().getCitations()
        }
    }

    public getMessageWithCitations(): string {
        if (isValidRef(this.hasCitations()) && isValidRef(this.getMessage())) {
            const citations = this.getInteraction().getCitations();
            let message = formatTextToClient(formatTextToServer(this.getMessage()));
            message = setCitationsOnText(citations, message);
            return message;
        }
    }

    public getFormatedMessageTitle(): string {
        return this.getAvatarName();
    }

    public isValidMessage(): boolean {
        return true;
    }

    public getMissingMediaNumber(): number {
        return (this.getMultimediaInstanceLength() - clientConstants.multimedia.maxDisplayedMediaNumber);
    }

    public isMsgShownRight(): boolean {
        const showRightRules = (!this.hasReply() && this.isRootInteraction())
            || this.getMessageHandler().getChatBackbone().isChatViewMode()
        return this.isMyMessage() && showRightRules;
    }

    public hasReply(): boolean {
        return this.hasNextLevel();
    }

    isParentMultimediaMessage(): boolean {
        const chained = <ChainedInteraction>this.getMessageHandler().getInteraction();
        return isValidObject(chained.getInteractionParent().getMultimediaObject()?.toJSON());
    }

    getParentInteractionMessage(): string {
        const chained = <ChainedInteraction>this.getMessageHandler().getInteraction()
        const message = chained.getInteractionParent() && chained.getInteractionParent().getMessage()
            ? chained.getInteractionParent().getMessage()
            : '';


        if (isValidString(message)) {
            return message;
        }

        const messageMultimedia = chained.getInteractionParent().getMultimediaObject();
        if (isValidRef(messageMultimedia)) {
            const fileName = messageMultimedia.getMultimediasName();
            return fileName;
        }

        return '';
    }

    canShowReplyInnerMessage(): boolean {
        const isChild = this.getMessageHandler().getInteraction().isChainedOrTreeWithParent();
        return isChild
            && this.getMessageHandler().getChatBackbone().isChatViewMode() && isValidString(this.getParentInteractionMessage());
    }

    private getMultimediaInstanceLength(): number {
        return this.getAllMultimedia(MMconstant.tag.thumbnail).length;
    }

    public hasLeftMargin() {
        return false;
    }

    public showReactOptions(): void {
        if (this.hw.getNetwork().isOnline()) {
            this.msgInstanceSvc.handleSelectedOptionClicked({
                optionSelected: Serializable.staticFactory(EChatBtnsVisualElements.Feedbacks),
                interactionClicked: this.getInteraction(),
                currentGroup: this.getMessageHandler().getGroup(),
                backBoneModel: this.getMessageHandler().getChatBackbone(),
                clientInstance: this
            }, super.getInstanceID());
        } else {
            const serializable: Serializable = Serializable.staticFactory(errorCodes.client.connection.clientConnectionError);
            this.snack.openError(serializable.getSerializableText(constant.serializableField.name));
        }
    }

    public getMaxAllowedToShowMedia(): TMultimediaInstanceArray {
        const thumbnails = this.getAllMultimedia(MMconstant.tag.thumbnail).slice(0, clientConstants.multimedia.maxDisplayedMediaNumber);
        const audioList: TMultimediaInstanceArray = this.getAllMultimedia(MMconstant.tag.voiceMessage);
        thumbnails.push(...audioList);
        return thumbnails;
    }

    public hasMultimedia(): boolean {
        return this.getInteraction().getMultimediaObject() &&
            (this.getAllMultimedia(MMconstant.tag.thumbnail) && !!this.getAllMultimedia(MMconstant.tag.thumbnail).length) ||
            (this.getAllMultimedia(MMconstant.tag.photo) && !!this.getAllMultimedia(MMconstant.tag.photo).length) ||
            (this.getMaxAllowedToShowMedia() && !!this.getMaxAllowedToShowMedia().length);
    }

    //#region ClickHandlers
    // private currentDropdownMouseEvent: MouseEvent;

    get isEmbedded(): boolean {
        return this.sessionSvc.isEmbeddedChat();
    }

    public onReplyMessage(): void {
        if (!this.sessionSvc.isEmbeddedChat()) {
            this.featuresSvc.replyChatBar(
                this.getMessageHandler().getInteraction()
            );
        }
    }

    public shouldShowReplyToMessage(): boolean {
        return !this.isEmbedded;
    }

    public isReplyToMessageDisabled(): boolean {
        return !this.messageHandler.canReplyToMessage();
    }

    get conditionalMoreOptionsClassByInteractionType(): Array<string> {
        const ret = [];

        switch (this.getInteractionType().getPrimaryID()) {
            case constant.interactionType.subscription.subscription:
                ret.push('moreopt-margin-10px');
                break;
        }

        return ret;
    }

    canShowMoreOptions(): boolean {
        return isValidArray(this.moreOptionsHandler && this.moreOptionsHandler.getMenuOptions()) && !this.sessionSvc.isEmbeddedChat();
    }

    getMoreOptionsHandler(): MoreOptionsThreeDotsHandler {
        return this.moreOptionsHandler
    }

    newMoreOptionsHandler(): MoreOptionsThreeDotsHandler {
        const chosenDropdownOptions: TSerializableArray = this.getMessageHandler().getMoreOptions();

        if (this.msgInstanceSvc.isAttending() && this.isMyMessage()) {
            chosenDropdownOptions.splice(chosenDropdownOptions.findIndex(s => s.getPrimaryID() === EChatBtnsVisualElements.Remove), 1);
        }

        return MoreOptionsThreeDotsHandler.new({
            options: chosenDropdownOptions,
            clientInstance: {
                onOptionSelectedCallback: (optionSelected: Serializable) => {
                    this.msgInstanceSvc.handleSelectedOptionClicked({
                        optionSelected,
                        interactionClicked: this.getInteraction(),
                        currentGroup: this.getMessageHandler().getGroup(),
                        backBoneModel: this.getMessageHandler().getChatBackbone(),
                        clientInstance: this
                    }, super.getInstanceID())
                }
            },
        });
    }

    //#endregion
    public getMessageStatus(): MaterialIcons {
        return this.isRefreshNeeded()
            ? 'refresh'
            : this.isServerReceived()
                ? 'check'
                : this.isDeliveredToAll()
                    ? 'done_all'
                    : '';
    }

    public getAllMultimediaID(): string[] {
        return this.getInteraction()
            .getMultimediaObject()
            .getAllMultimediaIDMediaArray();
    }

    public isVideo(mmInstance: MultimediaInstance): boolean {
        return this.hasMultimedia()
            ? this.getInteraction()
                .getMultimediaObject()
                .isMultimediaVideo(mmInstance.getThumbnailParentKey())
            : false;
    }

    public getInteraction(): Interaction { return this.getMessageHandler().getInteraction(); };

    public get notificationInteraction(): ConnectionToSerializable {
        return this.getInteraction() as ConnectionToSerializable;
    }

    public get walletInteraction(): WalletOperationInteraction {
        return this.getInteraction() as WalletOperationInteraction;
    }

    public get feedbackInteraction(): FeedbackInteraction {
        return this.getInteraction() as FeedbackInteraction;
    }

    public get purchaseConfirmationInteraction(): PurchaseConfirmation {
        return this.getInteraction() as PurchaseConfirmation;
    }

    public get finishServiceCallInteraction(): FinishServiceCall {
        return this.getInteraction() as FinishServiceCall;
    }

    public $menuInteraction(interaction: Interaction): MenuInteraction {
        return interaction as MenuInteraction;
    }

    public getInteractionLevel(): number { return this.getInteraction().getHopNumber(); };

    public isRootInteraction(): boolean {
        return this.getInteractionLevel() === 0;
    }

    public getInteractions(): TInteractionArray {
        return this.getInteraction().getChildren(this.getGroupID());
    }

    public getEditingParticipant(): Participant {
        return this.getMessageHandler().getReactingParticipant();
    }

    get interactionsHandlerJSON(): string {
        return JSON.stringify(this.getInteractionHandlerArray());
    }

    public getInteractionHandlerArray(): TMessageInstanceHandlerArray {
        const excludeSharedChild = (interactionHandler: MessageInstanceHandler) =>
            !this.isSharedInteraction(interactionHandler.getInteraction())
        const excludeMessageFeatureChildInChat = (interactionHandler: MessageInstanceHandler) =>
            !this.isMessageFeatureChildInChat(interactionHandler.getInteraction())

        return this.getMessageHandler()
            .getNextLevelHandler()
            .getInteractionHandlerArray()
        // .filter(excludeMessageFeatureChildInChat)
        // .filter(excludeSharedChild)
    }

    // @TODO Daniel ver isto:
    isMessageFeatureChildInChat(childInteraction: Interaction): boolean {
        const isInChat = this.chatBackboneInputData.isInChat();
        const isChild = childInteraction.isChainedOrTreeWithParent();
        return isInChat && isChild;
    }

    public hasNextLevel(): boolean {
        return this.getMessageHandler().getNextLevelHandler()
            && isValidArray(this.getMessageHandler().getNextLevelHandler().getInteractionHandlerArray());
    }

    public hasPreviousLevel(): boolean {
        return this.getInteraction().isChainedOrTreeWithParent();
    }

    public isShowAvatar(): boolean {
        return this.getMessageHandler().isShowAvatarName();
    }

    // COMPLIANCE COM ENDPOINT
    public callMessageContainerChangeDetector(refreshVector: boolean): void {
        this.onAboutToDetectChanges();
        super.callMessageContainerChangeDetector(refreshVector);
    }

    public isYourAnswerRequired(): boolean { return false };
    public isDirectToASubGroup(): boolean { return this.getInteraction().isToSubgroup(); }
    public isDirectToAll(): boolean { return !this.isDirectToASubGroup(); }
    public isTimeBounded(): boolean { return this.getInteraction().isTimed(); }
    public isTranscription(): boolean { return isValidRef(this.getInteraction().getTranscription()) }

    // #region readMore
    canShowReadMoreTranscription(): boolean {
        return this.transcription && this.transcription.length > this.maxCharacters
    }

    getTranscription(): string {
        this.transcription = this.getInteraction().getTranscription();

        if (this.canShowReadMoreTranscription() && !this.canShowAllTranscription) {
            return this.transcription = `${this.transcription.slice(0, this.maxCharacters)}...`;
        } else {
            return this.transcription;
        }
    }

    get readMoreLessText(): string {
        if (this.canShowAllTranscription) {
            return this.readMoreLabels.readLess;
        } else {
            return this.readMoreLabels.readMore;
        }
    }

    setMessageTranscription(): void {
        this.transcription = this.getTranscription()

        if (this.canShowReadMoreTranscription() && !this.canShowAllTranscription) {
            this.transcription = `${this.transcription.slice(0, this.maxCharacters)}...`;
        }
    }

    toggleTextToShow(): void {
        this.transcription = this.getTranscription();
        this.canShowAllTranscription = !this.canShowAllTranscription;
    }
    // #endregion

    public isServerReceived(): boolean { return this.getInteraction().isWaitingResponse(); }
    public isRefreshNeeded(): boolean { return !this.isServerReceived(); }
    public isDeliveredToAll(): boolean { return false; }
    public isReadByAll(): boolean { return false; }
    public isFirstMultimedia(): boolean { return true; }

    public isMyMessage(): boolean {
        const idAvatarOfInteraction = this.getInteraction().getParticipant().getAvatar().getAvatarID();

        /**
         * Pra não quebrar o que já funciona, essa regra é especial para usuários do embedded
         */
        if (this.isEmbedded) {
            return idAvatarOfInteraction === this.sessionSvc.getAvatarID();
        }

        return this.sessionSvc.getPlayerInfoServices().getPlayerInfo()
            .avatarBelongsToPlayer(idAvatarOfInteraction);
    };

    public hasFullWidth(): boolean {
        return this.isMenu || this.isServiceGroupMessage || this.isFinishServiceMessage;
    }

    public isResponse(): boolean {
        return this.getInteraction() && this.getInteraction().getHopNumber() > 0;
    }

    public rootInteractionIsMine(): boolean {
        const rootInteraction = this.getInteraction().getRoot();
        return rootInteraction.getParticipant().getAvatar().iss(
            this.getMessageHandler().getReactingParticipant().getAvatar());
    }

    public isYourAttentionRequired(): boolean {
        return this.getInteraction().getAllIDAvatarCopied()
            .some((avt) => { return this.getMessageHandler().getInteractionParticipant().getAvatar().is(avt) })
    };

    //#region HexagonAvatar
    public getHexagonHandler(): HandlerHexagonon {
        return this.hexagonHandler;
    }

    public buildHexagonHandler(): HandlerHexagonon {
        const handler = this.getInteraction().getParticipant().getAvatar() ?
            serializableWithPhotoToHexagonHandler(
                this.getInteraction().getParticipant().getAvatar(),
                EHexagonSizes.xs,
                null,
                false
            ) :
            null;
        return handler;
    }
    //#endregion

    //#region Share
    isSharedRoot(): boolean {
        return this.getInteraction()
            .getChildren(this.getGroupID())
            .some(interaction => this.isSharedInteraction(interaction));
    }

    isSharedInteraction(interaction: Interaction): boolean {
        return interaction.getInteractionType().is(constant.interactionType.sharing.shareInteraction);
    }

    getUsersWhoShared(): string {
        const sharedInteractions = this.getShares();
        return sharedInteractions.length
            ? sharedInteractions
                .map(int => int.getParticipant().getAvatar().getName())
                .reduce((curr, acc) => `${curr} ${acc.length ? '@' + acc : ''}`)
            : '';
    }

    getShares(): TInteractionArray {
        const sharedInteractions = this.getInteraction()
            .getChildren(this.getGroupID())
            .filter(int => int.getInteractionType().is(constant.interactionType.sharing.shareInteraction));
        return sharedInteractions;
    }
    //#endregion

    //#region MessageStatus
    messageStatusHandler: StatusIconsHandler;

    getStatusIconsHandler(): StatusIconsHandler {
        return this.messageStatusHandler
    }

    resetMessageStatus(): void {
        this.messageStatusHandler = this.handlerFactorySvc.newStatusIconsHandler({
            clientInstance: this,
            messageHandlerInstance: this.messageHandler,
        });
    }

    isAnyStatusAvailable(): boolean {
        return this.messageStatusHandler.isAnyStatusAvailable()
    }

    canShowExpired(): boolean {
        return this.messageStatusHandler.canShowExpired()
    }

    canShowDueDate(): boolean {
        return this.messageStatusHandler.canShowDueDate()
    }

    canShowFinishedTask(): boolean {
        return this.messageStatusHandler.canShowFinishedTask()
    }

    // canApplyMarginToMoreOptions(): boolean {
    //     return this.messageStatusHandler.canApplyMarginToMoreOptions()
    // }

    isMessageToMe(): boolean {
        return this.messageStatusHandler.isMessageToMe();
    }

    isSuicidal(): boolean {
        return this.messageStatusHandler.isSuicidal();
    }

    getDueDate(): number {
        return this.messageStatusHandler.getDueDate()
    }

    isEditedMessage(): boolean {
        return this.getMessageHandler().isEditedMessage();
    }

    //#endregion

    get isFeatureCarries(): boolean {
        return this.getInteraction()
            .getInteractionType()
            .is(constant.interactionType.standard.featureCarrier);
    }

    //#region GPS
    canShowGpsMessage(): boolean {
        const isBeginRealtimeTracking = this.getInteraction()
            .getInteractionType()
            .is(EGeolocationIntType.beginRealtimeTracking);
        const isEndRealtimeTracking = this.getInteraction()
            .getInteractionType()
            .is(EGeolocationIntType.endRealTimeTracking);
        return isBeginRealtimeTracking || isEndRealtimeTracking;
    }

    getGpsMsgHandler(): IRealTimeGPSInteractionOwner {
        return {
            getInteraction: () => <RealTimeGPSTrackerOn>this.getInteraction(),
            getCurrentGroupID: () => this.getGroupID(),
            getDate: () => this.getMessageDate(),
        };
    }
    //#endregion

    getIsServerProcessed(): boolean {
        return this.getMessageHandler().getIsServerProcessed();
    }

    onAboutToDetectChanges(): void {
        const iterator = this.msgIterator;
        if (iterator) {
            iterator.markForCheck();
        }
        this.getMessageHandler().resetDisplayBarHandler();
    }

    public changeAllSiblingsDetection(): void {
        this.onAboutToDetectChanges();
        super.changeAllSiblingsDetection();
    }

    public canShowIterator(): boolean {
        return true;
    }

    getAnimationState(): EMessageIteratorAnimationState {
        return this.canShowIterator()
            ? EMessageIteratorAnimationState.visible
            : EMessageIteratorAnimationState.hidden;
    }

    isBackGroundWhite(): boolean {
        return this.canSetBackGround()
    }

    isBackgroundGrey(): boolean {
        return !this.canSetBackGround() &&
            (this.isRootInteraction() || this.isChatViewMode())
    }

    isChatViewMode(): boolean {
        return this.getMessageHandler().getChatBackbone().isChatViewMode()
    }

    canApplyMarginInMsgFeature(): boolean {
        const groupID: TGlobalUID = this.getMessageHandler().getGroupID()
        const isFeatureCreator = Group.staticFactory(groupID).getGroupType().is(constant.groupType.functional.featureCreator)
        return this.isRootInteraction()
            && isFeatureCreator
    }

    canSetBackGround(): boolean {
        if (!this.isRootInteraction() && !this.isChatViewMode())
            return false;

        const defaultBackgroundReturn = true;
        const handler = this.messageHandler;
        const prevHandler = handler.getPreviousMessageHandler();
        const isNullPrevHandler = !prevHandler;

        if (isNullPrevHandler) {
            return handler.getSetPainted(defaultBackgroundReturn);
        }

        const isPreviousPainted = handler.isPreviousMsgPainted();
        const mustSetBackground = !isPreviousPainted;
        const currentIsAvatarShow = handler && this.canShowAvatar();

        if (!currentIsAvatarShow && !this.isMyMessage()) {
            return this.shouldPaintCurrentMsgBasedOnAncestor(handler, prevHandler, defaultBackgroundReturn);
        }

        return handler.getSetPainted(mustSetBackground);
    }

    shouldPaintCurrentMsgBasedOnAncestor(
        handler: MessageInstanceHandler,
        prevHandler: MessageInstanceHandler,
        defaultBackgroundReturn: boolean,
    ): boolean {
        let currentPrevHandler = prevHandler;
        const isSameParticipant = (currentPrevHandler) => currentPrevHandler.getInteractionParticipant().iss(
            handler.getInteraction().getParticipant())

        while (currentPrevHandler) {
            if (!isSameParticipant(currentPrevHandler)) {
                return handler.getSetPainted(currentPrevHandler.getNextMessageHandler().getPainted());
            }
            currentPrevHandler = currentPrevHandler.getPreviousMessageHandler();
        }

        return handler.getSetPainted(defaultBackgroundReturn);
    }

    scrollTop(): void {
        // must wait browser render real dom to use .nativeElement ...
        setTimeout(() => {
            this.elementRef.nativeElement.scrollIntoView(true);
        }, 100)
    }

    private isPersonalGroup(): boolean {
        return this.sessionSvc.getSelectedGroup().getGroupType().isPersonal();
    }

    private getInteractionType(): InteractionType {
        return this.getInteraction().getInteractionType();
    }

    canShowMessageInstanceDefault(): boolean {
        return this.isNormalMessage() && !this.isLocationInteraction();
    }

    isNormalMessage(): boolean {
        const interactionType: InteractionType = this.getInteraction().getInteractionType();
        return interactionType.is(
            constant.interactionType.standard.message,
            constant.interactionType.standard.citation,
        );
    }

    private _featureCarrierHandler: FeatureCarrierHandler;

    private generateFeatureCarrierHandler(): void {
        const parameter: IFeatureCarrierParameter = {
            interaction: this.getInteraction()
        };
        this._featureCarrierHandler = new FeatureCarrierHandler(parameter);
    }

    get featureCarrierHandler(): FeatureCarrierHandler {
        if (!this._featureCarrierHandler)
            this.generateFeatureCarrierHandler();

        return this._featureCarrierHandler;
    }

    get isTransfer(): boolean {
        return this.getInteraction().getInteractionType().is(constant.interactionType.wallet.operation) && (<WalletOperationInteraction>this.getInteraction()).getTransactionType() === EWalletOperation.transfer;
    }

    get isCharge(): boolean {
        return this.getInteraction().getInteractionType().is(constant.interactionType.wallet.operation) && (<WalletOperationInteraction>this.getInteraction()).getTransactionType() === EWalletOperation.charge;
    }

    get isWallet(): boolean {
        return isValidRef(this.getInteraction().getBillableOptions()) && this.getInteraction().getBillType() === EBillType.wallet;
    }

    get isWrapperInteraction(): boolean {
        return this.getInteraction()
            .getInteractionType()
            .is(constant.interactionType.special.wrapperInteraction)
    }

    getTimedMessageHandler(): ITimedMessageHandler {
        return {
            wrapperInteraction: <WrapperInteraction>this.getInteraction(),
        }
    }

    isFeedbackNotificationMessage(): boolean {
        if (!this.messageHandler)
            return false;

        return this.getInteractionType().is(constant.interactionType.subscription.stdResponse)
    }

    isNotificationRequestMessage(): boolean {
        if (!this.messageHandler)
            return false;

        return this.getInteractionType().isSubscription() &&
            (<ConnectionToSerializable>this.getInteraction()).getSubscriptionType().isRequest()
            ;
    }

    get isBillable(): boolean {
        return this.getInteraction().isBillable();
    }

    /* Service SECTION */
    get isServiceGroupMessage(): boolean {
        return this.getInteractionType().is(constant.interactionType.serviceGroup.startServiceChat);
    }

    getGroupServiceParameter(): IGroupServiceParameter {
        if (this.isServiceGroupMessage) {
            this.isChildrenMinimized = true;
        }

        return {
            interaction: this.getInteraction() as StartServiceChat,
            toggleChildren: () => this.isChildrenMinimized = !this.isChildrenMinimized,
        }
    }

    get isFinishServiceMessage(): boolean {
        return this.getInteractionType().is(constant.interactionType.serviceGroup.finishService);
    }

    get isMenu(): boolean {
        return constant.interactionType.menu.menuHeader === this.getInteractionType().getPrimaryID();
    }

    get isItemMenu(): boolean {
        return this.getInteractionType().is();
    }

    get isMenuReply(): boolean {
        return this.getInteractionType().is();
    }


    /* PURCHASE SECTION: */
    public buy(): void {
        const interaction: Interaction = this.getInteraction();
        const feedback = Feedback.staticFactory(constant.interactionType.products.prePurchase.feedbacks.wantPurchase);
        this.getMessageHandler().getChatBackbone().onReactBarReaction(
            interaction.getPrimaryID(),
            interaction.getInteractionType().getPrimaryID(),
            this.getGroupID(),
            feedback
        );
    }

    get isPurchaseConfirmation(): boolean {
        return this.getInteraction().getInteractionType().is(constant.interactionType.products.purchase.confirmation);
    }

    get mustShowPurchaseConfirmation(): boolean {
        const feedbacks: Array<string> = this.getPaymentFeedbacks();

        const status = Object.keys({ ...PaymentStatus })
            .map(key => PaymentStatus[key]);

        return ![]
            .concat(status)
            .map(status => feedbacks.includes(status))
            .includes(true);
    }

    get isOfTypePayment(): boolean {
        return this.getInteraction().getInteractionType().is(constant.interactionType.products.purchase.feedbackDriver);
    }

    get isSuccessPurchase(): boolean {
        return this.isOfTypePayment && !this.isFailPurchase;
    }

    get isFailPurchase(): boolean {
        return this.isOfTypePayment
            && PaymentStatusStructures[(<IFeedbackInteractionJSON>this.getInteraction().toJSON()).idFeedback].isNegative;
    }

    get isLastPurchaseFeedback(): boolean {
        const times: Array<number> = (<FeedbackInteraction>this.getInteraction())
            .getInteractionParent()
            .getChildren(this.sessionSvc.getSelectedGroupID(), constant.interactionType.products.purchase.feedbackDriver)
            .map(feedback => feedback.getClockTick())
            .sort();

        return this.getInteraction().getClockTick() === times.pop();
    }

    private getPaymentFeedbacks(): Array<string> {
        const feedbacks: TInteractionArray = this.getInteraction().getChildren(this.sessionSvc.getSelectedGroupID(), constant.interactionType.products.purchase.feedbackDriver);
        return feedbacks.map(interaction => (<FeedbackInteraction>interaction).getFeedback().getFeedbackID());
    }

    get isFromDelivery() {
        return this.getInteraction().isFrom360();
    }

    get isToDelivery() {
        return this.getInteraction().is360FromColmeia();
    }

    getProviderTypeFromParent(): EDelivery360Action {
        return this.getInteraction().isChainedOrTreeWithParent()
            ? (<ChainedInteraction>this.getInteraction()).getInteractionParent().get360ProviderType()
            : null;
    }

    getProviderType(): EDelivery360Action {
        return this.getInteraction().get360ProviderType();
    }

    interlocutorToIconMap = {
        [EConversationStatusCC.supervisorMessage]: 'S',
        [EConversationStatusCC.attMessage]: 'A'
    }

    getInterlocutorIcon() {
        return this.interlocutorToIconMap[this.getInteraction().getInterlocutor()]
    }

    getProviderTypeIcon() {
        if (this.getInteraction().isAttendantPersonal()) {
            return ''
        }

        if (this.getProviderTypeFromParent() || this.getInteraction().isChainedOrTreeWithParent()) {
            return '';
        }

        return MessageInstance.getDeliveryIcon(this.getProviderType());
    }

    public static getDeliveryIcon(deliveryAction: EDelivery360Action): string {
        switch (deliveryAction) {
            case EDelivery360Action.Delivery360Email:
                return './assets/icons/delivery-360/chat_icon_mail.svg';
            case EDelivery360Action.Delivery360FBMessenger:
                return './assets/icons/delivery-360/chat_icon_messenger.svg';
            case EDelivery360Action.Delivery360SMS:
                return './assets/icons/delivery-360/chat_icon_sms.svg';
            case EDelivery360Action.Delivery360Voice:
                return './assets/icons/delivery-360/chat_icon_voice.svg';
            case EDelivery360Action.Delivery360WhatsApp:
                return './assets/icons/delivery-360/chat_icon_whats.svg';
            case EDelivery360Action.DeliveryColmeia:
                return './assets/icons/delivery-360/chat_icon_embed.svg';
            case EDelivery360Action.DeliveryTelegram:
                return './assets/icons/delivery-360/chat_icon_telegram.svg';
            case EDelivery360Action.DeliveryInstagram:
                return './assets/icons/delivery-360/chat_icon_instagram.svg';
            case EDelivery360Action.Delivery360FBMessenger:
                return './assets/icons/delivery-360/chat_icon_messenger.svg';
            default:
                return ''
        }
    }

    private getReactBarInjectorHandler(): Injector {
        return Injector.create([{
            provide: MessageInstanceHandler,
            useValue: this.getMessageHandler()
        }], this._injector);
    }

    private getReadConfirmationInjectorHandler(): Injector {
        return Injector.create([{
            provide: MessageInstanceHandler,
            useValue: this.getMessageHandler()
        }], this._injector);
    }

    canShowFooter(): boolean {
        return this.canShowStatusIcons()
            || this.canShowReadConfirmation();
    }

    showingFooterInNestedComponent(): boolean {
        return this.canShowFooter()
            && this.isServiceGroupMessage;
    }

    canShowStatusIcons(): boolean {
        return isValidRef(this.messageInstanceOptionsConfig) && (
            this.canShowEdited()
            || this.canShowShared()
            || this.canShowAnnotations()
        );
    }

    private canShowEdited(): boolean {
        return this.messageInstanceOptionsConfig && this.messageInstanceOptionsConfig.edited;
    }

    private canShowShared(): boolean {
        return this.messageInstanceOptionsConfig && this.messageInstanceOptionsConfig.shared;
    }

    private canShowAnnotations(): boolean {
        return this.messageInstanceOptionsConfig && this.messageInstanceOptionsConfig.annotations;
    }

    public canShowReadConfirmation(): boolean {
        return this.messageInstanceOptionsConfig && this.messageInstanceOptionsConfig.readConfirmation;
    }

    isLocationInteraction(): boolean {
        return this.getInteraction().isLocationInteraction();
    }

    private buildSentLocationHandler(): void {
        this.locationMapsHandler = {
            locationsReceived: [this.getInteraction().getSentLocation()]
        };

    }

    private isWebChatChannel(): boolean {
        if(!this.attendanceSvc.isCurrentAttendanceValid() || !this.attendanceSvc) return false
        const startServiceInteraction = this.attendanceSvc.getInitInteractionServiceOnCurrentGroup();
        if (!startServiceInteraction) return false
        const channelType = startServiceInteraction.get360ProviderType()
        return channelType === EDelivery360Action.DeliveryColmeia
    }

    private isMessageFromBot(): boolean {
        return this.getProviderType() === EDelivery360Action.DeliveryColmeia
    }

    private addExtraReplyClasses() {
        this.extraReplyClasses = []
        if (!this.attendanceSvc.isCurrentAttendanceValid()) return

        const chainedInteraction = <ChainedInteraction>this.getMessageHandler().getInteraction()
        if (!chainedInteraction?.getInteractionParent) return
        const isSuperVisorReply = chainedInteraction.getInteractionParent().isInterlocutorASupervisor()
        // const chainedMessage = chainedInteraction.getInteractionParent() && chainedInteraction.getInteractionParent().getMessage()

        if (isSuperVisorReply) {
            this.extraReplyClasses.push('reply--supervisor')
        }
        // I only needed to tag the supervisor replies, but this can accept anything else as well
    }

    private addExtraContainerReplyReactClasses() {
        this.extraContainerReplyReactClasses = []

        if (this.getInteraction().isInterlocutorASupervisor()) {
            this.extraContainerReplyReactClasses.push('supervisor-reply-react')
        }
    }
}
