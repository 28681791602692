import { HttpClient, HttpEventType, HttpHeaders, HttpProgressEvent } from "@angular/common/http";
import { Injectable, isDevMode } from '@angular/core';
import { IServerFileInfo } from "@colmeia/core/src/comm-interfaces/aux-interfaces";
import { ROUTES } from "@colmeia/core/src/core-constants/routes-shared";
import { TPostgresPrimaryKey } from "@colmeia/core/src/core-constants/types";
import { errorCodes } from '@colmeia/core/src/error-control/error-definition';
import { ErrorDomain } from '@colmeia/core/src/error-control/error-domain';
import { FriendlyMessage } from '@colmeia/core/src/error-control/friendly-message';
import { MMconstant } from '@colmeia/core/src/multi-media/barrel-multimedia';
import { ClientCachedFile } from '@colmeia/core/src/multi-media/client-cached';
import { SelectedFile } from '@colmeia/core/src/multi-media/selected-file';
import { IHomologObj, IRequest } from "@colmeia/core/src/request-interfaces/request-interfaces";
import { IResponse, ISignedUrlResponse } from "@colmeia/core/src/request-interfaces/response-interfaces";
import { getRouteByMessageType } from '@colmeia/core/src/rules/route-message';
import { Miliseconds, secToMS } from "@colmeia/core/src/time/time-utl";
import { getClock, isValidNumber, isValidRef, isValidString, safeStringifyJSON } from "@colmeia/core/src/tools/utility";
import { Compute, Differences, Omit, SimpleDifferences } from '@colmeia/core/src/tools/utility-types';
import { createServiceLogger } from "app/model/client-utility";
import { IMigrationVersionComm } from "app/model/journaling-client.model";
import { environment } from "environments/environment-client";
import { Observable, Subject } from 'rxjs';
import { map, retry, timeout } from 'rxjs/operators';
import { DefaulActionOnError, IInfraParameters } from "../model/client-infra-comm";
import { ClientInfraResponse } from "../model/component/client-infra-comm";
import { clientConstants } from "../model/constants/client.constants";
import { MAX_TIMEOUT } from "../model/constants/general.constants";
import { FileUploadSignal } from "../model/signal/file-upload-signal";
import { GlobalWarningService } from "./global-warning.service";
import { HardwareLayerService } from "./hardware";
import { LocalStoragePersistence } from "./local-storage-persistence.service";
import { CURRENT_JOURNALING_VERSION_ON_LOCAL_STORAGE } from "./migration-journaling.service";
import { RateLimitClientService } from "./rate-limit-client.service";
import { RequestBuilderServices } from "./request-builder.services";
import { RequestClientCacheService } from "./request-cache.service";
import { ScreenSpinnerService, SpinType } from "./screen-spinner.service";

export const PRE_FAILED_MATCH_INDEX_ERROR = 'FAILED_PRECONDITION: no matching index found';

export interface IFileUpdate {
    file: Blob,
    fileInfo: IServerFileInfo,
};

export type TIFileUpdateArray = Array<IFileUpdate>;

type HTTPOptions = { [key: string]: HttpHeaders | boolean };


interface IQuickRequestOptions {
    defaultActionOnError?: DefaulActionOnError
    removeSpinner?: boolean
    showError?: boolean
}

interface IExtraParams {
    ignoreErrorIDs: string[]
}

const requestIntervalTreshold: Miliseconds = secToMS(2);

export type TActionReportRequestResponse = {
    success: true;
    data: any;
    error: null;
} | {
    success: false;
    data: null;
    error: any;
}

/**
 * Unify server communication, by making it the single source of communication between client and API
 */
@Injectable()
export class ServerCommunicationService {
    private log = createServiceLogger('ServerCommunicationService', '#00e1ff');
    public get isMappingRequestTypeToIdMenu(): boolean { return window['HAS_SEARCH'] }
    lastFriendlyMessageError: FriendlyMessage = undefined;
    private httpOptions: HTTPOptions
    private uploadStatus: Subject<FileUploadSignal> = new Subject<FileUploadSignal>();
    private isAnEmbeddedInstance: boolean = false;
    private logHomologObj: IHomologObj;
    private static readonly defaultHmlObj = { isActive: false, id: 'ServerCommunicationService.init' }

    private requestRecurrency: Map<string, Miliseconds> = new Map();

    constructor(
        private httpClient: HttpClient,
        private rbs: RequestBuilderServices,
        private warningScreenSvc: GlobalWarningService,
        private screenLoadingSvc: ScreenSpinnerService,
        private hardwareSvc: HardwareLayerService,
        private rateLimitSvc: RateLimitClientService,
        private requestCacheSvc: RequestClientCacheService,
        private localStorageSvc: LocalStoragePersistence
        // private migrationJournalingService: MigrationJournalingService
    ) {
        this.httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
            }),
            withCredentials: true
        };
    }

    /**
     * funcao utilizada pela equipe de homologacao
     */
    init() {
        this.logHomologObj = ServerCommunicationService.defaultHmlObj
    }

    /**
     * funcao utilizada pela equipe de homologacao para criar objetos customizados pra logging
     */
    hmlSetObj(objToAdd: object) {
        this.logHomologObj = {
            ...this.logHomologObj,
            isActive: true,
            logData: {
                ...this.logHomologObj.logData,
                ...objToAdd
            },
        }
    }

    /**
     * funcao utilizada pela equipe de homologacao para apagar objetos previamente setados de logging
     */
    hmlClearObj() {
        this.logHomologObj = ServerCommunicationService.defaultHmlObj
    }

    setAsEmbeddedInstance() {
        this.isAnEmbeddedInstance = true;
    }

    public getHttpOptions(): HTTPOptions {
        return this.httpOptions;
    }

    /**
     * Método simplificado para envio de requests de report (ou log) - que não precisam de response.
     * o primeiro parametro 'e o requestType,
     * o segundo, os parametros especificos da interface de request, exemplo de utilizacao:
     * sendRequest<IGetConnectionRouteRequest, IGetConnectionRouteResponse>(apiRequestType.connections.routes.getOne)({ connectionRouteIdNS });
     * @param requestType um dos campos que esta no arquivo message-types.ts
     * @param data um objeto com os dados necessários para esse report.
     * @returns Obj: { success, data, error }
     */
    public async sendActionReportRequest(requestType: IRequest['requestType'], data: any): Promise<TActionReportRequestResponse> {
        const reqData = {
            ...this.rbs.secureBasicRequest(requestType),
            ...data,
            requestType,
        }
        const serverUrl = this.getServerURL(requestType);

        console.debug(`> sendActionReportRequest(${requestType}), data:`, data);

        const actRepResult = await fetch(serverUrl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Connection': 'keep-alive',
                'Authorization': window._COLMEIA_.getToken(),
            },
            body: JSON.stringify(reqData),
            keepalive: true
        }).then(response => {
            if (!response.ok) {
                console.error('sendActionReportRequest response error: ', response);
                throw new Error('sendActionReportRequest call failed');
            }
            return response.json();
        }).then(responseData => {
            console.debug('> sendActionReportRequest call successful:', data);
            return {
                success: true,
                data: responseData,
                error: null,
            };
        }).catch(error => {
            return {
                success: false,
                data: null,
                error,
            };
        });
        console.debug(`> sendActionReportRequest(${requestType}) actRepResult:`, actRepResult);

        return actRepResult
    }

    /**
     * Metodo responsavel por enviar requests para o servidor
     * o primeiro parametro 'e o requestType,
     * o segundo, os parametros especificos da interface de request, exemplo de utilizacao:
     * sendRequest<IGetConnectionRouteRequest, IGetConnectionRouteResponse>(apiRequestType.connections.routes.getOne)({ connectionRouteIdNS });
     * @param requestType um dos campos que esta no arquivo message-types.ts
     * @returns response da interface especifica passada como parametro
     */
    public sendRequest<Request extends IRequest, Response extends IResponse = IResponse>(requestType: Request['requestType']) {
        return async <Options extends Compute<SimpleDifferences<Request, IRequest>>>(options: Options, config: IQuickRequestOptions = {}): Promise<Response | undefined> => {


            const response = await (this.doRequest<Request>(
                requestType,
                // @ts-expect-error
                options,
                config.defaultActionOnError,
                config.removeSpinner,
                config.showError,
            ) as unknown as Promise<Response | undefined>);
            return response;
        }
    }

    /**
     * Igual ao @link sendRequest, entretando lança um erro se não obtiver resposta
     */
    public safeSendRequest<Request extends IRequest, Response extends IResponse = IResponse>(requestType: Request['requestType']) {
        const requester = this.sendRequest<Request, Response>(requestType);
        return async <Options extends Compute<SimpleDifferences<Request, IRequest>>>(options: Options, config: IQuickRequestOptions = {}): Promise<Response> => {
            const response = await requester(options, config);
            if (response === undefined) {
                throw {
                    requestType,
                    ...options,
                };
            }
            return response;
        }
    }

    /**
     * @deprecated use o método sendRequest ao invés deste
     */
    public quick<Request extends IRequest, Response extends IResponse = IResponse>() {
        return <Options extends Differences<IRequest, Request> & Pick<IRequest, 'requestType'>>(options: Options, config: IQuickRequestOptions = {}): Promise<Response> => {
            const response = this.doRequest<Request>(
                options.requestType,
                // @ts-expect-error
                options,
                config.defaultActionOnError,
                config.removeSpinner,
                config.showError,
            ) as unknown as Promise<Response>;
            return response;
        }
    }

    /**
     * @deprecated use o método sendRequest ao invés deste
     */
    public async doRequest<
        Request extends Extends,
        Extends extends IRequest = IRequest,
        Response extends IResponse = IResponse,
    >(
        requestType: Request['requestType'],
        request: Omit<Request, keyof Extends>,
        defaultActionOnError: DefaulActionOnError = DefaulActionOnError.standardErrorMessage,
        removeSpinner?: boolean,
        showError?: boolean,
        alwaysReturn?: boolean
    ): Promise<Response | undefined> {
        const infra: IInfraParameters = {
            ...(!removeSpinner ? this.rbs.getContextNoCallBackSpinnningParameters() : this.rbs.getContextNoCallBackNoSpinnningParameters()),
            defaultActionOnError,
        };
        const req = {
            ...(this.rbs.secureBasicRequest(requestType) as Extends),
            ...request,

            requestType,
        };

        const response: ClientInfraResponse = await this.managedRequest(
            infra,
            req,
            showError
        );

        if (response.executionOK || alwaysReturn) {
            return response.response as Response;
        }
    }

    public async guestManagedRequest(infraParameters: IInfraParameters, data: IRequest, showError: boolean = true): Promise<ClientInfraResponse> {
        return this.managedRequest(infraParameters, data, showError);
    }

    public getServerURL(messageType: string) {
        const { path, isBotEndpoint } = getRouteByMessageType({
            messageType,
            isAnEmbeddedInstance: this.isAnEmbeddedInstance,
        })
        const ROOT_URL = (isBotEndpoint && environment.BOT_URL) || clientConstants.ROOT_URL;
        const SERVER_URL = `${ROOT_URL}${path}`;
        return SERVER_URL;
    }

    public async managedRequest(
        infraParameters: IInfraParameters,
        data: IRequest,
        showError: boolean = true,
        extraParams?: IExtraParams,
    ): Promise<ClientInfraResponse> {
        let replay: () => Promise<ClientInfraResponse>
        if (window.IS_LOGGING_REQUEST_FN) {
            const args: any = arguments;
            replay = () => this.managedRequest.apply(this, args);
        }

        if (this.isMappingRequestTypeToIdMenu) {
            window['mappingRequestTypeToIdMenuCallback']?.(infraParameters, data, showError);
        }

        if (this.rateLimitSvc.isBlocked(data.requestType)) {
            return;
        }

        const isCacheable = this.requestCacheSvc.isCacheableRequest(data);

        if (isCacheable) {
            const cached: ClientInfraResponse = this.requestCacheSvc.getCachedResponse(data);
            if (cached) return cached;
        }

        if (!isCacheable && isDevMode()) {
            this.checkRequestRecurrency(data);
        }

        if (infraParameters.spinType !== SpinType.none) {
            this.screenLoadingSvc.show({
                show: true,
                spinType: SpinType.fullScreen
            });
        };

        const SERVER_URL = this.getServerURL(data.requestType);
        data = this.logHomologObj.isActive
            ? {
                ...data,
                hmlLog: this.logHomologObj
            }
            : data;

        const migrationVersion = this.localStorageSvc.getItem<IMigrationVersionComm>(CURRENT_JOURNALING_VERSION_ON_LOCAL_STORAGE)
        // versionamento de mudancas do sistema
        // console.log({migrationVersion})
        if (isValidRef(migrationVersion) && migrationVersion.isSelected && isValidRef(migrationVersion.version)) {
            data.changeVersion = migrationVersion?.version?.idVersion;
        }

        const promise: Promise<ClientInfraResponse> = new Promise(resolve => {
            this.httpClient.post(SERVER_URL, data, this.httpOptions).pipe(
                retry(0),
                timeout(MAX_TIMEOUT)
            ).subscribe((res: IResponse) => {
                //
                this.log(`requestType`, data.requestType, { request: data }, { response: res }, replay);

                if (res.friendlyError) {
                    resolve(this.rbs.buildInfraClientResponse(res, FriendlyMessage.factoryMessage(res.friendlyError), null));
                } else {
                    resolve(this.rbs.buildInfraClientResponse(res, null, null));
                };
            }, (error) => {
                // handling errors related to this observable, ex: timeout, connection refused
                console.error(`ServerCommunicationService.postRequest error: `, error);
                const defaultFriendlyMessage = new FriendlyMessage('controledPostRequest', false);
                defaultFriendlyMessage.add(errorCodes.client.connection.clientConnectionError);
                if (showError) {
                    this.warningScreenSvc.showError(defaultFriendlyMessage);
                }
                resolve(this.rbs.buildInfraClientResponse(null, defaultFriendlyMessage, null));
            });
        });

        try {
            const clientResponse: ClientInfraResponse = await promise;

            if (infraParameters.spinType !== SpinType.none) {
                this.screenLoadingSvc.show({
                    show: false,
                    spinType: SpinType.fullScreen
                });
            };

            if (!clientResponse.friendlyMessage) {
                // if response has no friendlyError we should throw an exception
                // because something very bad happened, global-error-handler should get this exception
                const defaultFriendlyMessage = new FriendlyMessage('controledPostRequest', false);
                defaultFriendlyMessage.add(errorCodes.client.connection.serverResponseIncomplete, 'IResponse without FriendlyError');
                if (showError) {
                    this.warningScreenSvc.showError(defaultFriendlyMessage);
                }

                // okState is false, means we need to go deep.. server error or a business message?
            } else {
                showError = extraParams?.ignoreErrorIDs.some(errorID => clientResponse.friendlyMessage.getFriendlyArray().some(err => err.errorID === errorID))
                    ? false
                    : showError;

                if (clientResponse.friendlyMessage.isOk()) {
                    if (clientResponse.friendlyMessage.hasReturnMessageWithError()) {
                        clientResponse.executionOK = false;
                        clientResponse.friendlyMessage.setOk(false);
                    };

                    if (clientResponse.friendlyMessage.hasDelegatedMessageToInfra() && showError) {
                        this.warningScreenSvc.showWarning(clientResponse.friendlyMessage, clientResponse.response);
                    };
                } else {
                    const error: ErrorDomain = clientResponse.friendlyMessage.getFirstErrorDomain();
                    console.error(`ServerCommunicationService.postRequest error: `, clientResponse.response);

                    if (clientResponse.friendlyMessage.hasCustomMessage() || (error.isNonBusinessMessage() && !clientResponse.friendlyMessage.isBusinessError())) {
                        clientResponse.executionOK = false;
                        //if (!clientResponse.friendlyMessage.isCookieLogError())
                        if (showError) {
                            const isMatchIndexError = clientResponse.response?.jsError?.message?.includes(PRE_FAILED_MATCH_INDEX_ERROR);
                            if (isMatchIndexError) {
                                clientResponse.friendlyMessage.setBusinessError(true);
                                this.warningScreenSvc.showWarning('Não foi possível realizar a ação desejada. Contate o suporte.', clientResponse.response)
                            } else {
                                this.warningScreenSvc.showError(clientResponse.friendlyMessage, clientResponse.response);
                            }
                        }
                    } else {
                        clientResponse.executionOK = true;
                        if (infraParameters.defaultActionOnError === DefaulActionOnError.standardErrorMessage) {
                            if (showError) {
                                this.warningScreenSvc.showWarning(clientResponse.friendlyMessage, clientResponse.response);
                            }
                        } else if (infraParameters.optionButtons.length > 0 && infraParameters.clientCallback) {
                            // mostrar mensagens
                        };
                    };
                }

                if (!clientResponse.friendlyMessage.isOk()) {
                    this.lastFriendlyMessageError = clientResponse.friendlyMessage;
                }
            };

            if (clientResponse?.executionOK) {
                this.rateLimitSvc.handleResponse(data.requestType, clientResponse.response);

                if (isCacheable) {
                    this.requestCacheSvc.saveCache(data, clientResponse);
                }
            }

            return clientResponse;
        } catch (err) {
            console.error('Esse erro acontece por alguma variável nula pós requisição', { err });
        } finally {
            if (infraParameters.spinType !== SpinType.none) {
                this.screenLoadingSvc.show({
                    show: false,
                    spinType: SpinType.fullScreen
                });
            };
        };

        return this.rbs.buildInfraClientResponse(null, null, null);
    };

    private checkRequestRecurrency(request: IRequest) {
        const { dateTime, ...req } = request;
        const requestType = req.requestType;
        const requestKey: string = safeStringifyJSON(req);
        const lastClocktick = this.requestRecurrency.get(requestKey);
        const now = getClock();
        this.requestRecurrency.set(requestKey, now);

        if (!isValidNumber(lastClocktick)) return;

        const intervalFromLast = now - lastClocktick;

        if (intervalFromLast < requestIntervalTreshold) {
            console.error(`Atenção! Checar requisição do tipo "${requestType}", 2 ou mais requisições idênticas em um intervalo de ${intervalFromLast}ms, Request: `, req);
        }

        this.requestRecurrency.delete(requestKey);
    }

    public async uploadFiles(infraParameters: IInfraParameters, fileArray: TIFileUpdateArray): Promise<ClientInfraResponse> {
        const promises: Array<Promise<boolean>> = [];
        let response: ClientInfraResponse;

        if (infraParameters.spinType !== SpinType.none) {
            this.screenLoadingSvc.show({
                show: true,
                spinType: SpinType.fullScreen
            });
        }

        try {
            for (const file of fileArray) {
                promises.push(this.uploadFile(file.fileInfo, file.file));
            };

            const results: Array<boolean> = await Promise.all(promises);
            const atLeastOneFail: boolean = results.some((wasOk) => { return !wasOk; });
            response = this.rbs.buildSimpleInfraResponse(!atLeastOneFail);

        } catch (err) {
            response = this.rbs.buildSimpleInfraResponse(false);
            if (infraParameters.defaultActionOnError === DefaulActionOnError.standardErrorMessage) {
                const defaultFriendlyMessage = new FriendlyMessage('uploadFiles', false);
                defaultFriendlyMessage.add(errorCodes.client.connection.timeoutExpired, 'Some file error');
                this.warningScreenSvc.showWarning(defaultFriendlyMessage);
            } else if (infraParameters.defaultActionOnError === DefaulActionOnError.useButtonsToDecide) {
                // response.executionOK = await MODAL
            }

        } finally {
            if (infraParameters.spinType !== SpinType.none) {
                this.screenLoadingSvc.show({
                    show: false,
                    spinType: SpinType.fullScreen
                });
            }
        };

        return response;
    }

    /**
     * uploads file to server using new angular file uploader api
     * upload progress can be accessed by listening to uploadStatusListener()
     * @param  {string} fileUID
     * @param  {File} file
     * @returns Promise
     */
    public async uploadFile(fileInfo: IServerFileInfo, file: Blob): Promise<boolean> {
        return new Promise<boolean>((resolve) => {
            // creating form data
            const formData: FormData = new FormData();
            formData.append('file', file, fileInfo.name);
            // if (isValidObject(additionalParams)) {
            //     formData.append('additionalParams', JSON.stringify(additionalParams));
            // }

            // setting file info
            const cachedFile = ClientCachedFile.fileToNewCachedFile(file, fileInfo.name);
            cachedFile.setHash(fileInfo.hash);
            const selectedFile: SelectedFile = SelectedFile.newClientFileInfo(
                cachedFile,
                {
                    currentName: fileInfo.name,
                    idMediaTag: null
                });

            const UPLOAD_URL: string = (fileInfo.idMediaTag === MMconstant.tag.voiceMessage)
                ? clientConstants.VOICE_URL
                : clientConstants.MULTIMEDIA_URL;

            // sending file to server
            this.httpClient.post(`${UPLOAD_URL}/${fileInfo.idMedia}`, formData, {
                observe: 'events',
                reportProgress: true,
                withCredentials: true,
            })
                .subscribe(
                    // handling UploadProgress
                    (event: HttpProgressEvent) => {
                        const type: HttpEventType = event.type;
                        if (type === HttpEventType.UploadProgress) {
                            if (event.total == null)
                                return;
                            const uploadPercentage: number = Math.round(100 * event.loaded / event.total);
                            this.uploadStatus.next(new FileUploadSignal(selectedFile, uploadPercentage, true));
                        }
                    },
                    // error
                    error => {
                        this.uploadStatus.next(new FileUploadSignal(selectedFile, 0, false));
                        resolve(false);
                    },
                    // upload completed
                    () => {
                        return resolve(true);
                    }
                );
        });
    };

    public async colmeiaServicesRequest<T, U>(request: T): Promise<U> {
        const url: string = clientConstants.ROOT_URL + ROUTES.v1.colmeia_services;
        const response = await this.httpClient.post<U>(url, request, {}).toPromise();
        return response;
    }

    /**
     * Emmits upload progress events to listeners
     * @returns Observable
     */
    public uploadStatusListener(): Observable<FileUploadSignal> {
        return this.uploadStatus.asObservable();
    }

    getMultimediaSignedTempURL(idMedia: string): Observable<string> {
        let videoUrl: string = `${clientConstants.MULTIMEDIA_URL}/${idMedia}`
        return this.httpClient.get<ISignedUrlResponse>(videoUrl)
            .pipe(
                map(response => response.url),
                // catchError(_ => throwCustomFieldError(
                //     clientErrorCodes.errorPrimaryID,
                //     clientErrorCodes.multimedia.noVideoAvailable,
                //     true,
                //     'MultimediaService.getVideo',
                //     videoUrl))
            )
    }
}
