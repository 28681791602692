import { AfterViewInit, Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild, ViewChildren } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ICopilotServer } from '@colmeia/core/src/shared-business-rules/copilot/copilot.model';
import { IServiceDeployedServices } from '@colmeia/core/src/shared-business-rules/deployed-services/deployed-services';
import { EWebChatEmbeddedType } from '@colmeia/core/src/shared-business-rules/embedded/webchat-embedded.model';
import { ENonSerializableObjectType } from '@colmeia/core/src/shared-business-rules/non-serializable-id/non-serializable-id-interfaces';
import { IdDep } from '@colmeia/core/src/shared-business-rules/non-serializable-id/non-serializable-types';
import { appURLToEnvironment, EAppBaseURL, getEnvironmentByAppUrl } from '@colmeia/core/src/shared-business-rules/production-deploy/prod-deploy-model';
import { ColmeiaWindowRef } from 'app/components/dashboard/dashboard-foundation/colmeia-window/colmeia-window-ref';
import { IColmeiaDialogComponentData } from 'app/components/dialogs/dialog/dialog.component';
import { LookupService } from 'app/services/lookup.service';

export type TAttendanceCopilotData = {
    copilotId: IdDep<ENonSerializableObjectType.attCopilot>,
}

@Component({
  selector: 'app-attendance-copilot',
  templateUrl: './attendance-copilot.component.html',
  styleUrls: ['./attendance-copilot.component.scss']
})
export class AttendanceCopilotComponent implements OnInit, AfterViewInit, OnDestroy {
    @ViewChild('hostCopilot', { static: true }) hostCopilot: ElementRef<HTMLDivElement>;
    colmeiaChat: any;

    constructor(
        public lookupSvc: LookupService,
        public colmeiaRef: ColmeiaWindowRef,
        @Inject(MAT_DIALOG_DATA) public dataToComponent: TAttendanceCopilotData,

    ) {
        console.log({dataToComponent})
    }

    ngOnInit(): void {
    }

    ngAfterViewInit(): void {
        this.openCopilotChat();
    }

    ngOnDestroy(): void {
        this.colmeiaChat?.destroy();
    }

    async openCopilotChat() {
        try {
            const url = this.getCurrentBaseUrl();
            const environment = getEnvironmentByAppUrl(url);
            const copilot: ICopilotServer = await this.lookupSvc.getNS(this.dataToComponent.copilotId);
            // const service: IServiceDeployedServices = await this.lookupSvc.getNS(copilot.deployedServiceId)
            this.colmeiaChat = new (window as any).ColmeiaChat({
                serviceId: copilot.deployedServiceId,
                webchatType: EWebChatEmbeddedType.attCopilot,
                // botId: service.parameters.idBotFile,
                position: "bottom-right",
                environment,
                mode: 'inline',
                style: {
                  '--chat-box-height': '100%',
                  'height': '100%'
                },
                host: this.hostCopilot.nativeElement
            });
            //@ts-ignore
            this.colmeiaChat.addEventListener('minify', e => {
                console.log('minify event', {e})
                this.colmeiaRef.minimize()
                e.preventDefault()
            })
        } catch (error) {
            console.error(error);
        }
    }

    erroHandler = (ev: any) => {
    }

    getCurrentBaseUrl() {
        const url = new URL(window.location.href);
        const baseUrl = <EAppBaseURL>url.origin;
        return baseUrl;
    }

}
