import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { INaviTracker } from '@colmeia/core/src/shared-business-rules/bot/bot-model';
import { IChoiceConfigure } from '@colmeia/core/src/shared-business-rules/metadata/meta-engagement';
import { ENonSerializableObjectType } from '@colmeia/core/src/shared-business-rules/non-serializable-id/non-serializable-id-interfaces';
import { ChoiceTrackerComponent } from 'app/components/dashboard/choice-tracker/choice-tracker.component';
import { DashBoardService } from 'app/services/dashboard/dashboard.service';

export type TNaviTrackerPickerParameters = {
  naviTracker: INaviTracker
  title?: string
  smallSize?: boolean
  darkColorMode?: boolean
}

export type TNaviTrackerResponse = {
  naviTracker: INaviTracker,
  tagName: string
}

@Injectable({
  providedIn: 'root'
})
export class ChoiceTrackerService {

  constructor(
    private dialogSvc: MatDialog,
  ) { }

  public async open(naviTracker: INaviTracker, title?: string): Promise<TNaviTrackerResponse | undefined> {
    const res = await this.dialogSvc.open<
      ChoiceTrackerComponent,
      TNaviTrackerPickerParameters,
      TNaviTrackerResponse
    >(ChoiceTrackerComponent, {
      data: {
        naviTracker,
        title
      }
    }).afterClosed().toPromise();
    return res
  }


}
