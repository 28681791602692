
<section class="group-order-section" >
  @if (state === 'order') {
    <mat-form-field class="no-padding-bottom pill-mode-selector" appearance="fill">
      <mat-select [value]="parameters.selected" (selectionChange)="onSelectOrder($event.value)">
        <mat-option *ngFor="let mode of EGroupOrderSelectorMode | keyvalue" [value]="mode.value">
          {{ mode.value | translate }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  }

  @if (state === 'search') {
    <div class="search-bar-container">
      <mat-icon size="20">search</mat-icon>         
      <input 
        matInput
        type="search"
        class="simple-input"
        placeholder="Pesquisar ou encontrar uma conversa"
        [(ngModel)]="searchToken"
        (keyup.enter)="emitSearch()"
        (ngModelChange)="emitSearch()"> 
    </div>
  }
  
  <div class="buttons-row">
    @if (state === 'search') {
      <button mat-icon-button (click)="toggleSearch()" class="small-size fix-touch-area">
        <mat-icon class="mat-20">arrow_back</mat-icon>
      </button>
    }

    @if (state === 'order') {
      <button mat-icon-button (click)="toggleSearch()" class="small-size fix-touch-area">
        <mat-icon class="mat-20">search</mat-icon>
      </button>

      <ng-container *ngFor="let option of $configOptions">
        <button mat-icon-button
          *ngIf="showConfigOption(option)"
          class="group__flat__icon small-size fix-touch-area"
          [class.is__recent__mode]="applyRotateOnIcon(option)"
          (click)="onClickOption(option)"
        >
          <mat-icon class="mat-18">{{ getOptionIcon(option) }}</mat-icon>
        </button>
      </ng-container>
    }
  </div>
</section>
