import { Component } from '@angular/core';
import { EChatViewStyle } from 'app/components/backbone/chat-backbone.component';
import { ColmeiaWindowRef } from 'app/components/dashboard/dashboard-foundation/colmeia-window/colmeia-window-ref';
import { ColmeiaWindowService } from 'app/components/dashboard/dashboard-foundation/colmeia-window/colmeia-window.service';
import { RootComponent } from 'app/components/foundation/root/root.component';
import { AttendantRegistrationHandler } from 'app/handlers/chat-right-side-bar/attendant-registration.handler';
import { ChatBackboneModel } from 'app/model/chat-backbone.model';
import { IFormSchemmaAndEngagement, IFormSchemmaAndEngagementWithMedia } from 'app/model/chat-options.model';
import { AnnotationsService } from 'app/services/annotations.service';
import { AttendanceService } from 'app/services/attendance.service';
import { NavigatorServices } from 'app/services/controllers-services/navigator/navigator.service';
import { GeneralFormService } from 'app/services/general-form.service';
import { AttendanceAnnotationsService } from 'app/services/attendance-annotations.service';
import { SessionService } from 'app/services/session.service';
import { Interaction } from '@colmeia/core/src/interaction/interaction';

@Component({
    selector: 'chat-options-attendant-registration',
    templateUrl: './chat-options-attendant-registration.component.html',
    styleUrls: ['./chat-options-attendant-registration.component.scss']
})
export class ChatOptionsAttendantRegistrationComponent extends RootComponent<any> {
    public model: ChatBackboneModel;
    public viewStyle: EChatViewStyle;
    public chatViewStyleTypes: typeof EChatViewStyle = EChatViewStyle;
    public metadatas: IFormSchemmaAndEngagementWithMedia[];
    public openedWindows: Map<string, ColmeiaWindowRef> = new Map();

    constructor(
        private attendantRegistrationHandler: AttendantRegistrationHandler,
        private attendanceService: AttendanceService,
        private annotationsService: AnnotationsService,
        private generalFormSvc: GeneralFormService,
        private session: SessionService,
        private windowSvc: ColmeiaWindowService,
        private navigatorSvc: NavigatorServices,
        private attAnnotationsSvc: AttendanceAnnotationsService,
    ) {
        super()
    }

    ngOnInit() {
        const model = ChatBackboneModel.getActiveBackBoneModel();
        const { viewStyle } = model.getDisplayOptions();

        this.model = model;
        this.viewStyle = viewStyle;
        this.metadatas = this.attendantRegistrationHandler.getMetadatas() || [];

        console.log({METADATAS: this.metadatas})
    }

    async openAnnotations(metadata: IFormSchemmaAndEngagement, $event: PointerEvent) {
        $event.stopPropagation();

        const idGroup: string = this.session.getSelectedGroupID();
        const idInteraction: string = this.attendanceService.getReplyInteractionForGroup(idGroup);
        const interaction: Interaction = Interaction.staticFactory(idInteraction);

        this.attAnnotationsSvc.initAnnotation({
            idGroup,
            idInteraction,
            metadata,
            annotationsParameters: {
                onSaveAnnotationCallback: () => {
                    this.annotationsService.messageInstanceChangeDetection(interaction, idGroup);
                },
            }
        });
    }

    onViewModeChangedCallback(viewStyle) {
        this.model.onViewModeChangedCallback(viewStyle)
    }
}
