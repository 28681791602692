import { BotHostNode } from "@colmeia/core/src/shared-business-rules/graph-transaction/host/bot/bot.host";
import { GraphElement } from "@colmeia/core/src/shared-business-rules/graph/essential/graph-element";
import { GraphRulesProcessor } from "@colmeia/core/src/shared-business-rules/graph/essential/graph-rules-processor";
import { stringToColor } from "app/utils/random-color-gen";
import tinycolor from "tinycolor2";

const MENU_CONTAINER_DEFAULT_COLORS = [
    '#34927E',
    '#3D5092',
    '#529CB4',
    '#B37905',
    '#AF7470',
];

export function getBPMRulesBotNodeColor(graphElement: GraphElement, ruleProcessor: GraphRulesProcessor): string {
    const host = graphElement.getHostObject() as BotHostNode;
    const baseColor = getNodeBaseColor(graphElement, ruleProcessor);

    if (graphElement.isExternalElementOnDiagram()) {
        return undefined;
    }

    if (host.isMenuContainer()) {
        return tinycolor(baseColor).darken(18).desaturate(4).toHexString();
    }

    if (host.isMenuItem()) {
        return baseColor;
    }

    return undefined;
}

function getNodeBaseColor(graphElement: GraphElement, ruleProcessor: GraphRulesProcessor): string {
    const host = graphElement.getHostObject() as BotHostNode;

    if (host.isMenuContainer()) {
        // color is stored and saved inside the render data
        let baseColor = graphElement.getRenderData().color;

        if (!baseColor) {
            baseColor = getMenuContainerColor(graphElement, ruleProcessor);

            graphElement.setRenderData({
                ...graphElement.getRenderData(),
                color: baseColor
            });
        }

        return baseColor;
    }

    if (host.isMenuItem()) {
        const containerElement = graphElement.getNeighborsPointingToMe()[0].neighbor;
        let baseColor = containerElement.getRenderData().color;

        // if container has no color, make recursive call to generate the color
        if (!baseColor) {
            baseColor = getNodeBaseColor(containerElement, ruleProcessor);
        }

        return baseColor;
    }

    return undefined;
}

function getMenuContainerColor(menuContainer: GraphElement, ruleProcessor: GraphRulesProcessor): string {
    // containers come ordered by oldest first
    const allContainers = getAllMenuContainersInOrder(ruleProcessor);
    const currentIndex = allContainers.indexOf(menuContainer);
    const allDefaultColorsTaken = currentIndex >= MENU_CONTAINER_DEFAULT_COLORS.length;

    if (!allDefaultColorsTaken) {
        return MENU_CONTAINER_DEFAULT_COLORS[currentIndex];
    } else {
        // gen random color
        return generateMenuContainerColor(menuContainer);
    }
}

function generateMenuContainerColor(graphElement: GraphElement): string {
    return stringToColor(graphElement.getHostedID(), {
        saturation: {
            base: 20,
            randomizedRange: 30
        },
        lightness: {
            base: 50
        }
    });
}

function getAllMenuContainersInOrder(ruleProcessor: GraphRulesProcessor): GraphElement[] {
    return ruleProcessor.getAllNodes()
    .filter(elem => {
        const host = elem.getHostObject() as BotHostNode;

        return host.isMenuContainer() && !elem.isExternalElementOnDiagram();
    }).sort((a, b) => {
        const nsA = a.getHostObject().getNonSerializable();
        const nsB = b.getHostObject().getNonSerializable();

        return nsA.clockTick - nsB.clockTick;
    });
}
