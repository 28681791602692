import { EBotEventType } from "@colmeia/core/src/shared-business-rules/bot/bot-event-model";
import { EBotActionType } from "@colmeia/core/src/shared-business-rules/bot/new-bot-action";

export enum EEventMessageType { 
    content = 'content',
    preContent = 'preContent',
    posContent = 'posContent'
}
export interface IEventMessageComposition { 
    eventMessageType: EEventMessageType;
    messages: string[];
}

export const defaultMessagesMap: Map<EBotActionType | EBotEventType, IEventMessageComposition[]> = new Map<EBotActionType | EBotEventType, IEventMessageComposition[]>([
    [   EBotEventType.customerExpire, [
            {
                eventMessageType: EEventMessageType.preContent,
                messages: ['Oi, estamos estamos te aguardando, você ainda está por aqui?']
            },
            {
                eventMessageType: EEventMessageType.posContent,
                messages: ['Estamos encerrando o seu atendimento, não se preocupe você pode voltar para o nosso atendimento de segunda-feira à sexta-feira das 08:00 às 17:00.']
            } 
        ] as IEventMessageComposition[]
    ],
    [
        EBotEventType.onQueueAdd, [
            {
                eventMessageType: EEventMessageType.content,
                messages: ['Você foi adicionado a fila, te atenderemos em breve.']
            },
        ]
    ],
    [
        EBotEventType.onQueueNext, [
            {
                eventMessageType: EEventMessageType.content,
                messages: ['Você é o próximo da fila.']
            },
        ]
    ],
    [
        EBotEventType.onCustomerGaveUp, [
            {
                eventMessageType: EEventMessageType.content,
                messages: [
                    `Que pena que não pode esperar, fique a vontade para retornar ao nosso atendimento de segunda-feira à sexta-feira das 08:00 às 17:00, o nosso auto atendimento pelo bot está disponível 24 horas.`
                ]
            },
        ]
    ],
    [
        EBotEventType.onQueueOnBegin, [
            {
                eventMessageType: EEventMessageType.content,
                messages: [`Olá, meu nome é {nome do atendente}, como posso te ajudar?`]
            },
        ]
    ],
    [
        EBotEventType.onQueueOnFinish, [
            {
                eventMessageType: EEventMessageType.content,
                messages: [`O seu atendimento foi encerrado, obrigada por entrar em contato.`]
            },
        ]
    ],
    [
        EBotEventType.onQueueReturn, [
            {
                eventMessageType: EEventMessageType.content,
                messages: [`O seu atendente teve um problema e te retornou para fila, mas não se preocupe, você tem prioridade para ser atendido.`]
            },
        ]
    ],
    [
        EBotEventType.onQueueTimeRestriction, [
            {
                eventMessageType: EEventMessageType.content,
                messages: [`Desculpe, não estamos em horário de atendimento, você pode entrar em contato como os nossos atendentes de segunda-feira à sexta-feira das 08:00 às 17:00.`, `Lembramos que o nosso auto atendimento pelo bot está disponível 24 horas.`]
            },
        ]
    ],
    [
        EBotEventType.onQueueTransfer, [
            {
                eventMessageType: EEventMessageType.content,
                messages: ['Estamos transferindo o seu atendimento.']
            },
        ]
    ],
    [
        EBotEventType.onCreatingCredentials, [
            {
                eventMessageType: EEventMessageType.content,
                messages: [`Aguarde um pouco, estamos criando suas credenciais para o atendimento.`]
            },
        ]
    ],
    [
        EBotEventType.onAttendentAbsent, [
            {
                eventMessageType: EEventMessageType.content,
                messages: [`O seu atendente precisou se ausentar por alguns minutos, mas não se preocupe, ele está recebendo as suas mensagens e te responderá em alguns minutos.`]
            },
        ]
    ],
    [
        EBotEventType.onAgentNotAvailable, [
            {
                eventMessageType: EEventMessageType.content,
                messages: [`No momento não há agentes online para realizar o seu atendimento. Lembramos que o nosso auto atendimento pelo bot está disponível 24 horas.`]
            },
        ]
    ],
    [
        EBotEventType.providerExpire, [
            {
                eventMessageType: EEventMessageType.content,
                messages: [`No momento não estou disponível para dar prosseguimento ao seu atendimento. Você voltará para a fila para ser realocado. Lembramos que o nosso auto atendimento pelo bot está disponível 24 horas.`]
            },
        ]
    ],
    [
        EBotEventType.onMaximumCustomerQueueReach, [
            {
                eventMessageType: EEventMessageType.content,
                messages: [`A capacidade de clientes aguardando atendimento foi atingida. Pedimos que retorne novamente em alguns minutos.`]
            },
        ]
    ],
]);