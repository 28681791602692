<section class="chat-options">
  <ng-container *ngIf="showOverlay$ | async as overlay">
    <div class="global-loading-overlay center-align" *ngIf="overlay.isVisible()">
      <ng-container *ngIf="loading$ | async as loading">
        <mat-spinner *ngIf="loading.isVisible()" diameter="28"></mat-spinner>
      </ng-container>
    </div>
  </ng-container>
  <section class="default-view">
    @for (view of views; track $index) {
      <button (click)="open(view, $event)" mat-mini-fab color="primary" [matTooltip]="view.text" class="fix-touch-area">
        <mat-icon
          [svgIcon]="view.icon"
          [class]="view.iconSize ? 'mat-' + view.iconSize : 'mat-16'"
        ></mat-icon>
      </button>
    }
  </section>
</section>