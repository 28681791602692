import { NamedNumber, NamedString } from "@colmeia/core/src/core-constants/named-types";
import { PrimaryIdOf } from "@colmeia/core/src/core-constants/named-types/named-primary-ids";
import { Avatar } from "../business/avatar";
import { constant } from "../business/constant";
import { Group, SocialNetwork } from "../business/group";
import { IAvatarJSON, IGroupJSON, IGroupJSONArray, TAvatarJSONArray } from "../comm-interfaces/business-interfaces";
import { EDelivery360Action } from "../comm-interfaces/interaction-interfaces";
import { INonSerializable } from "../shared-business-rules/non-serializable-id/non-serializable-id-interfaces";
import { ISocialMediaConnectionServer } from "../shared-business-rules/social-media/social-media.model";

export {
    NamedNumber, NamedString
};

export type TGlobalUID = string;
export type TNserUID = string;
export type TDatastoreID = string;
export type TPostgresPrimaryKey = string;
export type TPostgresPrimaryKeyAdEvent = NamedString<'pg__pk__colmeia_ad_event_type'>;
export type TNserUIDArray = string[];
export type TIdProperty = NamedString<'IdProperty'>
export type TArrayID = Array<TGlobalUID>;
export type TNumberArray = Array<number>;
export type SmartDate = Date;
export type IdMedia = NamedString<'IdMedia'>;
export type SHA256Hash = NamedString<'SHA256Hash'>;
export type CustomerNumber = NamedString<'CustomerNumber'>;

export type URLIdMedia = NamedString<'URLIdMedia'>;
export type URLExternalMedia = NamedString<'URLExternalMedia'>;
export type URLNamedIdMedia = NamedString<'URLNamedIdMedia'>;

export type IdSocialContext = PrimaryIdOf<SocialNetwork>;
export type IdSN = IdSocialContext;

export type IdConversation = NamedString<'IdConversation'>;

export type TAcceptedMetricType = string | number | boolean;
export type TArrayAvatar = Array<Avatar>;
export type TGroupArray = Array<Group>;          // esse tipo ficará dentro da instancia de Group
export type TExtendedParticipantArray = Array<TExtendedParticipant>
export type TExtendedParticipant = Avatar | Group;
export type ISecuredArrayJSON = IGroupJSONArray | TAvatarJSONArray;
export type ISecuredJSON = IGroupJSON | IAvatarJSON;

export const fieldMarkLiveInfo = '.';

export type TBranchID = string

export type TConfirmationTypeArray = Array<EConfirmationType>;
export type TConfirmationTypeMatrix /** go, watch */ = Array<TConfirmationTypeArray>;
export type IIdNSToNserMap = {
    [id in TNserUID]: INonSerializable
}

export enum EConfirmationType {
    colmeiaSent = 'csent', // Colmeia enviou
    sent = 'sen', // Provedor enviou
    read = 'rea', // ESTE PARA WA, FB e EMAIL, VOZ
    receive = 'rec', //ESTE para TODOS
    failed = 'fail', // falha na entrega
    queued = 'qued', // Sinch nos informa quando colocou na fila deles
    viewMedia = 'vm',
    channelClosed = 'cclo',
    span = 'spam',
    respond = 'respond',
    invalidNumber = 'invalidNumber',
    clicked = 'clicked',
    bounce = 'bounce',
    ignoredByActiveConversation = 'ignByAcC',
    ignoreByFilter = 'ignByFilter',
    noProcessFromColmeia = 'noProcssFrmColm',
    notRead = 'notRead',
    notAnswered = 'notAnswered',
    brokerOptOut = 'brokerOptOut',
    invalidSession = 'invalidSession',
    ignored = 'ignored',
    error = 'error'
};

export const confirmationTypeAsArray: TConfirmationTypeArray = Object.values(EConfirmationType);

export const mktRuleExclusiveConfirmationType: TConfirmationTypeArray = [EConfirmationType.notRead, EConfirmationType.notAnswered];

export interface IDeliveryStatusConfig {
    isSuccessDelivered: boolean;
    translation: string;
}

type TDeliveryStatusDB = {
    [confirmationType in EConfirmationType]: IDeliveryStatusConfig
}

export const deliveryDB: TDeliveryStatusDB = {
    [EConfirmationType.channelClosed]: { isSuccessDelivered: false, translation: 'Template Inválido' },
    [EConfirmationType.read]: { isSuccessDelivered: true, translation: 'Lido' },
    [EConfirmationType.receive]: { isSuccessDelivered: true, translation: 'Recebido' },
    [EConfirmationType.sent]: { isSuccessDelivered: true, translation: 'Enviado (Cliente Desconectado)' },
    [EConfirmationType.span]: { isSuccessDelivered: false, translation: 'Span' },
    [EConfirmationType.viewMedia]: { isSuccessDelivered: true, translation: 'Link Aberto' },
    [EConfirmationType.failed]: { isSuccessDelivered: false, translation: 'Falha Envio' },
    [EConfirmationType.colmeiaSent]: { isSuccessDelivered: true, translation: 'Enviado para whatsApp' },
    [EConfirmationType.respond]: { isSuccessDelivered: true, translation: 'Cliente Respondeu' },
    [EConfirmationType.invalidNumber]: { isSuccessDelivered: false, translation: 'Número invlálido' },
    [EConfirmationType.bounce]: { isSuccessDelivered: false, translation: 'Bounce: Irá tentar novamente' },
    [EConfirmationType.clicked]: { isSuccessDelivered: true, translation: 'Link Clicado' },
    [EConfirmationType.ignoredByActiveConversation]: { isSuccessDelivered: false, translation: 'Ignorado. Em conversação Ativa' },
    [EConfirmationType.ignoreByFilter]: { isSuccessDelivered: false, translation: 'Ignorado por filtro' },
    [EConfirmationType.notRead]: { isSuccessDelivered: true, translation: 'Não lido' },
    [EConfirmationType.notAnswered]: { isSuccessDelivered: true, translation: 'Não Respondido' },
    [EConfirmationType.queued]: { isSuccessDelivered: true, translation: 'Acrescentado á fila de envio' },
    [EConfirmationType.noProcessFromColmeia]: { isSuccessDelivered: true, translation: 'Ignorado pela colmeia' },
    [EConfirmationType.brokerOptOut]: { isSuccessDelivered: false, translation: 'Optout via Broker' },
    [EConfirmationType.invalidSession]: { isSuccessDelivered: false, translation: 'Sessão inválida' },
    [EConfirmationType.error]: { isSuccessDelivered: false, translation: 'Erro' },
    [EConfirmationType.ignored]: { isSuccessDelivered: false, translation: 'Ignorado' },
}


export const enviromentNames = {
    dev: 'dev',
    prod: 'prod',
    beta: 'beta'
};

//export const SOCKET_TIMEOUT =  12 * 60 * 60 * 1000; // DEBUG CLIENT
export const relevantGeolocationDistanceMeter: number = 4;

export interface INavigatorData {
    name: string;
    version: string;
    major: string;
};

export interface IPlatformData {
    name: string;
    version: string;
};

export interface IEngineData {
    name: string;
    version: string;
};

export interface IDeviceData {
    vendor: string;
    model: string;
    type: string;
}

export interface IMachineInfo {
    ip: string;
    navigator: INavigatorData;
    platform: IPlatformData;
    engine: IEngineData;
    device: IDeviceData;
}

export type TIdentifiableObject = object


export interface QueryParams {
    [key: string]: any;
}

export interface IExpressNativeRequestData {
    url: string,
    method: string,
    protocol: string,
    body: TIdentifiableObject,
    params?: TIdentifiableObject,
    headers: { host?: string, 'user-agent'?: string },
    hostname: string,
    originalUrl: string
    query?: QueryParams,
}

export interface IHttpServerRequestData {
    url: string,
    method: string,
    headers: IPartialHttpHeaders,
    query?: QueryParams,
    body?: TIdentifiableObject,
    params?: Record<string, string>,
    machineInfo?: IMachineInfo,
    curlRequest?: string,
    size?: number,
    userAgent?: string,
    timeStamp?: number,
}
export interface IPartialHttpHeaders {
    authorization?: string;
    [key: string]: any;
}
export interface IHttpServerResponseData {
    status: number,
    data: any,
    headers: Record<string, string>,
    size?: number,
    timeStamp?: number,
}

export interface IHttpServerRequestAndResponseData {
    req: IHttpServerRequestData,
    res: IHttpServerResponseData,
}

export type TSecurityCarries = Avatar | Group;



export interface ISearchInteractionAvatarDetail {
    nameAvatar: string;
    idMediaAvatar: string;
    groupName: string;
    idGroup: TGlobalUID;
    businessIDGroup: string;
};
export interface ISearchParticipantDetail {
    online: boolean;
}


export interface INavigationRules {
    cursor: string;
    fromGroupID: TGlobalUID;
    where: TGlobalUID;
    timed: {
        attention: TGlobalUID;
        afterDate: boolean;
        whenOccurs: number;
    };
    popularity: TGlobalUID;
    followUp: TGlobalUID;

    idInteractionType: TGlobalUID;
    interactionTime: {
        afterDate: boolean;
        clockTick: number;
    };
    getGroupData: boolean;
    isFirstNavigation: boolean;
};


export const bulkInviteFields = {
    metadataContainer: {
        idTemplate: constant.serializableField.auxiliars.aux01,
    },
    metadata: {
        idFieldMetadata: constant.serializableField.auxiliars.aux01,
    },
    peopleContainer: {
        idGroupToInvite: constant.serializableField.auxiliars.aux01,
    },
    personalInvite: {
        name: constant.serializableField.name,
        lastName: constant.serializableField.auxiliars.aux01,
        email: constant.serializableField.auxiliars.aux02
    },
};


export type TExtendedIdentifierArray = Array<IExtendedIdentifier>;


export interface IExtendedIdentifier {
    primaryID: TGlobalUID;
    idObjectType: TGlobalUID;
}

export type TCurrentSharedStateArray = Array<ICurrentSharedState>;
export type TInteractionSharedTo = { [idInteraction: string]: TCurrentSharedStateArray };

export interface ICurrentSharedState {
    to: TGlobalUID;
    state: ECurrentSharedState;
    isSharedAsFeature: boolean;
}

export enum ECurrentSharedState {
    NotShared = 1,
    AlreadyShared,
    PartialShared
}

export type TLabel = {
    source: string;
    idField: number;
}

export const coreClientConf = {
    limits: {
        maxMultiValue: 99
    }
}


export enum ESecurityChanged {
    participant = 1,
    archive,
    newRole,
    newSpoke,
    header,

};



export enum DeliveryProvider {
    sendGrid = 'sendGrid',
    twilio = 'twilio',
    whatsApp = 'whatsApp',
    infobip = 'infobip',
    colmeia = 'colmeia',
    telegram = 'telegram',
    facebook = 'facebook',
    instagram = 'instagram',
    sinch = 'sinch',
    wavy = 'wavy',
    smarters = 'smarters',
    pontaltech = 'pontaltech',
};

export type TDeliveryTargets = Array<IDeliveryTarget>;

export interface ISearchableDeliveryTarget {
    addressName?: string;
    address: string;
    providerType: EDelivery360Action;
    target: string;
}

export interface IWindowSearchableDeliveryTarget extends ISearchableDeliveryTarget {
    idSocialContext: string;
}

export interface IDeliveryTarget extends ISearchableDeliveryTarget {
    /** Our identification endpoint (phone number, e-mail address...) */
    provider: DeliveryProvider;
    /** Customer identification endpoint (phone number, e-mail address...) */
    target: string;
    connection?: ISocialMediaConnectionServer;
    addressName?: string;
}

export enum MessageTypeEnum {
    Image,
    Audio,
    Video
}

export const MimeType2MessageType = new Map(
    [
        ["image/jpeg", MessageTypeEnum.Image],
        ["image/png", MessageTypeEnum.Image],
        ["audio/mpeg3", MessageTypeEnum.Audio],
        ["audio/ogg", MessageTypeEnum.Audio],
        ["audio/aac", MessageTypeEnum.Audio],
        ["audio/m4a", MessageTypeEnum.Audio],
        ["audio/amr", MessageTypeEnum.Audio],
        ["audio/3gpp", MessageTypeEnum.Audio],
        ["audio/3gpp2", MessageTypeEnum.Audio],
        ["video/mp4", MessageTypeEnum.Video],
        ["video/3gpp", MessageTypeEnum.Video],
        ["video/3gpp2", MessageTypeEnum.Video]
    ]
);


export type RecursiveReadOnly<T> = {
    readonly [key in keyof T]: T[key] extends object ? Readonly<T[key]> : T[key];
};
export const emailPattern: RegExp = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const genericBrazilianCityPattern: RegExp = /^[A-Za-zÀ-ÖØ-öø-ÿ]+(?:[ '\-][A-Za-zÀ-ÖØ-öø-ÿ]+)*$/;
export const cnpjPattern: RegExp = /^\d{14}$|^\d{2}\.\d{3}\.\d{3}\/\d{4}-\d{2}$/;
export const cpfPattern: RegExp = /^\d{11}$|^\d{3}\.\d{3}\.\d{3}-\d{2}$/;
export const pixRandomKeyPattern: RegExp = /^[0-9a-zA-Z]{32}$|^[0-9a-zA-Z]{8}-[0-9a-zA-Z]{4}-[0-9a-zA-Z]{4}-[0-9a-zA-Z]{4}-[0-9a-zA-Z]{12}$/;
export const brPhoneNumberPattern: RegExp = /^(\+?55\s?)?(\(?\d{2}\)?)[\s\-]?(\d{4,5}[\s\-]?\d{4})$/;
export const cleanedBRPhoneNumberPattern: RegExp = /^(55\d{2}9\d{8}|\d{2}9\d{8}|9\d{8})$/;

export const brazilianDDDs: string[] = [
    "68", "96", "92", "97", "91", "93", "94", "69", "95", "63", //<< North Region
    "82", "71", "73", "74", "75", "77", "85", "88", "98", "99", "83", "81", "87", "86", "89", "84", "79", //<< Northeast Region
    "61", "62", "64", "65", "66", "67", //<< Central-West Region
    "27", "28", "31", "32", "33", "34", "35", "37", "38", "21", "22", "24", "11", "12", "13", "14", "15", "16", "17", "18", "19", //<< Southeast Region
    "41", "42", "43", "44", "45", "46", "51", "53", "54", "55", "47", "48", "49" //<< South Region
];

export enum ESequenceRenewPolicy {
    never = 'never',
    dailyRenewed = 'daily',
    weekly = 'weekly',
    monthly = 'montlhy',
    yearly = 'yearly'
};


export interface IBasicConversationInfo {
    botTalking?: true
    waitingForHuman?: true;
    humanTalking?: true;
    finished?: true;
    assessAnomaly?: true,
    includeExpireQueue?: true;
    fromColmeia?: true;
    noSemaphoreOps?: true;
    massComm?: true;
}
