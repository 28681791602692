import { Injectable } from '@angular/core';
import { EBotContentEvent } from "@colmeia/core/src/shared-business-rules/bot/bot-content-model";
import { ENextGenBotElementType } from "@colmeia/core/src/shared-business-rules/bot/bot-model";
import { EBotActionType, ENodeAssetIcons } from "@colmeia/core/src/shared-business-rules/bot/new-bot-action";
import { EAdditionalBotObjects, TReferencedObject } from "@colmeia/core/src/shared-business-rules/graph-transaction/toolbar/config-toolbar.types";
import { ITransactionServer, initBotTransaction } from '@colmeia/core/src/shared-business-rules/knowledge-base/bot-transaction/bot-transaction';
import { ENonSerializableObjectType } from '@colmeia/core/src/shared-business-rules/non-serializable-id/non-serializable-id-interfaces';
import { BotTransactionBaseDataComponent } from 'app/components/dashboard/ai-pages/bot-transaction-edit/bot-transaction-edit.component';
import { BotTransactionBaseDataComponentDiagramDialogHandler } from 'app/components/dashboard/ai-pages/bot-transaction-edit/bot-transaction-edit.handler';
import { EGraphElementInfoBotHandlerTabs } from "app/components/dashboard/ai-pages/bpm-graph/bpm-graph-element-info/bot-graph-element-info-bot/bot-graph-element-info-bot.handler";
import { BpmGraphElementInfoHandler, EBpmGraphElementInfoMode, IBpmGraphElementInfoClientCallback, IBpmGraphElementInfoEntity, IBpmGraphElementInfoParameters } from "app/components/dashboard/ai-pages/bpm-graph/bpm-graph-element-info/bpm-graph-element-info/bpm-graph-element-info.handler";
import { IUpserterComponentBehavior, IUpserterComponentReturn, IUpserterNSComponentInfo } from './bpm-rules.types';
import { BpmGraphElementInfoComponent } from 'app/components/dashboard/ai-pages/bpm-graph/bpm-graph-element-info/bpm-graph-element-info/bpm-graph-element-info.component';


@Injectable({
    providedIn: 'root'
})
export class BpmRulesBotDataService {

    constructor() { }

    getComponentAndHandler(info: IUpserterNSComponentInfo): (info: IUpserterNSComponentInfo) => IUpserterComponentReturn {
        if (
            info.type === ENonSerializableObjectType.contentGenerator
            && info.iconClicked?.type === ENodeAssetIcons.edit
        ) {
            return (info: IUpserterNSComponentInfo) => (
                {
                    behavior: IUpserterComponentBehavior.Dialog,
                    component: BotTransactionBaseDataComponent,
                    handler: new BotTransactionBaseDataComponentDiagramDialogHandler({
                        transaction: info.entityInfo.entity as ITransactionServer,
                        clientCallback: undefined,
                    })
                }
            );
        }

        return this.typeToComponentAndHandler[info.type];
    }

    bpmInfoComponentHandlerFactory(workElement: IBpmGraphElementInfoEntity, override?: Partial<IBpmGraphElementInfoParameters["workElement"]>): IUpserterComponentReturn {
        const handler = BpmGraphElementInfoHandler.factory({
            workElement: { ...workElement, ...override },
            clientCallback: <IBpmGraphElementInfoClientCallback>{
                close: () => handler.getDialogRef().close(),
            },
        });
        console.log({bpmInfoComponentHandlerFactory: handler, workElement, override});
        
        return {
            component: BpmGraphElementInfoComponent,
            behavior: IUpserterComponentBehavior.Sidebar,
            handler,
        };
    }

    private typeToComponentAndHandler: { [key in TReferencedObject]?: (info: IUpserterNSComponentInfo) => IUpserterComponentReturn } = {
        [ENextGenBotElementType.root]: (info: IUpserterNSComponentInfo) => this.bpmInfoComponentHandlerFactory(info.entityInfo),
        [ENextGenBotElementType.menuContainer]: (info: IUpserterNSComponentInfo) => this.bpmInfoComponentHandlerFactory(info.entityInfo),
        [ENextGenBotElementType.botMenuItem]: (info: IUpserterNSComponentInfo) => this.bpmInfoComponentHandlerFactory(info.entityInfo),
        [EBotActionType.goHuman]: (info: IUpserterNSComponentInfo) => (
            this.bpmInfoComponentHandlerFactory(
                info.entityInfo,
                { tabToOpen: EGraphElementInfoBotHandlerTabs.actionConfiguration, mode: EBpmGraphElementInfoMode.Edit, preSelectedAction: EBotActionType.goHuman }
            )
        ),
        [EBotActionType.contentGenerator]: (info: IUpserterNSComponentInfo) => (
            this.bpmInfoComponentHandlerFactory(
                info.entityInfo,
                { tabToOpen: EGraphElementInfoBotHandlerTabs.actionConfiguration, mode: EBpmGraphElementInfoMode.Edit, preSelectedAction: EBotActionType.contentGenerator }
            )
        ),
        [EBotContentEvent.preContent]: (info: IUpserterNSComponentInfo) => (
            this.bpmInfoComponentHandlerFactory(
                info.entityInfo,
                { tabToOpen: EGraphElementInfoBotHandlerTabs.preContent, mode: EBpmGraphElementInfoMode.Edit }
            )
        ),
        [EBotContentEvent.posContent]: (info: IUpserterNSComponentInfo) => (
            this.bpmInfoComponentHandlerFactory(
                info.entityInfo,
                { tabToOpen: EGraphElementInfoBotHandlerTabs.posContent, mode: EBpmGraphElementInfoMode.Edit }
            )
        ),
        [EAdditionalBotObjects.menuItemConditionalDisplay]: (info: IUpserterNSComponentInfo) => (
            this.bpmInfoComponentHandlerFactory(
                info.entityInfo,
                { tabToOpen: EGraphElementInfoBotHandlerTabs.conditionalDisplay, mode: EBpmGraphElementInfoMode.Edit }
            )
        ),
        [EBotActionType.LGPD]: (info: IUpserterNSComponentInfo) => (
            this.bpmInfoComponentHandlerFactory(
                info.entityInfo,
                { tabToOpen: EGraphElementInfoBotHandlerTabs.actionConfiguration, mode: EBpmGraphElementInfoMode.Edit, preSelectedAction: EBotActionType.LGPD }
            )
        ),
        [ENonSerializableObjectType.contentGenerator]: (info: IUpserterNSComponentInfo) => (
            {
                behavior: IUpserterComponentBehavior.Dialog,
                component: BotTransactionBaseDataComponent,
                handler: new BotTransactionBaseDataComponentDiagramDialogHandler({
                    transaction: initBotTransaction() as ITransactionServer,
                    clientCallback: undefined,
                })
            }
        ),
    };

}
