import { ChangeDetectorRef, Component, HostListener, Input, OnDestroy, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { ActivatedRoute } from "@angular/router";
import { constant, TGlobalUID } from "@colmeia/core/src/business/constant";
import { Group } from "@colmeia/core/src/business/group";
import { allAtendeeStatusDB } from "@colmeia/core/src/comm-interfaces/service-group-interfaces";
import { UberCache } from "@colmeia/core/src/persistency/uber-cache";
import { fixGenealogy, groupToGenealogyEntry, isValidGenealogy } from "@colmeia/core/src/rules/filters";
import { AttendanceService } from "app/services/attendance.service";
import { NavigatorServices } from "app/services/controllers-services/navigator/navigator.service";
import { SocialNetworkDatabaseService } from "app/services/social-network-database.service";
import { Observable } from "rxjs";
import { ChatBackboneHandler, EChatBackboneViewType } from "../../handlers/chat-backbone.handler";
import { BreadcrumbItem, BreadcrumbItemList, IBreadcrumbDescriptor } from "../../model/breadcrumb.model";
import { ChatBackboneModel, TChatBackboneModelArray } from "../../model/chat-backbone.model";
import { getBreadCrumbFromGenealogy } from "../../model/client-filter";
import { routeID } from "../../model/routes/route-constants";
import { HideLeftBarSignal } from "../../model/signal/hide-left-bar-signal";
import { ILeftBarHideListener, IListenerSubscription, IRightBarHideListener } from "../../model/signal/ps-interfaces";
import { SubscriptionSignal } from "../../model/signal/subscription-signal";
import { RoutingService } from "../../services/routing.service";
import { SessionService } from "../../services/session.service";
import { SignalListenerService } from "../../services/signal/signal-listener";
import { AttendanceEvent } from './../../model/attendence.model';
import { MenuDashboardService } from "app/components/menu-dashboard/menu-dashboard.service";
import { EmbeddedChatService } from "app/services/embedded-chat.service";
import { DashBoardService } from "app/services/dashboard/dashboard.service";
import { ModuleNavService } from "app/components/navigation/module-nav.service";
import { EConversationFinishingActions } from "@colmeia/core/src/shared-business-rules/embedded/embedded-req-res";

/*@TODO WARNING ver se isso funciona*/
export enum EChatViewStyle {
    topic = 1,
    forum,
    chat,
};

export interface ChatDisplayOptions {
    viewStyle: EChatViewStyle,
};

enum EMobileSideBarActive {
    None,
    Right,
    Left
}

/**
 * Group chat page
 */
@Component({
    // changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'app-chat-backbone',
    templateUrl: './chat-backbone.component.html',
    styleUrls: ['./chat-backbone.component.scss'],
    host: {
        ['[class.is-widgets-api-chat]']: 'isWidgetsApi',
    }
})
export class ChatBackboneComponent implements OnInit, OnDestroy, IListenerSubscription, ILeftBarHideListener, IRightBarHideListener {
    private hideLeftBar: boolean = window.matchMedia('(max-width: 960px)').matches;
    private hideRightBar: boolean = false;

    private _handler: ChatBackboneHandler;
    private sidebarSelected: EMobileSideBarActive = EMobileSideBarActive.None;
    private isChatBackboneLoadedByRouter: boolean;
    public breadCrumbDescriptor: IBreadcrumbDescriptor;

    public lastAttendanceEvent: Observable<AttendanceEvent>;
    public get currentGroupID(): string { return this.sessionSvc.getSelectedGroupID() };

    public isRightSidebarInitialized: boolean = false;

    constructor(
        private cdr: ChangeDetectorRef,
        private sessionSvc: SessionService,
        private routingService: RoutingService,
        private listener: SignalListenerService,
        private snDatabaseSvc: SocialNetworkDatabaseService,
        private attendanceSvc: AttendanceService,
        private route: ActivatedRoute,
        private navigatorSvc: NavigatorServices,
        private dialogSvc: MatDialog,
        private snackSvc: MatSnackBar,
        private menuDashboardSvc: MenuDashboardService,
        private embedded: EmbeddedChatService,
        private dashboardSvc: DashBoardService,
        private moduleNavService: ModuleNavService,

    ) { }

    //#region ngOnInit

    public ngOnInit() {
        this.initBackboneHandler();

        this.listener.listenSubscriptionChanges(this);
        this.listener.listenToHideLeftBar(this);
        this.listener.listenToHideRightBar(this);

        if (!this.sessionSvc.isEmbeddedChat()) {
            this.lastAttendanceEvent = this.attendanceSvc.events$;

            this.navigatorSvc.checkValidGroupNavigation(this.route);
            this.moduleNavService.openChat();
        } else {
            this.initExitingPrevention();
        }
    }

    public ngOnDestroy(): void {
        this.listener.destroySubscriptions(this);
        this.destroyExitingPrevention();
    };

    onRightSidebarToggle(isOpened: boolean): void {
        this.isRightSidebarInitialized = isOpened;
    }

    //#endregion

    //#region Backbone

    @Input()
    set handler(value: ChatBackboneHandler) {
        this._handler = value
    }
    get handler(): ChatBackboneHandler {
        return this._handler;
    }

    public changeSubscriptionSignCallback(subsSignal: SubscriptionSignal): void {
        this.sidebarSelected = EMobileSideBarActive.None;
        if (this.isChatBackboneLoadedByRouter) {

            if (this.getActiveBackboneModel()) {
                const groupType = this.getActiveBackboneModel().getGroupType()
                const viewType = groupType.is(constant.groupType.functional.featureCreator)
                    ? EChatBackboneViewType.FeatureCreator
                    : EChatBackboneViewType.Chat
                this.handler.setViewType(viewType)
            }
        }
        this.resetBreadCrumb();
        this.cdr.markForCheck();
        console.log({CANSHOWRIGHTSIDE: this.canShowRightSideBar()})
        console.log({ANYCHAT: this.handler.isInAnyChat(), SOMETHING: this.handler.isInChat()})
    };

    getActiveBackboneModel(): ChatBackboneModel {
        if (!ChatBackboneModel.getChatBackboneModelArray(true) || ChatBackboneModel.getChatBackboneModelArray(true).length == 0) {
            return;
        }
        return ChatBackboneModel.getActiveBackBoneModel();
    }

    public getActiveGroupID(): TGlobalUID { return this.sessionSvc.getSelectedGroupID() };

    public getAllChatBackboneModels(): TChatBackboneModelArray {
        return ChatBackboneModel.getChatBackboneModelArray(true);
    };

    //
    // Routing functions
    //
    public goToGroupChat(group: Group): void {
        this.routingService.navigateToId(routeID.groups.chat, group.getGroupID());
    }

    public goToGroupHome(group?: Group): void {
        this.routingService.navigateToId(
            routeID.groups.home,
            group
                ? group.getGroupID()
                : this.sessionSvc.getSelectedGroupID());
    }

    public goToGroupCreate(): void { }

    public goBack(): void { };

    get isWidgetsApi(): boolean {
        return this.embedded.isWidgetsApi
    }

    public isFinishingEmbeddedConversation(): boolean {
        return this.embedded.isFinishingConversation;
    }

    // TODO: Remove deadcode
    public isAttending(): boolean {
        return allAtendeeStatusDB[this.attendanceSvc.currentStatus]?.showCharOrderSelector && this.attendanceSvc.hasAnyConversation();
    }

    isHindingLeftBar(): boolean {
        return this.hideLeftBar;
    }

    onLeftBarToggle(_: HideLeftBarSignal): void {
        this.hideLeftBar = !this.hideLeftBar;
        this.cdr.markForCheck();
    }


    onRightBarToggle(): void {
        this.hideRightBar = !this.hideRightBar
        this.cdr.markForCheck()
    }


    private screenIsBiggerThanMobile(): boolean {
        return window.innerWidth >= 600;
    }

    toggleRightBarIfIsNotMobile() {
        if (this.screenIsBiggerThanMobile()) return this.onRightBarToggle()
    }

    isNotHidingLeftBar(): boolean {
        return !this.hideLeftBar;
    }

    isNotHidingRightBar(): boolean {
        return !this.hideRightBar;
    }

    private initBackboneHandler(): void {
        if (!this.handler) {
            this.handler = ChatBackboneHandler.new(
                {
                    clientCallback: this,
                    clickedInteraction: null,
                    rootInteraction: null,
                    viewType: EChatBackboneViewType.Chat
                }
            );
            this.isChatBackboneLoadedByRouter = true;
        }
    }

    //#endregion

    //#region onEvent Listeners

    private onBeforeUnloadListener(event): string | null | undefined {
        const customerExitControl = this.embedded.getCustomerExitControl();

        if (customerExitControl?.keepConv || customerExitControl?.dontAskCloseConv) {
            return;
        }

        this.embedded.finishConversation(EConversationFinishingActions.exitPage);

        return null;
    }

    // set & unset - window Event Listeners
    private initExitingPrevention(): void {
        window.addEventListener(
            "beforeunload",
            this.onBeforeUnloadListener.bind(this),
            { capture: true }
        );
    }
    private destroyExitingPrevention(): void {
        window.removeEventListener("beforeunload", this.onBeforeUnloadListener);
    }

    @HostListener('keydown', ['$event'])
    handleKeyDown(event: KeyboardEvent): void {
        // if (event.key === 'Escape') {
        //     this.onRightBarToggle()
        // }
    }

    //#endregion

    //#region Sidebar

    canShowNav() {
        return this.handler.isInAnyChat() && !this.isEmbedded;
    }

    canShowBreadCrumb(): boolean {
        return false // !this.sessionSvc.isEmbeddedChat() && !this.handler.isInFeatureVisualization();
    }

    canShowRightSideBarOnMobile(): boolean {
        return this.sidebarSelected === EMobileSideBarActive.Right
    }

    canShowRightSideBar(): boolean {
        return this.handler.isInAnyChat() && !this.isEmbedded && this.attendanceSvc?.isCurrentAttendanceValid()
    }

    get isEmbedded(): boolean {
        return this.sessionSvc.isEmbeddedChat();
    }

    closeSidebars(): void {
        this.sidebarSelected = EMobileSideBarActive.None;
    }

    openLeftSideBar(): void {
        this.openCloseSideBar(EMobileSideBarActive.Left);
    }

    openRightSideBar(): void {
        this.openCloseSideBar(EMobileSideBarActive.Right);
    }

    openCloseSideBar(mobileSide: EMobileSideBarActive): void {
        // closes mobile bar if already selected
        if (this.sidebarSelected === mobileSide) {
            this.closeSidebars();
            return;
        }

        // opens mobile bar
        this.sidebarSelected = mobileSide;
    }

    getGroupTypeID(): TGlobalUID {
        if (!ChatBackboneModel.getChatBackboneModelArray(true) || ChatBackboneModel.getChatBackboneModelArray(true).length == 0) {
            return;
        }
        return ChatBackboneModel.getActiveBackBoneModel()
            .getGroupType()
            .getGroupTypeID();
    }

    getChannelConstant(): TGlobalUID {
        return constant.groupType.standard.channel;
    }

    getGroupName(): string {
        if (!ChatBackboneModel.getChatBackboneModelArray(true) || ChatBackboneModel.getChatBackboneModelArray(true).length == 0) {
            return;
        }
        return ChatBackboneModel.getActiveBackBoneModel()
            .getGroup()
            .getName();
    }

    //#endregion

    //#region Breadcrumb

    newBreadCrumbDescriptor(): IBreadcrumbDescriptor {
        return {
            items: this.getBreadcrumbList(),
            breadCrumbClicked: (item: BreadcrumbItem) => this.routingService.goToGroupHome(item.primaryId),
        }
    }

    private resetBreadCrumb(): void {
        this.breadCrumbDescriptor = this.newBreadCrumbDescriptor();
    }

    private getBreadcrumbList(): BreadcrumbItemList {
        const currentGroup = this.sessionSvc.getSelectedGroup();

        if (!isValidGenealogy(currentGroup.getPlainGroupGenealogy()))
            fixGenealogy(currentGroup.getPrimaryID(), currentGroup.getPlainGroupGenealogy(), this.snDatabaseSvc.getGroupDB())
                ;


        return [
            ...getBreadCrumbFromGenealogy([groupToGenealogyEntry(currentGroup)].concat(currentGroup.getPlainGroupGenealogy()))
        ].reverse();
    }

    get isCopilotWebchat() {
        return this.embedded.isCopilot;
    }

    //#endregion

}
