import { suggestions } from "../tools/type-utils";

export type ILogCharacteristicHash = { [name: string]: ILogCharacteristic }

export interface ILogCharacteristic {
    severity: number;
    stackName: string;
    qualifier: string;
}


export type ILogUnignoredClasses = {
    [key in string]?: boolean;
}
//

//https://cloud.google.com/logging/docs/reference/v2/rest/v2/LogEntry
export const allSeverityRegistry = suggestions<ILogCharacteristicHash>()({ // deepSuggestions
    default: { stackName: 'DEFAULT', qualifier: 'DEFAULT', severity: 0 },
    debug: { stackName: 'DEFAULT', qualifier: 'DEBUG', severity: 0 },


    lowLevelStats: { stackName: 'DEBUG', qualifier: 'LOWSTATS', severity: 100 },

    info: { stackName: 'INFO', qualifier: 'INFO', severity: 200 },
    stats: { stackName: 'INFO', qualifier: 'STATS', severity: 200 },
    billing: { stackName: 'INFO', qualifier: 'BILLEDEVENT', severity: 200 },
    statsFail: { stackName: 'INFO', qualifier: 'STATSFAIL', severity: 200 },
    secDenied: { stackName: 'INFO', qualifier: 'SECDENIED', severity: 200 },
    businessDenied: { stackName: 'INFO', qualifier: 'BUSDENIED', severity: 200 },

    warning: { stackName: 'WARNING', qualifier: 'WARNING', severity: 300 },
    security: { stackName: 'WARNING', qualifier: 'SECURITY', severity: 300 },
    bug: { stackName: 'WARNING', qualifier: 'BUG', severity: 300 },

    error: { stackName: 'ERROR', qualifier: 'ERROR', severity: 400 },
    botProgramError: { stackName: 'ERROR', qualifier: 'BOT-PROG-ERROR', severity: 400 },
    errorCycle: { stackName: 'ERROR', qualifier: 'ERRORCYCLE', severity: 400 },
    customerFaceError: { stackName: 'ERROR', qualifier: 'CUSTOMER', severity: 400 },
    errorUnhandledClientException: { stackName: 'ERROR', qualifier: 'ERROR_CLIENT', severity: 400 },

    critical: { stackName: 'CRITICAL', qualifier: 'CRITICAL', severity: 500 },
    syncDW: { stackName: 'CRITICAL', qualifier: 'SYNCHDW', severity: 500 },
    syncDS: { stackName: 'CRITICAL', qualifier: 'SYNCHDS', severity: 500 },
} as const)

export const defaultUnignoredClasses: ILogUnignoredClasses = {
    Spanner: false,
    DSCache: false,
    Query: false,
    SendKey: false,
    PUBSUB: false,
    Tracker: false,
    Socket: false,
    Emit: false,
    Errors: false,
    genericError: false,
    genericInfo: false,
    Security: false,
    Lock: false,
    Controlers: false,
    DatabaseExecution: false,
    saveDS: false,
    Cycle: false,
    MarketingConversionAPI: false,
    InternalCycle: false,
    batchCycle: false,
    Stream: false,
    FUNCTION: false,
    userFunction: false,
    REDIS: false,
    BACK: false,
    CONSUMER: false,
    CORPORATE_SEARCH: false,
    analytics: false,
    DW: false,
    InfraEvent: false,
    API: false,
    externalAPI: false,
    downstreamAPI: false,
    colmeiaInternalServiceAPI: false,
    cloudStorage: false,
    fbAndInstaMessenger: false,
    billingCosts: false,
    memoryLeak: false,
    authAPI: false,
    proxyAPI: false,
    client: false,
    PushNot: false,
    Server: false,
    SocialCC: false,
    generalInfo: false,
    INC360: false,
    Delivery: false,
    externalIntegration: false,
    connections: false,
    massComm: false,
    bot: false,
    job: false,
    house: false,
    spannerLog: false,
    colmeiaService: false,
    omniSense: false,
    environmentMigrationInfo: false,
    unhandledException: false,
    GooglePubSub: false,
    CampaignInteligence: false,
    Hook: false,
    BI: false,
    warehouseDataExtractor: false,
    Scylla: false,
    Batch: false,
    shutdownServer: false,
    scheduler: false,
    ToBeModified: false,
    LONGRUN: false,
    crmTicket: false,
    WAF: false,
}
