import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Serializable } from '@colmeia/core/src/business/serializable';
import { TArrayID } from "@colmeia/core/src/core-constants/types";
import {
    IBatchNonSerializableRequest,
    IBatchNonSerializableResponse
} from "@colmeia/core/src/request-interfaces/lookup-ns-request";
import { apiRequestType, EBotTransaction } from '@colmeia/core/src/request-interfaces/message-types';
import { IGetCanonicalDBRequest } from "@colmeia/core/src/request-interfaces/request-interfaces";
import { IGetCanonicalDBResponse } from "@colmeia/core/src/request-interfaces/response-interfaces";
import { ENextGenBotElementType, IConfigureCanonical } from '@colmeia/core/src/shared-business-rules/bot/bot-model';
import { ILocalCanonical, IServerLocalCanonical, IServerLocalCanonicalArray, TCanonicalDB } from '@colmeia/core/src/shared-business-rules/canonical-model/local-canonical';
import { IGetLocalCanonicalRequest, IGetLocalCanonicalResponse, IGetLocalCanonicalsWithGlobalRequest, IGetLocalCanonicalsWithGlobalResponse, ISaveLocalCanonicalRequest, ISaveLocalCanonicalResponse } from '@colmeia/core/src/shared-business-rules/canonical-model/local-canonocal-req-resp';
import { metadataNamesTranslations } from '@colmeia/core/src/shared-business-rules/const-text/views/metadata';
import { ITransactionServer } from '@colmeia/core/src/shared-business-rules/knowledge-base/bot-transaction/bot-transaction';
import { EMetadataNames } from '@colmeia/core/src/shared-business-rules/metadata/metadata-db';
import { IEditorVariable, TGlobalToFieldMap, getAllLocalCanonicalIds, mapGlobalToField } from '@colmeia/core/src/shared-business-rules/metadata/metadata-utils';
import { toMultipleCursor } from '@colmeia/core/src/shared-business-rules/non-serializable-id/non-serializable-functions';
import { ENonSerializableObjectType, TNonSerializableArray } from '@colmeia/core/src/shared-business-rules/non-serializable-id/non-serializable-id-interfaces';
import { isValidRef, keys } from '@colmeia/core/src/tools/utility';
import { routeList } from 'app/model/routes/route-constants';
import { ClientInfraResponse, IInfraParameters } from "../model/client-infra-comm";
import { BotTransactionService } from './bot-transaction.service';
import { DashboardBotsService } from './dashboard/dashboard-bots.service';
import { GenericNonSerializableService } from './generic-ns.service';
import { LookupService } from './lookup.service';
import { RequestBuilderServices } from './request-builder.services';
import { RoutingService } from './routing.service';
import { ServerCommunicationService } from './server-communication.service';
import { SessionService } from './session.service';
import { SpinType } from './screen-spinner.service';
import { TSchemaPropertyArray } from '@colmeia/core/src/general-form/general-form-interface';
import { I } from 'ts-toolbelt';
import { CanonicalPickerEditCanonicalConfigHandler, ICanonicalPickerSlave } from 'app/components/canonical-picker/canonical-picker-edit-canonical-config/canonical-picker-edit-canonical-config.handler';



@Injectable({
    providedIn: 'root'
})
export class CanonicalService extends GenericNonSerializableService {

    private mapIdCanonicalToConfig: Map<string, IConfigureCanonical> = new Map();
    private canonicals: IServerLocalCanonical[];
    private canonicalVariables: IEditorVariable[];
    private mapGlobalCanonicalTranslation: Map<EMetadataNames, string> = new Map();

    public mapCanonicalIdToHandler: Map<string, CanonicalPickerEditCanonicalConfigHandler> = new Map();

    constructor(
        api: ServerCommunicationService,
        rbs: RequestBuilderServices,
        session: SessionService,
        routeSvc: RoutingService,
        private dashboardBotsService: DashboardBotsService,
        private lookupSvc: LookupService,
        private transactionSvc: BotTransactionService,
        private activatedRoute: ActivatedRoute
    ) {
        super(
            routeList.dashboard.children.smartFlow.path,
            routeList.dashboard.children.smartFlow.children.canonical,
            { api, rbs, session, routeSvc },
        );
        this.initGlobalCanonicalTranslations();
    }

    private initGlobalCanonicalTranslations(): void {
        keys(metadataNamesTranslations).map(globalCanonical => this.mapGlobalCanonicalTranslation.set(globalCanonical, Serializable.getTranslation(metadataNamesTranslations[globalCanonical])))
    }

    public async getLocalByGlobalCanonicals(globalCanonicals: EMetadataNames[], cursor?: string): Promise<IServerLocalCanonicalArray> {
        const request: IGetLocalCanonicalsWithGlobalRequest = {
            ...this.baseRequest(undefined),
            requestType: EBotTransaction.getLocalCanonicalByGlobal,
            cursor,
            globalCanonicals,
        };

        const response: IGetLocalCanonicalsWithGlobalResponse = await this.send<IGetLocalCanonicalsWithGlobalResponse>(request);
        return isValidRef(response) ? response.canonicals : [];
    }

    async getCanonicalsById(idsNS: TArrayID): Promise<TNonSerializableArray> {
        const infra: IInfraParameters = this.rbs.getContextNoCallBackNoSpinnningParameters();
        const req: IBatchNonSerializableRequest = {
            ...this.rbs.secureBasicRequest(apiRequestType.nonSerializable.getGeneric),
            idsNS,
        };
        const infraRes: ClientInfraResponse = await this.api.managedRequest(infra, req);
        const response: IBatchNonSerializableResponse = <IBatchNonSerializableResponse>infraRes.response;
        return response.nonSerializables;
    }

    getCanonicalsRequest(cursor: string = null, additional?: Partial<IGetLocalCanonicalRequest>): IGetLocalCanonicalRequest {
        const request: IGetLocalCanonicalRequest = {
            ...this.baseRequest(apiRequestType.botTransaction.getLocalCanonical),
            multipleCursor: toMultipleCursor(cursor),
            ...isValidRef(additional) ? additional : {},
        };
        return request;
    }


    private getGlobalCanonicalTranslation(globalCanonical: EMetadataNames): string {
        return this.mapGlobalCanonicalTranslation.get(globalCanonical);
    }

    async getCanonicals(cursor: string = null, additional?: Partial<IGetLocalCanonicalRequest>): Promise<IGetLocalCanonicalResponse> {
        const request = this.getCanonicalsRequest(cursor, { doNotComputeNSSecure: true, ...additional });
        return this.send<IGetLocalCanonicalResponse>(request);
    }

    async saveCanonical(localCanonical: ILocalCanonical): Promise<IServerLocalCanonical> {
        localCanonical.nsType = ENonSerializableObjectType.canonical
        const request: ISaveLocalCanonicalRequest = {
            ...this.baseRequest(apiRequestType.botTransaction.saveLocalCanonical),
            ns: localCanonical
        }

        const response: ISaveLocalCanonicalResponse = await this.send(request, SpinType.fullScreen)

        return response?.ns;
    }

    public isOnTransaction(): boolean {
        return this.routeSvc.getCurrentPath().split('/').some((token: string) => token === routeList.dashboard.children.ai.children.botTransactions.path);
    }

    public isOnRoot(): boolean {
        return this.dashboardBotsService.lastNavigatorItem.botLevel === ENextGenBotElementType.root;
    }


    public async getNS(id: string): Promise<ITransactionServer> {
        return this.lookupSvc.getBatchNonSerializables([id])[0];
    }

    public async getBot(id: string): Promise<ITransactionServer> {
        return this.lookupSvc.getBatchNonSerializables([id])[id];
    }

    public setCanonicals(canonicals: IServerLocalCanonical[], canonicalsConfig?: IConfigureCanonical[]): void {
        // canonicalsConfig = canonicalsConfig.map(config => ({ ...config, isSafe: false }));
        this.canonicals = canonicals;
        const map: Map<string, IConfigureCanonical> = new Map();

        if (isValidRef(canonicalsConfig)) {
            for (const config of (canonicalsConfig))
                map.set(config.idCanonical, config)
                    ;
        }

        this.mapIdCanonicalToConfig = map;
        this.canonicalVariables = this.canonicals.map(entity => ({ idProperty: entity.idNS, variable: undefined }));
    }

    public isCanonicalSafeOnConfig(canonical: IServerLocalCanonical): boolean {
        return this.mapIdCanonicalToConfig.has(canonical.idNS) && this.mapIdCanonicalToConfig.get(canonical.idNS).isSafe;
    }

    public getCanonicalsFromPicker(): IServerLocalCanonical[] {
        return this.canonicals;
    }

    public getCanonicalsVariables(): IEditorVariable[] {
        return this.canonicalVariables;
    }

    public getCanonicalsConfig(): Map<string, IConfigureCanonical> {
        return this.mapIdCanonicalToConfig;
    }


    async getGlobalCanonical(localIds: string[]): Promise<TCanonicalDB> {
        const infra = this.rbs.getContextNoCallBackNoSpinnningParameters();
        const request: IGetCanonicalDBRequest = {
            ...this.rbs.secureBasicRequest(apiRequestType.canonical.getGlobalCanonical),
            localIds
        };
        const infraRes = await this.api.managedRequest(infra, request);
        if (infraRes.executionOK) {
            const response: IGetCanonicalDBResponse = <IGetCanonicalDBResponse>infraRes.response;
            return response.database;
        }
        return null;
    }

    async mapGlobalToField(schema: TSchemaPropertyArray): Promise<TGlobalToFieldMap> {
        const allLocalIds = getAllLocalCanonicalIds(schema);

        const localDB = await this.getGlobalCanonical(allLocalIds);

        return mapGlobalToField(schema, localDB);
    }
}
