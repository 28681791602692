<div class="message-box message-box--padtop" [ngStyle]="getMessageContentStyles()" [ngClass]="{
    'message-box--padbottom': isRootInteraction(),
    'message-box__mtop':      canHaveMarginTop(),
    'mw400px': isPurchaseConfirmation,
    'message-box--feature': canApplyMarginInMsgFeature()
  }">
  <!-- <pre>{{ {id: getInteraction()?.getPrimaryID(), getThreadInfo: getInteraction()?.getThreadInfo()} |json}}</pre> -->

  <div class="message-instance" [class.is-embedded]="isEmbedded" [class.message-instance--editing]="isMsgBeingEdited()">
    <div class="message-instance__container" [ngClass]="{
        'align-right': isMsgShownRight() && canShowMessageInstanceDefault(),
        'has-more-options': canShowMoreOptions(),
        'message-instance__container--full-width': hasFullWidth()
      }">
      <app-gps-message *ngIf="canShowGpsMessage()" [handler]="getGpsMsgHandler()"></app-gps-message>
      <cm-notification-message [chatBackboneInputData]="chatBackboneInputData"
        [notificationInteraction]="notificationInteraction" *ngIf="isNotificationRequestMessage()">
      </cm-notification-message>
      <app-wallet-message [interaction]="getInteraction()" *ngIf="isWallet"></app-wallet-message>
      <app-wallet-charge-message [interaction]="walletInteraction" *ngIf="isCharge"></app-wallet-charge-message>
      <app-wallet-transfer-message [interaction]="walletInteraction" *ngIf="isTransfer"></app-wallet-transfer-message>
      <cm-notification-feedback-message [feedbackInteraction]="feedbackInteraction"
        *ngIf="isFeedbackNotificationMessage()"></cm-notification-feedback-message>
      <app-success-purchase-message *ngIf="isSuccessPurchase && isLastPurchaseFeedback"
        [interaction]="feedbackInteraction"></app-success-purchase-message>
      <app-failed-purchase-component *ngIf="isFailPurchase && isLastPurchaseFeedback"
        [interaction]="feedbackInteraction"></app-failed-purchase-component>
      <app-confirm-purchase-message *ngIf="isPurchaseConfirmation && mustShowPurchaseConfirmation"
        [backbone]="messageHandler.getChatBackbone()" [interaction]="purchaseConfirmationInteraction">
      </app-confirm-purchase-message>
      <app-feature-carrier *ngIf="isFeatureCarries" [handler]="featureCarrierHandler">
      </app-feature-carrier>
      <app-timed-message *ngIf="isWrapperInteraction" [handler]="getTimedMessageHandler()"></app-timed-message>
      <app-group-service *ngIf="isServiceGroupMessage" [groupServiceParameter]="groupServiceParameter">
        <ng-container *ngTemplateOutlet="messageFooter"></ng-container>
      </app-group-service>
      <app-finish-service-message *ngIf="isFinishServiceMessage" [interaction]="finishServiceCallInteraction">
      </app-finish-service-message>
      <div *ngIf="isLocationInteraction() && locationMapsHandler" class="map-box">
        <app-maps [handler]="locationMapsHandler"></app-maps>
      </div>
      <app-menu-print *ngIf="isMenu" [interaction]="$menuInteraction(getInteraction())"></app-menu-print>

      <div *ngIf="canShowMessageInstanceDefault()" class="default-message">

        <div class="more-options" *ngIf="isMsgShownRight() && !isMessageFromBot() && isWebChatChannel()">
          <app-message-side-options [handler]="getMoreOptionsHandler()"
            [class]="conditionalMoreOptionsClassByInteractionType" />
        </div>

        <div class="single-message" [ngClass]="{
            'single-message__mine--right': isMsgShownRight()
          }">
          <!-- Block single message -->
          <div class="single-message__content" [ngClass]="!canShowAvatar() ? 'single-message--without-avatar' : ''">
            <div class="message-block" [ngClass]="isMyMessage() ? '--my-message' : ''">
              <div class="message-block__container">

                <!-- Message content -->
                <div class="message-block__content flex-container">
                  <div class="message-block__body">
                    <div class="flex-container-col">
                      <!-- <div class="user-info-container">
                        <span class="single-message--avatar" *ngIf="canPutAvatarInHTML()"
                          [ngClass]="!canShowAvatar() ? 'single-message--avatar--hidden' : ''">
                          <hexagon [hexagonHandler]="getHexagonHandler()"></hexagon>
                        </span>
                        <span class="avatar--name" *ngIf="canShowAvatarName()">
                          <span>{{ getFormatedMessageTitle() }}</span>
                        </span>
                      </div> -->
                      <div class="flex-container">
                        <div class="flex-container-col">

                          <!-- message status(bomb, edit) -->
                          <div class="message-status__container" [matTooltip]="generatedByBotEventName"
                            [ngClass]="extraStatusClasses">

                            <div class="user-info-container" *ngIf="generatedByBotEventName">
                              <span class="mat-bot-icon" class="item-container ic-m-icon">
                                <mat-icon svgIcon="bot" style="vertical-align: -5px;"></mat-icon>
                              </span>
                              <span class="avatar--name" class="item-container ic-m-icon">
                                <span>Bot colmeia</span>
                              </span>
                            </div>

                            <div class="user-info-container" *ngIf="!generatedByBotEventName">
                              <!-- <span class="single-message--avatar" *ngIf="canPutAvatarInHTML()"
                                [ngClass]="!canShowAvatar() ? 'single-message--avatar--hidden' : ''">
                                <hexagon [hexagonHandler]="getHexagonHandler()"></hexagon>
                                </span> AVATAR -->
                              <span class="mat-person-icon">
                                <mat-icon>person_outline</mat-icon>
                              </span>
                              <span class="avatar--name">
                                <span>{{ getFormatedMessageTitle() }}</span>
                              </span>
                            </div>

                            <!-- <div class="spacing-div"*ngIf="messageContent.classList.contains('message--mine')">
                                </div> -->

                            <div class="message-line header-line" [ngClass]="{'with-avatar': canPutAvatarInHTML()}">
                              <!-- <div [ngClass]="extraClasses" class="message-line header-line"> -->

                              <mat-icon class="mat-clock-icon">
                                access_time
                              </mat-icon>

                              <span class="message-date" [ngClass]="extraClasses">
                                {{ getReadableDate() }}
                              </span>

                            </div>

                            <div class="reply-react-container"
                              *ngIf="messageContent.classList.contains('message--others')"
                              [ngClass]="extraContainerReplyReactClasses">

                              <button *ngIf="!isBillable" #tagFaces class="material-icons action-icon emoji__adder"
                                (click)="showReactOptions()">
                                tag_faces
                              </button>

                              <button *ngIf="shouldShowReplyToMessage()" class="action-icon reply-icon-container"
                                (click)="onReplyMessage()" [disabled]="isReplyToMessageDisabled()"
                                matTooltip="Não é possível responder a atendimentos antigos"
                                [matTooltipDisabled]="!isReplyToMessageDisabled()">
                                <mat-icon class="reply-icon">reply</mat-icon>
                              </button>

                            </div>

                            <ng-container *ngIf="isMyMessage() && canShowReadConfirmation()"
                              [ngComponentOutlet]="_ReadConfirmation"
                              [ngComponentOutletInjector]="readConfirmationInjectorHandler"
                              style="align-self: center;" />
                          </div>

                          <!-- helper messages -->
                          <div class="helper-message__container">
                            <div *ngIf="canShowDueDate()" class="helper-message__item helper-message--golden">
                              <i class="material-icons">timer</i>
                              <p>Prazo: {{ getDueDate() | date:'medium' }}</p>
                            </div>
                            <div *ngIf="canShowFinishedTask()" class="helper-message__item helper-message--green">
                              <i class="material-icons">check_circle</i>
                              <p>Finalizada</p>
                            </div>
                            <div *ngIf="canShowExpired()" class="helper-message__item helper-message--red">
                              <i class="material-icons">error</i>
                              <p>Expirada</p>
                            </div>
                          </div>

                          <!-- message body -->

                          <div class="message-content" [ngClass]="extraClasses" #messageContent
                            [ngClass]="extraClasses">

                            <div class="status-icons" *ngIf="isFromDelivery || isToDelivery || generatedByBotEventName">

                              <img class="item-container" *ngIf="getProviderTypeIcon().length > 0"
                                [src]="getProviderTypeIcon()" alt="" />

                              <!-- <div *ngIf="generatedByBotEventName" class="item-container ic-m-icon" [matTooltip]="generatedByBotEventName">
                                <mat-icon class="mat-14">smart_toy</mat-icon>
                              </div> -->

                              <div *ngIf="createdAtTranslation" class="item-container ic-m-icon"
                                [matTooltip]="createdAtTranslation">
                                <mat-icon class="mat-14">info</mat-icon>
                              </div>

                            </div>

                            <!-- floating edit border -->
                            <div *ngIf="isEditedMessage()" [ngClass]="{
                                  'message--mine':     isEditedMessage() && isMyMessage(),
                                  'message--others':   isEditedMessage() && !isMyMessage(),
                                  'message--private':  isEditedMessage() && isMessageToMe(),
                                  'message--suicidal': isEditedMessage() && isSuicidal()
                                }">
                            </div>

                            <div class="message-content__upper">
                              <div class="message__body">

                                <!-- inner message reply -->
                                <div class="reply message-content message--private mbottom6"
                                  *ngIf="canShowReplyInnerMessage()" [ngClass]="extraReplyClasses">
                                  <div style="display: flex; flex-direction: row; align-items: center;">
                                    <mat-icon *ngIf="isParentMultimediaMessage()">attach_file</mat-icon>
                                    <span>{{ getParentInteractionMessage() }}</span>
                                  </div>
                                </div>

                                <div class="message-line">
                                  <p *ngIf="isValidMessage() && !hasCitations()" class="message-content__text">
                                    <ng-container *ngIf="layoutSettings">
                                      <ng-container [ngSwitch]="layoutSettings.type">
                                        <ng-container *ngSwitchCase="ELayoutElement.inputMask">
                                          <textarea readonly class="input-field-mask" cdkTextareaAutosize
                                            cdkAutosizeMinRows="1" [mask]="layoutSettings.formatMask"
                                            [leadZeroDateTime]="true" [hiddenInput]="true" [ngModel]="getMessage()">
                                          </textarea>
                                        </ng-container>
                                        <ng-container *ngSwitchCase="ELayoutElement.secret">
                                          {{ getMessageAsSecret() }}
                                        </ng-container>
                                        <div *ngSwitchDefault>{{ getMessage() }}</div>
                                      </ng-container>
                                    </ng-container>
                                    <ng-container *ngIf="!layoutSettings">
                                      <app-bbcode-viewer [text]="getMessage()"></app-bbcode-viewer>
                                    </ng-container>
                                  </p>

                                  <p *ngIf="hasCitations()" class="message-content__text">
                                    <app-bbcode-viewer [text]="getMessageWithCitations()"></app-bbcode-viewer>
                                  </p>
                                </div>

                                <!-- message text -->

                                <app-multimedia-preview *ngIf="multimediaPreviewHandler"
                                  [handler]="multimediaPreviewHandler"></app-multimedia-preview>

                                <!-- transcription -->
                                <div class="transcription" *ngIf="isTranscription()">
                                  <header class="transcription__header">
                                    <mat-icon class="mat-18">subtitles</mat-icon>&nbsp;
                                    Transcrição
                                  </header>
                                  <div class="transcription__text">
                                    {{ getTranscription() }}
                                  </div>
                                  <button *ngIf="canShowReadMoreTranscription()" mat-flat-button
                                    class="dense read-more-less" (click)="toggleTextToShow()">
                                    {{ readMoreLessText }}
                                  </button>
                                </div>

                              </div>

                              <!-- emojis display -->

                              <div class="buyable" *ngIf="isBillable">
                                <button class="buy-button" (click)="buy()">
                                  <i class="material-icons">
                                    shopping_cart
                                  </i>
                                  Comprar
                                </button>
                              </div>
                            </div>
                          </div>

                          <div class="citation-list" *ngIf="hasCitations()">
                            <app-cited-participants-list [handler]="getCitedParticipantsListHandler()">
                            </app-cited-participants-list>
                          </div>

                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <ng-container *ngIf="canShowFooter() && !showingFooterInNestedComponent()">
        <ng-container *ngTemplateOutlet="messageFooter"></ng-container>
      </ng-container>

    </div>

    <!-- <div class="more-options">
      <app-more-options-three-dots [class]="conditionalMoreOptionsClassByInteractionType" *ngIf="canShowMoreOptions()"
        [handler]="getMoreOptionsHandler()"></app-more-options-three-dots>
    </div> -->

  </div>

  <!-- we call again message-instance-iterator to get all children from current message instance -->
  <app-message-instance-iterator #msgIterator [iteratorAnimationState]="getAnimationState()"
    [chatBackboneInputData]="_chatBackboneInputData" [arrayMessageHandlers]="getInteractionHandlerArray()">
  </app-message-instance-iterator>
  <!--@TODO ^-->
</div>

<ng-template #messageFooter>
  <div class="message-footer">
    <!-- status icons -->
    <ng-container [ngComponentOutlet]="_ReactBarDisplay" [ngComponentOutletInjector]="reactBarInjectorHandler">
    </ng-container>
    <app-status-icons [handler]="messageStatusHandler" *ngIf="canShowStatusIcons()"
      [messageInstanceOptionsConfig]="messageInstanceOptionsConfig"></app-status-icons>

    <!-- <ng-container *ngIf="isMyMessage() && canShowReadConfirmation()" [ngComponentOutlet]="_ReadConfirmation"
      [ngComponentOutletInjector]="readConfirmationInjectorHandler"></ng-container> -->
  </div>
</ng-template>