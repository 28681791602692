import { Component, OnInit, Optional } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { EDelivery360Action } from '@colmeia/core/src/comm-interfaces/barrel-comm-interfaces';
import { ROUTES } from '@colmeia/core/src/core-constants/routes-shared';
import { DeliveryProvider } from '@colmeia/core/src/core-constants/types';
import { IMetaExchangeCodeForTokenAndGetWhatsappInfoResponse, IMetaWebhookRegisterResponse } from '@colmeia/core/src/shared-business-rules/channels/facebook-integration-req-resp.dto';
import { ENonSerializableObjectType } from '@colmeia/core/src/shared-business-rules/non-serializable-id/non-serializable-id-interfaces';
import { environmentBaseURL } from '@colmeia/core/src/shared-business-rules/production-deploy/prod-deploy-model';
import { IProviderPresentation, providersBySocialMedia } from '@colmeia/core/src/shared-business-rules/social-media/social-media.model';
import { IWhatsappBusinessAccountClient, IWhatsappBusinessAccountServer } from '@colmeia/core/src/shared-business-rules/waba/waba.model';
import { ECreditLineStatus, EWABACustomWebhookStatus, IAttachWabaCreditLineResponse, IRevokeWabaCreditLineResponse, IUnregisterWABACustomWebhookResponse, IWhatsappBusinessAccountResponse } from '@colmeia/core/src/shared-business-rules/whatsapp-business-account/whatsapp-business-account.req-resp.dto';
import { isValidRef, isValidString, typedClone, values } from '@colmeia/core/src/tools/barrel-tools';
import { GenericDashboardEditHandler, IGenericDashboardEditCustomButton, IGenericDashboardEditHandlerParameter } from 'app/handlers/generic-dashboard-edit.handler';
import { ENsMoreOptions } from 'app/handlers/ns-more-options.handler';
import { getCurrentEnvironmentName } from 'app/model/client-utility';
import { routeList } from 'app/model/routes/route-constants';
import { FacebookIntegrationService } from 'app/services/auth/facebook-auth.service';
import { DashboardWabaService } from 'app/services/dashboard-waba.service';
import { FacebookService } from 'app/services/facebook/fb.service';
import { LookupService } from 'app/services/lookup.service';
import { ServerCommunicationService } from 'app/services/server-communication.service';
import { SessionService } from 'app/services/session.service';
import { SnackMessageService } from 'app/services/snack-bar';
import { ColmeiaWindowRef } from '../../dashboard-foundation/colmeia-window/colmeia-window-ref';
import { ColmeiaService } from '../../colmeia/colmeia.service';
import { EInteractiveButton, GlobalWarningService, INTERACTIVE_NO, INTERACTIVE_YES } from 'app/services/global-warning.service';
import { WhatsappAuthService } from 'app/services/auth/whatsapp-auth.service';
import { EmbeddedSignupService } from 'app/services/auth/embedded-signup.service';


@Component({
    selector: 'app-dashboard-waba-edit',
    templateUrl: './dashboard-waba-edit.component.html',
    styleUrls: ['./dashboard-waba-edit.component.scss']
})
export class DashboardWabaEditComponent implements OnInit {

    private isEditing: boolean = false;
    private onlyWABASharing: boolean = true;
    private buttons: IGenericDashboardEditCustomButton[]
    private wabaIdNs: string | null;

    public genericDashboardEdit: GenericDashboardEditHandler;
    public wabaNser: IWhatsappBusinessAccountClient | IWhatsappBusinessAccountServer;
    public wabaNserOrigin: IWhatsappBusinessAccountClient | IWhatsappBusinessAccountServer;
    public isEmbededSignUpButtonEnabled: boolean = true;
    public creditLineStatus: ECreditLineStatus;
    public webhookStatus: EWABACustomWebhookStatus;

    public note: string;

    private wppProviders: IProviderPresentation[] = providersBySocialMedia.get(EDelivery360Action.Delivery360WhatsApp)!;
    public availableProviders: IProviderPresentation[] = this.wppProviders.filter(pvd => pvd.enum === DeliveryProvider.smarters);
    availableSolutionPartners: Partial<Record<DeliveryProvider, string>> = {
        [DeliveryProvider.smarters]: '371169055259765'
    }

    get businessId() { return this.wabaNser.businessId; }
    set businessId(value: string | undefined) { this.wabaNser.businessId = value; }
    get wabaName() { return this.wabaNser.nName }
    set wabaName(value: string) { this.wabaNser.nName = value; }
    get wabaId() { return this.wabaNser.wabaId }
    set wabaId(value: string) { this.wabaNser.wabaId = value; }
    get token() { return this.wabaNser.token }
    set token(value: string) { this.wabaNser.token = value; }
    get webhookURL() { return this.wabaNser.webhookURL }
    set webhookURL(value: string) { this.wabaNser.webhookURL = value; }
    get creditLineAllocationId() { return this.wabaNser.creditLineAllocationId }
    set creditLineAllocationId(value: string) { this.wabaNser.creditLineAllocationId = value; }
    get provider() { return this.wabaNser.provider }
    set provider(value: DeliveryProvider | undefined) { this.wabaNser.provider = value }

    constructor(
        private wabaSvc: DashboardWabaService,
        private colmeiaSvc: ColmeiaService,
        private activatedRoute: ActivatedRoute,
        private lookupSvc: LookupService,
        private whatsappAuthService: WhatsappAuthService,
        private embeddedSignupService: EmbeddedSignupService,
        private sessionSvc: SessionService,
        private snackSvc: SnackMessageService,
        private facebookSvc: FacebookService,
        private globalWarningSvc: GlobalWarningService,
        @Optional() private windowRef: ColmeiaWindowRef,
    ) { }

    ngOnInit(): void {
        if (isValidRef(this.windowRef)) {
            this.isEditing = false;
        }
        else {

            const wabaRouteParam = routeList.dashboard.children.communication.children.waba.children.edit.routeParam.slice(1);
            this.wabaIdNs = this.activatedRoute.snapshot.paramMap.get(wabaRouteParam);

            // const routeParams: Params = this.activatedRoute.snapshot.params;
            // const has = routeParams.hasOwnProperty(wabaRouteParam)
            // console.log({ routeParams, has })
            this.isEditing = isValidString(this.wabaIdNs);
        }


        if (this.isEditing) {
            this.getWaba(this.wabaIdNs!);
        }
        else {
            this.wabaNser = this.wabaSvc.getEmptyWaba();
            this.wabaNserOrigin = typedClone(this.wabaNser);
            this.initGenericDashboardEdit();
        }

    }

    private async getWaba(wabaIdNs: string) {
        const ns = await this.lookupSvc.getNS(wabaIdNs);
        // console.log({ ns })
        this.wabaNser = ns as IWhatsappBusinessAccountServer;
        this.wabaNserOrigin = typedClone(this.wabaNser);
        this.initGenericDashboardEdit();

        //Get info
        this.getWebhookStatus();
        this.getCreditLineStatus();
    }

    public initGenericDashboardEdit(): void {
        this.buttons = [];
        this.genericDashboardEdit = new GenericDashboardEditHandler(<IGenericDashboardEditHandlerParameter>{
            title: 'Whatsapp Business Account (WABA)',
            nsType: ENonSerializableObjectType.waba,
            clientCallback: this,
            moreOptions: {
                [ENsMoreOptions.CheckDependencies]: true,
                [ENsMoreOptions.CopyID]: true,
            },
            buttons: this.buttons,
            nser: this.wabaNser,
        })
    }

    private async getWebhookStatus() {
        this.webhookStatus = (await this.wabaSvc.getWABACustomWebhookStatus(this.wabaNser)).data.status;
    }

    private async getCreditLineStatus() {
        this.creditLineStatus = (await this.wabaSvc.getWABACreditLineStatus(this.wabaNser)).data.status;
    }

    public async onGenericBackButtonPressed(): Promise<void> {
        if (this.isColmeiaWIndow()) {
            this.windowRef.close();
            return;
        }
        this.wabaSvc.goToWabaList();

    }

    public async onGenericSaveButtonPressed(): Promise<void> {
        const error = this.hasErrors();
        if (error) {
            this.snackSvc.openError(`Por favor preencha o campo ${error}`, 3000);
            return;
        }

        const res: IWhatsappBusinessAccountResponse | undefined = await this.wabaSvc.saveWaba(this.wabaNser);
        if (!res) {
            this.snackSvc.openError('Não foi possível salvar a WABA')
            return;
        }
        this.snackSvc.openSuccess('Whatsapp Business Account salva com sucesso!');
        this.wabaNser = res.ns;
        this.wabaNserOrigin = typedClone(this.wabaNser);

        //Refetch information:
        this.getWebhookStatus();
    }

    public async launchWhatsAppEmbeddedSignup() {
        try {
            this.isEmbededSignUpButtonEnabled = false;

            const config = await this.embeddedSignupService.getChannelsEmbeddedSignupLoginConfig(this.onlyWABASharing, this.wabaNser.solutionPartnerID)

            const response = await this.facebookSvc.login(config);
            if (response.authResponse) {
                const codeToBeExchangedToToken = response.authResponse.code;
                console.log({
                    FBLoginResponse: response,
                });

                if (isValidString(codeToBeExchangedToToken)) {
                    const exchangeTokenResponse: IMetaExchangeCodeForTokenAndGetWhatsappInfoResponse | undefined = await this.whatsappAuthService.exchangeCodeByToken(codeToBeExchangedToToken, true);
                    if (!exchangeTokenResponse) return;
                    this.businessId = exchangeTokenResponse?.data.businessID;

                    this.token = exchangeTokenResponse.data.access_token
                    this.wabaId = exchangeTokenResponse.data.sharedWABAID
                    this.wabaName = exchangeTokenResponse.data.wabaName!;

                    // caso algum solution partner seja selecionado nao podemos registrar 2 webhooks, precisamos usar o webhook dele no canal dele
                    // if (this.isAbleToRegisterWebhook()) {
                    //     const res: IMetaWebhookRegisterResponse = await this.wabaSvc.registerWebhookAndGetURL(this.wabaNser);
                    //     this.wabaNser.webhookURL = res.webhookResult.webHookURL;
                    // }
                } else {
                    console.error('User cancelled login or did not fully authorize 1.');
                }
            } else {
                console.error('User cancelled login or did not fully authorize 2.');
            }

        } catch (error) {
            console.error(error)
        } finally {
            this.isEmbededSignUpButtonEnabled = true
        }
    }

    public isAbleToRegisterWebhook() {
        return !isValidString(this.wabaNser.solutionPartnerID)
    }

    public insertDomainOnMetaWebhookUrl() {
        const serverDomainFromEnvironment = this.getServerDomainFromEnvironment();
        const currentSocialContextID = this.sessionSvc.getCurrentSocialNetworkID();

        const url = `${serverDomainFromEnvironment}${ROUTES.webhook.whatsapp}/receive/${currentSocialContextID}`;
        this.webhookURL = url;
    }

    public canEditWebhookURL(): boolean {
        return (this.isAbleToRegisterWebhook() && !this.isWebhookSubscribed());
    }

    public isWebhookSubscribed(): boolean {
        return (this.webhookStatus === EWABACustomWebhookStatus.REGISTERED);
    }

    public canEnableWebhook(): boolean {
        return (this.isEditing && !this.isWebhookSubscribed() && this.isAbleToRegisterWebhook());
    }

    public canDisableWebhook(): boolean {
        return (this.isEditing && this.isWebhookSubscribed() && this.isAbleToRegisterWebhook());
    }

    public async confirmDisableWebhook(): Promise<void> {
        const prompt = await this.globalWarningSvc.showInteractivePrompt(
            {
                title: "Desativar Webhook",
                message: 'Deseja desativar este webhook?<br>A Colmeia não vai mais receber notificações por este webhook para esta WABA.',
                options: [
                    INTERACTIVE_NO,
                    INTERACTIVE_YES
                ]
            });
        if (prompt === EInteractiveButton.Yes) {
            this.disableCustomWebhook();
        }
    }

    public async enableCustomWebhook() {
        if (this.canEnableWebhook()) {
            await this.wabaSvc.registerWABACustomWebhook(this.wabaNser, this.wabaNser.idNS);

            //update display and status
            this.getWebhookStatus();
        }
    }

    private async disableCustomWebhook() {
        if (this.canDisableWebhook()) {
            await this.wabaSvc.unregisterWABACustomWebhook(this.wabaNser);

            //update display and status
            this.getWebhookStatus();
        }
    }

    envDomains: Set<string> = new Set(values(environmentBaseURL));

    getServerDomainFromEnvironment() {
        const currentDomain = this.getDomainFromURL(this.webhookURL)
        if (isValidRef(currentDomain) && !this.envDomains.has(currentDomain)) {
            return currentDomain;
        }
        const currentEnv = getCurrentEnvironmentName();
        const serverDomainFromEnvironment = environmentBaseURL[currentEnv]; //'https://bot.jrabelo.tunnels.colmeia.me'
        return serverDomainFromEnvironment;
    }

    getDomainFromURL(url: string): string | undefined {
        try {
            const domain = new URL(url).origin;
            return domain;
        } catch { }
    }

    public isAbleToManageCreditLine(): boolean {
        return this.colmeiaSvc.isColmeiaStaff() && this.isEditing;
    }

    public canAttachCreditLine(): boolean {
        return this.isAbleToManageCreditLine() && (
            this.creditLineStatus === ECreditLineStatus.UNSHARED || this.creditLineStatus === ECreditLineStatus.DELETED
        );
    }

    public async confirmAttachCreditLine() {
        const prompt = await this.globalWarningSvc.showInteractivePrompt(
            {
                title: "Vincular Linha de Crédito",
                message: 'Deseja vincular a Linha de Crédito desta WABA à Linha de Crédito da Colmeia?',
                options: [
                    INTERACTIVE_NO,
                    INTERACTIVE_YES
                ]
            });
        if (prompt === EInteractiveButton.Yes) {
            this.attachWABACreditLine();
        }
    }

    private async attachWABACreditLine() {
        if (this.canAttachCreditLine()) {
            const res: IAttachWabaCreditLineResponse = await this.wabaSvc.attachWABACreditLine(this.wabaNser, this.wabaNser.idNS);
            this.creditLineAllocationId = res.data.creditLineId;
            this.creditLineStatus = res.data.creditLineStatus;
        }
    }

    public canRevokeCreditLine(): boolean {
        return this.isAbleToManageCreditLine() && this.creditLineStatus === ECreditLineStatus.APPROVED;
    }

    public async confirmRevokeCreditLine() {
        const prompt = await this.globalWarningSvc.showInteractivePrompt(
            {
                title: "Revogar Linha de Crédito",
                message: 'Deseja revogar a Linha de Crédito desta WABA da Linha de Crédito da Colmeia?',
                options: [
                    INTERACTIVE_NO,
                    INTERACTIVE_YES
                ]
            });
        if (prompt === EInteractiveButton.Yes) {
            this.revokeWABACreditLine();
        }
    }

    private async revokeWABACreditLine() {
        if (this.canRevokeCreditLine()) {
            const res: IRevokeWabaCreditLineResponse = await this.wabaSvc.revokeWABACreditLine(this.wabaNser);
            this.creditLineAllocationId = res.data.creditLineId;
            this.creditLineStatus = res.data.creditLineStatus;
        }
    }

    private isColmeiaWIndow() {
        return isValidRef(this.windowRef);
    }

    private hasErrors(): string | undefined {
        // console.log({ nser: this.wabaNser })
        if (!this.wabaNser.nName) return 'Nome';
        if (!this.wabaNser.wabaId) return 'Id da WABA';
        if (!this.wabaNser.token) return 'Token';
        if (!isValidString(this.wabaNser.solutionPartnerID) && !this.wabaNser.webhookURL) return 'Webhook URL';
    }

    public onPartnerChange(selected: DeliveryProvider | undefined) {
        // console.log({selected, wabaProvider: this.wabaNser.provider});
        this.wabaNser.solutionPartnerID = this.wabaNser.provider ? this.availableSolutionPartners[this.wabaNser.provider] : undefined;

    }
    public getSocialMediaProvider(providerEnum: DeliveryProvider): IProviderPresentation | undefined {
        return this.availableProviders.find(provider => provider.enum === providerEnum);
    }
}
