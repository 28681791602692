import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, EventEmitter, Input, OnInit, Output, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatChipInputEvent } from '@angular/material/chips';
import { MatSnackBar } from '@angular/material/snack-bar';
import { EUnitTypeID } from '@colmeia/core/src/business/constant.enums';
import { IFormChoice, SchemaProperty } from '@colmeia/core/src/general-form/general-form-interface';
import { ILocalCanonical, IServerLocalCanonical } from '@colmeia/core/src/shared-business-rules/canonical-model/local-canonical';
import { ECatalogType } from '@colmeia/core/src/shared-business-rules/catalog/catalog.interfaces';
import { unityEnumTranslations } from "@colmeia/core/src/shared-business-rules/const-text/enums";
import { gTranslations } from '@colmeia/core/src/shared-business-rules/const-text/translations';
import { metadataNamesTranslations } from '@colmeia/core/src/shared-business-rules/const-text/views/metadata';
import { EMetadataNames } from '@colmeia/core/src/shared-business-rules/metadata/metadata-db';
import { getUnityInfo } from "@colmeia/core/src/shared-business-rules/metadata/unity-utils";
import { ENonSerializableObjectType } from '@colmeia/core/src/shared-business-rules/non-serializable-id/non-serializable-id-interfaces';
import { nonNullable } from '@colmeia/core/src/tools/type-utils';
import { isInvalid, isValidArray, isValidPropertyName, isValidPropertyNameForCorporateSearch, isValidRef, isValidString, keys } from '@colmeia/core/src/tools/utility';
import { RootComponent } from 'app/components/foundation/root/root.component';
import { NSPickerHandler } from 'app/handlers/ns-picker/ns-picker.handler';
import { createEmptySchemaProperty, IChoiceDisplay } from 'app/model/general-form.model';
import { CanonicalService } from 'app/services/canonical.service';
import { DashBoardService } from 'app/services/dashboard/dashboard.service';
import { copyToClickBoard } from 'app/utils/copy';
import {
    EEnumPickerMode,
    EnumPickerHandler,
    IEnumPickerClientCallback
} from "../../foundation/enum-picker/enum-picker.handler";



@Component({
    selector: 'app-general-form-field',
    templateUrl: './general-form-field.component.html',
    styleUrls: ['./general-form-field.component.scss']
})
export class GeneralFormFieldComponent extends RootComponent<'nameLabel' | 'typeLabel' | 'canonicalLabel' | 'multipleLabel' | 'addField' | 'fieldRequired' | 'propNameLabel' | 'option' | 'required' | 'internalBehavior' | EMetadataNames> implements OnInit, IEnumPickerClientCallback<EUnitTypeID> {
    canonicals: ILocalCanonical[];
    lockExpansion: boolean = true;
    expanded: boolean = false;
    canonicalDisabled: boolean = false;

    choices: IChoiceDisplay[] = [];

    public _propertyNameError: string = "";

    @Input() isCorporateSearch: boolean;
    @Input() field: SchemaProperty;
    @Output() delete = new EventEmitter();


    @ViewChild(NgForm) form: NgForm;
    @ViewChildren(GeneralFormFieldComponent) innerFields: QueryList<GeneralFormFieldComponent>;

    constructor(
        private canonicalSvc: CanonicalService,
        private dashboardSvc: DashBoardService,
        private snackSvc: MatSnackBar
    ) {
        super({
            nameLabel: gTranslations.common.name,
            typeLabel: gTranslations.common.type,
            canonicalLabel: gTranslations.generalForm.canonical,
            multipleLabel: gTranslations.generalForm.multiple,
            addField: gTranslations.generalForm.addField,
            fieldRequired: gTranslations.common.fieldRequired,
            propNameLabel: gTranslations.generalForm.propNameLabel,
            option: gTranslations.generalForm.option,
            required: gTranslations.generalForm.required,
            internalBehavior: gTranslations.generalForm.internalBehavior,
            dynamicIsland: gTranslations.errors.dynamicIsland,
            ...metadataNamesTranslations,
        });
    }

    public localCanonicalPicker: NSPickerHandler;

    async ngOnInit() {
        this.initUnitiesPickerHandler();
        this.onTranslation.subscribe(this.initUnitiesPickerHandler);
        this.checkType(false);
        const canonicalResponse = await this.canonicalSvc.getCanonicals();
        this.canonicals = canonicalResponse.nonSerializableArray;

        this.localCanonicalPicker = this.dashboardSvc.easyCreateNSPickerHandler({
            title: 'Canonização',
            nsType: ENonSerializableObjectType.canonical,
            useDemandedTag: false,
            selectedId: this.field.idLocalCanonical,
            clientCallback: {
                onSaveNSCallback: (canonical: IServerLocalCanonical) => this.onSelectCanonical(canonical)
            },
        }, { filter: (ns: IServerLocalCanonical) => this.field.idGlobalCanonical ? ns.globalCanonical === this.field.idGlobalCanonical : true });

        if (isValidArray(this.field.choices) && typeof this.field.choices[0] === 'string') {
            this.field.choices = this.field.choices.map((text: any) => ({
                text,
            }));
        }

        if (this.isMultipleChoice()) {
            this.choices = this.field.choices.map(choice => ({
                choice,
                handler: undefined
            }));
        }
    }


    public mapCanonicalName = (ns: IServerLocalCanonical): string => {
        let name: string = ns.nName;
        if (isValidRef(ns.globalCanonical)) name += ` <span style="font-weight: bold;">-</span> <span style="color: #4dd165; font-weight: bold;">${this.translations[ns.globalCanonical].value}</span>`;
        return name;
    }

    onSelectCanonical(canonical: IServerLocalCanonical): void {
        this.field.idLocalCanonical = canonical?.idNS;
    }

    typeEnumPicker: EnumPickerHandler<EUnitTypeID>;

    onMultipleEnumSelection(values: EUnitTypeID[]): void {
    }

    onSingleEnumSelection(val: EUnitTypeID): void {
        this.field.idUnity = val;
        this.checkType();
    }

    initUnitiesPickerHandler() {
        this.typeEnumPicker = new EnumPickerHandler<EUnitTypeID>({
            enum: EUnitTypeID,
            client: this,
            translations: unityEnumTranslations,
            inputTitle: `Tipo`,
            clientCallback: null,
            mode: EEnumPickerMode.Single,
            appearance: "fill",
            ignoreValues: keys(EUnitTypeID).filter(key => getUnityInfo(nonNullable(EUnitTypeID[key])).special),
            current: this.field?.idUnity
        })
    }

    isObject(): boolean {
        return this.field.idUnity === EUnitTypeID.objectType;
    }

    isMultipleChoice(): boolean {
        return this.field.idUnity === EUnitTypeID.multipleChoiceType;
    }

    checkType(autoOpen: boolean = true) {
        if (this.isObject()) {
            this.lockExpansion = false;
            this.expanded = autoOpen;
            this.canonicalDisabled = true;
            this.field.idLocalCanonical = undefined;

            if (isInvalid(this.field.nestedSchema)) {
                this.field.nestedSchema = [];
            }

            if (this.field.nestedSchema.length === 0) {
                this.addInnerField();
            }
        } else if (this.isMultipleChoice()) {
            this.lockExpansion = false;
            this.expanded = autoOpen;
            this.canonicalDisabled = false;

            if (isInvalid(this.field.choices)) {
                this.field.choices = [];
            }

        } else {
            this.lockExpansion = true;
            this.expanded = false;
            this.canonicalDisabled = false;
        }
    }

    addChoiceByInput(event: MatChipInputEvent) {
        const value = (event.value || '').trim();
        if (!isValidString(value)) return;

        const choice: IFormChoice = {
            text: value
        }
        this.field.choices.push(choice);
        this.choices.push({
            choice,
        });

        event.input.value = "";
    }

    deleteChoice(index: number) {
        this.field.choices.splice(index, 1);
        this.choices.splice(index, 1);
    }

    moveChoice(event: CdkDragDrop<IFormChoice[]>) {
        moveItemInArray(this.field.choices, event.previousIndex, event.currentIndex);
        moveItemInArray(this.choices, event.previousIndex, event.currentIndex);
    }

    addInnerField() {
        this.field.nestedSchema.push(createEmptySchemaProperty());
    }

    removeField(index: number) {
        this.field.nestedSchema.splice(index, 1);
    }

    trackByIdx(index: number, value: string) {
        return index;
    }

    isValid(): boolean {
        this.form.onSubmit(null);
        let isValid = this.form.valid;

        if (this.isObject() && this.innerFields.length > 0) {
            this.innerFields.forEach(innerField => {
                const fieldValid = innerField.isValid();
                if (!fieldValid) {
                    isValid = false;
                }
            });
            if (!isValid) {
                this.expanded = true;
            }
        }

        if (this.isMultipleChoice() && this.innerFields.length > 0) {
            isValid = !this.field.choices.some(isInvalid);

            if (!isValid) {
                this.expanded = true;
            }
        }

        return isValid;
    }

    removeMultipleChoiceOption(index: number) {

    }

    public validatePropertyName() {
        this._propertyNameError = "";

        if (this.isCorporateSearch && !isValidPropertyNameForCorporateSearch(this.field.propertyName)) {
            this._propertyNameError = "Apenas letras minúsculas e underscore.";
        } else if (!isValidPropertyName(this.field.propertyName)) {
            this._propertyNameError = "Apenas letras, números e underscore.";
        }
    }

    public copyId() {
        copyToClickBoard(this.field.idProperty);
        this.snackSvc.open("Copiado para a área de transferência.", "Fechar", { duration: 3000 });
    }
}
