import { IFilterlet, IGetUsedFeedbackAndTypeLookupResponse, EDynamicMatch } from "@colmeia/core/src/rules/dynamic-filter/dynamic-filter-interfaces";
import { HandlerHexagonon } from "app/handlers/hexagono.handler";
import { InjectionToken } from "@angular/core";
import { Subject } from "rxjs";
import { ITranslationHash } from "@colmeia/core/src/shared-business-rules/translation/translation-engine";
import { constant } from "@colmeia/core/src/business/constant";
import { EServiceGroupIntType, EGeolocationIntType } from "@colmeia/core/src/business/constant.enums";
import { genericTypedSuggestions } from "@colmeia/core/src/tools/type-utils";


export interface IChatFilterData {
    filterlet: IFilterlet,
    canShowInterval: boolean,
    onFilterApply?: (filterlet: IFilterlet) => void,
}

export interface IAvatarHandlerHash {
	[avatarId: string]: HandlerHexagonon
}

export interface IChatFilterParameters {
    name: string;
    iconFile: string;
    component: any;
}

export class ChatFilter {

    isExpanded: boolean = false;
    name: string;
    icon: string;
    component: any;

    constructor(parameters: IChatFilterParameters
    ) {
        this.name = parameters.name;
        this.component = parameters.component;
        this.icon = `./assets/icons/chat-filter/${parameters.iconFile}`;
    }

    onOpened() {
        this.isExpanded = true;
    }

    onClosed() {
        this.isExpanded = false;
    }
}

export const filterletToken = new InjectionToken<IFilterlet>('filterlet');

export type TUsedFeedbackAndInteractionPromise = Promise<IGetUsedFeedbackAndTypeLookupResponse>;
export const feedbackAndInteractionPromiseToken = new InjectionToken<TUsedFeedbackAndInteractionPromise>('feedbackAndInteractionPromise');

export const chatFilterEventsToken = new InjectionToken<IChatFilterEvents>('chatFilterEvents');

export interface IChatFilterEvents {
    applied: Subject<void>;
}

export interface IAppliedChatFilter {
    icon: string;
    isApplied: boolean;
}

export function isValidFilter(filter: Array<any> | Object): boolean {
	if (filter instanceof Array){
		return filter.length > 0;
	} else if (typeof filter === 'object') {
		return Object.values(filter).some(Boolean)
	}
	return false;
}

export const FilterMatchTranslations = genericTypedSuggestions<ITranslationHash>()({
    [EDynamicMatch.first]: {
        serializableId: constant.chatFilter.serializableId,
        idField: constant.serializableField.auxiliars.aux06
    },
    [EDynamicMatch.last]: {
        serializableId: constant.chatFilter.serializableId,
        idField: constant.serializableField.auxiliars.aux07
    },
    [EDynamicMatch.any]: {
        serializableId: constant.chatFilter.serializableId,
        idField: constant.serializableField.auxiliars.aux08
    },

})

export interface IInteractionTypeIconHash {
    [idInteractionType: string]: string;
}

const featureIcon = (icon: string) => `./assets/icons/feature-icons/${icon}`;
const groupTypeIcon = (icon: string) => `./assets/icons/group-type/${icon}`;
const groupServiceIcon = (icon: string) => `./assets/icons/group-service/${icon}`;

export const InteractionTypeIcons: IInteractionTypeIconHash = {
    // [EFormularyIntType.newForm]: featureIcon('form.svg'),
    // [ETreeInteractionTypeID.treeChoice]: featureIcon('survey.svg'),
    [constant.interactionType.standard.message]: featureIcon('icon_msg.svg'),
    [EServiceGroupIntType.startServiceChat]: groupServiceIcon('icon_solicitacaoservico.svg'),
    [EGeolocationIntType.beginRealtimeTracking]: featureIcon('icon_route.svg')
}
