import { Injectable } from "@angular/core";
import { TGlobalUID } from "@colmeia/core/src/core-constants/types";
import { ICRMStep, ICRMTicketDataWithCustomerName, TICRMTicketItems } from "@colmeia/core/src/shared-business-rules/crm/crm-entities";
import { ECRMCanonicalItemsTypes } from "@colmeia/core/src/shared-business-rules/crm/crm-services/crm-config-canonical-model";
import { TICRMProjectArray } from "@colmeia/core/src/shared-business-rules/crm/crm-services/crm-config.model";
import { ECRMTicketRuntimeRequests, ICRMGetAgentTicketsRequest, ICRMGetAgentTicketsResponse, ICRMGetPackagesRequest, ICRMGetPackagesResponse, ICRMGetTicketDataRequest, ICRMGetTicketDataResponse, ICRMInsertTicketInteractionRequest, ICRMInsertTicketInteractionResponse } from "@colmeia/core/src/shared-business-rules/crm/crm-services/crm-runtime-req-res";
import { ECRMTimeWindowField, ECRMTimeWindows } from "@colmeia/core/src/shared-business-rules/crm/crm-services/crm-time-window.model";
import { daysInMS } from "@colmeia/core/src/time/time-utl";
import { ServerCommunicationService } from "app/services/server-communication.service";

const itemTypeToStatusProp: Record<ECRMCanonicalItemsTypes, keyof ICRMStep> = {
    [ECRMCanonicalItemsTypes.Severity]: "idSeverity",
    [ECRMCanonicalItemsTypes.UrgencyLevel]: "idUrgencyLevel",
    [ECRMCanonicalItemsTypes.SupportLevel]: "idSupportLevel",
    [ECRMCanonicalItemsTypes.Phase]: "idPhase",
    [ECRMCanonicalItemsTypes.TicketState]: "idTicketState",
    [ECRMCanonicalItemsTypes.CloseState]: "idCloseState"
}


export interface IGetAllTicketsAndPackagesResponse {
    tickets: TICRMTicketItems;
    packages: TICRMProjectArray;
}

@Injectable({
    providedIn: 'root'
})
export class CRMTicketPersistenceService {

    constructor(private api: ServerCommunicationService) { }


    async getAllTicketsAndPackages(req?: Partial<ICRMGetAgentTicketsRequest>): Promise<IGetAllTicketsAndPackagesResponse> {
        const result = await this.api.sendRequest<ICRMGetAgentTicketsRequest, ICRMGetAgentTicketsResponse>(ECRMTicketRuntimeRequests.getAgentTickets)({
            timeWindow: {
                id: ECRMTimeWindows.t7,
                field: ECRMTimeWindowField.updatedAt,
                minTimestamp: daysInMS(7),
            },
            filters: {},
            sort: [],
            fields: {},
            idCustomerAvatar: '',
            ...req,
        });

        return {
            tickets: result?.tickets || [],
            packages: result?.packages || [],
        }

    }

    private async sendInsertInteractionRequest(ticketId: TGlobalUID, interaction: Partial<ICRMInsertTicketInteractionRequest>): Promise<boolean> {
        const result = await this.api.sendRequest<ICRMInsertTicketInteractionRequest, ICRMInsertTicketInteractionResponse>(ECRMTicketRuntimeRequests.insertTicketInteraction)({
            ticketId,
            ...interaction,
        });
        return !!result
    }

    public async updateTicketStatusItem(ticketId: TGlobalUID, itemType: ECRMCanonicalItemsTypes, itemId: TGlobalUID, idAnswer?: TGlobalUID): Promise<boolean> {
        return this.sendInsertInteractionRequest(ticketId, {
            statusInteraction: {
                [itemTypeToStatusProp[itemType]]: itemId,
            },
            answer: { idAnswer },
        });
    }

    public async insertContent(ticketId: TGlobalUID, comment: string, idMedias?: string[]): Promise<boolean> {
        return this.sendInsertInteractionRequest(ticketId, {
            content: { comment, idMedias }
        });
    }

    public async getTicket(idTicket: string): Promise<ICRMTicketDataWithCustomerName> {
        const result = await this.api.sendRequest<ICRMGetTicketDataRequest, ICRMGetTicketDataResponse>(ECRMTicketRuntimeRequests.getTicketData)({
            idTicket,
        });
        return result?.ticket;
    }

    public async getPackages(idPackages: string[]): Promise<TICRMProjectArray> {
        const result = await this.api.sendRequest<ICRMGetPackagesRequest, ICRMGetPackagesResponse>(ECRMTicketRuntimeRequests.getPackages)({
            idPackages
        });
        console.log({
            getPackages: result
        })
        return result?.packages;
    }
}
