import { TNodeAssetIcons } from '@colmeia/core/src/shared-business-rules/bot/new-bot-action';
import { TArrayID } from '../../../core-constants/types';
import { IGraphElementRenderData } from '../../../shared-business-rules/graph/essential/diagram.interfaces';
import { TReferencedObject } from '../../graph-transaction/toolbar/config-toolbar.types';
import { GraphElement } from './graph-element';
import { GraphPredicate } from './predicate';
import { Property } from './property';
export type TGraphElementArray = Array<GraphElement>;

export enum EGraphElementType {
    root = 'root',
    node = 'gnode',
    predicate = 'gpred',
    property = 'gprop',
    leaf = 'gleaf'
};

export enum EPredicateType {
    undirected = 'gund', directed = 'gdir'
}

export type TElementTypeArray = Array<EGraphElementType>;
export type TGraphPropertyArray = Array<Property>;
export type TGraphPropertyData = number | string;

export interface IGraphConnectionDB {
    [idElement: string]: TArrayID;
};

export type TRenderData = IGraphElementRenderData;

export const gDefaultRenderData: TRenderData = {
    offsetX: 220,
    offsetY: 130,
    id: '',
    width: 100,
    height: 100,
}

export const botRootDefaultRenderData: TRenderData = {
    ...gDefaultRenderData,
    offsetX: 220,
    offsetY: 130,
}

export enum EPropertyType {
    text = 'text',
    textInput = 'textInput',
    choice = 'choice',
}

export enum ECommonEvents {
    translation = 'translation',
    internalElementsGroupClick = 'assetGroupClick',
    editClick = 'editClick',
    updateElement = 'updateElement',
    updateRenderData = 'updateRenderData',
    selected = 'selected',
    remove = 'remove'
}

export interface IEventHash {
    [eventName: string]: Function[];
}

export interface IPropertyChoiceOption {
    label: string;
    value: string;
}

export type TChoicePropertyData = Array<IPropertyChoiceOption>;


export interface IInternalElementRenderData {
    type: TNodeAssetIcons;
    hasValidConfiguration: boolean;
    count?: number;
    title?: string;
    iconURL?: string;
    intentId?: string;
}

export interface INextStepInfoRenderData {
    type: TNodeAssetIcons;
    title: string;
    asideIcons?: TNodeAssetIcons[];
}

export type TIGraphNeighborArray = IGraphNeighbor[]
export interface IGraphNeighbor {
    pred: GraphPredicate;
    neighbor: GraphElement;
}

export type TIGraphConnectionDataArray = Array<IGraphConnectionData>;

export interface IGraphConnectionData {
    targetHostId: string;
    connectionType: TReferencedObject;
    subElementId: string; // Identifica o elemento da representado pela porta de origem desta conexão
}

export interface IExternalSubTreeSliced {
    externalNodes: GraphElement[],
    externalEdges: GraphPredicate[],
}
