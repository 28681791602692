import { Location } from "@angular/common";
import { Component, Inject, Optional, ViewChild, ViewContainerRef } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { apiRequestType } from "@colmeia/core/src/request-interfaces/message-types";
import { IRequest } from "@colmeia/core/src/request-interfaces/request-interfaces";
import {
    INewAgentCallContactServer,
    INewAgentCallContactItem,
    INewAgentCallBatchServer,
    ESendActiveMethod,
} from "@colmeia/core/src/shared-business-rules/active-1x1-call/active-1x1-model";
import {
    ENonSerializableObjectType,
    INonSerializable,
} from "@colmeia/core/src/shared-business-rules/non-serializable-id/non-serializable-id-interfaces";
import {
    GenericDashboardEditHandler,
    IGenericDashboardEditPageClientCallback,
} from "app/handlers/generic-dashboard-edit.handler";
import { routeList } from "app/model/routes/route-constants";
import { AttendanceActiveEditHelperService } from "app/services/attendance-active-edit-call-init.service";
import { GenericDashboardRequester } from "app/services/generic-dashboard-requester.service";
import { LookupService } from "app/services/lookup.service";
import { GenericTableComponent } from "../../call-center/generic-table/generic-table.component";
import { DynamicDialogComponent } from "../../dashboard-data-extractor/dynamic-dialog/dynamic-dialog.component";
import {
    EGenericDashboardColumnType,
    IDashboardClientCallback,
    IGenericDashboardRequestParameters,
    IGenericDashboardTextColumn,
    IGenericHomeRow,
} from "../../dashboard-foundation/generic-dashboard-home/generic-dashboard-home.model";
import { GenericHomeHandler } from "../../dashboard-foundation/generic-dashboard-home/generic-home.handler";
import { DashboardActiveCallInfoComponent } from "../dashboard-active-call-info/dashboard-active-call-info.component";
import { deprecatedSuggestions } from "@colmeia/core/src/tools/type-utils";
import { values, getIfNotValid, isValidRef, isValidString, isValidArray } from "@colmeia/core/src/tools/utility";
import { RootComponent } from "app/components/foundation/root/root.component";
import { gTranslations } from "@colmeia/core/src/shared-business-rules/const-text/translations";
import { DatetimeConversions } from "app/services/datetime-conversions";
import { UserSettingsService } from "app/services/user-settings.service";
import { AttendanceActiveEditCallApiService } from "app/services/attendance-active-edit-call.service";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { EDefaultTag } from "@colmeia/core/src/shared-business-rules/colmeia-tags/tags";
import { EListNonSerializablesSortOrder } from "@colmeia/core/src/dashboard-control/dashboard-request-interfaces";
import { ColmeiaWindowService } from "app/components/dashboard/dashboard-foundation/colmeia-window/colmeia-window.service";
import { AttendanceActiveCallComponent } from "app/components/dashboard/dashboard-attendance-active-call/attendance-active-call/attendance-active-call.component";
import { ColmeiaWindowRef } from "app/components/dashboard/dashboard-foundation/colmeia-window/colmeia-window-ref";
import { SnackMessageService } from "app/services/snack-bar";
import { ColmeiaDialogService } from "app/services/dialog/dialog.service";
import { AttendanceSendMethodDialogComponent } from "app/components/dashboard/dashboard-attendance-active-call/attendance-send-method-dialog/attendance-send-method-dialog.component";
import { ICampaignServer, ICampaingActionHeader, ICMassCommunicationAction, TCMassCommActionArray } from "@colmeia/core/src/shared-business-rules/campaigns/campaign-type-model";
import { CampaignActionPickerComponent, ICampaignActionPicker } from "../../campaign-action-picker/campaign-action-picker.component";
import { NsTypeToInterface } from '@colmeia/core/src/shared-business-rules/non-serializable-id/non-serializable-interface-mapper';
import { EMetadataNames } from "@colmeia/core/src/shared-business-rules/metadata/metadata-db";

@Component({
    selector: "app-attendance-active-call-edit",
    templateUrl: "./attendance-active-call-edit.component.html",
    styleUrls: ["./attendance-active-call-edit.component.scss"],
})
export class AttendanceActiveCallEditComponent extends RootComponent<ESendActiveMethod> {
    public dateTimeConversor: DatetimeConversions;
    public genericEdit: GenericDashboardEditHandler;
    public genericHome: GenericHomeHandler;
    public nsTypeEntity: ENonSerializableObjectType =
        ENonSerializableObjectType.activeCallBatch;
    public nsTypeList: ENonSerializableObjectType =
        ENonSerializableObjectType.activeCall1x1;
    public idEntity: string;
    public entity: INewAgentCallBatchServer;
    public getNSs = this.lookupSvc.createNSCacheImplementation();
    public getNS = async <
        NonSerializable extends INonSerializable = INonSerializable
    >(
        id: string
    ) => (await this.getNSs([id]))[0] as NonSerializable;

    campaignActionPickerHandler: ICampaignActionPicker;
    @ViewChild('actionPicker') actionPicker: CampaignActionPickerComponent;

    public constructor(
        private requester: GenericDashboardRequester,
        private activatedRoute: ActivatedRoute,
        private lookupSvc: LookupService,
        private location: Location,
        private dialogSvc: ColmeiaDialogService,


		private viewContainerRef: ViewContainerRef,

        @Optional() private colmeiaWindowRef: ColmeiaWindowRef<AttendanceActiveCallComponent>,
        @Optional() @Inject(MAT_DIALOG_DATA) private data: { idActiveBatch: string, refreshList: () => void },
        private attendanceActiveEditCallInitService: AttendanceActiveEditHelperService,
        private attendanceActiveEditCallAPISvc: AttendanceActiveEditCallApiService,
        private userSettings: UserSettingsService,
        private snackSvc: SnackMessageService,
    ) {
        super({
            [ESendActiveMethod.campaignOnly]:
                gTranslations.activeCall[ESendActiveMethod.campaignOnly],
            [ESendActiveMethod.customMessage]:
                gTranslations.activeCall[ESendActiveMethod.customMessage],
            [ESendActiveMethod.templateOnly]:
                gTranslations.activeCall[ESendActiveMethod.templateOnly],
        });
        this.attendanceActiveEditCallInitService.setComponents({
            dynamicDialog: DynamicDialogComponent,
            genericTable: GenericTableComponent,
            activeCallInfo: DashboardActiveCallInfoComponent,
        });
    }

    public get title(): string {
        return this.entity.nName;
    }

    public ngOnInit(): void {
        this.init();
    }

    public async init(): Promise<void> {
        this.loadDateTimeConversor();
        this.initIdEntity();
        await this.fetchEntity();
        this.initGenericEditHandler();
        this.initGenericHomeHandler();
        this.initCampaignActionPicker();
    }

    private async initCampaignActionPicker() { 
        const campaignList: TCMassCommActionArray = await this.attendanceActiveEditCallAPISvc.searchForAvailableCampaignList(undefined, true);

        this.campaignActionPickerHandler = {
            valueChange: (_, [act]) => {
                const masCommAction = act as ICMassCommunicationAction;
                if (isValidArray(masCommAction?.constants)) {
                    this.validateCampaignActionConstants(masCommAction);
                } 
                else {
                    this.entity.idCampaignAction = act?.idCampaigAction;
                    this.attendanceActiveEditCallAPISvc.saveBatch(this.entity);
                }
            },
            value: this.entity.idCampaignAction,
            filters: {
                actionFilter: (action: ICampaingActionHeader) => !!action.allowActionToActiveCall,
                campaignFilter: (campaign: ICampaignServer) => {
                    return campaign.campaingActions.some(action => !!action.allowActionToActiveCall)
                }
            },
            getCompatibleCampaignActions: () => this.attendanceActiveEditCallAPISvc.searchForAvailableCampaignList()
        }
    }

    validateCampaignActionConstants(masCommAction: ICMassCommunicationAction) {
        this.lookupSvc.getNSs<ENonSerializableObjectType.canonical, NsTypeToInterface[ENonSerializableObjectType.canonical]>(masCommAction.constants!.map(constant => constant.idLocalCanonical))
                    .then(nss => {
                        const prohibitedConstant = nss.some(ns => (isValidString(ns.globalCanonical) 
                        ? (ns.globalCanonical !== EMetadataNames.name && ns.globalCanonical !== EMetadataNames.cellular)
                        : false));
                        if (prohibitedConstant) {
                            this.snackSvc.openError('Este ação de campanha contém constantes que não correspondem aos Significados Globais de Nome e Celular. Favor selecione outra ação de campanha.');
                            this.entity.idCampaignAction = undefined;
                            this.attendanceActiveEditCallAPISvc.saveBatch(this.entity);
                            if (isValidRef(this.actionPicker)) this.actionPicker.selectecActionsNames = '';
                            return;
                        }
                        this.entity.idCampaignAction = masCommAction?.idCampaigAction;
                        this.attendanceActiveEditCallAPISvc.saveBatch(this.entity);
                        
                    })
                    .catch(err => this.snackSvc.openWarning('Ocorreu um erro durante a sua requisição. Favor contacte o suporte.'));
    }

    public get isColmeiaWindow(): boolean {
        return isValidRef(this.colmeiaWindowRef);
    }

    public async fetchEntity(): Promise<void> {
        this.entity = await this.getNS<INewAgentCallBatchServer>(this.idEntity);
    }

    public initIdEntity(): void {
        if (!isValidRef(this.colmeiaWindowRef)) {
            this.idEntity = this.activatedRoute.snapshot.paramMap.get(
                routeList.dashboard.children.serviceStatus.children.activeCall.children.details.routeParam.slice(
                    1
                )
            ) || '';
        } else {
            this.idEntity = this.data.idActiveBatch;
        }
    }

    public initGenericEditHandler(): void {
        this.genericEdit = new GenericDashboardEditHandler({
            title: this.title,
            nsType: this.nsTypeEntity,
            clientCallback: <IGenericDashboardEditPageClientCallback>this,
            moreOptions: {},
            nser: this.entity,
            customSaveButton: {
                text: "Enviar para todos",
                icon: "send",
            },
            removeSaveButton: true,
        });
    }

    public onGenericSaveButtonPressed = async (): Promise<void> => { };

    public async onGenericBackButtonPressed(): Promise<void> {
        if (isValidRef(this.colmeiaWindowRef)) {
            this.colmeiaWindowRef.close();
            this.data?.refreshList?.();
        } else {
            this.location.back();
        }
    }

    public columns = deprecatedSuggestions<{ [key in string]: string }>()({
        name: "Nome",
        sendMethod: "Metodo",
        target: "Alvo",
        sentAt: "Hora de envio",
    } as const).ok;

    public initGenericHomeHandler(): void {
        this.genericHome = new GenericHomeHandler({
            nsType: this.nsTypeList,
            skipTagFilter: true,
            defaultTag: EDefaultTag.serviceAttendent,
            fixedRowCallbacks: {
                edit: {
                    callback: (ns) => this.onEditClick(ns),
                },
                checkDependencies: true,
                delete: true,
                copyID: true,
            },
            fixedCallbacks: {
                create: {
                    callback: this.onCreateClick,
                },
            },
            labels: {
                title: "",
            },
            multiplePickAble: {
                callbacks: [
                    {
                        icon: "send",
                        text: "Enviar para selecionados",
                        callback: this.openMethodSelectorDialog,
                        type: window.innerWidth <= 600 ? 'icon' : 'stroked'
                    },
                ],
            },
            forcedTitles: values(this.columns),
            clientCallback: <IDashboardClientCallback>this,
            ordination: {
                allow: false
            },
        });
    }

    public openMethodSelectorDialog = async (nsers: INonSerializable[]) => {
        await this.attendanceActiveEditCallInitService.sendForContactsSelection({ idParent: this.idEntity, nsers: nsers as INewAgentCallContactServer[] });
        this.genericHome.refresh();
    }

    public onSelectItems = async (nsers: INonSerializable[]): Promise<void> => {
        const activeCalls = nsers as INewAgentCallContactServer[];
        if (activeCalls.some(ac => ac.isPartial)) {
            this.snackSvc.openError("Essa opção não pode ser usada em contatos parciais");
            return;
        }

        this.attendanceActiveEditCallAPISvc.sendSelectedContacts(
            nsers.map((nser) => nser.idNS)
        );

        if (this.isColmeiaWindow) {
            this.colmeiaWindowRef.close();
        } else {
            this.location.back();
        }
        this.snackSvc.openInfo("O disparo para os contatos selecionados foi iniciado");
    };

    public onEditClick = async (ns: INonSerializable): Promise<void> => {
        await this.openActiveCallInfoAndRefresh(
            {
                ...ns,
                name: ns.nName,
            } as INewAgentCallContactItem
        );
    };

    public async openActiveCallInfoAndRefresh(
        contactNS?: INewAgentCallContactItem
    ): Promise<void> {
        await this.attendanceActiveEditCallInitService.openActiveCallInfo(
            { idParentContactList: this.idEntity, contactNS, contactList: undefined, initialSearchInputValue: undefined, parentContactListIdCampaignAction: this.entity.idCampaignAction, refreshListCallback: () => this.genericHome.refresh(), sendToAllWithCampaignActionId: undefined, directToActiveCall: true },
        ).promise;
        this.genericHome.refresh();
    }

    public onCreateClick = async (): Promise<void> => {
        await this.openActiveCallInfoAndRefresh();
    };

    public getRequest({
        taggable,
    }: IGenericDashboardRequestParameters): IRequest {
        return this.requester.getRequestForNS({
            apiRequestType: apiRequestType.nonSerializable.list,
            nsType: this.nsTypeList,
            idParent: this.idEntity,
            cursor: null,
            taggable,
        });
    }

    public loadDateTimeConversor(): void {
        this.dateTimeConversor = this.userSettings.getDatetimeConversor();
    }

    public mapEntity = (nser: INewAgentCallContactServer): IGenericHomeRow => {
        const columns: IGenericDashboardTextColumn[] = [
            <IGenericDashboardTextColumn>{
                type: EGenericDashboardColumnType.Text,
                value: nser.nName,
                title: this.columns.name,
                isItemName: true,
            },
            <IGenericDashboardTextColumn>{
                type: EGenericDashboardColumnType.Text,
                value: nser.sendMethod ? this.translations[nser.sendMethod].value : '',
                title: this.columns.sendMethod,
            },
            <IGenericDashboardTextColumn>{
                type: EGenericDashboardColumnType.Text,
                value: nser.target,
                title: this.columns.target,
            },
            <IGenericDashboardTextColumn>{
                type: EGenericDashboardColumnType.Text,
                value: this.dateTimeConversor.timeToDateTimeString(
                    getIfNotValid(nser.scheduleBeginning, nser.sentAt)
                ),
                title: this.columns.sentAt,
            },
        ];

        return {
            nser,
            columns,
        };
    };
}
