import { Component, EventEmitter, Input, Output } from '@angular/core';

export enum EAttendanceChannelStatusTypeFilter {
    Active = 'Active',
    ChannelClosed = 'ChannelClosed'
}

export type TSelectedAttendanceChannelStatusType = EAttendanceChannelStatusTypeFilter | null;

@Component({
    selector: 'app-attendance-type-filters',
    templateUrl: './attendance-type-filters.component.html',
    styleUrl: './attendance-type-filters.component.scss'
})
export class AttendanceTypeFiltersComponent {
    selectedFilter: TSelectedAttendanceChannelStatusType = null;

    @Output()
    filterSelected = new EventEmitter<TSelectedAttendanceChannelStatusType>();

    @Input()
    channelClosedCount: number;

    EAttendanceChannelStatusTypeFilter = EAttendanceChannelStatusTypeFilter;

    isFilterActive(type: TSelectedAttendanceChannelStatusType): boolean {
        return this.selectedFilter === type;
    }

    selectFilter(type: TSelectedAttendanceChannelStatusType){
        this.selectedFilter = type;
        this.filterSelected.emit(type);
    }
}
