import { ROUTES } from '@colmeia/core/src/core-constants/routes-shared';
import { minToMS, secToMS } from '@colmeia/core/src/time/time-utl';
import { environment } from 'environments/environment-client';
import { authenticationKey, uberCacheKey } from './client.keys';
import * as _ from 'lodash';

function getClientConstants() {
    const clientConstants = {
        delays: {
            daemonDeliveryMessage: secToMS(2),
            refreshUI: 100,
        },
        global: {
            fixes: {
                router: {
                    router: '_ColmeiaRouterManagerService_',
                    zone: '_ColmeiaRouterManagerZone_'
                }
            }
        },
        error: {
            maxTimeToClearGlobalErrorHandlerDB: minToMS(10),
            maxSendToServerLogTimeInterval: 5000
        },
        debug: {
            on: true,
        },
        nullStr: '[NULL]',
        maps: {
            location: {
                geoDirectives: {
                    fetchPointsSize: 500,
                    minimumAccuracy: 20,
                    minimunDistanceBetweenPoints: 5,
                },
                lastHoursLocationToRetrieve: 5,
            },
            google: {
                apiKey: environment.googleMapsApiKey,
                zoom: 14
            },
            others: {
                gpsTimeout: 30000
            }
        },
        pushNotification: {
            publicKey: 'BPJvulyRYF2tK2OkEednPqxb32csc1H-ej6ZfYVgBFc4FoPkL81tzMf9i2XkKjZgZLZKQd9GOSLpWxN_Dt48i-E'
        },
        notification: {
            minimumIntervalBetweenSounds: minToMS(0.5),
            notAnsweredAttendanceSoundPath: "/assets/audio/notifications/unfocused/ping.wav",
            newAttendanceSoundPath: "/assets/audio/notifications/unfocused/plucky.ogg",
            availableAttInactivitySounds: [
                "/assets/audio/notifications/custom-alerts/sound-1.mp3",
                "/assets/audio/notifications/custom-alerts/sound-2.mp3",
                "/assets/audio/notifications/custom-alerts/sound-3.mp3",
                "/assets/audio/notifications/custom-alerts/sound-4.mp3",
            ],
        },
        maxBusinessID: Number.MAX_SAFE_INTEGER,
        maxParticipantsPerGroup: 2500,
        tracker: {
            typingTracker: {
                msToResendTypingTracker: 1500,
                msSendTyAfterStopTyping: 3000,
            },
        },
        ROOT_URL: environment.ROOT_URL,
        SERVER_URL: environment.ROOT_URL + ROUTES.v1.business,
        VOICE_URL: environment.ROOT_URL + ROUTES.v1.files,
        FILE_URL: environment.ROOT_URL + ROUTES.v1.files,
        MULTIMEDIA_URL: environment.ROOT_URL + ROUTES.v1.streaming,
        UI: {
            sizeForThreeDots: 15,
            typingDurationEvent: 1000 * 1.5,
            smoothScrollFeeling: 1000 * 0.025,
            message: {
                transcription: {
                    maxCharacters: 100
                }
            },
            group: {
                description: {
                    maxCharacters: 120
                }
            },
            maxInterationTextLength: 2000,
            maxInterationTextLengthMeta: 4096,
            phoneMask: '+00 (00) 0 0000-0000',
        },
        searchDelayTimeMS: 300,
        multimedia: {
            mobile: {
                camera: {
                    quality: 65
                }
            },
            maxDisplayedMediaNumber: 4,
            maxNumberOFUploadFile: 15,
            maxRecordingTime: 120,
            thumbnail: {
                maxWidth: 250,
                maxHeight: 250,
            }
        },
        loadGroups: {
            numberOfInteractionsPerCharge: 15,
        },
        forms: {
            maxNumberOfChars: 255
        },
        gorillaSuervey: {
            maxElements: 20
        },
        recorder: {
            useOnlyPolyfill: true,
        },
        authentication: {
            storageKey: authenticationKey
        },
        storage: {
            commonKeys: {
                uberCache: uberCacheKey,
                rateLimitBlockExpiration: '_rateLimitBlockExpiration',
                attendanceMacros: 'AttendanceMacros'
            }
        },
        firebase: {
            settings: {
                apiKey: "AIzaSyBE8ISMRsrTf1UaNGCu5LM0fjDXzyHzGOY",
                authDomain: "colmeia-notification.firebaseapp.com",
                databaseURL: "https://colmeia-notification.firebaseio.com",
                projectId: "colmeia-notification",
                storageBucket: "",
                messagingSenderId: "795840225823",// WHEN UPDATE THIS CHANGE ALSO: src/assets/sw/colmeia-sw.js
                appId: "1:795840225823:web:6fd23d8681e2cefa",
                measurementId: "G-K83LNWEGRY"
            },
            vapidKey: 'BFk27W8jas9FCmWR3E5fJ1lTOsn31o0NeLyjwi8olLh0rNULuHvvVRC53O7eCSrDI7eXdR4RjAaAc5XJZp0kKcE'
        }
    };

    return clientConstants;
}

export const clientConstants = getClientConstants();


export function refreshClientConstants(): void {
    _.merge(clientConstants, getClientConstants());
}
