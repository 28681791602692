import { EAllSerializable, getTranslationsFields } from "../all-serializables";
import { typedScreenOptions } from "../../../tools/utility";

export const clientErrorsScreenOptions = typedScreenOptions({
    idSerializable: EAllSerializable.ClientErrors,
    fields: {
        fullfillFallBackMessage: 1, // Preencha a mensagem de fallback
        youCantWriteInsideAVariable: 2, // Você não pode escrever dentro de uma variável.
        youDontHaveAccessToAnyMenu: 3, // Você não tem acesso a nenhum menu
        dynamicIsland: 4, // Você não tem acesso a nenhum menu
    }
});

export const clientErrorTranslations = getTranslationsFields(clientErrorsScreenOptions);
