import { Injectable } from '@angular/core';
import { ColmeiaDialogService } from './dialog/dialog.service';
import { IChatFilterData } from 'app/model/chat-filter.model';
import { EDynamicMatch, IFilterlet } from '@colmeia/core/src/rules/dynamic-filter/dynamic-filter-interfaces';
import { ChatBackboneModel } from 'app/model/chat-backbone.model';
import { ChatFilterModalComponent } from 'app/components/chat-filter/chat-filter-modal/chat-filter-modal.component';
import { isValidRef } from '@colmeia/core/src/tools/utility';

@Injectable({
	providedIn: 'root'
})
export class ChatFilterService {
	
	constructor(
		private dialogSvc: ColmeiaDialogService
	) { }

	addFilter() {
		const filterlet =  {
			match: EDynamicMatch.any,
			positiveMatch: true,
		};
		this.openFilterModal({
			filterlet,
			canShowInterval: this.checkIfCanShowDate(filterlet),
			onFilterApply: (filterlet: IFilterlet) => {
				if (hasAnyFilter(filterlet)) {
					ChatBackboneModel.getActiveBackBoneModel().addFilter(filterlet);
				}
			}
		});
	}


	reloadFiltersAndBlockScrollDown(): Promise<void> {
		return ChatBackboneModel.getActiveBackBoneModel().reloadFiltersAndBlockScrollDown()
	}


	editFilter(filterlet: IFilterlet, callback?: Function) {
		this.openFilterModal({
			filterlet,
			canShowInterval: this.checkIfCanShowDate(filterlet),
			onFilterApply: (filterlet: IFilterlet) => {
				if (!hasAnyFilter(filterlet)) {
					ChatBackboneModel.getActiveBackBoneModel().removeFilter(filterlet);
				} else if (callback){
					callback(filterlet);
				}
				ChatBackboneModel.getActiveBackBoneModel().applyFilters();
			}
		})
	}

	openFilterModal(data: IChatFilterData) {
		this.dialogSvc.open({
			componentRef: ChatFilterModalComponent,
			hideHeader: true,
			dataToComponent: {
				data
			}
		});
	}

	removeFilter(filterlet: IFilterlet) {
		ChatBackboneModel.getActiveBackBoneModel().removeFilter(filterlet);
	}

	checkIfCanShowDate(filterlet: IFilterlet): boolean {
		if (isValidRef(filterlet.interval) && hasValue(filterlet.interval)) {
			return true;
		}

		const filters = ChatBackboneModel.getActiveBackBoneModel().getAppliedFilters();
		return !filters.map(filter => filter.interval).filter(isValidRef).some(hasValue);
	}
}

function hasAnyFilter(filterlet: IFilterlet): boolean {
	const { avatarList = [], tasks = {}, interval = {}, feedbacks = [], interactionTypes = [] } = filterlet;
	return [avatarList, tasks, interval, feedbacks, interactionTypes].some(hasValue) || filterlet.match !== EDynamicMatch.any;
}

function hasValue(source: Array<any> | Object): boolean {
	if (Array.isArray(source)) return source.length > 0;
	return Object.values(source).filter(Boolean).length > 0;
}