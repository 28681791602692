import { Component, OnInit } from '@angular/core';
import { Interaction } from '@colmeia/core/src/interaction/interaction';
import { EMetadataNames } from '@colmeia/core/src/shared-business-rules/metadata/metadata-db';
import { TComputedInfo } from '@colmeia/core/src/shared-business-rules/metadata/metadata-utils';
import { AttendantDynamicListRegistrationHandler } from 'app/handlers/chat-right-side-bar/attendant-dynamic-list-registration.handler';
import { IFormSchemmaAndEngagement, IFormSchemmaAndEngagementWithMedia } from 'app/model/chat-options.model';
import { AttendanceAnnotationsService } from 'app/services/attendance-annotations.service';
import { AttendanceService } from 'app/services/attendance.service';
import { SessionService } from 'app/services/session.service';

@Component({
    selector: 'app-chat-options-dynamic-list-registration',
    templateUrl: './chat-options-dynamic-list-registration.component.html',
    styleUrls: ['./chat-options-dynamic-list-registration.component.scss']
})
export class ChatOptionsDynamicListRegistrationComponent implements OnInit {

    metadatas: IFormSchemmaAndEngagementWithMedia[]

    constructor(
        private attendantRegistrationHandler: AttendantDynamicListRegistrationHandler,
        private attendanceService: AttendanceService,
        private session: SessionService,
        private attAnnotationsSvc: AttendanceAnnotationsService,
    ) { }

    ngOnInit() {

        this.metadatas = this.attendantRegistrationHandler.getMetadatas() || [];

        console.log({ metadatas: this.metadatas });
    }
    
    async openAnnotations(metadata: IFormSchemmaAndEngagement, $event: PointerEvent) {
        $event.stopPropagation();

        const idGroup: string = this.session.getSelectedGroupID();
        const idInteraction: string = this.attendanceService.getReplyInteractionForGroup(idGroup);
        const interaction: Interaction = Interaction.staticFactory(idInteraction);
        const groupName: string = `Lista: ${this.getClientName(interaction)}`;

        this.attAnnotationsSvc.initAnnotation({
            idGroup,
            idInteraction,
            metadata,
            groupName,
            annotationsParameters: {
                targetDatabaseId: metadata.metaRegister.isDynamicList && metadata.metaRegister.idDatabase,
                onSaveAnnotationCallback: () => {
                    console.log({ interaction, idGroup });
                },
            }
        });
    }

    private getClientName(interaction: Interaction): string {
        const allComputedValues: TComputedInfo = this.attendanceService.getAllComputedInfoForAttendance(interaction.getGroupID());
        const clientName: string = allComputedValues[EMetadataNames.name] || allComputedValues[EMetadataNames.customerProvideName];

        return clientName
    }

}
