<div class="config-inputs">

  <div *ngIf="isBotRoot" class="root-itens">
    <mat-form-field appearance="fill">
      <mat-label>Incrementar por:</mat-label>
      <mat-select [(value)]="$botRoot(parameters.botElement).incrementor">
        <mat-option [value]="incrementorOptions.letter">
          Letras
        </mat-option>
        <mat-option [value]="incrementorOptions.numeric">
          Números
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field appearance="fill" *ngIf="isNumericMenu">
      <mat-label>Exibir números no Whatsapp</mat-label>
      <mat-select [(ngModel)]="numericWhatsappMode">
        <mat-option [value]="numericWhatsappModeEnum.Normal">
          Números
        </mat-option>
        <mat-option [value]="numericWhatsappModeEnum.Enhanced">
          Emojis
        </mat-option>
      </mat-select>
    </mat-form-field>

    <div class="voice-config__wrapper">
      <mat-form-field appearance="fill" class="no-padding-bottom">
        <mat-label>Configuração transcrição de voz</mat-label>
        <mat-select [(ngModel)]="voiceGeneratorConfig">
          <mat-option *ngFor="let option of voiceOptionTranslations | keyvalue" [value]="option.key">
            {{option.value}}
          </mat-option>
        </mat-select>
      </mat-form-field>


      <mat-checkbox
        *ngIf="canShowIgnoreEmojis()"
        class="include-analytics-checkbox boxed boxed-14"
        [(ngModel)]="ignoreEmojis"
        color="primary"
      >
        Ignorar emojis
      </mat-checkbox>
    </div>

    <app-ns-picker style="width: 100%"
      *ngIf="canShowIgnoreEmojis() && userFunctionPicker"
      [handler]="userFunctionPicker"
      >
    </app-ns-picker>
  </div>

  <mat-form-field appearance="fill" *ngIf="canShowContent" id="botTitleToShow">
    <mat-label>Texto à exibir</mat-label>

    <span matPrefix>
      <mat-icon
        *ngIf="hasAdvancedTitleConfigured"
        [@horizontalAppear]="'in'"
        style="margin-right: 12px;color: var(--alert-900);"
        matTooltip="Com um título avançado configurado, essa opção será ignorada."
        matTooltipClass="mat-tooltip-warning"
        appHighlightEl="#botAdvancedTitle"
        >info</mat-icon>
    </span>

    <input [(ngModel)]="content" matInput type="text" name="content">
    <span matSuffix>
      <button mat-icon-button (click)="openTextToShowValidation()">
        <mat-icon>pivot_table_chart</mat-icon>
      </button>
    </span>
  </mat-form-field>


  <ng-container *ngIf="isMenuContainer">
    <cm-render-options
    *ngIf="renderOptionsHandler"
    [handler]="renderOptionsHandler"
    ></cm-render-options>
    <app-alert
    type="warning"
    dense
    *ngIf="menuPreviewAlert">
      {{ menuPreviewAlert }}
  </app-alert>
  </ng-container>

</div>

<div class="right-container">

  <ng-container *ngIf="isMenuContainer">
    <mat-form-field appearance="fill" class="full-width-field">
      <mat-label>{{ translations.actionOnComplete }}</mat-label>
      <mat-select [(ngModel)]="actionOnComplete">
        <mat-option *ngFor="let item of actionsOnComplete" [value]="item">{{ translations['onComplete'+item] }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </ng-container>

  <ng-container *ngIf="isMetadata">
    <app-lookup-select *ngIf="isMetadata && lookupSchemaHandler" [handler]="lookupSchemaHandler"></app-lookup-select>
    <mat-form-field appearance="fill">
      <mat-label>{{ translations.timeToReuseInformation }}</mat-label>
      <input matInput [(ngModel)]="reuseInfoExpireBHour" type="number" min="0" max="24">
    </mat-form-field>
  </ng-container>

  <ng-container *ngIf="isItemMenu">
    <div class="item__menu__fields">
      <mat-form-field appearance="fill">
        <span matSuffix>
          <button mat-icon-button (click)="openSectionNameValidation()">
            <mat-icon>pivot_table_chart</mat-icon>
          </button>
        </span>
        <mat-label>Nome da seção</mat-label>
        <input [(ngModel)]="parameters.botElement.section" matInput type="text" name="section">
      </mat-form-field>
      <mat-form-field appearance="fill">
        <span matSuffix>
          <button mat-icon-button (click)="openSectionDescriptionValidation()">
            <mat-icon>pivot_table_chart</mat-icon>
          </button>
        </span>
        <mat-label>Descrição do item</mat-label>
        <input [(ngModel)]="parameters.botElement.description" matInput type="text" name="section">
      </mat-form-field>

    </div>

    <button mat-stroked-button color="primary" class="button-w-icon" style="width: 100%;" (click)="openBotSubject()">
      <mat-icon>title</mat-icon>
      {{ translations.subject }}
    </button>
  </ng-container>

  <mat-checkbox class="include-analytics-checkbox boxed boxed-14" [(ngModel)]="parameters.botElement.includeOnAnalytics"
    color="primary">
    Incluir como tráfego no Analytics
  </mat-checkbox>

  <ng-container *ngIf="isMenuContainer">
    <switch-menu-template [handler]="switchMenuTemplateHandler"></switch-menu-template>
  </ng-container>

  <ng-container *ngIf="isItemMenu">
    <mat-card style="margin: 4px 2px;">
      <mat-checkbox class="boxed boxed-14" color="primary" [(ngModel)]="trackRuleExist">
        Trackeamento
      </mat-checkbox>
      <div *ngIf="parameters.botElement.trackerRule" style="display: flex; gap: 16px;">
        <choice-tracker [handler]="naviTrackerHandler" (naviTrackerChange)="handleNaviTrackerChange($event)"
          [parameterInputTitle]="'Parâmetro do Trackeamento'">
        </choice-tracker>
      </div>
      <!-- tracker -->
    </mat-card>

    <mat-card *ngIf="canHavePreFunction">
      <app-conditional-expansion-panel class="pre-function-cond-panel" [active]="hasPreFunction"
        (activeChange)="handlePreFunctionActive($event)" label="Executar pré função">
        <mat-card class="highlighted info-message">
          <mat-icon>info</mat-icon>
          <div>Esta função será executada antes da ação principal!</div>
        </mat-card>
        <app-ns-picker *ngIf="preFunctionPickerHandler" [handler]="preFunctionPickerHandler"></app-ns-picker>
        <mat-form-field *ngIf="this.botMenuItem.track?.idPreFunction" appearance="fill">
          <mat-label>Parâmetro pré-função</mat-label>
          <input matInput [(ngModel)]="botMenuItem.track.parameter" />
        </mat-form-field>
      </app-conditional-expansion-panel>
    </mat-card>
  </ng-container>
</div>
