import { Component, OnInit, Input, ChangeDetectorRef, ChangeDetectionStrategy, ViewChild } from '@angular/core';
import { RootComponent } from 'app/components/foundation/root/root.component';
import { TColmeiaTagArray, ETagType, IServerColmeiaTag } from '@colmeia/core/src/shared-business-rules/colmeia-tags/tags';
import { isValidRef, isValidArray } from '@colmeia/core/src/tools/utility';
import { TagPickerHandler, ITagPickerHandlerParameter, ITagPickerHandlerClientCallback, ETagPickerHandlerMode } from 'app/handlers/tag-picker-handler';
import { getVariablesWithDelimeters } from '@colmeia/core/src/shared-business-rules/metadata/metadata-utils';
import { pickTranslations } from '@colmeia/core/src/shared-business-rules/const-text/all-serializables';
import { allCommonScreenOptionsTranslations } from '@colmeia/core/src/shared-business-rules/const-text/views/txt-common';
import { MatTable } from '@angular/material/table';
import { TIVariablesArray } from '@colmeia/core/src/shared-business-rules/metadata/metadata-util-interfaces';
import { VariablesService } from 'app/services/variables.service';
import { AttendantResourcesService } from 'app/services/attendant-rources.service';

export interface IVariableInputHandler {
	selectedVariableTags: TColmeiaTagArray;
	onUpdateSelectedVariables(): void;
	canCopyVariable?: boolean;
    syncWithVariablesService?: boolean;
    borderless?: boolean; //<< Changes the border and the base design
    smallTag?: boolean;
	smallSize?: boolean; //<< Changes the size of the tags to a smaller version
}
@Component({
	changeDetection: ChangeDetectionStrategy.OnPush,
	selector: 'app-variable-tags',
	templateUrl: './variable-tags.component.html',
	styleUrls: ['./variable-tags.component.scss']
})
export class VariableTagsComponent extends RootComponent<
	'name'|
	'values'|
	'variables'|
	'actions'
> implements OnInit, ITagPickerHandlerClientCallback {


	_handler: IVariableInputHandler

	@Input()
	set handler(value: IVariableInputHandler) {
		this._handler = value;
		this.init();
	}
	get handler(): IVariableInputHandler { return this._handler }

	tagPickerHandler: TagPickerHandler;

	possibleVariables: TIVariablesArray;

	displayedColumns = ['name', 'value', 'actions'];

	@ViewChild(MatTable) table: MatTable<TColmeiaTagArray>;

	constructor(
		private cdr: ChangeDetectorRef,
        private variablesSvc: VariablesService,
		private attendantRsourcesSvc: AttendantResourcesService
		) {
		super({
			...pickTranslations(allCommonScreenOptionsTranslations, [
				'name',
				'values',
				'variables',
				'actions',
			]),
		});
	}

	init() {
		this.initTagPickerHandler();
		this.resetVariables();
        this.updataVariablesService();
	}

	private resetTagTable() {
		if(isValidRef(this.table)){
			this.resetVariables();
			this.table.renderRows();
			this.cdr.markForCheck();
		}
	}

	public get selectedVariableTags(): IServerColmeiaTag[] {
		return this.handler.selectedVariableTags;
	}

	private resetVariables(): void {
		const schemaProperties: TIVariablesArray = [];

		if(isValidArray(this.selectedVariableTags)){
			this.selectedVariableTags
				.map(varTag => {
					schemaProperties.push({
						idProperty: varTag.idNS || '',
						text: varTag.nName,
						value: varTag.value,
					});
				});
		}

		this.possibleVariables = getVariablesWithDelimeters(schemaProperties);
		this.cdr.markForCheck();
	}

	get canCopyVariable(): boolean {
		return !!this.handler.canCopyVariable;
	}

	copyVariable(tag: IServerColmeiaTag): void {
		const variable = getVariablesWithDelimeters([{
			idProperty: tag.idNS || '',
			text: tag.nName,
			value: tag.value,
		}])[0];
		const text = `${variable.text} `;
        const input = document.createElement('input');
        input.value = text;
        document.body.appendChild(input);
        input.focus();
        input.select();
        document.execCommand('copy');
        document.body.removeChild(input);
	}

	async removeTag(selectedTag: IServerColmeiaTag): Promise<void> {
		const deletionTitle = `Remover Variável${selectedTag.nName ? ": '" + selectedTag.nName + "'" : ''}?` 
		const comfirmedDeletion = await this.attendantRsourcesSvc.confirmDeletion(deletionTitle);

		if (!comfirmedDeletion) return;

		const idx: number = this.selectedVariableTags.findIndex(tag => tag.idNS === selectedTag.idNS);

		if(idx !== -1){
			this.selectedVariableTags.splice(idx, 1);
            this.updataVariablesService();
			this.handler.onUpdateSelectedVariables();
			this.resetTagTable();
		}

		this.tagPickerHandler.setSelectedTags([...this.selectedVariableTags]);

		this.cdr.markForCheck();
	}


	initTagPickerHandler(): void {
		const parameter: ITagPickerHandlerParameter = {
			tagType: ETagType.variable,
            smallTag: this.handler?.smallTag,
			smallSize: this.handler?.smallSize,
			mode: ETagPickerHandlerMode.edit,
			selectedTags: [...this.selectedVariableTags],
			clientCallback: this,
		};

		this.tagPickerHandler = new TagPickerHandler(parameter);
	}

	onCancel(): void {
		this.tagPickerHandler.setSelectedTags([...this.selectedVariableTags]);

		this.cdr.markForCheck();
	}


	onFinishSelectionCallback() {

		this.selectedVariableTags.splice(0, Number.MAX_VALUE, ...this.tagPickerHandler.getSelectedTags());

        this.updataVariablesService();

		this.resetTagTable();
		this.handler.onUpdateSelectedVariables();

		this.cdr.markForCheck();
	}

    private updataVariablesService() {
        if(this.handler.syncWithVariablesService) {
            this.variablesSvc.setSelectedVariables(this.selectedVariableTags);
        }
    }

	public get smallSizeClass() {
		return this.isSmallSize ? 'small-size' : '';
	}

	public get isSmallSize() {
		return this.handler?.smallSize;
	}

    public get hideBorderClass() {
        return this.handler?.borderless
            ? 'hide-borders'
            : '';
    }

    public get shouldRenderBorderless() {
        return this.hideBorderClass !== '';
    }
}
