import { isValidNumber, typedClone } from "../../../tools/utility";
import { ENextGenBotElementType } from "../../bot/bot-model";
import { EBPMType } from "../../BPM/bpm-model";
import { IToolbarElement, TReferencedObject } from "../../graph-transaction/toolbar/config-toolbar.types";
import { ENonSerializableObjectType, INonSerializable } from "../../non-serializable-id/non-serializable-id-interfaces";
import { BasicElement } from "./basic-element";
import { IGraphElementOffset, IGraphElementRenderData } from "./diagram.interfaces";
import { TIBasicElementServerArray } from "./graph-basic-element-interfaces";
import { graphElementContainerSize, graphElementsMarginPX } from "./graph-constants";
import { GraphElement } from "./graph-element";
import { IBasicElementJSON } from "./graph-interfaces";
import { EGraphElementType } from "./graph-types";


type TDefineDiagramTemplate = (renderData: IGraphElementRenderData, graphElement: GraphElement) => string | number;
type TInputDefineDiagramTemplate = (graphElement: GraphElement) => string | number;

function defineDiagramTemplate<Events>(content: TemplateStringsArray, ...definitions: TDefineDiagramTemplate[]) {
    return (graphElement: GraphElement) => content.map((item: string, index: number) => `${item}${definitions[index](graphElement.getRenderData(), graphElement)}`).join('');
}

const htmlDefinition: TDefineDiagramTemplate = (renderData: IGraphElementRenderData) => `
    <div width="${renderData.width}">
        text: ${renderData.id}
    </div>
`;

const bot = defineDiagramTemplate`
    <div width="${renderData => renderData.width}">
        text: ${renderData => renderData.id}
    </div>
`


enum GraphHTMLElements {
    Div = 'div',
    Text = 'span',
}

export type TDiagramTypeDefinitions = { [key in TReferencedObject]?: TInputDefineDiagramTemplate };
export type TDiagramDefinitions = { [key in EBPMType]?: TDiagramTypeDefinitions };

const dbConfig: TDiagramDefinitions = {
    [EBPMType.bot]: {
        [ENextGenBotElementType.root]: bot,
        [ENextGenBotElementType.menuContainer]: bot,
        [ENextGenBotElementType.botMenuItem]: bot,
    },
};


export function isBasicElement(element: any): element is BasicElement {
    return 'getState' in element;
}

export function isGraphElement(element: BasicElement): element is GraphElement {
    const graphElementTypes: EGraphElementType[] = [
        EGraphElementType.root,
        EGraphElementType.node,
    ];
    return isBasicElement(element) && graphElementTypes.includes(element.getElementType());
}

export function isToolbarElement(element: GraphElement | IToolbarElement): element is IToolbarElement {
    return 'groupType' in element;
}

export function isGraphElementNS(ns: INonSerializable): boolean {
    return ns.nsType === ENonSerializableObjectType.graphElement;
}

type TGraphMatrix = IGraphElementRenderData[][];

function getInitialMatrix(): TGraphMatrix {
    const matrix: TGraphMatrix = [
        [null, null, null],
        [null, null, null],
        [null, null, null],
    ];
    return matrix;
}


function expandMatrix(matrix: TGraphMatrix): TGraphMatrix {
    matrix = typedClone(matrix);
    const lineBasis = [...matrix[0]];
    matrix.splice(0, 0, [...lineBasis]);
    matrix.push([...lineBasis]);

    return matrix.map(l => {
        const n = [...l];
        n.splice(0, 0, null);
        n.push(null);
        return n
    });
}

export function findNewOffsetsForGraphNode(
    renderData: IGraphElementRenderData,
    allGraphs: TIBasicElementServerArray,
): IGraphElementRenderData {
    return findOffsetsFor(
        renderData,
        allGraphs.map(node => node.element).filter(node => node.elementType != EGraphElementType.predicate),
    )
}

export function findOffsetsFor(renderData: IGraphElementRenderData, allGraphs: IBasicElementJSON[]): IGraphElementRenderData {
    let position: IGraphElementRenderData;
    const width = renderData.width;
    const height = renderData.height;
    const offsetX: number = renderData.offsetX + (width / 2) + graphElementsMarginPX;

    let offsetY: number = renderData.offsetY + (height + graphElementsMarginPX);

    do {
        const nodeInCoord = findNodeInCord({ ...renderData ,offsetX, offsetY: offsetY }, allGraphs);

        if (!nodeInCoord) {
            position = { id: renderData.id, width, height, offsetX, offsetY: offsetY };
        } else {
            offsetY += (nodeInCoord.renderData.height + graphElementsMarginPX);
        }
    } while (!position)

    return position;
}

export function findOffsetsForGrid(renderData: IGraphElementRenderData, allGraphs: IBasicElementJSON[], matrix = getInitialMatrix()): IGraphElementRenderData {
    let position: IGraphElementRenderData;

    do {
        matrix = matrixToOffset(matrix, renderData);

        for (let line = 0; line < matrix.length; ++line) {
            for (let column = 0; column < matrix[line].length; ++column) {
                const freeSpace = !findNodeInCord(matrix[line][column], allGraphs)
                if (freeSpace) {
                    position = matrix[line][column];
                    break;
                }
            }
            if (position) break;
        }

        if (!position) {
            matrix = expandMatrix(matrix);
        }

    } while (!position)

    return position;

}

export function findOffsetsForSequecialElemet(graph: IGraphElementRenderData, allGraphs: IBasicElementJSON[], isSequencial: boolean): IGraphElementRenderData {
    return;
}

function findNodeInCord(offset: IGraphElementRenderData, allGraphs: IBasicElementJSON[]): IBasicElementJSON {
    return allGraphs.find(graph => {
        return isInsideNode(offset, graph)
    });
}

function isInsideNode(toCheck: IGraphElementRenderData, target: IBasicElementJSON): boolean {
    const {
        top: targetTopOffset,
        right: targetRightOffset,
        bottom: targetBottomOffset,
        left: targetLeftOffset
    }: IElementRect = getRectOffsets(target.renderData);
    const {
        topLeft, topRight,
        bottomRight, bottomLeft,
    }: IElementRect = getRectOffsets(toCheck);

    const targetOffsets: number[] = [targetTopOffset, targetRightOffset, targetBottomOffset, targetLeftOffset];

    return (
        isInnterPoint(toCheck, targetOffsets) ||
        isInnterPoint(topLeft, targetOffsets) ||
        isInnterPoint(topRight, targetOffsets) ||
        isInnterPoint(bottomRight, targetOffsets) ||
        isInnterPoint(bottomLeft, targetOffsets)
    );
}

interface IElementRect {
    top: number;
    right: number;
    bottom: number;
    left: number;
    topLeft: IGraphElementOffset,
    topRight: IGraphElementOffset,
    bottomRight: IGraphElementOffset,
    bottomLeft: IGraphElementOffset,
}

function getRectOffsets(renderData: IGraphElementRenderData): IElementRect {
    const { offsetX, offsetY, width, height } = renderData;
    const widthHalf: number = width / 2;
    const heightHalf: number = height / 2;
    const top: number = offsetY - heightHalf;
    const bottom: number = offsetY + heightHalf;
    const left: number = offsetX - widthHalf;
    const right: number = offsetX + widthHalf;
    const topLeft: IGraphElementOffset = { offsetX: left, offsetY: top };
    const topRight: IGraphElementOffset = { offsetX: right, offsetY: top };
    const bottomRight: IGraphElementOffset = { offsetX: right, offsetY: bottom };
    const bottomLeft: IGraphElementOffset = { offsetX: left, offsetY: bottom };

    return {
        top, right, bottom, left, topLeft, topRight, bottomRight, bottomLeft,
    }
}

function isInnterPoint(source: IGraphElementOffset, [top, right, bottom, left]: number[]): boolean {
    return (
        ((source.offsetX >= left) && (source.offsetX <= right)) &&
        ((source.offsetY >= top) && (source.offsetY <= bottom))
    );
}

function getMatrixCenterIndex(matrix: unknown[][]): number {
    const linesCount: number = matrix.length;
    const center = (linesCount - 1) / 2;

    return center;
}

function matrixToOffset(matrix: TGraphMatrix, center: IGraphElementRenderData): TGraphMatrix {
    const matrixCenterIndex: number = getMatrixCenterIndex(matrix);

    return matrix.map((l, lineIndex) => {
        const isVerticallAfter: boolean = lineIndex > matrixCenterIndex;
        return l.map((c, columnIndex) => {
            if (c) return c;

            const isHorizontalAfter: boolean = columnIndex > matrixCenterIndex;

            const horizontalIndexD = Math.max(matrixCenterIndex, columnIndex) - Math.min(matrixCenterIndex, columnIndex)
            const verticalIndexD /* xD */ = Math.max(matrixCenterIndex, lineIndex) - Math.min(matrixCenterIndex, lineIndex);
            const horizontalD = (center.width + graphElementsMarginPX) * horizontalIndexD;
            const verticalD = (center.height + graphElementsMarginPX) * verticalIndexD;

            const offsetX: number = columnIndex === matrixCenterIndex
                ? center.offsetX
                : center.offsetX + (isHorizontalAfter ? horizontalD : horizontalD * -1);

            const offsetY = lineIndex === matrixCenterIndex
                ? center.offsetY
                : center.offsetY + (isVerticallAfter ? verticalD : verticalD * -1);

            return { ...c, ...graphElementContainerSize, offsetX, offsetY }
        })
    });
}
