import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { AfterViewInit, Component, EventEmitter, Inject, InjectionToken, Input, OnChanges, OnInit, Optional, Output, QueryList, TemplateRef, ViewChild, ViewChildren, ViewContainerRef } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatExpansionPanel } from '@angular/material/expansion';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { MatSnackBar } from '@angular/material/snack-bar';
import { constant } from '@colmeia/core/src/business/constant';
import { EObjectType } from '@colmeia/core/src/business/constant.enums';
import { TArrayID, TExtendedParticipant, TExtendedParticipantArray, TGlobalUID } from '@colmeia/core/src/core-constants/types';
import { SchemaProperty, TSchemaPropertyArray } from "@colmeia/core/src/general-form/general-form-interface";
import { IBotActionAsset } from "@colmeia/core/src/shared-business-rules/bot/bot-action-model";
import { IBasicAsset, KBAssetType, KBAssetTypeClientOnly } from "@colmeia/core/src/shared-business-rules/bot/bot-asset-model";
import {
    EBotContentEvent,
    IContentBasicAsset,
    TContentAssetArray
} from "@colmeia/core/src/shared-business-rules/bot/bot-content-model";
import { getEmptyAction } from '@colmeia/core/src/shared-business-rules/bot/bot-model';
import { EBotActionType } from "@colmeia/core/src/shared-business-rules/bot/new-bot-action";
import {
    EBPMAction, ELGPDActionStyle, IBPMActionAsset, IBPMActionModel,
    IBPMActionSolicitation,
    IBPMConditionalDisplay, IBPMLGPDActionOnEngagement,
    IBPMValidatorAction, IBPMValidatorActionOnError, IMultipleNestedFormConfig, TAllBPMACtion
} from "@colmeia/core/src/shared-business-rules/BPM/bpm-action-model";
import { getEmptyActionOnErrorsConfig, getEmptyBpmAction, getNewMultipleCallsConfig } from '@colmeia/core/src/shared-business-rules/BPM/bpm-functions';
import {
    IBPConditionalEvaluator, TIBPConditionalEvaluatorArray
} from "@colmeia/core/src/shared-business-rules/BPM/bpm-model";
import { EBPMMarketingActionType, IBPMMKTMActionModel, IBPMMMarketingBotAction } from '@colmeia/core/src/shared-business-rules/BPM/marketing/bpm-marketing.model';
import { ETagType, IServerColmeiaTag, TTagAssignmentArray } from '@colmeia/core/src/shared-business-rules/colmeia-tags/tags';
import { pickTranslations } from "@colmeia/core/src/shared-business-rules/const-text/all-serializables";
import { gTranslations } from "@colmeia/core/src/shared-business-rules/const-text/translations";
import {
    EMetaEngagementConditionType, ESourceOfInfo
} from "@colmeia/core/src/shared-business-rules/metadata/meta-engagement";
import { dbControlMetaEngagement } from '@colmeia/core/src/shared-business-rules/metadata/meta-engagement-db';
import { getAllFieldsBefore } from '@colmeia/core/src/shared-business-rules/metadata/metadata-utils';
import { ENonSerializableObjectType } from '@colmeia/core/src/shared-business-rules/non-serializable-id/non-serializable-id-interfaces';
import { ESearchAllowedType } from '@colmeia/core/src/shared-business-rules/search';
import { ESolicitationDestination } from '@colmeia/core/src/shared-business-rules/you-control/solicitation-tasks-model';
import {
    isInvalid,
    isInvalidString, isValidArray, isValidRef, objectShallowReplace, typedClone, values
} from "@colmeia/core/src/tools/utility";
import { ActionOnErrorEditorComponent } from 'app/components/dashboard/new-condition-editor/action-on-error-editor/action-on-error-editor.component';
import { IActionOnErrorEditorDialogReponse, IActionOnErrorHandler } from 'app/components/dashboard/new-condition-editor/action-on-error-editor/action-on-error-editor.model';
import { EEnumPickerMode, EnumPickerHandler, IEnumPickerClientCallback } from 'app/components/foundation/enum-picker/enum-picker.handler';
import { ConnectionRoutePickerHandler } from 'app/handlers/connection-route-picker.handler';
import { INSPickerHandlerParameter, NSPickerHandler } from 'app/handlers/ns-picker/ns-picker.handler';
import { EParticipantClickTypeMode, EParticipantSelectorAction, ParticipantSelectorHandler } from 'app/handlers/participant-selector.handler';
import { ETagPickerHandlerMode, TagPickerHandler } from 'app/handlers/tag-picker-handler';
import { DashBoardService } from 'app/services/dashboard/dashboard.service';
import { LookupService, TNSHashCacheImplementation } from 'app/services/lookup.service';
import { first, take } from 'rxjs/operators';
import { CanonicalPickerHandler } from "../../../handlers/canonical/canonical-picker.handler";
import { MainHandler } from "../../../handlers/main-handler";
import { IComponentParameter } from "../../../model/component-comm/basic";
import { IAssetAdderHandler } from "../../../model/dashboard/asset-adder.model";
import { SnackMessageService } from "../../../services/snack-bar";
import { RootComponent } from "../../foundation/root/root.component";
import { IBotActionHandler } from "../bot-action-editor/bot-action-editor.component";
import { ICreateEditEngagementDialogCallback } from "../create-edit-engagement-dialog/create-edit-engagement-dialog.component";
import { verticalAppear } from '../dashboard-animations';
import { DeployedServicesService } from '../dashboard-deployed-services/deployed-services.service';
import { ConditionMultipleCallsEditorComponent } from './condition-multiple-calls-editor/condition-multiple-calls-editor.component';
import { ConditionsEditorHandler, TConditionsEditorTargetsList } from './conditions-editor/conditions-editor.model';
import { EBPMTicketAgentAction, ICRMBPMActionAgentSendCampaign, ICRMAssignation, ICRMExecuteFunction, ICRMBPMActionNotification } from '@colmeia/core/src/shared-business-rules/crm/crm-services/crm-bpm-action.model';
import { BPMActionIcons } from './bpm.client-config-db';
import { dbControlBPM } from '@colmeia/core/src/shared-business-rules/BPM/bpm-control-db';

export interface NewConditionEditorParameter extends IComponentParameter {
    field: SchemaProperty;
    fields: TSchemaPropertyArray;
    allowDisableActions?: boolean;
    allowedActionTypes: {
        [EBPMAction.conditionalDisplay]?: true;
        [EBPMAction.validator]?: {
            hideSuccessMsg?: boolean;
            hideErrorRecurrency?: boolean;
        };
        [EBPMAction.action]?: {
            allowedActions: EBotActionType[];
        },
        [EBPMAction.anomization]?: true,
        [EBPMAction.getTitularData]?: true,
        [EBPMAction.optOut]?: true,
        [EBPMAction.optIn]?: true,
        [EBPMTicketAgentAction.Assign]?: true,
        [EBPMTicketAgentAction.Notification]?: true,
        [EBPMTicketAgentAction.SendCampaign]?: true,
        [EBPMTicketAgentAction.executeFunction]?: true,
        [EBPMMarketingActionType.goCampaignWithBot]?: true,
        [EBPMMarketingActionType.messageWithCampaing]?: true,
        [EBPMAction.solicitation]?: true,
    };
    currentConditions: TIBPConditionalEvaluatorArray | TIBPConditionalEvaluatorArray;
    allowedSourceInfo: ESourceOfInfo[]
    disableAutoAdd?: boolean;
    removeMetaConditionTypes?: EMetaEngagementConditionType[];
    actionHandlerOverride?: (action: IBotActionAsset) => Partial<IBotActionHandler>
    getNsPickerValueParams?: (nsType: ENonSerializableObjectType) => Partial<INSPickerHandlerParameter>
}

export const NEW_CONDITION_PARAMETER_TOKEN = new InjectionToken<NewConditionEditorParameter>('NEW_CONDITION_PARAMETER_TOKEN');

export class NewConditionEditorHandler extends MainHandler {
    public evaluatorAllowedTypes: EBPMAction[];

    constructor(parameter: NewConditionEditorParameter) {
        super(parameter);

        this.updateAlloweedTypes();
    }

    updateAlloweedTypes() {
        this.evaluatorAllowedTypes = this.getEvaluatorAllowedTypes();
    }

    getComponentParameter(): NewConditionEditorParameter {
        return super.getComponentParameter() as NewConditionEditorParameter;
    }

    isFirstField() {
        const { field, fields } = this.getComponentParameter();
        return fields.indexOf(field) === 0;
    }

    isLastField() {
        const { field, fields } = this.getComponentParameter();
        return fields.indexOf(field) === fields.length - 1;
    }

    private getEvaluatorAllowedTypes(): EBPMAction[] {
        const { field, allowedActionTypes } = this.getComponentParameter();
        const allTypes = Object.keys(allowedActionTypes) as EBPMAction[];

        return allTypes;
    }

    allFieldsBefore(): TSchemaPropertyArray {
        const { field, fields } = this.getComponentParameter();

        return getAllFieldsBefore(field, fields);
    }

}

interface HandlersHash {
    [name: string]: {
        action: IBotActionHandler;
        content: IAssetAdderHandler;
        validationOnSuccess: IAssetAdderHandler;
        validationOnFailed: IAssetAdderHandler;
        canonicalPicker: {
            [key: string]: CanonicalPickerHandler
        };
        lgpdActionStyle: EnumPickerHandler<ELGPDActionStyle>;
        lgpdRoutePicker: ConnectionRoutePickerHandler;
        conditionsEditor: ConditionsEditorHandler,
        botPicker?: NSPickerHandler,
        title?: IAssetAdderHandler,
        yesQuestion?: IAssetAdderHandler,
        noQuestion?: IAssetAdderHandler,
        solicitation?: {
            destHandlerPicker?: EnumPickerHandler<ESolicitationDestination>,
            groupPicker?: ParticipantSelectorHandler,
            islandPicker?: NSPickerHandler,
            avatarPicker?: ParticipantSelectorHandler,
        }
    }
}

export type TEvaluatorChangeEvent = {
    evaluator: IBPConditionalEvaluator,
    index: number
}

@Component({
    selector: 'app-new-condition-editor',
    templateUrl: './new-condition-editor.component.html',
    styleUrls: ['./new-condition-editor.component.scss'],
    animations: [verticalAppear]
})
export class NewConditionEditorComponent extends RootComponent<
    | EMetaEngagementConditionType
    | EBPMAction
    | EBPMTicketAgentAction
    | EBPMMarketingActionType
    > implements OnInit, OnChanges, AfterViewInit {
    functionText: string;
    copy: TIBPConditionalEvaluatorArray | TIBPConditionalEvaluatorArray = [];
    handlersHash: HandlersHash = {};
    public selectedEvaluator: IBPConditionalEvaluator;
    private _handler: NewConditionEditorHandler;
    conditionTypeEnum: typeof EMetaEngagementConditionType = EMetaEngagementConditionType;
    nsHashCache: TNSHashCacheImplementation;

    /**
     * BPM Actions enums
     */
    bpmActionEnum: typeof EBPMAction = EBPMAction;
    // ETypeBPMActionModel: typeof EBPMTicketAgentAction = EBPMTicketAgentAction;
    EBPMMarketingActionType: typeof EBPMMarketingActionType = EBPMMarketingActionType;
    ESolicitationDestination: typeof ESolicitationDestination = ESolicitationDestination;

    allConditionType: EMetaEngagementConditionType[] = values(EMetaEngagementConditionType);
    @Input() toggleSaveOrCancelCallback: ICreateEditEngagementDialogCallback;
    @Output() removedEvaluator = new EventEmitter<TEvaluatorChangeEvent>();
    @Output() restauredEvaluator = new EventEmitter<TEvaluatorChangeEvent>();
    @Input() autoOpenWhenEmpty: boolean = true;
    @Input() autoOpenFirstItem: boolean = false;

    public userFunctionPickerHandler: NSPickerHandler;
    public currentMultipleCallsConfig: IMultipleNestedFormConfig;

    BPMActionIcons: typeof BPMActionIcons = BPMActionIcons;

    @Input() set handler(val: NewConditionEditorHandler) {
        this._handler = val;
        if (val) {
            this.onHandlerChanged();
        }
    }
    get handler(): NewConditionEditorHandler {
        return this._handler;
    }

    get parameters(): NewConditionEditorParameter {
        return this.handler.getComponentParameter();
    }

    @ViewChild("insertingForm")
    insertingForm: TemplateRef<any>;
    addingDialogRef: MatDialogRef<TemplateRef<any>>;

    @ViewChildren(MatExpansionPanel) expansionPanels: QueryList<MatExpansionPanel>;

    constructor(
        private snack: SnackMessageService,
        private dialogSvc: MatDialog,
        private snackBarSvc: MatSnackBar,
        private viewContainerRef: ViewContainerRef,
        private dashboardSvc: DashBoardService,
        private deployedSvc: DeployedServicesService,
        private lookupSvc: LookupService,
        @Optional() @Inject(NEW_CONDITION_PARAMETER_TOKEN) private parametersOverride: NewConditionEditorParameter
    ) {
        super({
            ...pickTranslations(gTranslations.bot, [
                ...values(EMetaEngagementConditionType),
                ...values(EBPMAction),
            ]),
            ...pickTranslations(gTranslations.crm, [
                ...values(EBPMTicketAgentAction),
            ]),
            ...pickTranslations(gTranslations.bpmMkt, [
                ...values(EBPMMarketingActionType),
            ]),
        }, true);

        this.nsHashCache = this.lookupSvc.createNSHashCache();
    }

    ngOnInit() {
        this.updateParametersByInjection();
        this.resetInsertingForm();
    }

    ngOnChanges() {
        this.updateParametersByInjection();
    }

    private updateParametersByInjection() {
        if (this.parametersOverride) {
            objectShallowReplace(this.handler.getComponentParameter(), this.parametersOverride);
            this.handler.updateAlloweedTypes();
        }

    }

    ngAfterViewInit() {
        this.updateParametersByInjection();
        const parameter: NewConditionEditorParameter = this.handler.getComponentParameter();

        if (!isValidArray(this.copy) && !parameter.disableAutoAdd) {
            this.addEvaluator();
        }
    }

    public getActionName(bpmAction: EBPMAction): string {
        return this.translations[bpmAction].value;
    }

    private onHandlerChanged(): void {
        this.handlersHash = {};
        this.copy = [];
        const parameter: NewConditionEditorParameter = this.handler.getComponentParameter();
        this.copy = parameter.currentConditions;
        for (const evaluator of this.copy) {
            this.initializeHandlersBasedOnType(evaluator as IBPConditionalEvaluator);
        }
    }

    private initializeHandlersBasedOnType(evaluator: IBPConditionalEvaluator): void {
        this.initializeHashObjectIfNeeded(evaluator.name);

        this.initConditionsEditorFor(evaluator);

        if (!isValidRef(evaluator.action)) return;

        switch (evaluator.action.bpmAction) {
            case EBPMAction.validator:
                this.buildValidationHandlerFor(evaluator.name, (<IBPMValidatorAction>evaluator.action).onSuccess, (<IBPMValidatorAction>evaluator.action).onError);
                break;
            case EBPMAction.action:
                const actionAsset = (<IBPMActionAsset>evaluator.action);
                this.buildActionHandlerFor(evaluator.name, actionAsset.action);
                break;
            case EBPMAction.solicitation:
                this.initSolicitationPickers(evaluator.name, evaluator.action as IBPMActionSolicitation);
                break;
            case EBPMAction.anomization:
            case EBPMAction.getTitularData:
            case EBPMAction.optOut:
            case EBPMAction.optIn:
                this.buildLGPDHandlersFor(evaluator.name, (<IBPMLGPDActionOnEngagement>evaluator.action));
                break;
            case EBPMMarketingActionType.goCampaignWithBot:
                this.buildBotPickerFor(evaluator.name, evaluator.action as IBPMMMarketingBotAction);
                break;
        }
    }

    private initSolicitationPickers(name: string, action: IBPMActionSolicitation) {
        this.initDestPicker(name, action);
        this.initConditionalPickersForDestType(name, action);
        this.initTagPickerHandler(action);
    }

    private initDestPicker(name: string, action: IBPMActionSolicitation): void {
        this.handlersHash[name].solicitation.destHandlerPicker = new EnumPickerHandler<ESolicitationDestination>({
            inputTitle: 'Tipo de Destino',
            client: {
                onSingleEnumSelection: (value: ESolicitationDestination | undefined) => {
                    if (value && action.destType !== value) {
                        action.destId = undefined;
                        action.destType = value;
                        this.initConditionalPickersForDestType(name, action);
                    }
                },
            },
            mode: EEnumPickerMode.Single,
            translations: {
                [ESolicitationDestination.AllFromGroup]: 'Todos de um grupo',
                [ESolicitationDestination.AllAdminFromGroup]: 'Todos os administradores de um grupo',
                [ESolicitationDestination.IslandRandom]: 'Agente aleatório (por ilha)',
                [ESolicitationDestination.ToAvatar]: 'Agente específico',
            },
            enum: ESolicitationDestination,
            current: action?.destType,
            clientCallback: {},
            allowClear: false,
        });
    }

    async getSelectedParticipant(id: TGlobalUID, objectTypeID: EObjectType.group | EObjectType.avatar): Promise<TExtendedParticipantArray> {
        const selectedParticipant: TExtendedParticipant = await this.deployedSvc.getExtendedParticipantById(id, objectTypeID);
        return isValidRef(selectedParticipant) ? [selectedParticipant] : [];
    }

    private async initConditionalPickersForDestType(name: string, action: IBPMActionSolicitation) {
        const parameters = {
            typesOfActionsOnEachElementSelected: [
                EParticipantSelectorAction.Remove
            ],
            clientCallback: {
                onParticipantsSelected(selected: Array<TExtendedParticipant>, excluded: Array<TExtendedParticipant>): void {
                    if (selected.length)
                        action.destId = selected[0].getPrimaryID();
                    else if (excluded.length)
                        action.destId = undefined;
                }
            },
            isEditableTypesSelected: false,
            operationMode: EParticipantClickTypeMode.SendToParent,
            maxNumberOfParticipants: 1,
            overwritePartipantsWhenFull: true
        }

        let selectedGroup: TExtendedParticipantArray = [];
        const isGroupType: boolean = [ESolicitationDestination.AllFromGroup, ESolicitationDestination.AllAdminFromGroup].includes(action.destType);
        if (action.destId && isGroupType)
            selectedGroup = await this.getSelectedParticipant(action.destId, constant.objectType.group);
        this.handlersHash[name].solicitation.groupPicker = new ParticipantSelectorHandler({
            ...parameters,
            alreadySelectedList: selectedGroup,
            typesAllowed: [
                ESearchAllowedType.groups
            ],
        });

        this.handlersHash[name].solicitation.islandPicker = new NSPickerHandler({
            nsType: ENonSerializableObjectType.serviceIsland,
            title: "Selecione uma Ilha",
            genericNonSerializableService: this.dashboardSvc.getGenericNonSerializableService(),
            demandedTag: undefined,
            nonSerializablesIds: [action.destId],
            maxSelections: 1,
            pillMode: true,
            clientCallback: {
                onSaveCallback([island]) {
                    action.destId = island?.idNS;
                },
            },
        });

        let selectedAvatar: TExtendedParticipantArray = [];
        if (action.destId && action.destType === ESolicitationDestination.ToAvatar)
            selectedAvatar = await this.getSelectedParticipant(action.destId, constant.objectType.avatar);
        this.handlersHash[name].solicitation.avatarPicker = new ParticipantSelectorHandler({
            ...parameters,
            alreadySelectedList: selectedAvatar,
            typesAllowed: [
                ESearchAllowedType.callcanterAgents
            ],
        });
    }

    tagPickerHandler: TagPickerHandler;
    private async initTagPickerHandler(action: IBPMActionSolicitation) {
        const currentTags: TTagAssignmentArray = action.tags;
        const tagIDS: TArrayID = isValidRef(currentTags) ? currentTags.map(tag => tag.idTag) : [];
        const tags: IServerColmeiaTag[] = await this.nsHashCache.hydrate(tagIDS);

        this.tagPickerHandler = new TagPickerHandler({
            tagType: ETagType.marker,
            selectedTags: tags,
            tagsData: undefined,
            mode: ETagPickerHandlerMode.edit,
            clientCallback: {
                onFinishSelectionCallback: (selectedTags) => {
                    let tag = this.dashboardSvc.getDefaultTaggable()[0];
                    action.tags = selectedTags.map(colmeiaTag => ({ ...tag, idTag: colmeiaTag.idNS }));
                }
            },
            compact: false
        });
    }

    private buildBotPickerFor(name: string, action: IBPMMMarketingBotAction) {
        this.handlersHash[name].botPicker = new NSPickerHandler({
            nsType: ENonSerializableObjectType.bot,
            title: "Selecione o bot",
            genericNonSerializableService: this.dashboardSvc.getGenericNonSerializableService(),
            demandedTag: undefined,
            nonSerializablesIds: [action.idBot],
            maxSelections: 1,
            clientCallback: {
                onSaveCallback([bot]) {
                    action.idBot = bot?.idNS;
                },
            },
        })
    }

    private initConditionsEditorFor(evaluator: IBPConditionalEvaluator, schemaPropsList?: TSchemaPropertyArray) {
        const { fields, allowedSourceInfo } = this.handler.getComponentParameter();
        const localAllowedSourceInfo: ESourceOfInfo[] = [...allowedSourceInfo];

        schemaPropsList = schemaPropsList || (dbControlMetaEngagement[evaluator.action?.bpmAction]?.onlyPreviousFields
            ? this.handler.allFieldsBefore()
            : fields);

        const targetsFromSchema: TConditionsEditorTargetsList = schemaPropsList.map((field) => ({
            targetLabel: field.prompt,
            targetId: field.idProperty
        }));

        const isFirstField: boolean = this.handler.isFirstField();
        const isConditionalDisplay: boolean = <EBPMAction>evaluator.action?.bpmAction === EBPMAction.conditionalDisplay;

        const targetsList: TConditionsEditorTargetsList = isFirstField
            ? []
            : targetsFromSchema;

        if (isFirstField && isConditionalDisplay) {
            localAllowedSourceInfo.splice(localAllowedSourceInfo.indexOf(ESourceOfInfo.property), 1);
        }

        const conditionHandler = new ConditionsEditorHandler({
            allowedSourceInfo: localAllowedSourceInfo,
            bpmAction: <EBPMAction>evaluator.action?.bpmAction,
            targetsList,
            currentConditions: evaluator.matchConditions,
            removeMetaConditionTypes: this.handler.getComponentParameter().removeMetaConditionTypes,
            clientCallback: {
                onChange: (conditions) => {
                    evaluator.matchConditions = conditions;
                },
            },
            getNsPickerValueParams: this.handler.getComponentParameter().getNsPickerValueParams,
        });

        this.handlersHash[evaluator.name].conditionsEditor = conditionHandler;
    }

    private buildValidationHandlerFor(name: string, onSuccess: TContentAssetArray, onFail: TContentAssetArray): void {
        this.initializeHashObjectIfNeeded(name);
        const successHandler = this.buildAssetAdderHandlerForValidation(onSuccess);
        const failHandler = this.buildAssetAdderHandlerForValidation(onFail);
        this.handlersHash[name].validationOnSuccess = successHandler;
        this.handlersHash[name].validationOnFailed = failHandler;
    }

    private buildAssetAdderHandlerForValidation(content: TContentAssetArray): IAssetAdderHandler {
        const handler: IAssetAdderHandler = {
            assets: content,
            assetTypesEnabled: [KBAssetType.contentAPICall, KBAssetType.content, KBAssetType.media, KBAssetTypeClientOnly.sticker],
            removeAsset: (index: number, assets: IBasicAsset[]) => {
                assets.splice(index, 1);
                return Promise.resolve(true)
            },
            saveAsset: (newAsset: IBasicAsset, assets: IBasicAsset[]) => {
                const index = assets.findIndex(ast => ast.idAsset === newAsset.idAsset);
                if (index > -1) {
                    assets[index] = newAsset;
                } else {
                    assets.push(newAsset);
                }
                (<IContentBasicAsset>newAsset).botContentType = EBotContentEvent.mainContent
                return Promise.resolve(true)
            }
        };
        return handler;
    }

    newEvaluator: { name: string; action: TAllBPMACtion; };

    addEvaluator(): void {
        this.resetInsertingForm();

        this.addingDialogRef = this.dialogSvc.open(this.insertingForm, {
            viewContainerRef: this.viewContainerRef,
            panelClass: "small-size"
        })
    }

    renameEvaluator(evaluator: IBPConditionalEvaluator, index: number, event: PointerEvent): void {
        this.resetInsertingForm();

        this.newEvaluator = {
            name: evaluator.name,
            action: evaluator.action.bpmAction
        };

        this.addingDialogRef = this.dialogSvc.open(this.insertingForm, {
            viewContainerRef: this.viewContainerRef,
            panelClass: "small-size",
            data: {
                isRenaming: true,
                renamingIndex: index
            }
        });

        event?.stopPropagation();
    }

    private resetInsertingForm(): void {
        this.newEvaluator = {
            name: '',
            action: this.handler.evaluatorAllowedTypes[0]
        };
        if (this.toggleSaveOrCancelCallback) {
            this.toggleSaveOrCancelCallback.enableSaveOrCancel();
        }
    }

    closeEvaluatorInserting(): void {
        this.resetInsertingForm();
        this.addingDialogRef.close();
    }

    private nameAlreadyExists(name: string): boolean {
        return isValidRef(this.findEvaluatorReference(name));
    }

    confirmEvaluatorInserting(): void {
        if (!this.validateNewEvaluator()) {
            return;
        }

        let newEvaluator: IBPConditionalEvaluator = {
            action: undefined,
            stopAction: false,
            name: this.newEvaluator.name,
            matchConditions: []
        };

        const lgpdAction: () => IBPMLGPDActionOnEngagement = () => <IBPMLGPDActionOnEngagement>{
            bpmAction: this.newEvaluator.action,
            actionStyle: undefined,
            idRoute: undefined,
        };
        const actionInitMapper: { [key in TAllBPMACtion]?: () => IBPMActionModel } = {
            [EBPMAction.action]: () => getEmptyBpmAction<IBPMActionAsset>({
                bpmAction: EBPMAction.action,
                action: getEmptyAction()
            }),
            [EBPMAction.validator]: () => getEmptyBpmAction<IBPMValidatorAction>({
                bpmAction: EBPMAction.validator,
                onError: [],
                onSuccess: []
            }),
            [EBPMAction.conditionalDisplay]: () => getEmptyBpmAction<IBPMConditionalDisplay>({
                bpmAction: EBPMAction.conditionalDisplay,
                doNotDisplay: undefined,
            }),
            [EBPMAction.anomization]: lgpdAction,
            [EBPMAction.getTitularData]: lgpdAction,
            [EBPMAction.optOut]: lgpdAction,
            [EBPMAction.optIn]: lgpdAction,
            [EBPMAction.solicitation]: () => getEmptyBpmAction<IBPMActionSolicitation>({
                bpmAction: EBPMAction.solicitation,
                agentOwner: false,
                destType: undefined,
                destId: undefined
            }),
            [EBPMTicketAgentAction.Assign]: () => getEmptyBpmAction<ICRMAssignation>({
                bpmAction: EBPMTicketAgentAction.Assign,
                idIsland: undefined,
            }),
            [EBPMTicketAgentAction.SendCampaign]: () => getEmptyBpmAction<ICRMBPMActionAgentSendCampaign>({
                bpmAction: EBPMTicketAgentAction.SendCampaign,
                campaigns: [],
            }),
            [EBPMTicketAgentAction.Notification]: () => getEmptyBpmAction<ICRMBPMActionNotification>({
                bpmAction: EBPMTicketAgentAction.Notification,
                idCampaignAction: undefined,
            }),
            [EBPMTicketAgentAction.executeFunction]: () => getEmptyBpmAction<ICRMExecuteFunction>({
                bpmAction: EBPMTicketAgentAction.executeFunction,
                idFunction: undefined,
            }),
            [EBPMMarketingActionType.goCampaignWithBot]: () => getEmptyBpmAction<IBPMMKTMActionModel>({
                bpmAction: EBPMMarketingActionType.goCampaignWithBot,
            }),
            [EBPMMarketingActionType.messageWithCampaing]: () => getEmptyBpmAction<IBPMMKTMActionModel>({
                bpmAction: EBPMMarketingActionType.messageWithCampaing,
            }),
        };

        if (isValidRef(this.newEvaluator.action))
            newEvaluator.action = actionInitMapper[this.newEvaluator.action]();

        this.initializeHandlersBasedOnType(newEvaluator);
        this.copy.push(newEvaluator);
        this.closeEvaluatorInserting();

        this.expansionPanels.changes.pipe(first()).subscribe(() => {
            this.expansionPanels.last.open();
        })
    }

    confirmEvaluatorRenaming(index: number): void {
        const name = this.newEvaluator.name;
        const oldName = this.copy[index].name;

        if (!this.validateNewEvaluator()) {
            return;
        }

        this.renameFromHashObject(oldName, name);

        // assigns the new name
        this.copy[index].name = name;

        this.closeEvaluatorInserting();
    }

    private initializeHashObjectIfNeeded(name: string): void {
        if (isInvalid(this.handlersHash[name])) {
            this.handlersHash[name] = {
                content: undefined,
                action: undefined,
                validationOnFailed: undefined,
                validationOnSuccess: undefined,
                canonicalPicker: {},
                lgpdActionStyle: undefined,
                lgpdRoutePicker: undefined,
                conditionsEditor: undefined,
                solicitation: {
                    groupPicker: undefined,
                    islandPicker: undefined,
                    avatarPicker: undefined,
                }
            };
        }
    }

    /**
     * Renames a key from the handler hash object
     */
    private renameFromHashObject(oldName: string, newName: string): void {
        const handler = this.handlersHash[oldName];

        this.handlersHash[newName] = handler;
        delete this.handlersHash[oldName];
    }

    private buildLGPDHandlersFor(name: string, action: IBPMLGPDActionOnEngagement): void {
        this.handlersHash[name].lgpdActionStyle = new EnumPickerHandler({
            client: {
                onSingleEnumSelection: value => action.actionStyle = value,
            } as IEnumPickerClientCallback<ELGPDActionStyle>,
            mode: EEnumPickerMode.Single,
            appearance: 'fill',
            inputTitle: 'Estilo da ação',
            translations: gTranslations.bot,
            enum: ELGPDActionStyle,
            clientCallback: null,
            current: action.actionStyle,
        });
        this.buildLGPDRoutePicker(name, action);

        if (!isValidRef(action.onSuccess)) {
            action.onSuccess = [];
        }

        this.buildContentHandlerFor(name, action.onSuccess);
    }

    public buildLGPDRoutePicker(name: string, action: IBPMLGPDActionOnEngagement): void {
        this.handlersHash[name].lgpdRoutePicker = ConnectionRoutePickerHandler.factory({
            onSelect: (route) => {
                action.idRoute = route?.idNS;
                this.buildLGPDRoutePicker(name, action);
            },
            currentIdRoute: action.idRoute,
            currentIdDomain: null
        });
    }

    public showEvaluatorLGPDRoutePicker(evaluator: IBPConditionalEvaluator) {
        const action: IBPMLGPDActionOnEngagement = evaluator.action as IBPMLGPDActionOnEngagement;
        return isValidRef(this.handlersHash[evaluator.name].lgpdRoutePicker) && action.actionStyle === ELGPDActionStyle.anonimizationAPI;
    }


    private buildActionHandlerFor(name: string, action: IBotActionAsset): void {
        this.buildContentHandlerFor(name, action.contentArray, { alloweedGrouping: true });

        const actionTypes: EBotActionType[] = this.handler.getComponentParameter().allowedActionTypes[EBPMAction.action].allowedActions;

        const botActionOverride = this.handler.getComponentParameter().actionHandlerOverride?.(action);

        const showTrackerMenu: boolean = !actionTypes.includes(EBotActionType.upsertTicket)

        this.handlersHash[name].action = {
            botAction: action,
            actionTitle: 'ação',
            showContentArray: true,
            actionTypes,
            handlerAssets: this.handlersHash[name].content,
            isEventAction: false,
            ...botActionOverride,
            ...(showTrackerMenu && {trackerFieldLocation: 'top'})
        };

    }

    private buildContentHandlerFor(name: string, content: TContentAssetArray, extra?: Partial<IAssetAdderHandler>): void {
        this.handlersHash[name].content = {
            ...extra,
            assets: content,
            assetTypesEnabled: [KBAssetType.contentAPICall, KBAssetType.content, KBAssetType.media, KBAssetTypeClientOnly.sticker],
            removeAsset: (index: number, assets: IBasicAsset[]) => {
                assets.splice(index, 1);
                return Promise.resolve(true)
            },
            saveAsset: (newAsset: IBasicAsset, assets: IBasicAsset[]) => {
                const index = assets.findIndex(ast => ast.idAsset === newAsset.idAsset);
                if (index > -1) {
                    assets[index] = newAsset;
                } else {
                    assets.push(newAsset);
                }
                (<IContentBasicAsset>newAsset).botContentType = EBotContentEvent.mainContent
                return Promise.resolve(true)
            }
        }
    }

    private findEvaluatorReference(name: string): IBPConditionalEvaluator {
        return this.copy.find(evaluator => evaluator.name === name) as IBPConditionalEvaluator;
    }

    removeEvaluator(evaluator: IBPConditionalEvaluator, index: number): void {
        this.copy.splice(index, 1);

        const snackRef = this.snackBarSvc.open("Removido", "Desfazer", {
            duration: 5000
        })

        snackRef.onAction().subscribe(() => {
            this.copy.splice(index, 0, evaluator);
            this.restauredEvaluator.emit({ evaluator, index })

            this.snackBarSvc.open("Restaurado", "Fechar", {
                duration: 3000
            });
        });

        this.removedEvaluator.emit({ evaluator, index })
    }

    public async onDropTable(event: CdkDragDrop<TIBPConditionalEvaluatorArray[]>) {
        moveItemInArray(this.copy, event.previousIndex, event.currentIndex);
    }

    public isConditionalDisplayEvaluator(evaluator: IBPConditionalEvaluator): boolean {
        return evaluator.action?.bpmAction === EBPMAction.conditionalDisplay;
    }

    public showMultipleForm(evaluator: IBPConditionalEvaluator): boolean {
        const isBotAction: boolean = evaluator.action?.bpmAction === EBPMAction.action;
        const action: IBotActionAsset = this.handlersHash[evaluator.name]?.action?.botAction;
        const isContentGenerator: boolean = action?.type === EBotActionType.contentGenerator

        return isBotAction && isContentGenerator;
    }

    public editMultipleForm(evaluator: IBPConditionalEvaluator, event?: PointerEvent) {
        event?.stopPropagation();

        const action: IBPMActionAsset = (<IBPMActionAsset>evaluator.action);
        const isCreate = !isValidRef(action.multipleCallsConfig);
        const multipleCallsConfig = isCreate
            ? getNewMultipleCallsConfig()
            : typedClone(action.multipleCallsConfig);

        const dialogRef = this.dialogSvc.open<ConditionMultipleCallsEditorComponent, IMultipleNestedFormConfig, IMultipleNestedFormConfig>(ConditionMultipleCallsEditorComponent, {
            viewContainerRef: this.viewContainerRef,
            panelClass: "average-size",
            data: multipleCallsConfig,
            maxWidth: "720px"
        });

        dialogRef.afterClosed().pipe(take(1)).subscribe((multipleCallsConfigRes: IMultipleNestedFormConfig) => {
            action.isMultipleFormConfig = isCreate ? !!multipleCallsConfigRes : !!action.isMultipleFormConfig;

            if (!isValidRef(multipleCallsConfigRes)) return

            action.multipleCallsConfig = multipleCallsConfigRes;
        });
    }

    public handleDoNotDisplayChangeFor(evaluator: IBPConditionalEvaluator, panel: MatExpansionPanel) {
        const action = evaluator.action as IBPMConditionalDisplay;
        const newState = !action.doNotDisplay;

        if (newState) {
            if (panel.opened) {
                panel.close();

                setTimeout((_action, _doNotDisplay) => { _action.doNotDisplay = _doNotDisplay; }, 200, action, newState)
            } else {
                action.doNotDisplay = newState;
            }
            return;
        }

        action.doNotDisplay = newState;
        panel.open();
    }

    public isMultipleFormChange($event: MatSlideToggleChange, evaluator: IBPConditionalEvaluator) {
        if ($event.checked && !isValidRef((<IBPMActionAsset>evaluator.action).multipleCallsConfig)) {
            this.editMultipleForm(evaluator);
        }
    }

    public hasMultipleConditionsOfSameType(): boolean {
        if (this.copy.length < 2) {
            return false;
        }

        // armazena a quantidade de condicionais de cada tipo (TAllBPMACtion)
        const typeMap: Map<TAllBPMACtion, number> = new Map();

        for (let basicCondition of this.copy) {
            /**
             * caso a condição não tiver a propriedade action, usaremos null para representar
             * o tipo de condição básica (que não tem action)
             */
            let key: TAllBPMACtion | null = null;

            if (isValidRef((<any>basicCondition).action)) {
                const condition = basicCondition as IBPConditionalEvaluator;

                key = condition.action.bpmAction;
            }

            const value = typeMap.get(key);

            if (isValidRef(value)) {
                return true;
            } else {
                typeMap.set(key, 0);
            }
        };

        return false;
    }

    hasErrorValidationRecurrencyAction(evaluator: IBPConditionalEvaluator): boolean {
        const action = evaluator.action as IBPMValidatorAction;
        return isValidRef(action.actionOnErrors);
    }

    recurrencyCount(evaluator: IBPConditionalEvaluator): number {
        const action = evaluator.action as IBPMValidatorAction;
        return action.actionOnErrors?.errorsCount
    }

    createErrorRecurrencyConfig(evaluator: IBPConditionalEvaluator) {
        const action = evaluator.action as IBPMValidatorAction;
        const actionOnErrors: IBPMValidatorActionOnError = isValidRef(action.actionOnErrors)
            ? typedClone(action.actionOnErrors)
            : getEmptyActionOnErrorsConfig()
        /**
         * open dialog
         */
        const dialogRef = this.dialogSvc.open<ActionOnErrorEditorComponent, IActionOnErrorHandler, IActionOnErrorEditorDialogReponse>(ActionOnErrorEditorComponent, {
            panelClass: "small-size",
            data: {
                actionOnErrorConfig: actionOnErrors,
            },
            viewContainerRef: this.viewContainerRef,
        });

        dialogRef.afterClosed().pipe(take(1)).subscribe((response) => {
            if (isValidRef(response?.saved)) {
                action.actionOnErrors = actionOnErrors;
            }
        });

    }

    removeErrorRecurrencyConfig(evaluator: IBPConditionalEvaluator) {
        const action = evaluator.action as IBPMValidatorAction;
        delete action.actionOnErrors;
    }

    public validatorHideSuccessMsg(evaluator: IBPConditionalEvaluator): boolean {
        if (evaluator.action.bpmAction !== EBPMAction.validator) return false;

        const parameter: NewConditionEditorParameter = this.handler.getComponentParameter();

        return parameter.allowedActionTypes[EBPMAction.validator]?.hideSuccessMsg === true;
    }

    public validatorErrorRecurrency(evaluator: IBPConditionalEvaluator): boolean {
        if (evaluator.action.bpmAction !== EBPMAction.validator) return false;

        const parameter: NewConditionEditorParameter = this.handler.getComponentParameter();

        return parameter.allowedActionTypes[EBPMAction.validator]?.hideErrorRecurrency === true;
    }

    readonly bpmCrmActionTypes: TAllBPMACtion[] = [
        EBPMTicketAgentAction.executeFunction,
        EBPMTicketAgentAction.Notification,
        EBPMTicketAgentAction.SendCampaign,
        EBPMTicketAgentAction.CloseTicket,
        EBPMTicketAgentAction.Assign,
    ];


    hideConditions(bpmAction: TAllBPMACtion) {
        return bpmAction && dbControlBPM[bpmAction].hideConditions === true;
    }

    public validateNewEvaluator(): boolean {
        if (isInvalidString(this.newEvaluator.name)) {
            this.snack.openError(`Insira um nome para a ação.`, 3000);
            return false;
        }
        if (this.nameAlreadyExists(this.newEvaluator.name)) {
            this.snack.openError(`Já existe uma ação com esse nome`, 3000);
            return false;
        }

        return true;
    }

    get allowDisableActions(): boolean {
        return this.parameters.allowDisableActions;
    }

    stopActionChanged(evaluator: IBPConditionalEvaluator, event: MatSlideToggleChange) {
        evaluator.stopAction = !event.checked;
    }
}
