import { Injectable } from "@angular/core";
import { Interaction } from "@colmeia/core/src/interaction/interaction";
import { apiRequestType } from "@colmeia/core/src/request-interfaces/message-types";
import { getIDConversationFromThreadInfo } from "@colmeia/core/src/rules/thread-conversation-functions";
import { IGetChannelOpenTimeRequest, IGetChannelOpenTimeResponse } from "@colmeia/core/src/shared-business-rules/active-1x1-call/active-1x1-req-resp";
import { ISendOpenChannelTemplateRequest, ISendOpenChannelTemplateResponse } from "@colmeia/core/src/shared-business-rules/attendent-service-pack/attendent-sp-req-resp";
import { EBotEventType } from "@colmeia/core/src/shared-business-rules/bot/bot-event-model";
import { getInitialAgentChannelClosedControl } from "@colmeia/core/src/shared-business-rules/social-network/social-network-config.functions";
import { Miliseconds } from "@colmeia/core/src/time/time-utl";
import { SNConfigService } from "app/components/dashboard/social-network-config/sn-config.service";
import { AttendanceService } from 'app/services/attendance.service';
import { ServerCommunicationService } from "../server-communication.service";
import { SessionService } from "../session.service";

export enum ECustomerAvatarStatus {
    Active = 'Active',
    Closed = 'Closed'
}

export function getCustomerAvatarStatusColor(status: ECustomerAvatarStatus): string {
    if (status === ECustomerAvatarStatus.Active) {
        return 'var(--success-500)'
    }

    if (status === ECustomerAvatarStatus.Closed) {
        return 'var(--warn-500)';
    }

    return null;
}

export interface ICheckChannelStatusReturn {
    isClosed: boolean;
    lastTemplateSent?: Miliseconds;
    sendTemplateBlockedUntil?: Miliseconds;
}

@Injectable()
export class AttendanceChannelService {

    constructor(
        private session: SessionService,
        private api: ServerCommunicationService,
        private attSvc: AttendanceService,
        private snConfigSvc: SNConfigService,
    ) {
    }

    get agentControl() {
        return this.snConfigSvc.getSettings()?.agent?.channelClosedControl || getInitialAgentChannelClosedControl();
    }

    async checkChannelStatus(idGroup: string): Promise<ICheckChannelStatusReturn> {

        if (!this.attSvc.isInAttendDB(idGroup)) return { isClosed: false };

        const startServiceChat = this.attSvc.getInitInteractionService(idGroup);

        const provider = startServiceChat.get360Providers()[0];

        if (!provider) {
            return { isClosed: false };
        }

        const response = await this.api.sendRequest<IGetChannelOpenTimeRequest, IGetChannelOpenTimeResponse>(
            apiRequestType.dashboardExtra.attendance.getChannelOpenTime
        )({
            target: provider.target,
            address: provider.address,
            channel: provider.providerType,
        });

        const { sendInterval } = this.agentControl;

        const lastMessageOfChannelClosedOnAgentChat: Interaction = this.getLastMessageOfChannelClosedOnAgentChat(idGroup);

        // if (!lastMessageOfChannelClosedOnAgentChat) {
        //     return { isClosed: false };
        // }

        const sendTemplateBlockedUntil: number | undefined = lastMessageOfChannelClosedOnAgentChat
            ? lastMessageOfChannelClosedOnAgentChat.getClockTick() + sendInterval
            : undefined;

        const attData = await this.attSvc.getOrLoadAttData(idGroup);

        this.attSvc.upsertAttendanceEntry(idGroup, {
            openChannelUntil: response?.openChannelUntil
        });

        return {
            isClosed: response?.openChannelUntil < Date.now(),
            sendTemplateBlockedUntil,
        }
    }

    private getAllAttendanceInteractions(idGroup: string): Interaction[] {
        const interactions = this.session.getGroupSubscription(idGroup)?.getInteractions() || [];

        const sortedInteractions: Interaction[] = [...interactions].sort((a, b) => {
            return a.getClockTick() - b.getClockTick();
        });
        return sortedInteractions;
    }

    public async sendOpenChannelTemplate(idGroup: string): Promise<boolean> {
        const startServiceChat = this.attSvc.getInitInteractionServiceJSON(idGroup);

        const idConversation = getIDConversationFromThreadInfo(startServiceChat.threadInfo);

        const res = await this.api.sendRequest<ISendOpenChannelTemplateRequest, ISendOpenChannelTemplateResponse>(apiRequestType.dashboardExtra.attendance.sendOpenChannelTemplate)({
            idConversation,
            startServiceChat,
        });

        return !!res;
    }

    public getLastMessageOfChannelClosedOnAgentChat(idGroup: string): Interaction | undefined {
        const allInteractions = this.getAllAttendanceInteractions(idGroup);
        let currentStartServiceChat: string;

        try {
            currentStartServiceChat = this.attSvc.getReplyInteraction(idGroup).getPrimaryID();
        } catch (e) { }

        if (!currentStartServiceChat) return;

        const allInteractionsOfCurrentAttendance = allInteractions.filter(interaction => {
            return interaction.getInteractionParentArray().includes(currentStartServiceChat);
        })

        let lastMessageOfChannelClosedOnAgentChat: Interaction;

        for (const interaction of allInteractionsOfCurrentAttendance) {
            if (interaction.isGeneratedByBotEvent(EBotEventType.onChannelClosedOnAgentChat)) {
                lastMessageOfChannelClosedOnAgentChat = interaction;
            }
        }

        return lastMessageOfChannelClosedOnAgentChat;
    }

    public getCustomerAvatarStatus(groupId: string): ECustomerAvatarStatus {
        const channelOpenUntil = this.attSvc.getChannelOpenUntilForGroup(groupId)

        if (isNaN(channelOpenUntil)) {
            return null;
        }

        if (channelOpenUntil < Date.now()) {
            return ECustomerAvatarStatus.Closed;
        } else {
            return ECustomerAvatarStatus.Active;
        }
    }
}
