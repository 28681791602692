import { typedEntryFields, typedFields, typedScreenOptions } from "../../../tools/utility";
import { EAllSerializable, defineTranslations, getTranslationsFields } from "../all-serializables";
import { EAttendantStatus, EAttendantStatusColor } from "../../../comm-interfaces/service-group-interfaces";
import { EIntegrationAllocationType, EIslandType } from "../../attendance-island/attendance-island";
import { EUnitTypeID } from "../../../business/constant.enums";
import { EEntryCancelReason } from "../../campaigns/campaing-interfaces";
import { MetaTranslation } from "@colmeia/core/src/shared-business-rules/const-text/meta-translation";
import { copilotTranslations } from "@colmeia/core/src/shared-business-rules/const-text/views/attendance-copilot";

export const attendanceViewScreenOptions = typedScreenOptions({
    idSerializable: EAllSerializable.Attendance,
    fields: {
        editTitle: 1, // Editar Ilha de Atendimento
        addAttendanceIsland: 2, // Adicionar Ilha de Atendimento
        basicData: 3, // Dados básicos
        attendants: 4, // 	Atendentes
        attendant: 5, // Atendente
        isActive: 6, // Ativo
        crmIntegrationField: 7, // Campo da Integração CRM
        crmIntegration: 8, // Integração CRM
        dataEntryForm: 9, // Formulário para entrada de dados
        simultaneousCalls: 10, // Quantos atendimentos poderão ser feitos simultaneamente?
        islandType: 11, // Tipo de Ilha
        attendanceIsland: 12, // Ilha de Atendimento
        attendanceIslands: 13, // Ilhas de Atendimento
        errorLoadingIslands: 14, // Não foi possível carregar as ilhas de atendimento
        noIslands: 15, // Não há ilhas de atendimento
        //
        attendanceCalendar: 16, // Calendário de atendimento
        calendarPlaceholder: 17, // Digite o nome do calendário
        openJourney: 18, // Intervalo de funcionamento
        openJourneys: 19, // Intervalos de funcionamento
        removeException: 20, // Remover Exceção
        exceptions: 21, // Exceções
        schedules: 22, // Horários de atendimento
        scheduleOverlap: 23, // Já existe um intervalo de funcionamento entre:
        duplicatedException: 24, // Já existe uma exceção para está data.
        exception: 25, // Exceção
        removeHoliday: 26, // Remover Feriado
        holidays: 27, // Feriados
        duplicatedHoliday: 28 // Já existe um feriado para essa data
    }
} as const);

export const serviceStatusScreenOptions = defineTranslations({
    idSerializable: EAllSerializable.ServiceStatus,
    fields: {
        updateStatusText: 5, // 'updateProviderStatus' 1
        serviceHistory: 10, // 'dashboardServiceStatusHistory' 19
        endTime: 11, // 'dashboardServiceStatusHistory' 20
        startTime: 12, // 'dashboardServiceStatusHistory' 21
        status: 13, // 'dashboardServiceStatusHistory' 22
    }
});

export const genericServiceStatusScreenOptions = defineTranslations({
    idSerializable: EAllSerializable.GenericServiceStatus,
    fields: {
        genericWhatsAppLogError: MetaTranslation.field.template({
            num: 1,
            template: 'id: {{ idName }} function: {{ functionName }} ({{ id }}) {{ englishMessage }}. {{ portugueseMessage }}',
            text: '{{ portugueseMessage }}',
        }),
        genericWhatsAppError: MetaTranslation.field.template({
            num: 2,
            template: '({{ id }}) {{ fullMessage }}. {{ message }}',
            text: '{{ message }}',
        }),
        whatsApp131009: MetaTranslation.field.template({
            num: 3,
            template: '(#131009) Parameter value is not valid. Parameter Invalid',
            text: 'Um ou mais parâmetros são inválidos.', // Um ou mais valores de parâmetro não são suportados, o número de telefone do destinatário não é um número de telefone do WhatsApp válido ou o número de telefone do remetente não foi adicionado à plataforma de negócios do WhatsApp
        }),
        whatsApp100: MetaTranslation.field.template({
            num: 4,
            template: '(#100) Invalid parameter. Parâmetro inválido',
            text: 'A solicitação incluiu um ou mais parâmetros sem suporte ou inválidos.',
        }),

        thereCannotBeMoreThan3Replies: MetaTranslation.field.template({
            num: 5,
            template: 'There cannot be more than 3 Replies',
            text: 'Não pode haver mais de 3 botões de resposta rápida',
            isWarning: true,
        }),

        thereCannotBeMoreThan2Actions: MetaTranslation.field.template({
            num: 6,
            template: 'There cannot be more than 2 Actions',
            text: 'Não pode haver mais de 2 botões de chamada para ação',
            isWarning: true,
        }),

        buttonComponentCanOnlyHaveThreeButtons: MetaTranslation.field.template({
            num: 7,
            template: 'button component can only have three buttons',
            text: 'Só pode haver 3 botões',
            isWarning: true,
        }),

    }
});

const attendantStatusColorScreenOptions = typedScreenOptions({
    idSerializable: EAllSerializable.AttendantStatusColor,
    fields: typedEntryFields<{ [key in EAttendantStatusColor]: number }>()([
        [EAttendantStatusColor.green, 1], // Verde
        [EAttendantStatusColor.red, 2], // Vermelho
        [EAttendantStatusColor.yellow, 3], // Amarelo
        [EAttendantStatusColor.gray, 4], // Cinza
    ]),
});

const attendantIslandTypeScreenOptions = typedScreenOptions({
    idSerializable: EAllSerializable.AttIslandType,
    fields: typedFields<EIslandType>()({
        [EIslandType.crmColmeia]: 1, // CRM Colmeia
        [EIslandType.externalCrm]: 2, // Encaminha para um CRM externo
        [EIslandType.internalSupport]: 3, // Will be deprecated
        [EIslandType.onlineColmeia]: 4, // Atendimento Real Time
    }),
});

const attendantAllocationTypeScreenOptions = typedScreenOptions({
    idSerializable: EAllSerializable.AttAllocationType,
    fields: typedFields<EIntegrationAllocationType>()({
        [EIntegrationAllocationType.Automatic]: 1, // Controla e inicia a alocação
        [EIntegrationAllocationType.Broadcast]: 2, // Envia atendimento para todos na Ilha
        [EIntegrationAllocationType.pool]: 3, // Atendimentos ficam em lista até serem pegos
    }),
});

export const presenceStatusScreenOption = typedScreenOptions({
    idSerializable: EAllSerializable.AttendanceTwo,
    fields: {
        [EAttendantStatus.absent]: 1, // Ausente
        [EAttendantStatus.ocuppied]: 2, // Ocupado
        [EAttendantStatus.resume]: 3, // Retomar
        promptLoginTitle: 4, // Tem certeza que deseja logar?
        promptLoginMessage: 5, // Ao fazer login você pode começar a receber atendimentos
        promptLogoutTitle: 6, // 'Tem certeza que deseja deslogar?'
        promptLogoutMessage: 7, // Ao deslogar você não recebera mais atendimentos
        attLogin: 8, // Fazer login
        attLogout: 9, // Fazer logout
        promptReturnQueueTitle: 10, // Tem certeza que deseja retornar todos os casos para a fila?
        promptReturnQueueMessage: 11, // Ao retornar os atendimentos você ficara sem atendimentos, mas disponível para receber novos atendimentos.
        promptChangeStatusTitle: 12, // Tem certeza que deseja trocar de status?
        promptChangeStatusMessage: 13, // "Seu novo status será: "
        [EAttendantStatus.online]: 14, // Online
        offline: 15, // Offline
        finishedByExpire: 16, // Finalizado por expirar
        customerName: 17, // Nome do cliente
        inAttendance: 18, // Em atendimento
        shorterTime: 19, // Menor tempo
        longerTime: 20, // Maior tempo
        averageTime: 21, // Tempo médio
        casesAttended: 22, // Casos atendindos
        cases: 23, // Casos
        createCalendar: 24, // Criar calendário
        editCalendar: 25, // Editar calendário
        [EAttendantStatus.logout]: 26, // Offline
    }
} as const);

export const attendanceEntryCancelReasonTranslations = getTranslationsFields(typedScreenOptions({
    idSerializable: EAllSerializable.AttendanceEntryCancelReason,
    fields: typedFields<EEntryCancelReason>()({
        [EEntryCancelReason.Filter]: 1,
        [EEntryCancelReason.OptOut]: 2,
        [EEntryCancelReason.Recency]: 3,
        [EEntryCancelReason.ActiveChat]: 4,
        [EEntryCancelReason.Engagement]: 5,
        [EEntryCancelReason.Schedule]: 6,
        [EEntryCancelReason.ByUser]: 7,
        [EEntryCancelReason.Reschedule]: 8,
        [EEntryCancelReason.InvalidTemplate]: 9,
        [EEntryCancelReason.SendOnlyIfChannelOpen]: 10,
        [EEntryCancelReason.Blocklist]: 11,
    }),
} as const));


export const attendanceEventsScreenOption = typedScreenOptions({
    idSerializable: EAllSerializable.AttendanceEvents,
    fields: {
        attendanceEvents: 1, // Eventos de atendimento
        attendanceEventsIcon: 18,
    }
} as const);




export const allAttendanceTranslations = {
    ...getTranslationsFields(attendantStatusColorScreenOptions),
    ...getTranslationsFields(attendanceEventsScreenOption),
    ...getTranslationsFields(attendanceViewScreenOptions),
    ...serviceStatusScreenOptions,
    ...genericServiceStatusScreenOptions,
    ...getTranslationsFields(presenceStatusScreenOption),
    ...getTranslationsFields(attendantAllocationTypeScreenOptions),
    ...getTranslationsFields(attendantIslandTypeScreenOptions),
    ...attendanceEntryCancelReasonTranslations,
    ...copilotTranslations
};
