<div class="action-bar" [class.is-loading]="isLoading()">
  <chat-action-bar-window-component #chatBarWindow [ngClass]="{'action-bar-window--hidden': hideActionWindow()}"
    [handler]="getActionBarWindowHandler()"></chat-action-bar-window-component>

  <div *ngIf="showMacroList" @openClose>
    <app-macro-list [handler]="macrosHandler"></app-macro-list>
  </div>

  <div [style.min-height]="'calc(100% - '+windowBarHeight()+'px)'">
    <div class="action-bar__scribbler">
      <div class="action-bar__scribbler__input-container">
        <section *ngIf="!hasFieldEngagement" class="more-features">
          <button #moreFeaturesMenuTrigger="matMenuTrigger" mat-icon-button [matMenuTriggerFor]="moreFeaturesMenu" class="more-features-button" [disabled]="isLoading()" [class.more-features-button-rotate]="moreFeaturesMenuTrigger.menuOpen">
            <mat-icon>add_circle_outline</mat-icon>
          </button>
        </section>

        <div class="action-bar__scribbler__block" [class.has-field-engagement]="hasFieldEngagement"
          [class.show-as-calendar]="isFieldShowAsCalendar()">

          <ng-container *ngIf="hasFieldEngagement">
            <input *ngIf="!isFieldShowAsCalendar() else fieldAsCalendar" matInput name="field-response-message" class="field-response-input"
              placeholder="Digite aqui" [type]="getFormFieldType()" [leadZeroDateTime]="true" [hiddenInput]="true"
              [(ngModel)]="formFieldAnswer" [mask]="getFormFieldMask().formatMask"
              [dropSpecialCharacters]="!!getFormFieldMask().removePontuation">
          </ng-container>

          <div
            #contenteditableInput
            [attr.contenteditable]="!isLoading() ? 'true' : 'false'"
            [style.opacity]="isLoading() ? '0.6' : '1'"
            [style.display]="hasFieldEngagement ? 'none' : null"
            [attr.data-placeholder]="getInputPlaceholder()"
            class="chat-input__message nice-scroll notranslate"
            (keyup)="onKeyUp($event)"
            (keydown)="onKeyDown($event)"
            (paste)="onPaste($event)"
            (input)="onInput($event)"
            (focus)="updateEditingPosition()"
            (click)="updateEditingPosition()"
          >
          </div>
        </div>

        <div class="right-buttons" [class.has-field-engagement]="hasFieldEngagement">
          @if (!hasFieldEngagement) {
            <div class="small-right-buttons">
              <button mat-icon-button (click)="toggleEmojiBox()" [disabled]="isLoading()" class="small-size">
                <mat-icon class="mat-20">sentiment_satisfied_alt</mat-icon>
              </button>

              <!-- <button mat-flat-button [matMenuTriggerFor]="aiEditMenu" [disabled]="isLoading()" class="ai-edit-button fix-touch-area">
                <span>
                  Edite com IA
                  <mat-icon class="mat-16">auto_awesome</mat-icon>
                </span>
              </button> -->
            </div>

            @if (canSendAudioMessage) {
              <button mat-icon-button (click)="toggleMediaRecorder()" [disabled]="isLoading()">
                <mat-icon>mic</mat-icon>
              </button>
            }
          }

          <button mat-icon-button (click)="sendInteractionWrapper()" [disabled]="!canSendMessage() || isLoading()" [ngClass]="{'send-disabled': !canSendMessage()}">
            <mat-icon *ngIf="!isLoading() else sendLoading" svgIcon="send-plane"></mat-icon>
          </button>
        </div>
      </div>
      <div #emojibox [class]="emojiBoxClasses"></div>
    </div>
  </div>
</div>

<ng-template #sendLoading>
  <mat-spinner diameter="24" color="primary"></mat-spinner>
</ng-template>

<ng-template #fieldAsCalendar>
  <mat-calendar [(selected)]="calendarValue"></mat-calendar>
</ng-template>

<mat-menu #moreFeaturesMenu="matMenu">
  <button mat-menu-item [disabled]="isLoading()" (click)="openAttachments(true)">
    <mat-icon>folder</mat-icon>
    <span>Arquivos</span>
  </button>

  <button mat-menu-item [disabled]="isLoading()" (click)="openAttachments(true, true)">
    <mat-icon>image</mat-icon>
    <span>Fotos e vídeos</span>
  </button>

  <button mat-menu-item [disabled]="isLoading()" (click)="openMacroMenu()">
    <mat-icon>keyboard</mat-icon>
    <span>Macros</span>
  </button>
</mat-menu>

<mat-menu #aiEditMenu="matMenu">
  <button mat-menu-item [disabled]="isLoading()">
    <mat-icon>spellcheck</mat-icon>
    <span>Corretor de texto</span>
  </button>

  <button mat-menu-item [disabled]="isLoading()">
    <mat-icon>title</mat-icon>
    <span>Deixar Texto Mais Amigável</span>
  </button>

  <button mat-menu-item [disabled]="isLoading()">
    <mat-icon>post_add</mat-icon>
    <span>Enriquecer Texto</span>
  </button>

  <button mat-menu-item [disabled]="isLoading()">
    <mat-icon>translate</mat-icon>
    <span>Traduzir Texto para Inglês</span>
  </button>
</mat-menu>
