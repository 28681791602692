<div class="text-input-container">
    <div *ngIf="highlightElement" class="label-container">
        <label for="text-input">{{ textLabel }}:</label>
        <mat-icon
        *ngIf="showHelpIcon"
        class="help-icon"
        [@horizontalAppear]="'in'"
        style="height: 17px; color: var(--alert-900);"
        [matTooltip]="helpIconValue"
        matTooltipClass="mat-tooltip-warning"
        appHighlightEl="#botAdvancedTitle"
        >info</mat-icon>
    </div>
    <div *ngIf="!highlightElement" class="label-container">
        <label for="text-input">{{ textLabel }}:</label>
        <mat-icon
        *ngIf="showHelpIcon"
        class="help-icon"
        [@horizontalAppear]="'in'"
        style="height: 17px; color: var(--alert-900);"
        [matTooltip]="helpIconValue"
        matTooltipClass="mat-tooltip-warning"
        >info</mat-icon>
    </div>
    <div class="input-container">
        <input 
            #inputElement
            type="text"
            [(ngModel)]="inputValue"
            (ngModelChange)="inputValueChange.emit($event)" 
            (blur)="onBlur()"
            (click)="enableEditing()"
            [ngClass]="darkColorModeClass"
            matInput
        >
        <button 
            *ngIf="showValidation && isReadonly" 
            class="show-validation-button"
            matTooltip="Mostrar Validação"
            (click)="isSectionInput ? openMenuItemTextValidation() : openTextToShowValidation()">
            <mat-icon class="show-validation-icon">pivot_table_chart</mat-icon>
        </button>
        <img 
            src="../../../assets/icons/bpm/edit-pencil.svg" 
            alt="edit-pencil-icon"
            class="edit-icon"
            matTooltip="Editar"
            (click)="enableEditing()"
            [hidden]="!isReadonly"
        >
        <!-- <input 
            #inputElement
            type="text"
            [(ngModel)]="inputValue"
            (ngModelChange)="inputValueChange.emit($event)" 
            [readonly]="isReadonly"
            (blur)="onBlur()"
        > -->
        <!-- <button 
            *ngIf="showValidation && isReadonly" 
            class="show-validation-button"
            matTooltip="Mostrar Validação"
            (click)="openTextToShowValidation()">
            <mat-icon class="show-validation-icon">pivot_table_chart</mat-icon>
        </button> -->
        <!-- <img 
            src="../../../assets/icons/bpm/edit-pencil.svg" 
            alt="edit-pencil-icon"
            class="edit-icon"
            matTooltip="Editar"
            (click)="enableEditing()"
            [hidden]="!isReadonly"
        > -->
    </div>
</div>
