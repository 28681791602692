import { Injectable } from "@angular/core";
import { Avatar } from "@colmeia/core/src/business/avatar";
import { constant } from "@colmeia/core/src/business/constant";
import { Group } from "@colmeia/core/src/business/group";
import { IGroupJSON } from "@colmeia/core/src/comm-interfaces/business-interfaces";
import { IInteractionJSON } from "@colmeia/core/src/comm-interfaces/interaction-interfaces";
import { IInteractionLiveInfo } from "@colmeia/core/src/comm-interfaces/tracker-interfaces";
import { allSeverityRegistry } from "@colmeia/core/src/core-constants/tracker-qualifiers";
import { TArrayID } from "@colmeia/core/src/core-constants/types";
import { Interaction } from "@colmeia/core/src/interaction/interaction";
import { UberCache } from "@colmeia/core/src/persistency/uber-cache";
import { isGroupOnGenealogy } from "@colmeia/core/src/rules/filters";
import { getLastClockTickWithoutReplyOf, interactionReadableMessage } from "@colmeia/core/src/rules/interaction-filter";
import { TSerializableHeaderResponse } from "@colmeia/core/src/serializable/header";
import { EGroupOrderSelectorMode } from "@colmeia/core/src/shared-business-rules/const-text/views/group-order-and-search";
import { assign, getClock, isInvalid, isInvalidNumber, isValidRef, typedClone } from "@colmeia/core/src/tools/utility";
import { GroupOrderSelectorShortcutHandler, IGroupOrderSelectorShortcutClientCallback, TGroupOrderSelectorModeConfig } from "app/handlers/group-order-and-search.handler";
import { InteractionPersistorServices } from "app/services/interaction-persistor.service";
import { TIdGroupSet } from "app/supervisor-agent.model";
import { Observable, Subject } from "rxjs";
import {
    GroupSelectorShortcutHandler,
    IShortcutParentInstance
} from "../handlers/group-selector-shortcut.handler";
import { GroupSelectorShortcutHandlerList, sortGroupShortcutHandlerList } from "../model/chat-utils";
import { AttendanceService } from "./attendance.service";
import { IPlayerInfoChangedData, PlayerInfoService } from './player-info.service';
import { RoutingService } from "./routing.service";
import { SerializableHeaders } from "./serializable-headers";
import { SessionService } from "./session.service";
import { SocialNetworkDatabaseService } from "./social-network-database.service";
import { SusbcriptionContainerService } from "./subscriptions.service";
import { OldestRecentInteractionService } from './oldest-recent-interaction.service';

export interface IGroupShortcutStats {
    unreadMessages: number;
    lastMessage?: ILastMessage;
}

export interface ILastMessage {
    avatar: string;
    message: string;
    clocktick: number;
    idInteraction: string;
}


@Injectable({
    providedIn: 'root'
})
export class GroupShortcutHandlerService implements IShortcutParentInstance {

    private handlers: { [idgroup: string]: GroupSelectorShortcutHandler } = {};
    private stats: { [idGroup: string]: IGroupShortcutStats } = {};

    private _groupUpdate$: Subject<string> = new Subject();
    public groupUpdate(): Observable<string> {
        return this._groupUpdate$.asObservable()
    }

    constructor(
        private session: SessionService,
        private attendance: AttendanceService,
        private social: SocialNetworkDatabaseService,
        private router: RoutingService,
        private subscriptionContainerSvc: SusbcriptionContainerService,
        private playerInfoService: PlayerInfoService,
        private interactionPersistorSvc: InteractionPersistorServices,
        private oldestRecentInteractionSvc: OldestRecentInteractionService

    ) {
        this.subscribeToGroupInsertion();
        this.playerInfoService.listenPlayerInfoChanged()
            .subscribe((playerChangedData: IPlayerInfoChangedData) => {

                this.onGroupsArrivedFromWebSocket(playerChangedData.addedGroupsToPlayer);
            })
    }

    private onGroupsArrivedFromWebSocket(addedGroupsToPlayer: TIdGroupSet) {
        console.log('onGroupsArrivedFromWebSocket'.toUpperCase(), addedGroupsToPlayer)
        for (const groupId of addedGroupsToPlayer) {
            if (this.session.checkIfParticipantExistsOnPlayerCached(groupId)) {
                this.onGroupInsertedInDatabase(groupId);
            } else {
                const errorObj = {
                    fnName: "GroupShortcutHandlerService.onGroupsArrivedFromWebSocket",
                    message: `Player não tem participante no grupo de Id: ${groupId}`,
                    idGroup: groupId,
                }
                console.error(errorObj)
                this.interactionPersistorSvc.sendTrace(
                    allSeverityRegistry.warning,
                    "GroupShortcutHandlerService.onGroupsArrivedFromWebSocket",
                    errorObj
                );
            }
        }
    }

    private subscribeToGroupInsertion() {
        this.social.subscribeToNewGroup(this.onGroupInsertedInDatabase);
    }

    public groupOrderShortCurtHandler: GroupOrderSelectorShortcutHandler;
    public getGroupOrderShortCurtHandler(): GroupOrderSelectorShortcutHandler {
        if (isInvalid(this.groupOrderShortCurtHandler)) this.groupOrderShortCurtHandler = new GroupOrderSelectorShortcutHandler({
            clientCallback: {} as IGroupOrderSelectorShortcutClientCallback,
        });
        return this.groupOrderShortCurtHandler;
    }

    private safeAvatarName(idAvatar: string): string {
        if (UberCache.testCache(idAvatar)) {
            return Avatar.staticFactory(idAvatar).getNickName();
        }
        return '';
    }

    resetUnreadMessageCounting(idGroup: string) {
        if (this.stats[idGroup]) {
            this.stats[idGroup].unreadMessages = 0;
        }
    }

    private hasGroupStat(idGroup: string): boolean {
        return idGroup in this.stats;
    }

    private upsertGroupStat(idGroup, props: Partial<IGroupShortcutStats>) {
        this.stats[idGroup] = this.hasGroupStat(idGroup)
            ? { ...this.stats[idGroup], ...props }
            : { unreadMessages: 0, ...props };
    }

    onReceiveNotHydratedInteraction(interaction: IInteractionJSON): void {
        if (!this.hasGroupStat(interaction.idGroup)) return;

        this.upsertGroupStat(interaction.idGroup, {
            lastMessage: {
                clocktick: interaction.clockTick,
                message: interactionReadableMessage(interaction),
                avatar: this.safeAvatarName(interaction.participant.idAvatar),
                idInteraction: interaction.primaryID,
            }
        })

        this.oldestRecentInteractionSvc.upsertInteraction(interaction.idGroup,
            { clockTick: interaction.clockTick, idAvatar: interaction.participant.avatar.primaryID });

        if (
            this.session.avatarBelongsToMe(interaction.participant.idAvatar)
            || this.router.isInsideChat(interaction.idGroup)
        ) {
            return;
        }

        if (interaction.idInteractionType === constant.interactionType.standard.deleteInteraction) {
            this.stats[interaction.idGroup].unreadMessages--;
        } else {
            this.stats[interaction.idGroup].unreadMessages++;
        }

        this._groupUpdate$.next(interaction.idGroup);
    }

    showableShortcutHandlers: GroupSelectorShortcutHandler[];
    public updateShowableShortcutHandlers(): void {
        let handlers: GroupSelectorShortcutHandler[] = Object.values(this.handlers);

        if (this.attendance.isReadyToAttend()) {
            handlers = handlers.filter(handler => {
                const isVisibleGroup: boolean = this.attendance.isVisibleGroupDuringAttendance(handler.getGroupID());

                return isVisibleGroup;//&& !this.removedGroups[handler.getGroupID()]
            });
        } else {
            const idSocialNetwork = this.session.getCurrentSocialNetworkID();
            handlers = handlers.filter(handler => isGroupOnGenealogy(idSocialNetwork, handler.getGroup().getPlainGroupGenealogy()));
        }

        const groups: GroupSelectorShortcutHandlerList = sortGroupShortcutHandlerList(handlers);
        const attendanceGroups: GroupSelectorShortcutHandlerList = [];
        const otherGroups: GroupSelectorShortcutHandlerList = [];

        for (const group of groups) {
            const isAttendanceGroup: boolean = this.attendance.isInAttendDB(group.getGroupID());
            if (isAttendanceGroup) {
                const island = this.attendance.getIslandForGroup(group.getGroupID());
                const channel = this.attendance.getChannelForGroup(group.getGroupID());

                isValidRef(island) && group.setIsland(island);
                isValidRef(channel) && group.setChannel(channel);

                attendanceGroups.push(group);
            } else {
                otherGroups.push(group);
            }
        }

        const now: number = getClock();

        this.showableShortcutHandlers = [
            ...attendanceGroups.sort((group, otherGroup) => this.sortHandlers(group, otherGroup, now)),
            ...otherGroups,
        ];
    }

    public onChangeHandlers(): void {
        // @fernando
    }

    public get selectedOrder() {
        return this.getGroupOrderShortCurtHandler().getComponentParameter().selected;
    }

    public get config(): TGroupOrderSelectorModeConfig {
        return this.getGroupOrderShortCurtHandler().getComponentParameter().config;
    }

    //

    private getGroupLastClock(group: GroupSelectorShortcutHandler, now: number): number {
        const isInMemory: boolean = this.subscriptionContainerSvc.isInMemory(group.getGroupID());
        const fallbackClocktick: number = this.attendance.getInitInteractionServiceJSON(group.getGroupID())?.clockTick;

        if (isInMemory) {
            const interaction: Interaction = this.attendance.getInitInteractionService(group.getGroupID(), false);

            if (!interaction) return fallbackClocktick;

            const idAvatar: string = this.config.agent ?
                this.session.getParticipant(group.getGroupID()).getAvatar().getPrimaryID()  // AGENTE
                : interaction.getParticipant().getAvatar().getAvatarID();   // CLIENTE

            switch (this.selectedOrder) {
                case EGroupOrderSelectorMode.lastMessage: {
                    return interaction.getProfoundClockTick(group.getGroupID());
                }

                case EGroupOrderSelectorMode.startServiceChat: {
                    return interaction.getClockTick();
                }

                case EGroupOrderSelectorMode.timeWithoutAnswer: {
                    const lastClockWithoutReply: number = getLastClockTickWithoutReplyOf(idAvatar, interaction.getChildren(group.getGroupID()));
                    if (isInvalidNumber(lastClockWithoutReply)) {
                        return this.config.recent ? 0 : now;
                    }

                    return lastClockWithoutReply;
                }
            }

        } else {
            return fallbackClocktick;
        }
    }

    public sortToReversedOrderIfNecessary(): number {
        if (isValidRef(this.config) && !this.config.recent) {
            return -1;
        }
        return 1;
    }

    public sortHandlers(group: GroupSelectorShortcutHandler, otherGroup: GroupSelectorShortcutHandler, now: number): number {
        if (this.selectedOrder === EGroupOrderSelectorMode.alphabeticOrder) {
            const orderedArray: number = group.getGroupName().localeCompare(otherGroup.getGroupName())
            return orderedArray * this.sortToReversedOrderIfNecessary()
        }

        return (this.getGroupLastClock(otherGroup, now) - this.getGroupLastClock(group, now)) * this.sortToReversedOrderIfNecessary();
    }

    getShowableShortcutHandlers(): GroupSelectorShortcutHandler[] {
        return this.showableShortcutHandlers;
    }

    private groupHasAlreadyHandler(idGroup: string) {
        return idGroup in this.handlers;
    }

    public getStatsForGroup(idGroup: string): IGroupShortcutStats {
        return typedClone(this.stats[idGroup]);
    }

    public removeShortcut(idGroup: string) {
        delete this.handlers[idGroup];
    }

    async playerCachedChanged(): Promise<void> {
        const allGroups: TArrayID = this.session.getPlayerInfo().getGroupArrayID();
        const toCreateGroups: TArrayID = allGroups.filter(key => !(key in this.handlers));
        const oldGroupsToRemove: TArrayID = Object.keys(this.handlers).filter(key => !allGroups.includes(key));

        for (const idGroup of oldGroupsToRemove) {
            if (this.attendance.isInAttendDB(idGroup)) {
                this.attendance.removeAttendanceOfGroup(idGroup);
            }

            delete this.handlers[idGroup];
        }

        const needHeader = toCreateGroups.filter(key => !UberCache.testCache(key));
        const headers: TSerializableHeaderResponse = await SerializableHeaders.fetch(
            needHeader.map(id => ({ objectTypeID: constant.objectType.group, serializableID: id }))
        );
        const groupsToUpdate = Object.values(headers) as IGroupJSON[];

        this.social.updateGroups(groupsToUpdate);
    }

    private onGroupInsertedInDatabase = (idGroup: string) => {
        if (!this.groupHasAlreadyHandler(idGroup)) {
            if (this.session.checkIfParticipantExistsOnMultipleDbs(idGroup)) {

                this.upsertGroupStat(idGroup, { unreadMessages: 0 })

                const group = Group.staticFactory(idGroup);

                const info: IInteractionLiveInfo = group.getLastGroupNavigatorInteractionJSON();

                if (isValidRef(info)) {
                    this.upsertGroupStat(idGroup, {
                        lastMessage: {
                            message: info.text,
                            avatar: info.nameAvatar,
                            clocktick: info.clockTick,
                            idInteraction: info.primaryID,
                        }
                    });
                }

                this.handlers[idGroup] = new GroupSelectorShortcutHandler({
                    group: group,
                    service: this,
                    attendanceService: this.attendance
                });

                this.onChangeHandlers();
            }
        }
    };

    mustResortGroups() {

    }

}
