import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { ESortDirection } from "@colmeia/core/src/rules/relational-query";
import { ECRMTicketSortField, ICRMTicketSortConfig, TICRMTicketSortConfigArray } from "@colmeia/core/src/shared-business-rules/crm/crm-services/crm-runtime-interfaces";

@Component({
    selector: 'app-ticket-sort-picker',
    styleUrls: ['./ticket-sort-picker.component.scss'],
    templateUrl: './ticket-sort-picker.component.html'
})
export class TicketSortPickerComponent implements OnInit {

    @Input()
    public loading?: boolean;

    @Input()
    public sortConfigs?: TICRMTicketSortConfigArray;

    @Output()
    public sortConfigsChange?: EventEmitter<TICRMTicketSortConfigArray> = new EventEmitter();

    get sortConfig(): ICRMTicketSortConfig {
        return this.sortConfigs[0];
    }
    // public sortFieldLabels: { [key in TTicketSortFields]?: string } = {
    //     created_at: 'Criado',
    //     updated_at: 'Atualizado',
    // }

    public sortFields: { [key in ECRMTicketSortField]?: string } = {
        [ECRMTicketSortField.createdAt]: 'Criado',
        [ECRMTicketSortField.updatedAt]: 'Atualizado',
    }

    public sortDirections: { [key in ESortDirection]?: { label: string, icon: string } } = {
        [ESortDirection.DESC]: { label: 'Decrescente', icon: 'arrow_downward' },
        [ESortDirection.ASC]: { label: 'Crescente', icon: 'arrow_upward' },
    }

    constructor() {}

    ngOnInit(): void {
        
    }

    public setSortConfig(sortConfig: ICRMTicketSortConfig, field: ECRMTicketSortField, direction: ESortDirection) {
        sortConfig.field = field;
        sortConfig.direction = direction;
    }

}