import { ENTER } from '@angular/cdk/keycodes';
import { Location } from '@angular/common';
import { Component, InjectionToken, Injector, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { MatCheckboxChange } from "@angular/material/checkbox";
import { MatChipInputEvent } from '@angular/material/chips';
import { MatSelectChange } from "@angular/material/select";
import { MatSnackBar } from '@angular/material/snack-bar';
import { Avatar } from '@colmeia/core/src/business/avatar';
import { constant } from "@colmeia/core/src/business/constant";
import { Serializable } from '@colmeia/core/src/business/serializable';
import { EDelivery360Action, IAvatarJSON } from '@colmeia/core/src/comm-interfaces/barrel-comm-interfaces';
import { EServiceChatType } from '@colmeia/core/src/comm-interfaces/service-group-interfaces';
import { TExtendedParticipant, TExtendedParticipantArray } from "@colmeia/core/src/core-constants/types";
import { ENonSerializableListMode, IListNonSerializablesMatch } from '@colmeia/core/src/dashboard-control/dashboard-request-interfaces';
import { IFormSchema, INonserializableSchemaResponseServer } from '@colmeia/core/src/general-form/general-form-interface';
import { IGeneralFileMetadata } from '@colmeia/core/src/request-interfaces/files-interfaces';
import { TSerializableHeaderResponse } from "@colmeia/core/src/serializable/header";
import { socialConfig, whatsTel } from '@colmeia/core/src/shared-business-rules/bot/bot-action-config-db';
import { IBasicAsset, KBAssetType } from "@colmeia/core/src/shared-business-rules/bot/bot-asset-model";
import { IContentBasicAsset, TContentAssetArray } from "@colmeia/core/src/shared-business-rules/bot/bot-content-model";
import { emptyMetaEngagement } from '@colmeia/core/src/shared-business-rules/bot/engagement-function';
import { isValidURL } from '@colmeia/core/src/shared-business-rules/connections/connections-functions';
import {
    IConnectionRouteServer,
    IConnectionServer
} from '@colmeia/core/src/shared-business-rules/connections/endpoint-model';
import { pickTranslations } from "@colmeia/core/src/shared-business-rules/const-text/all-serializables";
import { gTranslations } from '@colmeia/core/src/shared-business-rules/const-text/translations';
import {
    IDServiceParameters,
    IServiceDeployedServices
} from '@colmeia/core/src/shared-business-rules/deployed-services/deployed-services';
import { EMetadataEngagementType, IMetadataRegister, TIMetadataServiceRegisterArray } from '@colmeia/core/src/shared-business-rules/metadata/meta-engagement';
import { EMetadataNames } from '@colmeia/core/src/shared-business-rules/metadata/metadata-db';
import { IVariable, TIVariablesArray } from '@colmeia/core/src/shared-business-rules/metadata/metadata-util-interfaces';
import {
    ENonSerializableObjectType,
    INonSerializable
} from '@colmeia/core/src/shared-business-rules/non-serializable-id/non-serializable-id-interfaces';
import { NsTypeToInterface } from '@colmeia/core/src/shared-business-rules/non-serializable-id/non-serializable-interface-mapper';
import { ESearchAllowedType } from "@colmeia/core/src/shared-business-rules/search";
import { GenericSharedService } from '@colmeia/core/src/shared-business-rules/shared-services/services/generic.shared.service';
import {
    IAvailableChannelForService,
    TAvailableChannelForServiceArray
} from '@colmeia/core/src/shared-business-rules/social-cc/social-cc-types';
import { delivery360ConfigDB } from "@colmeia/core/src/shared-business-rules/social-cc/social-cc.config";
import {
    ISocialMediaConnection,
    TIISocialMediaConnectionArray,
    socialMediaToAddress
} from '@colmeia/core/src/shared-business-rules/social-media/social-media.model';
import { EFunctionContext } from '@colmeia/core/src/shared-business-rules/user-function/user-function-model';
import { arrayToHash, formatMessage, getReadableUniqueID, isInvalid, isValidArray, isValidRef, isValidString, typedClone } from '@colmeia/core/src/tools/utility';
import { useClientPredicates } from '@colmeia/core/src/tools/utility-types';
import { BrandTheme } from 'app/components/color-theme-builder/brand-theme.functions';
import { WebchatPreviewViewer } from 'app/components/color-theme-builder/webchat-preview-viewer/webchat-preview-viewer.component';
import { RootComponent } from 'app/components/foundation/root/root.component';
import { GenericDashboardEditHandler } from 'app/handlers/generic-dashboard-edit.handler';
import { INonSerializableSelectHandler } from 'app/handlers/non-serializable-select.handler';
import { INSPickerHandlerParameter, NSPickerHandler } from 'app/handlers/ns-picker/ns-picker.handler';
import { IAssetAdderHandler } from 'app/model/dashboard/asset-adder.model';
import { ColorThemeBuilderService, EPreviewType, IOppeningColorBuilderData } from 'app/services/color-theme-builder.service';
import { DashboardSocialMediaService } from 'app/services/dashboard-social-media.service';
import { DashboardFileService } from 'app/services/dashboard/dashboard-file.service';
import { DashBoardService } from 'app/services/dashboard/dashboard.service';
import { GeneralFormService } from 'app/services/general-form.service';
import { EInteractiveButton, GlobalWarningService, INTERACTIVE_NO, INTERACTIVE_YES } from 'app/services/global-warning.service';
import { SnackMessageService } from 'app/services/snack-bar';
import { indent } from 'indent.js';
import { mapKeys } from 'lodash';
import { environment } from "../../../../../environments/environment-client";
import { CmPrintField, CmPrintHandler } from "../../../../handlers/cm-print.handler";
import {
    EParticipantClickTypeMode,
    EParticipantSelectorAction, IParticipantSelectorClient, IParticipantSelectorParameter,
    IParticipantSelectorToRolesSelected,
    ParticipantSelectorHandler
} from "../../../../handlers/participant-selector.handler";
import { ExtendedParticipantService } from "../../../../services/extended-participant.service";
import { LookupService } from "../../../../services/lookup.service";
import { SerializableHeaders } from "../../../../services/serializable-headers";
import { copyToClickBoard } from "../../../../utils/copy";
import { DashboardWindowEditorRef } from '../../dashboard-foundation/colmeia-window/colmeia-window-edit-ref';
import { GenericDashboardEditComponent } from '../../dashboard-foundation/generic-dashboard-edit/generic-dashboard-edit.component';
import { ISingleContentAssetAdderHandler } from "../../single-content-asset-editor/single-content-asset-editor.handler";
import { DeployedServicesService } from '../deployed-services.service';
import { ETagPickerHandlerMode, TagPickerHandler } from 'app/handlers/tag-picker-handler';
import { ETagType, IServerColmeiaTag } from '@colmeia/core/src/shared-business-rules/colmeia-tags/tags';
import { NSSharedService } from '@colmeia/core/src/shared-business-rules/shared-services/services/ns.shared.service';
import { EAppBaseURL, EColmeiaEnvironment, environmentToLoader, getEnvironmentByAppUrl } from '@colmeia/core/src/shared-business-rules/production-deploy/prod-deploy-model';
import { getCurrentEnvironmentName } from 'app/model/client-utility';


interface IFilesSchemaSelectable {
    files: IGeneralFileMetadata[],
    schema: IFormSchema,
}


interface IMappedSelectables {
    [idSchemma: string]: IFilesSchemaSelectable
}

const initialDepService: IServiceDeployedServices = {
    nName: '',
    parameters: {
        serviceType: undefined,
        isActive: undefined,
        config: {
            idBotRoot: undefined,
            expireBotComm: undefined
        }
    },
    nsType: undefined,
    ident: undefined,
    messages: {
        expirationMessage: undefined,
        lastRequestStillUnprocessed: undefined,
        resetMessage: undefined,
        transcriptionMessage: undefined,
        unableToResetSessionMessage: undefined,
        failToTranscriptMessage: undefined,
        feedbackDuringCustomerAllocation: undefined
    }
}

export type TDeployedServiceCreateParams = {
    deployedService: IServiceDeployedServices
}

export type TDeployedServiceCreateResponse = {
    saved: boolean
}

export const DEPLOYED_SERVICE_ID = new InjectionToken<string>('DEPLOYED_SERVICE_ID');

@Component({
    selector: 'app-dashboard-deployed-service-edit',
    templateUrl: './dashboard-deployed-service-edit.component.html',
    styleUrls: ['./dashboard-deployed-service-edit.component.scss'],
})
export class DashboardDeployedServiceEditComponent extends RootComponent<
    'sdkMetadataId' |
    'initialMetadataId' |
    'deployedServices' |
    'details' |
    'serviceType' |
    'defaultConfig' |
    'customService' |
    'group' |
    'activateService' |
    'generateRoutingKey' |
    'routingKey' |
    'availableChannels' |
    'save' |
    'welcomeText' |
    'copyRoute' |
    'copied' |
    'preferences' |
    'serviceName' |
    'add' |
    'editing' |
    'default' |
    'active' |
    'macroPackages' |
    'selectRoot' |
    'addException' |
    'selectAFile' |
    'selectTheForm' |
    'addBots' |
    'addFiles' |
    'warningNoChannelsTitle' |
    'warningNoChannels' |
    'addRoutes' |
    'expireTimeInHours' |
    'loadServiceListError'
    | EServiceChatType
> implements OnInit {
    readonly allowedDomainsSeparatorKeysCodes = [ENTER] as const
    deployedService: IServiceDeployedServices;

    allowedEngagementTypes: EMetadataEngagementType[] = [EMetadataEngagementType.smartMapper, EMetadataEngagementType.CRMTransformation, EMetadataEngagementType.NewCondition, EMetadataEngagementType.layout];
    availableSocialMedias: TIISocialMediaConnectionArray = [];
    channelsByType: { [channelType: string]: TAvailableChannelForServiceArray } = {};
    nsSelectBotRootHandler: NSPickerHandler;
    nsSelectSDKMedatadataHandler: NSPickerHandler;
    nsSelectInitialMetadataHandler: NSPickerHandler;
    groupContainerPS: ParticipantSelectorHandler;
    availableConnections: IConnectionServer[] = [];
    routes: IConnectionRouteServer[];
    route: IConnectionRouteServer;
    selectedIdSchema: string;
    selectables: IFilesSchemaSelectable[];
    mappedSelectables: IMappedSelectables = {}
    namedAccountHandler: NSPickerHandler;
    blocklistRTFHandler: NSPickerHandler;
    isSaving: boolean;
    genericDashboardEdit: GenericDashboardEditHandler;
    public cmPrint: CmPrintHandler;
    allServiceType: EServiceChatType[] = [
        EServiceChatType.socialReceptiveCallcenter,
        EServiceChatType.panic,
        EServiceChatType.embeddedColmeiaChat,
        EServiceChatType.internalSupport,
        EServiceChatType.service,
        EServiceChatType.inactives,
    ];
    public botAvatarSelector: ParticipantSelectorHandler;
    private sdkMetadata: INonserializableSchemaResponseServer;
    private costumerFormularyMetadataNS: INonserializableSchemaResponseServer;
    private metadataHash: Record<string, INonserializableSchemaResponseServer> = {}
    private costumerMetadata: INonserializableSchemaResponseServer;
    public metadataFormsNames: Record<string, string> = {};
    public costumerMetadataEngagements: TIMetadataServiceRegisterArray = [];
    public costumerMetadataVariables: TIVariablesArray = [];
    rtfParamsHandler: NSPickerHandler;
    userFunctionPickerHandler: NSPickerHandler;

    @ViewChild(GenericDashboardEditComponent, { static: false })
    gHomeEditRef: GenericDashboardEditComponent;

    channelsWithoutVoiceSupport: string[] = [];

    widgetTitleHandler: ISingleContentAssetAdderHandler;
    widgetDescriptionHandler: IAssetAdderHandler;

    constructor(
        private deployedSvc: DeployedServicesService,
        private dashSocialMediaSvc: DashboardSocialMediaService,
        private location: Location,
        private dashFileSvc: DashboardFileService,
        private formSvc: GeneralFormService,
        private dashboardSvc: DashBoardService,
        private extendend: ExtendedParticipantService,
        private lookupSVC: LookupService,
        private snackSvc: MatSnackBar,
        private service: DashboardWindowEditorRef<IServiceDeployedServices>,
        private snackMessageSvc: SnackMessageService,
        private globalWarningSvc: GlobalWarningService,
        private colorBuilderSvc: ColorThemeBuilderService,
        private viewRef: ViewContainerRef,
        private injector: Injector,

    ) {
        super({
            ...pickTranslations(gTranslations.deployedServices, [
                'sdkMetadataId',
                'initialMetadataId',
                'deployedServices',
                'loadServiceListError',
                'expireTimeInHours',
                'macroPackages',
                'selectRoot',
                'selectAFile',
                'selectTheForm',
                'addBots',
                'addRoutes',
                'availableChannels',
                'serviceType',
                'customService',
                'activateService',
                'generateRoutingKey',
                'routingKey',
                'group',
                'welcomeText',
                'copyRoute',
                'copied',
                'preferences',
                'addException',
                'add',
                'defaultConfig',
                'serviceName',
                'editing',
                'addFiles',
                'warningNoChannelsTitle',
                'warningNoChannels'
            ]),
            ...pickTranslations(gTranslations.common, [
                'default',
                'details',
                'save',
                'active',
            ]),
            ...pickTranslations(gTranslations.fragments, [
                EServiceChatType.service,
                EServiceChatType.panic,
                EServiceChatType.internalSupport,
                EServiceChatType.socialReceptiveCallcenter,
                EServiceChatType.inactives,
                EServiceChatType.embeddedColmeiaChat,
            ])
        });
    }

    public onBotAvatarSelected(selected: TExtendedParticipantArray): void {
        this.parameters.embedded.botAvatar = isValidArray(selected) ? selected[0].getPrimaryID() : undefined;
    }

    set trustBrowserFingertipOnUserIdentity(value) {
        if (this.parameters?.embedded) {
            const previous = this.parameters.embedded.trustBrowserFingertipOnUserIdentity;
            this.parameters.embedded.trustBrowserFingertipOnUserIdentity = value;
            if (value && (value !== previous)) this.showTrustBrowserFingertipOnUserIdentityMessage();
        }
    }

    showTrustBrowserFingertipOnUserIdentityMessage() {
        const message = formatMessage(`
            Atenção! O ID único gerado pela Colmeia tem como base um algoritmo com 140 parâmetros diferentes (e.g IP, browser, sistema operacional, equipamento, som etc).
            Todavia, apesar de improvável, não há 100% de garantias de que o código gerado será único.
        `);

        GenericSharedService.show({
            message,
            isWarning: true,
        });
    }

    get trustBrowserFingertipOnUserIdentity() {
        return this.parameters.embedded?.trustBrowserFingertipOnUserIdentity;
    }

    get isWebChatEmbedded(): boolean {
        return this.parameters.serviceType === EServiceChatType.embeddedColmeiaChat;
    }


    //
    public async initBotAvatarSelector(): Promise<void> {
        const avatarArr: Avatar[] = [];

        if (isValidRef(this.parameters.embedded.botAvatar)) {
            const res: TSerializableHeaderResponse = await SerializableHeaders.fetch([{
                serializableID: this.parameters.embedded.botAvatar,
                objectTypeID: constant.objectType.avatar,
            }]);
            for (const avatarJSON of Object.values(res)) {
                avatarArr.push(Avatar.factoryMessage(avatarJSON as IAvatarJSON));
            }
        }

        this.botAvatarSelector = new ParticipantSelectorHandler({
            typesAllowed: [ESearchAllowedType.people],
            typesOfActionsOnEachElementSelected: [EParticipantSelectorAction.Remove],
            alreadySelectedList: avatarArr,
            clientCallback: <IParticipantSelectorClient>{
                onParticipantsSelected: (selected: TExtendedParticipantArray) => this.onBotAvatarSelected(selected),
            },
            operationMode: EParticipantClickTypeMode.SendToParent,
            maxNumberOfParticipants: 1,
            isEditableTypesSelected: false,
        });
    }

    public initGenericDashboardEdit(): void {
        this.genericDashboardEdit = this.service.editHandler;
    }

    private buildRTFParamPickerHandler(): void {
        this.rtfParamsHandler = new NSPickerHandler(<INSPickerHandlerParameter>{
            title: 'Fusão por parâmetros de configuração',
            clientCallback: {
                onSaveNSCallback: (nsSelected: INonSerializable) => {
                    this.deployedService.parameters.idRTFParamBody = nsSelected ? nsSelected.idNS : null
                },
            },
            demandedTag: undefined,
            maxSelections: 1,
            listMode: ENonSerializableListMode.child,
            nonSerializablesIds: this.deployedService.parameters.idRTFParamBody ? [this.deployedService.parameters.idRTFParamBody] : [],
            nsType: ENonSerializableObjectType.smartFlowRTFParamBody,
            genericNonSerializableService: this.dashboardSvc.genericNonSerializableService,
        })
    }

    buildUserFunctionPickerHandler() {
        const matchItem: IListNonSerializablesMatch = useClientPredicates<NsTypeToInterface[ENonSerializableObjectType.userFunction]>()
            ($ => ({ [$.context]: EFunctionContext.ShakingHands }));

        this.userFunctionPickerHandler = new NSPickerHandler(<INSPickerHandlerParameter>{
            title: 'Fusão por parâmetros de função',
            clientCallback: {
                onSaveNSCallback: (userFunctionSelected: INonSerializable) => {
                    this.deployedService.parameters.idFunctionShakingHands = userFunctionSelected ? userFunctionSelected.idNS : null
                }
            },
            demandedTag: undefined,
            nonSerializablesIds: this.deployedService.parameters.idFunctionShakingHands ? [this.deployedService.parameters.idFunctionShakingHands] : [],
            match: [matchItem],
            nsType: ENonSerializableObjectType.userFunction,
            genericNonSerializableService: this.dashboardSvc.genericNonSerializableService
        });
    }

    public urlShakingHandsFusionHandler: NSPickerHandler;
    private buildNamedAccountPickerHandler(): void {
        this.namedAccountHandler = new NSPickerHandler(<INSPickerHandlerParameter>{
            title: 'Fusão pelo identificador do canal',
            clientCallback: {
                onSaveNSCallback: (nsSelected: INonSerializable) => {
                    this.deployedService.parameters.idNamedAccount = nsSelected ? nsSelected.idNS : null
                },
            },
            demandedTag: undefined,
            maxSelections: 1,
            nonSerializablesIds: this.deployedService.parameters.idNamedAccount ? [this.deployedService.parameters.idNamedAccount] : [],
            nsType: ENonSerializableObjectType.realTimeFusion,
            genericNonSerializableService: this.dashboardSvc.genericNonSerializableService,
        })
    }

    private buildUrlShakingHandsFusionHandler(): void {
        this.urlShakingHandsFusionHandler = this.dashboardSvc.easyCreateNSPickerHandler({
            title: 'Fusão pela URL',
            clientCallback: {
                onSaveNSCallback: (nsSelected: INonSerializable) => {
                    this.deployedService.parameters.idUrlShakingHandsFusion = nsSelected ? nsSelected.idNS : null
                },
            },
            useDemandedTag: false,
            selectedId: this.deployedService.parameters.idUrlShakingHandsFusion,
            nsType: ENonSerializableObjectType.realTimeFusion,
        });
    }

    get selectedFileIdNS() {
        return this.deployedService.parameters.idBotFile
    }

    set selectedFileIdNS(value) {
        this.deployedService.parameters.idBotFile = value
    }

    public customerFormularyMetadataRegister: IMetadataRegister[] = [];
    public costumerEngagementVariables: TIVariablesArray = [];

    async ngOnInit() {
        this.deployedService = this.service.object;

        this.consistency();

        this.buildBlocklistRTFHandler();
        this.buildNamedAccountPickerHandler();
        this.buildRTFParamPickerHandler();
        this.buildUrlShakingHandsFusionHandler();
        this.buildUserFunctionPickerHandler();
        this.buildHandlersBasedOnType();
        this.loadStuff().then(() => {
            this.defineChannelsWithoutVoiceSupport();
        });
        this.initGenericDashboardEdit();
        this.initMessagesHandlers();
        this.initMarketingEventsPerfNSPickers();

        if (this.isWebChatEmbedded) {
            this.initWelcomePhrasesAndStartButtonsAssetHandlers()
        }

        this.service.onSaveButtonClick(() => this.save())

    }

    public welcomeMessagePhrasesHandler: IAssetAdderHandler;
    public startButtonsHandler: IAssetAdderHandler;

    private initWelcomePhrasesAndStartButtonsAssetHandlers() {
        this.welcomeMessagePhrasesHandler = this.getAssetAdderHandler(this.parameters.embedded.welcomePhrases, {
            title: "Mensagens de boas-vindas"
        });
        this.startButtonsHandler = this.getAssetAdderHandler(this.parameters.embedded.startButtons, {
            title: "Mensagens de inicialização",
        });
    }

    private getAssetAdderHandler(assets: TContentAssetArray, override?: Partial<IAssetAdderHandler>): IAssetAdderHandler {
        return <IAssetAdderHandler>{
            saveAsset(newAsset: IContentBasicAsset, assets: Array<IBasicAsset>, isEdit?: boolean): Promise<boolean> {
                const index = assets.findIndex(ast => ast.idAsset === newAsset.idAsset);
                if (index > -1) {
                    assets[index] = newAsset;
                } else {
                    assets.push(newAsset);
                }
                return Promise.resolve(true);
            },
            removeAsset(index: number, assets: Array<IBasicAsset>): Promise<boolean> {
                assets.splice(index, 1);
                return Promise.resolve(true);
            },
            assets,
            assetTypesEnabled: [
                KBAssetType.content,
            ],
            enableChangePositions: true,
            ...override,
        }
    }



    private consistency() {
        if (this.isWebChatEmbedded) {
            this.parameters.embedded.welcomePhrases ??= [];
            this.parameters.embedded.startButtons ??= [];
            this.parameters.embedded.customerExitControl ||= {};
        }
    }

    private buildBlocklistRTFHandler() {
        this.blocklistRTFHandler = new NSPickerHandler(<INSPickerHandlerParameter>{
            title: 'RTF Blocklist',
            clientCallback: {
                onSaveNSCallback: (ns: INonSerializable) => {
                    this.deployedService.parameters.idRTFBlacklist = ns?.idNS;
                },
            },
            demandedTag: undefined,
            maxSelections: 1,
            nonSerializablesIds: this.deployedService.parameters.idRTFBlacklist
                ? [this.deployedService.parameters.idRTFBlacklist]
                : [],
            nsType: ENonSerializableObjectType.realTimeFusion,
            genericNonSerializableService: this.dashboardSvc.genericNonSerializableService,
        })
    }

    private async buildHandlersBasedOnType() {
        if (this.isCallcenter() || this.isEmbedded()) {
            this.loadNSSelectBotHandler();
        }
        if (this.isEmbedded()) {
            this.initEmbeddedConfigIfNeed()
            this.initMetadataHandler();
            this.initGroupContainerPSHandler();
            this.initCmPrintHandler();
            this.initBotAvatarSelector();
            this.initWidgetTitleAndDescriptionHandler();
        }

        if (isValidRef(this.genericDashboardEdit)) this.genericDashboardEdit.getComponentParameter().nser = this.deployedService;
    }

    private initWidgetTitleAndDescriptionHandler() {
        this.widgetTitleHandler = {
            title: 'Título',
            assetTypeEnabled: [KBAssetType.content],
            content: this.deployedService.parameters.embedded.title
                ? [this.deployedService.parameters.embedded.title]
                : [],
            saveCallBack: (contents: TContentAssetArray) => {
                this.deployedService.parameters.embedded.title = contents[0];
            },
        };

        if (!this.deployedService.parameters.embedded?.desc) {
            this.deployedService.parameters.embedded.desc = [];
        }

        this.widgetDescriptionHandler = {
            title: 'Descrição',
            assets: this.deployedService.parameters.embedded?.desc,
            assetTypesEnabled: [KBAssetType.content],
            maxAssets: Number.MAX_SAFE_INTEGER,
            onlyText: true,
            saveAsset: async (asset, assets, isEdit) => {
                const index = assets.findIndex(ast => ast.idAsset === asset.idAsset);
                if (index > -1) {
                    assets[index] = asset;
                } else {
                    assets.push(asset);
                }
                return true;
            },
            removeAsset: async (index, assets) => {
                assets.splice(index, 1);
                return true;
            },
            // varEditorMode: EVarEditorEntityType.SchemaProperty,
        }
    }

    private initCmPrintHandler(): void {
        this.cmPrint = CmPrintHandler.factory({
            items: [
                CmPrintField.Texts('Domínios', this.deployedService.parameters.embedded.allowedDomains, {
                    enableEditing: true,
                    enableAdd: true
                }),
            ],
            clientCallback: this,
            enableStyles: {
                listItem: true
            },
        });
    }

    public addAllowedDomain(event: MatChipInputEvent) {
        const value = (event.value || '').trim();

        if (value && isValidURL(value)) {
            const domainOrigin = this.extractDomainWithProtocol(value);
            this.deployedService.parameters.embedded.allowedDomains.push(domainOrigin);
            event.input.value = '';
        } else {
            this.snackSvc.open("Digite uma url válida", "Fechar", { duration: 4000 });
        }
    }

    private extractDomainWithProtocol(input: string): string {
        // Using regex to extract the base domain
        const regex = /^([a-zA-Z]+:\/\/[^/]+)|([a-zA-Z]+:\/\/\*\.[^/]+)/;
        const match = input.match(regex);

        // Extracted base domain or return the original input if no match
        return match ? match[0] : input;
    }

    public removeAllowedDomain(domain: string) {
        const { allowedDomains } = this.deployedService.parameters.embedded;
        const index = allowedDomains.indexOf(domain);
        allowedDomains.splice(index, 1);
    }

    private async getContainerGroupSelectedParticipant(): Promise<TExtendedParticipantArray> {
        if (isValidRef(this.deployedService.parameters.embedded) && isValidRef(this.deployedService.parameters.embedded.userChatContainerGroup)) {
            return this.extendend.getExtendedParticipants([this.deployedService.parameters.embedded.userChatContainerGroup]);
        }
        return [];
    }

    private async initGroupContainerPSHandler(): Promise<void> {
        const alreadySelectedList: TExtendedParticipantArray = await this.getContainerGroupSelectedParticipant();
        const that = this;
        const parameter: IParticipantSelectorParameter = {
            alreadySelectedList,
            typesOfActionsOnEachElementSelected: [
                EParticipantSelectorAction.Remove
            ],
            typesAllowed: [
                ESearchAllowedType.groups
            ],
            clientCallback: {
                onRolesSelectedChange(participantToRoles: IParticipantSelectorToRolesSelected) { },
                onParticipantsSelected(selected: Array<TExtendedParticipant>, excluded: Array<TExtendedParticipant>): void {
                    for (const exclude of excluded) {
                        that.deployedService.parameters.embedded.userChatContainerGroup = undefined;
                    }
                    for (const current of selected) {
                        that.deployedService.parameters.embedded.userChatContainerGroup = current.getPrimaryID();
                    }
                }
            },
            isEditableTypesSelected: true,
            operationMode: EParticipantClickTypeMode.SendToParent,
            maxNumberOfParticipants: 1,
            overwritePartipantsWhenFull: true
        };
        this.groupContainerPS = new ParticipantSelectorHandler(parameter);
    }

    public loadNSSelectBotHandler() {
        this.nsSelectBotRootHandler = this.getNSSelectBotHandler();
    }

    private async loadStuff() {
        this.availableSocialMedias = await this.dashSocialMediaSvc.getSocialMedias();
        this.filterChannelTypes();
        await this.loadSelectables();
        await this.loadSelectedIdSchema();
        this.availableConnections = await this.dashboardSvc.genericNonSerializableService.getChildren<IConnectionServer>(null, ENonSerializableObjectType.connection);
    }

    async loadSelectedIdSchema() {
        const foundSelectableFromSelectedFileIdNS = this.selectables.find(selectable => selectable.files.some(file => file.idNS === this.selectedFileIdNS))

        if (!foundSelectableFromSelectedFileIdNS) return;

        const { schema: { idSchemma: selectedIdSchema } } = foundSelectableFromSelectedFileIdNS

        this.selectedIdSchema = selectedIdSchema;

    }



    async loadSelectables() {
        const [schemas, files] = await Promise.all([
            this.formSvc.getSchemas(this.dashboardSvc.defaultTag),
            this.dashFileSvc.getFileMetadataList({ demandedTag: this.dashboardSvc.defaultTag }),
        ]);

        this.selectables = schemas
            .map(schema => {
                const { idSchemma } = schema;

                const selectable: IFilesSchemaSelectable = {
                    schema,
                    files: files
                        .filter(file => file.idSchemma === idSchemma)
                };

                return selectable;
            })
            .filter((item: IFilesSchemaSelectable) => isValidArray(item.files))
            ;

        this.mappedSelectables = mapKeys(
            this.selectables,
            selectable => selectable.schema.idSchemma
        );

    }

    getNewNSPicker(previousPicker: INonSerializableSelectHandler): NSPickerHandler {
        const handler: NSPickerHandler = this.dashboardSvc.easyCreateNSPickerHandler({
            title: previousPicker.title,
            nsType: previousPicker.nsType,
            selectedId: previousPicker.selectedNS,
            clientCallback: this,
            idParent: previousPicker.idParent,
            useDemandedTag: false
        });

        return handler;
    }


    onSaveNSCallback(nsSelected: INonSerializable, nsType: ENonSerializableObjectType) {
        switch (nsType) {
            case ENonSerializableObjectType.bot:
                this.deployedService.parameters.config.idBotRoot = nsSelected ? nsSelected.idNS : null;
                break;
        }
    }


    private getNSSelectBotHandler(): NSPickerHandler {
        return new NSPickerHandler(<INSPickerHandlerParameter>{
            nsType: ENonSerializableObjectType.bot,
            maxSelections: 1,
            nonSerializablesIds: [this.deployedService.parameters.config.idBotRoot],
            clientCallback: {
                onSaveCallback: (nsers, nstype) => {
                    this.deployedService.parameters.config.idBotRoot = isValidArray(nsers) ? nsers[0].idNS : undefined
                }
            },
            demandedTag: undefined,
            genericNonSerializableService: this.dashboardSvc.genericNonSerializableService,
            title: this.translations.selectRoot.value,
            idParent: null
        })
    }

    get allowVoiceCommandsOnBot(): boolean {
        return this.deployedService.parameters.allowVoiceCommandsOnBot
    }

    set allowVoiceCommandsOnBot(val: boolean) {
        this.deployedService.parameters.allowVoiceCommandsOnBot = val
    }

    get doNotSendTranscriptFeedbackToCustomer(): boolean {
        return this.deployedService.parameters.doNotSendTranscriptFeedbackToCustomer;
    }

    set doNotSendTranscriptFeedbackToCustomer(value: boolean) {
        this.deployedService.parameters.doNotSendTranscriptFeedbackToCustomer = value;
    }

    get ignoreCampaignWhenReffered(): boolean {
        return this.deployedService.parameters.ignoreCampaignWhenReffered;
    }

    set ignoreCampaignWhenReffered(value: boolean) {
        this.deployedService.parameters.ignoreCampaignWhenReffered = value;
    }

    get keyWordReset(): string {
        return this.deployedService.parameters.keyWordForReset
    }

    set keyWordReset(val: string) {
        this.deployedService.parameters.keyWordForReset = val
    }

    changeCheck(event: MatCheckboxChange) {
        this.allowVoiceCommandsOnBot = event.checked;
    }

    private filterChannelTypes(): void {
        Object.values(EDelivery360Action).map(ct => {
            this.channelsByType[ct] = this.getAllChannelsByType(ct);
        });
    }

    get channelTypes(): any {
        const channelTypes = Object.keys(this.channelsByType).filter(cn => this.channelsByType[cn].length > 0);
        return channelTypes;
    }

    getSocialMediaTitle(socialMediaIDNS: string): string {
        const socialMedia = this.availableSocialMedias.find(sm => sm.idNS === socialMediaIDNS);
        return socialMedia.nName;
    }

    isActiveChange($event: boolean) {

    }

    private defineChannelsWithoutVoiceSupport() {
        this.channelsWithoutVoiceSupport = this.parameters?.comm
            ?.filter(c => c && !delivery360ConfigDB[c.e360Provider].hasVoiceSupport)
            .map(c => this.getSocialMediaTitle(c.idSocialMedia)) || [];
    }

    public getAllChannelsByType(channelType: string): TAvailableChannelForServiceArray {
        const channels = this.availableSocialMedias
            .filter(socialMedia => socialMedia.socialMediaType === channelType)
            .map(socialMedia => {
                return {
                    idSocialMedia: socialMedia.idNS,
                    address: this.getChannelAddress(socialMedia),
                    e360Provider: socialMedia.socialMediaType,
                }
            });

        return channels;
    }

    private getChannelAddress(socialMedia: ISocialMediaConnection): any {
        return socialMediaToAddress(socialMedia);
    }

    getChannelPlaceholder(channelType: string) {
        const serializable = Serializable.staticFactory(channelType);
        return serializable.getName();
    }

    onChannelSelect(event: MatSelectChange): void {
        const selectedChannels: TAvailableChannelForServiceArray = event.value;
        if (!this.serviceItemChannels.find(channel => channel.e360Provider === EDelivery360Action.Delivery360WhatsApp)) {
            delete this.parameters.welcomeText;
        }
        this.parameters.comm = selectedChannels;
        this.defineChannelsWithoutVoiceSupport();
    }

    isChannelDisabled(channel: IAvailableChannelForService): boolean {
        const channelOfSelectedType = this.serviceItemChannels.find(ch => ch.e360Provider === channel.e360Provider);
        return isInvalid(channelOfSelectedType)
            ? false
            : channelOfSelectedType.idSocialMedia === channel.idSocialMedia
                ? false
                : true;
    }

    get parameters(): IDServiceParameters {
        return this.deployedService.parameters;
    }

    onServiceTypeChange(): void {
        this.parameters.isActive = false;
        this.resetDeployedService();
        this.buildHandlersBasedOnType();

        if(this.isEmbedded) {
            this.parameters.embedded.customerExitControl ||= {};
        }

    }

    private resetDeployedService(): void {
        this.deployedService = {
            ...typedClone<IServiceDeployedServices>(initialDepService),
            ...typedClone<IServiceDeployedServices>(this.deployedService),
            parameters: {
                ...typedClone(initialDepService).parameters,
                ...typedClone(this.deployedService).parameters,
                serviceType: this.deployedService.parameters.serviceType,
            },
            nName: this.deployedService.nName,
        }

        this.defineChannelsWithoutVoiceSupport();
    }

    isCallcenter(): boolean {
        return this.parameters.serviceType === EServiceChatType.socialReceptiveCallcenter;
    }

    isEmbedded(): boolean {
        return this.parameters.serviceType === EServiceChatType.embeddedColmeiaChat;
    }

    routeToClippboard(): void {
        this.copyValue(this.routeText);
    }

    private get routeText(): string {
        let prefix = socialConfig.whatsService.prefix;
        const whatsAppNumber = this.getChannelItem(EDelivery360Action.Delivery360WhatsApp);
        prefix = prefix.replace(whatsTel, whatsAppNumber);
        return encodeURI(`${prefix}${this.parameters.welcomeText || ''}${socialConfig.whatsService.init}${this.parameters.idWhatsAppServiceRouting}`)
    }

    getChannelItem(channelType: string): string {
        const channel = this.serviceItemChannels.find(channel => channel.e360Provider === channelType);
        return isValidRef(channel)
            ? channel.address
            : null;
    }

    get serviceItemChannels(): TAvailableChannelForServiceArray {
        if (isInvalid(this.parameters.comm)) {
            this.parameters.comm = [];
        }
        return this.parameters.comm;
    }

    canShowPreferences(): boolean {
        return this.parameters.serviceType === EServiceChatType.socialReceptiveCallcenter
            || this.parameters.serviceType === EServiceChatType.embeddedColmeiaChat;
    }

    canShowBotSelect(): boolean {
        return isValidRef(this.nsSelectBotRootHandler)
            && (this.isCallcenter() || this.isEmbedded());
    }

    canShowInitialMetadata(): boolean {
        return isValidRef(this.nsSelectInitialMetadataHandler) && this.isEmbedded();
    }

    compareFn(channel1: IAvailableChannelForService, channel2: IAvailableChannelForService) {
        return channel1 && channel2
            ? channel1.e360Provider === channel2.e360Provider && channel1.idSocialMedia === channel2.idSocialMedia
            : channel1 === channel2;
    }

    canShowWelcomeText(): boolean {
        return this.serviceItemChannels.find(channel => channel.e360Provider === EDelivery360Action.Delivery360WhatsApp)
            && this.hasRoutingKey();
    }

    hasRoutingKey(): boolean {
        return isValidRef(this.parameters.idWhatsAppServiceRouting);
    }

    copyRoutingKey() {
        this.copyValue(this.parameters.idWhatsAppServiceRouting);
    }

    private copyValue(value: string) {
        copyToClickBoard(value);
        this.snackSvc.open("Copiado para área de transferência", "Fechar", { duration: 3000 });
    }

    public generateRoutingKey(): void {
        this.parameters.idWhatsAppServiceRouting = getReadableUniqueID(socialConfig.whatsService.size);
    }

    public canShowCopyBtn(): boolean {
        return this.hasRoutingKey()
            && isValidRef(this.getChannelItem(EDelivery360Action.Delivery360WhatsApp))
            && this.parameters.welcomeText != '';
    }


    get expireBotComm(): number {
        return this.deployedService.parameters.config.expireBotComm;
    }

    set expireBotComm(expireBotComm: number) {
        this.deployedService.parameters.config.expireBotComm = expireBotComm;
    }

    goBack(): void {
        this.location.back();
    }

    async save(): Promise<boolean> {
        if (!this.genericDashboardEdit.validateBeforeSave()) return false;
        if (!(await this.promptValidateNoChannelSelected())) return false;
        if (this.isSaving) return false;
        this.isSaving = true;

        const saved: boolean = await this.deployedSvc.saveDeployedService(this.deployedService);


        this.isSaving = false;

        if (!saved) return false;

        if (!this.service.isEdit)
            this.service.close(saved, true);

        return saved;
    }

    messageHandlers: {
        unableToResetSessionMessage: ISingleContentAssetAdderHandler;
        transcriptionMessage: ISingleContentAssetAdderHandler;
        resetMessage: ISingleContentAssetAdderHandler;
        lastRequestStillUnprocessed: ISingleContentAssetAdderHandler;
        expirationMessage: ISingleContentAssetAdderHandler;
        failToTranscriptMessage: ISingleContentAssetAdderHandler;
        feedbackDuringCustomerAllocation: ISingleContentAssetAdderHandler;
    } = {
            unableToResetSessionMessage: undefined,
            transcriptionMessage: undefined,
            resetMessage: undefined,
            lastRequestStillUnprocessed: undefined,
            expirationMessage: undefined,
            failToTranscriptMessage: undefined,
            feedbackDuringCustomerAllocation: undefined
        };

    private initMessagesHandlers(): void {

        if (isInvalid(this.deployedService.messages)) {
            this.deployedService.messages = {
                unableToResetSessionMessage: undefined,
                transcriptionMessage: undefined,
                resetMessage: undefined,
                lastRequestStillUnprocessed: undefined,
                expirationMessage: undefined,
                failToTranscriptMessage: undefined,
                feedbackDuringCustomerAllocation: undefined
            };
        }

        this.messageHandlers.unableToResetSessionMessage = {
            assetTypeEnabled: [KBAssetType.content],
            content: this.deployedService.messages.unableToResetSessionMessage ? [this.deployedService.messages.unableToResetSessionMessage] : [],
            saveCallBack: (contents: TContentAssetArray) => {
                this.deployedService.messages.unableToResetSessionMessage = contents[0];
            }
        }

        this.messageHandlers.transcriptionMessage = {
            assetTypeEnabled: [KBAssetType.content],
            content: this.deployedService.messages.transcriptionMessage ? [this.deployedService.messages.transcriptionMessage] : [],
            saveCallBack: (contents: TContentAssetArray) => {
                this.deployedService.messages.transcriptionMessage = contents[0];
            }
        }

        this.messageHandlers.resetMessage = {
            assetTypeEnabled: [KBAssetType.content],
            content: this.deployedService.messages.resetMessage ? [this.deployedService.messages.resetMessage] : [],
            saveCallBack: (contents: TContentAssetArray) => {
                this.deployedService.messages.resetMessage = contents[0];
            }
        }

        this.messageHandlers.lastRequestStillUnprocessed = {
            assetTypeEnabled: [KBAssetType.content],
            content: this.deployedService.messages.lastRequestStillUnprocessed ? [this.deployedService.messages.lastRequestStillUnprocessed] : [],
            saveCallBack: (contents: TContentAssetArray) => {
                this.deployedService.messages.lastRequestStillUnprocessed = contents[0];
            }
        }

        this.messageHandlers.expirationMessage = {
            assetTypeEnabled: [KBAssetType.content],
            content: this.deployedService.messages.expirationMessage ? [this.deployedService.messages.expirationMessage] : [],
            saveCallBack: (contents: TContentAssetArray) => {
                this.deployedService.messages.expirationMessage = contents[0];
            },
        }

        this.messageHandlers.failToTranscriptMessage = {
            assetTypeEnabled: [KBAssetType.content],
            content: this.deployedService.messages.failToTranscriptMessage ? [this.deployedService.messages.failToTranscriptMessage] : [],
            saveCallBack: (contents: TContentAssetArray) => {
                this.deployedService.messages.failToTranscriptMessage = contents[0];
            },
        }

        this.messageHandlers.feedbackDuringCustomerAllocation = {
            assetTypeEnabled: [KBAssetType.content],
            content: this.deployedService.messages.feedbackDuringCustomerAllocation ? [this.deployedService.messages.feedbackDuringCustomerAllocation] : [],
            saveCallBack: (contents: TContentAssetArray) => {
                this.deployedService.messages.feedbackDuringCustomerAllocation = contents[0];
            },
        }
    }

    private getMetadataFieldsAsJSON(): string {
        if (this.sdkMetadata) {
            const fields = this.sdkMetadata.schemma.form;
            return fields.map(fld => `${fld.propertyName}: '', //preencha com seu código esta informação\n`).join('');
        }
        return ``;
    }

    get embeddedText(): string {
        const code = `ColmeiaEmbeddedChat.initialize('${this.deployedService.idNS}', {
            title: "Título", // Título da caixa de chat,
            description: "Descrição", // Descrição durante o preenchimento do formulário,
            display: {
                position: "bottom-right", // bottom-left, bottom-right
                mode: "button", // button, programmatically, instantly
                /*header: { //objeto opcional
                    show: true // default true
                },*/
                /*size: 'small', // small,full   default = small */
            },
            customer: {
                metadata: {\n${this.getMetadataFieldsAsJSON()}},
                identifier: undefined, // Identificador único do cliente, se não prover nenhum será usado um aleatório não permitindo repetir a sessão do usuário..
                shouldForceUseMachineId: ${this.trustBrowserFingertipOnUserIdentity},
            },
            /*button: { // Propriedade button é opcional
                url: undefined // Url customizada para o botão de chat, se não prover nenhuma será usado o default..
            }*/${this.mustFillEnvPropOfEmbedded() ? `\nenvironment:"${this.getEnvironment()}"` : ''}
        })`
        return `<script src="https://embedded.colmeia.me/v1/colmeia-embedded.js"></script>\n<script>\n${indent.js(code)}\n</script>`;
    }

    public initWidgetAPI: 'js' | 'html' = 'html';
    public selectedPosition: string = 'bottom-right';
    public webChatPositions: Record<string, string> = {
        'top-left': 'Canto superior esquerdo',
        'top-right': 'Canto superior direiro',
        'bottom-left': 'Canto inferior esquerdo',
        'bottom-right': 'Canto inferior direito',
    }

    get widgetsCode(): string {
        const src = environmentToLoader[this.getEnv()]
        // const src = environmentToLoader['beta']
        let implCode = `<script src="${src}" async defer></script>\n`;


        switch (this.initWidgetAPI) {
            case 'js':
                implCode += this.generateJSAPiCode();
                break;
            case 'html':
                implCode += this.generateHTMLApiCode();
                break;
            default:
                this.snackMessageSvc.openWarning('Meio inválido de inicialização da api')
        }

        return implCode;
    }

    private generateJSAPiCode(): string {
        const jsonConfig: any = {
            serviceId: this.deployedService.idNS,
            customer: {
                useMachineId: this.trustBrowserFingertipOnUserIdentity,
            },
        };

        const { sdkMetadataId } = this.deployedService.parameters.embedded;
        if (isValidString(sdkMetadataId)) {
            const meta = this.metadataHash[sdkMetadataId];
            if (meta) jsonConfig.customer.sdkAnswers = arrayToHash('propertyName', meta.schemma.form, '');
        }

        if (this.selectedPosition) {
            jsonConfig.position = this.selectedPosition;
        }

        if (this.mustFillEnvPropOfEmbedded()) {
            jsonConfig.environment = this.getEnvironment();
        }

        return `<script> window.addEventListener('colmeia-widgets-loaded', function() { new ColmeiaChat(${JSON.stringify(jsonConfig, undefined, 2)}); }); </script>`;
    }

    private generateHTMLApiCode(): string {
        const chatEl = document.createElement('colmeia-chat');

        chatEl.setAttribute('service-id', this.deployedService.idNS);

        if (this.mustFillEnvPropOfEmbedded()) {
            chatEl.setAttribute('environment', this.getEnvironment());
        }

        chatEl.setAttribute('position', this.selectedPosition);

        const customerEl = document.createElement('customer-info');

        const { sdkMetadataId } = this.deployedService.parameters.embedded;
        if (isValidString(sdkMetadataId)) {
            const meta = this.metadataHash[sdkMetadataId];
            meta?.schemma.form.forEach((prop) => {
                customerEl.setAttribute(`answer-${prop.propertyName}`, '')
            });
        }


        if (this.trustBrowserFingertipOnUserIdentity) {
            customerEl.setAttribute('use-machine-id', 'true');
        }

        chatEl.appendChild(customerEl);

        return chatEl.outerHTML;
    }

    private mustFillEnvPropOfEmbedded(): boolean {
        return !environment.ROOT_URL.startsWith('https://api.colmeia.me');
    }

    getEnv(): EColmeiaEnvironment {
        const url = new URL(window.location.href);
        const baseUrl = <EAppBaseURL>url.origin;
        return getEnvironmentByAppUrl(baseUrl);
    }

    private getEnvironment() {
        if (environment.ROOT_URL.startsWith('https://beta-api.colmeia.me')) {
            return "beta";
        }

        return "dev";
    }

    onCopyEmbeddedText() {
        this.copyValue(this.embeddedText);
    }

    get canShowEmbeddedCode(): boolean {
        return this.isEmbedded() && isValidRef(this.deployedService.idNS);
    }

    canShowSDKMetadata(): boolean {
        return isValidRef(this.nsSelectSDKMedatadataHandler)
            && (this.isEmbedded());
    }

    private async fetchMetadata(id: string): Promise<INonserializableSchemaResponseServer> {
        return this.lookupSVC.getSingleLookupElement<INonserializableSchemaResponseServer>(id);
    }

    private get hasCostumerFormulary(): boolean {
        return isValidRef(this.costumerFormulary?.idMetadata);
    }

    private async initMetadataHandler(): Promise<void> {
        await this.loadMetadataNSs();

        this.nsSelectSDKMedatadataHandler = this.initMetadataPicker(
            this.deployedService.parameters.embedded.sdkMetadataId,
            (metadata) => {
                if (!metadata) {
                    this.sdkMetadata = undefined;
                    this.deployedService.parameters.embedded.sdkMetadataId = undefined;
                    return;
                }

                this.sdkMetadata = metadata;
                this.deployedService.parameters.embedded.sdkMetadataId = metadata.idNS;
            },
            { pillMode: true, hideLabel: true }
        );

        this.nsSelectInitialMetadataHandler = this.initMetadataPicker(
            this.costumerFormulary.idMetadata,
            async (metadata) => {
                if (!metadata) {
                    this.costumerFormulary = undefined;
                    this.customerFormularyMetadataRegister = [];
                    return;
                }

                this.costumerFormularyMetadataNS = metadata;
                this.costumerFormulary = {
                    idMetadata: metadata.idNS,
                    engagement: {
                        idMetaEngagement: "",
                        engagementConfig: {},
                        descriptor: [],
                        elementsOrder: []
                    }
                };

                const canonizedFields: string[] = metadata.schemma.form.filter(p => isValidString(p.idLocalCanonical)).map(p => p.idLocalCanonical);
            },
            { pillMode: true, hideLabel: true }
        );
    }

    private async isAnyoneCanonicalName(canonicalsIds: string[]): Promise<boolean> {
        const canonicals = await this.lookupSVC.getCanonicals(canonicalsIds);

        return canonicals.some(c => c.globalCanonical === EMetadataNames.name);
    }

    private initMetadataPicker(
        current: string,
        selectionCallback: (metadata?: INonserializableSchemaResponseServer) => void,
        override: Partial<INSPickerHandlerParameter> = {}
    ): NSPickerHandler {
        return new NSPickerHandler(<INSPickerHandlerParameter>{
            nsType: ENonSerializableObjectType.formSchemma,
            maxSelections: 1,
            nonSerializablesIds: [current],
            clientCallback: {
                onSaveCallback: ([metadata]: [INonserializableSchemaResponseServer]) => {
                    selectionCallback(metadata);
                    if (isValidRef(metadata)) {
                        this.metadataFormsNames[metadata.idNS] = metadata.nName;
                        this.metadataHash[metadata.idNS] = metadata;
                    }
                },
                onLoadNonSerializables: ([metadata]: [INonserializableSchemaResponseServer]) => {
                    this.metadataHash[metadata.idNS] = metadata;
                }
            },
            demandedTag: undefined,
            genericNonSerializableService: this.dashboardSvc.genericNonSerializableService,
            title: this.translations.selectTheForm.value,
            idParent: null,
            ...override,
        });
    }

    private initEmbeddedConfigIfNeed() {
        if (isInvalid(this.deployedService.parameters.embedded)) {
            this.deployedService.parameters.embedded = {
                sdkMetadataId: undefined,
                allowedDomains: [],
                userChatContainerGroup: undefined,
                botAvatar: undefined,
                customerFormulary: undefined
            };
        }

        if (!this.costumerFormulary) {
            this.costumerFormulary = {
                idMetadata: undefined,
                engagement: emptyMetaEngagement()
            };
        }

        this.customerFormularyMetadataRegister = [this.costumerFormulary];
    }

    private async loadMetadataNSs() {
        if (this.hasCostumerFormulary) {
            this.costumerFormularyMetadataNS = await this.fetchMetadata(this.costumerFormularyID);
            this.metadataFormsNames[this.costumerFormularyMetadataNS.idNS] = this.costumerFormularyMetadataNS.nName;
            this.costumerEngagementVariables = this.costumerFormularyMetadataNS.schemma.form.map<IVariable>((prop) => {
                return {
                    idProperty: prop.idProperty,
                    text: prop.prompt
                }
            })
        }
    }

    private get costumerFormulary(): IMetadataRegister {
        return this.deployedService.parameters.embedded.customerFormulary;
    }

    private set costumerFormulary(value: IMetadataRegister) {
        this.deployedService.parameters.embedded.customerFormulary = value;
        this.customerFormularyMetadataRegister = [value];
    }

    get addedForms() {
        return [this.costumerFormulary];
    }

    private get costumerFormularyID(): string {
        return this.costumerFormulary?.idMetadata;
    }

    buildMetadatasEngagements() {
        if (this.hasCostumerFormulary) {
            this.metadataFormsNames[this.costumerMetadata.idNS] = this.costumerMetadata.nName;
            this.costumerMetadataEngagements = [{
                idMetadata: this.costumerMetadata.idNS,
                engagement: emptyMetaEngagement()
            }
            ];
        }
    }

    private async promptValidateNoChannelSelected(): Promise<boolean> {
        if (isValidArray(this.parameters.comm)) {
            return true;
        }

        const prompt = await this.globalWarningSvc.showInteractivePrompt(
            {
                title: this.translations.warningNoChannelsTitle.value, message: this.translations.warningNoChannels.value, options: [
                    INTERACTIVE_NO,
                    INTERACTIVE_YES,
                ]
            });

        return prompt === EInteractiveButton.Yes;
    }

    async openColorBuilderDialog() {
        this.deployedService.parameters.embedded.theme ||= BrandTheme.brandThemeDefaultObjectBuilder();

        const injector = Injector.create({
            providers: [{
                provide: DEPLOYED_SERVICE_ID,
                useValue: this.deployedService.idNS
            }]
        });

        const oppeningData: IOppeningColorBuilderData = {
            viewRef: this.viewRef,
            previewType: EPreviewType.Component,
            brandTheme: this.deployedService.parameters.embedded.theme,
            previewComponent: WebchatPreviewViewer,
            width: "860px",
            injector,
        }
        const updatedTheme = await this.colorBuilderSvc.openDialog(oppeningData)

        if (updatedTheme) {
            this.deployedService.parameters.embedded.theme = updatedTheme;
        }
    }

    trackingTagPickerHandler: TagPickerHandler;
    private async initMarketingEventsPerfNSPickers() {

        const dataObj = this.deployedService.parameters;

        const prefilledSelected: IServerColmeiaTag[] = [];
        if (dataObj.idTrackingTag) {
            const ns = await NSSharedService.getById(dataObj.idTrackingTag);
            if (ns) prefilledSelected.push(ns as IServerColmeiaTag);
        }

        this.trackingTagPickerHandler = new TagPickerHandler({
            tagType: ETagType.marker,
            mode: ETagPickerHandlerMode.edit,
            compact: false,
            canSelectGroupTag: true,
            allowSearchOnEditMode: true,
            maxSelections: 1,
            selectedTags: prefilledSelected,
            clientCallback: {
                onClear: () => {
                    dataObj.idTrackingTag = undefined;
                },
                onFinishSelectionCallback: (selectedTags: any[]) => {
                    dataObj.idTrackingTag = isValidString(selectedTags?.[0]?.idNS) ? selectedTags[0].idNS! : undefined;
                },
            },
        });
    }
}
