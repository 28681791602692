import { TGlobalUID } from "@colmeia/core/src/business/constant";
import { ESortDirection } from "@colmeia/core/src/rules/relational-query";
import { ICRMStep } from "../crm-entities";
import { ICRMTimeWindowConfig } from "./crm-time-window.model";

export enum ECRMTicketSortField {
    createdAt = 'createdAt',
    updatedAt = 'updatedAt',
}

export interface ICRMTicketSortConfig {
    field: ECRMTicketSortField;
    direction: ESortDirection;
}

export type TICRMTicketSortConfigArray = Array<ICRMTicketSortConfig>;

export type TTicketSortFields = keyof ICRMTicketSortConfig;

export type TTicketStatusFilters = Partial<ICRMStep>;

export interface IGetUserTicketsParams {
    idSocialContext: TGlobalUID;
    /**
     * Busca por agente ou cliente
     */
    idAvatarAgent?: string;
    // OR
    idSocialKey?: string;

    timeWindow?: ICRMTimeWindowConfig;
    filters?: TTicketStatusFilters;
    sort?: TICRMTicketSortConfigArray;

    limit?: number;
}

export interface IGetAttendanceTicketsParams {
    idCustomerAvatar: string;
    includeAnotherAgentTickets: boolean;
    timeWindow: ICRMTimeWindowConfig;
    useVisualizerIdForTitle: string;
    showAllCustomerTicketsToAgent?: boolean;
    showProjectKey?: boolean;
}

export interface IGetAttendanceTicketsWithAgentParams extends IGetAttendanceTicketsParams {
    idAvatarAgent: string;
}


export interface ICRMTransferTicketParamsBase {
    // idSocialContext: TGlobalUID;
    idTicket: TGlobalUID;
    targetIsland?: TGlobalUID;
    targetIdAvatarAgent?: TGlobalUID;
    /**
     * Transferências forçadas são possíveis apenas quando um agente é selecionado,
     * nesse caso o ticket não volta a fila e é transferido imediatamente para o agente, ignorando pesos e etc.
     */
    forceTransfer?: boolean;
}

export enum ECRMTicketTransferType {
    allocatedTicket = 'allocatedTicket',
    queueTicket = 'queueTicket'
}


export function getDefaultTicketSort(): TICRMTicketSortConfigArray {
    return [{
        field: ECRMTicketSortField.updatedAt,
        direction: ESortDirection.DESC,
    }];
} 
