import { ComponentType } from "@angular/cdk/portal"
import { EBPMType } from "@colmeia/core/src/shared-business-rules/BPM/bpm-model"
import { EBPMElementMode, IBasicToolbarElement, IToolbarElement, TReferencedObject } from "@colmeia/core/src/shared-business-rules/graph-transaction/toolbar/config-toolbar.types"
import { TBasicElementArray } from "@colmeia/core/src/shared-business-rules/graph/essential/basic-element"
import { IGraphElementRenderData } from "@colmeia/core/src/shared-business-rules/graph/essential/diagram.interfaces"
import { GraphElement } from "@colmeia/core/src/shared-business-rules/graph/essential/graph-element"
import { TGraphElementActionDescriptorList } from "@colmeia/core/src/shared-business-rules/graph/essential/graph-element-action.types"
import { GraphRulesProcessor } from "@colmeia/core/src/shared-business-rules/graph/essential/graph-rules-processor"
import { IGraphConnectionData, IInternalElementRenderData, INextStepInfoRenderData, TRenderData } from "@colmeia/core/src/shared-business-rules/graph/essential/graph-types"
import { GraphPredicate } from "@colmeia/core/src/shared-business-rules/graph/essential/predicate"
import { INonSerializable, TINonSerializableArray } from "@colmeia/core/src/shared-business-rules/non-serializable-id/non-serializable-id-interfaces"
import { EBpmGraphElementInfoMode, IBpmGraphElementInfoEntity } from "app/components/dashboard/ai-pages/bpm-graph/bpm-graph-element-info/bpm-graph-element-info/bpm-graph-element-info.handler"
import { IBPMDialogResult } from "app/components/dashboard/ai-pages/bpm-graph/diagram-editor/diagram-editor.types"
import { MainHandler } from "app/handlers/main-handler"
import { LookupService } from "app/services/lookup.service"
import { SnackMessageService } from "app/services/snack-bar"
import { BpmApiService } from "../api/bpm-bot-api.service"
import { BpmRulesBotDataService } from "./bpm-rules-bot-data.service"
import { BpmRulesBotImportGraphService } from "./bpm-rules-bot-import-graph.service"
import { ENodeRenderType } from 'app/components/dashboard/ai-pages/bpm-graph/diagram-editor/react-diagram-editor/react-nodes.model'
import { DiagramEditorUserEventHandlerService } from 'app/components/dashboard/ai-pages/bpm-graph/diagram-editor/services/diagram-editor-user-event-handler.service'
import { BpmRulesNestedAIDataService } from './bpm-rules-nested-ai-data.service'
import { TNserUID } from '@colmeia/core/src/core-constants/types'

export interface IConnectElementsResultTrue {
    result: true
}

export interface IConnectElementsResultFalse {
    result: false
    reason: string
}

export type TConnectElementsResult = IConnectElementsResultTrue
    | IConnectElementsResultFalse


export interface IUpserterNSComponentInfo {
    type: TReferencedObject,
    mode: EBpmGraphElementInfoMode,
    entityInfo: IBpmGraphElementInfoEntity,
    renderData?: IGraphElementRenderData,
    rootNode?: GraphElement,
    iconClicked?: IInternalElementRenderData,
    extraData?: IExtraDialogData
}

export interface IBPMDragInto {
    predicateType?: TReferencedObject;
    child: IToolbarElement;
    parent?: GraphElement;
}

export interface IDraggableReturn {
    type: EBPMElementMode;
    element?: GraphElement;
}

export interface ICanRemoveReturn {
    allowed: boolean,
    errorMessage?: string
}

export enum IUpserterComponentBehavior {
    Dialog = 'dialog',
    Sidebar = 'sidebar'
}

export interface IUpserterComponentReturn {
    handler: MainHandler,
    extraData?: IExtraDialogData
    behavior: IUpserterComponentBehavior;
    component?: ComponentType<any>, // required if behavior is Dialog
    openSidebar?: boolean;
    clickedElement?: GraphElement
}

export interface IExtraDialogData {
    hasExtraStep?: boolean,
    creatingNewElement?: boolean,
    intentId?: string
}

export interface INewGraphElementFromDraggedToolbarIconBasic {
    draggedElementNS: INonSerializable,
    renderData: IGraphElementRenderData,
    isExternalElement?: boolean;
}
export interface IGraphNodeContainerWithDraggedNser extends INewGraphElementFromDraggedToolbarIconBasic {
    graphNode: GraphElement
}
export interface IBPMDialogResultContainer {
    dialogResult: IBPMDialogResult,
}
export interface IBPMDialogInfoContainer {
    dialogInfo: IUpserterNSComponentInfo
}
export interface INewGraphElementFromDraggedToolbarIconBasicWithDialogResult extends INewGraphElementFromDraggedToolbarIconBasic, IGraphNodeContainerWithDraggedNser, IBPMDialogResultContainer {
}
export interface INewGraphElementFromDraggedToolbarIcon extends INewGraphElementFromDraggedToolbarIconBasic {
    // getNewGraphElementFromDraggedElement: (info: INewGraphElementFromDraggedToolbarIconBasic) => GraphElement
    targetNodeAlreadyInDiagramHostedId?: string
}
export interface IToolbarElementDraggedOnExistingNode extends IBPMDialogInfoContainer {
    targetNodeAlreadyInDiagram: GraphElement;
    draggedToolbarElement: IToolbarElement;
    predicateType: TReferencedObject;
    draggedToolbarElementRenderData: IGraphElementRenderData;
}

export interface IGraphNodeCreatedPosProcessor {
    dialogResult: IBPMDialogResult,
    renderData: IGraphElementRenderData,
    createdNode: GraphElement
}

export interface IGraphNodeToUpdate {
    nodeToUpdate: GraphElement
}

export interface IGraphNodeEdited {
    editedNode: GraphElement
}
export interface IGraphNodeToUpdateWithDialog extends IBPMDialogInfoContainer, IGraphNodeToUpdate {

}

export interface IGraphNodeEditedWithDialog extends IBPMDialogInfoContainer {
    editingElement: GraphElement
    updatedNserInfoFromEditElement: INonSerializable
}

export interface IBPMSubjectRulesListener {
    openDragIntoDashboard(toolbarElement: IBasicToolbarElement): Promise<boolean>;
    generateHostedByModal(on: GraphElement, toolbarElement: IToolbarElement): Promise<INonSerializable>;
}

export interface IBPMSubjectRulesProcessorMethods {
    onToolbarElementDraggedOnExistingNode(arg0: IToolbarElementDraggedOnExistingNode);
    performGraphImportSteps(idBotRoot: string, idRootGraphElement: string);
    getAllAvailableToolbarElementsForSelectedGraphElement(selectedElements: GraphElement[]): IBasicToolbarElement[];
    setGraphRulesProcessor(graphRulesProcessor: GraphRulesProcessor): void;
    getGraphRulesProcessor(): GraphRulesProcessor;
    getDiagramToolbar(type: EBPMElementMode): IBasicToolbarElement[];
    handleEditedNserFromDialogByUser(graphElement: GraphElement, beforeNSData?: INonSerializable): void;
    onUpdateElement(element: GraphElement, renderData: TRenderData): void;
    handleConnectionAdditionByUser(from: GraphElement, to: GraphElement, connectionData: IGraphConnectionData): Promise<void>;
    canConnectElements(from: GraphElement, to: GraphElement): TConnectElementsResult;
    canRemoveGraphNode(graphElement: GraphElement): Promise<ICanRemoveReturn>;
    canRemovePredicate(predicate: GraphPredicate): boolean;
    handleConnectionRemovalByUser(predicate: GraphPredicate): Promise<void>;
    removeGraphElementNodeByUser(graphElementList: TBasicElementArray): Promise<void>
    getCustomToolbarElement(type: TReferencedObject, renderData: TRenderData): IToolbarElement;
    getInternalIconsFromNode(graphElement: GraphElement): IInternalElementRenderData[];
    getNextStepInfoFromNode(graphElement: GraphElement): INextStepInfoRenderData | undefined;
    getNodeColor(graphElement: GraphElement): string;
    handleAssetClick(graphElement: GraphElement, asset: IInternalElementRenderData): void;
    handleElementEditClick(graphElement: GraphElement): void;
    getAllToolbarElements(): IBasicToolbarElement[];
    getOneToolbarElement(bpmObj: TReferencedObject): IBasicToolbarElement;
    getAvailableToolbarElement(from: TReferencedObject, to: TReferencedObject): IToolbarElement[];
    dragInto(drag: IBPMDragInto): Promise<IDraggableReturn>;
    isDraggableOnDiagram(objType: TReferencedObject): boolean;
    isAnExternalElement(graph: GraphElement): boolean;
    // handleToolbarElementDragOnElementByUser(
    //     targetElement: GraphElement,
    //     draggedElement: INonSerializable,
    //     draggedElementRenderData: TRenderData,
    //     predicateType: TReferencedObject,
    // ): void
    handleInternalToolbarElementDragOnElementByUser(
        targetElement: GraphElement,
        draggedType: TReferencedObject,
        draggedNS?: INonSerializable,
        extraData?: IExtraDialogData,
    ): void;
    updateOrderedPosition(graphElement: GraphElement): TGraphElementActionDescriptorList;
    getUpserterComponent(info: IUpserterNSComponentInfo): Promise<IUpserterComponentReturn>;
    openUpserterComponent(upserterData: IUpserterComponentReturn): Promise<IBPMDialogResult>;
    onToolbarElementDraggedOnDiagram(info: IUpserterNSComponentInfo): Promise<void>;
    createNewGraphNode(info: INewGraphElementFromDraggedToolbarIconBasic): Promise<GraphElement>;
    isAnExternalElement(graph: GraphElement): boolean;
    createGraphElementAndPersistToServer(info: INewGraphElementFromDraggedToolbarIcon): Promise<void>;
    handleElementPositionChangedByUser(graphElement: GraphElement, updatedPosition: Pick<IBasicToolbarElement["renderData"], "offsetX" | "offsetY">): Promise<void>;
    handleElementPositionChangedProgramatically(graphElements: GraphElement[]): Promise<void>;
    needLeftPushOnAutoImport(type: TReferencedObject): boolean;
    importPostProcessor(): Promise<void>;
    hasRoot(): boolean;
    isDraggableInto(graphElement: GraphElement, droppedType: TReferencedObject): boolean;
    updateAllNodesHostedObjects(nss: TINonSerializableArray);
    getNodeRenderType(graphElement?: GraphElement): ENodeRenderType;
    getCustomNodeData?(graphElement: GraphElement, diagramEditorSvc: DiagramEditorUserEventHandlerService);
    checkElementIsBPMRoot(idHostedObject: TNserUID): Promise<boolean>;
}

export interface IBPMSubjectRulesProcessorInfo {
    botDialogMap: Map<TReferencedObject, ComponentType<any>>
}

export interface IBPMRulesBase {
    bpmType: EBPMType,
    bpmBotApiService: BpmApiService,
    bpmRulesBotDataService: TBpmRulesDataService,
    bpmRulesBotImportGraphSvc: BpmRulesBotImportGraphService,
    snackSvc: SnackMessageService,
    nserLookupService: LookupService
}

export type TBpmRulesDataService = BpmRulesBotDataService | BpmRulesNestedAIDataService;
