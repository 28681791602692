import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatDialog } from '@angular/material/dialog';
import { IBasicUniversalInfo } from '@colmeia/core/src/comm-interfaces/aux-interfaces';
import { EDelivery360Action } from '@colmeia/core/src/comm-interfaces/barrel-comm-interfaces';
import { EVoiceOption, I360Media } from '@colmeia/core/src/core-constants/bot';
import {
    MMconstant,
    MultimediaInstance,
    MultimediaObject,
    MultiMediaType
} from '@colmeia/core/src/multi-media/barrel-multimedia';
import { ELookupType } from '@colmeia/core/src/request-interfaces/lookup-request';
import { getBestIdMediaFromNS } from "@colmeia/core/src/rules/mm-functions";
import { EBotContentEvent, TContentAssetArray } from '@colmeia/core/src/shared-business-rules/bot/bot-content-model';
import { hasRequiredBotContentProperty } from '@colmeia/core/src/shared-business-rules/bot/bot-function-model-helper';
import { EActionTreeIncrementor, IBotSubject } from '@colmeia/core/src/shared-business-rules/bot/bot-interfaces';
import {
    EMenuMode, ENaviTrackerRules, ENextGenBotElementType, EWhatsappMenuMode,
    IBotElementWithRenderOptions,
    IBotMenuContainerServer,
    IBotMenuItem,
    IBotMenuItemServer,
    IBotMetadataServer,
    IBotRoot,
    IBotRootServer,
    IConfigChannelRender,
    IMenuContainerDisplayOptions,
    INaviTracker,
    INextGenBotServer,
    initDefaultRenderOptions,
    menuModeDisplayConfig,
    TMenuMode
} from '@colmeia/core/src/shared-business-rules/bot/bot-model';
import {
    pickTranslations
} from "@colmeia/core/src/shared-business-rules/const-text/all-serializables";
import { gTranslations } from "@colmeia/core/src/shared-business-rules/const-text/translations";
import { GraphRoot } from '@colmeia/core/src/shared-business-rules/graph/essential/graph-root-element';
import { EActionOnComplete } from '@colmeia/core/src/shared-business-rules/knowledge-base/bot-transaction/bot-transaction';
import { ENonSerializableObjectType, INonSerializable } from '@colmeia/core/src/shared-business-rules/non-serializable-id/non-serializable-id-interfaces';
import { NSSharedService } from '@colmeia/core/src/shared-business-rules/shared-services/services/ns.shared.service';
import { MAX_WHATSAPP_BUTTON_TITLE_SIZE, MAX_WHATSAPP_LIST_ROW_DESCRIPTION_SIZE, MAX_WHATSAPP_LIST_ROW_TITLE_SIZE, MAX_WHATSAPP_LIST_SECTION_TITLE_SIZE, MAX_WHATSAPP_TEMPLATE_BODY_SIZE, MAX_WHATSAPP_BUTTONS_QUICK_REPLY, MAX_WHATSAPP_LIST_SECTIONS, MAX_RCS_LIST_SECTIONS, MAX_RCS_TEMPLATE_BUTTON_TEXT_SIZE, MAX_RCS_BUTTONS_QUICK_REPLY_CALL_TO_ACTION } from '@colmeia/core/src/shared-business-rules/social-media/social-media.model';
import { FBM_MAX_BUTTONS_QUICK_REPLY, FBM_MAX_GENERIC_TEMPLATE_ITEMS, FBM_MAX_QUICK_REPLY_TITLE_LENGTH } from '@colmeia/core/src/shared-business-rules/social-media/meta/meta-constants';
import { IGetAllowedMenuModes, ValidatedMenuMode, WhatsAppValidators } from '@colmeia/core/src/shared-business-rules/social-media/whatsapp-validators';
import { MapBy, empty, isInvalid, isValidArray, isValidRef, values } from '@colmeia/core/src/tools/utility';
import { DeepMap, cast } from '@colmeia/core/src/tools/utility-types';
import { CmRenderOptionsHandler } from 'app/components/dashboard/ai-pages/bots/bot-edit/bot-configuration/cm-render-options/cm-render-options.handler';
import { CmRenderOptionsService } from 'app/components/dashboard/ai-pages/bots/bot-edit/bot-configuration/cm-render-options/cm-render-options.service';
import { ENaviTrackTranslations } from 'app/components/dashboard/choice-tracker/choice-tracker.component';
import { horizontalAppear } from 'app/components/dashboard/dashboard-animations';
import { DynamicDialogComponent } from 'app/components/dashboard/dashboard-data-extractor/dynamic-dialog/dynamic-dialog.component';
import { ISwitchMenuTemplateHandler } from 'app/components/dashboard/dashboard-social-media/menu-templates/switch-menu-template/switch-menu-template.component';
import { HexagonUploaderParameterDefaults } from 'app/components/foundation/hexagon-uploader/hexagon-uploader.model';
import { RootComponent } from 'app/components/foundation/root/root.component';
import {
    BotEditConfigurationHandler,
    IBotEditConfigurationHandlerParameter
} from 'app/handlers/bot-edit-configuration.handler';
import { HexagonUploaderHandler } from 'app/handlers/hexagon-uploader-handler';
import { EHexagonFormat, EHexagonSizes } from 'app/handlers/hexagono.handler';
import { ILookupSelectHandler } from 'app/handlers/lookup-select-handler';
import { BpmService } from 'app/services/bpm/bpm.service';
import { ChoiceTrackerService, TNaviTrackerPickerParameters } from 'app/services/dashboard/choice-tracker.service';
import { DashBoardService } from 'app/services/dashboard/dashboard.service';
import { MenuPreviewService } from 'app/services/dashboard/menu-peview.service';
import { ColmeiaDialogService } from 'app/services/dialog/dialog.service';
import { MultimediaService } from 'app/services/multimedia.service';
import { SnackMessageService } from 'app/services/snack-bar';
import { Subscription } from 'rxjs';
import {
    EnumPickerHandler,
    IEnumPickerClientCallback
} from "../../../../../foundation/enum-picker/enum-picker.handler";
import { BotSubjectComponent } from '../../bot-subject/bot-subject.component';
import { Delivery360MenuModeSectionRangesConfig } from './delivery-360-menu-mode.db';
import { IBotMenuOption } from "@colmeia/core/src/shared-business-rules/bot/bot-interfaces";
import { NSPickerHandler } from 'app/handlers/ns-picker/ns-picker.handler';
import { isDevEnvironment } from 'app/model/is-dev-env';
import { ColmeiaService } from 'app/components/dashboard/colmeia/colmeia.service';
import { BotMenuContainerWhatsappConfigurationHandler } from '../../bot-menu-container-whatsapp-configuration/bot-menu-container-whatsapp-configuration.handler';
import { BotMenuContainerRCSConfigurationHandler } from '../../bot-menu-container-rcs-configuration/bot-menu-container-rcs-configuration.handler';


// type TMenuRenderTabOptionsSettings = Record<EDelivery360Action, TStringLengthRanges>;
export type MapValidatedMenuModes = DeepMap<[mode: EMenuMode, config: ValidatedMenuMode]>;

@Component({
    selector: 'app-bot-configuration',
    templateUrl: './bot-configuration.component.html',
    styleUrls: ['./bot-configuration.component.scss'],
    animations: [horizontalAppear],
    host: {
        "[class.is-bot-menu-item]": "isItemMenu"
    }
})
export class BotConfigurationComponent extends RootComponent<
    'bots'
    | 'createBot'
    | 'save'
    | 'config'
    | 'image'
    | 'name'
    | 'incrementBy'
    | 'letters'
    | 'numbers'
    | 'primaryBot'
    | 'configAction'
    | 'subject'
    | 'optionAction'
    | 'message'
    | 'waitSeconds'
    | 'create'
    | 'remove'
    | 'selectMetadata'
    | 'selectRoot'
    | 'selectNode'
    | 'loadError'
    | 'noBots'
    | 'actionOnComplete'
    | 'onCompletereturn'
    | 'onCompletekUntC'
    | 'timeToReuseInformation'
> implements OnInit, IEnumPickerClientCallback<EMenuMode>, OnDestroy {

    // External statics
    EDelivery360Actions: EDelivery360Action[] = values(EDelivery360Action);
    Delivery360MenuModeSectionRangesConfig = Delivery360MenuModeSectionRangesConfig;

    lookupSchemaHandler: ILookupSelectHandler;
    actionsOnComplete: string[] = Object.values(EActionOnComplete);
    private _handler: BotEditConfigurationHandler;
    botElementHexagonHandler: HexagonUploaderHandler;

    menuPreviewAlert: string;

    public preFunctionPickerHandler: NSPickerHandler;
    private botMenuItem: IBotMenuItemServer;

    voiceOptionTranslations = {
        [EVoiceOption.showVoiceOlny]: 'Apenas Voz processada',
        [EVoiceOption.originalContent]: 'Conteúdo original',
        [EVoiceOption.both]: 'Ambos'
    };

    constructor(
        private cdr: ChangeDetectorRef,
        private multimediaSvc: MultimediaService,
        private dialogSvc: ColmeiaDialogService,
        private matDialogSvc: MatDialog,
        private dashboardSvc: DashBoardService,
        private snackBarMessageSvc: SnackMessageService,
        private cmRenderOptionsSvc: CmRenderOptionsService,
        private trackerSvc: ChoiceTrackerService,
        private menuPreviewSvc: MenuPreviewService,
        private bpmSvc: BpmService,
        private colmeiaSvc: ColmeiaService,


    ) {
        super({
            onCompletereturn: gTranslations.bot[EActionOnComplete.return],
            onCompletekUntC: gTranslations.bot[EActionOnComplete.keepUntilCommand],
            ...pickTranslations(gTranslations.bot, [
                'bots',
                'createBot',
                'actionOnComplete',
                'incrementBy',
                'letters',
                'numbers',
                'primaryBot',
                'configAction',
                'selectMetadata',
                'selectRoot',
                'selectNode',
                'loadError',
                'noBots',
                'optionAction',
                'waitSeconds',
                'timeToReuseInformation',
            ]),
            ...pickTranslations(gTranslations.fragments, [
            ]),
            ...pickTranslations(gTranslations.common, [
                'save',
                'config',
                'image',
                'name',
                'subject',
                'message',
                'create',
                'remove',
            ])
        });

        this.dashboardSvc.setDynamicDialogComponent(DynamicDialogComponent);
    }

    get content(): string {
        return this.parameters.botElement.content
    }

    set content(value: string) {
        this.parameters.botElement.content = String(value).trim();
        if (this.isMenuContainer) this.initSwitchMenuTemplateHandler();
    }

    private assignDefaultValues(): void {
        if (!this._handler)
            return;

        if (this.isBotRoot && isInvalid((<IBotRootServer>this.parameters.botElement).incrementor)) {
            (<IBotRootServer>this.parameters.botElement).incrementor = EActionTreeIncrementor.numeric;
        } else if (this.isMenuContainer && isInvalid((<IBotMenuContainerServer>this.parameters.botElement).actionOnComplete)) {
            (<IBotMenuContainerServer>this.parameters.botElement).actionOnComplete = EActionOnComplete.keepUntilCommand;
        }
    }

    @Input()
    set handler(value: BotEditConfigurationHandler) {
        this._handler = value;
        if (this.isMetadata) {
            this.initlookupSchemaHandler();
        }
        if (this.isBotRoot) {
            /*@TODO remover isso apenas para fixar bots criados antes!*/
            if (isInvalid(this.$botRoot(this.parameters.botElement).renderOptions)) {
                this.$botRoot(this.parameters.botElement).renderOptions = initDefaultRenderOptions();
            }
            if (isValidRef(this.botRoot.voiceGeneratorConfig)) {
                this.initUserFunctionPicker();
            }
        }
        if (this.isMenuContainer) {
            this.initRenderOptionsHandler();
            this.initSwitchMenuTemplateHandler();
        }
        if (this.isMenuItem) {
            this.botMenuItem = this.parameters.botElement as IBotMenuItemServer;

            if (this.botMenuItem?.track)
                this.initPreFunctionHandler();
        }

        this.assignDefaultValues();
        this.initChoiceTrackerHandler();
    }

    get parameters(): IBotEditConfigurationHandlerParameter {
        return this._handler.getComponentParameter();
    }


    public incrementorOptions = {
        ...EActionTreeIncrementor
    };

    get isNode(): boolean {
        return this.parameters.botElement.botLevel === ENextGenBotElementType.menuContainer;
    }

    get isLeaf(): boolean {
        return this.isMenuItem;
    }

    get isItemMenu(): boolean {
        return this.isMenuItem;
    }

    get isMenuItem(): boolean {
        return this.parameters.botElement.botLevel === ENextGenBotElementType.botMenuItem
    }

    get isMetadata(): boolean {
        return this.parameters.botElement.botLevel === ENextGenBotElementType.metadata;
    }

    get isRoot(): boolean {
        return this.parameters.botElement.botLevel === ENextGenBotElementType.root;
    }

    get actionOnComplete(): EActionOnComplete {
        if (this.parameters.botElement.botLevel === ENextGenBotElementType.menuContainer) {
            return (<IBotMenuContainerServer>this.parameters.botElement).actionOnComplete;
        }
    }

    set actionOnComplete(action: EActionOnComplete) {
        if (this.parameters.botElement.botLevel === ENextGenBotElementType.menuContainer) {
            (<IBotMenuContainerServer>this.parameters.botElement).actionOnComplete = action;
        }
    }

    get canShowContent(): boolean {
        return hasRequiredBotContentProperty(this.parameters.botElement);
    }

    openBotSubject(): void {
        if (!isValidRef((<IBotMenuItemServer>this.parameters.botElement).botSubject)) {
            (<IBotMenuItemServer>this.parameters.botElement).botSubject = {
                subject: "",
                action: ""
            };
        }

        const dialogRef = this.matDialogSvc.open<BotSubjectComponent, IBotSubject, IBotSubject>(BotSubjectComponent, {
            data: (<IBotMenuItemServer>this.parameters.botElement).botSubject,
            panelClass: "small-size"
        });

        dialogRef.afterClosed().subscribe((botSubject) => {
            if (!isValidRef(botSubject)) return;

            (<IBotMenuItemServer>this.parameters.botElement).botSubject = botSubject;
            this.cdr.markForCheck();
        });
    }

    fbMenuRenderModePicker: EnumPickerHandler<EMenuMode>;
    whatsappMenuRenderModePicker: EnumPickerHandler<EMenuMode>;

    public renderOptionsHandler: CmRenderOptionsHandler;

    private initRenderOptionsHandler(): void {

        this.$assertsBotWithRenderOptions();
        this.renderOptionsHandler = new CmRenderOptionsHandler({
            element: this.parameters.botElement,
            clientCallback: {},
            menuContainerHandler: this.initWhatsappMenuContainerConfigurationHandler(),
            menuRcsContainerHandler: this.initRcsMenuContainerConfigurationHandler(),
        });
    }


    onMultipleEnumSelection(values: EMenuMode[]): void {
    }

    onSingleEnumSelection(val: EMenuMode): void {
        (<IBotMenuContainerServer>this.parameters.botElement).renderOptions[EDelivery360Action.Delivery360FBMessenger].menuRenderConfigType = val;
    }

    private generateHexagonUploaderHandler(): HexagonUploaderHandler {
        if (isInvalid(this.parameters.botElement.medias)) {
            this.parameters.botElement.medias = [];
        }

        return HexagonUploaderHandler.createNonSerializable({
            ...HexagonUploaderParameterDefaults,
            size: EHexagonSizes.smd,
            fileMode: true,
            idTag: MMconstant.tag.hexagonon,
            format: EHexagonFormat.RoundedSquare,
            onMultimediaObjectChange: () => this.cdr.markForCheck(),
            idMediaEditing: isValidArray(this.parameters.botElement.medias) ? getBestIdMediaFromNS(this.parameters.botElement) : undefined,
            multimediaObject: MultimediaObject.getNewMultimediaObjectFrom360(this.parameters.botElement.medias),
            onFileSelected: async (mm: MultimediaInstance) => {
                await this.multimediaSvc.genericUploadFile(mm, MMconstant.tag.photo);
                this.parameters.botElement.medias.push(mm.getI360Media())
                if (MultiMediaType.staticFactory(mm.getMediaTypeID()).isThumbnailable()) {
                    const thumbInstance: MultimediaInstance = this.multimediaSvc.blobToMultimediaInstance(mm, mm.getSelectedFile().getThumbnailBlob());
                    await this.multimediaSvc.genericUploadFile(thumbInstance, MMconstant.tag.thumbnail);
                    const thumb360: I360Media = thumbInstance.getI360ThumbMedia(mm.getSelectedFile().getThumbnailBlob().size);
                    this.parameters.botElement.medias.push(thumb360);
                }
                this.cdr.markForCheck();
            },
            onMediaDeleted: (mm: MultimediaInstance) => {
                this.parameters.botElement.medias.length = 0;
                // this.parameters.botElement.medias = [];
                this.cdr.markForCheck();
            }
        });
    }

    get isMenuContainer(): boolean {
        return this.parameters.botElement.botLevel === ENextGenBotElementType.menuContainer;
    }

    get menuContainer(): IBotMenuContainerServer {
        return cast(this.parameters.botElement);
    }

    get menuItem(): IBotMenuContainerServer {
        return cast(this.parameters.botElement);
    }

    get isBotRoot(): boolean {
        return this.parameters.botElement.botLevel === ENextGenBotElementType.root;
    }


    get isBotWithRenderOptions(): boolean {
        return this.isMenuContainer || this.isBotRoot;
    }


    get reuseInfoExpireBHour(): number {
        return (<IBotMetadataServer>this.parameters.botElement).reuseInfoExpireBHour;
    }
    set reuseInfoExpireBHour(hours: number) {
        (<IBotMetadataServer>this.parameters.botElement).reuseInfoExpireBHour = hours;
    }
    menuPreviewSubscription: Subscription;
    menuItens: IBotMenuItem[];
    ngOnInit() {
        this.botElementHexagonHandler = this.generateHexagonUploaderHandler();
        this.getTagName();

        if (this.isMenuContainer) {
            this.menuPreviewSubscription = this.menuPreviewSvc.updateMenuSwitchHandler.subscribe((value: Partial<ISwitchMenuTemplateHandler>) => {
                this.initSwitchMenuTemplateHandler(value);
            })
            this.menuItens = this.getMenuItens();
            this.getIncrementorAndConfigOptionsFromRoot();
            this.initSwitchMenuTemplateHandler();
        }
    }

    ngOnDestroy() {
        if (this.menuPreviewSubscription) {
            this.menuPreviewSubscription.unsubscribe();
        }
    }

    incrementorFromRoot: EActionTreeIncrementor;
    configOptionsFromRoot: IConfigChannelRender;

    getIncrementorAndConfigOptionsFromRoot() {
        const getGraphRulesProcessor = this.bpmSvc.getCurrentStrategy().getGraphRulesProcessor();

        const hostedObj: IBotRoot = getGraphRulesProcessor.getRootElement().getHostedObject();
        console.log({ hostedObj, incrementor: hostedObj.incrementor, rendeOptions: hostedObj.renderOptions })
        this.incrementorFromRoot = hostedObj.incrementor;
        this.configOptionsFromRoot = hostedObj.renderOptions
    }

    getMenuItens(): IBotMenuItem[] {
        const idMenuContainer = this.parameters.botElement.idNS
        const getGraphRulesProcessor = this.bpmSvc.getCurrentStrategy().getGraphRulesProcessor();

        const allNodes = getGraphRulesProcessor.getAllNodes();
        const allMenuItens: IBotMenuItem[] = allNodes.filter(graphElement => {
            const nonSerializable = graphElement.getHostedObject() as IBotMenuItem;

            return nonSerializable.idParent === idMenuContainer;
        })
            .map(ge => ge.getHostedObject());

        return allMenuItens.sort((a, b) => a.position! - b.position!);
    }

    initlookupSchemaHandler() {
        const title = this.translations.selectMetadata.value;

        this.lookupSchemaHandler = {
            title: title,
            lookupID: (<IBotMetadataServer>this.parameters.botElement).idMetadata,
            lookupType: ELookupType.nonSerializableGeneric,
            nonSerializableType: ENonSerializableObjectType.formSchemma,
            onSelectLookup: (lookup: IBasicUniversalInfo) => {
                this.parameters.clientCallback.onBotElementConfigurationMetadataChange(lookup.primaryID)
            }
        }
    }

    $botRoot(val: INextGenBotServer): IBotRootServer {
        return val as IBotRootServer;
    }

    numericWhatsappModeEnum: typeof EWhatsappMenuMode = EWhatsappMenuMode;

    get isNumericMenu(): boolean {
        return this.$botRoot(this.parameters.botElement).incrementor === EActionTreeIncrementor.numeric;
    }


    public $assertsBotWithRenderOptions(): asserts this is { parameters: { botElement: IBotElementWithRenderOptions } } {
        return;
    }

    get whatsAppDisplayOptions(): IMenuContainerDisplayOptions {
        this.$assertsBotWithRenderOptions();
        return (this.parameters.botElement).renderOptions[EDelivery360Action.Delivery360WhatsApp]
    }

    get rcsDisplayOptions(): IMenuContainerDisplayOptions {
        this.$assertsBotWithRenderOptions();
        return (this.parameters.botElement).renderOptions[EDelivery360Action.Delivery360RCS]
    }

    get numericWhatsappMode(): EWhatsappMenuMode {
        this.$assertsBotWithRenderOptions();
        return this.whatsAppDisplayOptions.whatsappNumericMenuMode;
    }

    set numericWhatsappMode(val: EWhatsappMenuMode) {
        this.$assertsBotWithRenderOptions();
        this.whatsAppDisplayOptions.whatsappNumericMenuMode = val;
    }

    get botRoot() {
        return this.parameters.botElement as unknown as IBotRoot;
    }

    get voiceGeneratorConfig(): EVoiceOption | undefined  {
        if (isInvalid(this.botRoot.voiceGeneratorConfig)) {
            this.botRoot.voiceGeneratorConfig = {
                type: EVoiceOption.originalContent,
                removeEmojiFromTranscription: false
            }
            this.initUserFunctionPicker();
        }
        return this.botRoot.voiceGeneratorConfig!.type
    }
    set voiceGeneratorConfig(val: EVoiceOption | undefined) {
        if (isInvalid(val)) {
            this.botRoot.voiceGeneratorConfig = undefined;
            this.userFunctionPicker = undefined
            return;
        }
        if (isValidRef(this.botRoot.voiceGeneratorConfig)) {
            this.botRoot.voiceGeneratorConfig.type = val;
        } else {
            this.botRoot.voiceGeneratorConfig = {
                type: val,
                removeEmojiFromTranscription: false
            }
        }
        if (isInvalid(this.userFunctionPicker)) {
            this.initUserFunctionPicker();
        }
    }

    get ignoreEmojis() {
        return this.botRoot.voiceGeneratorConfig!.removeEmojiFromTranscription;
    }
    set ignoreEmojis(value: boolean) {
        this.botRoot.voiceGeneratorConfig!.removeEmojiFromTranscription = value;
    }

    canShowIgnoreEmojis() {
        return isValidRef(this.botRoot.voiceGeneratorConfig) && this.botRoot.voiceGeneratorConfig.type;
    }

    public $notAllowed = empty;


    public openSectionNameValidation(): void {
        return this.cmRenderOptionsSvc.openMenuValidationTable((<IBotMenuItemServer>this.parameters.botElement).section, {
            [EDelivery360Action.Delivery360WhatsApp]: {
                [EMenuMode.Quick]: this.$notAllowed,
                [EMenuMode.Full]: MAX_WHATSAPP_LIST_SECTION_TITLE_SIZE,
                [EMenuMode.Text]: this.$notAllowed,
            },
        });
    }


    public openMenuContainerTextValidation() {
        return this.cmRenderOptionsSvc.openMenuValidationTable(this.menuContainer.content, {
            [EDelivery360Action.Delivery360WhatsApp]: {
                [EMenuMode.Quick]: MAX_WHATSAPP_TEMPLATE_BODY_SIZE,
                [EMenuMode.Full]: MAX_WHATSAPP_TEMPLATE_BODY_SIZE,
                [EMenuMode.Text]: MAX_WHATSAPP_TEMPLATE_BODY_SIZE,
            },
        });
    }

    public openMenuItemTextValidation() {
        return this.cmRenderOptionsSvc.openMenuValidationTable(this.menuItem.content, {
            [EDelivery360Action.Delivery360WhatsApp]: {
                [EMenuMode.Quick]: MAX_WHATSAPP_BUTTON_TITLE_SIZE,
                [EMenuMode.Full]: MAX_WHATSAPP_LIST_ROW_TITLE_SIZE,
                [EMenuMode.Text]: MAX_WHATSAPP_TEMPLATE_BODY_SIZE,
            },
        });
    }

    public openTextToShowValidation(): void {
        if (this.isMenuContainer) return this.openMenuContainerTextValidation();
        if (this.isMenuItem) return this.openMenuItemTextValidation();
    }

    public openSectionDescriptionValidation(): void {
        return this.cmRenderOptionsSvc.openMenuValidationTable((<IBotMenuItemServer>this.parameters.botElement).description, {
            [EDelivery360Action.Delivery360WhatsApp]: {
                [EMenuMode.Quick]: this.$notAllowed,
                [EMenuMode.Full]: MAX_WHATSAPP_LIST_ROW_DESCRIPTION_SIZE,
                [EMenuMode.Text]: this.$notAllowed,
            },
        });
    }

    get trackRuleExist(): boolean {
        return !!this.parameters.botElement.trackerRule;
    }
    set trackRuleExist(value: boolean) {
        if (value) {
            this.parameters.botElement.trackerRule = { idNSTrack: '', navTrackRule: ENaviTrackerRules.noReplace, parameter: '' };
            this.naviTrackerHandler = {
                naviTracker: this.parameters.botElement.trackerRule
            }
        } else {
            delete this.parameters.botElement.trackerRule;
            delete this.naviTrackerHandler;
        }

    }

    tagName: string | undefined;
    naviTrackerHandler: TNaviTrackerPickerParameters | undefined;

    handleNaviTrackerChange(event: INaviTracker) {
        this.parameters.botElement.trackerRule = event;
    }

    initChoiceTrackerHandler() {
        if (this.parameters.botElement.trackerRule) {
            this.naviTrackerHandler = {
                naviTracker: this.parameters.botElement.trackerRule
            }
        }
    }

    public handleAnalyticsChange(event: MatCheckboxChange) {
        if (!event.checked) {
            delete this.parameters.botElement.trackerRule;
            this.tagName = '';
        }
    }
    get hasAdvancedTitleConfigured(): boolean {
        const menu = (<IBotMenuContainerServer>this.parameters.botElement);
        return (
            this.isMenuContainer &&
            isValidRef(menu.multipleChoiceAdvancedTitle)
        );
    }

    public async getTagName() {
        if (this.parameters.botElement.trackerRule?.idNSTrack) {
            const tag = await NSSharedService.getById(this.parameters.botElement.trackerRule?.idNSTrack)
            this.tagName = tag?.nName
        }
    }

    public getTrackRuleTranslation(): string {
        if (this.parameters?.botElement?.trackerRule?.navTrackRule) {
            return ENaviTrackTranslations[this.parameters.botElement.trackerRule.navTrackRule]
        }
        return ''
    }
    switchMenuTemplateHandler: ISwitchMenuTemplateHandler;

    initSwitchMenuTemplateHandler(menuHandlerParameters?: Partial<ISwitchMenuTemplateHandler>) {
        const bot = (this.parameters?.botElement as unknown) as IBotElementWithRenderOptions;

        const channel = menuHandlerParameters?.channel || this.switchMenuTemplateHandler?.channel || EDelivery360Action.Delivery360WhatsApp;

        const menuType = this.handleMenuTypeForChannels(channel, this.menuItens?.length);

        this.switchMenuTemplateHandler = {
            channel: this.switchMenuTemplateHandler?.channel || EDelivery360Action.Delivery360WhatsApp,
            menuTitle: this.parameters?.botElement?.content || '',
            menuTitleAsset: bot.multipleChoiceAdvancedTitle,
            menuPreContent: (this.parameters.botElement.events as TContentAssetArray).filter(event => event.botContentType === EBotContentEvent.preContent),
            menuItens: this.menuItens,
            menuType,
            selectorButton: bot.selectorButton,
            isNumericMenu: this.incrementorFromRoot === EActionTreeIncrementor.numeric,
            whatsappMode: this.configOptionsFromRoot?.WhatsApp?.whatsappNumericMenuMode,
            whatsappFooterText: bot.whatsApp?.footer?.content.content,
            whatsappHeader: bot.whatsApp?.header?.content,
            rcsMode: this.configOptionsFromRoot?.WhatsApp?.whatsappNumericMenuMode,
            rcsHeader: bot.rcs?.config?.map(config => config.header?.content),
            ...menuHandlerParameters
        };

        console.log({
            bot: this.parameters?.botElement,
            switchHandler: this.switchMenuTemplateHandler,
            menuHandlerParameters
        })
    }

    getIBotMenuOptionsFromMenuItens(menuItens: IBotMenuItem[]): IBotMenuOption[] {
        return menuItens?.map((item: IBotMenuItem): IBotMenuOption => {
            return {
                title: item.content || '',
                sectionName: item.section || '',
                description: item.description || '',
                expectedReply: '',
                isQuickReplyAllowed: undefined,
                isListMenuAllowed: undefined
            }
        }).map((item) => WhatsAppValidators.Menu.Item.check(item))
    }

    handleMenuTypeForChannels(channel: EDelivery360Action, menuItensLength: number): EMenuMode {
        this.menuPreviewAlert = '';
        const bot = (this.parameters?.botElement as unknown) as IBotElementWithRenderOptions;

        const menuType: EMenuMode = bot?.renderOptions?.[channel]?.menuRenderConfigType;
        const botItens = this.getIBotMenuOptionsFromMenuItens(this.menuItens);
        const allowMenuModesParameters: IGetAllowedMenuModes = {
            title: this.parameters?.botElement?.content || '',
            selectorButton: bot.selectorButton || '',
            menuItems: botItens || [],
            socialProvider: channel,
            displayOptions: { menuRenderConfigType: menuType },
            whatsApp: bot.whatsApp,
        }


        const validatedMenuModes = WhatsAppValidators.Menu.Container.getValidatedMenuModes(allowMenuModesParameters)
        const mapValidatedMenuModes: MapValidatedMenuModes = MapBy.factory(validatedMenuModes).by(item => [item.mode, item]).value();

        const allowedMenuModes = validatedMenuModes.filter(item => item.isAllowed).map(item => item.mode);
        switch (channel) {
            case EDelivery360Action.Delivery360WhatsApp:
                return this.handleMenuTypeForWpp(menuType, menuItensLength, allowedMenuModes, mapValidatedMenuModes);

            case EDelivery360Action.Delivery360FBMessenger:
            case EDelivery360Action.DeliveryInstagram:
                return this.handleMenuTypeForFbAndIg(menuType, menuItensLength, allowedMenuModes);

            case EDelivery360Action.DeliveryTelegram:
                return this.handleMenuTypeForTelegram(menuType);

            case EDelivery360Action.DeliveryColmeia:
                return bot.renderOptions[channel].menuRenderConfigType;

            case EDelivery360Action.Delivery360Email:
                return EMenuMode.Text;

            case EDelivery360Action.Delivery360SMS:
                return EMenuMode.Text;

            case EDelivery360Action.Delivery360RCS:
                return this.handleMenuTypeForRCS(menuType, menuItensLength, allowedMenuModes, mapValidatedMenuModes);

            default: return EMenuMode.Text;
        }
    }

    handleMenuTypeForWpp(
        menuType: EMenuMode,
        menuItensLength: number,
        allowedMenuModes: TMenuMode[],
        mapValidatedMenuModes: MapValidatedMenuModes,
    ): EMenuMode {
        console.log({ allowedMenuModes })
        const config = mapValidatedMenuModes.get(menuType);
        const setErrorMessage = (text: string) => {
            this.menuPreviewAlert = config?.errorMessage ?? text;
        }


        if (menuType === EMenuMode.Full) {
            if (!allowedMenuModes.includes(EMenuMode.Full)) {
                setErrorMessage(`Umas das opções de escolha possui mais de ${MAX_WHATSAPP_LIST_SECTION_TITLE_SIZE} caracteres, por isso o menu exibido foi alterado.`);
                return EMenuMode.Text
            }

            if (menuItensLength > MAX_WHATSAPP_LIST_SECTIONS) {
                setErrorMessage('O tipo de menu "Completo" só comporta dez opções, por isso o tipo exibido será o "Texto".');
                return EMenuMode.Text
            }
        }

        if (menuType === EMenuMode.Quick) {
            if (!allowedMenuModes.includes(EMenuMode.Quick)) {
                setErrorMessage(`Umas das opções de escolha possui mais de ${MAX_WHATSAPP_BUTTON_TITLE_SIZE} caracteres, por isso o menu exibido foi alterado.`);
                return this.handleMenuTypeForWpp(EMenuMode.Full, menuItensLength, allowedMenuModes, mapValidatedMenuModes);
            }

            if (menuItensLength <= MAX_WHATSAPP_BUTTONS_QUICK_REPLY) {
                return EMenuMode.Quick;
            }

            if (menuItensLength <= MAX_WHATSAPP_LIST_SECTIONS) {
                setErrorMessage('O tipo de menu "Botões" só comporta três opções de escolha, por isso o tipo exibido será o "Completo".')
                return this.handleMenuTypeForWpp(EMenuMode.Full, menuItensLength, allowedMenuModes, mapValidatedMenuModes);
            }

            if (menuItensLength > MAX_WHATSAPP_LIST_SECTIONS) {
                setErrorMessage('O tipo de menu "Botões" só comporta três opções de escolha, para menus com mais de dez opções o tipo exibido será o "Texto".')
                return EMenuMode.Text;
            }
        }

        if (menuType === EMenuMode.Adaptative) {
            if (menuItensLength <= MAX_WHATSAPP_BUTTONS_QUICK_REPLY) {
                return this.handleMenuTypeForWpp(EMenuMode.Quick, menuItensLength, allowedMenuModes, mapValidatedMenuModes);
            }
            if (menuItensLength <= MAX_WHATSAPP_LIST_SECTIONS) {
                return this.handleMenuTypeForWpp(EMenuMode.Full, menuItensLength, allowedMenuModes, mapValidatedMenuModes);
            }
            if (menuItensLength > MAX_WHATSAPP_LIST_SECTIONS) {
                return EMenuMode.Text;
            }
        }

        return menuType;

    }

    handleMenuTypeForFbAndIg(menuType: EMenuMode, menuItensLength: number, allowedMenuModes: TMenuMode[]): EMenuMode {
        console.log({ allowedMenuModes })


        if (menuType === EMenuMode.Full) {
            if (!allowedMenuModes.includes(EMenuMode.Full)) {
                this.menuPreviewAlert = `Umas das opções de escolha possui mais de ${FBM_MAX_QUICK_REPLY_TITLE_LENGTH} caracteres, por isso o menu exibido foi alterado.`;
                return EMenuMode.Text
            }

            if (menuItensLength > FBM_MAX_GENERIC_TEMPLATE_ITEMS) {
                this.menuPreviewAlert = 'O tipo de menu "Completo" só comporta dez opções de escolha, por isso o tipo exibido será o "Texto".';
                return EMenuMode.Text;
            }
        }
        if (menuType === EMenuMode.Quick) {

            if (!allowedMenuModes.includes(EMenuMode.Quick)) {
                this.menuPreviewAlert = `Umas das opções de escolha possui mais de ${FBM_MAX_QUICK_REPLY_TITLE_LENGTH} caracteres, por isso o menu exibido foi alterado.`;

                return EMenuMode.Text;
            }

            if (menuItensLength <= FBM_MAX_BUTTONS_QUICK_REPLY) {
                return EMenuMode.Quick;
            }

            if (menuItensLength > FBM_MAX_BUTTONS_QUICK_REPLY) {
                this.menuPreviewAlert = 'O tipo de menu "Botões" só comporta três opções de escolha, por isso o tipo exibido será o "Texto".';
                return EMenuMode.Text;
            }
        }

        if (menuType === EMenuMode.Adaptative) {

            if (menuItensLength <= FBM_MAX_BUTTONS_QUICK_REPLY && allowedMenuModes.includes(EMenuMode.Quick)) {
                return this.handleMenuTypeForFbAndIg(EMenuMode.Quick, menuItensLength, allowedMenuModes);
            }

            if (menuItensLength <= FBM_MAX_GENERIC_TEMPLATE_ITEMS) {
                return this.handleMenuTypeForFbAndIg(EMenuMode.Full, menuItensLength, allowedMenuModes);

            };

            if (menuItensLength > FBM_MAX_GENERIC_TEMPLATE_ITEMS) return EMenuMode.Text;
        }

        return menuType
    }

    handleMenuTypeForTelegram(menuType: EMenuMode): EMenuMode {
        if (menuType === EMenuMode.Adaptative || menuType === EMenuMode.Quick) return EMenuMode.Full;

        return menuType
    }

    handleMenuTypeForRCS(menuType: EMenuMode, menuItensLength: number, allowedMenuModes: TMenuMode[], mapValidatedMenuModes: MapValidatedMenuModes): EMenuMode {
        const config = mapValidatedMenuModes.get(menuType);
        const setErrorMessage = (text: string) => {
            this.menuPreviewAlert = config?.errorMessage ?? text;
        }

        if (menuType === EMenuMode.Quick) {
            if (!allowedMenuModes.includes(EMenuMode.Quick)) {
                setErrorMessage(`Umas das opções de escolha possui mais de ${MAX_RCS_TEMPLATE_BUTTON_TEXT_SIZE} caracteres, por isso o menu exibido foi alterado.`);
                return this.handleMenuTypeForWpp(EMenuMode.Full, menuItensLength, allowedMenuModes, mapValidatedMenuModes);
            }

            if (menuItensLength <= MAX_RCS_BUTTONS_QUICK_REPLY_CALL_TO_ACTION) {
                return EMenuMode.Quick;
            }

            if (menuItensLength <= MAX_RCS_LIST_SECTIONS) {
                setErrorMessage('O tipo de menu "Botões" só comporta três opções de escolha, por isso o tipo exibido será o "Completo".')
                return this.handleMenuTypeForWpp(EMenuMode.Full, menuItensLength, allowedMenuModes, mapValidatedMenuModes);
            }

            if (menuItensLength > MAX_RCS_LIST_SECTIONS) {
                setErrorMessage('O tipo de menu "Botões" só comporta três opções de escolha, para menus com mais de dez opções o tipo exibido será o "Texto".')
                return EMenuMode.Text;
            }
        }

        return menuType;
    }

    handlePreFunctionActive(active: boolean) {
        if (active) {
            this.botMenuItem.track = {
                idPreFunction: undefined,
                parameter: undefined
            };

            this.initPreFunctionHandler();
        } else {
            this.botMenuItem.track = undefined;
            this.preFunctionPickerHandler = undefined;
        }
    }

    private initPreFunctionHandler() {
        this.preFunctionPickerHandler = new NSPickerHandler({
            nsType: ENonSerializableObjectType.userFunction,
            title: "Selecione a função",
            genericNonSerializableService: this.dashboardSvc.getGenericNonSerializableService(),
            demandedTag: undefined,
            nonSerializablesIds: [this.botMenuItem.track.idPreFunction],
            maxSelections: 1,
            clientCallback: {
                onSaveCallback: ([userFunction]) => {
                    this.botMenuItem.track.idPreFunction = userFunction?.idNS;
                }
            }
        });
    }

    get canHavePreFunction(): boolean {
        return !!this.botMenuItem;
    }

    get hasPreFunction(): boolean {
        return !!this.botMenuItem?.track;
    }

    public initWhatsappMenuContainerConfigurationHandler(): BotMenuContainerWhatsappConfigurationHandler | undefined {
        const handler = new BotMenuContainerWhatsappConfigurationHandler({
            entity: this.parameters.botElement as IBotMenuContainerServer,
            getVariables: () => this.parameters.getAssetAdderVariables!(),
        });
        return handler;
    }

    public initRcsMenuContainerConfigurationHandler(): BotMenuContainerRCSConfigurationHandler | undefined {
        const handler = new BotMenuContainerRCSConfigurationHandler({
            entity: this.parameters.botElement as IBotMenuContainerServer,
            getVariables: () => this.parameters.getAssetAdderVariables!(),
        });
        return handler;
    }

    userFunctionPicker: NSPickerHandler<ENonSerializableObjectType.userFunction> | undefined;

    initUserFunctionPicker() {
        this.userFunctionPicker = new NSPickerHandler({
            title: 'Função de Text to Voice Customizada',
            nsType: ENonSerializableObjectType.userFunction,
            // shouldUseCurrentDemandedTag: false,
            ...(this.botRoot.voiceGeneratorConfig?.idFunction ? {nonSerializablesIds: [this.botRoot.voiceGeneratorConfig?.idFunction]} : {}),
            clientCallback: {
                onSaveNSCallback: (ns) => {
                    this.botRoot.voiceGeneratorConfig!.idFunction = ns?.idNS;
                },
            },

        });
    }

}


