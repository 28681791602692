import { replaceCompiledMessageByTagContent, TComputedInfo, } from "@colmeia/core/src/shared-business-rules/metadata/metadata-utils";
import { IServerColmeiaTag } from "@colmeia/core/src/shared-business-rules/colmeia-tags/tags";
import { IMacroEntry, TMacroEntryArray } from "@colmeia/core/src/shared-business-rules/attendent-service-pack/attendente-service-pack";

export interface IMacroChooseListener {
    onMacroClicked: (output: string) => void;
}

export interface IMacrosHandlerParameter {
    callback: IMacroChooseListener;
    variables: IServerColmeiaTag[];
    computed: TComputedInfo;
    variableIds: string[];
    list: TMacroEntryArray;
    searchString?: string;
}


export class MacrosHandler {

    private parameter: IMacrosHandlerParameter;

    constructor(parameter: IMacrosHandlerParameter) {
        this.parameter = parameter;
    }

    public getMacroList(): TMacroEntryArray {
        return this.parameter.list;
    }

    public getSearchString(): string {
        return this.parameter.searchString;
    }

    public renderMacro(macro: IMacroEntry): string {
        const { messageIfNoBind, compiledTemplate } = macro.variablesTemplate;
        const { variables, computed } = this.parameter;

        const result: string = replaceCompiledMessageByTagContent(
            messageIfNoBind || compiledTemplate,
            macro.variablesTemplate,
            variables,
            computed,
            {}
            );

        return result;
    }


    public async getCanonicalsComputedInfo(): Promise<TComputedInfo> {
        return;
    }

    public onMacroClick(macro: IMacroEntry) {
        const render = this.renderMacro(macro);

        this.parameter.callback.onMacroClicked(render);
    }
}
