import { EDelivery360Action } from '../../comm-interfaces/barrel-comm-interfaces';
import { I360Media } from '../../core-constants/bot';
import { TGlobalUID } from '../../core-constants/types';
import { IEntryCancelReason } from '../campaigns/campaing-interfaces';
import { ENonSerializableObjectType } from '../non-serializable-id/non-serializable-id-interfaces';
import { IdDep } from '../non-serializable-id/non-serializable-types';
import { EBPMTicketAgentAction, IBPMCRMConditionalEvaluator, ICRMTicketActionRuntimeInfo } from './crm-services/crm-bpm-action.model';


export interface ICRMStep {
    idPhase: IdDep<ENonSerializableObjectType.crmItemPhase>;
    idSeverity: IdDep<ENonSerializableObjectType.crmItemSeverity>;
    idTicketState: IdDep<ENonSerializableObjectType.crmItemState>;
    idCloseState: IdDep<ENonSerializableObjectType.crmItemCloseState>;
    idSupportLevel: IdDep<ENonSerializableObjectType.crmItemSupportLevel>;
    idUrgencyLevel: IdDep<ENonSerializableObjectType.crmItemUrgencyLevel>;
}

export interface ICRMStatusNames {
    phaseName?: string;
    severityName?: string;
    ticketStateName?: string;
    closeStateName?: string;
    supportLevelName?: string;
    urgencyLevelName?: string;
}

export interface ICRMCTicketStatusInteractionInsert extends Partial<ICRMStep> { }

export type TIBPMCRMConditionalEvaluatorArray = Array<IBPMCRMConditionalEvaluator>;

export interface ICRMTicketDataBasic {
    idTicket: string;
    title: string;
    idSocialContext: string;
    channel?: EDelivery360Action;
    idIsland: string;
    idSocialKey?: TGlobalUID;
    idAvatarCustomer?: TGlobalUID;
    idAvatarAgent: TGlobalUID;
    clockTick: number;
    lastClockTick?: number;
    currentStatus: ICRMStep;
    currentStatusNames?: ICRMStatusNames;
    protocol: number;
    lastComment?: string;
    lastIdMedia?: string;
    idPackage: string;
    projectKey: string;
}

export type TICRMTicketDataBasicArray = Array<ICRMTicketDataBasic>

export interface ICRMTicketDataWithCustomerName extends ICRMTicketDataBasic {
    customerName: string;
    availableChannels?: TTicketChannels;
}

export type TTicketChannels = Array<ITicketChannel>;
export interface ITicketChannel {
    channelId: IdDep<ENonSerializableObjectType.socialMediaConnection>;
    /**
     * Response template used for sending messages in this channel
     */
    templateId: IdDep<ENonSerializableObjectType.channelTemplate>;
    /**
     * Target address allowed for this channel
     * Must match the channel type
     */
    target: string;
    /**
     * Timestamp when this channel should not be used
     * If null, the channel is always available
     */
    openUntil?: number;
}

export type TICRMTicketItems = Array<ICRMTicketDataWithCustomerName>;


export enum ETicketInteractionType {
    content = 'ctt',
    status = 'sts',
    allocation = 'alloc',
    formAnswer = 'frmAnsr',
    queue = 'q',
    action = 'act',
    message = 'msg',
}

export interface ITicketInteractionSendCampaignMetadataPayload {
    executed?: boolean;
    idMktRule?: TGlobalUID;
    idCampaignAction?: TGlobalUID;
    scheduledTo?: number;
    idMassEntry?: TGlobalUID;
    cancelReason?: Partial<IEntryCancelReason>;
}

export interface ITicketInteractionMetadataPayload {
    sendCampaignData?: ITicketInteractionSendCampaignMetadataPayload;
}

export interface ITicketInteractionMetadata {
    // common
    actionData?: ICRMTicketActionRuntimeInfo;

    // custom
    payload?: ITicketInteractionMetadataPayload;
}

export interface ITicketInteractionBase {
    idTicket: string;
    idInteraction: string;
    interactionType: ETicketInteractionType;
    clockTick: number;
    idAvatarAuthor: TGlobalUID;
    idSocialKey: TGlobalUID;
    idChat?: string;
    idConversation?: string;
    isAgentInteraction: boolean;
    metadata?: ITicketInteractionMetadata;
}

export interface ITicketInteractionStatusBase {
    idSeverityItem: TGlobalUID;
    idTicketStateItem: TGlobalUID;
    idSupportLevelItem: TGlobalUID;
    idPhaseItem: TGlobalUID;
    idCloseStateItem: TGlobalUID;
    idUrgencyLevelItem: TGlobalUID;
    isCurrent: boolean;
}

export interface ITicketInteractionStatus extends ITicketInteractionBase, ITicketInteractionStatusBase {
    interactionType: ETicketInteractionType.status;
}

export interface ITicketInteractionContentBase {
    comment?: string;
    idMedias?: TGlobalUID[];
    i306Medias?: I360Media[];
}

export interface ITicketInteractionContent extends ITicketInteractionBase, ITicketInteractionContentBase {
    interactionType: ETicketInteractionType.content;
}

export interface ITicketInteractionAllocationBase {
    idIsland: TGlobalUID;
    idAvatarAgentSuggested?: TGlobalUID;
    idAvatarAgent: TGlobalUID;
    isTransfer: boolean;
}

export interface ITicketInteractionAllocation extends ITicketInteractionBase, ITicketInteractionAllocationBase {
    interactionType: ETicketInteractionType.allocation;
}

export interface ITicketInteractionFormAnswerBase {
    idAnswer: TGlobalUID;
}

export interface ITicketInteractionFormAnswer extends ITicketInteractionBase, ITicketInteractionFormAnswerBase {
    interactionType: ETicketInteractionType.formAnswer;
}

export interface ITicketInteractionQueueBase {
    idQueue: TGlobalUID;
    idIsland: TGlobalUID;
    isTransfer: boolean;
    idAvatarAgentSuggested?: TGlobalUID;
}

export interface ITicketInteractionQueue extends ITicketInteractionBase, ITicketInteractionQueueBase {
    interactionType: ETicketInteractionType.queue;
}

export interface ITicketInteractionActionBase {
    idAction: TGlobalUID;
    typeAction: EBPMTicketAgentAction;
}

export interface ITicketInteractionAction extends ITicketInteractionBase, ITicketInteractionActionBase {
    interactionType: ETicketInteractionType.action;
}

export interface ITicketInteractionMessageBase {
    message: string;
    idMedias?: TGlobalUID[];
    messageIdMedias?: TGlobalUID[];
    i306Medias?: I360Media[];
}

export interface ITicketInteractionMessage extends ITicketInteractionBase, ITicketInteractionMessageBase {
    interactionType: ETicketInteractionType.message;
}

export type TTicketInteraction = ITicketInteractionStatus | ITicketInteractionContent | ITicketInteractionAllocation | ITicketInteractionFormAnswer | ITicketInteractionQueue | ITicketInteractionAction | ITicketInteractionMessage;
export type TTicketInteractionAllData = ITicketInteractionBase & Partial<ITicketInteractionStatusBase & ITicketInteractionContentBase & ITicketInteractionAllocationBase & ITicketInteractionFormAnswerBase & ITicketInteractionQueueBase & ITicketInteractionAction>;
export type TTicketInteracitonArray = Array<TTicketInteraction>;

export interface ITicketAttachedInfo {
    idTicket: string;
    idProject?: string;
    actionData?: ICRMTicketActionRuntimeInfo;
}


export interface ICRMInteractionTypeToInterface {
    [ETicketInteractionType.content]: ITicketInteractionContent;
    [ETicketInteractionType.status]: ITicketInteractionStatus;
    [ETicketInteractionType.formAnswer]: ITicketInteractionFormAnswer;
    [ETicketInteractionType.allocation]: ITicketInteractionAllocation;
    [ETicketInteractionType.queue]: ITicketInteractionQueue;
    [ETicketInteractionType.action]: ITicketInteractionAction;
    [ETicketInteractionType.message]: ITicketInteractionMessage;
}
