import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    ElementRef,
    Input,
    OnDestroy,
    OnInit,
    ViewChild
} from '@angular/core';
import { Avatar } from '@colmeia/core/src/business/avatar';
import { constant } from '@colmeia/core/src/business/constant';
import { Serializable } from '@colmeia/core/src/business/serializable';
import { ISenderSignatureJSON } from '@colmeia/core/src/comm-interfaces/tracker-interfaces';
import { TGlobalUID } from '@colmeia/core/src/core-constants/types';
import { MMconstant } from '@colmeia/core/src/multi-media/multimedia-constant';
import { UberCache } from '@colmeia/core/src/persistency/uber-cache';
import { removeUniquizer } from '@colmeia/core/src/shared-business-rules/social-cc/social-cc-rules';
import { chatMessageEdit } from "@colmeia/core/src/shared-business-rules/visual-constants";
import { isInvalid, isValidAndEqual, isValidRef, isValidString } from "@colmeia/core/src/tools/utility";
import { AttendanceEvent } from 'app/model/attendence.model';
import { getContrastColorFor } from 'app/model/client-utility';
import { InteractionSignal } from 'app/model/signal/state-signals/interaction-signal';
import { AttendanceService } from 'app/services/attendance.service';
import { GroupNavigationStart } from 'app/services/controllers-services/navigator/navigator';
import { GroupShortcutHandlerService } from 'app/services/group-shortcut-handler.service';
import { Observable, Subscription, timer } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { Memoize } from 'typescript-memoize';
import {
    GroupSelectorShortcutHandler
} from "../../../handlers/group-selector-shortcut.handler";
import { EHexagonSizes, HandlerHexagonon } from "../../../handlers/hexagono.handler";
import { clientConstants } from "../../../model/constants/client.constants";
import { InterfaceInfoSignal } from "../../../model/signal/interface-signal";
import {
    IChangeInterfaceListener, IFilteredGroup,
    IListenerInteraction,
    IListenerSubscription,
    IListenerTypingInfo
} from "../../../model/signal/ps-interfaces";
import { SubscriptionSignal } from "../../../model/signal/subscription-signal";
import { TypingSignal } from "../../../model/signal/trackers/typing-signal";
import { ReadableClocktickService } from "../../../services/readable-clocktick.service";
import { RoutingService } from "../../../services/routing.service";
import { SignalListenerService } from "../../../services/signal/signal-listener";
import { NavigatorServices } from './../../../services/controllers-services/navigator/navigator.service';
import { ElementOverflowService } from "../../../services/layout/element-overflow.service";
import { ProfileAvatarStoreService } from 'app/services/profile-avatar-store.service';
import { AvatarService } from 'app/services/avatar.service';

//<< MINE
import { AttendanceChatSearchCharacters } from "../../../services/attendance-chat-search-characters.service"
import { HighlightMatchingStringCharacters } from "../../../services/highlight-matching-string-characters.service"
import { MessageInstance } from '../message-container/message-instance/message-instance.component';
import { AttendanceChannelService, ECustomerAvatarStatus } from 'app/services/attendace/attendance-channel.service';


@Component({
    selector: 'app-group-selector-shortcut',
    templateUrl: './group-selector-shortcut.component.html',
    styleUrls: ['./group-selector-shortcut.component.scss'],
    // changeDetection: ChangeDetectionStrategy.OnPush
})
export class GroupSelectorShortcutComponent
    implements
    OnInit,
    AfterViewInit,
    OnDestroy,
    IListenerTypingInfo,
    IChangeInterfaceListener,
    IListenerSubscription,
    IListenerInteraction {
    private _handler: GroupSelectorShortcutHandler;

    public hexagonHandler: HandlerHexagonon = HandlerHexagonon.newEmpty(EHexagonSizes.smd);
    public isAttendanceGroup: boolean
    private customerAvatar: Avatar | undefined;

    @Input() restrictedWidth: boolean = false;
    @Input() isCurrentGroup: boolean;
    @Input() set handler(val: GroupSelectorShortcutHandler) {
        this._handler = val;

        this.isAttendanceGroup = this.attendanceSvc.isInAttendDB(val.getGroupID())

        this.hexagonHandler = HandlerHexagonon.newHandler({
            serializable: val.getGroup(),
            size: EHexagonSizes.smd,
        });

        this.updateCustomerAvatar();

        this.cdr.markForCheck();
    }

    get handler(): GroupSelectorShortcutHandler {
        return this._handler;
    }

    @ViewChild('islandName', {
        static: false
    }) islandNameElRef: ElementRef<HTMLElement>;

    private filteredGroup: IFilteredGroup;
    private avatarImage: string;
    private signature: ISenderSignatureJSON;
    private typingAvatar: string;
    private lastTiming: string;
    private timer: any;
    private uiUpdateSubscription: Subscription;
    public shakeEffect: boolean = false;
    attStatusEvent$!: Observable<AttendanceEvent>;

    get channelIcon() {
        return MessageInstance.getDeliveryIcon(this.handler.channel);
    }

    getCustomerAvatarStatus(): ECustomerAvatarStatus {
        const status = this.attendanceChannelSvc.getCustomerAvatarStatus(this.getGroupID());

        return status;
    }

    labels = {
        isTyping: ''
    };

    public isLoading: Observable<boolean>;
    public bounceSubscription: Subscription;
    private avatarSubscription: Subscription;

    constructor(
        private cdr: ChangeDetectorRef,
        private listener: SignalListenerService,
        private routing: RoutingService,
        private clocktick: ReadableClocktickService,
        private navigator: NavigatorServices,
        private shortcut: GroupShortcutHandlerService,
        private attendanceSvc: AttendanceService,
        private elOverflowSvc: ElementOverflowService,
        private profileAvatarStoreSvc: ProfileAvatarStoreService,
        private avatarSvc: AvatarService,
        private lettersToHighlight: AttendanceChatSearchCharacters,
        private highlightSvc: HighlightMatchingStringCharacters,
        private attendanceChannelSvc: AttendanceChannelService
    ) {
        this.initAttStatus();
    }

    private initAttStatus() {
        this.attStatusEvent$ = this.attendanceSvc.events$.pipe(
            filter(ev => {
                return !ev || ev.idGroup === this.getGroupID();
            })
        );
    }

    public customerDoesNotAnswerCount(): number {
        return this.attendanceSvc.getCustomerDoesNotAnswerCountByGroup(this.getGroupID());
    }

    public ngOnInit(): void {
        this.isLoading = this.navigator.groupNavigation$.pipe(
            filter(ev => ev.idGroup === this.getGroupID()),
            map(ev => ev instanceof GroupNavigationStart)
        );

        this.bounceSubscription = this.attendanceSvc
            .thresholdStream$()
            .pipe(filter(ev => ev.idGroup === this.handler.getGroupID()))
            .subscribe((ev) => {
                if (ev.runVisualFeedback) this.runVisualFeedback(ev);
                if (ev.playSound) this.runVisualSoundPlayedFeedback(ev);
            });

        this.handler.setSlaveInstance(this);
        this.filteredGroup = { idGroup: this.getGroupID() };
        this.listener.listenToTypingFiltered(this.filteredGroup, this);
        this.listener.listenToInterfaceChanges(this);
        this.listener.listenToIncomingInteraction(this);
        this.uiUpdateSubscription = this.shortcut.groupUpdate().subscribe((idGroup: string) => {
            if (idGroup === this.getGroupID()) {
                this.cdr.markForCheck();
            }
        });

        this.initLabels();
    };

    public isIslandNameOverflowing: boolean;

    public ngAfterViewInit(): void {
        if (this.islandNameElRef) {
            this.elOverflowSvc.observeOverflow(this.islandNameElRef.nativeElement).subscribe(isOverflowing => {
                this.isIslandNameOverflowing = isOverflowing;
            });
        }
    }

    private runVisualFeedback(ev: unknown) {
        this.shakeEffect = true;
        timer(1000).subscribe(() => {
            this.shakeEffect = false;
        });
    }

    private runVisualSoundPlayedFeedback(ev: unknown) {
        // TODO
    }

    public ngOnDestroy(): void {
        this.listener.destroySubscriptions(this);
        this.uiUpdateSubscription.unsubscribe();
        this.bounceSubscription.unsubscribe();
        this.avatarSubscription?.unsubscribe();
    };

    initLabels(): void {
        this.labels.isTyping = (
            Serializable.staticFactory(chatMessageEdit)
                .getSerializableText(constant.serializableField.auxiliars.aux02)
        );

        this.cdr.markForCheck();
    }

    changeSubscriptionSignCallback(subscriptionSignal: SubscriptionSignal): void {
        if (subscriptionSignal.getGroupID() === this.getGroupID()) {
            this.cdr.markForCheck();
        }
    }

    hasIncomingMessages(): boolean {
        return this.incomingMessagesCount() > 0;
    }

    incomingMessagesCount(): number {
        return this.handler.getStats().unreadMessages;
    }

    isCurrentActiveGroup(): boolean {
        const idGroup: TGlobalUID = this.handler.getGroupID();
        return this.routing.isInsideChat(
            idGroup
        );
    }

    public receiveChangeInterfaceCallback(sign: InterfaceInfoSignal) {
        if (isValidAndEqual(sign.getInterfaceSignal().changeGroupHeader, this.getGroupID())) {
            this.cdr.markForCheck();
        }
    }

    public getGroupID(): TGlobalUID {
        return this.handler.getGroup().getPrimaryID();
    }
    // Compliance com IListenerInteraction

    // Compliance com Typing
    public receiveTypingInfoCallback(info: TypingSignal): void {
        this.signature = info.getObservableInformation();
        let avatar: Avatar;
        let img: string = '';
        this.lastTiming = 'now';

        this.cdr.markForCheck();
        if (UberCache.testCache(this.signature.idAvatar)) {
            avatar = Avatar.staticFactory(this.signature.idAvatar);
            if (avatar.getMultimediaObject()) {
                img = avatar.getMultimediaObject().getMultimediaIDwithIDTag(MMconstant.tag.photo);
            };
        };
        this.setTypingVariables(this.signature.avatarName, img, this.lastTiming);

        if (this.timer) {
            clearTimeout(this.timer);
            this.timer = null;
        }

        this.timer = setTimeout(() => {
            this.setTypingVariables('', '', '');
            this.timer = null;
            this.cdr.markForCheck();
        }, clientConstants.UI.typingDurationEvent);
        this.cdr.markForCheck();
    }

    private safeInteractionText(): string {
        const lastMsg = this.handler.getLastMessage();

        if (isInvalid(lastMsg)) {
            return '';
        }

        const avatar: string = removeUniquizer(lastMsg.avatar)?.trim();

        if (isValidString(avatar) && !this.isAttendanceGroup) {
            return `${avatar}: ${lastMsg.message || ''}`;
        } else {
            return lastMsg.message || '';
        }
    }

    public getLettersToHighlightFromService(): string {
        return this.lettersToHighlight.getSearchingLettersToHighLight()
    }

    public getDisplay(): string {
        return this.typingAvatar ? (`${this.typingAvatar} ${this.labels.isTyping}...`) : this.safeInteractionText();
    }

    private setTypingVariables(name: string, idMedia: string, timing: string): void {
        this.avatarImage = idMedia;
        this.typingAvatar = removeUniquizer(name);
        this.lastTiming = timing;
        this.cdr.markForCheck();
    }

    public presentableTime(): string {
        const lastMsg = this.handler.getLastMessage();
        if (isValidRef(lastMsg)) {
            return this.clocktick.getReadable(lastMsg.clocktick, true);
        }
        return '';
    }

    newIncomingInteractionSignCallback(signal: InteractionSignal) {
        if (signal.getGroupID() === this.getGroupID()) {
            this.cdr.markForCheck();
        }
    }

    get thresholdColor(): string {
        return this.attendanceSvc.getLastAttThresholdForGroup(this.handler.getGroupID())?.color || null;
    }

    get shouldShowBadge() {
        const lastAtt = this.attendanceSvc.getLastAttThresholdForGroup(this.handler.getGroupID());
        const hasThresholdConfig = isValidRef(lastAtt);
        return hasThresholdConfig && isValidString(lastAtt.color);
    }

    @Memoize()
    getContrastColor(color: string): string {
        return getContrastColorFor(color);
    }

    public getCardTitle(): string {
        let groupName: string = !!this.customerAvatar
            ? this.customerAvatar.getName()
            : this.handler.getGroupName();

        return groupName
    }

    public getHighlightedCardTitle(): string {
        const normalizedSearchToken: string = this.getLettersToHighlightFromService()?.toUpperCase();
        let groupName: string = this.getCardTitle()

        return this.highlightSvc.getHighlightedString(groupName, normalizedSearchToken)
    }

    private updateCustomerAvatar() {
        this.customerAvatar = undefined;

        if (this.isAttendanceGroup) {
            const group = this.handler.getGroup();
            this.profileAvatarStoreSvc.getAvatarFromServerIfNotOurs(group.getIdAvatarCreator()).then(avatar => {
                const avatarObservable = this.avatarSvc.observeAvatarChanges(avatar.getPrimaryID());

                this.customerAvatar = avatar;
                this.avatarSubscription = avatarObservable.subscribe(avatar => {
                    this.customerAvatar = avatar;
                });
            });
        }
    }
};
