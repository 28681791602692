import { Injectable } from '@angular/core';
import { BPMRulesBase } from './bpm-rules-base';
import { NSGenericHost } from '@colmeia/core/src/shared-business-rules/graph-transaction/host/ns/ns.host';
import { EAdditionalNestedAIActions, TReferencedObject } from '@colmeia/core/src/shared-business-rules/graph-transaction/toolbar/config-toolbar.types';
import { TBasicElementArray } from '@colmeia/core/src/shared-business-rules/graph/essential/basic-element';
import { IGraphElementRenderData } from '@colmeia/core/src/shared-business-rules/graph/essential/diagram.interfaces';
import { GraphElement } from '@colmeia/core/src/shared-business-rules/graph/essential/graph-element';
import { GraphElementActionSaver, TGraphElementActionDescriptorList } from '@colmeia/core/src/shared-business-rules/graph/essential/graph-element-action.types';
import { EGraphElementType, IGraphConnectionData, IInternalElementRenderData, TIGraphConnectionDataArray } from '@colmeia/core/src/shared-business-rules/graph/essential/graph-types';
import { GraphPredicate } from '@colmeia/core/src/shared-business-rules/graph/essential/predicate';
import { INonSerializable } from '@colmeia/core/src/shared-business-rules/non-serializable-id/non-serializable-id-interfaces';
import { IToolbarElementDraggedOnExistingNode, INewGraphElementFromDraggedToolbarIconBasic, IUpserterNSComponentInfo, IUpserterComponentReturn, TConnectElementsResult, IBPMDragInto, IDraggableReturn, IExtraDialogData, ICanRemoveReturn, IUpserterComponentBehavior } from './bpm-rules.types';
import { EBPMType } from '@colmeia/core/src/shared-business-rules/BPM/bpm-model';
import { DiagramEditorDialogService } from 'app/components/dashboard/ai-pages/bpm-graph/diagram-editor/services/diagram-editor-dialog.service';
import { LookupService } from 'app/services/lookup.service';
import { RoutingService } from 'app/services/routing.service';
import { SnackMessageService } from 'app/services/snack-bar';
import { BpmApiService } from '../api/bpm-bot-api.service';
import { BpmRulesBotImportGraphService } from './bpm-rules-bot-import-graph.service';
import { getUniqueStringID, isEqual, isInvalid, isInvalidString, isValidArray, isValidFunction, isValidRef, isValidString, typedCloneLodash } from '@colmeia/core/src/tools/barrel-tools';
import { BasicElementFactory } from '@colmeia/core/src/shared-business-rules/graph/essential/basic-element.factory';
import { ENodeRenderType } from 'app/components/dashboard/ai-pages/bpm-graph/diagram-editor/react-diagram-editor/react-nodes.model';
import { EGeneratorTransactionType, INLPTransaction, INLPTransactionServer, ITransactionServer } from '@colmeia/core/src/shared-business-rules/knowledge-base/bot-transaction/bot-transaction';
import { ENextGenBotElementType, INextGenBotServer, getEmptyAction } from '@colmeia/core/src/shared-business-rules/bot/bot-model';
import { KnowledgeBaseService } from 'app/services/knowledge-base.service';
import { INestedAIRootCustomNodeData } from 'app/components/dashboard/ai-pages/bpm-graph/diagram-editor/react-diagram-editor/custom-nodes/nestedAIRoot.node';
import { IMLCLUIntent } from '@colmeia/core/src/shared-business-rules/knowledge-base/clu-core-interfaces';
import { DiagramEditorUserEventHandlerService } from 'app/components/dashboard/ai-pages/bpm-graph/diagram-editor/services/diagram-editor-user-event-handler.service';
import { HostNodeFactory } from '@colmeia/core/src/shared-business-rules/graph-transaction/host/factory/host-node.factory';
import { NestedAItHostNode } from '@colmeia/core/src/shared-business-rules/graph-transaction/host/nested-ai/nested-ai-node.host';
import { BpmRulesNestedAIDataService } from './bpm-rules-nested-ai-data.service';
import { TMatDialogRef } from 'app/model/client-utility';
import { ColmeiaDialogService } from 'app/services/dialog/dialog.service';
import { SelectIntentModalComponent, SelectIntentModalReturn } from './nested-ai-select-intent-modal/nested-ai-select-intent-modal.component';
import { EBotActionType, TNodeAssetIcons } from '@colmeia/core/src/shared-business-rules/bot/new-bot-action';
import { isActionAsset, isContentAsset, isNLP } from '@colmeia/core/src/shared-business-rules/bot/bot-function-model-helper';
import { IBasicAsset } from '@colmeia/core/src/shared-business-rules/bot/bot-asset-model';
import { IContentBasicAsset } from '@colmeia/core/src/shared-business-rules/bot/bot-content-model';
import { EBpmGraphElementInfoMode } from 'app/components/dashboard/ai-pages/bpm-graph/bpm-graph-element-info/bpm-graph-element-info/bpm-graph-element-info.handler';
import { IBotActionAsset } from '@colmeia/core/src/shared-business-rules/bot/bot-action-model';
import { RenderedElementMapToBotAction, ToolbarElementMapToBotAction } from '@colmeia/core/src/shared-business-rules/graph-transaction/host/nested-ai/nested-ai-root.host';
import { IGraphElementJSON } from '@colmeia/core/src/shared-business-rules/graph/essential/graph-interfaces';
import { IBasicElementClient } from '@colmeia/core/src/shared-business-rules/graph/essential/graph-basic-element-interfaces';
import { BasicElementInterfaceFactory } from '@colmeia/core/src/shared-business-rules/graph/essential/basic-element-interface.factory';
import { TNserUID } from '@colmeia/core/src/core-constants/types';
import { BpmGraphNodeDetailsService } from 'app/components/dashboard/ai-pages/bpm-graph/bpm-graph-node-details/bpm-graph-node-details.service';

export const INTENT_TAB_INDEX = 3;

@Injectable({
    providedIn: 'root'
})
export class BpmRulesNestedAIService extends BPMRulesBase {
    constructor(
        private bpmBotApiService: BpmApiService,
        private bpmRulesNestedAIDataService: BpmRulesNestedAIDataService,
        private bpmRulesBotImportGraphSvc: BpmRulesBotImportGraphService,
        private snackSvc: SnackMessageService,
        private nserLookupService: LookupService,
        private dialogSvc: ColmeiaDialogService,
        protected routingSvc: RoutingService,
        protected diagramEditorDialogSvc: DiagramEditorDialogService,
        protected bpmGraphNodeDetailsSvc: BpmGraphNodeDetailsService,
        public kbSvc: KnowledgeBaseService,
    ) {
        super(
            EBPMType.nestedAI,
            bpmBotApiService,
            bpmRulesNestedAIDataService,
            bpmRulesBotImportGraphSvc,
            snackSvc,
            nserLookupService,
            routingSvc,
            diagramEditorDialogSvc,
            bpmGraphNodeDetailsSvc)
    }

    private newInternalNSs = new Set<TNserUID>();

    public async onToolbarElementDraggedOnDiagram(info: IUpserterNSComponentInfo): Promise<void> {
        const upserterData = await this.getUpserterComponent(info);

        const result = await this.openUpserterComponent(upserterData);

        if (!result.userHasClickedSave) {
            return
        }

        await super.createGraphElementAndPersistToServer({
            draggedElementNS: result.nonSerializable,
            renderData: info.renderData,
        })
    }

    public isDraggableOnDiagram(itemType: TReferencedObject): boolean {
        return this.shouldElementBeGraphRoot(itemType);
    }

    public shouldElementBeGraphRoot(itemType: TReferencedObject) {
        const isRootType = this.isRootType(itemType);
        const isRootAlreadyOnDiagram = this.hasRoot();
        return isRootType && !isRootAlreadyOnDiagram;
    }

    public isRootType(itemType: TReferencedObject) {
        return itemType === ENextGenBotElementType.nlpTransaction;;
    }

    private async showSelectIntentDialog(targetGraphElement: GraphElement, selectMode: boolean): Promise<SelectIntentModalReturn> {
        const targetNS = targetGraphElement.getHostedObject() as INLPTransaction;
        const response = await this.kbSvc.getKBInfo(targetNS.nlp.idKB);
        const intents = response.kb.app.intents;
        const intentDialogRef: TMatDialogRef = this.dialogSvc.open({
            componentRef: SelectIntentModalComponent,
            dataToComponent: {
                data: {
                    intents,
                    label: "Intenção",
                    selectMode
                },
            },
            title: "Selecione uma Intenção",
            panelClass: "small-size"
        });

        const data = await intentDialogRef.afterClosed().toPromise();
        return data;
    }

    public async getUpserterComponent(info: IUpserterNSComponentInfo): Promise<IUpserterComponentReturn> {
        const extraData: IExtraDialogData = {
            hasExtraStep: false,
            intentId: undefined,
            creatingNewElement: false
        }

        const idParentHosted = info.entityInfo.idParentHosted
        if (isValidString(idParentHosted)) { // There is a target so it was a drag and drop on top of an element
            extraData.hasExtraStep = true;
            const targetGraphElement = this.graphProcessor.findNodeElementByHostedId(idParentHosted);
            if (this.isRootType(targetGraphElement.getHostedType())) {
                const selectMode = ToolbarElementMapToBotAction[info.type] === EBotActionType.contentGenerator;
                const extraDialogResult = await this.showSelectIntentDialog(targetGraphElement, selectMode);
                if (isInvalidString(extraDialogResult.intentId))
                    return {
                        behavior: IUpserterComponentBehavior.Dialog,
                        component: undefined,
                        handler: undefined,
                        extraData: {
                            hasExtraStep: true,
                            intentId: undefined,
                            creatingNewElement: info.mode === EBpmGraphElementInfoMode.Create
                        }
                    };
                const { intentId, mode } = extraDialogResult;
                if (selectMode) info.mode = mode;
                info.iconClicked = { ...info.iconClicked, ...{ type: EAdditionalNestedAIActions.openIntentsTab, intentId } };
                extraData.intentId = intentId;
                extraData.creatingNewElement = info.mode === EBpmGraphElementInfoMode.Create
            }
        }

        return this.getBotDialogComponent(info, extraData);
    }

    private getBotDialogComponent(info: IUpserterNSComponentInfo, extraData: IExtraDialogData): IUpserterComponentReturn {
        const targetNS = isValidRef(info.entityInfo.entity) ? info.entityInfo.entity : this.graphProcessor.findNodeElementByHostedId(info.entityInfo.idParentHosted)?.getHostedObject();
        info.entityInfo.entity = targetNS as INLPTransactionServer;
        const componentAndHandlerConstructor = this.bpmRulesNestedAIDataService.getComponentAndHandler(info);
        return isValidFunction(componentAndHandlerConstructor)
            ? { ...componentAndHandlerConstructor(info), extraData }
            : undefined;
    }

    async createNewGraphNode(info: INewGraphElementFromDraggedToolbarIconBasic): Promise<GraphElement> {
        const botLevel = (info.draggedElementNS as INextGenBotServer).botLevel;
        const draggedHost: NestedAItHostNode = <NestedAItHostNode>HostNodeFactory.create({
            bpmType: EBPMType.nestedAI,
            ns: info.draggedElementNS
        })
        const actionSaver = GraphElementActionSaver.create();
        actionSaver.addSaveActionToNserList(draggedHost);

        const isMenuContainer = botLevel == ENextGenBotElementType.menuContainer;
        const isColmeiaForms = botLevel == ENextGenBotElementType.formTransaction;
        const isContentGeneratorNLP = this.isRootType(botLevel)
        const shouldElementBeCurrentGraphRoot = isContentGeneratorNLP && this.shouldElementBeGraphRoot(draggedHost.getHostedType());
        const isRootOfAnotherBPM = isContentGeneratorNLP && !shouldElementBeCurrentGraphRoot && await this.checkElementIsBPMRoot(info.draggedElementNS.idNS);
        const isInternalNLP = shouldElementBeCurrentGraphRoot || isContentGeneratorNLP && this.newInternalNSs.has(info.draggedElementNS.idNS);
        // To-do: In case of an NLP, we should check if it is being created inside this BPM,
        // if not then it is an external NLP (could be another BPM or simply a normal NLP).
        // Ideally this info should come from info.isExternalElement
        const isExternalElement = !isInternalNLP &&
            (isRootOfAnotherBPM || info.isExternalElement || isMenuContainer || isColmeiaForms || isContentGeneratorNLP);

        const graphTypeToCreate: EGraphElementType = shouldElementBeCurrentGraphRoot ? EGraphElementType.root : EGraphElementType.node

        const basicElement: IBasicElementClient = BasicElementInterfaceFactory.create(graphTypeToCreate, {
            name: info.draggedElementNS.nName,
            renderData: info.renderData,
            idParent: this.graphProcessor.getRootElementId()
        })
        const newGraphNode: GraphElement = BasicElementFactory.createGraphNode(graphTypeToCreate, {
            name: info.draggedElementNS.nName,
            hostObject: draggedHost,
            graphJSON: <IGraphElementJSON>{
                ...basicElement.element,
                isExternalElement
            },
            ruleProcessor: this.graphProcessor,
        }) as GraphElement
        return newGraphNode
    }

    public getNodeRenderType(graphElement: GraphElement): ENodeRenderType {
        const hostedObject = graphElement?.getHostedObject() as INLPTransaction;
        if (hostedObject.botLevel === ENextGenBotElementType.nlpTransaction && !graphElement.isExternalElementOnDiagram())
            return ENodeRenderType.nestedAIRoot;
        return ENodeRenderType.standard;
    }

    public getCustomNodeData(graphElement: GraphElement, diagramEditorSvc: DiagramEditorUserEventHandlerService): INestedAIRootCustomNodeData {
        return {
            kbSvc: this.kbSvc,
            getClickFunctions: (intents: Array<IMLCLUIntent>) => this.createFunctionsMap(graphElement, intents, diagramEditorSvc)
        };
    }

    private createFunctionsMap(graphElement: GraphElement, intents: Array<IMLCLUIntent> = [], diagramEditorSvc: DiagramEditorUserEventHandlerService) {
        const functionsMap = new Map();
        intents.forEach(intent => {
            functionsMap.set(intent.intentId, async () => {
                diagramEditorSvc.handleClickOnIconInsideDiagramNode(graphElement, { type: EAdditionalNestedAIActions.openIntentsTab, hasValidConfiguration: true, intentId: intent.intentId });
            })
        });
        return functionsMap;
    }

    public getAdditionalElementToBeRemoved(updatedElement: GraphElement, currentConnectionData?: IGraphConnectionData, connectionsToRemove?: TIGraphConnectionDataArray): TBasicElementArray {
        let connectionsToOperate = connectionsToRemove;
        let connections = [currentConnectionData];
        let existingNodesAndPredicates = updatedElement.getNeighborsAndEdgesThatIPointTo();
        let isConnectionsFromSameOrigin = false;
        let originElementId = undefined;

        if (isInvalid(currentConnectionData)) {
            if (updatedElement.isExternalElementOnDiagram())
                return existingNodesAndPredicates;

            isConnectionsFromSameOrigin = true; // Se não é externo então é um NLP interno e estamos removendo ele
            originElementId = updatedElement.getGraphElementID();
            connections = updatedElement.getHostObject().getConnectionTargetData();
        }

        for (const connectionData of connections) {
            existingNodesAndPredicates = existingNodesAndPredicates.filter(node => {
                switch (node.getElementType()) {
                    case EGraphElementType.root:
                    case EGraphElementType.node:
                        if (node.getHostedID() !== connectionData.targetHostId) return false;
                        let shouldRemoveNode = false;
                        if (isConnectionsFromSameOrigin)
                            connectionsToOperate = [connectionData] // Todas as conexões a serem removidas serão da mesma origem (NLP interno) então posso ignora-las ao verificar se "nodeHasAtleastOneConnectionOrIsTargetOfFutureOperation", faço isso setando a uinica operação como sendo a atual
                        if (isValidRef(connectionData) && isValidArray(connectionsToOperate)) {
                            const graphElement = node as GraphElement;
                            shouldRemoveNode = graphElement.isExternalElementOnDiagram() &&
                                !this.nodeHasAtleastOneConnectionOrIsTargetOfFutureOperation(graphElement, connectionsToOperate, connectionData, isConnectionsFromSameOrigin, originElementId);
                        }
                        return shouldRemoveNode;
                        break;

                    case EGraphElementType.predicate:
                        if (isConnectionsFromSameOrigin) return true;
                        const currConnSubElementId = connectionData.subElementId;
                        if (isInvalidString(currConnSubElementId)) return true;

                        const predicate = node as GraphPredicate;
                        const hasSameSourceHandle = predicate.getSubElementId() === currConnSubElementId;
                        return hasSameSourceHandle;
                        break;
                }
            })
        }

        return existingNodesAndPredicates;
    }

    private eventToReferencedObject(event: IBasicAsset): TReferencedObject {
        if (isContentAsset(event.type))
            return (event as IContentBasicAsset).botContentType;
    }

    public getInternalIconsFromNode(graphElement: GraphElement): IInternalElementRenderData[] {
        const host: NestedAItHostNode = graphElement.getHostObject() as NestedAItHostNode;
        const ns: INextGenBotServer = host.getNonSerializable() as INextGenBotServer;
        const nodeTypeToIcons: Map<TReferencedObject, IBasicAsset[]> = new Map();

        ns.events.forEach((event) => {
            const type = this.eventToReferencedObject(event);
            if (!nodeTypeToIcons.has(type)) nodeTypeToIcons.set(type, []);
            nodeTypeToIcons.get(type).push(event);
        });
        const result = [...nodeTypeToIcons.keys()].map(
            (type: TReferencedObject): IInternalElementRenderData => ({
                type: type as TNodeAssetIcons,
                count: nodeTypeToIcons.get(type).length,
                hasValidConfiguration: true,
            })
        );

        return result
    }

    getNextStepInfoFromNode(graphElement: GraphElement) {
        return undefined;
    }

    getNodeColor(graphElement: GraphElement): string {
        return undefined;
    }

    public async handleEditedNserFromDialogByUser(oldElement: GraphElement, updatedElementNser: INonSerializable): Promise<void> {
        const actionSaver = GraphElementActionSaver.create()
        const editedElement: NestedAItHostNode = <NestedAItHostNode>HostNodeFactory.create({
            bpmType: EBPMType.nestedAI,
            ns: updatedElementNser
        })

        const graphNodesToChange = await this.handleEditedNodeNserModelUpdateGraph(oldElement, editedElement)
        actionSaver.concatChangedNodes(graphNodesToChange)

        await this.finishTransaction(actionSaver.getChangedNodes())
    }

    async refreshPosActionState(
        isRoot: boolean, nser: INonSerializable, oldNser: INonSerializable,
        updatedElement: GraphElement, batchSaver: GraphElementActionSaver, connectionType: TReferencedObject
    ): Promise<void> { }

    public isDraggableInto(graphElement: GraphElement, droppedType: TReferencedObject): boolean {
        return graphElement.isDraggableIntoMe(droppedType);
    }

    public async handleInternalToolbarElementDragOnElementByUser(
        targetElement: GraphElement,
        draggedType: TReferencedObject,
        draggedNS?: INonSerializable,
        extraData?: IExtraDialogData
    ): Promise<void> {
        let newNS = draggedNS;
        if (extraData?.creatingNewElement) {
            const newAsset = this.createNewAsset(ToolbarElementMapToBotAction[draggedType], draggedNS);
            const typedNS = draggedNS as ITransactionServer;
            if (isNLP(typedNS.botLevel))
                this.newInternalNSs.add(draggedNS.idNS);
            newNS = this.bindAssetToNLP(targetElement.getHostedObject<INLPTransactionServer>(), extraData.intentId, newAsset);
        }
        const actionSaver = GraphElementActionSaver.create()
        const editedElementHost: NestedAItHostNode = <NestedAItHostNode>HostNodeFactory.create({
            bpmType: EBPMType.nestedAI,
            ns: newNS
        })
        switch (draggedType) {
            case EBotActionType.goActionTree:
            case EBotActionType.contentGenerator:
            case EBotActionType.goHuman:
            case ENextGenBotElementType.nlpTransaction:
                const graphNodesToChange = await this.handleEditedNodeNserModelUpdateGraph(targetElement, editedElementHost);
                actionSaver.concatChangedNodes(graphNodesToChange)
                break;
            default:
                throw new Error(`handleInternalToolbarElementDragOnElement "${draggedType}" nao encontrado`)
        }
        await this.finishTransaction(actionSaver.getChangedNodes());
    }

    public canConnectElements(from: GraphElement, to: GraphElement): TConnectElementsResult {
        const hostedFrom = from.getHostedObject() as INLPTransaction;
        if (hostedFrom.botLevel !== ENextGenBotElementType.nlpTransaction)
            return { result: false, reason: "Conexões são permitidas apenas a partir de um nó Gerador NLP" }

        if (isEqual(from, to))
            return { result: false, reason: "Um nó não pode conectar-se a sí mesmo." }

        const fromType: TReferencedObject = from.getHostedType();
        const toType: TReferencedObject = to.getHostedType();

        const isValidConnection: boolean = super.isValidConnectionInDB(fromType, toType)
        if (!isValidConnection) {
            console.log({ result: false, reason: "Conexão inválida" });
            return { result: false, reason: "Conexão inválida" };
        }
        return { result: true };
    }

    public async handleConnectionAdditionByUser(from: GraphElement, to: GraphElement, connectionData: IGraphConnectionData): Promise<void> {

        const sourceNS = from.getHostedObject() as INLPTransactionServer;
        const actionsTargetType = to.getHostedType();
        const actionsTargetNS = to.getHostedObject() as INonSerializable;

        const newAsset = this.createNewAsset(RenderedElementMapToBotAction[actionsTargetType], actionsTargetNS);
        const newNS = this.bindAssetToNLP(sourceNS, connectionData.subElementId, newAsset);

        const actionSaver = GraphElementActionSaver.create()
        const editedElementHost: NestedAItHostNode = <NestedAItHostNode>HostNodeFactory.create({
            bpmType: EBPMType.nestedAI,
            ns: newNS
        })
        switch (actionsTargetType) {
            case ENextGenBotElementType.menuContainer:
            case ENextGenBotElementType.formTransaction:
            case ENextGenBotElementType.nlpTransaction:
            case EBotActionType.goHuman:
                const graphNodesToChange = await this.handleEditedNodeNserModelUpdateGraph(from, editedElementHost);
                actionSaver.concatChangedNodes(graphNodesToChange)
                break;
            default:
                throw new Error(`handleConnectionAdditionByUser "${actionsTargetType}" nao encontrado`)
        }
        await this.finishTransaction(actionSaver.getChangedNodes());
    }

    private createNewAsset(
        targetType: EBotActionType,
        targetNS: INonSerializable,
    ) {
        let newAsset: IBotActionAsset = getEmptyAction();
        newAsset = {
            ...newAsset,
            idAsset: getUniqueStringID(10),
            type: targetType,
            idElement: targetNS.idNS,
        };
        return newAsset;
    }

    private bindAssetToNLP(
        targetNS: INLPTransactionServer,
        intentId: string,
        newAsset: IBotActionAsset
    ) {
        const targetNSCopy = typedCloneLodash(targetNS);
        let assets = typedCloneLodash(targetNSCopy.nlp.assets[intentId]);
        let events = typedCloneLodash(targetNSCopy.events);
        const oldAsset = targetNSCopy.nlp.assets[intentId].find(asset => isActionAsset(asset.type))
        if (isValidRef(oldAsset)) {
            assets = targetNSCopy.nlp.assets[intentId].filter(asset => asset.idAsset !== oldAsset.idAsset)
            events = targetNSCopy.events.filter(asset => asset.idAsset !== oldAsset.idAsset)
        }
        assets.push(newAsset);
        events.push(newAsset);

        targetNSCopy.nlp.assets[intentId] = assets;
        targetNSCopy.events = events;

        return targetNSCopy;
    }

    public isGraphRoot(graphElement: GraphElement) {
        return this.graphProcessor.getRootElement().isSameGraphElement(graphElement);
    }

    public onToolbarElementDraggedOnExistingNode(info: IToolbarElementDraggedOnExistingNode) {
        this.handleInternalToolbarElementDragOnElementByUser(
            info.targetNodeAlreadyInDiagram,
            info.dialogInfo.type,
            info.dialogInfo.entityInfo.entity,
            info.dialogInfo.extraData
        );
    }

    public updatePosActionRemoval(graphElementList: TBasicElementArray): TGraphElementActionDescriptorList {
        return []
    }

    public async canRemoveGraphNode(graphElement: GraphElement): Promise<ICanRemoveReturn> {
        // Add logic to manage nlps
        if (this.isGraphRoot(graphElement))
            return {
                allowed: false,
                errorMessage: "Não é possível remover o root do grafo/BPM."
            }
        let result = {};
        if (isNLP(graphElement.getHostedType() as EGeneratorTransactionType)) {
            const isConnectedToInternalNLP = graphElement.getConnectionsToOthers().find(predicate => {
                if (predicate.getBusinessPredicateType() !== EBotActionType.contentGenerator) return;
                const predicateTarget = this.graphProcessor.findNodeByGraphID(predicate.getTargetElementId());
                return isNLP(predicateTarget.getHostedType() as EGeneratorTransactionType) && !predicateTarget.isExternalElementOnDiagram();
            });
            if (isConnectedToInternalNLP)
                return {
                    allowed: false,
                    errorMessage: "Não é possível remover esse elemento pois uma de suas intenções está conectada a outro Gerador de Conteúdo NLP.\nRemova essa conexão ou o gerador conectado e tente novamente."
                };
            return { allowed: true };
        }
        return super.canRemoveGraphNode(graphElement);
    }

    public canRemovePredicate(predicate: GraphPredicate): boolean {
        return true;
    }

    public async handleConnectionRemovalByUser(predicate: GraphPredicate): Promise<void> {
        const fromElementId = predicate.getFromElementId();
        const subElementId = predicate.getSubElementId();

        const from = this.graphProcessor.getElementById(fromElementId) as GraphElement;

        const nlpTransaction = typedCloneLodash(this.graphProcessor.getElementById(fromElementId).getHostedObject()) as INLPTransactionServer;
        const oldAsset = nlpTransaction.nlp.assets[subElementId].find(asset => isActionAsset(asset.type))
        if (isValidRef(oldAsset)) {
            const assets = nlpTransaction.nlp.assets[subElementId].filter(asset => asset.idAsset !== oldAsset.idAsset)
            const events = nlpTransaction.events.filter(asset => asset.idAsset !== oldAsset.idAsset)
            nlpTransaction.nlp.assets[subElementId] = assets;
            nlpTransaction.events = events;
        }

        const actionSaver = GraphElementActionSaver.create();
        const editedElementHost: NestedAItHostNode = <NestedAItHostNode>HostNodeFactory.create({
            bpmType: EBPMType.nestedAI,
            ns: nlpTransaction
        });

        const graphNodesToChange = await this.handleEditedNodeNserModelUpdateGraph(from, editedElementHost);
        actionSaver.concatChangedNodes(graphNodesToChange);
        await this.finishTransaction(actionSaver.getChangedNodes());
    }

    public updateSequencedGraphNodes(sequencedGraphList: GraphElement[]): NSGenericHost[] {
        throw new Error('Method not implemented.');
    }
    public performGraphImportSteps(idBotRoot: string, idRootGraphElement: string) {
        throw new Error('Method not implemented.');
    }
    public onUpdateElement(element: GraphElement, renderData: IGraphElementRenderData): void {
        throw new Error('Method not implemented.');
    }
    public dragInto(drag: IBPMDragInto): Promise<IDraggableReturn> {
        throw new Error('Method not implemented.');
    }
    public updateOrderedPosition(graphElement: GraphElement): TGraphElementActionDescriptorList {
        throw new Error('Method not implemented.');
    }
    public importPostProcessor(): Promise<void> {
        throw new Error('Method not implemented.');
    }
    public needLeftPushOnAutoImport(type: TReferencedObject): boolean {
        throw new Error('Method not implemented.');
    }
    public onDraggedToolbarElementExternalOnDiagramByUser(): void {
        throw new Error('Method not implemented.');
    }
    public onDraggedToolbarElementInternalOnDiagramByUser(): void {
        throw new Error('Method not implemented.');
    }
    public onDraggedToolbarElementOnExistingNodeByUser(): void {
        throw new Error('Method not implemented.');
    }
    public onEditedNodeByUser(): void {
        throw new Error('Method not implemented.');
    }
    public onDeletedNodeByUser(): void {
        throw new Error('Method not implemented.');
    }
    public removeOldConnections(from: GraphElement, newPredicateType: TReferencedObject): TGraphElementActionDescriptorList {
        throw new Error('Method not implemented.');
    }
}
