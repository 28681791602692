import { flat, flatMap, isValidArray, isValidRef, isValidString } from "../../../tools/utility";
import { TIBotActionAssetContainerArray } from '../../bot/bot-action-model';
import { ENextGenBotElementType } from "../../bot/bot-model";
import { NSGenericHost } from "../../graph-transaction/host/ns/ns.host";
import { EAdditionalPredicateType, EBPMElementMode, IBasicToolbarElement, TReferencedObject } from "../../graph-transaction/toolbar/config-toolbar.types";
import { ENonSerializableObjectType } from "../../non-serializable-id/non-serializable-id-interfaces";
import { BasicElement, IBasicElementCreate, TBasicElementArray } from "./basic-element";
import { IGraphElementJSON, IPredicateElementJSON } from "./graph-interfaces";
import { GraphRulesProcessor } from "./graph-rules-processor";
import { EGraphElementType, EPredicateType, IExternalSubTreeSliced, IGraphConnectionData, TIGraphConnectionDataArray, TIGraphNeighborArray } from "./graph-types";
import { IHostObjectInterface } from "./host-object";
import { GraphPredicate, ICreateNewPredicate, INewPredicate, TGraphPredicateArray } from "./predicate";



export interface IGraphCreateInterface {
    newElement: IBasicElementCreate,
    predicate: INewPredicate;
};

export interface IGraphElementContainer {
    element?: GraphElement
}

export interface IGraphNewElement extends IGraphCreateInterface {
    fromElement: GraphElement,
};

export interface IGraphElementCreationInfo {
    name: string,
    graphJSON: IGraphElementJSON
    ruleProcessor?: GraphRulesProcessor,
    hostObject?: IHostObjectInterface,
}

export class GraphElement extends BasicElement {
    static is(element: BasicElement): element is GraphElement {
        return element instanceof GraphElement;
    }

    protected ruleProcessor: GraphRulesProcessor;
    private isExternalElement: boolean;

    public constructor(
        json: IGraphElementJSON,
        ruleProcessor: GraphRulesProcessor,
        hostObject: IHostObjectInterface,
        name: string
    ) {
        super(name, json, hostObject);
        this.isExternalElement = json.isExternalElement;

        if (ruleProcessor) {
            this.ruleProcessor = ruleProcessor;
            ruleProcessor.addGraphElementToDatabase(this);
        }
    };

    public isExternalElementOnDiagram(): boolean { return this.isExternalElement; };

    public isDraggableIntoMe(otherNodeDraggedType: TReferencedObject): boolean {
        if (this.isExternalElement) {
            return false;
        }

        const edgesToOtherNodesCount = this.getDrawableConnectionsToOthers().length
        return this.getHostObject().isDraggableIntoMe(
            otherNodeDraggedType,
            edgesToOtherNodesCount);
    }

    public isDraggedIntoMeAnExternalElement(toolbarElement: IBasicToolbarElement): boolean {
        // const toolbarElement: IBasicToolbarElement = bpmRules.getOneToolbarElement(otherNodeDraggedType);

        const mode: EBPMElementMode = toolbarElement.mode;

        return mode === EBPMElementMode.Element
    }

    public isDraggedIntoMeAnInternalElement(toolbarElement: IBasicToolbarElement): boolean {
        return !this.isDraggedIntoMeAnExternalElement(toolbarElement)
    }

    thereIsMoreChildrenNodesToCreate(): boolean {
        return this.getHostObject().thereIsMoreChildrenNodesToCreate(this.isExternalElement)
    }

    getExternalSubTreeSliced(): IExternalSubTreeSliced {
        return this.getRuleProcessor().getExternalSubTreeSliced(this)
    }

    public dragOnMe(dragged: GraphElement, aux?: Object): void {
        dragged.getHostObject().externalChangeData(this.getHostObject(), aux);
        dragged.changeDetection();
        this.getHostObject().externalChangeData(dragged.getHostObject(), aux);
        this.changeDetection();
    }

    public getHostedID(): string {
        return this.getHostObject().getHostedID()
    }

    public setHostObject(object: NSGenericHost) {
        super.setHostObject(object);
    }

    public getHostObject(): NSGenericHost {
        return super.getHostObject() as NSGenericHost;
    };

    public getIDRoot(): string {
        return this.getRuleProcessor().getRootElement().getGraphElementID();
    }

    public toJSON(): IGraphElementJSON {
        return {
            ...super.toJSON(),
            elementType: EGraphElementType.node,
            isExternalElement: this.isExternalElement
        };
    };

    deleteNeighboursPointingToMe(): GraphElement[] { // Delete action from neighbours pointing to me*
        const deletedDataList = this.getNeighborsPointingToMe() // [{pred, neighbor}]
            .map((nodePoingToMe) => {
                const neighborPointingToMe: GraphElement = nodePoingToMe.neighbor
                const connection: GraphPredicate = nodePoingToMe.pred
                const deletedData = neighborPointingToMe.getHostObject()
                    .deleteConnectionTo(this.getHostObject(), connection.getBusinessPredicateType(), connection.getSubElementId())
                return { nodePoingToMe, deletedData }
            })
            .filter(data => data.deletedData.deletedConnection)
            .map(data => data.nodePoingToMe.neighbor)

        return deletedDataList
    }

    getAllEdgesPointingTo(to: GraphElement): GraphPredicate[] {
        const predicates = this.getRuleProcessor().getAllPredicates();
        return predicates.filter(
            (predicate: GraphPredicate) =>
                predicate.getFromElementId() === this.getGraphElementID() &&
                predicate.getToElementId() === to.getGraphElementID()
        ) as GraphPredicate[];
    }

    public getAllInAndOutConnections(): GraphPredicate[] {
        const connectionsFromOthers = this.ruleProcessor.getConnectionsFromOthers(this.getGraphElementID())
        const connectionsToOthers = this.ruleProcessor.getConnectionsToOthers(this.getGraphElementID())
        return flat([connectionsFromOthers, connectionsToOthers])
    }

    public getNeighborsPointingToMe(): TIGraphNeighborArray {
        const connectionsFromOthers = this.ruleProcessor.getConnectionsFromOthers(this.getGraphElementID())
        const allNeiboursPointingToMe = connectionsFromOthers.map(pred => ({ pred, neighbor: <GraphElement>this.ruleProcessor.getElementById(pred.getFromElementId()) }))
        return allNeiboursPointingToMe
    }

    public getNeighborsThatIPointTo(): GraphElement[] {
        const connectionsFromOthers = this.ruleProcessor.getConnectionsToOthers(this.getGraphElementID())
        const allNeiboursPointingToMe = new Set(connectionsFromOthers.map(pred => <GraphElement>this.ruleProcessor.getElementById(pred.getToElementId())));
        return Array.from(allNeiboursPointingToMe);
    }

    public getNeighborsAndEdgesThatIPointTo(): TBasicElementArray {
        const nodes = this.getNeighborsThatIPointTo();
        const edges = nodes.map(node => this.getAllEdgesPointingTo(node))
        return [nodes, edges.flat()].flat()
    }

    public getExternalNeighborsAndEdgesThatIPointTo(): TBasicElementArray {
        const externalNodes = this.getNeighborsThatIPointTo().filter(node => node.isExternalElementOnDiagram())
        const edges = externalNodes.map(node => this.getAllEdgesPointingTo(node))
        return [externalNodes, edges.flat()].flat()
    }

    public getFirstNeighbourThatIPointTo(): IGraphElementContainer {
        const elemenList = this.getNeighborsThatIPointTo()
        return {
            element: elemenList.length > 0 ? this.getNeighborsThatIPointTo()[0] : null
        }
    }

    public getAllNeighbors(): GraphElement[] {
        return flat([this.getNeighborsThatIPointTo(), this.getNeighborsPointingToMe().map(el => el.neighbor)])
    }

    public getAllNeighborsOfType(nodeHostedType: TReferencedObject): GraphElement[] {
        return this.getAllNeighbors().filter(node => node.getHostObject().isHostedOfType(ENextGenBotElementType.botMenuItem))
    }

    public getAllDrawableConnections(): GraphPredicate[] {
        const allConnections = [this.getDrawableConnectionsToOthers(), this.getDrawableConnectionsFromOthers()]
        return flatMap(allConnections, x => x)
    }

    public getConnectionsToOthers(): GraphPredicate[] {
        return this.ruleProcessor.getConnectionsToOthers(super.getGraphElementID()) as GraphPredicate[];
    };

    public getDrawableConnectionsToOthers(): GraphPredicate[] {
        const allConnections = this.getConnectionsToOthers()
        return allConnections
            .filter((pred) => { return this.isDrawableSrcToTarget(this, pred) })
    }

    public getDrawableConnectionsFromOthers(): GraphPredicate[] {
        const allConnections = this.getConnectionsFromOthers()
        return allConnections
            .filter((pred) => {
                const srcElement: GraphElement = <GraphElement>this.ruleProcessor.getElementById(pred.getFromElementId())
                return this.isDrawableSrcToTarget(srcElement, pred)
            })
    }

    public getConnectionsFromOthers(): TGraphPredicateArray {
        return this.ruleProcessor.getConnectionsFromOthers(super.getGraphElementID()) as TGraphPredicateArray;
    };

    isAlreadyConnectedToAnyOne(): boolean {
        return this.getDrawableConnectionsToOthers().length > 0
    }

    isAlreadyConnectedTo(target: GraphElement): boolean {
        return this.getDrawableConnectionsToOthers().some(predicate => predicate.getToElementId() === target.getGraphElementID());
    }

    public getDrawableConnectionsToOthersWithSamePredicateBusinessType(actionType: TReferencedObject): TGraphPredicateArray {
        return this.getDrawableConnectionsToOthers().filter(predicate => predicate.isSameBusinessPredicateType(actionType))
    }

    public isActionVisuallyConnectable(targetType: TReferencedObject, action: TReferencedObject): boolean {
        return this.getHostObject().isPredicateDrawable(targetType, action)
    }

    public isConnectionDrawableForTarget(targetType: TReferencedObject, actionTarget: TReferencedObject) {
        return this.getHostObject().isConnectionDrawableForTarget(targetType, actionTarget)
    }

    public getRuleProcessor(): GraphRulesProcessor { return this.ruleProcessor; };

    getExternalSubTree(includeItself: boolean = false): TBasicElementArray {
        return this.getRuleProcessor().getExternalSubTree(this, includeItself)
    }

    public getNserType(): ENonSerializableObjectType {
        return this.getHostObject().getNonSerializable().nsType
    }

    public createNewPredicateTo(element: GraphElement): GraphPredicate {
        const newPredicate: IPredicateElementJSON = this.buildNewPredicateJSON({
            fromElementId: this.getGraphElementID(),
            toElementId: element.getGraphElementID(),
            predicateName: element.getGraphElementID(),
            businessPredicateType: EAdditionalPredicateType.Parent,
            subElementId: undefined
        })
        const predicate: GraphPredicate = this.linkElements(this, element, newPredicate);
        return predicate;
    }

    public connectToOtherExistingNode(targetElement: GraphElement, sourceConnectionData: IGraphConnectionData): GraphPredicate {
        const newPredicate = this.buildNewPredicateJSON({
            fromElementId: this.getGraphElementID(),
            toElementId: targetElement.getGraphElementID(),
            predicateName: targetElement.getName(),
            businessPredicateType: sourceConnectionData.connectionType,
            subElementId: sourceConnectionData.subElementId
        })
        return this.linkElements(this, targetElement, newPredicate)
    }

    private buildNewPredicateJSON(info: ICreateNewPredicate): IPredicateElementJSON {
        const newPredicate: IPredicateElementJSON = {
            predicateType: EPredicateType.directed,
            fromElementId: info.fromElementId,
            toElementId: info.toElementId,
            outputName: info.predicateName,
            inputName: this.getName(),
            businessPredicateType: info.businessPredicateType,
            elementType: EGraphElementType.predicate,
            renderData: undefined,
            subElementId: info.subElementId ?? undefined
        };
        return newPredicate
    }

    public getPredicatedOfLinkedObject(fromElement: GraphElement, toElement: GraphElement, predicateJSON: IPredicateElementJSON): GraphPredicate {
        const connectionsToOthers = fromElement.getConnectionsToOthers();
        if (isValidString(predicateJSON.subElementId)) return connectionsToOthers.find(predicate => predicate.isSameSubElementId(predicateJSON.subElementId))
        return connectionsToOthers.find(predicate => predicate.isSameBusinessPredicateType(predicateJSON.businessPredicateType))
    }

    public getNewPredicate(json: IPredicateElementJSON): GraphPredicate {
        return new GraphPredicate(json);
    };

    public isPredicateExists(fromElement: GraphElement, toElement: GraphElement, predicateJSON: IPredicateElementJSON): boolean {
        return isValidRef(this.getPredicatedOfLinkedObject(fromElement, toElement, predicateJSON))
    }

    public upsertPredicateFor(predicateJSON: IPredicateElementJSON): GraphPredicate {
        const predicate: GraphPredicate = this.getNewPredicate(predicateJSON);
        return predicate
    }

    private linkElements(fromElement: GraphElement, toElement: GraphElement, predicateJSON: IPredicateElementJSON): GraphPredicate {
        const predicate = this.upsertPredicateFor(predicateJSON)
        this.getRuleProcessor().linkElements(fromElement, predicate, toElement);
        return predicate;
    }

    public changeDetection(): void {
        this.getRuleProcessor().getVisualComponentCallback().renderNodeGraphElement(this);
    }

    public syncInternalState(aux: Object = undefined): void {
        this.getHostObject().externalChangeData(this, aux);
    }

    public isNode(): boolean { return super.getElementType() === EGraphElementType.node; };
    public isLeaf(): boolean { return this.getElementType() === EGraphElementType.leaf; };
    public isRoot(): boolean { return super.getElementType() === EGraphElementType.root; };

    public getElementType(): EGraphElementType { return super.getElementType(); };

    public isEditable(): boolean {
        return this.getHostObject().isEditable(this);
    }

    private isDrawableSrcToTarget(srcElement: GraphElement, connection: GraphPredicate): boolean {
        const target = this.ruleProcessor.getElementById(connection.getToElementId())
        const isPredicateDrawable = srcElement.getHostObject().isPredicateDrawable(target.getHostedType(), connection.getBusinessPredicate())

        return connection.hasValidBusinessPredicate() && isPredicateDrawable
    }

    getConnectionsToRemove(): TGraphPredicateArray {
        return this.getHostObject().getConnectionsToRemove(this, this.ruleProcessor);
    }

    public static create(info: IGraphElementCreationInfo): GraphElement {
        return new GraphElement({
            ...info.graphJSON,
            elementType: EGraphElementType.node,
        },
            info.ruleProcessor,
            info.hostObject,
            info.name)
    }
}
