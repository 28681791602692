import { Serializable } from '@colmeia/core/src/business/serializable';
import { TGlobalUID } from '@colmeia/core/src/core-constants/types';
import { MainHandler } from './main-handler';
import {IComponentParameter} from "../model/component-comm/basic";
import {MultimediaInstance} from "@colmeia/core/src/multi-media/multi-media-instance";
import {EColorComponent} from "../model/component/color-component.model";
import {HexagonComponent, HexagonVisualizationType} from "../components/foundation/hexagon/hexagon.component";
import {MMconstant} from "@colmeia/core/src/multi-media/multimedia-constant";

export enum EHexagonColor {
    None,
    Golden
}

export interface IHandlerRecord {
    [primaryID: string]: HandlerHexagonon;
}

export enum EHexagonSizes {
    xss = 'xxs',
    xs = 'xs',
    xsm = 'xsm',
    smd = 'smd',
    xsmd = 'xsmd',
    sm = 'sm',
    xmd = 'xmd',
    md = 'md',
    lg = 'lg',
    xlg = 'xlg',
    xxlg = 'xxlg',
    lsmd = 'lsmd',
}

export type TValidExagononSize = EHexagonSizes.xss
    | EHexagonSizes.xs
    | EHexagonSizes.smd
    | EHexagonSizes.xsmd
    | EHexagonSizes.sm
    | EHexagonSizes.xmd
    | EHexagonSizes.md
    | EHexagonSizes.lg
    | EHexagonSizes.xlg
    | EHexagonSizes.xxlg
    | EHexagonSizes.lsmd

export type TvalidExagononSize =
    'xxs' | 'xsm' | 'xs' | 'smd' | 'xsmd' | 'sm' | 'xmd'| 'md' | 'lg' | 'xlg' | 'xxlg' | 'lsmd';


export enum EHexagonFormat {
    Circle = 1,
    RoundedSquare = 2
}

// Identifier: caso estejamos editando mais de uma tag do mesmo serializable.. passar esse identifier
// Se não passado, será o primaryID do Serializable
export interface IHexagononParameter extends IComponentParameter {
    format?: EHexagonFormat;
    identifier?: TGlobalUID;
    serializable?: Serializable;
    isSelectionable?: boolean;
    size?: TvalidExagononSize;
    idMultimediaTag?: TGlobalUID;
    multimediaInstance?: MultimediaInstance;
    forceImage?: string;
    forceURL?: string;
    fileMode?: boolean;
    color?: EColorComponent;
    visualizationType?: HexagonVisualizationType;
    wrapperClass?: string;
    newIcon?: boolean;
};

export type THandlerHexagononArray = Array<HandlerHexagonon>;
export class HandlerHexagonon extends MainHandler  {

    public itemURL: string;

    private mmInstance: MultimediaInstance;
    private identifier: TGlobalUID;
    private component: HexagonComponent;
    private static defaults: IHexagononParameter = {
        serializable: null,
        color: EColorComponent.White,
        size: EHexagonSizes.xsmd,
        clientCallback: null,
        isSelectionable: true,
        idMultimediaTag: MMconstant.tag.photo,
        multimediaInstance: null,
        forceImage: null,
    };
    constructor (parameters: IHexagononParameter) {
        super(parameters);
        if (parameters.identifier) {
            this.identifier = parameters.identifier;
        } else if (parameters.serializable) {
            this.identifier = parameters.serializable.getPrimaryID();
        }
    };

    static newForIcon(serializableWithIcon: Serializable, size: EHexagonSizes): HandlerHexagonon {
        const defaultParameter = HandlerHexagonon.defaults;
        const param: IHexagononParameter = {
            ...defaultParameter,
            size,
            serializable: serializableWithIcon,
            forceURL: serializableWithIcon.getIcon()
        };
        return new HandlerHexagonon(param);
    }

    static newEmpty(size: TvalidExagononSize = HandlerHexagonon.defaults.size): HandlerHexagonon {
        const defaultParameter = HandlerHexagonon.defaults;
        return new HandlerHexagonon({
            ...defaultParameter,
            size
        });
    }

    static newHandler(parameter: Partial<IHexagononParameter>): HandlerHexagonon {
        const defaultParameter = HandlerHexagonon.defaults;
        return new HandlerHexagonon({
            ...defaultParameter,
            ...parameter
        });
    }

    public getColor(): EColorComponent {
        return this.getComponentParameter().color;
    }

    public getExagonoClient(): HandlerHexagonon {return this;};

    public getComponentParameter(): IHexagononParameter {
        return <IHexagononParameter>super.getComponentParameter();
    };

    public setSize(size: TvalidExagononSize): void {
        this.getComponentParameter().size = size;
    };

    public getEditingSerializable(): Serializable { return this.getComponentParameter().serializable; };

    public destroyComponent(): void {
        this.component = null;
    };

    public getWrapperClass(): string {
        return this.getComponentParameter().wrapperClass;
    }

    public setWrapperClass(clazz: string) {
        this.getComponentParameter().wrapperClass = clazz;
    }
};



