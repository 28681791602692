import { Injectable } from '@angular/core';
import { IGeneralFormAnswer, IRFieldResponse, TGeneralFieldArray, TGeneralFormServerAnswerArray } from '@colmeia/core/src/general-form/general-form-answer';
import { IFormSchema } from '@colmeia/core/src/general-form/general-form-interface';
import { Interaction } from '@colmeia/core/src/interaction/interaction';
import { StartServiceChat } from '@colmeia/core/src/interaction/service-group/start-service-chat';
import { getSocialCCThreadId } from '@colmeia/core/src/rules/thread-conversation-functions';
import { EMetadataNames } from '@colmeia/core/src/shared-business-rules/metadata/metadata-db';
import { mergeFormsByCanonical, mergeResponseWithBotComputedState } from '@colmeia/core/src/shared-business-rules/metadata/metadata-merge';
import { TComputedInfo } from '@colmeia/core/src/shared-business-rules/metadata/metadata-utils';
import { isValidRef, keys } from '@colmeia/core/src/tools/utility';
import { AnnotationsModalComponent } from 'app/components/annotation/annotations-modal/annotations-modal.component';
import { ColmeiaWindowRef } from 'app/components/dashboard/dashboard-foundation/colmeia-window/colmeia-window-ref';
import { ColmeiaWindowService } from 'app/components/dashboard/dashboard-foundation/colmeia-window/colmeia-window.service';
import { AnnotationsModalHandler, EAnnotationsType, IAnnotationsModalParameters } from 'app/handlers/annotations-modal-handler';
import { IFormSchemmaAndEngagement } from 'app/model/chat-options.model';
import { EGeneralFormViewType } from 'app/model/general-form.model';
import { take } from 'rxjs/operators';
import { AttendanceService } from './attendance.service';
import { GeneralFormService } from './general-form.service';
import { ScreenSpinnerService } from './screen-spinner.service';

type TAttendanceAnnotationsItem = {
    idGroup: string;
    idInteraction: string;
    idSchemma: string;
    windowRef: ColmeiaWindowRef;
    isFinalization: boolean;
    groupName?: string;
};

type TAttendanceAnnotationsMatrix = TAttendanceAnnotationsItem[]; // Go watch The matrix
type TAttendanceAnnotationsIndexKeys = Pick<TAttendanceAnnotationsItem, 'idGroup' | 'idInteraction' | 'idSchemma' | 'isFinalization' | 'groupName'>;

interface IInitAnnotationParams {
    idGroup: string,
    idInteraction: string,
    metadata: IFormSchemmaAndEngagement,
    isFinalization?: boolean,
    title?: string,
    groupName?: string,
    annotationsParameters?: Partial<IAnnotationsModalParameters>,
}

function matchToItem(item: TAttendanceAnnotationsItem, keysToMatch: Partial<TAttendanceAnnotationsItem>) {
    return keys(keysToMatch).every((k) => item[k] === keysToMatch[k]);
}

@Injectable({
    providedIn: 'root'
})
export class AttendanceAnnotationsService {

    private _annotations: TAttendanceAnnotationsMatrix = [];

    constructor(
        private attendanceService: AttendanceService,
        private generalFormSvc: GeneralFormService,
        private windowSvc: ColmeiaWindowService,
        private spinnerSvc: ScreenSpinnerService,
    ) { }

    private getClientName(interaction: Interaction): string {
        const allComputedValues: TComputedInfo = this.attendanceService.getAllComputedInfoForAttendance(interaction.getGroupID());
        const clientName: string = allComputedValues[EMetadataNames.name] || allComputedValues[EMetadataNames.customerProvideName];

        return clientName
    }

    private getWindowGroupName(interaction: Interaction): string {
        const clientName: string = this.getClientName(interaction);
        const groupName: string = `Anotações: ${clientName}`;

        return groupName;
    }

    async initAnnotation({
        idGroup,
        idInteraction,
        metadata,
        isFinalization = false,
        title = metadata.form.name,
        groupName,
        annotationsParameters,
    }: IInitAnnotationParams): Promise<ColmeiaWindowRef> {
        const { idSchemma } = metadata.form;
        const indexKeys: TAttendanceAnnotationsIndexKeys = { groupName, idGroup, idInteraction, idSchemma, isFinalization };

        if (this.has(indexKeys)) {
            this.restoreWindow(indexKeys);
            return;
        }

        this.spinnerSvc.show();

        const interaction: Interaction = Interaction.staticFactory(idInteraction);
        const answer: IGeneralFormAnswer = await this.getAnswer(idGroup, metadata.form);
        const annotationsData: IAnnotationsModalParameters = {
            answer,
            idGroup,
            type: EAnnotationsType.onlyAnswersFromInteraction,
            serializableId: interaction.getPrimaryID(),
            persist: true,
            selectedSchema: metadata.form,
            viewType: EGeneralFormViewType.save,
            clientCallback: undefined,
            engagement: metadata.engagement,
            computedInfo: this.attendanceService.getAllComputedInfoForCurrentAttendance(),
            onSaveAnnotationCallback: () => { },
            ...annotationsParameters,
        };

        const handler: AnnotationsModalHandler = new AnnotationsModalHandler(annotationsData);
        const group: string = groupName || this.getWindowGroupName(interaction);
        const windowRef = this.windowSvc.open(AnnotationsModalComponent, {
            group,
            title,
            data: handler,
            panelClass: "medium-size",
        });

        this.setItem(indexKeys, windowRef);

        windowRef.afterClosed().subscribe(() => {
            this.removeItem(indexKeys);
        });

        windowRef.afterOpened().pipe(take(1)).subscribe(() => {
            this.spinnerSvc.hide();
        });

        return windowRef;
    }

    public has(keys: Partial<TAttendanceAnnotationsIndexKeys>) {
        return this._annotations.some(item => matchToItem(item, keys));
    }

    private restoreWindow(keys: TAttendanceAnnotationsIndexKeys) {
        this.find(keys).windowRef.restore();
    }

    public find(keys: TAttendanceAnnotationsIndexKeys): TAttendanceAnnotationsItem | undefined {
        return this._annotations.find(item => matchToItem(item, keys));
    }

    public query(keys: Partial<TAttendanceAnnotationsIndexKeys>): TAttendanceAnnotationsItem[] {
        return this._annotations.reduce((result, item) => {
            if (matchToItem(item, keys)) {
                result.push(item);
            }
            return result
        }, [])
    }

    private setItem(keys: TAttendanceAnnotationsIndexKeys, windowRef: ColmeiaWindowRef): void {
        this._annotations.push({ ...keys, windowRef });
    }

    private removeItem(keys: TAttendanceAnnotationsIndexKeys) {
        const idx: number = this._annotations.findIndex(item => matchToItem(item, keys));
        this._annotations.splice(idx, 1);
    }

    private async getAnswer(idGroup: string, metadata: IFormSchema): Promise<IGeneralFormAnswer> {
        const interaction = this.attendanceService.getReplyInteraction(idGroup) as StartServiceChat;
        const idConversation = getSocialCCThreadId(interaction);
        const result = await this.generalFormSvc.getAnswersForConversation(idConversation);
        const attendanceAnswers: TGeneralFormServerAnswerArray = result.responses
        const canonicalMergedAnswers: TGeneralFieldArray = [];

        attendanceAnswers.forEach(res => {
            mergeFormsByCanonical(res.responses, metadata.form, canonicalMergedAnswers);
        });

        await this.generalFormSvc.mergeAnswersWithChannel(
            EMetadataNames.cellular,
            canonicalMergedAnswers,
            metadata,
        );

        if (isValidRef(result.allFieldMap)) {
            mergeResponseWithBotComputedState(canonicalMergedAnswers, result.allFieldMap);
        }

        const field = metadata.form.find(field => field.idGlobalCanonical === EMetadataNames.attendent);
        if (isValidRef(field)) {
            const fieldResponse: IRFieldResponse = {
                idGlobalCanonical: field.idGlobalCanonical,
                idLocalCanonical: field.idLocalCanonical,
                idProperty: field.idProperty,
                raw: interaction.getAgentName(),
                value: interaction.getAgentName(),
            }
            canonicalMergedAnswers.push(fieldResponse)
        }

        const answer: IGeneralFormAnswer = {
            idSchemma: metadata.idSchemma,
            responses: canonicalMergedAnswers,
            text: '',
            primaryID: interaction.getPrimaryID(),
            json: undefined
        };

        return answer;
    }

    /**
     * Registros de atendente do atendimento atual selecionado
     */
    public attendanceFormData?: IFormSchemmaAndEngagement[];
}
