import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { EDelivery360Action } from '@colmeia/core/src/comm-interfaces/interaction-interfaces';
import { EMenuMode, ENextGenBotElementType, IBotMenuContainerServer, IBotMenuItemServer } from '@colmeia/core/src/shared-business-rules/bot/bot-model';
import { MAX_WHATSAPP_TEMPLATE_BODY_SIZE, MAX_WHATSAPP_BUTTON_TITLE_SIZE, MAX_WHATSAPP_LIST_ROW_TITLE_SIZE, MAX_WHATSAPP_LIST_SECTION_TITLE_SIZE, MAX_WHATSAPP_LIST_ROW_DESCRIPTION_SIZE } from '@colmeia/core/src/shared-business-rules/social-media/social-media.model';
import { CmRenderOptionsService } from '../dashboard/ai-pages/bots/bot-edit/bot-configuration/cm-render-options/cm-render-options.service';
import { empty } from '@colmeia/core/src/tools/utility';
import { IBotEditConfigurationHandlerParameter } from 'app/handlers/bot-edit-configuration.handler';
import { cast } from '@colmeia/core/src/tools/utility-types';
import { horizontalAppear } from '../dashboard/dashboard-animations';

declare var window: Window;

@Component({
    selector: 'app-input-with-edit-icon',
    templateUrl: './input-with-edit-icon.component.html',
    styleUrls: ['./input-with-edit-icon.component.scss'],
    animations: [
        horizontalAppear,
    ]
})

export class InputWithEditIconComponent {
    @Input() botData?: IBotEditConfigurationHandlerParameter;
    @Input() textLabel: string;
    @Input() inputValue: string = '';
    @Input() showValidation: boolean = false;
    @Input() helpIconValue: string = '';
    @Input() highlightElement?: boolean = false;
    @Input() darkColorModeClass: string = '';
    @Output() inputValueChange = new EventEmitter<string>();

    isReadonly: boolean = true;
    public $notAllowed = empty;

    @ViewChild('inputElement') inputElement!: ElementRef<HTMLInputElement>

    constructor(
        private cmRenderOptionsSvc: CmRenderOptionsService,
    ) { }

    get menuContainer(): IBotMenuContainerServer {
        return cast(this.botData?.botElement);
    }

    get menuItem(): IBotMenuContainerServer {
        return cast(this.botData?.botElement);
    }

    get isSectionInput(): boolean {
        return this.textLabel === 'Nome da seção';
    }

    enableEditing() {
        this.isReadonly = false;
        this.inputElement.nativeElement.focus();
        this.inputElement.nativeElement.select();
    }

    onBlur() {
        this.isReadonly = true;
        this.inputValueChange.emit(this.inputValue);
    }

    public openSectionNameValidation(): void {
        return this.cmRenderOptionsSvc.openMenuValidationTable((<IBotMenuItemServer>this.botData.botElement).section, {
            [EDelivery360Action.Delivery360WhatsApp]: {
                [EMenuMode.Quick]: this.$notAllowed,
                [EMenuMode.Full]: MAX_WHATSAPP_LIST_SECTION_TITLE_SIZE,
                [EMenuMode.Text]: this.$notAllowed,
            },
        });
    }

    public openMenuContainerTextValidation() {
        return this.cmRenderOptionsSvc.openMenuValidationTable(this.inputValue, {
            [EDelivery360Action.Delivery360WhatsApp]: {
                [EMenuMode.Quick]: MAX_WHATSAPP_TEMPLATE_BODY_SIZE,
                [EMenuMode.Full]: MAX_WHATSAPP_TEMPLATE_BODY_SIZE,
                [EMenuMode.Text]: MAX_WHATSAPP_TEMPLATE_BODY_SIZE,
            },
        });
    }

    public openMenuItemTextValidation() {
        return this.cmRenderOptionsSvc.openMenuValidationTable(this.inputValue, {
            [EDelivery360Action.Delivery360WhatsApp]: {
                [EMenuMode.Quick]: MAX_WHATSAPP_BUTTON_TITLE_SIZE,
                [EMenuMode.Full]: MAX_WHATSAPP_LIST_ROW_TITLE_SIZE,
                [EMenuMode.Text]: MAX_WHATSAPP_TEMPLATE_BODY_SIZE,
            },
        });
    }

    public openTextToShowValidation(): void {
        if (this.menuContainer) return this.openMenuContainerTextValidation();
        if (this.menuItem) return this.openMenuItemTextValidation();
    }

    public openSectionDescriptionValidation(): void {
        return this.cmRenderOptionsSvc.openMenuValidationTable((<IBotMenuItemServer>this.botData?.botElement).description, {
            [EDelivery360Action.Delivery360WhatsApp]: {
                [EMenuMode.Quick]: this.$notAllowed,
                [EMenuMode.Full]: MAX_WHATSAPP_LIST_ROW_DESCRIPTION_SIZE,
                [EMenuMode.Text]: this.$notAllowed,
            },
        });
    }

    get showHelpIcon(): boolean {
        return !!this.helpIconValue;
    }
}
