<div class="scroll-container nice-scroll"
[ngStyle]="{height: height || '600px'}"
(scroll)="onScroll($event)" #scrollContainer>
  <!-- Ensure the content is properly projected -->
  <div class="content-container">
    <ng-content></ng-content>
  </div>
  <div class="spinner-container" *ngIf="isLoading">
    <mat-spinner diameter="18"></mat-spinner>
  </div>
</div>
