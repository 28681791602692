import { Component, OnInit, Inject } from '@angular/core';
import { IFilterlet } from '@colmeia/core/src/rules/dynamic-filter/dynamic-filter-interfaces';
import { DatePickerHandler } from 'app/handlers/date-picker.handler';
import { filterletToken, chatFilterEventsToken, IChatFilterEvents } from 'app/model/chat-filter.model';
import { RootComponent } from 'app/components/foundation/root/root.component';
import { gTranslations } from '@colmeia/core/src/shared-business-rules/const-text/translations';

@Component({
	selector: 'app-chat-date-filter',
	templateUrl: './chat-date-filter.component.html',
	styleUrls: ['./chat-date-filter.component.scss']
})
export class ChatDateFilterComponent extends RootComponent<
	'resetDates' | 'start' | 'finish'
> implements OnInit {

	minHandler: DatePickerHandler;
	maxHandler: DatePickerHandler;

	min: any;
	max: any;

	constructor(
		@Inject(filterletToken) public filterlet: IFilterlet,
		@Inject(chatFilterEventsToken) private events: IChatFilterEvents,
	) {
		super({
			resetDates: gTranslations.fragments.resetDates,
			start: gTranslations.common.begin,
			finish: gTranslations.common.end,
		});
	}

	ngOnInit() {

		const {
			interval: {
				min = undefined,
				max = undefined
			} = {}
		} = this.filterlet

		this.min = min
		this.max = max

		this.resetHandlers()

		const sub = this.events.applied.subscribe(() => {
			if (!this.filterlet.interval) this.filterlet.interval = {
				min: undefined,
				max: undefined
			}
			this.filterlet.interval.min = this.min
			this.filterlet.interval.max = this.max

			sub.unsubscribe();
		})
	}

	resetFilter() {
		this.min = undefined
		this.max = undefined
	}

	resetDates() {
		this.resetFilter()
		this.resetHandlers()
	}

	resetHandlers() {
		this.minHandler = DatePickerHandler.new({
			current: this.min,
			onPick: time => this.min = time
		})

		this.maxHandler = DatePickerHandler.new({
			current: this.max,
			onPick: time => this.max = time
		})
	}
}
