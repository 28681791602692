
import { TArrayID } from '../../core-constants/types';
import { ENonSerializableObjectType, INonSerializable, INonSerializableHeader, NonSerializable } from '../non-serializable-id/non-serializable-id-interfaces';
import { EEntryCancelReason, TChannelRestrictions, TICampaingVariableConfigArray } from './campaing-interfaces';
import { IBPConditionalEvaluator } from '../BPM/bpm-model';
import { IdDep, IdNS } from '../non-serializable-id/non-serializable-types';
import { IBPMMarketingRootServer } from '../BPM/marketing/bpm-marketing.model';
import { WhatsApp } from '../social-media/whatsapp-interface';

export type TCampaingExecutionHeaderArray = Array<ICampaignExecutionHeader>;




export interface ICampaignRecency {
    recencyTimeMS: number; // Enviar apenas se ___h após a última comunicação
    ignoreIfCustomerContactAfter: boolean;  // Enviar de qualquer caso última comunicação seja do cliente
    removeAllScheduledActions: boolean; // Remove campanhas já agendadas
};


export enum ERunCampaignCadenceType {
    proportion = 'proportion',
    automatic = 'automatic',
}

export interface IProportionalCadenceEntry {
    proportion: number; // 30%
    minutesAfterLast: number; // Não pergunta primiero (zero)
}

export type TCadenceProportional = Array<IProportionalCadenceEntry>;

export interface IProportionalCadenceControl {
    proportions: TCadenceProportional
}

export interface IRunCampaignCadenceConfig {
    type: ERunCampaignCadenceType;
    proportionalCadence?: IProportionalCadenceControl;
}

export enum EAutomaticAllocationRule {
    IslandCapacity = 'islandCapacity', // (amountAgents * islandWeight) * sendRules.value
    Contacts = 'contacts',
    PercentOfDatabase = 'percentOfDatabase',
}

export interface IRunCampaignAutomaticAllocation {
    resumeOccupation: number;
    maxOccupation: number;

    timeBetweenProcessOnSuccessMS: number; //
    timeBetweenProcessOnFailureMS: number; //

    maxTriesOnFailure: number;
    doNotSendAfter: number;

    idIslandToCheck: string // IdDep<ENonSerializableObjectType.serviceIsland>; // Filtrar dependências nestadas

    sendRulesType?: EAutomaticAllocationRule;
    sendRulesValue?: number;
}

export enum ERunCampaignExecutionMethod {
    Cadence = 'Cadence',
    Allocation = 'Allocation',
    None = 'None',
}

export interface ICampaignExecutionHeader extends INonSerializableHeader {
    nsType: ENonSerializableObjectType.runCampaing,
    idFiredAction: string;
    files: TArrayID;
    scheduleBeginning?: number;  // ClockTick
    constants?: TICampaingVariableConfigArray;
    recency?: ICampaignRecency
    filter?: IBPConditionalEvaluator;

    executionMethod?: ERunCampaignExecutionMethod;

    cadence?: IRunCampaignCadenceConfig;
    allocation?: IRunCampaignAutomaticAllocation;

    idSchedule?: string;
    idChannels?: IdDep<ENonSerializableObjectType.socialMediaConnection>[];

    mktRulerId: IdNS<IBPMMarketingRootServer>;
    mktRulerOffset?: number;
    exeType: ECampaignExecutionType;
    blockedIn?: number;  // ClockTick
    templateStatus?: WhatsApp.Template.Status;
    cancelIn?: number; // ClockTick
    cancelReason?: EEntryCancelReason;

    databaseBlocklistArray?: TChannelRestrictions;
    shouldIgnoreOptout?: boolean;
    enableBlocklistOnRunCampaign?: boolean;
};

export enum ECampaignExecutionType {
    activeCall = 'acCall', // Campanha de Marketing ativo
    activeClosed = 'accCallClosed',  // Ativo, mas fechado
    traditional = 'trad', // campanha Tradicional,
    bpmMkt = 'bpmMkt'
}

export interface ICampaignExecutionServer extends NonSerializable<ENonSerializableObjectType.runCampaing>, ICampaignExecutionHeader {
    targets: number;
    ignored?: number;
    finishedAt?: number;
    idUserWhoSent?: string;
}
