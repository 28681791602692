<section class="chat-feedback-filter">

  <section class="no-feedbacks" *ngIf="feedbacks?.length == 0; else showFeedbacks">
    Não há reações disponíveis para o filtro
  </section>

  <ng-template #showFeedbacks>
    <section class="available-feedbacks">
      <div class="feedback" *ngFor="let item of feedbacks">
        <text-icon-white-box
          [title]="item.name"
          [text]="item.idMedia"
          [bigText]="true"
          (click)="toggleFeedback(item)"
          [backgroundColor]="selectedFeedbacks.includes(item.primaryID) ? 'blue' : 'white'"
        ></text-icon-white-box>
      </div>
    </section>
  </ng-template>

</section>