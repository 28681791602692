import { narrow } from "../../tools/utility";
import { WhatsApp } from "./whatsapp-interface";

export const DEFAULT_LIST_PICKER_BUTTON_TEXT = narrow<string>()('Escolha um');


export namespace WhatsAppParser {
    interface PatternConfig {
        regex: RegExp;
        tag: string;
        markupChar: string;
        markup: string;
    }
    const configs: PatternConfig[] = [
        {
            regex: /(?:^|[\s\W_])\*(\S|\S[^\n]*?\S)\*(?=$|[[\s\W_])/g,
            tag: 'strong',
            markupChar: '*',
            markup: '*',
        },
        {
            regex: /(?:^|\s|_|[^\w\\])_(\S|\S[^\n]*?\S)_(?=$|[[\s\W_])/g,
            tag: 'em',
            markupChar: '_',
            markup: '_',
        },
        {
            regex: /(?:^|[\s\W_])~(\S|\S[^\n]*?\S)~(?=$|[[\s\W_])/g,
            tag: 'del',
            markupChar: '~',
            markup: '~',
        },
        {
            regex: /```([\s\S]*?\S[\s\S]*?)```/g,
            tag: 'code',
            markupChar: '`',
            markup: '```',
        },
    ];

    function format(content: string, patternConfig: PatternConfig) {
        return !patternConfig.regex.test(content)
            ? content
            : content.replace(patternConfig.regex, function (accumulator: string, current: string) {
                accumulator = accumulator.charAt(0) !== patternConfig.markupChar ? accumulator.charAt(0) : '';
                return accumulator + '<' + patternConfig.tag + '>' + current + '</' + patternConfig.tag + '>';
            });
    }


    function formatEmpty(content: string, patternConfig: PatternConfig) {
        return !patternConfig.regex.test(content)
            ? content
            : content.replace(patternConfig.regex, function (accumulator: string, current: string) {
                accumulator = accumulator.charAt(0) !== patternConfig.markupChar ? accumulator.charAt(0) : '';
                return accumulator + current;
            });
    }

    export function customOf(fn: (char: string, patternConfig: PatternConfig) => string) {
        return function execute(content: string, patternConfig: PatternConfig) {
            return !patternConfig.regex.test(content)
                ? content
                : content.replace(patternConfig.regex, function (accumulator: string, current: string) {
                    accumulator = accumulator.charAt(0) !== patternConfig.markupChar ? accumulator.charAt(0) : '';
                    return accumulator + fn(current, patternConfig);
                })
                ;
        }
    }


    export function customFormat(fn: (char: string, patternConfig: PatternConfig) => string) {
        return (message: string) => configs.reduce(customOf(fn), message);
    }

    /**
     * 
     * @example 'foo *test*bar' // 'foo *test*bar'
     * @example 'foo *test* bar' // 'foo <strong>test</strong> bar'
     * @returns 
     */
    export function formatEmptyMessage(message: string) {
        return configs.reduce(formatEmpty, message);
    }

    /**
     * 
     * @example 'foo *test*bar' // 'foo *test*bar'
     * @example 'foo *test* bar' // 'foo <strong>test</strong> bar'
     * @returns 
     */
    export function formatMessage(message: string) {
        return configs.reduce(format, message);
    }
}

export const whatsappHeaderFormatsIncompatibleWithLTO: Array<WhatsApp.Message.Template.Structure.Format> = [
    WhatsApp.Message.Template.Structure.Format.Text,
    WhatsApp.Message.Template.Structure.Format.Document
];

export const whatsappHeaderFormatsIncompatibleWithCarousel: Array<WhatsApp.Message.Template.Structure.Format> = [
    WhatsApp.Message.Template.Structure.Format.Text,
    WhatsApp.Message.Template.Structure.Format.Document
];