import { Injectable } from '@angular/core';
import { EBPMType } from '@colmeia/core/src/shared-business-rules/BPM/bpm-model';
import { CrmHostNode } from '@colmeia/core/src/shared-business-rules/graph-transaction/host/crm/crm-host-node';
import { HostNodeFactory } from '@colmeia/core/src/shared-business-rules/graph-transaction/host/factory/host-node.factory';
import { NSGenericHost } from '@colmeia/core/src/shared-business-rules/graph-transaction/host/ns/ns.host';
import { EAdditionalInternalType, TBPMCRMElementTypes, TReferencedObject } from '@colmeia/core/src/shared-business-rules/graph-transaction/toolbar/config-toolbar.types';
import { TBasicElementArray } from '@colmeia/core/src/shared-business-rules/graph/essential/basic-element';
import { BasicElementFactory } from '@colmeia/core/src/shared-business-rules/graph/essential/basic-element.factory';
import { IGraphElementRenderData } from '@colmeia/core/src/shared-business-rules/graph/essential/diagram.interfaces';
import { GraphElement } from '@colmeia/core/src/shared-business-rules/graph/essential/graph-element';
import { GraphElementActionSaver, TGraphElementActionDescriptorList } from '@colmeia/core/src/shared-business-rules/graph/essential/graph-element-action.types';
import { EGraphElementType, IGraphConnectionData, IInternalElementRenderData, TIGraphConnectionDataArray } from '@colmeia/core/src/shared-business-rules/graph/essential/graph-types';
import { GraphPredicate } from '@colmeia/core/src/shared-business-rules/graph/essential/predicate';
import { ENonSerializableObjectType, INonSerializable } from '@colmeia/core/src/shared-business-rules/non-serializable-id/non-serializable-id-interfaces';
import { isValidNumber, isValidRef } from '@colmeia/core/src/tools/utility';
import { IBPMDialogResult } from 'app/components/dashboard/ai-pages/bpm-graph/diagram-editor/diagram-editor.types';
import { DiagramEditorDialogService } from 'app/components/dashboard/ai-pages/bpm-graph/diagram-editor/services/diagram-editor-dialog.service';
import { LookupService } from 'app/services/lookup.service';
import { RoutingService } from 'app/services/routing.service';
import { SnackMessageService } from 'app/services/snack-bar';
import { BpmApiService } from '../api/bpm-bot-api.service';
import { BPMRulesBase } from './bpm-rules-base';
import { BpmRulesBotDataService } from './bpm-rules-bot-data.service';
import { BpmRulesBotImportGraphService } from './bpm-rules-bot-import-graph.service';
import { IBPMDragInto, IDraggableReturn, IGraphNodeContainerWithDraggedNser, IGraphNodeToUpdate, INewGraphElementFromDraggedToolbarIcon, IToolbarElementDraggedOnExistingNode, IUpserterComponentReturn, IUpserterNSComponentInfo, TConnectElementsResult } from './bpm-rules.types';
import { CrmFrontEndDialogFactory } from './factory/crm-frontend-factory';
import { IBPMBaseNodeServer } from '@colmeia/core/src/shared-business-rules/BPM/common/common-bpm';
import { ENodeAssetIcons } from '@colmeia/core/src/shared-business-rules/bot/new-bot-action';
import { ICRMRootServer, ICRMAgentEventServer, ICRMNotificationServer } from '@colmeia/core/src/shared-business-rules/crm/crm-services/crm-bpm-model';
import { BpmGraphNodeDetailsService } from 'app/components/dashboard/ai-pages/bpm-graph/bpm-graph-node-details/bpm-graph-node-details.service';

@Injectable({
    providedIn: 'root'
})
export class BpmRulesCrmService extends BPMRulesBase {

    constructor(
        private bpmBotApiService: BpmApiService,
        private bpmRulesBotDataService: BpmRulesBotDataService,
        private bpmRulesBotImportGraphSvc: BpmRulesBotImportGraphService,
        private snackSvc: SnackMessageService,
        protected diagramEditorDialogSvc: DiagramEditorDialogService,
        private nserLookupService: LookupService,
        protected routingSvc: RoutingService,
        protected bpmGraphNodeDetailsService: BpmGraphNodeDetailsService
    ) {
        super(
            EBPMType.crm,
            bpmBotApiService,
            bpmRulesBotDataService,
            bpmRulesBotImportGraphSvc,
            snackSvc,
            nserLookupService,
            routingSvc,
            diagramEditorDialogSvc,
            bpmGraphNodeDetailsService)
    }

    /**
     * getAdditionalElementToBeRemoved gets all elements that needs to be removed when a node is updated
     * all nodes from crm can listen to events,
     * so all external needs to be removed if listento data-structure changes
     * @param updatedElement
     * @returns
     */
    public getAdditionalElementToBeRemoved(updatedElement: GraphElement): TBasicElementArray {
        const nodes = updatedElement.getExternalNeighborsAndEdgesThatIPointTo()
        // const result = nodes.map(neighbour => neighbour.getExternalSubTree(true)).flat()
        return nodes
    }

    performGraphImportSteps(idBotRoot: string, idRootGraphElement: string) {
        throw new Error('Method not implemented.');
    }
    onUpdateElement(element: GraphElement, renderData: IGraphElementRenderData): void {
        throw new Error('Method not implemented.');
    }
    handleConnectionAdditionByUser(from: GraphElement, to: GraphElement, connectionData: IGraphConnectionData): Promise<void> {
        throw new Error('Method not implemented.');
    }
    canConnectElements(from: GraphElement, to: GraphElement): TConnectElementsResult {
        throw new Error('Method not implemented.');
    }
    canRemovePredicate(predicate: GraphPredicate): boolean {
        throw new Error('Method not implemented.');
    }
    handleConnectionRemovalByUser(predicate: GraphPredicate): Promise<void> {
        throw new Error('Method not implemented.');
    }

    getInternalIconsFromNode(graphElement: GraphElement): IInternalElementRenderData[] {
        const ret: IInternalElementRenderData[] = [];

        const ns: IBPMBaseNodeServer = graphElement.getHostedObject();

        if (isValidNumber(ns.bpmScheduleMS)) {
            ret.push({
                type: EAdditionalInternalType.Timer,
                hasValidConfiguration: true
            })
        }

        return ret;
    }

    getNextStepInfoFromNode(graphElement: GraphElement) {
        return undefined;
    }

    getNodeColor(graphElement: GraphElement): string {
        return undefined;
    }

    updateSequencedGraphNodes(sequencedGraphList: GraphElement[]): NSGenericHost[] {
        return []
    }

    updatePosActionRemoval(graphElementList: TBasicElementArray): TGraphElementActionDescriptorList {
        return []
    }

    dragInto(drag: IBPMDragInto): Promise<IDraggableReturn> {
        throw new Error('Method not implemented.');
    }


    handleInternalToolbarElementDragOnElementByUser(targetElement: GraphElement, draggedType: TReferencedObject, draggedNS?: INonSerializable): void {
        throw new Error('Method not implemented.');
    }
    updateOrderedPosition(graphElement: GraphElement): TGraphElementActionDescriptorList {
        throw new Error('Method not implemented.');
    }

    needLeftPushOnAutoImport(type: TReferencedObject): boolean {
        throw new Error('Method not implemented.');
    }
    importPostProcessor(): Promise<void> {
        throw new Error('Method not implemented.');
    }

    isDraggableOnDiagram(crmNodeType: TReferencedObject): boolean {
        const isRoot = crmNodeType === ENonSerializableObjectType.crmServiceConfigRoot;
        const allowedElementsToDropOnDiagram = [ENonSerializableObjectType.crmServiceConfigRoot, ENonSerializableObjectType.crmServiceConfigAgentEvent]

        const alreadyHasRootDropped = isValidRef(this.graphProcessor.getRootElement())
        if (isRoot && alreadyHasRootDropped) {
            return false
        }

        return allowedElementsToDropOnDiagram.includes(<ENonSerializableObjectType>crmNodeType)
    }

    upsertGraphNode(info: IGraphNodeContainerWithDraggedNser) {
    }

    public removeOldConnections(
        from: GraphElement,
        newPredicateType: TReferencedObject,
    ): TGraphElementActionDescriptorList {
        return []
    }

    isDraggableInto(graphElement: GraphElement, droppedType: TReferencedObject): boolean {
        return graphElement.isDraggableIntoMe(droppedType);
    }

    async refreshPosActionState(
        isRoot: boolean, nser: INonSerializable, oldNser: INonSerializable,
        updatedElement: GraphElement, batchSaver: GraphElementActionSaver, connectionType: TReferencedObject
    ): Promise<void> {
    }

    updateGraphNode(info: IGraphNodeToUpdate): GraphElement {
        return info.nodeToUpdate
    }

    async createNewGraphNode(info: INewGraphElementFromDraggedToolbarIcon): Promise<GraphElement> {
        const crmNser = info.draggedElementNS as (ICRMRootServer | ICRMAgentEventServer | ICRMNotificationServer)
        const crmElement: CrmHostNode = <CrmHostNode>HostNodeFactory.createCRM({
            hostType: crmNser.nsType,
            ns: crmNser,
        })
        const graphTypeToCreate: EGraphElementType = crmElement.isRoot()
            ? EGraphElementType.root
            : EGraphElementType.node
        const isRootAlreadyOnDiagram = isValidRef(this.graphProcessor.getRootElement())
        const isExternalElement = (crmElement.isRoot() && isRootAlreadyOnDiagram)
            || crmElement.isContentGenerator()

        const newGraphNode: GraphElement = <GraphElement>BasicElementFactory.create(graphTypeToCreate, {
            hostNser: info.draggedElementNS,
            name: info.draggedElementNS.nName,
            renderData: info.renderData,
            graphProcessor: this.graphProcessor,
            isExternalElement,
            bpmType: EBPMType.crm,
        })

        return newGraphNode
    }

    public upsertConnections(from: GraphElement, to: GraphElement, connectionData: IGraphConnectionData): TGraphElementActionDescriptorList {
        const actionSaver = GraphElementActionSaver.create()
        actionSaver.addSaveActionToElementList(from);

        const [, graphNodesToChange]: [GraphPredicate, TGraphElementActionDescriptorList] = this.updateConnections(
            from, to, connectionData)
        actionSaver.concatChangedNodes(graphNodesToChange)

        return actionSaver.getChangedNodes()
    }

    async getUpserterComponent(info: IUpserterNSComponentInfo): Promise<IUpserterComponentReturn> {
        return CrmFrontEndDialogFactory.create({
            hostType: <TBPMCRMElementTypes>info.type,
            ns: info.entityInfo.entity,
            idParent: info.entityInfo.idParentHosted
        })
    }

    async handleEditedNserFromDialogByUser(oldElement: GraphElement, editedNser: INonSerializable): Promise<void> {
        const actionSaver = GraphElementActionSaver.create()
        const editedElement: CrmHostNode = <CrmHostNode>HostNodeFactory.create({
            bpmType: EBPMType.crm,
            ns: editedNser
        })
        const graphNodesToChange = await super.handleEditedNodeNserModelUpdateGraph(oldElement, editedElement)
        actionSaver.concatChangedNodes(graphNodesToChange)

        const editedNode: GraphElement = oldElement;
        editedNode.setHostObject(editedElement)

        await this.finishTransaction(actionSaver.getChangedNodes())
    }

    async onToolbarElementDraggedOnExistingNode(info: IToolbarElementDraggedOnExistingNode) {
        const actionSaver = GraphElementActionSaver.create()
        const draggedElementNS: INonSerializable = info.dialogInfo.entityInfo.entity;

        let draggedGraphNode: GraphElement = this.getGraphRulesProcessor().findNodeElementByHostedId(draggedElementNS.idNS);
        if (!isValidRef(draggedGraphNode)) {
            draggedGraphNode = await this.createNewGraphNode({
                draggedElementNS,
                renderData: info.draggedToolbarElementRenderData,
            })
        }
        actionSaver.addSaveActionToElementList(draggedGraphNode)

        const connectionData: IGraphConnectionData = {
            connectionType: info.predicateType,
            targetHostId: draggedElementNS.idNS,
            subElementId: undefined,
        };
        connectionData.connectionType = info.predicateType
        const upsertedConnections = this.upsertConnections(info.targetNodeAlreadyInDiagram,
            draggedGraphNode, connectionData)
        actionSaver.concatChangedNodes(upsertedConnections)

        await this.finishTransaction(actionSaver.getChangedNodes())
    }

    async onToolbarElementDraggedOnDiagram(info: IUpserterNSComponentInfo): Promise<void> {
        const dialogParams: IUpserterComponentReturn = await this.getUpserterComponent(info)
        const dialogResult: IBPMDialogResult = await this.diagramEditorDialogSvc.openDialog({
            componentToOpen: dialogParams.component,
            componentHandler: dialogParams.handler,
        });

        if (dialogResult.userHasClickedSave) {
            await super.createGraphElementAndPersistToServer({
                draggedElementNS: dialogResult.nonSerializable,
                renderData: info.renderData,
            })
        }
    }

    onDraggedToolbarElementExternalOnDiagramByUser() {
        //create child nodes and connections
    }
    onDraggedToolbarElementInternalOnDiagramByUser() {
        //create child nodes and connections
    }
    onDraggedToolbarElementOnExistingNodeByUser() {
        //update child nodes and connections
    }
    onEditedNodeByUser() {
        //update child nodes and connections
    }
    onDeletedNodeByUser() {
        //remove child nodes and connections
    }
}
