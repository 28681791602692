<section class="attendance-component">
  <!-- <button (click)="switchMinimizeOnAllViews()" matRipple class="main-icon" [style.opacity]="loadingView ? '0' : '1'">
    <mat-icon class="expand-indicator" [class.rotate]="isAllViewsMinimized">expand_less</mat-icon>
  </button> -->

  <chat-options-customer [group]="getCurrentChatGroup()"></chat-options-customer>


  <div class="attendance-options-container">


    <ng-container *ngIf="servicePackNotFound">
      <app-alert type="error">Pacote de recursos de atendente não encontrado!</app-alert>
    </ng-container>

    <!-- these seem to be the default ^ -->

    <div class="attendance-actions">

      <div class="separator"></div>

      <div class="top-container">

        <button class="attendance-action-button" (click)="openActiveCall()" matRipple>
          <div class="action-icon-container">
            <mat-icon class="attendance-action-svg-icon" svgIcon="add-list"></mat-icon>
          </div>
          <p class="button-text">
            Adicionar Lead a Lista de Envio
          </p>
        </button>

        <button class="attendance-action-button" (click)="addContactToList()" matRipple>
          <div class="action-icon-container">
            <mat-icon class="attendance-action-icon" style="margin-left: 11px;">person_add_alt_1</mat-icon>
          </div>
          <p class="button-text">
            Adicionar Lead a Lista de Contato
          </p>
        </button>

        <button class="attendance-action-button" (click)="openConversationHistory()" matRipple>
          <div class="action-icon-container">
            <mat-icon class="attendance-action-icon">restore</mat-icon>
          </div>
          <p class="button-text">
            Filtro Histórico de Conversas
          </p>
        </button>

        <button class="attendance-action-button" (click)="sendCampaign()" matRipple>
          <div class="action-icon-container">
            <mat-icon class="attendance-action-icon">campaign</mat-icon>
          </div>
          <p class="button-text">
            Envio de Campanhas
          </p>
        </button>

        <button class="attendance-action-button" *ngIf="isCopilotEnabled()" (click)="openCopilotWindow()" matRipple>
          <div class="action-icon-container">
            <mat-icon class="attendance-action-icon">smart_toy</mat-icon>
          </div>
          <p class="button-text">
            Iniciar Copilot
          </p>
        </button>

      </div>

      <div class="separator"></div>

      <div class="loading-container" [visible-horizontal]="loadingView" [class.loading]="loadingView">
        <div class="loading-content">
          <mat-spinner diameter="24"></mat-spinner>
        </div>
      </div>

      <div *ngFor="let view of views" class="bottom-containers">
        <span class="action-title">
          {{view.text}}
          <mat-icon class="action-title-icon" svgIcon="writing-form"></mat-icon>
        </span>

        <ng-container
          class="attendance-action-button"
          [ngComponentOutlet]="view.target"
          [ngComponentOutletInjector]="view.injector"
        />
      </div>

      <!-- <div class="bottom-containers">

          <span class="action-title">
            Registro Atendentes
            <mat-icon class="action-title-icon">draw</mat-icon>
          </span>

          <button class="attendance-action-button">
            <div class="action-icon-container">
              <mat-icon class="attendance-action-icon">description</mat-icon>
            </div>
            <p class="button-text">
              Registro de Finalização Padrão
            </p>
          </button>

          <button class="attendance-action-button">
            <div class="action-icon-container">
              <mat-icon class="attendance-action-icon">description</mat-icon>
            </div>
            <p class="button-text">
              Registro de Finalização Padrão 2
            </p>
          </button>
           <button *ngFor="let view of views"class="attendance-action-button" (click)="handleViewClick(view)">
            <div class="action-icon-container">
              <mat-icon class="attendance-action-icon">description</mat-icon>
            </div>
            <p>
              {{ view.text }}
            </p>

            <ng-container
            [ngComponentOutlet]="view.target"
            [ngComponentOutletInjector]="view.injector"
            />
          </button>

      </div> -->

      <!-- <div class="bottom-containers">

          <span class="action-title">
            Listas Dinâmicas
            <mat-icon class="action-title-icon">draw</mat-icon>
          </span>

          <button class="attendance-action-button">
            <div class="action-icon-container">
              <mat-icon class="attendance-action-icon">description</mat-icon>
            </div>
            <p class="button-text">
              Nome lista 1
            </p>
          </button>

          <button class="attendance-action-button">
            <div class="action-icon-container">
              <mat-icon class="attendance-action-icon">description</mat-icon>
            </div>
            <p class="button-text">
              Nome lista 2
            </p>
          </button>

      </div>

    </div> -->

    <!-- <div [visible-horizontal]="!loadingView">
      <div *ngFor="let view of views" class="attendance-option">
        <div class="attendance-header" (click)="handleViewClick(view)" matRipple [class.expanded]="!view.isMinimized">
          <div class="attendance-title">
            {{ view.text }}
          </div>

          <div class="attendance-icon">
            <mat-icon class="expand-indicator" [class.rotate]="view.isMinimized">expand_less</mat-icon>
          </div>
        </div>
        <div
          class="attendance-content"
          [visible-horizontal]="!view.isMinimized"
          [style.opacity]="view.isMinimized ? 0 : 1"
        >
          <ng-container
            [ngComponentOutlet]="view.target"
            [ngComponentOutletInjector]="view.injector"
          ></ng-container>
        </div>
      </div>-->
    </div>
  </div>
</section>
