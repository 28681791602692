import { Component, Inject, OnDestroy, OnInit, Optional } from '@angular/core';
import { IServiceIslandServer } from '@colmeia/core/src/shared-business-rules/attendance-island/attendance-island';
import { ECRMCanonicalItemsTypes } from '@colmeia/core/src/shared-business-rules/crm/crm-services/crm-config-canonical-model';
import { isValidNumber, isValidRef } from '@colmeia/core/src/tools/barrel-tools';
import { MessageInstance } from 'app/components/chat/message-container/message-instance/message-instance.component';
import { ColmeiaWindowRef } from 'app/components/dashboard/dashboard-foundation/colmeia-window/colmeia-window-ref';
import { TTicketInteractionClient } from 'app/crm-service-tickets-view/tickets-view-card-dialog/ticket-interaction/ticket-interaction.component';
import { SubscriptionGroup } from 'app/model/client-utility';
import { AttendanceService } from 'app/services/attendance.service';
import { filter } from 'rxjs/operators';
import { ETicketInteractionType, ICRMTicketDataWithCustomerName } from '../../../../../core/src/shared-business-rules/crm/crm-entities';
import { CRMTicketPersistenceService } from '../crm-ticket-persistence.service';
import { CRMTicketRouterService } from '../crm-ticket-router.service';
import { TICKET_DATA } from '../crm-tickets-dialog.service';
import { CRMTicketsService } from '../crm-tickets.service';
import { UserSettingsService } from 'app/services/user-settings.service';
import { DatetimeConversions } from 'app/services/datetime-conversions';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AttendanceActiveEditHelperService } from 'app/services/attendance-active-edit-call-init.service';
import { getNoDDDPhone } from '@colmeia/core/src/shared-business-rules/social-cc/config-cell';
import { ProfileAvatarStoreService } from 'app/services/profile-avatar-store.service';
import { Avatar } from '@colmeia/core/src/business/avatar';
import { CustomerFinderComponent } from 'app/components/customer-finder/customer-finder.component';
import { INewAgentCallContactItem, ISearchActiveCallResult, TISearchActiveCallResultArray } from '@colmeia/core/src/shared-business-rules/active-1x1-call/active-1x1-model';
import { ContactListService } from 'app/components/dashboard/contact-list/contact-list.service';
import { ETypeOfContact } from '@colmeia/core/src/comm-interfaces/business-interfaces';
import { EDelivery360Action } from '@colmeia/core/src/comm-interfaces/interaction-interfaces';
import { MatTabChangeEvent } from '@angular/material/tabs';


@Component({
    selector: 'tickets-view-card-dialog',
    templateUrl: './tickets-view-card-dialog.component.html',
    styleUrls: ['./tickets-view-card-dialog.component.scss'],
    providers: [
        CRMTicketPersistenceService
    ]
})
export class TicketsViewCardDialogComponent implements OnInit, OnDestroy {
    public readonly comunicationTab = 'Comunicação';
    public readonly activityTab = 'Atividade';

    public ticketInteractions: TTicketInteractionClient[];
    public ticketCommunicationInteractions: TTicketInteractionClient[];
    public ticketInteractionsIterator: TTicketInteractionClient[];
    public island: IServiceIslandServer;
    public loading: boolean = true;
    public isWindow: boolean = false;
    public protocol: string = '';
    private subs = new SubscriptionGroup();
    public ticketFullRoute: string;
    ECRMCanonicalItemsTypes = ECRMCanonicalItemsTypes;

    public selectedInteractionType?: ETicketInteractionType[] = [];

    public interactionsTypes: Record<ETicketInteractionType, string> = {
        [ETicketInteractionType.content]: 'Conteúdo',
        [ETicketInteractionType.status]: 'Status',
        [ETicketInteractionType.allocation]: 'Alocação',
        [ETicketInteractionType.formAnswer]: 'Formulário',
        [ETicketInteractionType.queue]: 'Fila',
        [ETicketInteractionType.action]: 'Ação',
        [ETicketInteractionType.message]: 'Mensagem',
    }
    private dateTimeConversor: DatetimeConversions;

    public target: string;

    public selectedTabIndex: number = 0;

    get ticketChannels() {
        return this.ticketData.availableChannels;
    }

    get ticketId() {
        return this.ticketData.idTicket;
    }

    constructor(
        private ticketRouterSvc: CRMTicketRouterService,
        private attendanceSvc: AttendanceService,
        private crmTicketSvc: CRMTicketsService,
        @Inject(TICKET_DATA)
        public ticketData: ICRMTicketDataWithCustomerName,
        @Optional()
        private windowRef: ColmeiaWindowRef<TicketsViewCardDialogComponent>,
        private userSettingsSvc: UserSettingsService,
        private activeCallService: AttendanceActiveEditHelperService,
        private profileAvatarStoreSvc: ProfileAvatarStoreService,
        private dialog: MatDialog,
        private contactListSvc: ContactListService,
    ) {
        this.dateTimeConversor = this.userSettingsSvc.getDatetimeConversor();
        if (isValidRef(this.windowRef)) {
            this.isWindow = true;
        }
        // ticketData.channel = EDelivery360Action.Delivery360Email
        // console.log({ availableChannels: this.ticketData.availableChannels })

        // MOCKUP
        // const futureDate = new Date();
        // futureDate.setHours(futureDate.getHours() + 10);
        // const timestamp = futureDate.getTime();
        // const pastTimestamp = Date.now() - 24 * 60 * 60 * 1000;
        // console.log({futureDate, timestamp})
        // this.ticketData.availableChannels = [
        //     {
        //         channelId: 'sdFajUNjN0kkVmlhhWTTwAXW8kdT4HeZ',
        //         target: 'gtcastro7@gmail.com',
        //         openUntil: pastTimestamp
        //     },
        //     // {
        //     //     channelId: 'sdFajUNjN0kkVmlhhWTTwAXW8kdT4HeZ',
        //     //     target: 'gtcastro7@gmail.com',
        //     //     openUntil:  Date.now() - 24 * 60 * 60 * 1000
        //     // }
        // ]
        // MOCKUP
    }

    async ngOnInit() {
        const _url = new URL(document.URL);
        _url.pathname = this.ticketRouterSvc.getTicketFullPath(this.ticketData);

        this.ticketFullRoute = _url.toString();

        const pkg = await this.crmTicketSvc.getProject(this.ticketData.idPackage);

        this.island = this.attendanceSvc.getIsland(this.ticketData.idIsland);
        this.protocol = `${pkg?.projectKey}-${this.ticketData?.protocol}`;
        this.loadInfos();

        this.subs.from(this.crmTicketSvc.$ticketUpdate)
            .pipe(filter(t => t.ticketData.idTicket === this.ticketData.idTicket))
            .subscribe(({ ticketData }) => {
                this.loadInfos();
            });

        this.getTarget();
        console.log({ ticketData: this.ticketData, pkg })
    }

    ngOnDestroy(): void {
        this.subs.destroy();
    }

    isWhatsappChannel() {
        return this.ticketData.channel === EDelivery360Action.Delivery360WhatsApp;
    }

    isEmailChannel() {
        return true;
        return this.ticketData.channel === EDelivery360Action.Delivery360Email;
    }

    getDate(value: number): string {
        return isValidNumber(value) ? this.dateTimeConversor.timeToDateTimeString(value) : "--";
    }

    getUpdatedAt() {
        return this.getDate(this.ticketData.lastClockTick);
    }

    getCreatedAt() {
        return this.getDate(this.ticketData.clockTick);
    }

    setInteractionFilterType(interactionType: ETicketInteractionType = undefined, event?: MouseEvent) {
        if (!interactionType) {
            this.selectedInteractionType = [];
            return;
        }

        const idx = this.selectedInteractionType.indexOf(interactionType);

        if (event?.ctrlKey) {
            if (idx === -1) {
                this.selectedInteractionType.push(interactionType);
            } else {
                this.selectedInteractionType.splice(idx, 1);
            }
        } else {
            this.selectedInteractionType = [interactionType];
        }
    }

    private async loadInfos() {
        this.loading = true;
        await this.loadAllTicketInteractions();
        this.loading = false;
    }

    private async loadAllTicketInteractions(): Promise<void> {
        const interactions = await this.crmTicketSvc.getAllTicketInteractions(this.ticketData.idTicket);

        this.ticketInteractions = this.crmTicketSvc.parseTicketInteractionsForClient(interactions);


        this.ticketCommunicationInteractions = this.crmTicketSvc.parseEmailTicketInteractions(interactions);
        // MOCKUP
        // this.ticketCommunicationInteractions = this.crmTicketSvc.parseTicketInteractionsForClient(interactions);
        // MOCKUP


        // if (tabLabel === this.activityTab) {
        //     console.log('Whatsapp interactions')
        //     return;
        // }

        // if (tabLabel === this.comunicationTab) {
        //     console.log('Communication interactions')
        //     return;
        // }

        // console.log('DEFAULT interactions')
        // this.ticketInteractions = this.crmTicketSvc.parseTicketInteractionsForClient(interactions);

    }

    public getDisplayForInteraction(interaction: TTicketInteractionClient): boolean {
        return this.selectedInteractionType.length ? this.getDisplayForInteractionType(interaction.interactionType) : true;
    }

    public getDisplayForInteractionType(interactionType: ETicketInteractionType): boolean {
        return this.selectedInteractionType.includes(interactionType);
    }

    getIconUrl() {
        const deliveryIcon = MessageInstance.getDeliveryIcon(this.ticketData.channel);

        if (deliveryIcon) {
            return deliveryIcon;
        }
    }

    onAddComment() {
        this.loadInfos();
        this.updateLastUpdate();
    }

    // atualiza somente no client
    private updateLastUpdate() {
        const clockTick = new Date().getTime();

        // this.card.setLastUpdate(clockTick);
    }

    private async getTarget(): Promise<void> {
        const idAvatar = this.ticketData.idAvatarCustomer;
        const avatar: Avatar = await this.profileAvatarStoreSvc.getBestAvatarCached(idAvatar);
        const contact = avatar.getContacts().find(contacts => contacts.type === ETypeOfContact.mobile);
        this.target = contact?.address;
    }

    async openActiveCall() {
        this.activeCallService.openActiveCallInfo(
            {
                contactNS: {
                    nName: this.ticketData.customerName,
                    target: this.target,
                    channel: this.ticketData.channel,
                    name: this.ticketData.customerName,
                    idAvatar: this.ticketData.idAvatarCustomer,
                    email: "",
                    idSocialKey: this.ticketData.idSocialKey
                } as INewAgentCallContactItem,
                directToActiveCall: true,
            });
    }

    async addContactToSendList() {
        const dialogRef: MatDialogRef<CustomerFinderComponent> = this.dialog.open(CustomerFinderComponent, {
            panelClass: "average-size",
            minWidth: "70vw",
        });

        const noDddTarget = getNoDDDPhone(this.target!);

        dialogRef.componentInstance.handler = {
            target: noDddTarget,
            allowedSearchSources: {
                standard: {},
                infoSquare: {},
            },
            onResult: (result: TISearchActiveCallResultArray) => {
                if (result.length === 1) {
                    const contact = result[0];
                    this.contactListSvc.addContactToSendLists({
                        name: contact.name,
                        target: contact.target,
                        idAvatar: contact.idAvatar,
                        channel: contact.channel,
                    });

                    dialogRef.close();

                } else if (dialogRef.componentInstance.target === noDddTarget) {
                    const customer = result.find(row => row.target === this.target);
                    if (!customer) return;
                    this.contactListSvc.addContactToSendLists(customer);
                }

            },
            onCustomerSelected: async ([contact]: ISearchActiveCallResult[]) => {
                if (!contact) return;

                this.contactListSvc.addContactToSendLists(contact);

                dialogRef.close();
            }
        };
        // dialogRef.componentInstance.onRowClick(this.selectedCustomer);
    }

    public async addContactToList() {
        const dialogRef: MatDialogRef<CustomerFinderComponent> = this.dialog.open(CustomerFinderComponent, {
            panelClass: "average-size",
            minWidth: "70vw"
        });

        const noDddTarget = getNoDDDPhone(this.target!);

        dialogRef.componentInstance.handler = {
            target: noDddTarget,
            allowedSearchSources: {
                standard: {},
                infoSquare: {},
            },
            onResult: (result: TISearchActiveCallResultArray) => {
                if (result.length === 1) {
                    const contact = result[0];
                    this.contactListSvc.addContactToLists(
                        contact.name,
                        {
                            target: contact.target,
                            idAvatar: contact.idAvatar,
                            idSocialKey: contact.idSocialKey,
                            channel: contact.channel,
                        }
                    );

                    dialogRef.close();

                } else if (dialogRef.componentInstance.target === noDddTarget) {
                    const customer = result.find(row => row.target === this.target);
                    if (!customer) return;
                    this.contactListSvc.addContactToLists(
                        customer.name,
                        {
                            target: customer.target,
                            idAvatar: customer.idAvatar,
                            idSocialKey: customer.idSocialKey,
                            channel: customer.channel,
                        }
                    );
                }

            },
            onCustomerSelected: async ([contact]: ISearchActiveCallResult[]) => {
                if (!contact) return;

                this.contactListSvc.addContactToLists(
                    contact.name,
                    {
                        target: contact.target,
                        idAvatar: contact.idAvatar,
                        idSocialKey: contact.idSocialKey,
                        channel: contact.channel,
                    }
                );

                dialogRef.close();
            }
        };
    }

    onTabChange(event: MatTabChangeEvent) {
    }
}
