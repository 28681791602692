<section class="interaction-type-filter">
  <section
    class="no-interactions"
    *ngIf="interactionTypes?.length < 1; else showInteractions"
  >
    Não há tipos de mensagens disponíveis para o filtro
  </section>

  <ng-template #showInteractions>
    <section class="available-interactions">
      <div class="interaction" *ngFor="let item of interactionTypes">
        <text-icon-white-box
          [title]="item.name"
          [url]="item.idMedia"
          (click)="toggleInteraction(item)"
          [backgroundColor]="
            selectedInteractions.includes(item.primaryID)
              ? 'blue'
              : 'white'
          "
        ></text-icon-white-box>
      </div>
    </section>
  </ng-template>
</section>
