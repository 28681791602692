import { fieldMarkLiveInfo, NamedNumber } from "../core-constants/types";
import { IInterval, TIntervalArray } from "../shared-business-rules/attendance-island/ns-sheduler";
import { EImplementAccessModifier, getClock, isInvalid, isThisOneOfThat, padLeft, padRight, round } from "../tools/utility";
import { IsEqual, ReMapObject } from "../tools/utility-types";
import { $$ } from "../tools/utility/types/error";
import { Interval, TArrayInterval } from "./interval";
import { Implement } from "@colmeia/core/src/tools/utility/functions/Implement";


export const daysInMS = (days: Days): Miliseconds => days * oneDayMS;
export const daysInSeconds = (days: Days): Seconds => days * oneDaySeconds;
export const yearsInMS = (years: Years): Miliseconds => years * daysInMS(365);
export const oneDayH: Hours = 24;
export const oneDayMS: Miliseconds = oneDayH * 60 * 60 * 1000;
export const oneDaySeconds: Seconds = 24 * 60 * 60;
export const oneWeekMS: Miliseconds = oneDayMS * 7;
export const oneMinuteMS: Miliseconds = 1000 * 60;
export const farAwayMS: Miliseconds = getClock() + oneDayMS * 365 * 100; // 100 anos a frente
export const midnightMS: Miliseconds = oneDayH * 60 * 60 * 1000;
export const oneHourMS: Miliseconds = 60 * 60 * 1000;



export type MicroSeconds = NamedNumber<'MicroSeconds'>;
export type Miliseconds = NamedNumber<'Miliseconds'>;
export type MS = Miliseconds;
export type Seconds = NamedNumber<'Seconds'>;
export type Minutes = NamedNumber<'Minutes'>;
export type Hours = NamedNumber<'Hours'>;
export type Days = NamedNumber<'Days'>;
export type Years = NamedNumber<'Years'>;

export type ISODate = `${string}-${string}-${string}T${string}:${string}:${string}Z`;

export const hoursToSeconds = (hours: number): Seconds => hours * 60 * 60;
export const daysToSeconds = (days: Days): Seconds => days * oneDayH * 60 * 60;
export const daysToHours = (days: Days): Hours => days * oneDayH;
export function yearToMS(years: Years): Miliseconds { return daysToMS(365 * years); };
export function daysToMS(days: Days): Miliseconds { return secToMS(daysToSeconds(days)); };
export function hourToMS(hour: Hours): Miliseconds { return hour * oneHourMS; };
export function secToMS(seconds: Seconds): Miliseconds { return seconds * 1000; };
export function minToMS(minutes: Minutes): Miliseconds { return minutes * oneMinuteMS; };
export function minToS(minutes: Minutes): Seconds { return minutes * 60; };
export function minToHour(minutes: Minutes): Hours { return minutes / 60; };
export function ceilMStoS(ms: Miliseconds): Seconds { return Math.ceil(ms / 1000); };

export function msToSec(ms: Miliseconds): Seconds { return ms / 1000 };

export function milisecondsToHour(ms: Miliseconds): Hours { return Math.round(ms / 1000 / 60 / 60); };

export function msToMc(ms: Miliseconds): MicroSeconds {
    return ms * 1000;
}
export function mcToMs(microSeconds: MicroSeconds): Miliseconds {
    return microSeconds / 1000;
}

export const msToMin = (ms: Miliseconds): Minutes => ms / oneMinuteMS;
export const roundedMsToMin = (ms: Miliseconds): Minutes => round(msToMin(ms), 0)
export const msToHour = (ms: Miliseconds): Hours => ms / (oneMinuteMS * 60)
export const msToDay = (ms: Miliseconds): Days => ms / (oneMinuteMS * 60 * oneDayH)


export function getCoundown(now: number, time: number, useLetterSuffix: boolean = false, suffix: string = ':'): string {
    const delta = time - now;

    if (delta < 0) return '';

    const days = Math.trunc(delta / daysInMS(1));
    const hours = Math.trunc(delta / hourToMS(1) % 24);
    const minutes = Math.trunc(delta / minToMS(1) % 60);
    const seconds = Math.trunc(delta / secToMS(1) % 60);

    const daySuffix = useLetterSuffix ? "d " : suffix;
    const hourSuffix = useLetterSuffix ? "h " : suffix;
    const minuteSuffix = useLetterSuffix ? "m " : suffix;
    const secondsSuffix = useLetterSuffix ? "s" : '';

    const countdown = (days === 0 ? '' : days.toString() + daySuffix) + (hours === 0 ? '' : hours.toString() + hourSuffix) +
        padLeft(minutes, 2, '0') + minuteSuffix + padLeft(seconds, 2, '0') + secondsSuffix

    return countdown;
}


export function formatDateMonthYear(today: Date): string {
    let dd: string | number = today.getDate();
    let mm: string | number = today.getMonth() + 1; //January is 0!
    const yyyy = today.getFullYear();

    if (dd < 10) {
        dd = '0' + dd;
    }

    if (mm < 10) {
        mm = '0' + mm;
    }

    return dd + '/' + mm + '/' + yyyy;
}

export function clockToYYYYMM(clock: number): string {
    const vdate = new Date(clock);
    let mm: string | number = vdate.getMonth() + 1; //January is 0!
    const yyyy = vdate.getFullYear().toString();



    if (mm < 10) {
        mm = '0' + mm;
    }

    return yyyy + mm;
}

//20/02/2019 - 8:55
export function dateToFullTime(d: Date): string {
    return padLeft(d.getDate(), 2) + "/" + padLeft(d.getMonth() + 1, 2) + "/" + d.getFullYear().toString().substr(-2)
        + ' ' + d.getHours() + ':' + padLeft(d.getMinutes(), 2)
};


export function dateToDateTimeUTC(d: UTCDate) {
    return padLeft(d.getUTCDate(), 2) + "/" + padLeft(d.getUTCMonth() + 1, 2) + "/" + d.getUTCFullYear().toString().substr(-2)
}

export function dateToHoursUTC(date: UTCDate) {
    return date.getUTCHours() + ':' + padLeft(date.getUTCMinutes(), 2)
}
export function dateToFullTimeUTC(d: Date): string
export function dateToFullTimeUTC(d: UTCDate): string {
    return dateToDateTimeUTC(d) + ' ' + dateToHoursUTC(d)
};

export function clockToFullTime(d: number): string {
    return dateToFullTime(new Date(d));
};


export function intervalContainsInterval(outside: Interval, inside: Interval): boolean {
    return inside.getFromMS() >= outside.getFromMS() && inside.getToMS() <= outside.getToMS();

}

export function intervalsContainInterval(planned: TArrayInterval, candidate: Interval): boolean {
    return planned.some((p) => { return p.contains(candidate) })
};


export function intervalsIntersectInterval(range: TArrayInterval, interval: Interval): boolean {
    return range.some((i) => { return i.hasIntersectionWith(interval) })
};

export function intervalIntersectsInterval(planned: Interval, candidate: Interval): boolean {
    return intervalUPIntersectsInterval(planned, candidate) || intervalDOWNIntersectionInterval(planned, candidate);
};


// Candidate UP in relation to Planned
export function intervalUPIntersectsInterval(planned: Interval, candidate: Interval): boolean {
    return planned.getFromMS() >= candidate.getFromMS() && planned.getFromMS() <= candidate.getToMS();
}

export function intervalDOWNIntersectionInterval(planned: Interval, candidate: Interval): boolean {
    return planned.getToMS() >= candidate.getFromMS() && planned.getToMS() <= candidate.getToMS();
};

export function isInBetweenHours(time: number, begin: number, end: number): boolean {
    const hour: number = new Date(time).getHours();
    return hour >= begin && hour <= end;
}



export function datesToInterval(begin: number, end: number): Interval {
    return Interval.getNewInterval(begin, end);
}


// elements that are in A but not in B
export function intervalSubstractionInterval(planned: Interval, allocated: Interval): TArrayInterval {
    let interval: TArrayInterval = [];
    if (planned.contains(allocated)) {  // atenção!! lógica só funciona se passar por checagem de contém antes
        interval.push(Interval.getNewInterval(planned.getFromMS(), allocated.getFromMS()));
        interval.push(Interval.getNewInterval(allocated.getToMS(), planned.getToMS()));

    } else if (intervalUPIntersectsInterval(planned, allocated)) {
        if (allocated.getToMS() < planned.getToMS()) {  // ver se tem substraction
            interval.push(Interval.getNewInterval(allocated.getToMS(), planned.getToMS()));
        };
    } else if (intervalDOWNIntersectionInterval(planned, allocated)) {
        if (allocated.getFromMS() > planned.getFromMS()) {  // ver se tem substraction
            interval.push(Interval.getNewInterval(planned.getFromMS(), allocated.getFromMS()));
        };
    };
    return interval;
};

export function isConfirmationTimeIntervalExpired(timestampToCheck: number, maxConfirmationIDTime: number): boolean {
    const timeInterval: number = getClock() - timestampToCheck
    const isTimeToConfirmLoginExpired: boolean = timeInterval > maxConfirmationIDTime
    return isTimeToConfirmLoginExpired
}

export function getSubstractedAllocationArray(plannedArray: TArrayInterval, allocation: TArrayInterval, duration: number = 0): TArrayInterval {
    let viable: TArrayInterval = [];
    let substraction: TArrayInterval;
    for (let planned of plannedArray) {
        for (let candidate of allocation) {
            substraction = intervalSubstractionInterval(planned, candidate);
            for (let interval of substraction) {
                if (interval.isDurationOk(duration))
                    viable.push(interval)
            };
        };
    };
    return viable;
};


export function getSubstractedAllocation(plannedArray: TArrayInterval, allocation: TArrayInterval, duration: number = 0): Interval {
    let substraction: TArrayInterval;
    for (let planned of plannedArray) {
        for (let candidate of allocation) {
            substraction = intervalSubstractionInterval(planned, candidate);
            for (let interval of substraction) {
                if (interval.isDurationOk(duration))
                    return interval;
            };
        };
    };
    return null;
};

export function isGenericDaysRecencyOk(lastVisit: number, minimumRecencyMS: number, nowMS: number = getClock()): boolean {
    return isInvalid(lastVisit) || isInvalid(minimumRecencyMS) || (nowMS > lastVisit + daysInMS(minimumRecencyMS))
}


export function isGenericMSRecencyOk(lastVisit: number, minimumRecencyMS: number, nowMS: number = getClock()): boolean {
    const ok: boolean = isInvalid(lastVisit) || isInvalid(minimumRecencyMS) || (nowMS > lastVisit + minimumRecencyMS);
    return ok;
}



export function intervalIntersectionInterval(planned: Interval, candidate: Interval): Interval {
    let interval: Interval;
    if (planned.contains(candidate)) {  // atenção!! lógica só funciona se passar por checagem de contém antes
        interval = candidate;
    } else if (intervalUPIntersectsInterval(planned, candidate)) {
        interval = Interval.getNewInterval(planned.getFromMS(), candidate.getToMS());
        interval.setPriority(candidate.getPriority());
    } else if (intervalDOWNIntersectionInterval(planned, candidate)) {
        interval = Interval.getNewInterval(candidate.getFromMS(), planned.getToMS())
        interval.setPriority(candidate.getPriority());
    };
    return interval;
};

export function getIntersectionsWithDuration(plannedArray: TArrayInterval, candidateArray: TArrayInterval, duration: number = 0): TArrayInterval {
    let viable: TArrayInterval = [];
    let intersection: Interval;
    for (let planned of plannedArray) {
        for (let candidate of candidateArray) {
            intersection = intervalIntersectionInterval(planned, candidate);
            if (intersection && intersection.isDurationOk(duration)) {
                viable.push(intersection);
            };
        };
    };
    return viable;
};


// Reverse Mirror
export function getComplement(toBeMirroed: TArrayInterval, beginMS: number = 0): TArrayInterval {
    let gap: TArrayInterval = [];

    let last: number = beginMS;
    let interval: Interval;

    while (last < midnightMS) {
        interval = toBeMirroed.find((i) => { return i.getFromMS() >= last });
        if (!interval) {
            gap.push(Interval.getNewInterval(last, midnightMS));
            last = midnightMS;

        } else if (interval.getFromMS() == last) {
            last = interval.getToMS() + 1;

        } else if (interval.getFromMS() > last) {
            gap.push(Interval.getNewInterval(last + 1, interval.getFromMS() - 1));
            last = interval.getToMS() + 1;
        }
    }
    return gap;
};



export function extractUnions(plannedAllocation: TArrayInterval, alreadyAllocated: TArrayInterval): TArrayInterval {
    let rest: TArrayInterval = [];
    let last: number;
    let match: number = 0;


    // All the allreadyAllocation must be inside planned or it will throw an error
    for (let planned of plannedAllocation) {
        for (let allocated of alreadyAllocated) {
            if (allocated.contains(planned)) {
                if (planned.getFromMS() > allocated.getFromMS()) {
                    rest.push(Interval.getNewInterval(planned.getFromMS(), allocated.getFromMS() - 1));
                };
                if (planned.getToMS() > allocated.getToMS()) {
                    rest.push(Interval.getNewInterval(allocated.getToMS() + 1, planned.getToMS()));
                }
                ++match;
            };
        };
    };
    return match != alreadyAllocated.length ? [] : rest;
};


export function intervalContainDuaration(interval: Interval, duration: number): boolean {
    return interval.getIntervalDuration() >= duration;
};

export function intervalsContainsDuration(range: TArrayInterval, duration: number): boolean {
    return range.some((i) => { return intervalContainDuaration(i, duration) });
};

export function to0AM(date: Miliseconds): Miliseconds {
    const d: Date = new Date(date);
    d.setUTCHours(0, 0, 0, 0);
    return d.getTime();
};



type TUTCDateKeys =
    Extract<keyof Date, `${string}UTC${string}`> extends infer Key ?
    Key extends `${infer Prefix}UTC${infer Suffix}` ?
    `${Prefix}${Suffix}`
    : never
    : never
    ;
type MakeUTCDate<Keys extends keyof Date> = { [key in Keys]: IsEqual<UTCDate[key], never> extends false ? $$<'Should be never'> : never }
type TUTCDate = MakeUTCDate<Exclude<TUTCDateKeys, 'toString'>>;

@Implement<TUTCDate, {
    [key in keyof TUTCDate]: {
        isStatic: false;
        modifier: EImplementAccessModifier.public;
    }
}>()
export class UTCDate extends Date {
    getFullYear: never;
    getMonth: never;
    getDate: never;
    getDay: never;
    getHours: never;
    getMinutes: never;
    getSeconds: never;
    getMilliseconds: never;
    setMilliseconds: never;
    setSeconds: never;
    setMinutes: never;
    setHours: never;
    setDate: never;
    setMonth: never;
    setFullYear: never;
}


export function to0AMwithTimezone(date: number, timezone: number): number {
    return 0;
}

export function getDateTimeZoneFixed(date: Date, timezone: number): Date {
    const expireDateTimezoneAdjusted = new Date(date.getTime() + (60000 * (date.getTimezoneOffset() - timezone)))
    return expireDateTimezoneAdjusted
}

export function getHoursOrMinutesFormated(hoursOrMinutes: number): string {
    return hoursOrMinutes < 10
        ? '0' + hoursOrMinutes
        : hoursOrMinutes.toString()
}


export function compactClockTick(n: number): string {
    let str: string = n.toString();
    let ascField: string = '';
    while (str.length > 0) {
        let offset: number = str.length > 2 ? 3 : str.length;
        if (str[0] == '0') {
            offset = 1;
        } else if (parseInt(str.substr(0, offset)) > 254) {
            offset = 2;
        };
        if (offset == 1 && onlyZeros(str)) {
            str = '';
        } else {
            ascField += String.fromCharCode(parseInt(str.substr(0, offset)));
            str = str.substring(offset)
        };
    };
    return ascField;
};

export function toCompactedLiveInfoField(clockTick: number): string {
    return fieldMarkLiveInfo + compactClockTick(clockTick);
}

export function toClockTick(compacted: string): number {
    let newNField: string = '';
    for (const char of compacted) {  // tira o marcador
        newNField += char.charCodeAt(0).toString();
    };
    return parseInt(padRight(newNField, 13));
};

export function uncompactLiveInfoField(compacted: string): number {
    return toClockTick(compacted.substring(1))
};


export function onlyZeros(str: string): boolean {
    let onlyZeros: boolean = true;
    let k: number = 0;
    while (onlyZeros && k < str.length) {
        onlyZeros = str[k] == '0';
        ++k;
    };
    return onlyZeros;
};


export type TDateMaskElement = Array<string | RegExp>;

export function getDateFormatMask(format: string): TDateMaskElement {
    return format.split('').map(
        char => {
            return isThisOneOfThat(char, '/', '-', ':')
                ? char
                : /\d/;
        }
    );
};


export type TDateTransform = (clockTick: number, format: string) => string;

export function clockTickToDate(clock: number, dateFormat: string, hourFormat: string, transformFunction: TDateTransform): string {
    const format = `${dateFormat} ${hourFormat}`;
    return transformFunction(clock, format);
};



export function isInInterval(now: number, interval: IInterval): boolean {
    const abs = toAbsoluteHour(now);
    return abs <= interval.begin && abs >= interval.end;
}

export function isInsideAnyInterval(now: number, intervals: TIntervalArray): boolean {
    return intervals.some(interval => isInInterval(now, interval));
}

export function getOverlappedInterval(toCheck: IInterval, intervals: TIntervalArray): IInterval | undefined {
    return intervals.find(interval => {
        return toCheck.begin >= interval.begin && toCheck.begin <= interval.end
            || toCheck.end >= interval.begin && toCheck.end <= interval.end;
    });
}

export function getSystemUTCOffsetInMS(): number {
    const obj = new Date();
    return (obj.getTimezoneOffset()) * 60 * 1000; // Note that this means that the offset is positive if the local timezone is behind UTC, and negative if it is ahead. For example, for time zone UTC+10:00 (Australian Eastern Standard Time,
}

export function getWeekNumber(date: Date): number {
    const onejan = new Date(date.getFullYear(), 0, 1);
    const week = Math.ceil((((date.getTime() - onejan.getTime()) / 86400000) + onejan.getDay() + 1) / 7);
    return week
}



export function getWeekDay(): number {
    return (new Date()).getUTCDay();
}

// export function get0DateObject(): Date {
//     return new Date(0);
// }

export function toAbsoluteHour(time: number): number {
    const originalOBJ = new Date(time);
    return hourToMS(originalOBJ.getHours()) + minToMS(originalOBJ.getMinutes()) + secToMS(originalOBJ.getSeconds());
}

export function toAbsoluteUTCHour(time: number): number {
    const originalOBJ = new Date(time);
    return hourToMS(originalOBJ.getUTCHours()) + minToMS(originalOBJ.getUTCMinutes()) + secToMS(originalOBJ.getUTCSeconds());
}

export type TMonthDaysHoursMinutesSeconds = {
    seconds: number,
    minutes: number,
    hours: number,
    days: number,
    months: number
}

export function getDaysHoursMinutesSeconds(time: number): TMonthDaysHoursMinutesSeconds {
    const seconds = Math.floor(time / secToMS(1)) % 60;

    const auxMin = Math.floor((time - secToMS(seconds)) / secToMS(60));
    const minutes = auxMin % 60;

    const hrAux = Math.floor((time - minToMS(minutes)) / hourToMS(1));
    const hours = hrAux % 24;

    const dayAux = Math.floor((time - hourToMS(hours)) / hourToMS(24));
    const days = dayAux % 30;

    const months = Math.floor((time - daysInMS(days)) / daysInMS(30));

    return {
        seconds,
        minutes,
        hours,
        days,
        months
    };
}


export function getStringDate(time: Date | number): string {
    const date: Date = time instanceof Date ? time : new Date(time);
    const day = `${date.getDate()}`.padStart(2, '0');
    const month = `${date.getMonth() + 1}`.padStart(2, '0');
    const year = `${date.getFullYear()}`;
    return `${day}/${month}/${year}`;
}

export function getStringHoursMinutesSeconds(time: Date | number): string {
    const date: Date = time instanceof Date ? time : new Date(time);
    const hour = `${date.getHours()}`.padStart(2, '0');
    const minute = `${date.getMinutes()}`.padStart(2, '0');
    const seconds = `${date.getSeconds()}`.padStart(2, '0');

    return `${hour}:${minute}:${seconds}`;
}

export function getStringDateWithTime(time: Date | number): string {
    return `${getStringDate(time)} ${getStringHoursMinutesSeconds(time)}`
}

export function today0HourUTC(): number {
    const obj = getClock();
    return to0AM(obj);
}

// export const defaultTimezoneName: string = 'America/Brasília';
export const defaultTimezoneName: string = 'America/Sao_Paulo';

export const allTimezones: ITimezone[] = [
    {
        name: defaultTimezoneName,
        offset: -3
    },
    {
        name: 'America/Noronha',
        offset: -2
    },
    {
        name: 'America/Manaus',
        offset: -4
    },
    {
        name: 'America/Caracas',
        offset: -4.5 //<< Venezuela Standard Time (UTC-4:30)
    },
    {
        name: 'America/Bogota',
        offset: -5
    },
    {
        name: 'America/Rio_Branco',
        offset: -5
    },
    {
        name: 'Pacific/Easter',
        offset: -6
    }
];

export const allTimezonesDescMap: Map<string, string> = new Map<string, string>([
    [defaultTimezoneName, 'Horário de Brasília'],
    ['America/Noronha', 'Horário de Fernando de Noronha'],
    ['America/Manaus', 'Horário do Amazonas'],
    ['America/Caracas', 'Horário de Caracas'],
    ['America/Bogota', 'Horário de Bogota'],
    ['America/Rio_Branco', 'Horário do Acre'],
    ['Pacific/Easter', 'Horário do Easter/Pacífico']
]);

export const timeZoneByOffset = allTimezones.reduce((acc, item) => {
    acc[item.offset] = item.name;
    return acc;
}, {});


export function isNowInsideInterval({ offset, begin: startTime, end: endTime }: IInterval, now?: number): boolean {
    const time: number = getNowIntervalTime(offset, now);
    if (!(startTime <= endTime)) {
        return false;
    }
    return time >= startTime && time <= endTime;
}

export function getUTCDifference(gmt: number) {
    return (gmt * oneHourMS);
}

export function getNowIntervalTime(gmt: number, now: number = getClock()): number {
    return ((now) % (oneDayMS)) + (gmt * oneHourMS);
}

export function get0AMCurrentDay(): number {
    return to0AM(getClock());
}


export function getTimeFromClock(clockTick: number): Date {
    return new Date(clockTick)
}

export function getTimeFromIntervalHourSince12(timeMS: number): Date {
    return getTimeFromClock(to0AM(getClock()) + timeMS)
}

export function getWeekDayFromUTC(time: number): number {
    const instance = new Date(time);
    instance.setUTCDate(instance.getUTCDate());
    instance.setUTCHours(0, 0, 0, 0);
    return instance.getUTCDay();
}

export interface ITimezone {
    name: string;
    offset: number;
}

export function getTimestampOfTimeString(input: string): number {
    const [hour, minutes]: number[] = input.split(':').map(v => parseInt(v));
    const now = new Date();

    return new Date(now.getFullYear(), now.getMonth(), now.getDay(), hour, minutes).getTime();
}

export function resetTimestampSeconds(time: number): number {
    const obj = new Date(time);
    obj.setSeconds(0);
    return toAbsoluteHour(obj.getTime());
}

export function findRepeatingWords(strs: string[]) {
    const subStrs = [];
    const wordCounts: Record<string, number> = {};
  
    // Find all substrings and their counts
    for (const str of strs) {
      const words = str.split(' ');
      for (const word of words) {
        if (!wordCounts[word]) {
          wordCounts[word] = 0;
        }
        wordCounts[word]++;
      }
    }
  
    // Filter substrings that appear in all strings
    for (const word in wordCounts) {
      if (wordCounts[word] === strs.length) {
        subStrs.push(word);
      }
    }
  
    return subStrs;
  }

export function getRelativeDateSpelled(
  valDate: Date | number | string,
  config?: {
    locale?: any;
    localeMatcher?: Intl.RelativeTimeFormatOptions["localeMatcher"];
    numeric?: Intl.RelativeTimeFormatOptions["numeric"];
    style?: Intl.RelativeTimeFormatOptions["style"];
    maxParts?: number;
    joinPartsStr?: string;
    joinLastPartsStr?: string;
    asObject?: boolean;
  }
): string {
  const objDate = new Date(valDate);
  const asObject = config?.asObject === true;
  if (valDate == null || isNaN(+valDate)) {
    return (asObject ? { valDate, config, objDate } : "") as string;
  }

  const locale = config?.locale ?? <any>navigator.languages;
  const localeMatcher = config?.localeMatcher ?? "best fit";
  const numeric = config?.numeric ?? "always";
  const style = config?.style ?? "long";
  const maxParts = config?.maxParts ?? 3;
  const joinPartsStr = config?.joinPartsStr ?? ", ";
  const joinLastPartsStr = config?.joinLastPartsStr ?? " e ";

  const parts = [
    { val: 31536000, unit: "years" },
    { val: 2592000, unit: "months" },
    { val: 604800, unit: "weeks" },
    { val: 86400, unit: "days" },
    { val: 3600, unit: "hours" },
    { val: 60, unit: "minutes" },
    { val: 1, unit: "seconds" },
  ];

  const formatter = new Intl.RelativeTimeFormat(locale, {
    localeMatcher,
    numeric,
    style,
  });

  const deltaSecs = Math.round((objDate.getTime() - Date.now()) / 1000);
  // console.log({ deltaSecs });

  const divParts: any = parts.reduce(
    (prev, { val, unit }) => {
      // console.log({ ...prev, val, unit })
      return val > Math.abs(prev.seconds)
        ? prev
        : {
            segments: [
              ...prev.segments,
              { val: Math.round(prev.seconds / val), unit },
            ],
            seconds: prev.seconds % val,
          };
    },
    { segments: [], seconds: deltaSecs } as any
  );
  // console.log({ divParts });

  const formattedParts = (<any>(
    divParts.segments.map((seg: { val: number; unit: string }) =>
      formatter.formatToParts(seg.val, seg.unit as any)
    )
  )) as Array<Intl.RelativeTimeFormatPart[]>;

  let timeParts = formattedParts.map((seg) =>
    seg.map((part) => part.value).join("")
  );

  const commonWords = findRepeatingWords(timeParts);
  if (timeParts.length > 1) {
    timeParts = timeParts.map((part) =>
        commonWords.reduce(
        (strPart, word) => strPart.replace(new RegExp(word, "gi"), "").trim(),
        part
        )
    );
  }
  // console.log('commonWords :>> ', commonWords);
  // console.log('timeParts :>> ', timeParts);

  const timePartsSl = timeParts.slice(0, maxParts);
  const result = [
    ...timePartsSl.slice(0, -2),
    timePartsSl.slice(-2).join(joinLastPartsStr),
  ].join(joinPartsStr);

  const allObjects = {
    valDate,
    config,
    objDate,
    parts,
    formatter,
    deltaSecs,
    divParts,
    formattedParts,
    commonWords,
    timeParts,
    result,
  };
//   console.log("getRelativeDateSpelled :>> ", allObjects);

  if (asObject) {
    return <any>allObjects;
  }
  return result;
}