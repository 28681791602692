import {
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
} from '@angular/core';
import { SignalListenerService } from "../../../services/signal/signal-listener";
import { GroupOrderSelectorShortcutHandler, IGroupOrderSelectorShortcutHandlerParameter, groupOrderSelectorModeDB, defaultGroupOrderSelectorModeConfig, EGroupOrderSelectorModeOption } from 'app/handlers/group-order-and-search.handler';
import { GroupShortcutHandlerService } from 'app/services/group-shortcut-handler.service';
import { EnumPickerHandler, EEnumPickerMode, IEnumPickerClientCallback } from 'app/components/foundation/enum-picker/enum-picker.handler';
import { typedClone, isValidFunction, values } from '@colmeia/core/src/tools/utility';
import { EGroupOrderSelectorMode } from '@colmeia/core/src/shared-business-rules/const-text/views/group-order-and-search';
import { gTranslations } from '@colmeia/core/src/shared-business-rules/const-text/translations';
import { pickTranslations } from '@colmeia/core/src/shared-business-rules/const-text/all-serializables';
import { RootComponent } from 'app/components/foundation/root/root.component'

@Component({
    selector: 'app-group-order-and-search',
    templateUrl: './group-order-and-search.component.html',
    styleUrls: ['./group-order-and-search.component.scss'],
})
export class GroupOrderAndSearchComponent extends RootComponent<EGroupOrderSelectorMode> implements OnInit, OnDestroy {
    private _handler: GroupOrderSelectorShortcutHandler;
    @Input()
    public set handler (val: GroupOrderSelectorShortcutHandler) { this._handler = val; this.init(); }
    public get handler(): GroupOrderSelectorShortcutHandler { return this._handler; }
    public get parameters(): IGroupOrderSelectorShortcutHandlerParameter { return this.handler.getComponentParameter() }

    state: 'order' | 'search' = 'order';

    @Input()
    public searchToken: string;
    @Output()
    public searchTokenChange = new EventEmitter<string>();

    @Output()
    public onSearch = new EventEmitter<void>();

    public enumPickerHandler: EnumPickerHandler<EGroupOrderSelectorMode>;

    EGroupOrderSelectorMode = EGroupOrderSelectorMode;

    constructor (
        private listener: SignalListenerService,
        private shortcutSVC: GroupShortcutHandlerService,
    ) {
        super({
            ...pickTranslations(gTranslations.groupOrderSelectorShortcurt, [
                ...values(EGroupOrderSelectorMode)
            ])
        });
    }

    public ngOnInit(): void {
        this.handler = this.shortcutSVC.getGroupOrderShortCurtHandler();
    }

    public init(): void {
        this.handler.setSlave(this);
        this.initEnumPickerHandler();
        this.resetConfig();
    };

    public onSelectOrder(value: EGroupOrderSelectorMode): void {
        this.parameters.selected = value;
        this.resetConfig();
        if (isValidFunction(this.parameters.clientCallback?.onGroupOrderSelectorChange))
            this.parameters.clientCallback?.onGroupOrderSelectorChange()
        ;
    }

    public $configOptions = values(EGroupOrderSelectorModeOption);

    public get config() {
        return this.parameters.config;
    }

    public showConfigOption(option: EGroupOrderSelectorModeOption): boolean {
        return groupOrderSelectorModeDB[this.parameters.selected][option];
    }

    public resetConfig(): void {
        this.parameters.config = typedClone(defaultGroupOrderSelectorModeConfig[this.parameters.selected]);
    }

    public getOptionIcon(option: EGroupOrderSelectorModeOption) {
        switch (option) {
            case EGroupOrderSelectorModeOption.recent: return 'filter_list';
            case EGroupOrderSelectorModeOption.agent: return this.parameters.config[option] ? 'support_agent' : 'groups';
        }
    }

    public applyRotateOnIcon(option: EGroupOrderSelectorModeOption): boolean {
        return option === EGroupOrderSelectorModeOption.recent && !this.config.recent;
    }

    public initEnumPickerHandler(): void {
        this.enumPickerHandler = new EnumPickerHandler({
            client: {
                onSingleEnumSelection: value => this.onSelectOrder(value),
            } as IEnumPickerClientCallback<EGroupOrderSelectorMode>,
            mode: EEnumPickerMode.Single,
            appearance: 'outline',
            translations: gTranslations.groupOrderSelectorShortcurt,
            inputTitle: 'Ordernar por',
            enum: EGroupOrderSelectorMode,
            clientCallback: null,
            current: this.parameters.selected,
            buttonMode: true
        })
    }

    public onClickOption(option: EGroupOrderSelectorModeOption): void {
        switch (option) {
            case EGroupOrderSelectorModeOption.recent: {
                this.parameters.config[option] = !this.parameters.config[option];
            };
            break;
            case EGroupOrderSelectorModeOption.agent: {
                this.parameters.config[option] = !this.parameters.config[option];
            };
            break;
        }
        if (isValidFunction(this.parameters.clientCallback?.onGroupOrderSelectorChange))
            this.parameters.clientCallback?.onGroupOrderSelectorChange()
        ;
    }

    public ngOnDestroy(): void {
        this.listener.destroySubscriptions(this);
    };

    public emitSearch() {
        this.searchTokenChange.emit(this.searchToken);

        this.onSearch.emit();
    }

    public toggleSearch() {
        this.state = this.state === 'search' ? 'order' : 'search';

        this.searchToken = '';
        this.searchTokenChange.emit(this.searchToken);
    }
};
