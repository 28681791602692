import {TFormSchemmaArray} from "@colmeia/core/src/general-form/general-form-interface";
import { IFormSchemmaAndEngagement } from "app/model/chat-options.model";





export class ClientRegistrationHandler {

    constructor(private metadatas: IFormSchemmaAndEngagement[]) {}

	getMetadatas(): IFormSchemmaAndEngagement[] {
		return this.metadatas;
	}

}
