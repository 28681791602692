import { Injectable, ViewContainerRef } from "@angular/core";
import {
    MatDialog,
    MatDialogRef
} from "@angular/material/dialog";
import {
    EDelivery360Action,
    TCheckedContactArray
} from "@colmeia/core/src/comm-interfaces/barrel-comm-interfaces";
import { TArrayID } from "@colmeia/core/src/core-constants/types";
import {
    INewAgentCallContactItem,
    INewAgentCallContactServer,
    IPreDefinedCampaignMessage,
    ISearchActiveCallResult
} from "@colmeia/core/src/shared-business-rules/active-1x1-call/active-1x1-model";
import { IGetActiveCustomerStatusResponse, IRunActionCampaignForActiveCall } from "@colmeia/core/src/shared-business-rules/active-1x1-call/active-1x1-req-resp";
import {
    ICMassCommunicationAction,
    ICampaingActionHeader
} from "@colmeia/core/src/shared-business-rules/campaigns/campaign-type-model";
import {
    getAllNeedCommTypes,
    getChannelsFromCampaignAction,
} from "@colmeia/core/src/shared-business-rules/campaigns/mkt-utils";
import {
    IServerLocalCanonical,
    IServerLocalCanonicalArray
} from "@colmeia/core/src/shared-business-rules/canonical-model/local-canonical";
import { addTagToNSPure } from "@colmeia/core/src/shared-business-rules/colmeia-tags/tag-config-db";
import {
    EDefaultTag,
    ITaggable
} from "@colmeia/core/src/shared-business-rules/colmeia-tags/tags";
import { activeAgentTranslations } from "@colmeia/core/src/shared-business-rules/const-text/views/active-agent";
import { SchemaPropertyServer } from "@colmeia/core/src/shared-business-rules/files/files";
import {
    EMetadataNames,
    TMetadataNameArray
} from "@colmeia/core/src/shared-business-rules/metadata/metadata-db";
import {
    IPropertyKey,
    IVariable,
    TPropertyKeyArray
} from "@colmeia/core/src/shared-business-rules/metadata/metadata-util-interfaces";
import { findSchemaProperty } from "@colmeia/core/src/shared-business-rules/metadata/metadata-utils";
import { INonSerializable } from "@colmeia/core/src/shared-business-rules/non-serializable-id/non-serializable-id-interfaces";
import {
    getCanonicalForDeliveryAction,
    isChannelCanonocal
} from "@colmeia/core/src/shared-business-rules/social-cc/social-cc-rules";
import { IWhatsAppTemplate } from "@colmeia/core/src/shared-business-rules/social-media/social-media.model";
import {
    arrayToHash,
    getDeepTypedProperty,
    hashToArray,
    isInvalid,
    isValidAndEqual,
    isValidArray,
    isValidFunction,
    isValidRef,
    presentableFirstName,
    values
} from "@colmeia/core/src/tools/utility";
import { GenericTableComponent } from "app/components/dashboard/call-center/generic-table/generic-table.component";
import { AttendanceSendMethodDialogComponent } from "app/components/dashboard/dashboard-attendance-active-call/attendance-send-method-dialog/attendance-send-method-dialog.component";
import { DashboardActiveCallInfoComponent } from "app/components/dashboard/dashboard-attendance-active-call/dashboard-active-call-info/dashboard-active-call-info.component";
import { DashboardActiveCallInfoHandler, IDashboardActiveCallInfoParameters } from "app/components/dashboard/dashboard-attendance-active-call/dashboard-active-call-info/dashboard-active-call-info.handler";
import {
    OpenActiveCallContactSelectComponent,
    TOpenActiveCallContactSelectComponentInput,
    TOpenActiveCallContactSelectComponentOutput
} from "app/components/dashboard/dashboard-attendance-active-call/open-active-call-contact-select/open-active-call-contact-select.component";
import { DynamicComponentHandler } from "app/components/dashboard/dashboard-data-extractor/cm-dynamic-component/cm-dynamic-component.handler";
import { DynamicDialogComponent } from "app/components/dashboard/dashboard-data-extractor/dynamic-dialog/dynamic-dialog.component";
import { ColmeiaWindowInitService, DashboardWindowInitiator } from 'app/components/dashboard/dashboard-foundation/colmeia-window/colmeia-window-init.service';
import { ColmeiaWindowRef } from 'app/components/dashboard/dashboard-foundation/colmeia-window/colmeia-window-ref';
import { ColmeiaWindowRuntime } from "app/components/dashboard/dashboard-foundation/colmeia-window/colmeia-window-runtime";
import { ColmeiaWindowService } from 'app/components/dashboard/dashboard-foundation/colmeia-window/colmeia-window.service';
import {
    EEnumPickerMode,
    EnumPickerHandler
} from "app/components/foundation/enum-picker/enum-picker.handler";
import { MainHandler } from "app/handlers/main-handler";
import { AttendanceActiveEditCallApiService } from "./attendance-active-edit-call.service";
import { CanonicalService } from "./canonical.service";
import { DashBoardService } from "./dashboard/dashboard.service";
import { ColmeiaDialogService } from "./dialog/dialog.service";
import { LookupService } from "./lookup.service";
import { SessionService } from "./session.service";
import { SnackMessageService } from "./snack-bar";

export type TAttendanceActiveEditPrompt = { [id: string]: IVariable };

export interface ICampaignVariablesInfo {
    campaingMessageVariables: TPropertyKeyArray;
    allPrompts: TAttendanceActiveEditPrompt;
    templateLocalCanonicals: TPropertyKeyArray;
    responseToMe: boolean;
    form: SchemaPropertyServer;
}

interface IAttendanceActiveEditCallInitServiceComponentClasses {
    dynamicDialog: typeof DynamicDialogComponent;
    genericTable: typeof GenericTableComponent;
    activeCallInfo: typeof DashboardActiveCallInfoComponent;
}

enum EVariableType {
    campaign = 1,
    template,
}

interface IVariableControl {
    isChannelInfo?: boolean;
    type: EVariableType;
}

type TControlVariableDB = {
    [idProperty: string]: IVariableControl;
};

interface IOpenActiveCallParameters {
    windowId?: string;
    idParentContactList?: string;
    contactNS?: INewAgentCallContactItem;
    contactList?: TArrayID;
    initialSearchInputValue?: string;
    parentContactListIdCampaignAction?: string;
    refreshListCallback?: () => void;
    sendToAllWithCampaignActionId?: string;
    directToActiveCall?: boolean;
    activeCallParameters?: Partial<IDashboardActiveCallInfoParameters>,
    channelsType?: Array<EDelivery360Action>
}

interface IOpenActiveCallReturn {
    windowRef: ColmeiaWindowRef;
    promise: Promise<unknown>;
}

@Injectable({
    providedIn: "root",
})
export class AttendanceActiveEditHelperService {
    private controlVariables: TControlVariableDB;
    private canonicals: { [idLocalCanonical: string]: IServerLocalCanonical };

    private windowInitiator: DashboardWindowInitiator;

    constructor(
        private dialogSvc: ColmeiaDialogService,
        private matDialog: MatDialog,
        private lookupSvc: LookupService,
        private dashboardSvc: DashBoardService,
        private activeCallAPI: AttendanceActiveEditCallApiService,
        private canonicalSvc: CanonicalService,
        private sessionSvc: SessionService,
        private colmeiaWindow: ColmeiaWindowService,
        private colmeiaInitService: ColmeiaWindowInitService,
        private snackBarMessageSvc: SnackMessageService,
    ) {
        this.resetData();

        this.windowInitiator = this.colmeiaInitService.setup({
            component: DashboardActiveCallInfoComponent,
            initialPath: null,
            createPath: null,
            editPath: null,
            initializeNSObject: () => null,
            dialogConfig: {
                panelClass: ["no-bottom-padding"],
                width: "80vw",
                height: "90vh",
            },
        });
    }

    public components: IAttendanceActiveEditCallInitServiceComponentClasses;
    public setComponents(
        components: IAttendanceActiveEditCallInitServiceComponentClasses
    ) {
        this.components = components;
    }

    public addTagToNonSerializablePure<Taggable extends ITaggable>(
        taggable: Taggable,
        tags: TArrayID = []
    ): Taggable {
        return addTagToNSPure(
            taggable,
            this.sessionSvc.getAvatarID(),
            EDefaultTag.serviceAttendent,
            tags
        );
    }

    public resetData(): void {
        this.controlVariables = {};
        this.canonicals = {};
    }

    public getNSs = this.lookupSvc.createNSCacheImplementation();
    public async getNS<NonSerializable extends INonSerializable>(
        id: string
    ): Promise<NonSerializable> {
        const ns: INonSerializable = (await this.getNSs([id]))[0];
        return ns as NonSerializable;
    }

    public getPredefinedCampaignMessage(
        info: ICampaignVariablesInfo
    ): IPreDefinedCampaignMessage {
        values(info.allPrompts).forEach((pr: IVariable) => {
            const camp = info.campaingMessageVariables.find((c) => {
                return c.idProperty === pr.idProperty;
            });
            if (isValidRef(camp)) {
                camp.value = pr.value;
            } else {
                const temp = info.templateLocalCanonicals.find((c) => {
                    return c.idProperty === pr.idProperty;
                });
                if (isValidRef(temp)) {
                    temp.value = pr.value;
                }
            }
        });

        return {
            campaingMessageVariables: info.campaingMessageVariables,
            templateLocalCanonicals: info.templateLocalCanonicals,
        };
    }

    private upsertVariable(
        variable: IPropertyKey,
        variables: TPropertyKeyArray
    ): void {
        const index: number = variables.findIndex((v) => {
            return v.idProperty === variable.idProperty;
        });
        if (index === -1) {
            variables.push(variable);
        } else {
            variables[index] = variable;
        }
    }

    public async processCampaignVariables(
        campaingMessageVariables: TPropertyKeyArray,
        allPrompts: TAttendanceActiveEditPrompt,
        form: SchemaPropertyServer,
        contact: IGetActiveCustomerStatusResponse
    ): Promise<TArrayID> {
        const allreadyFullfiled: TArrayID = [];
        const localsCanonicalsIds = campaingMessageVariables
            .map(
                (v) =>
                    findSchemaProperty(
                        form.schemma.form,
                        (x) => x.idProperty === v.idProperty
                    )?.idLocalCanonical
            )
            .filter((id) => id);
        const serverLocalCanonicals = arrayToHash(
            getDeepTypedProperty<IServerLocalCanonical>((x) => x.idNS),
            await this.getNSs<IServerLocalCanonical>(localsCanonicalsIds)
        );

        campaingMessageVariables.forEach((k) => {
            const prop = findSchemaProperty(
                form.schemma.form,
                (x) => x.idProperty === k.idProperty
            );
            if (prop) {
                const serverLocalCanonical =
                    serverLocalCanonicals[prop.idLocalCanonical];
                const isNameCanonical =
                    serverLocalCanonical &&
                    isValidAndEqual(
                        serverLocalCanonical.globalCanonical,
                        EMetadataNames.name
                    );
                const isEmailCanonical =
                    serverLocalCanonical &&
                    isValidAndEqual(
                        serverLocalCanonical.globalCanonical,
                        EMetadataNames.email
                    );
                const value =
                    isNameCanonical && isValidRef(contact.contactName)
                        ? contact.contactName
                        : isEmailCanonical && isValidRef(contact.email)
                            ? contact.email
                            : "";

                allPrompts[k.idProperty] = {
                    text: prop.prompt,
                    idProperty: k.idProperty,
                    value,
                };
                this.upsertVariable(
                    allPrompts[k.idProperty],
                    campaingMessageVariables
                );
                if (prop.idLocalCanonical) {
                    allreadyFullfiled.push(prop.idLocalCanonical);
                }
            }
        });

        this.canonicals = {
            ...this.canonicals,
            ...arrayToHash(
                getDeepTypedProperty<IServerLocalCanonical>((x) => x.idNS),
                await this.getNSs<IServerLocalCanonical>(
                    hashToArray(allreadyFullfiled)
                )
            ),
        };

        return allreadyFullfiled;
    }

    public async createTemplateStructure(
        allreadyFullfiled: TArrayID,
        selectedAction: ICMassCommunicationAction,
        templateLocalCanonicals: TPropertyKeyArray,
        allPrompts: TAttendanceActiveEditPrompt,
        customer: ISearchActiveCallResult
    ): Promise<boolean> {
        const whatsTemplate: IWhatsAppTemplate =
            await this.activeCallAPI.getWhatsappChannelTemplate(selectedAction);

        if (!isValidRef(whatsTemplate)) {
            return false;
        }

        if (isValidArray(whatsTemplate.whatsAppVariablesTemplate?.variables)) {
            templateLocalCanonicals.push(
                ...whatsTemplate.whatsAppVariablesTemplate.variables.filter(
                    (variableKey) => {
                        return !allreadyFullfiled.includes(variableKey.idProperty);
                    }
                )
            );
        }

        const mustFullfillCanonicals: TArrayID =
            this.getLocalCanonicalsNotAsked(
                allreadyFullfiled,
                templateLocalCanonicals
            );

        if (isValidArray(mustFullfillCanonicals)) {
            const missingCanonicals: IServerLocalCanonicalArray =
                await this.getNSs<IServerLocalCanonical>(
                    mustFullfillCanonicals
                );

            this.canonicals = {
                ...this.canonicals,
                ...arrayToHash(
                    getDeepTypedProperty<IServerLocalCanonical>((x) => x.idNS),
                    missingCanonicals
                ),
            };

            if (isValidArray(missingCanonicals)) {
                for (const miss of missingCanonicals) {
                    if (miss.globalCanonical === EMetadataNames.name) {
                        if (isValidRef(customer.name)) {
                            allPrompts[miss.idNS] = {
                                idProperty: miss.idNS,
                                text: miss.nName,
                                value: presentableFirstName(customer.name),
                            };
                            this.upsertVariable(
                                allPrompts[miss.idNS],
                                templateLocalCanonicals
                            );
                        }
                    } else if (
                        miss.globalCanonical === EMetadataNames.attendent
                    ) {
                        allPrompts[miss.idNS] = {
                            idProperty: miss.idNS,
                            text: miss.nName,
                            value: presentableFirstName(
                                this.sessionSvc.getAvatarName()
                            ),
                        };
                        this.upsertVariable(
                            allPrompts[miss.idNS],
                            templateLocalCanonicals
                        );
                    } else {
                        allPrompts[miss.idNS] = {
                            idProperty: miss.idNS,
                            text: miss.nName,
                            value: "",
                        };
                        this.upsertVariable(
                            allPrompts[miss.idNS],
                            templateLocalCanonicals
                        );
                    }
                }
            }
        }

        return true;
    }

    getLocalCanonicalsNotAsked(
        idLocalUsedOnForm: TArrayID,
        idsUsedOnTemplate: TPropertyKeyArray
    ): TArrayID {
        const mustBeCompleted: TArrayID = [];

        for (const idUsed of idsUsedOnTemplate) {
            if (
                !idLocalUsedOnForm.some((f) => {
                    return f === idUsed.idProperty;
                })
            ) {
                mustBeCompleted.push(idUsed.idProperty);
            }
        }

        return mustBeCompleted;
    }

    public async addCampaingRunInformation(
        info: ICampaignVariablesInfo,
        form: SchemaPropertyServer,
        selectedAction: ICampaingActionHeader,
        contact: ISearchActiveCallResult,
        contactWithTargetFound: IGetActiveCustomerStatusResponse
    ): Promise<void> {
        // Aqui pegamos os canonicos referentes ao canal
        const needeedType: TMetadataNameArray = getAllNeedCommTypes(
            getChannelsFromCampaignAction(selectedAction)
        );
        const localsCanonicals: IServerLocalCanonicalArray =
            await this.canonicalSvc.getLocalByGlobalCanonicals(needeedType);
        const contactGlobalCanonical: EMetadataNames =
            getCanonicalForDeliveryAction(contact.channel);

        for (const local of localsCanonicals) {
            const prop = findSchemaProperty(
                form.schemma.form,
                (x) => x.idLocalCanonical === local.idNS
            );

            const isSameOfContact: boolean = isValidAndEqual(
                local.globalCanonical,
                contactGlobalCanonical
            );
            const isSameOfEmail: boolean = isValidAndEqual(
                local.globalCanonical,
                EMetadataNames.email
            );

            const value: string =
                isSameOfContact && isValidRef(contact.target)
                    ? contact.target
                    : isSameOfEmail && isValidRef(contactWithTargetFound.email)
                        ? contactWithTargetFound.email
                        : "";

            if (isValidRef(prop)) {
                info.allPrompts[prop.idProperty] = {
                    idProperty: prop.idProperty,
                    text: local.nName,
                    value,
                };
                this.upsertVariable(
                    info.allPrompts[prop.idProperty],
                    info.campaingMessageVariables
                );

                this.canonicals[prop.idProperty] = local;
            } else {
                this.canonicals[local.idNS] = local;
                info.allPrompts[local.idNS] = {
                    idProperty: local.idNS,
                    text: local.nName,
                    value,
                };
                this.upsertVariable(
                    info.allPrompts[local.idNS],
                    info.templateLocalCanonicals
                );
            }
        }
    }

    public initChannelPicker(
        channelParam: EDelivery360Action
    ): EnumPickerHandler<EDelivery360Action> {
        return new EnumPickerHandler<EDelivery360Action>({
            clientCallback: null,
            client: {
                onSingleEnumSelection: (channel) => (channelParam = channel),
            },
            mode: EEnumPickerMode.Single,
            translations: activeAgentTranslations,
            inputTitle: "",
            enum: EDelivery360Action,
            current: channelParam,
            appearance: "outline",
        });
    }

    public dynamicDialog<Component, Handler, T>(
        component: Component,
        handler?: Handler,
        setDialogRef?: (ref: MatDialogRef<any>) => void
    ): Promise<void> {
        const ref = this.dialogSvc.open({
            componentRef: this.components.dynamicDialog,
            hideHeader: true,
            dataToComponent: {
                data: DynamicComponentHandler.factory({
                    component,
                    handler: handler as unknown as MainHandler,
                }),
            },
        });
        if (isValidFunction(setDialogRef)) setDialogRef(ref);
        return this.waitDialog(ref);
    }

    public waitDialog<T>(dialogRef: MatDialogRef<T>): Promise<void> {
        return new Promise((resolve) =>
            dialogRef.beforeClosed().subscribe(() => {
                resolve();
            })
        );
    }

    public openActiveCallInfo({
        idParentContactList,
        contactNS,
        contactList,
        initialSearchInputValue,
        parentContactListIdCampaignAction,
        refreshListCallback,
        sendToAllWithCampaignActionId,
        directToActiveCall,
        activeCallParameters,
        windowId,
        channelsType
    }: IOpenActiveCallParameters = {}): IOpenActiveCallReturn {
        let ref: ColmeiaWindowRef;

        const promise = new Promise<null>((resolve) => {
            const handler: DashboardActiveCallInfoHandler =
                DashboardActiveCallInfoHandler.factory({
                    idParentContactList,
                    contactNS,
                    contactList,
                    initialSearchInputValue,
                    clientCallback: {
                        onUpdateActiveCallInfo: () => {
                            ref.close();
                            resolve(null);
                        },
                        closeDialog: () => ref.close(),
                        refreshList: isValidFunction(refreshListCallback) ? () => refreshListCallback?.() : undefined
                    },
                    parentContactListIdCampaignAction,
                    sendToAllWithCampaignActionId,
                    directToActiveCall,
                    channelsType,
                    ...activeCallParameters
                });

            ref = this.openWindow(handler, windowId);
        });

        return {
            windowRef: ref,
            promise,
        }
    }

    public isChannelPrompt(
        idProperty: string,
        info: ICampaignVariablesInfo
    ): boolean {
        const retur = this.controlVariables[idProperty];

        if (isValidRef(retur) && isValidRef(retur.isChannelInfo)) {
            return retur.isChannelInfo;
        }

        const variableConfig = this.getPropertyVariableConfig(idProperty, info);

        return variableConfig.isChannelInfo;
    }

    public getPropertyVariableConfig(
        idProperty: string,
        info: ICampaignVariablesInfo
    ): IVariableControl {
        const retur = this.controlVariables[idProperty];

        if (isValidRef(retur) && isValidRef(retur.type)) {
            return retur;
        }

        this.loadType(idProperty, info);

        return this.controlVariables[idProperty];
    }

    private loadType(idProperty: string, info: ICampaignVariablesInfo): void {
        if (isInvalid(this.controlVariables[idProperty])) {
            this.controlVariables[idProperty] = {} as IVariableControl;
        }

        const prop = findSchemaProperty(
            info.form.schemma.form,
            (x) => x.idProperty === idProperty
        );
        if (isValidRef(prop)) {
            this.controlVariables[idProperty].type = EVariableType.campaign;
        } else {
            const temp = info.templateLocalCanonicals.find(
                (x) => x.idProperty === idProperty
            );
            if (isValidRef(temp)) {
                this.controlVariables[idProperty].type ===
                    EVariableType.template;
            }
        }

        if (
            this.canonicals[idProperty] &&
            this.canonicals[idProperty].globalCanonical
        ) {
            this.controlVariables[idProperty].isChannelInfo =
                isChannelCanonocal(this.canonicals[idProperty].globalCanonical);
        }
    }

    public isIdAttendentVariable(id: string): boolean {
        return values(this.canonicals).some((canonical) => {
            return (
                canonical.idNS === id &&
                canonical.globalCanonical === EMetadataNames.attendentID
            );
        });
    }

    async openContactSelector(
        contacts: TCheckedContactArray,
        maxSelection: number = 1
    ): Promise<TCheckedContactArray> {
        const dialogRef = this.matDialog.open<
            OpenActiveCallContactSelectComponent,
            TOpenActiveCallContactSelectComponentInput,
            TOpenActiveCallContactSelectComponentOutput
        >(OpenActiveCallContactSelectComponent, {
            data: {
                contacts,
                maxSelection,
            },
            panelClass: "small-size",
        });

        const response = await dialogRef.afterClosed().toPromise();
        return response?.contacts;
    }

    openDialog(initialSearchInputValue?: string) {
        let ref: ColmeiaWindowRef;
        const handler: DashboardActiveCallInfoHandler =
            DashboardActiveCallInfoHandler.factory({
                initialSearchInputValue,
                clientCallback: {
                    onUpdateActiveCallInfo: () => {
                        ref.close();
                    },
                },
            });

        const runtime = this.openWindow(handler);

        ref = runtime;
    }

    private openWindow(handler: DashboardActiveCallInfoHandler, windowIdentifier?: string): ColmeiaWindowRef {

        const windowRef = this.colmeiaWindow.open(DashboardActiveCallInfoComponent, {
            windowIdentifier,
            panelClass: ["no-bottom-padding"],
            width: "80vw",
            height: "90vh",
            data: handler
        });

        windowRef.group = "Chamadas ativas";

        return windowRef;
    }

    public async sendForContactsSelection(par: {
        idParent: string;
        nsers: INewAgentCallContactServer[];
        selectedCampaignAction?: string,
        viewContainerRef?: ViewContainerRef;
    }): Promise<void> {
        if (par.nsers.every(ac => ac.isPartial)) {
            await this.openActiveCallInfo({
                idParentContactList: par.idParent,
                contactList: par.nsers.map(nser => nser.idNS),
                sendToAllWithCampaignActionId: par.selectedCampaignAction
            });
        } else {
            const dialogRef = this.dialogSvc.open({
                componentRef: AttendanceSendMethodDialogComponent,
                lineUnderHeader: false,
                title: 'Envio',
                dataToComponent: {
                    disableClose: false,
                    data: {
                        data: par.nsers,
                        selectedCampaignAction: par.selectedCampaignAction,
                        afterSend: () => {
                            this.snackBarMessageSvc.openInfo("O envio para os contatos selecionados foi iniciado");
                        },
                        afterSchedule: () => {
                            this.snackBarMessageSvc.openInfo("Envio agendado");
                        }
                    }
                },
                viewContainerRef: par.viewContainerRef,
            });

            await dialogRef.afterClosed().toPromise();
        }
    }

}
