import { Component, Input, OnChanges } from '@angular/core';
import { ECustomerAvatarStatus, getCustomerAvatarStatusColor } from 'app/services/attendace/attendance-channel.service';
import { stringToColor } from 'app/utils/random-color-gen';

@Component({
    selector: 'customer-avatar',
    templateUrl: './customer-avatar.component.html',
    styleUrls: ['./customer-avatar.component.scss']
})
export class CustomerAvatarComponent implements OnChanges {
    private _fullName: string;

    @Input()
    set fullName(value: string) {
        // limpa a string
        this._fullName = value.replace(/\s+/g, ' ');
    }

    get fullName(): string {
        return this._fullName;
    }

    @Input()
    public identifier: string;

    @Input()
    public status: ECustomerAvatarStatus = null;

    public initials: string;
    public backgroundColor: string;
    public borderColor: string;

    public ngOnChanges() {
        this.initials = this.getInitials(this.fullName);

        this.backgroundColor = stringToColor(this.identifier, {
            saturation: {
                base: 20,
                randomizedRange: 30
            },
            lightness: {
                base: 50
            }
        });

        this.borderColor = getCustomerAvatarStatusColor(this.status);
    }

    private getInitials(fullName: string) {
        let initials;
        const names = fullName.split(' ');

        if (names.length > 1) {
            initials = names[0][0] + (names[1][0] || '');
        } else {
            initials = names[0][0];
        }

        initials = initials.toUpperCase();

        return initials;
    }
}
