<ng-container *ngIf="!isLoading else loadingTpl">
    <cm-generic-dashboard-edit *ngIf="genericEditHandler" [handler]="genericEditHandler">
        <cm-generic-dashboard-home *ngIf="contactsHomeHandler" [handler]="contactsHomeHandler">
        </cm-generic-dashboard-home>
    </cm-generic-dashboard-edit>
</ng-container>

<ng-template #loadingTpl>
    <center>
        <mat-spinner style="margin: 12px;" diameter="28"></mat-spinner>
    </center>
</ng-template>

<app-attendance-conversation-history-search #attConvHistory style="display:none;"></app-attendance-conversation-history-search>