import { TFormSchemmaArray } from "@colmeia/core/src/general-form/general-form-interface";
import { TAttendentServiceGroupArray } from "@colmeia/core/src/shared-business-rules/attendent-service-pack/attendente-service-pack";
import { TBasicUniveralInfoArray } from "@colmeia/core/src/comm-interfaces/aux-interfaces";




export class InternalServiceGroupsHandler {
	constructor(private serviceGroups: TAttendentServiceGroupArray, private groupsBasicInfoLookups) {}


	getGroupsBasicInfoLookups(): TBasicUniveralInfoArray {

		console.log('InternalServiceGroupsHandler groupsBasicInfoLookups', {
			groupsBasicInfoLookups: this.groupsBasicInfoLookups
		})


		return this.groupsBasicInfoLookups
	}
	
	getServiceGroups(): TAttendentServiceGroupArray {
		return this.serviceGroups
	}
}