import { IComponentParameter } from "app/model/component-comm/basic";
import { MainHandler } from "app/handlers/main-handler";
import { INewAgentCallContactItem } from "@colmeia/core/src/shared-business-rules/active-1x1-call/active-1x1-model";
import { NamedString, TArrayID } from "@colmeia/core/src/core-constants/types";
import { TCMassCommActionArray } from "@colmeia/core/src/shared-business-rules/campaigns/campaign-type-model";
import { AcceptPromise } from "@colmeia/core/src/tools/utility-types";
import { IRunActionCampaignForActiveCall, IRunActionCampaignForActiveCallResponseBase } from "@colmeia/core/src/shared-business-rules/active-1x1-call/active-1x1-req-resp";
import { ICRMTicketDataWithCustomerName } from "@colmeia/core/src/shared-business-rules/crm/crm-entities";
import { IBPMMarketingRootServer } from "@colmeia/core/src/shared-business-rules/BPM/marketing/bpm-marketing.model";
import { EDelivery360Action } from "@colmeia/core/src/comm-interfaces/interaction-interfaces";

export interface IDashboardActiveCallInfoClientCallback {
    onUpdateActiveCallInfo(): void;
    closeDialog?(): void;
    refreshList?(): void;
}

export type TMKTRuleMap = { [mktRuleId in string]: IBPMMarketingRootServer };
export type TMKTAttachedCampaignActions = { [idCampaignAction in string]: string };

export type TCampaignActionId = NamedString<'campaignActionId'>

export interface IDashboardActiveCallInfoParameters extends IComponentParameter {
    idParentContactList?: string;
    initialSearchInputValue?: string,
    contactNS?: INewAgentCallContactItem;
    clientCallback: IDashboardActiveCallInfoClientCallback;
    contactList?: TArrayID;
    parentContactListIdCampaignAction?: string;
    sendToAllWithCampaignActionId?: TCampaignActionId;
    directToActiveCall?: boolean;
    ticketData?: ICRMTicketDataWithCustomerName;
    customActionsLoader?: () => Promise<TCMassCommActionArray>;
    requestInterceptor?: (request: IRunActionCampaignForActiveCall) => AcceptPromise<IRunActionCampaignForActiveCallResponseBase>;
    mktRuleMap?: TMKTRuleMap;
    mktRuleAttachedCampaignActions?: TMKTAttachedCampaignActions;
    onBackButtonClick?: (event: Event) => void;
    hideDetailsBackButton?: boolean;
    channelsType?: Array<EDelivery360Action>
}

export class DashboardActiveCallInfoHandler extends MainHandler {
    private constructor(parameter: IDashboardActiveCallInfoParameters) { super(parameter) }
    public static factory(parameter: IDashboardActiveCallInfoParameters) { return new DashboardActiveCallInfoHandler(parameter); }
    public getComponentParameter() { return super.getComponentParameter() as IDashboardActiveCallInfoParameters }
}
