import { ICorporateSearchRenderOptions } from '@colmeia/core/src/shared-business-rules/bot/bot-model';
import { TContentAssetArray } from '../bot/bot-content-model';
import { ENonSerializableObjectType, INonSerializable, INonSerializableHeader } from '../non-serializable-id/non-serializable-id-interfaces';
import { ECSPredicateType } from '../metadata/meta-engagement';


export enum EElasticConstants {
    keywordPrefix = '__',
    rawTextFieldName = 'raw'
}

export enum ECSFormatField {
    asIs = "asIs",
    toLowerCase = "toLower",
    toUperCase = "toUpper",
    firstLetterCapitalized = "firstLetCap",
    allFirstLetterCap = "allFirstLetCap"
}



export interface ICSDisplayField {
    idPropertyField: string; // x (3)
    columnName?: string;
    fieldFormatType?: ECSFormatField

};

export interface ICorporateSearchDatabaseConfig {
    idSearch: string,
}

export interface ICorporateSearchDatabaseProcessParams extends ICorporateSearchDatabaseConfig {
    idSocialContext: string,
    fileVersion?: string,
    parentSearchDefinition: ICorporateSearchHeaderDefinition,
    isForceReprocessDatabase?: boolean,
    childrenSearchDefinition: ICorporateSearchBody[] | ICorporateSearchBodyServer[],
}

export interface ICSSortField {
    idPropertyField: string;
    ascending: boolean;
}

export enum EESTypes {
    text = 'text',  // Por Semelhança
    keyword = 'keyword',  // Busca Exata
    date = 'date', // Por Data
    long = 'long', // long, -2^63 a 2^63
    ZipCode = 'zip',
    geo_point = 'geo_point' // Por Georeferenciamento
}

export enum EEsToPostgresDataTypes {
    text = 'text',
    keyword = 'text',
    long = 'bigint',
    date = 'date',
    ZipCode = 'text',
    geo_point = 'point',
}

export enum EPostgresDataTypes {
    text = 'text',
    bigint = 'bigint',
    date = 'date',
    point = 'point',
    ZipCode = 'text',
}

export enum EElasticAnalyzers {
    analyzer_plural_acentos = "analyzer_plural_acentos"
}

export type TEESTypesArray = Array<EESTypes>;

export type TICSSortFieldArray = Array<ICSSortField>;

export type TICSDisplayFieldArray = Array<ICSDisplayField>;

export interface IElasticSearchTypeContainer {
    type: EESTypes
}

export interface IElasticSearchInnerField {
    [EElasticConstants.rawTextFieldName]: {
        type: EESTypes;
    };
}

export interface ICSearchFieldConfigurationSchema {
    [idPropertyField: string]: { type: EESTypes, analyzer: EElasticAnalyzers; fields?: IElasticSearchInnerField }
}

export interface IPostgresTableDefinition {
    [idPropertyField: string]: {
        type: EPostgresDataTypes,
        ignoreDiacritics: boolean,
    };
}

export type THashICSearchFieldConfiguration = { [idProperty: string]: ISearchFieldConfig }

export interface ICSearchFieldConfiguration {
    idPropertyField: string;
    type: EESTypes | EPostgresDataTypes;
    ignoreDiacritics: boolean;
    allwaysSingular?: boolean;
    zipCodeRegex?: string; // Se For ZIP
};

export type TICSearchFieldConfigurationArray = Array<ICSearchFieldConfiguration>;

export enum ECSSource {
    elasticSearch = 'elastic',
    aiVectorSearch = 'aiVectorSearch',
    database = 'database',
    function = 'function',
}

export interface ICorporateSearchHeaderDefinition {
    type: ECSSource;
    idMetadata: string; // desnormalizado.. para conferência
    idFunction: string; // ECSSource.function
    idDatabase: string; // ECSSource.elasticSearch | ECSSource.database
    idIndex: string; // Índice
    configureFieldList: TICSearchFieldConfigurationArray;  // Buscar nos seguintes campos:
}

export interface ISearchFieldConfig {
    idPropertyField: string;
}

/**
 * partialWord = palavra parcial
 * fullWord = palavra inteira
 * partialPhraseWithPrefix = busca parcial em grupos de palavras
 */
export enum ECSContentComparison {
    partialWord = 'prefix',
    fullWord = 'term',
    partialPhraseWithPrefix = 'match_phrase_prefix'
}

export interface ISearchStringInfo {
    idProperty?: string; // opcional pois os campos para a busca podem ja estar setados no searchFields
    type: ECSPredicateType;
    value: string; // tokenizar, trim, minusculo
    comparison: ECSContentComparison;
}

export type TISearchStringInfoArray = Array<ISearchStringInfo>
export type TISearchFieldConfigArray = Array<ISearchFieldConfig>;

export interface ICorporateSearchDefinition {

    searchFields: TISearchFieldConfigArray

    // Configuração de Busca

    // Campos Alvo para a Busca

    // Configuração da Ordenação
    sortBy: TICSSortFieldArray



    // Configuração da Exibiço
    title: TContentAssetArray; // Apenas 1 elemento para o título
    messageOnSuccess: TContentAssetArray;
    messageOnEmptyResult: TContentAssetArray;

    showScorePercentage?: boolean;  // Mostrar percentage de acurácia

    maxItemDisplayed?: number;   // Número máximo de Itens para mostrar

    minimumScore: number; // Ignorar resultados com score abaixo de:

    displayFields: TICSDisplayFieldArray; // select list
    // Preenchido Formulário
    returningField: string; // O ID que será retornado para o preenchimento do formulário


    // O código de retorno da busca corporatova é apenas usado internamente para funções e joins.
    // Este atributo seta o comportamento ao Field visto pelo usuário também
    useReturnCodeAsDisplay?: boolean;

    advancedDisplay?: ICorporateSearchRenderOptions;
    splitter?: string;
}


export interface ICorporateSearchHeader extends INonSerializableHeader, ICorporateSearchHeaderDefinition {


}

export type TICorporateSearchServerArray = ICorporateSearchHeaderServer[]
export interface ICorporateSearchHeaderServer extends INonSerializable, ICorporateSearchHeader {
    nsType: ENonSerializableObjectType.corporateSearchHeader;
}


export interface ICorporateSearchBody extends INonSerializableHeader, ICorporateSearchDefinition {

}

export type TICorporateSearchBodyServerArray = Array<ICorporateSearchBodyServer>;

export interface ICorporateSearchBodyServer extends INonSerializable, ICorporateSearchBody {
    nsType: ENonSerializableObjectType.corporateSearchBody;
}

export const DEFAULT_CORPORATE_SEARCH_RESULT_SPLITTER = '/'
