import { ISocialNetworkSubscribers } from "@colmeia/core/src/shared-business-rules/social-network/social-subscriber-interfaces";
import { TGlobalUID } from "../../business/constant";
import { EDelivery360Action, IInteractionJSONArray } from "../../comm-interfaces/interaction-interfaces";
import { EAttendantStatus, IServiceChatInitiatedInteractionJSON, TAttendentStatusChangeLogArray, TServiceInteractionChatJSONArray } from "../../comm-interfaces/service-group-interfaces";
import { I360Media } from "../../core-constants/bot";
import { TGeneralFormServerAnswerArray } from "../../general-form/general-form-answer";
import { apiRequestType } from "../../request-interfaces/message-types";
import { IRequest, IRequestCursor, WithCursor } from "../../request-interfaces/request-interfaces";
import { IResponse, IResponseCursor } from "../../request-interfaces/response-interfaces";
import { TServiceIslandArray } from "../attendance-island/attendance-island";
import { IBotConversation } from "../bot/bot-interfaces";
import { IEntryCancelReason, IReceiveRegister } from "../campaigns/campaing-interfaces";
import { ITagableRequest, ITagableSearch } from "../non-serializable-id/non-serializable-req-resp";
import { TActiveCallAvailableChannelArray } from "./active-cc";
import {
    IAttendentServicePackClient,
    IMacroPackageServer,
    IPresenceStatusServer,
    TIMacroPackageServerArray
} from "./attendente-service-pack";
import { ICursorAndTagRequest } from "@colmeia/core/src/dashboard-control/dashboard-request-interfaces";
import { extend } from "joi";
import { IAdvancedTemplateAction } from "../social-media/social-media.model";
import { IContentBasicAsset } from "../bot/bot-content-model";
import { WhatsApp } from "../social-media/whatsapp-interface";
import { ECallCenterAllAgentsRequestTaggableType } from "@colmeia/core/src/shared-business-rules/call-center/call-center-types";
import { TISODateString } from "@colmeia/core/src/tools/date/date-utils.types";
import { ITransactionServer } from "../knowledge-base/bot-transaction/bot-transaction";
import { IdCampaign, IdCampaignAction } from "@colmeia/core/src/shared-business-rules/non-serializable-id/non-serializable-id-interfaces";
import { IPaymentViewData } from "../payments/payments.types";


export interface IQuickDrawAllocationRequest extends IRequest {
    requestType: typeof apiRequestType.dashboardExtra.attendance.quickDrawAllocation;
}

export interface IQuickDrawAllocationResponse extends IResponse {
    responseType: typeof apiRequestType.dashboardExtra.attendance.quickDrawAllocation;
}


export interface IAttendentServicePackSaveRequest extends IRequest {
    ns: IAttendentServicePackClient,
}

export interface IAttendentServicePackResponse extends IResponse {
    servicePack: IMacroPackageServer,
};


export interface IAttendentServicePackRequest extends ITagableRequest { };

export interface IAttendentServiceRequestListResponse extends IResponse {
    servicePacks: TIMacroPackageServerArray;
}

export interface IGetAttendentServicePackRequest extends IRequest {
    idServicePacks: string[];
}

export interface IGetAttendentServicePackResponse extends IResponse {
    servicePacks: IMacroPackageServer[];
}

export interface IUpdateStatusCommand {
    idAvatarAgent: TGlobalUID;
    currentStatus?: EAttendantStatus;
    status: EAttendantStatus;
    idPresenceStatus?: string;
    idLastLogin?: string;
}

export interface IAttendanceStats {
    customerName: string;
    target: string; // recently added
    start: string;
    end: string;
    duration: number;
    action?: string // added for the Widget table
}

export interface IAttendantStats {
    amountOfCases: number;
    nowAttending: number;
    longerTime: string;
    shortTime: string;
    averageTime: string;
    attendances: IAttendanceStats[];
}

export interface IAttendanceStatsRequest extends IRequest {
    idAgentLogin: string;
    initClock: number; // 8AM
    endClock: number; // NOW
}

export interface IGetMessageSentForActiveCallRequest extends IRequest {
    idMassEntry: string;
    channel: EDelivery360Action;
}

export interface IAcitveCallMessageSent {
    headerFormat?: WhatsApp.Message.Template.Structure.Format;
    headerContent?: IContentBasicAsset;
    body?: string;
    footer?: string;
    advancedOption?: IAdvancedTemplateAction
}

export interface IGetMessageSentForActiveCallResponse extends IResponse {
    sentMessage: IAcitveCallMessageSent;
}

export interface IAttendanceMetricsTagsTaggableSearch extends ICursorAndTagRequest {
    taggable: ITagableSearch;
    taggableSearchType: ECallCenterAllAgentsRequestTaggableType;
}

export interface IAttendanceMetricsRequest extends IRequestCursor {
    beginTimestamp: TISODateString;
    endTimestamp: TISODateString;
    taggableSearches: IAttendanceMetricsTagsTaggableSearch[];
}


export interface IRealTimeAnalyticsSections {
    attendanceIndicators: {
        mainIndicators: IAttendanceMainIndicators;
        othersIndicators: IAttendanceOthersIndicators
    };
    queueIndicators: {
        mainIndicators: IQueueMainIndicators,
        queueIndicatorsByIsland: IQueuesInfoByIsland[];
        queueByHourAndStatus: IQueueByHour[];
    };
    islandIndicators: IIslandInfo[];
    agentIndicators: IStatusDurationMetrics[];
}

export interface IAttendanceMainIndicators {
    totalCalls: number;
    completedCalls: number;
    ongoingCalls: number;
    queuedCalls: number;
    expiredCalls: number;
}
export interface IAttendanceOthersIndicators {
    totalAgents: number;
    onlineAgents: number;
    offlineAgents: number;
    busyAgents: number;
    awayAgents: number;
    servedClients: number;
    averageServiceTime: string;
}


export interface IQueueIndicators {

}

export interface IQueueMainIndicators {
    totalQueues: number;
    clientsInQueue: number;
    clientsWithPriorityAgent: number;
    averageTimeInQueue: string;
    averageTimeInQueueWithPriorityAgent: string;
    averageTimeInNormalQueue: string;
}

export interface IAttendanceMetricsResponse extends IResponse {
    sections: IRealTimeAnalyticsSections
}

export interface ICustomerOnAgentQueue {
    name: string;
    customerOnQueue: number;
    maxAttTime: number
}
export type TICustomerOnAgentQueueArray = Array<ICustomerOnAgentQueue>;

export type TIActiveCallSentArray = Array<IActiveCallSent>;

export interface IActiveCallSent extends IReceiveRegister {
    idAvatar: string;
    sentClock: number;
    scheduleTo: number;
    name: string;
    target: string;
    channel: EDelivery360Action;
    cancel?: IEntryCancelReason;
    errorMessages?: string[];
    agentName?: string;
    idAgentAvatar?: TGlobalUID;
    idMassEntry: string;
}

export interface IAttendanceStatsResponse extends IResponse {
    stats: IAttendantStats;
    isReadyToAttend: boolean;
    login: IAgentLoginInfo;
    customerOnAgentQueue: TICustomerOnAgentQueueArray;
    activeSent: TIActiveCallSentArray;
}



export interface IAgentLoginFullInfo {
    login: IAgentLoginInfo;
    isReadyToAttend: boolean;
}

export interface IUpdateAgentStatusRequest extends IRequest {
    upd: IUpdateStatusCommand;
};

export interface IStopServiceRequestWatchDog extends IRequest {
    requestType: typeof apiRequestType.stopServiceRequestWatchDog;
    idInteraction: string;
}

export interface IAgentLoginLogout extends IRequest {
    isLogin: boolean;
    idAgentAvatar: string;
}

export interface IGetAttendentStatusRequest extends IRequest {
    idAvatarAgent: TGlobalUID;
}

export type TBroadcastIslandControl = { [idIsland in string]: number };
export interface IAgentLoginInfo {
    broadCastIslands: TBroadcastIslandControl;
    loggedIn: boolean;
    loginId?: string;
    presenceId: string | null;
    status: EAttendantStatus;
    lastClock: number;
}

export interface IGetAttendentStatusResponse extends IResponse {
    login: IAgentLoginInfo;
    isOpenCase: boolean;
    isReadyToAttend: boolean;
    islands: TServiceIslandArray;
    presences: IPresenceStatusServer[];
}

export interface IGetStatusChangeLogReqest extends IRequestCursor {
    idAvatarAgent: TGlobalUID;
};

export interface IGetStatusChangeLogResponse extends IResponseCursor {
    logs: TAttendentStatusChangeLogArray
}


export interface IGetChannelStatusRequest extends IRequest {

}

export interface IGetIDentifiedChannelResponse extends IResponse {
    channels: TActiveCallAvailableChannelArray;

}


export enum EFilterConversationsOptions {
    all = 'all',
    withHumanAttendance = 'withHumanAttendance',
    withTemplateMessage = 'withTemplateMessage'
}
export interface IAllCustomerActivityRequest extends IRequest, WithCursor {
    idSocialKey: TGlobalUID;
    begin: number;
    end: number;
    filterConversationsBy?: EFilterConversationsOptions;

}

export interface IAllCustomerACtiveHeaderItemData {
    clockTick: number; // Começou 2a conversação
    hasService: boolean; // adicionar no filtro
    hasPayment?: boolean;
    hasTemplateMsg: boolean // Adicionar no filtr; checar com joi
}
export interface IAllCustomerActiveHeaderResult {
    avatarName: string;
    target: string;
    conversationDB: IAllCustomerActiveHeader;
}
export interface IAllCustomerActiveHeaderResultWithCursor {
    allInbound: IAllCustomerActiveHeaderResult,
    cursor: string
    startInteractionListCached?: TServiceInteractionChatJSONArray,
    socialNetworkSubscribersCache?: ISocialNetworkSubscribers
}

export interface IAllCustomerActiveHeader {
    [idConversation: string]: IAllCustomerACtiveHeaderItemData
}


export interface IAllCustomerActivityResponse extends IResponse {
    response: IAllCustomerActiveHeader;
    avatarName: string;
    lastConversation: IConversationDetailsWithMedia;
    lastIDConversation: string;
}

export interface IUserUploadedMediaToBot extends I360Media {
    clockTick: number,
}


export interface IConversationDetails {
    clockTick: number; // Começou o atendimento
    interactions: IInteractionJSONArray; // Mensagens com agentes
    botConversation: IBotConversation; // Bot
    answers: TGeneralFormServerAnswerArray; // Formulário
    answersColmeiaForms: ITransactionServer[];
    botResponses: TIBotAnswerDSRegisterArray
    channelName?: string;
}
export interface IConversationDetailsWithMedia extends IConversationDetails {
    userUploadedMediaToBot: IUserUploadedMediaToBot[]
}
export interface IAllConversationData {
    [idConversation: string]: IConversationDetails
}




export interface ICustomerConversationRequest extends IRequest {
    idConversation: TGlobalUID;
    target: string;
}


export interface IConversationResponse extends IResponse {
    response: IConversationDetailsWithMedia
}

export interface ICustomerConversationRenewStorageLinkRequest extends IRequest {
    botLogId: TGlobalUID;
    idMedia: string;
}

export interface ICustomerConversationRenewStorageLinkResponse extends IResponse {
    new360Media: I360Media;
}

export type TIBotAnswerDSRegisterArray = Array<IBotAnswerDSRegister>;

export interface IBotAnswerDSRegisterCampaign {
    id: IdCampaign;
    idAction: IdCampaignAction;
    isTimelyReturned: boolean;
}

export interface IPaymentTransactionInfo {
    paymentTransactionId: string;
}
export interface IBotAnswerDSRegister {
    idBotAnswer: string;
    idConversation: string;
    clockTick: number;
    text?: string;
    media?: I360Media;
    campaign?: IBotAnswerDSRegisterCampaign;
    paymentTransactionInfo?: IPaymentTransactionInfo;
}

export interface IBotAnswerDSRegisterWithBotId extends IBotAnswerDSRegister {
    isBot: true
}

export interface IAttendanceMetrics {
    queueTotal: number;

    queueCurrent: number;

    queuePriorityTotal: number;

    queueAvgTime: number;
    queueAvgTimePriority: number;

    expired: number;

    avgTimeNormalQueue: number;
}

export interface IAgentStatusMetrics {
    status: string;
    volume: number;
}

export interface IAgentCaseMetrics {
    currOnService: number;
    endedToday: number;
    avgServiceDuration: number;
}

export interface IStatusDurationMetrics {
    begin_att: number;
    id_avatar_agent: TGlobalUID;
    agent_name: string;
    changes: number;
    status: EAttendantStatus;
    status_duration: number;
    on_service: boolean;
}

export interface IAttByHour {
    hour: number;
    isOnService: number;
    hasEnded: number;

}

export interface IQueueByHour {
    hour: number;
    currentlyOnService: number;
    ended: number;
    onQueue: number;
}

export interface IIslandInfo {
    idIsland: string;
    islandName: string;
    totalCalls: number;
    ongoingCalls: number;
    averageServiceTime: number;
}

export interface IQueuesInfoByIslandFromDatabase {
    /**
     * desistencia
     */
    // dropped: number;
    /**
     * em fila - done
     */
    inQueue: number;
    /**
     * expirados - done
     */
    // expired: number;
    /**
     * em atendimento - done
     */
    ongoing: number;
    /**
     * atendimentos finalizados - done
     */
    finished: number;
    /**
     * fila agente prioritario
     */
    queueWithPriorityAgent: number;
}

export interface IQueuesInfoByIsland extends IQueuesInfoByIslandFromDatabase {
    islandName: string;
    idIsland: string
}

export interface ISendOpenChannelTemplateRequest extends IRequest {
    idConversation: string;
    startServiceChat: IServiceChatInitiatedInteractionJSON
}

export interface ISendOpenChannelTemplateResponse extends IResponse {
    idConversation: string;
}
