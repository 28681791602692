import { Component, OnInit, Inject, ChangeDetectorRef } from '@angular/core';
import { Avatar } from '@colmeia/core/src/business/avatar';
import { HandlerHexagonon, EHexagonSizes } from 'app/handlers/hexagono.handler';
import { IFilterlet } from '@colmeia/core/src/rules/dynamic-filter/dynamic-filter-interfaces';
import { isInvalidArray } from '@colmeia/core/src/tools/utility';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { IAvatarHandlerHash, filterletToken, chatFilterEventsToken, IChatFilterEvents } from 'app/model/chat-filter.model';
import { SearchService } from 'app/services/search.service';
import { SessionService } from 'app/services/session.service';
import { ISearchInfo, TSearchResultArray } from '@colmeia/core/src/comm-interfaces/ds-interfaces';
import { filter, first } from 'rxjs/operators';
import { constant } from '@colmeia/core/src/business/constant';
import { IAvatarJSON } from '@colmeia/core/src/comm-interfaces/business-interfaces';
import { ESearchScope, ESearchAllowedType } from '@colmeia/core/src/shared-business-rules/search'


@Component({
	selector: 'app-chat-people-filter',
	templateUrl: './chat-people-filter.component.html',
	styleUrls: ['./chat-people-filter.component.scss']
})
export class ChatPeopleFilterComponent implements OnInit {

	avatars: Avatar[];
	handlers: IAvatarHandlerHash = {};
	loading: boolean = true;
	selectedAvatars: string[];

	constructor(
		private searchSvc: SearchService,
		private session: SessionService,
		private cdr: ChangeDetectorRef,
		@Inject(filterletToken) public filterlet: IFilterlet,
		@Inject(chatFilterEventsToken) private events: IChatFilterEvents,
	) { }

	ngOnInit() {
		if (isInvalidArray(this.filterlet.avatarList)) {
			this.filterlet.avatarList = [];
		}
		this.selectedAvatars = Array.from(this.filterlet.avatarList);
		this.loadAvatars();
		this.events.applied.pipe(first()).subscribe(() => this.filterlet.avatarList = this.selectedAvatars);
	}

	async loadAvatars() {
		const searchInfo: ISearchInfo = {
            auxOptionID: ESearchAllowedType.people ,
            searchContext: ESearchScope.thisLevelOnly,
			text: '',
			getFirstElementsWithNoTyping: true
		};

		const subs = this.searchSvc
			.getSearchResults()
			.pipe(filter(searchResults => searchResults != null))
			.subscribe((searchResults: TSearchResultArray) => {
				this.avatars = searchResults
					.filter(result => result.idObjectType === constant.objectType.avatar)
					.map(avatar => Avatar.factoryMessage(avatar.universalJSON as IAvatarJSON));

				this.handlers = this.avatars.reduce((acc, avatar) => {
					acc[avatar.getPrimaryID()] = this.buildHandlerHexagonon(avatar);
					return acc;
				}, {});

				this.loading = false;
				this.cdr.markForCheck();
				subs.unsubscribe();
			})
		;

		this.searchSvc.search(searchInfo, this.session.getSelectedGroup());
	}

	buildHandlerHexagonon(avatar: Avatar): HandlerHexagonon {
		return HandlerHexagonon.newHandler({
			serializable: avatar,
			size: EHexagonSizes.xs
		});
	}

	peopleFilterChange(event: MatCheckboxChange, avatar: Avatar) {
		if (event.checked) {
			this.selectedAvatars.push(avatar.getPrimaryID());
		} else {
			const index = this.selectedAvatars.indexOf(avatar.getPrimaryID());
			this.selectedAvatars.splice(index, 1);
		}
	}
}
