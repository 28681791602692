import { Location } from '@angular/common';
import { Component, Inject, Input, Optional, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { Serializable } from '@colmeia/core/src/business/serializable';
import { TArrayID } from '@colmeia/core/src/core-constants/barrel-core-constants';
import { IFormSchema, SchemaProperty } from '@colmeia/core/src/general-form/general-form-interface';
import { apiRequestType } from '@colmeia/core/src/request-interfaces/message-types';
import { IBasicAsset, KAssetType, KBAssetType, KBAssetTypeClientOnly, TBasicAssetArray } from '@colmeia/core/src/shared-business-rules/bot/bot-asset-model';
import { EBotContentEvent, IContentBasicAsset } from '@colmeia/core/src/shared-business-rules/bot/bot-content-model';
import { EBotEventType, TBotEventArray } from '@colmeia/core/src/shared-business-rules/bot/bot-event-model';
import { isNLP } from '@colmeia/core/src/shared-business-rules/bot/bot-function-model-helper';
import { ENextGenBotElementType, IConfigureCanonical } from '@colmeia/core/src/shared-business-rules/bot/bot-model';
import { emptyMetaEngagement } from '@colmeia/core/src/shared-business-rules/bot/engagement-function';
import { IServerLocalCanonical } from '@colmeia/core/src/shared-business-rules/canonical-model/local-canonical';
import { ETagType, IServerColmeiaTag, TColmeiaTagArray } from '@colmeia/core/src/shared-business-rules/colmeia-tags/tags';
import { gTranslations } from '@colmeia/core/src/shared-business-rules/const-text/translations';
import {
    EGeneratorTransactionType,
    IBotTransaction,
    INLPTransactionServer,
    ITransactionServer,
    initBotTransaction,
    isNLPTransactionGuard
} from '@colmeia/core/src/shared-business-rules/knowledge-base/bot-transaction/bot-transaction';
import { IBPMIsNLPFromBPMRequest, IBPMIsNLPFromBPMResponse } from '@colmeia/core/src/shared-business-rules/knowledge-base/bpm/bpm-req-resp';
import { IKnowledgeDBServer } from '@colmeia/core/src/shared-business-rules/knowledge-base/kb-inferfaces';
import { EMetaEngagementConfigContext, EMetadataEngagementType } from '@colmeia/core/src/shared-business-rules/metadata/meta-engagement';
import { IVariable, TIVariablesArray } from '@colmeia/core/src/shared-business-rules/metadata/metadata-util-interfaces';
import { ENonSerializableObjectType, INonSerializable } from '@colmeia/core/src/shared-business-rules/non-serializable-id/non-serializable-id-interfaces';
import { IdNS } from '@colmeia/core/src/shared-business-rules/non-serializable-id/non-serializable-types';
import { WAFUtils } from '@colmeia/core/src/shared-business-rules/waf/waf.functions';
import { getPropertyName, isEqual, isInvalid, isValidAndEqual, isValidArray, isValidObject, isValidRef, isValidString, isValidTrimmedString, objectShallowReplace, typedClone } from '@colmeia/core/src/tools/utility';
import { EAppAlertTypes } from 'app/components/alert/alert.component';
import { BotTransactionBaseDataComponentDiagramDialogHandler, BotTransactionBaseDataComponentDiagramDialogParameters } from 'app/components/dashboard/ai-pages/bot-transaction-edit/bot-transaction-edit.handler';
import { BotTransactionVisualizerComponent } from 'app/components/dashboard/bot-transaction-visualizer/bot-transaction-visualizer.component';
import { RootComponent } from 'app/components/foundation/root/root.component';
import {
    CanonicalPickerHandler,
    ICanonicalPickerHandlerClientCallback
} from 'app/handlers/canonical/canonical-picker.handler';
import {
    GenericDashboardEditHandler, IGenericDashboardEditHandlerParameter, IGenericDashboardEditPageClientCallback
} from 'app/handlers/generic-dashboard-edit.handler';
import { IMetaEngagementHandlerData, MetaEngagementHandler } from 'app/handlers/meta-engagement.handler';
import { ENsMoreOptions } from 'app/handlers/ns-more-options.handler';
import { EVarEditorEntityType } from 'app/handlers/var-editor.handler';
import { IAssetAdderHandler } from 'app/model/dashboard/asset-adder.model';
import { BotEventsHandler, IBotEventHandlerParameters } from 'app/model/dashboard/bot/bot-events.handler';
import { routeList } from 'app/model/routes/route-constants';
import { BotTransactionService } from 'app/services/bot-transaction.service';
import { BpmRulesNestedAIService } from 'app/services/bpm/bpm-subject-rules/bpm-rules-nested-ai.service';
import { CanonicalService } from 'app/services/canonical.service';
import { DashBoardService } from 'app/services/dashboard/dashboard.service';
import { GeneralFormService } from 'app/services/general-form.service';
import { GlobalWarningService } from 'app/services/global-warning.service';
import { KnowledgeBaseService } from 'app/services/knowledge-base.service';
import { LookupService } from 'app/services/lookup.service';
import { RoutingService } from 'app/services/routing.service';
import { ServerCommunicationService } from 'app/services/server-communication.service';
import { SnackMessageService } from 'app/services/snack-bar';
import { VariablesService } from 'app/services/variables.service';
import { IRoutedDlgParameters } from 'app/services/window-navigation/window-navigation.service';
import { DashboardWindowEditorRef } from '../../dashboard-foundation/colmeia-window/colmeia-window-edit-ref';
import { ColmeiaWindowRef } from '../../dashboard-foundation/colmeia-window/colmeia-window-ref';
import { TConditionsEditorTargetsList } from '../../new-condition-editor/conditions-editor/conditions-editor.model';
import { IVariableInputHandler } from '../../variable-tags/variable-tags.component';
import { BotTransactionAccuracyDataComponent } from '../bot-transaction-accuracy-data/bot-transaction-accuracy-data.component';
import { BotTransactionAssetsComponent } from '../bot-transaction-assets/bot-transaction-assets.component';
import { BotTransactionBasicDataComponent } from '../bot-transaction-basic-data/bot-transaction-basic-data.component';
import { BotTransactionGenerationDataComponent } from '../bot-transaction-generation-data/bot-transaction-generation-data.component';
import { INSPickerHandlerParameter, NSPickerHandler } from 'app/handlers/ns-picker/ns-picker.handler';
import { useClientPredicates } from '@colmeia/core/src/tools/utility-types';
import { NsTypeToInterface } from '@colmeia/core/src/shared-business-rules/non-serializable-id/non-serializable-interface-mapper';
import { ITagPickerHandlerParameter, ETagPickerHandlerMode, TagPickerHandler } from 'app/handlers/tag-picker-handler';
import { NSSharedService } from '@colmeia/core/src/shared-business-rules/shared-services/services/ns.shared.service';

export interface IBotTransactionRoutedDlg extends IRoutedDlgParameters<ITransactionServer> {
    getParamsToChildComponent?: () => INonSerializable
}

@Component({
    selector: 'bot-transaction-edit',
    templateUrl: './bot-transaction-edit.component.html',
    styleUrls: ['./bot-transaction-edit.component.scss'],
    providers: [
        CanonicalService,
        VariablesService
    ]
})
export class BotTransactionBaseDataComponent extends RootComponent<
    'createTitle' |
    'editTitle' |
    'save' |
    'entityMap' |
    'success'
> {
    //@Input()
    transaction: IBotTransaction;

    private get transactionAsNS(): ITransactionServer {
        return this.transaction as ITransactionServer;
    }

    /**
     * esse handler só é usado quando ele é aberto pelo BPM
     */
    @Input()
    public _handler: BotTransactionBaseDataComponentDiagramDialogHandler;
    public get handler(): BotTransactionBaseDataComponentDiagramDialogHandler {
        return this._handler;
    }
    public set handler(value: BotTransactionBaseDataComponentDiagramDialogHandler) {
        this._handler = value;
    }

    saving: boolean = false;
    showNLP: boolean = false;
    hasWhatsAppFlowsAccess: boolean = false;
    allSchemas: IFormSchema[];
    allKBs: IKnowledgeDBServer[];
    selectedVariableTags: TColmeiaTagArray = [];
    metaEngagementHandler: MetaEngagementHandler;
    variableTagsHandler: IVariableInputHandler;
    transactionEventsHandler: BotEventsHandler;
    canonicalPickerHandler: CanonicalPickerHandler;
    genericEditHandler: GenericDashboardEditHandler;
    posContentHandler: IAssetAdderHandler;
    preContentHandler: IAssetAdderHandler;
    isCreationFromContentGeneratorPage: boolean;
    isInFormCtx: boolean;
    isAtNestedAIBPM: boolean;
    public botLevelCreation: EGeneratorTransactionType;
    public allowPickBotLevel: boolean = false;
    public selectedIndex = 0;
    public expandedIntentsIDs: Array<string> = [];
    public preSelectedValue: KAssetType = undefined;
    public nsPickerSelectedProperty: EGeneratorTransactionType = undefined;
    public prefillAdEventTagNSPicker: NSPickerHandler;
    public postfillAdEventTagNSPicker: NSPickerHandler;

    public get canonicalIds(): string[] { return this.transaction.canonicalIds }
    public set canonicalIds(value: string[]) { this.transaction.canonicalIds = value }
    public get canonicals(): IConfigureCanonical[] { return this.transaction.canonicals }
    public set canonicals(value: IConfigureCanonical[]) { this.transaction.canonicals = value }

    @ViewChild(BotTransactionBasicDataComponent) basicData: BotTransactionBasicDataComponent;
    @ViewChild(BotTransactionGenerationDataComponent) generationData: BotTransactionGenerationDataComponent;
    @ViewChild(BotTransactionAccuracyDataComponent) accuracyData: BotTransactionAccuracyDataComponent;
    @ViewChild(BotTransactionAssetsComponent) assetsData: BotTransactionAssetsComponent;
    @ViewChild(BotTransactionVisualizerComponent) botTransactionVisualizerComponent: BotTransactionVisualizerComponent;

    private allFields: SchemaProperty[] = [];

    constructor(
        private botTransactionSvc: BotTransactionService,
        private location: Location,
        private formSvc: GeneralFormService,
        private kbSvc: KnowledgeBaseService,
        private dashboardSvc: DashBoardService,
        private lookupSvc: LookupService,
        private warningSvc: GlobalWarningService,
        private bpmRulesNestedAIService: BpmRulesNestedAIService,
        private apiSvc: ServerCommunicationService,
        private routingService: RoutingService,
        private snackSvc: SnackMessageService,
        @Optional() private colmeiaWindowRef: ColmeiaWindowRef,
        @Optional() private editor: DashboardWindowEditorRef<ITransactionServer>,
        @Optional() @Inject(MAT_DIALOG_DATA) private data: IBotTransactionRoutedDlg,
    ) {
        super({
            createTitle: gTranslations.bot.createTitle,
            editTitle: gTranslations.bot.editTitle,
            save: gTranslations.common.save,
            entityMap: gTranslations.bot.entityMap,
            success: gTranslations.common.success
        });
    }

    get isEdit(): boolean {
        return this.isOpenInColmeiaWindow() ? !!this.data?.ns?.idNS : this.transaction.nName !== '';
    }

    get transactionIdNS(): string {
        return (this.transaction as ITransactionServer).idNS;
    }

    onGenericBackButtonPressed(): Promise<void> {
        this.goBack();
        return;
    }

    onGenericSaveButtonPressed(): Promise<void> {
        this.save();
        return;
    }

    onSaveClicked(): Promise<void> {
        this.save();
        return;
    }

    private initCanonicalPickerHandler(): void {
        if (isInvalid(this.canonicals)) this.canonicals = initBotTransaction().canonicals;

        this.canonicalPickerHandler = new CanonicalPickerHandler({
            canonicalIds: this.canonicals.map(canonical => canonical.idCanonical),
            canonicalsConfig: this.canonicals,
            clientCallback: <ICanonicalPickerHandlerClientCallback>{
                mapCanonicalToVariable: (ns: IServerLocalCanonical): IVariable => this.mapCanonicalEntity(ns),
            },
            enableCanonicalsConfig: true,
            enableSyncWithCanonicalsService: true,
        })
    }

    public mapCanonicalEntity(ns): IVariable {
        return {
            text: ns.nName,
            idProperty: ns.idNS,
        }
    }


    public getContentGeneratorById(id: string): Promise<ITransactionServer> {
        return this.lookupSvc.getSingleLookupElement<ITransactionServer>(id)
    }

    async ngOnInit() {
        console.log({ data: this.data, ref: this.colmeiaWindowRef })
        if (this.isOpenInColmeiaWindow()) {
            this.isCreationFromContentGeneratorPage = !this.data.ns;
            this.transaction = this.colmeiaWindowRef.data?.getParamsToChildComponent?.() as ITransactionServer || initBotTransaction();
            const handlerParameters: BotTransactionBaseDataComponentDiagramDialogParameters = {
                transaction: this.transactionAsNS,
                clientCallback: this
            }
            this.handler = new BotTransactionBaseDataComponentDiagramDialogHandler(handlerParameters)
            // this.initGenericDashboardEdit();
        } else {
            const entity = this.handler?.getComponentParameter().transaction;

            this.transaction = typedClone(entity) as ITransactionServer;
            // this.initGenericDashboardEdit();

            this.handler.getDialogRef().disableClose = true;
            this.handler.getDialogRef().backdropClick().subscribe(_ => {
                this.requestCloseDialog();
            });
        }

        if (isValidRef(this.handler)) {
            const params = this.handler.getComponentParameter();
            this.selectedIndex = params.selectedTabIndex ?? 0;
            this.expandedIntentsIDs = params.expandedIntentsIDs ?? [];
            this.preSelectedValue = params.preSelectedValue ?? undefined;
            this.nsPickerSelectedProperty = params.nsPickerSelectedProperty ?? undefined;
        }

        this.isInFormCtx = window.location.pathname.includes(routeList.dashboard.children.smartFlow.path);//this.dashboardSvc.currentDashboard === 'smart-flow';
        this.isAtNestedAIBPM = window.location.pathname.includes(routeList.dashboard.children.ai.children.nestedAI.path);

        if (this.isCreationFromContentGeneratorPage) {
            this.botLevelCreation = this.isInFormCtx ? ENextGenBotElementType.formTransaction : ENextGenBotElementType.nlpTransaction;
            this.transaction.botLevel = this.botLevelCreation;
            this.data.ns = this.transactionAsNS;
        } else { // Either editing or creating a content generator inside a BPM
            this.formSvc.getSchemas(this.dashboardSvc.defaultTag).then(schemas => { this.allSchemas = schemas; });
            this.kbSvc.getBases().then(bases => { this.allKBs = bases; });
            if (isValidArray(this.transaction.variableTags)) {
                this.selectedVariableTags = await this.lookupSvc.getBatchNonSerializables<IServerColmeiaTag[]>(this.transaction.variableTags);
            }
        }
        await this.initGenericDashboardEdit();
        this.showNLP = !this.isColmeiaForms();

        this.checkWhatsAppFlowsAccess().then(hasAccess => {
            this.hasWhatsAppFlowsAccess = hasAccess;
        });

        this.initHandlers();
    }

    get canShowCreate() {
        return this.isEdit && !!this.botLevelCreation
    }

    private _isValidDatabaseNS: boolean = false;
    private get isValidDatabaseNS(): boolean {
        return this._isValidDatabaseNS;
    }
    private set isValidDatabaseNS(value: boolean) { 
        this._isValidDatabaseNS = value;
    }

    public onIsValidDatabasePickerUpdate(event: boolean) {
        this.isValidDatabaseNS = event;
    }
    private isValidDatabaseNSRules(): boolean {
        const isValidWithoutIdDatabase: boolean = isValidTrimmedString(this.transaction.idSchemma)
            && !isValidTrimmedString(this.transaction.idDatabaseContentGenerator);
        
        return this.isValidDatabaseNS
            || isValidWithoutIdDatabase;
    }

    public async initGenericDashboardEdit(): Promise<void> {
        const parameter: IGenericDashboardEditHandlerParameter = {
            nsType: ENonSerializableObjectType.contentGenerator,
            autoValidate: true,
            posValidation: () => this.isValidDatabaseNSRules(),
            posValidationErrMsg: 'Não é possível salvar o Colmeia Forms com um banco de dados que não está corretamente configurado.',
            clientCallback: <IGenericDashboardEditPageClientCallback>{
                onGenericSaveButtonPressed: () => {
                    return this.save();
                },
                onGenericBackButtonPressed: () => {
                    this.requestCloseDialog();
                },
            },
            nser: this.transaction,
            allowEditTitleInHeader: true,
            moreOptions: {
                [ENsMoreOptions.CopyID]: true,
                [ENsMoreOptions.CheckDependencies]: true,
                [ENsMoreOptions.Archive]: false
            },
            disableSNCheck: true,
            isWindowMode: this.isOpenInColmeiaWindow(),
            dialogRef: this.colmeiaWindowRef,
            replaceEditTitle: this.getTitle(),
        }
        const isEditingNLPOutsideNestedAIBPM = !this.isAtNestedAIBPM && isNLP(this.transaction.botLevel);
        const originBPMData = isEditingNLPOutsideNestedAIBPM && await this.isNLPFromBPM(this.transactionIdNS);
        if (isEditingNLPOutsideNestedAIBPM && isValidString(originBPMData.nName) && isValidString(originBPMData.idNS)) {
            parameter.removeSaveButton = true;
            parameter.alert = {
                type: EAppAlertTypes.Information,
                message: `Este gerador é usado em um BPM. Para edita-lo acesse o  BPM ${originBPMData.nName}.`,
                action: "Acessar",
                actionCallback: () => this.goToBPM(originBPMData.idNS)
            }
        }
        this.genericEditHandler = new GenericDashboardEditHandler(parameter);
    }

    getTitle() {
        return this.isColmeiaForms() ? Serializable.getTranslation(gTranslations.bot.availableInfoText)! : Serializable.getTranslation(gTranslations.nlp.availableInfoText)
    }

    isColmeiaForms() {
        return this.transaction.botLevel === ENextGenBotElementType.formTransaction
    }

    private initHandlers() {

        this.initVariableTagHandler();

        if (isInvalid(this.transaction.engagement)) {
            this.transaction.engagement = emptyMetaEngagement();
            this.editor?.syncObjects();
        }

        this.buildEngagementHandler();

        this.buildPrePosContentHandlers();

        this.initEventsHandler();
        this.initCanonicalPickerHandler();
        this.initMarketingEventsPerfNSPickers();
    }

    buildPrePosContentHandlers() {
        this.posContentHandler = this.getAssetContentHandler(EBotContentEvent.posContent);
        this.preContentHandler = this.getAssetContentHandler(EBotContentEvent.preContent);
    }

    eventsPrePos(isPos: EBotContentEvent) {
        this.transaction.events = this.transaction.events || [];
        return this.transaction.events.filter(asset => isValidAndEqual((<IContentBasicAsset>asset).botContentType, isPos));
    }

    public getAssetAdderVariables() {
        return {
            [EVarEditorEntityType.NonSerializable]: this.selectedVariableTags.map(variable => ({ idProperty: variable.idNS, variable: undefined })),
        };
    }

    public getSchemaVariables(): TIVariablesArray {
        return this.selectedVariableTags.map(variable => ({ idProperty: variable.idNS, value: variable.value, text: variable.nName }))
    }

    private getAssetContentHandler(contentEvent: EBotContentEvent): IAssetAdderHandler {
        const enableChangePositions: boolean = EBotContentEvent.preContent === contentEvent;

        const additionalConditionsTargets: TConditionsEditorTargetsList = contentEvent === EBotContentEvent.posContent
            ? [
                ...this.allFields.map(field => ({ targetId: field.idProperty, targetLabel: field.prompt }))
            ]
            : [];

        return {
            additionalConditionsTargets,
            enableChangePositions,
            assets: this.eventsPrePos(contentEvent),
            assetTypesEnabled: [...Object.values(KBAssetType), KBAssetTypeClientOnly.sticker],
            shouldAskMediaDisplayName: true,
            removeAsset: (index: number, assets: IBasicAsset[]) => {
                const asset: IBasicAsset = assets[index];
                this.removeAssetContent(<IContentBasicAsset>asset);
                this[this.preOrPosProperty(contentEvent)].assets = this.eventsPrePos(contentEvent);
                return Promise.resolve(true);
            },
            saveAsset: (newAsset: IBasicAsset, assets: TBasicAssetArray) => {
                const contentBasicNewAsset = newAsset as IContentBasicAsset
                contentBasicNewAsset.botContentType = contentEvent;
                this.setAssetContent(contentBasicNewAsset);
                this[this.preOrPosProperty(contentEvent)].assets = this.eventsPrePos(contentEvent);
                return Promise.resolve(true);
            },
            onChangeAssetsPositions: (assets: TBasicAssetArray): void => {
                this.removeEventsFromEvents(assets);
                this.insertEventsOnEvents(assets);
            }
        }
    }

    public removeEventsFromEvents(removeAssets: TBasicAssetArray): void {
        const mapEventToKeep: Map<IBasicAsset, boolean> = new Map();
        const events: TBasicAssetArray = this.transaction.events;

        for (const removeAsset of removeAssets) {
            if (!mapEventToKeep.has(removeAsset)) {
                mapEventToKeep.set(removeAsset, false);
            }
        }

        for (const event of events) {
            if (!mapEventToKeep.has(event)) {
                mapEventToKeep.set(event, true);
            }
        }

        events.splice(0);
        events.push(...[...mapEventToKeep.keys()].filter((event: IBasicAsset) => mapEventToKeep.get(event)));
    }

    public insertEventsOnEvents(assets: TBasicAssetArray): void {
        const events: TBasicAssetArray = this.transaction.events;
        events.push(...assets);
    }


    public preOrPosProperty(botContentType: EBotContentEvent) {
        return botContentType === EBotContentEvent.posContent ? this.pickAssetAdderHandlerProperty('posContentHandler') : this.pickAssetAdderHandlerProperty('preContentHandler');
    }

    public pickAssetAdderHandlerProperty = getPropertyName<BotTransactionBaseDataComponent, IAssetAdderHandler>();

    removeAssetContent(asset: IContentBasicAsset) {
        const idx = this.transaction.events.findIndex((ev) => ev.idAsset === asset.idAsset);
        this.transaction.events.splice(idx, 1);
    }

    setAssetContent(asset: IContentBasicAsset) {
        const idx = this.transaction.events.findIndex((ev) => ev.idAsset === asset.idAsset);
        if (idx === -1) {
            this.transaction.events.push(asset)
        } else {
            this.transaction.events[idx] = (asset);
        }
        this.transaction.events
    }

    buildEventsHandler(): BotEventsHandler {
        const events = <TBotEventArray>this.transaction.events || <TBotEventArray>[];
        let idKB = null;

        if (isNLPTransactionGuard(this.transaction) && this.transaction.nlp?.idKB) { idKB = this.transaction.nlp.idKB };

        return new BotEventsHandler(<IBotEventHandlerParameters>{
            branchType: <ENextGenBotElementType>this.transaction.botLevel,
            events,
            idKB,
        });
    }

    initEventsHandler() {
        this.transactionEventsHandler = null;
        this.transactionEventsHandler = this.buildEventsHandler();
    }

    private get nlpTransaction(): INLPTransactionServer {
        return <INLPTransactionServer>this.transaction;
    }

    buildEngagementHandler() {
        if (!this.transaction.idSchemma || !this.transaction.engagement) return;

        const title: string = this.translations.entityMap.value;
        let data: IMetaEngagementHandlerData = {};

        const isFormTransaction = this.isColmeiaForms();

        switch (this.transaction.botLevel) {
            case ENextGenBotElementType.nlpTransaction:
                if (this.nlpTransaction.nlp) {
                    data.idKnowledgeBase = this.nlpTransaction.nlp.idKB;
                }
                break;
            default:
                break;
        }

        const allowedEngagementTypes: EMetadataEngagementType[] = isFormTransaction
            ? [
                EMetadataEngagementType.smartMapper,
                EMetadataEngagementType.richFillForm,
                EMetadataEngagementType.CRMTransformation,
                EMetadataEngagementType.NewCondition,
                EMetadataEngagementType.layout
            ]
            : [EMetadataEngagementType.entityMapper];

        const allowedEngagementConfigTypes: EMetadataEngagementType[] = isFormTransaction
            ?
            [
                EMetadataEngagementType.CRMTransformation,
                EMetadataEngagementType.mktEvents,
                EMetadataEngagementType.NewCondition,
                EMetadataEngagementType.integration,
                EMetadataEngagementType.solicitation
            ]
            : [EMetadataEngagementType.NewCondition, EMetadataEngagementType.mktEvents]

        this.metaEngagementHandler = new MetaEngagementHandler({
            clientCallback: {},
            idSchema: this.transaction.idSchemma,
            metaEngagement: this.transaction.engagement,
            allowedEngagementConfigTypes,
            title,
            data,
            includeCanonical: false,
            allowedEngagementTypes,
            context: EMetaEngagementConfigContext.ColmeiaForm,
        });
    }

    goBack() {
        this.location.back();
    }

    private async requestCloseDialog() {
        if (this.hasChange()) {
            let result = await this.warningSvc.confirmLeaveWithoutSave();

            if (!result) {
                return;
            }
        }

        this.handler.getDialogRef()?.close();
        this.colmeiaWindowRef?.close();
    }

    async save(): Promise<boolean> {
        if (!(await this.clientValidate())) {
            return;
        }

        if (this.hasChange()) {
            for (let event of this.nlpTransaction.events) {
                if (isValidObject((<IContentBasicAsset>event).variablesTemplate)) {
                    for (let variable of (<IContentBasicAsset>event).variablesTemplate.variables) {
                        variable.value = undefined
                    }
                }
            }
        }

        if (this.isEdit && !this.showNLP && isValidRef(this.nlpTransaction.nlp)) {
            this.nlpTransaction.nlp = undefined;
            // delete this.nlpTransaction.nlp;
            /**
             * Evite usar "delete" *quando possível*. Pesquise sobre como o v8 otimiza o trabalho com objetos(Shapes) @ https://mathiasbynens.be/notes/shapes-ics
             * Basicamente o "delete" cria a necessidade de uma chacagem do shape.
             * Assimilando um valor "undefined" temos o resultado que esperamos e evitamos essa checagem.
             */
        }
        const savedNS = await this.botTransactionSvc.save(this.transaction);
        const success: boolean = isValidRef(savedNS);

        if (success) {
            this.isCreationFromContentGeneratorPage = false;
            objectShallowReplace(this.transaction, savedNS);

            this.editor?.syncObjects();
            this.initHandlers();
        }

        if (!this.isOpenInColmeiaWindow()) {
            this.handler.getDialogRef().close({ userHasClickedSave: true, nonSerializable: this.transactionAsNS });
        } else {
            this.colmeiaWindowRef?.close(savedNS);
        }

        return success;
    }

    onTransactionTypeChange(type: EGeneratorTransactionType) {
        this.transaction.botLevel = type;
        this.initEventsHandler();
        this.showNLP = type !== ENextGenBotElementType.formTransaction;
        // const engagementType = this.showNLP ? EMetadataEngagementType.entityMapper : EMetadataEngagementType.smartMapper;
        this.transaction.engagement = emptyMetaEngagement();
        this.buildEngagementHandler();
    }

    onSchemaChange(idSchema: string) {
        if (this.metaEngagementHandler) {
            this.metaEngagementHandler.setIdSchema(idSchema);
        } else {
            this.initHandlers();
        }

        this.resetWhatsappFlowsEngagementConfig();
    }

    onKnowledgeBaseChange(idKnowledgedBase: string) {
        if (this.metaEngagementHandler) {
            this.metaEngagementHandler.setIdKnowledgeBase(idKnowledgedBase);
        }
        const events = <TBotEventArray>this.transaction.events || <TBotEventArray>[];
        this.transaction.events = events.filter(event => event.type !== EBotEventType.accuracyEvent);
        this.initHandlers();
    }

    get variableTagsIDs(): TArrayID {
        return this.transaction.variableTags;
    }

    private initVariableTagHandler() {
        this.variableTagsHandler = {
            selectedVariableTags: this.selectedVariableTags,
            onUpdateSelectedVariables: () => {
                this.transaction.variableTags = [];
                this.selectedVariableTags.map(tag => {
                    this.transaction.variableTags.push(tag.idNS);
                });
                this.updateAssetsVariables();
            },
            canCopyVariable: true,
            syncWithVariablesService: true
        }

    }

    private updateAssetsVariables(): void {
        if (isValidRef(this.assetsData)) {
            this.assetsData.variableTags = this.selectedVariableTags;
        }

        this.buildPrePosContentHandlers();
        this.initEventsHandler();
    }

    private nplSychronized: boolean = false;

    public consistencyMetagagement() {
        if (!this.nplSychronized) {
            this.editor?.syncObjects();
            this.nplSychronized = true;
        }
    }

    onAllFields($event: SchemaProperty[]) {
        this.allFields = $event;
        this.buildPrePosContentHandlers();
    }

    onTabChange(event: MatTabChangeEvent) {
        if (event.tab.labelClass === 'visualizer') {
            this.botTransactionVisualizerComponent.init();
        }
        this.selectedIndex = event.index;
    }

    private isOpenInColmeiaWindow(): boolean {
        return isValidRef(this.colmeiaWindowRef);
    }

    private hasChange(): boolean {
        if (this.isOpenInColmeiaWindow()) {
            return !isEqual(this.transactionAsNS, this.handler.getComponentParameter().transaction);
        } else {
            return !isEqual(this.transactionAsNS, this.handler.getComponentParameter().transaction);
        }
    }

    private async isNLPFromBPM(idNS: IdNS<ITransactionServer>) {
        const response = await this.apiSvc.safeSendRequest<IBPMIsNLPFromBPMRequest
            , IBPMIsNLPFromBPMResponse>(apiRequestType.dashboardExtra.ai.isNLPFromBPM)({
                idHostedObject: idNS
            });
        return response;
    }

    private goToBPM(idBPM: string) {
        this.colmeiaWindowRef?.close();
        this.routingService.goToNestedAIBPM(idBPM);
    }

    async checkWhatsAppFlowsAccess(): Promise<boolean> {
        const { isAllowed } = await this.dashboardSvc.getRouteAccessInfo(
            routeList.dashboard.children.smartFlow.path,
            routeList.dashboard.children.smartFlow.children.whatsappFlows.path
        );

        return isAllowed;
    }

    private resetWhatsappFlowsEngagementConfig() {
        const flowsEngagement = WAFUtils.getFlowsEngagementConfig(this.transaction.engagement);

        if (isValidRef(flowsEngagement)) {
            flowsEngagement.flowAreas = [];
        }
    }

    /**
     * validações só do client (existe outra no core)
     */
    private async clientValidate(): Promise<boolean> {
        // validacoes antigas foram removidas

        return true;
    }

    prefillAdEventTagPickerHandler: TagPickerHandler;
    postfillAdEventTagPickerHandler: TagPickerHandler;

    async initMarketingEventsPerfNSPickers() {

        const dataObj = this.transactionAsNS;
        const common: Omit<ITagPickerHandlerParameter, 'clientCallback'|'selectedTags'> = {
            tagType: ETagType.marker,
            mode: ETagPickerHandlerMode.edit,
            compact: false,
            canSelectGroupTag: true,
            allowSearchOnEditMode: true,
            maxSelections: 1,
        }

        const prefilledSelected: IServerColmeiaTag[] = [];
        if (dataObj.idPrefillAdEventTag) {
            const ns = await NSSharedService.getById(dataObj.idPrefillAdEventTag);
            if (ns) prefilledSelected.push(ns as IServerColmeiaTag);
        }

        const postfilledSelected: IServerColmeiaTag[] = [];
        if (dataObj.idPostfillAdEventTag) {
            const ns = await NSSharedService.getById(dataObj.idPostfillAdEventTag);
            if (ns) postfilledSelected.push(ns as IServerColmeiaTag);
        }

        this.prefillAdEventTagPickerHandler = new TagPickerHandler({
            ...common,
            selectedTags: prefilledSelected,
            clientCallback: {
                onClear: () => {
                    dataObj.idPrefillAdEventTag = undefined;
                },
                onFinishSelectionCallback: (selectedTags: any[]) => {
                    dataObj.idPrefillAdEventTag = isValidString(selectedTags?.[0]?.idNS) ? selectedTags[0].idNS! : undefined;
                },
            },
        });

        this.postfillAdEventTagPickerHandler = new TagPickerHandler({
            ...common,
            selectedTags: postfilledSelected,
            clientCallback: {
                onClear: () => {
                    dataObj.idPostfillAdEventTag = undefined;
                },
                onFinishSelectionCallback: (selectedTags: any[]) => {
                    dataObj.idPostfillAdEventTag = isValidString(selectedTags?.[0]?.idNS) ? selectedTags[0].idNS! : undefined;
                },
            },
        });
    }
}
