import { constant } from "../../../business/constant";
import { suggestions } from "../../../tools/type-utils";
import { IdField, typedFields, typedScreenOptions } from "../../../tools/utility";
import { EContractStatus, ECostEvent, ENLPProvider, EColmeiaProductCategory, ETypeServiceProvider, EAportionType } from "../../billing/billing-ns-model";
import { EAllSerializable, getTranslationsFields } from "../all-serializables";

export const ECostEventTranslations: Record<ECostEvent, string> = {
    [ECostEvent.TextToSpeech]: 'Text-to-speech',
    [ECostEvent.attendentMAU]: 'Qtd de agentes',
    [ECostEvent.biUser]: 'Usuários de BI',
    [ECostEvent.blobStorage]: 'Mídia Storage',
    [ECostEvent.bpmMarketing]: 'Marketing BPM',
    [ECostEvent.corporateSearch]: 'Corporate search',
    [ECostEvent.corporateSearchElastic]: 'Corporate search (ElasticSearch)',
    [ECostEvent.dwAccess]: 'Acesso data warehouse',
    [ECostEvent.dwProcessing]: 'Processamento data warehouse',
    [ECostEvent.gcpProject]: 'Projeto do Cliente no GCP',
    [ECostEvent.homologEnvironment]: 'Ambiente homologação',
    [ECostEvent.hours]: 'Horas (manual)',
    [ECostEvent.humanService]: 'Logins dos agentes',
    [ECostEvent.map]: 'Google maps',
    [ECostEvent.mktMessage]: 'Marketing mensagem',
    [ECostEvent.msgCountRecepetiveAndActive]: 'Mensagens receptivas + ativas',
    [ECostEvent.omniSenseConversation]: 'Omnisense conversação',
    [ECostEvent.omniSenseMessage]: 'Omnisense mensagem',
    [ECostEvent.others]: 'Outros (manual)',
    [ECostEvent.premiumSupport]: 'Suporte premium',
    [ECostEvent.reportUsage]: 'Relatório Uso',
    [ECostEvent.smartFlowAttMessage]: 'Smartflow atendimento',
    [ECostEvent.smartFlowAttMsgBotMsgCustomerMsg]: 'Msgs recebidas de usuario + msgs bot para cliente + msgs de agente pro cliente',
    [ECostEvent.smartFlowBotMessage]: 'Mensagens Outbound',
    [ECostEvent.smartFlowConversation]: 'Smartflow conversação',
    [ECostEvent.smartFlowCustomerMessage]: 'Smartflow mensagem do cliente',
    [ECostEvent.smartFlowMAU]: 'Smartflow MAU',
    [ECostEvent.speechToText]: 'Speech-to-text',
    [ECostEvent.storage]: 'Armazenamento',
    [ECostEvent.support]: 'Suporte básico',
    [ECostEvent.mktMessageMAU]: 'Disparos de Marketing Targets Únicos MAU',
    [ECostEvent.sumPercent]: 'Percentual de licenciamento',
    [ECostEvent.sumItensQuantity]: 'Somatório de itens',
}

export const productsScreenOptions = typedScreenOptions({
    idSerializable: EAllSerializable.ColmeiaProductsView,
    fields: typedFields<EColmeiaProductCategory | ECostEvent | EContractStatus | ETypeServiceProvider | ENLPProvider | EAportionType>()({
        [EColmeiaProductCategory.SaaS]: 1,
        [EColmeiaProductCategory.premiumSupport]: 2,
        [EColmeiaProductCategory.professionalServices]: 3,
        [ECostEvent.reportUsage]: 4,
        [ECostEvent.smartFlowConversation]: 5,
        [ECostEvent.smartFlowMAU]: 6,
        [ECostEvent.smartFlowCustomerMessage]: 7,
        [ECostEvent.humanService]: 9,
        [ECostEvent.attendentMAU]: 10,
        [ECostEvent.omniSenseConversation]: 11,
        [ECostEvent.omniSenseMessage]: 12,
        [ECostEvent.mktMessage]: 13,
        [ECostEvent.bpmMarketing]: 14,
        [ECostEvent.corporateSearch]: 15,
        [ECostEvent.corporateSearchElastic]: 16,
        [ECostEvent.storage]: 17,
        [ECostEvent.dwProcessing]: 18,
        [ECostEvent.map]: 19,
        [ECostEvent.TextToSpeech]: 20,
        [ECostEvent.speechToText]: 21,
        [ECostEvent.homologEnvironment]: 22,
        [ECostEvent.support]: 23,
        [ECostEvent.premiumSupport]: 24,
        [ECostEvent.dwAccess]: 25,
        [ECostEvent.hours]: 26,
        [ECostEvent.others]: 27,
        [ECostEvent.biUser]: 28,
        [EContractStatus.active]: 29,
        [EContractStatus.inactive]: 30,
        [EContractStatus.canceled]: 31,
        [ETypeServiceProvider.nlp]: 32,
        [ETypeServiceProvider.communication]: 33,
        [ENLPProvider.Microsoft]: 34,
        [ENLPProvider.Colmeia]: 35,
        [ECostEvent.smartFlowAttMessage]: 36, // Smartflow atendimento
        [ECostEvent.smartFlowBotMessage]: 37, // Mensagens Outbound
        [ECostEvent.smartFlowAttMsgBotMsgCustomerMsg]: 38,
        [EColmeiaProductCategory.incidentsCosts]: 39,
        [EColmeiaProductCategory.messenger]: 40,
        [EColmeiaProductCategory.infra]: 41,
        [ECostEvent.msgCountRecepetiveAndActive]: 42,
        [ECostEvent.gcpProject]: 43,
        // [ECostEvent.sms]: 43,
        [ECostEvent.blobStorage]: 45,
        [EAportionType.events]: 46,
        [EAportionType.ratio]: 47,
        [ECostEvent.mktMessageMAU]: 48,
        [ECostEvent.sumPercent]: 49,
        [ECostEvent.sumItensQuantity]: 50,
    })
} as const);

const productsMenuTranslations = getTranslationsFields(typedScreenOptions({
    idSerializable: EAllSerializable.ColmeiaProducts,
    fields: suggestions<{ [key in string]: IdField }>()({
        products: constant.serializableField.name,
        productsIcon: constant.serializableField.icon,
    })
} as const));

export const colmeiaProductsTranslations = {
    ...getTranslationsFields(productsScreenOptions),
    ...productsMenuTranslations,
}
