<div 
  class="ordenable-list"
  [ngClass]="[hideBorderClass, smallSizeClass]">

  <app-ns-picker *ngIf="nsSelectHandler" class="canonical-ns-picker" [handler]="nsSelectHandler"></app-ns-picker>

  <div 
    class="ordenable-item ordenable-header"
    [ngClass]="[hideBorderClass, smallSizeClass]">
    <div 
      class="ordenable-item-content title-row"
      [ngClass]="[hideBorderClass, smallSizeClass]">
      <div 
        class="row-title"
        [ngClass]="[hideBorderClass, smallSizeClass]">
          {{ isSmallSize ? 'Clique aqui para adicionar ' : translations.variables }}
      </div>      
  </div>
  <div 
    class="ordenable-item-actions add-button-wrapper"
    [ngClass]="[hideBorderClass, smallSizeClass]">
    <ng-container
      *ngIf="shouldHideBorder"
      class="hide-borders-button">
      <button
        *ngIf="allowEdit"
        class="add-canonical-button"
        mat-icon-button
        color="primary"
        [disabled]="disableAddMoreVariables()"
        (click)="openFieldAdder()"
        [matTooltip]="disableAddMoreVariables() ? 'Não é permitido adicionar mais variavéis.' : 'Adicionar'"
        matTooltipPosition="above"
        [ngClass]="[hideBorderClass, smallSizeClass]">
        <mat-icon
          class="custom-add-icon"
          [ngClass]="[hideBorderClass, smallSizeClass]" 
          style="margin: 0">add
        </mat-icon>
      </button>
    </ng-container>
    <ng-container
      *ngIf="!shouldHideBorder">
      <button
        *ngIf="allowEdit"
        class="add-canonical-button"
        mat-stroked-button
        color="primary"
        [disabled]="disableAddMoreVariables()"
        (click)="openFieldAdder()"
        [matTooltip]="disableAddMoreVariables() ? 'Não é permitido adicionar mais variavéis.' : 'Adicionar'"
        matTooltipPosition="above"
        [ngClass]="[hideBorderClass, smallSizeClass]">
        <mat-icon
          class="custom-add-icon"
          [ngClass]="[hideBorderClass, smallSizeClass]" 
          style="margin: 0">add
        </mat-icon>
      </button>
    </ng-container>
  </div>
  </div>
  <hr 
    *ngIf="shouldHideBorder && !isSmallSize"
    class="custom-hr">

  <div 
    class="items-container nice-scroll"
    [ngClass]="[hideBorderClass, smallSizeClass]">
    <div 
      *ngFor="let variable of possibleTemplateVariables; trackBy:canonicalTrackBy"  class="ordenable-item"
      [ngClass]="[hideBorderClass, smallSizeClass]">
      <div 
        class="ordenable-item-content"
        [ngClass]="[hideBorderClass, smallSizeClass]">
        <div class="row-title">{{ variable.text }}</div>
      </div>
      <div class="ordenable-item-actions">  
        <app-canonical-picker-edit-canonical-config
          *ngIf="parameters.enableCanonicalsConfig && getCanonicalConfigHandler(variable.idProperty)"
          [isSmallSize]="isSmallSize"
          [handler]="getCanonicalConfigHandler(variable.idProperty)">
        </app-canonical-picker-edit-canonical-config>
        <button
          *ngIf="allowEdit && canRemove(variable.idProperty)"
          mat-icon-button
          color="primary"
          matTooltip="Remover"
          (click)="removeVariable(variable.idProperty)"
          style="height: 40px; width: 40px; display: flex; align-items: center; justify-content: center; padding: 0;"
          >
          <mat-icon
            class="delete-icon"
            [ngClass]="[hideBorderClass, smallSizeClass]">
            delete
          </mat-icon>
        </button>


        <button
          *ngIf="enableCopy"
          mat-icon-button
          color="primary"
          matTooltip="Copiar"
          (click)="copyVariable(variable)">
          <mat-icon>
            copy_all
          </mat-icon>
        </button>
      </div>
    </div>
  </div>

</div>
