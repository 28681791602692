import { Component, Input, OnInit } from '@angular/core';
import { EMessageRecency } from "@colmeia/core/src/shared-business-rules/bot/bot-content-model";
import { ENextGenBotElementType, getInitialActionWithId, IBotElement, IBotMenuItemServer, IBotRootServer } from '@colmeia/core/src/shared-business-rules/bot/bot-model';
import { EBotActionType } from "@colmeia/core/src/shared-business-rules/bot/new-bot-action";
import { EBPMAction } from "@colmeia/core/src/shared-business-rules/BPM/bpm-action-model";
import { ENonSerializableObjectType, INonSerializable } from '@colmeia/core/src/shared-business-rules/non-serializable-id/non-serializable-id-interfaces';
import { isInvalid, isValidFunction, isValidRef } from '@colmeia/core/src/tools/utility';
import { DashBoardService } from 'app/services/dashboard/dashboard.service';
import { IBotActionHandler } from "../../../bot-action-editor/bot-action-editor.component";
import {
    NewConditionEditorHandler,
    NewConditionEditorParameter
} from "../../../new-condition-editor/new-condition-editor.component";

import { getBotAction, TBotActionTypeConfig } from '@colmeia/core/src/shared-business-rules/bot/bot-action-config-db';
import { ESourceOfInfo } from '@colmeia/core/src/shared-business-rules/metadata/meta-engagement';
import { NSPickerHandler } from 'app/handlers/ns-picker/ns-picker.handler';


@Component({
    selector: 'app-bot-action',
    templateUrl: './bot-action.component.html',
    styleUrls: ['./bot-action.component.scss']
})
export class BotActionComponent implements OnInit {
    private _handler: IBotActionHandler;

    public posActionBotHandler: IBotActionHandler;
    public targetElementNS: IBotElement;

    constructor(
        private dashboardSvc: DashBoardService
    ) { }

    @Input()
    set handler(handler: IBotActionHandler) {
        if (!handler) return;

        this._handler = handler;

        this.initPosActionHandler();

        if (this.useConditions) {
            this.buildConditionHandler();
        }
    }

    get handler(): IBotActionHandler {
        return this._handler;
    }

    ngOnInit() {
        this.handler.trackerFieldLocation = 'bottom'
    }

    get canHasCondition(): boolean {
        return !this.handler.actionHost || this.handler.actionHost.botLevel !== ENextGenBotElementType.root
    }

    get useConditions(): boolean {
        return this._handler && this._handler.botAction.recency === EMessageRecency.conditional;
    }

    set useConditions(val: boolean) {
        this._handler.botAction.recency = val ? EMessageRecency.conditional : EMessageRecency.everyTime;
        val ? this.buildConditionHandler() : this.resetConditionsAndConditionHandler();
    }

    get showConditions(): boolean {
        return this.useConditions && isValidRef(this.conditionHandler);
    }

    public getBotActionConfig(): TBotActionTypeConfig {
        return getBotAction(this._handler.botAction.type);
    }

    get canConfigurePosAction(): boolean {
        const botConfig: TBotActionTypeConfig = this.getBotActionConfig();
        return botConfig?.allowedPosAction?.[this.targetElementNS?.botLevel]?.parentTypes.includes(this.actionHostAsRoot?.botLevel);
    }

    get actionHostAsRoot(): IBotRootServer {
        return this.handler.actionHost as IBotRootServer;
    }

    get hasPosAction(): boolean {
        return !!this.actionHostAsRoot?.posAction;
    }

    private initPosActionHandler() {
        if (!this.hasPosAction) return

        const ignoredNSsIds: string[] = [
            this.actionHostAsRoot.idNS
        ];
        const botConfig: TBotActionTypeConfig = this.getBotActionConfig();
        const actionTypes: EBotActionType[] = botConfig?.allowedPosAction?.[this.targetElementNS?.botLevel]?.allowedActions;

        this.posActionBotHandler = {
            actionTypes,
            botAction: this.actionHostAsRoot.posAction,
            actionTitle: 'Selecione uma ação',//this.translations.optionAction.value,
            isEventAction: false,
            idCurrentBot: this.actionHostAsRoot.idNS,
            // trackerFieldLocation: 'top',
            nsPickerFilter: (NS) => {
                if (isValidFunction(this._handler.nsPickerFilter)) {
                    return this._handler.nsPickerFilter(NS) || !ignoredNSsIds.includes(NS.idNS)
                }

                return !ignoredNSsIds.includes(NS.idNS);
            }
        }
    }

    private resetConditionsAndConditionHandler(): void {
        this.handler.botAction.advancedConditions = [];
        this.conditionHandler = undefined;
    }

    conditionHandler: NewConditionEditorHandler;
    private buildConditionHandler(): void {
        if (isInvalid(this.handler.botAction.advancedConditions) && this.handler.botAction.recency === EMessageRecency.conditional) {
            this.handler.botAction.advancedConditions = [];
        }
        // const parameter: IConditionEditorParameter = {
        //     clientCallback: undefined,
        //     current: this.handler.botAction.conditions,
        //     basedOn: ECondtionalAction.canonicalBased,
        //     allowedConditionTypes: {
        //         [EMetaEngagementConditionType.literal]: true,
        //         [EMetaEngagementConditionType.smaller]: true,
        //         [EMetaEngagementConditionType.greater]: true,
        //         [EMetaEngagementConditionType.regex]: true,
        //         [EMetaEngagementConditionType.list]: true,
        //         [EMetaEngagementConditionType.interval]: true,
        //     }
        // };
        const parameter: NewConditionEditorParameter = {
            field: undefined, // @TODO Dann checar isto
            fields: [], //@TODO Dann checar isto
            clientCallback: undefined,
            allowedActionTypes: {
                [EBPMAction.action]: {
                    allowedActions: [
                        EBotActionType.goHuman,
                        EBotActionType.isEnd,
                        EBotActionType.goBot,
                        EBotActionType.contentGenerator,
                        EBotActionType.goActionTree,
                    ]
                }
            },
            currentConditions: this.handler.botAction.advancedConditions,
            allowedSourceInfo: [
                ESourceOfInfo.canonical,
                ESourceOfInfo.bot,
                ESourceOfInfo.metrics,
                ESourceOfInfo.serviceScheduler,
            ]
        }
        this.conditionHandler = new NewConditionEditorHandler(parameter);
    }

    targetElementLoaded(ns: INonSerializable) {
        this.targetElementNS = ns as unknown as IBotElement;
        this.initPosActionHandler();

        if (this.hasPosAction && !this.canConfigurePosAction) {
            this.actionHostAsRoot.posAction = undefined;
        }
    }

    onActionChange() {
        if (this.canConfigurePosAction) {
            this.initPosActionHandler();
        } else if (this.hasPosAction && !this.canConfigurePosAction) {
            this.actionHostAsRoot.posAction = undefined;
        }
    }

    handlePosActionActive(active: boolean) {
        if (active) {
            this.actionHostAsRoot.posAction = getInitialActionWithId();
            this.initPosActionHandler();
        } else {
            this.actionHostAsRoot.posAction = undefined;
        }
    }

    onTargetRemoved() {
        if (isValidRef(this.actionHostAsRoot?.posAction)) {
            this.actionHostAsRoot.posAction = undefined;
        }
    }
}
