<section class="chat-date-filter">

  <section class="date-fields-container">
    <section class="date-field">
      <div class="date-title">{{ translations.start }}</div>
      <div class="date-picker-container">
          <cm-date-picker [handler]="minHandler"></cm-date-picker>
      </div>
    </section>

    <section class="date-field">
      <div class="date-title">{{ translations.finish }}</div>
      <div class="date-picker-container">
          <cm-date-picker [handler]="maxHandler"></cm-date-picker>
      </div>
    </section>

  </section>


</section>

<section class="reset">
  <button class="reset-button" (click)="resetDates()">{{ translations.resetDates }}</button>
</section>
