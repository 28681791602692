import { WhatsAppFunctions } from "@colmeia/core/src/shared-business-rules/social-media/whatsapp-functions";
import { WhatsAppValidators } from "@colmeia/core/src/shared-business-rules/social-media/whatsapp-validators";
import { asConst } from "../../tools/type-utils";
import { values } from "../../tools/utility";
import { $string, SetAsCaseInsensitive, UnionToIntersection, ValueOf } from "../../tools/utility-types";
import { Guard } from "../../tools/utility/functions/guard";
import { StringNumber } from "../../tools/utility/types/basic";
import { Merger } from "../../tools/utility/types/entities/merger";
import { Meta } from "../catalog/meta-interface";
import { EWAFOnSubmit, IWACTAActionParameter, IWAFActionParameter } from "../waf/waf.types";
import { IntersectPartialExplicit, MapDiscriminatedUnion } from "@colmeia/core/src/tools/utility/types";
import { Define } from "@colmeia/core/src/tools/utility/types/entities/define";
import { EMetaHookReferralSourceType } from "@colmeia/core/src/shared-business-rules/user-function/user-function-model";
import { IPaymentMeta } from "../payments/payments.meta.types";

export const PROFILE_SETTINGS_ADDRESS_CHAR_LIMIT = 256;
export const PROFILE_SETTINGS_DESCRIPTION_CHAR_LIMIT = 516;
export const PROFILE_SETTINGS_EMAIL_CHAR_LIMIT = 128;

export namespace WhatsApp {

    export import Functions = WhatsAppFunctions;
    export import Validators = WhatsAppValidators;

    export namespace Profile {

        /**
         * If the settings are fullfilled the following propeties are mandatory.
         * If one of these is fullfilled the 4 must be fullfilled, if none are fullfilled then they are not mandatory.
          - about;
          - address;
          - description;
          - email;

          The following properties are optional:
          - websites
          - vertical

          Doc from meta:
          https://developers.facebook.com/docs/whatsapp/cloud-api/reference/business-profiles/
         */
        export interface Settings {
            about?: string;
            address?: string; // Character limit 256. PROFILE_SETTINGS_ADDRESS_CHAR_LIMIT
            description?: string; // Character limit 512. PROFILE_SETTINGS_DESCRIPTION_CHAR_LIMIT
            email?: string; // Character limit 128. PROFILE_SETTINGS_EMAIL_CHAR_LIMIT
            profile_picture_handle?: string;
            profile_picture_url?: string;
            websites?: string[];
            vertical?: WhatsApp.Profile.Vertical;
        }

        export enum Vertical {
            Automotive = 'AUTO',
            BeautySpaAndSalon = 'BEAUTY',
            ClothingAndApparel = 'APPAREL',
            Education = 'EDU',
            Entertainment = 'ENTERTAIN',
            EventPlanningAndService = 'EVENT_PLAN',
            FinanceAndBanking = 'FINANCE',
            FoodAndGrocery = 'GROCERY',
            PublicService = 'GOVT',
            HotelAndLodging = 'HOTEL',
            MedicalAndHealth = 'HEALTH',
            NonProfit = 'NONPROFIT',
            ProfessionalServices = 'PROF_SERVICES',
            ShoppingAndRetail = 'RETAIL',
            TravelAndTransportation = 'TRAVEL',
            Restaurant = 'RESTAURANT',
            Other = 'OTHER',
        }
        //
    }


    export namespace Template {
        export enum Category {
            AccountUpdate = "ACCOUNT_UPDATE",
            PaymentUpdate = "PAYMENT_UPDATE",
            PersonalFinanceUpdate = "PERSONAL_FINANCE_UPDATE",
            ShippingUpdate = "SHIPPING_UPDATE",
            ReservationUpdate = "RESERVATION_UPDATE",
            IssueResolution = "ISSUE_RESOLUTION",
            AppointmentUpdate = "APPOINTMENT_UPDATE",
            TransportationUpdate = "TRANSPORTATION_UPDATE",
            TicketUpdate = "TICKET_UPDATE",
            AlertUpdate = "ALERT_UPDATE",
            AutoReply = "AUTO_REPLY",
        }

        export type Categories = ValueOf<typeof Categories>;

        export enum CategoryV14 {
            Transactional = 'TRANSACTIONAL',
            Mkt = 'MARKETING',
            OTP = 'OTP',
        }

        export enum CategoryV15 {
            Authentication = 'AUTHENTICATION',
            Marketing = 'MARKETING',
            Utility = 'UTILITY',
        };

        export enum EAdvancedMarketingOptions {
            Carousel = 'CAROUSEL',
            LtoOffer = 'LTOOFFER'
        };

        export const Categories = {
            ...Category,
            ...CategoryV14,
            ...CategoryV15,
        }

        export const isNewCategory = Guard.is(values(CategoryV15));
        export const isPreviousCategory = Guard.is([...values(Category), ...values(CategoryV14)]);

        export function normalizeCategory(inputCategory: WhatsApp.Template.Categories) {
            const category = WhatsApp.Template.isPreviousCategory(inputCategory)
                ? WhatsApp.Template.mapCategoryToNewVersion[inputCategory]
                : inputCategory
                ;
            return category;
        }

        export const mapCategoryToNewVersion = asConst<{ [key in Category | CategoryV14]: CategoryV15 }>()({
            [Category.AccountUpdate]: CategoryV15.Utility,
            [Category.PaymentUpdate]: CategoryV15.Utility,
            [Category.PersonalFinanceUpdate]: CategoryV15.Utility,
            [Category.ShippingUpdate]: CategoryV15.Utility,
            [Category.ReservationUpdate]: CategoryV15.Utility,
            [Category.IssueResolution]: CategoryV15.Utility,
            [Category.AppointmentUpdate]: CategoryV15.Utility,
            [Category.TransportationUpdate]: CategoryV15.Utility,
            [Category.TicketUpdate]: CategoryV15.Utility,
            [Category.AlertUpdate]: CategoryV15.Utility,
            [Category.AutoReply]: CategoryV15.Utility,
            [CategoryV14.Transactional]: CategoryV15.Utility,
            [CategoryV14.Mkt]: CategoryV15.Marketing,
            [CategoryV14.OTP]: CategoryV15.Authentication,
        });

        export const mapNewCategoryToPrevious = asConst<{ [key in CategoryV14 | CategoryV15]: Category }>()({
            [CategoryV14.Transactional]: Category.AccountUpdate,
            [CategoryV14.OTP]: Category.AccountUpdate,
            [CategoryV14.Mkt]: Category.AccountUpdate,
            [CategoryV15.Utility]: Category.AccountUpdate,
            [CategoryV15.Authentication]: Category.AccountUpdate,
            // [CategoryV15.Marketing]: Category.AccountUpdate,
        });


        export enum Language {
            PortugueseBR = "pt_BR",
            PortuguesePOR = "pt_PT",
            EnglishUS = "en_US",
            Afrikaans = "af",
            Albanian = "sq",
            Arabic = "ar",
            Azerbaijani = "az",
            Bengali = "bn",
            Bulgarian = "bg",
            Catalan = "ca",
            ChineseCHN = "zh_CN",
            ChineseHKG = "zh_HK",
            ChineseTAI = "zh_TW",
            Croatian = "hr",
            Czech = "cs",
            Danish = "da",
            Dutch = "nl",
            English = "en",
            EnglishUK = "en_GB",
            Estonian = "et",
            Filipino = "fil",
            Finnish = "fi",
            French = "fr",
            Georgian = "ka",
            German = "de",
            Greek = "el",
            Gujarati = "gu",
            Hausa = "ha",
            Hebrew = "he",
            Hindi = "hi",
            Hungarian = "hu",
            Indonesian = "id",
            Irish = "ga",
            Italian = "it",
            Japanese = "ja",
            Kannada = "kn",
            Kazakh = "kk",
            Kinyarwanda = "rw_RW",
            Korean = "ko",
            KyrgyzKyrgyzstan = "ky_KG",
            Lao = "lo",
            Latvian = "lv",
            Lithuanian = "lt",
            Macedonian = "mk",
            Malay = "ms",
            Malayalam = "ml",
            Marathi = "mr",
            Norwegian = "nb",
            Persian = "fa",
            Polish = "pl",
            Punjabi = "pa",
            Romanian = "ro",
            Russian = "ru",
            Serbian = "sr",
            Slovak = "sk",
            Slovenian = "sl",
            Spanish = "es",
            SpanishARG = "es_AR",
            SpanishSPA = "es_ES",
            SpanishMEX = "es_MX",
            Swahili = "sw",
            Swedish = "sv",
            Tamil = "ta",
            Telugu = "te",
            Thai = "th",
            Turkish = "tr",
            Ukrainian = "uk",
            Urdu = "ur",
            Uzbek = "uz",
            Vietnamese = "vi",
            Zulu = "zu",
        }

        export enum ButtonType {
            PhoneNumber = 'PHONE_NUMBER',
            URL = 'URL',
            QuickReply = 'QUICK_REPLY',
            OTP = 'OTP',
            Flow = 'FLOW',
            CopyCode = 'COPY_CODE'
        }

        export enum Status {
            Approved = "APPROVED",
            InAppeal = "IN_APPEAL",
            Pending = "PENDING",
            Rejected = "REJECTED",
            PendingDeletion = "PENDING_DELETION",
            Deleted = "DELETED",
            Disabled = "DISABLED",
            Flagged = "FLAGGED",
            Reinstated = "REINSTATED",
            Paused = "PAUSED",
            LimitExceeded = 'LIMIT_EXCEEDED',
        }

        export enum PausedTitle {
            FIRST_PAUSE = 'FIRST_PAUSE',
            SECOND_PAUSE = 'SECOND_PAUSE',

        }

        export const ForbiddenToSendWhatsappTemplate: Status[] = [
            Status.Paused,
            Status.InAppeal,
            Status.Pending,
            Status.Rejected,
            Status.PendingDeletion,
            Status.Deleted,
            Status.Disabled,
            Status.LimitExceeded,
        ]

        export const TemporaryUnavaiableTemplateStatus: Status[] = [
            Status.Paused,
            Status.InAppeal,
            Status.Pending,
            Status.LimitExceeded,
        ]

        export const DisabledTemplateStatus: Status[] = [
            Status.Rejected,
            Status.PendingDeletion,
            Status.Deleted,
            Status.Disabled,
        ]

    }

    export namespace Message {
        export type TargetMedia = {
            id?: string;
            link?: string;
        };

        export interface Media extends TargetMedia {
            caption?: string;
            filename?: string;
        }

        export interface Audio extends Media {
            caption?: never;
        }
        export interface Document extends Media {
            link: string;
            caption?: string;
            filename?: string;
        }

        export interface Image extends Media {
            // id: string;
            caption?: string;
        }

        export interface Video extends Media {
            // id: string;
            caption?: string;
        }

        export interface Sticker {
            link: string;
            provider?: Provider;
        }

        export interface Provider {
            name: string;
        }

        export enum Type {
            Audio = "audio",
            Contact = "contacts",
            Document = "document",
            Image = "image",
            Location = "location",
            Sticker = "sticker",
            Template = "template",
            Text = "text",
            Video = "video",
            Interactive = "interactive",
        }

        export interface SendTemplateComponent {
            template?: Template;
            location?: Location;
            contacts?: Contact[];

            text?: Text;

            interactive?: Interactive;

            image?: Image;
            audio?: Audio;
            document?: Document;
            video?: Video;

            sticker?: Sticker;
        }

        interface Context {
            message_id: string;
        }

        export interface Send extends WhatsApp.Message.SendTemplateComponent {
            messaging_product: MessagingProduct.WhatsApp;
            recipient_type?: RecipientType;
            to: To;
            type: Type;
            context?: Context;
        }

        export namespace Template {
            export namespace Structure {

                export interface Response {
                    data: Interface[];
                    paging: Paging;
                }

                export interface Paging {
                    cursors: Cursors;
                    next?: string;
                    previous?: string;
                }

                export interface Cursors {
                    before: string;
                    after: string;
                }

                export interface Query extends Partial<Cursors>, Partial<Interface> {
                    limit?: number;
                    fields?: string[];
                }

                //



                export interface Interface {
                    name: string;
                    components: Component[];
                    language: WhatsApp.Template.Language;
                    category: WhatsApp.Template.CategoryV15;
                    status?: WhatsApp.Template.Status;
                    id?: string;
                    quality_score?: QualityScore;
                    rejected_reason?: RejectedReason;
                    /**
                     * Set to true to allow us to assign a category based on our template guidelines and the template's contents.
                     * This can prevent your template from being rejected for miscategorization.
                     */
                    allow_category_change?: boolean;
                }



                export interface QualityScore {
                    score: QualityScoreType;
                    reasons?: string[];
                }

                export enum RejectedReason {
                    ABUSIVE_CONTENT = 'ABUSIVE_CONTENT', // Abusive content
                    INVALID_FORMAT = 'INVALID_FORMAT', // Invalid format
                    NONE = 'NONE', // None
                    PROMOTIONAL = 'PROMOTIONAL', // Promotional
                    TAG_CONTENT_MISMATCH = 'TAG_CONTENT_MISMATCH', // Tag content mismatch
                    NON_TRANSIENT_ERROR = 'NON_TRANSIENT_ERROR', // Non transient error

                    // Smarters
                    INCORRECT_CATEGORY = 'INCORRECT_CATEGORY', // Non transient error
                }

                export enum QualityScoreType {
                    GREEN = 'GREEN',
                    YELLOW = 'YELLOW',
                    RED = 'RED',
                    UNKNOWN = 'UNKNOWN',
                }

                export interface Component {
                    type: ComponentType;
                    text?: string;
                    format?: Format;
                    buttons?: Button[];
                    example?: {
                        header_handle?: string[];
                        header_text?: string[];
                        body_text?: string[][];
                        header_url?: string[];
                    };
                    add_security_recommendation?: boolean; // Authentication Template Only
                    code_expiration_minutes?: number; // Authentication Template Only
                    cards?: CarouselCard[]

                }

                export interface CarouselCard {
                    components: CardComponent[];
                }

                export interface CardComponent {
                    type: ComponentType;
                    text?: string;
                    format?: Format;
                    buttons?: Button[];
                    example?: {
                        header_handle?: string[];
                        header_text?: string[];
                        body_text?: string[][];
                        header_url?: string[];
                    };
                }
                export interface LtoComponent extends Component {
                    limited_time_offer: {
                        text: string;
                        has_expiration: boolean;
                    }
                }

                export interface Button {
                    type: WhatsApp.Template.ButtonType;
                    text?: string;
                    url?: string;
                    example?: string; // [https://www.website.com/dynamic-url-example]
                    otp_type?: "copy_code";
                    phone_number?: string;
                    flow_id?: string;
                }

                export enum Format {
                    Document = "DOCUMENT",
                    Text = "TEXT",
                    Video = "VIDEO",
                    Image = "IMAGE",
                }

                export enum ComponentType {
                    Body = "body",
                    Buttons = "buttons",
                    Footer = "footer",
                    Header = "header",
                    Lto = "limited_time_offer",
                    Carousel = "carousel",
                }
            }

            export interface Language {
                policy?: 'deterministic';
                code: WhatsApp.Template.Language;
            }
            export interface Component {
                type: Component.Type;
                parameters?: Component.Parameter[];
                cards?: CarouselCard[];
            }
            export interface CarouselCard {
                card_index: number;
                components: ComponentCard[];
            }
            export interface ComponentCard {
                type: Component.Type;
                parameters?: Component.CardParameter[];
            }

            // const template: WhatsApp.Message.Send  = {
            //     "messaging_product": WhatsApp.Message.MessagingProduct.WhatsApp,
            //     "to": "5511966342820",
            //     "type": WhatsApp.Message.Type.Template,
            //     "template": {
            //         "name": "sample_shipping_confirmation",
            //         "language": {
            //             "code": WhatsApp.Template.Language.Indonesian
            //         },
            //         "components": [
            //             {
            //                 "type": WhatsApp.Message.Template.Component.Type.Body,
            //                 "parameters": [
            //                     {
            //                         "type": WhatsApp.Message.Template.Component.Parameter.Type.Text,
            //                         "text": "sdfsddf"
            //                     }
            //                 ]
            //             }
            //         ]
            //     }
            // };

            export namespace Component {
                export interface Button extends Component {
                    type: Component.Type.Button;
                    parameters: Parameter[];
                    sub_type: `${SubType}`;
                    index: number;
                    //Proprity Flows
                    flow_id?: string,
                    flow_action?: EWAFOnSubmit.Navigate | EWAFOnSubmit.DataExchange,
                    navigate_screen?: string,
                    flow_json?: string,
                }

                export interface CardParameter {
                    type: Parameter.Type;
                    /**
                     * Required for quick_reply buttons.
                     * Developer-defined payload that is returned when the button is clicked in addition to the display text on the button.
                     */
                    payload?: string;
                    /**
                     * Required for URL buttons.
                     * Developer-provided suffix that is appended to the predefined prefix URL in the template.
                     */
                    text?: string;
                    image?: TargetMedia;
                    video?: TargetMedia;
                    coupon_code?: string;
                    action?: Parameter.Action;
                }


                export enum SubType {
                    QuickReply = 'QUICK_REPLY',
                    URL = 'URL',
                    COPY_CODE = "COPY_CODE",
                    FLOW = 'FLOW',
                }

                export enum Type {
                    Header = 'header',
                    Body = 'body',
                    Footer = 'footer',
                    Button = 'button',
                    Lto = "limited_time_offer",
                    Carousel = "carousel",
                }

                export interface Parameter {
                    type: Parameter.Type;
                    /**
                     * Required for quick_reply buttons.
                     * Developer-defined payload that is returned when the button is clicked in addition to the display text on the button.
                     */
                    payload?: string;
                    /**
                     * Required for URL buttons.
                     * Developer-provided suffix that is appended to the predefined prefix URL in the template.
                     */
                    text?: string;

                    image?: TargetMedia;
                    document?: Document;
                    video?: TargetMedia;
                    coupon_code?: string;

                    // video?: WhatsApp.Message.Video;

                    action?: Parameter.Action;

                    cards?: CarouselCard[]
                }

                export interface LtoParameter extends Parameter {

                    limited_time_offer: {
                        expiration_time_ms: number
                    };
                }


                export interface ImageParameter {
                    type: Parameter.Type.Image;
                    image: TargetMedia;
                }

                export namespace Parameter {
                    export enum Type {
                        Text = 'text',
                        Currency = 'currency',
                        DateTime = 'date_time',
                        Image = 'image',
                        Document = 'document',
                        Video = 'video',
                        Payload = 'payload',
                        Lto = "limited_time_offer",
                        CouponCode = "coupon_code",
                        Action = 'action',
                    }

                    export interface Action {
                        flow_token: string
                        flow_action_data?: IWAFActionParameter
                    }
                }

            }

        }
        export interface Template {
            /**
             * The name of the template
             */
            name: string;
            /**
             * Contains a language object. Specifies the language the template may be rendered in.
             * Only the deterministic language policy works with media template messages.
             */
            language: Template.Language;
            components?: Template.Component[];
        }

        export namespace Send {
            export interface Response {
                messaging_product: string;
                contacts: Contact[];
                messages: Message[];
            }

            export interface Contact {
                input: string;
                wa_id: string;
            }

            export interface Message {
                id: string;
            }
        }

        export type Birthday = $string | `${number}-${number}-${number}`;

        export interface Contact {
            addresses?: Address[];
            birthday?: Birthday;
            emails?: Email[];
            name: Name;
            org?: Org;
            phones?: Phone[];
            urls?: URL[];
        }

        export interface Address {
            street: string;
            city: string;
            state: string;
            zip: string;
            country: string;
            country_code: string;
            type: string;
        }

        export interface Email {
            email: string;
            type: string;
        }

        export interface Name {
            formatted_name: string;
            first_name: string;
            last_name: string;
            middle_name: string;
            suffix: string;
            prefix: string;
        }

        export interface Org {
            company: string;
            department: string;
            title: string;
        }

        export interface Phone {
            phone: To;
            type: string;
            wa_id?: string;
        }

        export type To = $string | StringNumber;

        export interface URL {
            url: string;
            type: string;
        }
        export namespace Interactive {
            export enum Type {
                List = 'list',
                Button = 'button',
                CallToActionURL = 'cta_url',
                ProductList = 'product_list',
                FLOW = 'flow',
                OrderDetails = 'order_details',
                OrderStatus = 'order_status',
            }
        }
        export interface Interactive {
            type: Interactive.Type;
            header?: Header;
            body: Body;
            footer?: Body;
            action: Action;
        }

        export enum ActionType {
            CallToActionURL = 'cta_url',
            Flow = 'flow',
            ReviewAndPay = 'review_and_pay',
            ReviewOrder = 'review_order',
        }

        export interface BasicAction {
            button?: string;
            sections?: Section[];
            buttons?: Button[];
            catalog_id?: string;
            product_retailer_id?: string;
        }

        export interface NamedAction extends BasicAction {
            name: ActionType; // used when interactive type is waf
            parameters: {} // used when interactive type is waf
        }

        export interface ActionFlow extends NamedAction {
            name: ActionType.Flow; // used when interactive type is waf
            parameters: IWAFActionParameter // used when interactive type is waf
        }
        export interface ActionCTA extends NamedAction {
            name: ActionType.CallToActionURL; // used when interactive type is waf
            parameters: IWACTAActionParameter // used when interactive type is waf
        }

        export interface ActionPayment extends BasicAction {
            name: ActionType.ReviewAndPay; // used when interactive type is payment
            parameters: IPaymentMeta.IPaymentMetaActionParameter; // used when interactive type is payment
        }

        export interface ActionPaymentReviewOrder extends BasicAction {
            name: ActionType.ReviewOrder; // used when interactive type is payment
            parameters: IPaymentMeta.IReviewOrderActionParameterMeta; // used when interactive type is payment
        }

        type MapActions = Define<{ [name in ActionType]: IntersectPartialExplicit<NamedAction, { name: name }> }, {
            [ActionType.CallToActionURL]: ActionCTA;
            [ActionType.Flow]: ActionFlow;
            [ActionType.ReviewAndPay]: ActionPayment,
            [ActionType.ReviewOrder]: ActionPaymentReviewOrder,
        }>

        export type Action = ValueOf<MapActions> | BasicAction;

        export interface Button {
            type: string;
            reply: Reply;
        }

        export interface Reply {
            id: string;
            title: string;
        }

        export interface ProductSectionItem {
            product_retailer_id: string;
        }

        export interface Section {
            title: string;
            product_items?: ProductSectionItem[];
            rows?: Row[];
        }

        export interface Row {
            id: string;
            title: string;
            description: string;
        }

        export interface Body {
            text: string;
        }



        export type HeaderMedia = Partial<UnionToIntersection<WhatsApp.Message.TargetMedia>> & WhatsApp.Message.Media

        export interface Header {
            type: SetAsCaseInsensitive<`${WhatsApp.Message.Template.Structure.Format}`>;
            /**
             * Required if type is set to document. Contains the media object with the document.
             */
            document?: HeaderMedia; // WhatsApp.Message.Document
            /**
             * Required if type is set to image. Contains the media object with the image.
             */
            image?: HeaderMedia; // WhatsApp.Message.Image
            /**
             * Required if type is set to video. Contains the media object with the video.
             */
            video?: HeaderMedia; // WhatsApp.Message.Video
            /**
             * Required if type is set to text. Text for the header. Formatting allows emojis, but not markdown. Maximum length: 60 characters.
             */
            text?: string;
        }

        export interface Location {
            longitude: number;
            latitude: number;
            name: string;
            address: string;
        }

        export enum MessagingProduct {
            WhatsApp = "whatsapp",
        }

        export enum RecipientType {
            Individual = "individual",
        }

        export interface Text {
            preview_url: boolean;
            body: string;
        }

        type CreateSend<T extends Type> = { [key in Extract<keyof Send, `${T}`>]: unknown };

        export interface GenericSend<Type extends Message.Type> extends Send, Merger<CreateSend<Type>> {
            type: Type;
        }



        export namespace Media {
            export interface Response {
                messaging_product: MessagingProduct.WhatsApp;
                url: string;
                mime_type: string;
                sha256: string;
                file_size: number;
                id: string;
            }
        }

        export namespace Hook {


            export interface Receive {
                object: ReceiveObject;
                entry: Entry[];
            }

            export interface Entry {
                id: string;
                changes: Change[];
                time?: number;
            }

            export interface Change {
                value: Value;
                field: Field;
            }

            export enum Field {
                Messages = "messages",
                PhoneNumberQualityUpdate = "phone_number_quality_update",
                MessageTemplateStatusUpdate = "message_template_status_update",
                AccountUpdate = "account_update",
                MessageTemplateQualityUpdate = "message_template_quality_update",
                TemplateCategoryUpdate = "template_category_update",
            }

            /**
             * Manter a chave igual aos valores!
             */
            export enum EMetaEvents {
                ACCOUNT_VIOLATION = 'ACCOUNT_VIOLATION',
                ACCOUNT_RESTRICTION = 'ACCOUNT_RESTRICTION',
            }

            export enum ERestrictionType {
                RESTRICTION_ON_ADD_PHONE_NUMBER_ACTION = 'RESTRICTION_ON_ADD_PHONE_NUMBER_ACTION',
                RESTRICTED_BIZ_INITIATED_MESSAGING = 'RESTRICTED_BIZ_INITIATED_MESSAGING',
                RESTRICTED_CUSTOMER_INITIATED_MESSAGING = 'RESTRICTED_CUSTOMER_INITIATED_MESSAGING',
            }

            export interface Value {
                event?:
                | $string
                | WhatsApp.Template.Status
                | WhatsApp.Message.Hook.EMetaEvents
                ;

                phone_number?: string;
                restriction_info?: {
                    restriction_type: | $string | ERestrictionType;
                    expiration: number;
                }[];

                messaging_product?: MessagingProduct.WhatsApp;
                metadata?: Metadata;
                contacts?: ValueContact[];
                messages?: Message[];
                statuses?: Status[];
                display_phone_number?: string;

                current_limit?: string;
                old_limit?: string;

                message_template_id?: number;
                message_template_name?: string;
                message_template_language?: WhatsApp.Template.Language;
                reason?: string;
                new_quality_score?: WhatsApp.Message.Template.Structure.QualityScoreType;
                violation_info?: ViolationType;
                other_info?: OtherInfo;

                previous_category?: WhatsApp.Template.Categories;
                new_category?: WhatsApp.Template.Categories;
            }

            export interface OtherInfo {
                title: string,
                description: string,
            }

            export interface ViolationType {
                violation_type: WhatsApp.Waba.RestrictionInfo;
            }

            export interface ValueContact {
                profile: Profile;
                wa_id: string;
            }

            export interface Profile {
                name: string;
            }

            /**
             * Represents a referral object when a customer clicks an ad that redirects to WhatsApp.
             */
            export interface Referral {
                /** The Meta URL that leads to the ad or post clicked by the customer */
                source_url: string;
                /** The type of the ad’s source; either 'ad' or 'post' */
                source_type: EMetaHookReferralSourceType;
                /** Meta ID for an ad or a post */
                source_id: string;
                /** Headline used in the ad or post */
                headline: string;
                /** Body for the ad or post */
                body: string;
                /** Media present in the ad or post; either 'image' or 'video' */
                media_type: string;
                /** URL of the image, applicable when media_type is 'image' */
                image_url?: string;
                /** URL of the video, applicable when media_type is 'video' */
                video_url?: string;
                /** URL for the thumbnail, applicable when media_type is 'video' */
                thumbnail_url?: string;
                /** Click ID generated by Meta for ads that click to WhatsApp */
                ctwa_clid: string;
            }

            export interface Message {
                type: Type;
                from: string;
                id: string;
                timestamp: string;
                text?: Text;
                contacts?: MessageContact[];
                video?: Video;
                context?: Context;
                interactive?: Interactive;
                button?: {
                    payload?: string;
                    text?: string;
                };
                location?: {
                    latitude: number;
                    longitude: number;
                };
                referral?: Referral;
                image?: Image;
                order?: Order;
            }

            export interface Order {
                catalog_id: string;
                text: string;
                product_items: ProductItem[];
            }

            export interface ProductItem {
                product_retailer_id: string;
                quantity: number;
                item_price: number;
                currency: `${Meta.Catalog.CurrencyCode}`;
            }

            export interface Media {
                mime_type: string;
                sha256: string;
                id: string;
                caption?: string;
                filename?: string;
            }

            export interface Image extends Media { }

            export interface MessageContact {
                name: NameClass;
                phones: Phone[];
            }

            export interface NameClass {
                first_name: string;
                formatted_name: string;
            }

            export interface Phone {
                phone: string;
                wa_id: string;
                type: string;
            }

            export interface Context {
                from: string;
                id: string;
            }

            export interface Interactive {
                type: string;
                list_reply?: ListReply;
                button_reply?: ButtonReply;
                nfm_reply?: NfmReply;
            }

            export interface ButtonReply {
                id: string;
                title: string;
            }

            export interface ListReply {
                id: string;
                title: string;
                description: string;
            }

            export interface NfmReply {
                body: string;
                name: string;
                response_json: string;
            }

            export interface Text {
                body: string;
            }

            export interface Video extends Media { }

            export interface Metadata {
                display_phone_number: string;
                phone_number_id: string;
            }

            export namespace Status {
                export enum Type {
                    Sent = 'sent',  // Message received by WhatsApp server.	One checkmark
                    Delivered = 'delivered',  // Message delivered to the recipient.	Two checkmarks
                    Read = 'read',  // Message read by recipient.	Two blue checkmarks
                    Failed = 'failed',  // Message failed to send.	Red error triangle
                    Deleted = 'deleted',  // Message deleted by the user.	Message is replaced in WhatsApp mobile with the note "This message was deleted".
                }
            }
            export interface Status {
                id: string;
                status: Status.Type;
                timestamp: string;
                recipient_id: string;
                conversation?: Conversation;
                pricing?: Pricing;
                errors?: StatusError[];
            }

            export interface StatusError {
                code: number;
                href: string;
                title: string;
                message: string;
                error_data?: {
                    details: string;
                }
            }

            export interface Conversation {
                id: string;
                expiration_timestamp?: string;
                origin: Origin;
            }

            export interface Origin {
                type: string;
            }

            export interface Pricing {
                billable: boolean;
                pricing_model: string;
                category: string;
            }

            export enum ReceiveObject {
                WhatsappBusinessAccount = "whatsapp_business_account",
            }
        }
    }

    //WhatsApp Business Accounts
    export namespace Waba {

        export interface Settings {
            account_review_status: WhatsApp.Waba.AccountReviewStatus,
        }

        export enum AccountReviewStatus {
            Pending = 'PENDING',
            Approved = 'APPROVED',
            Rrejected = 'REJECTED'
        }

        export enum PhoneStatus {
            CONNECTED = 'CONNECTED',
            FLAGGED = 'FLAGGED',
            RESTRICTED = 'RESTRICTED',
        }

        export enum RestrictionInfo {
            adult = 'ADULT',
            alcohol = 'ALCOHOL',
            animals = 'ANIMALS',
            bodyPartsFluids = 'BODY_PARTS_FLUIDS',
            dating = 'DATING',
            digitalServicesProducts = 'DIGITAL_SERVICES_PRODUCTS',
            drugs = 'DRUGS',
            gambling = 'GAMBLING',
            healthcare = 'HEALTHCARE',
            illegalProducts = 'ILLEGAL_PRODUCTS',
            misleading = 'MISLEADING',
            overtlySexualizedPositioning = 'OVERTLY_SEXUALIZED_POSITIONING',
            realFakeCurrency = 'REAL_FAKE_CURRENCY',
            scam = 'SCAM',
            supplements = 'SUPPLEMENTS',
            thirdPartyInfringements = 'THRID_PARTY_INFRINGEMENTS',
            tobacco = 'TOBACCO',
            unauthorizedMedia = 'UNAUTHORIZED_MEDIA',
            weapons = 'WEAPONS',
        }

        export interface Analytics {
            analytics: {
                phone_numbers: string[],
                granularity: Granularity,
                data_points: DataPoints[]
            }
            id: string
        }

        export enum Granularity {
            day = "DAY",
            month = "MONTH",
            halfHour = "HALF_HOUR",
        }

        export interface DataPoints {
            start: number //Clock,
            end: number //Clock,
            sent: number,
            delivered: number,
        }
    }
}

