import { Serializable } from "../../business/serializable";
import { DEFAULT_GEO_DISTANCE, DEFAULT_GEO_TIME } from "../../client-shared/client-native.model";
import { getIdentifiedNS } from "../../nser/blank-ns";
import { hourToMS } from "../../time/time-utl";
import { getClock, getDomainFromEmail } from "../../tools/utility";
import { ENonSerializableObjectType, INonSerializableIdentification } from "../non-serializable-id/non-serializable-id-interfaces";
import { IMenuContractAccessItem, IMenuContractConfig, TIdMenuContractAccess } from "./social-network-config";
import { ATTENDANCE_QUEUE_KILL_TIMEOUT_DEFAULT_HOURS, colmeiaDomains } from "./social-network-config.constants";
import { ESocialNetworkPrimaryCEPSearchType, IAgentChannelClosedControl, ISocialNetworkSettingsServer } from "./social-network-user-settings.model";


const colmeiaDomainsSet = new Set(colmeiaDomains);

export function isColmeiaStaff(email: string): boolean {
    const domain: string = getDomainFromEmail(email)
    return colmeiaDomainsSet.has(domain);
}

export enum EContractItemError {
    expired = 'expired',
}

export function getContractItemErrors(idMenu: TIdMenuContractAccess, item: IMenuContractAccessItem) {
    const errors: EContractItemError[] = [];
    const config: IMenuContractConfig = Serializable.getIdMenuConfig(idMenu);
    if (item.hasAccess && isContractItemExpired(item)) errors.push(EContractItemError.expired);
    return errors;
}

export function isContractItemExpired(item: IMenuContractAccessItem): boolean {
    return getClock() > item.expireAt;
}

export function getEmptyUserSettingsNser(override: Partial<ISocialNetworkSettingsServer>, ident: INonSerializableIdentification): ISocialNetworkSettingsServer {

    return {
        ...getIdentifiedNS(ENonSerializableObjectType.socialNetworkUserSettings, override.nName, ident),
        idSocialContext: undefined,
        nsType: ENonSerializableObjectType.socialNetworkUserSettings,
        domain: [],
        returnCustomerToQueueOnAgentDowntime: false,
        cepSearch: {
            primary: ESocialNetworkPrimaryCEPSearchType.google,
            cepCacheTTLDays: 120,
            useViaCepAsBackupOnly: false,
        },
        virtualService: {
            idAvatarToImpersonateBot: undefined,
            idUserChatContainerGroup: undefined,
        },
        geo: {
            maxDistance: DEFAULT_GEO_DISTANCE,
            timeInterval: DEFAULT_GEO_TIME
        },
        agent: {
            historic: {
                disableHistoricSearch: false, // Não implementado
                disableOtherAgentHistoricSearch: false, // Não implementado
                disableSearchByDateInterval: false,
            },
            absense: {
                returnCustomerToQueuAfter: 0,
                disconnection: {
                    returnCustomersToQueue: false,
                    numberOfChecks: 0,
                    interval: 0,
                },
            },
            channelClosedControl: getInitialAgentChannelClosedControl(override.agent?.channelClosedControl),
        },
        attendance: {
            queueKillTimeout: {
                afterHS: ATTENDANCE_QUEUE_KILL_TIMEOUT_DEFAULT_HOURS,
                forOwnerAfterHS: ATTENDANCE_QUEUE_KILL_TIMEOUT_DEFAULT_HOURS
            },
            agentPunishForAttByLogout: 0,
        },
        visualIdentity: {},
        security: {
            authConsecutiveMaxFailure: 0,
            blockTime: 0,
            changePDWInterval: 0,
            pwdHistoricControl: 0
        },
        messageCordinator: {
            textMessage: false,
            waitTimeForMessage: null,
            waitTimeForMedia: null
        },
        externalDatabases: {
            useMultimediaBucket: false,
            useExternalBlobAPI: false,
            useExternalCloudProvider: false
        },
        anomaly: getEmptyAnomalyConfig(),
        waf: undefined,
        ...override,
    }
}

export function getEmptyAnomalyConfig(): ISocialNetworkSettingsServer['anomaly'] {
    return {
        latestFields: 6,
        maxRepeatedInput: 16,
        maxMessagesByConversation: 500,
    }
}

export function getInitialAgentChannelClosedControl(override: Partial<IAgentChannelClosedControl> = {}): IAgentChannelClosedControl {
    return {
        isSendAllowed: false,
        sendInterval: hourToMS(3),
        ...override
    }
}
