<div class="chat-right-bar" >

  <div [class.hidden]="macroMenuService.macroMenuEnabled$ | async">
    <section>
      <chat-options-attendance *ngIf="shouldShowAttendanceOptions()" />
    </section>

    <section *ngIf="isMobile && shouldShowAttendanceOptions()" class="attendance-finish-transfer">
      <chat-options-attendant-transferable-islands class="icon-only" />
      <chat-options-finish-attendance />
    </section>
  </div>

  <div [class.hidden]="!(macroMenuService.macroMenuEnabled$ | async)">
    <app-chat-macros-menu/>
  </div>

</div>
