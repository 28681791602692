import {
    GlobalPositionStrategy,
    Overlay,
    OverlayConfig,
    OverlayRef,
} from "@angular/cdk/overlay";
import { ComponentPortal, ComponentType } from "@angular/cdk/portal";
import { Injectable, ViewContainerRef } from "@angular/core";
import { MatButton } from "@angular/material/button";
import { verticalAppearDurationMS } from "app/components/dashboard/dashboard-animations";
import { TParamVariable } from "app/components/dashboard/params-editor/mat-quill-utility";
import { Subject } from "rxjs";
import { delay, take, takeUntil } from "rxjs/operators";
import { LocalStoragePersistence } from "../local-storage-persistence.service";

export type TSubscriptionCallback = (value: TParamVariable) => void;

export interface IVarInserterHandler {
    subscriptionCallback: TSubscriptionCallback;
    popUpEventEmitterProperty: string;
    popUpComponent: ComponentType<any>;
    containerRef: ViewContainerRef;
    data: {variablesOptions: TParamVariable[], updateVariables: (variables: TParamVariable[]) => void};
    componentDataProp: string;
}

@Injectable({
    providedIn: "root",
})
export class ColmeiaVariableInserterService {
    private overlayRef: OverlayRef;
    private collisionDetectorFire: Subject<void> = new Subject();
    private callback: TSubscriptionCallback;
    private dialogEventEmitter: string;
    private containerRef: ViewContainerRef;
    private data: {variablesOptions: TParamVariable[], updateVariables: (variables: TParamVariable[]) => void};
    private componentDataProperty: string;

    constructor(
        private overlay: Overlay,
        private localStorageSvc: LocalStoragePersistence
    ) {}

    public openPopUp(
        buttonTrigger: MatButton,
        event: MouseEvent,
        handler: IVarInserterHandler
    ) {
        event.stopPropagation();

        this.callback = handler.subscriptionCallback;
        this.dialogEventEmitter = handler.popUpEventEmitterProperty;
        this.containerRef = handler.containerRef;
        this.data = handler.data;
        this.componentDataProperty = handler.componentDataProp;

        const { overlayRef } = this.getTemplatePortalAndOverlayRef(
            buttonTrigger._elementRef.nativeElement,
            handler.popUpComponent
        );
        this.overlayRef = overlayRef;
    }

    private getTemplatePortalAndOverlayRef<T extends any>(
        trigger: HTMLElement,
        component: ComponentType<T>
    ): {
        overlayRef: OverlayRef;
    } {
        const overlayConfig = this.getOverlayConfig(trigger);
        const componentPortal = new ComponentPortal(
            component,
            this.containerRef
        );
        const overlayRef = this.overlay.create(overlayConfig);
        const collisionDetector = () =>
            this.detectOverlayBottomCollision(trigger, overlayRef);

        overlayRef
            .attachments()
            .pipe(take(1))
            .pipe(delay(verticalAppearDurationMS))
            .subscribe(() => {
                collisionDetector();
                // const addInput = this.insertVarInput?.nativeElement;
                // addInput?.focus();
            });

        this.collisionDetectorFire
            .pipe(
                takeUntil(overlayRef.backdropClick()),
                delay(verticalAppearDurationMS)
            )
            .subscribe(() => {
                collisionDetector();
            });

        const componentInstance = overlayRef.attach(componentPortal);

        const subscriptionVar = componentInstance.instance[
            this.dialogEventEmitter
        ].subscribe((value) => {
            this.callback(value);
            this.overlayRef.dispose();
        });

        componentInstance.instance['paramsVariables'] = this.data.variablesOptions;
        componentInstance.instance['updateVariables'] = this.data.updateVariables;

        window.addEventListener("resize", collisionDetector);

        overlayRef
            .backdropClick()
            .pipe(takeUntil(overlayRef.detachments()), take(1))
            .subscribe(() => {
                overlayRef.dispose();
            });

        overlayRef
            .detachments()
            .pipe(take(1))
            .subscribe(() => {
                window.removeEventListener("resize", collisionDetector);
                subscriptionVar.unsubscribe();
            });

        return { overlayRef };
    }

    private getOverlayConfig(
        trigger: HTMLElement,
        invertY: boolean = false
    ): OverlayConfig {
        return new OverlayConfig({
            positionStrategy: this.getPositionStrategyForTrigger(
                trigger,
                invertY
            ),
            hasBackdrop: true,
            disposeOnNavigation: true,
            backdropClass: [
                "no-backdrop-filter",
                "transparent-backdrop",
                "margin-animated",
            ],
        });
    }

    private detectOverlayBottomCollision(
        trigger: HTMLElement,
        overlayRef: OverlayRef
    ) {
        const overlayEl = overlayRef.overlayElement;
        const { bottom: triggerBottom } = trigger.getBoundingClientRect();
        const { height } = overlayEl.getBoundingClientRect();
        const collisionYPoint = window.innerHeight - 8;
        const getBottomCollision = triggerBottom + height >= collisionYPoint;
        const positionStrategy = this.getPositionStrategyForTrigger(
            trigger,
            getBottomCollision
        );

        overlayRef.updatePositionStrategy(positionStrategy);
    }

    private getPositionStrategyForTrigger(
        target: HTMLElement,
        invertY: boolean
    ): GlobalPositionStrategy {
        const positionStrategy = this.overlay.position().global();
        const { top, bottom, right, height, width } =
            target.getBoundingClientRect();

        if (invertY) {
            positionStrategy.bottom(
                `${window.innerHeight - bottom + height}px`
            );
        } else {
            positionStrategy.top(`${top + height + 8}px`);
        }

        positionStrategy.right(`${window.innerWidth - right + width - 52}px`);

        return positionStrategy;
    }

    public getVariables(storageKey: string): TParamVariable[] {
        return this.localStorageSvc.getItem(storageKey);
    }

    public setVariables(storageKey: string, variables: TParamVariable[]): void {
        this.localStorageSvc.putItem(storageKey, variables);

    }
}
