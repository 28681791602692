import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';
import { BBCode } from '@colmeia/core/src/shared-business-rules/bbcode/bbcode-main';
import { EBBCodeStyles, TBBCodeStyleTags } from '@colmeia/core/src/shared-business-rules/bbcode/bbcode-types';
import { IEditorVariable, removeBracket, TIEditorVariableArray, regexDoubleBrackets } from '@colmeia/core/src/shared-business-rules/metadata/metadata-utils';
import { GenericSharedService } from '@colmeia/core/src/shared-business-rules/shared-services/services/generic.shared.service';
import { getNormalizedValue, isEqual, isInvalidArray, isValidArray, isValidFunction, isValidNumber, isValidString } from '@colmeia/core/src/tools/barrel-tools';
import { Editor, Extensions, JSONContent } from "@tiptap/core";
import Bold from '@tiptap/extension-bold';
import Code from '@tiptap/extension-code';
import Document from '@tiptap/extension-document';
import Italic from '@tiptap/extension-italic';
import Mention from "@tiptap/extension-mention";
import Paragraph from '@tiptap/extension-paragraph';
import Strike from '@tiptap/extension-strike';
import Text from '@tiptap/extension-text';
import History from '@tiptap/extension-history';
import Link from '@tiptap/extension-link';
import Heading from '@tiptap/extension-heading';
import Image from '@tiptap/extension-image';
import Table from '@tiptap/extension-table';
import TableCell from '@tiptap/extension-table-cell';
import TableHeader from '@tiptap/extension-table-header';
import TableRow from '@tiptap/extension-table-row';
import Gapcursor from '@tiptap/extension-gapcursor';
import BulletList from '@tiptap/extension-bullet-list';
import OrderedList from '@tiptap/extension-ordered-list';
import ListItem from '@tiptap/extension-list-item';
import { SuggestionOptions, SuggestionProps } from '@tiptap/suggestion';
import { IVarEditorTextAreaParameter } from 'app/handlers/var-editor-textarea.handler';
import { createServiceLogger } from 'app/model/client-utility';
import { ColmeiaPopover, ColmeiaPopoverHandler, ColmeiaPopoverService } from 'app/services/dashboard/colmeia-popover.service';
import type { IEditorVariableClient } from '../../var-editor/var-editor.component';
import { IVarEditorUIConfig, VarEditorTextAreaComponent, varEditorUIConfigDefault } from '../var-editor-text-area.component';
import { constant } from '@colmeia/core/src/business/constant';
import { MatDialog } from '@angular/material/dialog';
import { MultimediaService } from 'app/services/multimedia.service';
import { onlyImageAllowed } from '@colmeia/core/src/multi-media/file-interfaces';
import { ETiptapMarkTypes, ETiptapNodeHasBBCodeTypes, ETiptapNodeNoBBCodeTypes, tipTapBlockHTMLElements, tiptapTextStyleMarkToBBTag } from './tiptap.constants';

function getMatchScore(query: string, target: string): number {
    query = query.toLocaleLowerCase();
    target = target.toLocaleLowerCase();

    const charsArr = target.split('');
    let charsCount = charsArr.reduce((score, char) => query.includes(char) ? score + 1 : score, 0);
    charsCount += target.includes(query) ? query.length : 0;

    return charsCount
}

const spaceRegExp = /^\s$/;

interface TTiptapHeaderButton {
    matIcon?: string;
    textLabel?: string;
    tooltip?: string;
    isEnabled: boolean | (() => boolean);
    isActive: () => boolean;
    onClick: () => unknown;
}

@Component({
    selector: 'app-var-editor-text-area-tiptap',
    templateUrl: './var-editor-text-area-tiptap.component.html',
    styleUrls: ['./var-editor-text-area-tiptap.component.scss']
})
export class VarEditorTextAreaTiptapComponent implements OnInit, OnDestroy, OnChanges, AfterViewInit {
    log = createServiceLogger('VarEditor', 'yellow');

    textUpdateStep = 10;

    @Input()
    varEditorUIConfig: IVarEditorUIConfig;
    public varEditorUIConfigDefault: IVarEditorUIConfig = varEditorUIConfigDefault;

    @Input()
    parameters: IVarEditorTextAreaParameter;

    public get shouldHideEmojis() {
        return this.parameters.shouldHideEmojis;
    }

    public get shouldHideBBCode() {
        return this.parameters.shouldHideBBCode;
    }

    @Input()
    allVariables: IEditorVariableClient[] = [];

    @Input()
    rawTextCharCount: number;

    @Input()
    amoutCharacters: number;

    @Input()
    placeholder: TIEditorVariableArray = [];

    @Input()
    content: string;
    @Output()
    contentChange: EventEmitter<string> = new EventEmitter();

    @Input()
    limitCharacters: number;

    @ViewChild('tiptapContainer', { static: true })
    tiptapContainer: ElementRef<HTMLDivElement>;

    private tiptapInstance!: Editor;
    public fullTextLength: number = 0;
    public charactersCount: number = 0;

    private internalRawContent: string = '';

    public readonly textStyleModifiers: TTiptapHeaderButton[] = [
        {
            matIcon: 'format_italic',
            tooltip: 'Itálico',
            isEnabled: true,
            isActive: () => this.tiptapInstance?.isActive('italic'),
            onClick: () => this.tiptapInstance?.chain().focus().toggleItalic().run()
        },
        {
            matIcon: 'format_bold',
            tooltip: 'Negrito',
            isEnabled: true,
            isActive: () => this.tiptapInstance?.isActive('bold'),
            onClick: () => this.tiptapInstance?.chain().focus().toggleBold().run()
        },
        {
            matIcon: 'strikethrough_s',
            tooltip: 'Tachado',
            isEnabled: true,
            isActive: () => this.tiptapInstance?.isActive('strike'),
            onClick: () => this.tiptapInstance?.chain().focus().toggleStrike().run()
        },
        {
            matIcon: 'code',
            tooltip: 'Código',
            isEnabled: true,
            isActive: () => this.tiptapInstance?.isActive('code'),
            onClick: () => this.tiptapInstance?.chain().focus().toggleCode().run()
        },
    ];

    public readonly elementButtons: TTiptapHeaderButton[] = [
        // [EBBCodeStyles.Menu]: { tooltip: '', matIcon: '', isEnabled: false, isActive: () => false, toggle: () => { } },
        {
            matIcon: 'title',
            tooltip: 'Título',
            isEnabled: () => this.parameters.elementButtons?.heading1,
            isActive: () => this.tiptapInstance?.isActive('heading', { level: 1 }),
            onClick: () => this.tiptapInstance.chain().focus().toggleHeading({ level: 1 }).run()
        },
        {
            matIcon: 'text_fields',
            tooltip: 'Subtítulo',
            isEnabled: () => this.parameters.elementButtons?.heading2,
            isActive: () => this.tiptapInstance?.isActive('heading', { level: 2 }),
            onClick: () => this.tiptapInstance.chain().focus().toggleHeading({ level: 2 }).run()
        },
        {
            matIcon: 'link',
            tooltip: 'Link',
            isEnabled: () => this.parameters.elementButtons?.link,
            isActive: () => this.tiptapInstance?.isActive('link'),
            onClick: () => this.editLink()
        },
        {
            matIcon: 'image',
            tooltip: 'Imagem',
            isEnabled: () => this.parameters.elementButtons?.base64image,
            isActive: () => false,
            onClick: () => this.insertImage()
        },
        {
            matIcon: 'format_list_bulleted',
            tooltip: 'Lista com pontos',
            isEnabled: () => this.parameters.elementButtons?.bulletList,
            isActive: () => this.tiptapInstance?.isActive('bulletList'),
            onClick: () => this.tiptapInstance.chain().focus().toggleBulletList().run()
        },
        {
            matIcon: 'format_list_numbered',
            tooltip: 'Lista numerada',
            isEnabled: () => this.parameters.elementButtons?.orderedList,
            isActive: () => this.tiptapInstance?.isActive('orderedList'),
            onClick: () => this.tiptapInstance.chain().focus().toggleOrderedList().run()
        },
        {
            matIcon: 'table_chart',
            tooltip: 'Tabela',
            isEnabled: () => this.parameters.elementButtons?.table,
            isActive: () => false,
            onClick: () => this.insertTable()
        }
    ];

    public readonly contextualButtons: TTiptapHeaderButton[] = [
        {
            textLabel: 'Inserir coluna',
            isEnabled: () => this.tiptapInstance?.can().addColumnAfter(),
            isActive: () => false,
            onClick: () => this.tiptapInstance.chain().focus().addColumnAfter().run()
        },
        {
            textLabel: 'Inserir linha',
            isEnabled: () => this.tiptapInstance?.can().addRowAfter(),
            isActive: () => false,
            onClick: () => this.tiptapInstance.chain().focus().addRowAfter().run()
        },
        {
            textLabel: 'Apagar coluna',
            isEnabled: () => this.tiptapInstance?.can().deleteColumn(),
            isActive: () => false,
            onClick: () => this.tiptapInstance.chain().focus().deleteColumn().run()
        },
        {
            textLabel: 'Apagar linha',
            isEnabled: () => this.tiptapInstance?.can().deleteRow(),
            isActive: () => false,
            onClick: () => this.tiptapInstance.chain().focus().deleteRow().run()
        },
        // {
        //     textLabel: 'Combinar células',
        //     isEnabled: () => this.tiptapInstance?.can().mergeCells(),
        //     isActive: () => false,
        //     onClick: () => this.tiptapInstance.chain().focus().mergeCells().run()
        // },
        // {
        //     textLabel: 'Dividir células',
        //     isEnabled: () => this.tiptapInstance?.can().splitCell(),
        //     isActive: () => false,
        //     onClick: () => this.tiptapInstance.chain().focus().splitCell().run()
        // },
        {
            textLabel: 'Apagar tabela',
            isEnabled: () => this.tiptapInstance?.can().deleteTable(),
            isActive: () => false,
            onClick: () => this.tiptapInstance.chain().focus().deleteTable().run()
        },
    ];

    async openEditLinkDialog(url?: string, linkText?: string): Promise<any> {
        const dialogRef = this.dialogSvc.open(
            this.editLinkTplRef, {
            panelClass: "small-size",
            viewContainerRef: this.viewContainerRef,
            data: {
                url,
                linkText
            }
        });

        return dialogRef.afterClosed().toPromise();
    }

    async editLink(): Promise<void> {
        const previousUrl = this.tiptapInstance.getAttributes(ETiptapMarkTypes.Link).href;

        this.tiptapInstance
            .chain()
            .focus()
            .extendMarkRange(ETiptapMarkTypes.Link)
            .run();

        const { from, to } = this.tiptapInstance.view.state.selection;

        const previousLinkText = this.tiptapInstance.state.doc.textBetween(from, to);

        const { url, linkText } = await this.openEditLinkDialog(previousUrl, previousLinkText);

        if (!url) {
            return;
        }

        if (url === '') {
            this.tiptapInstance
            .chain()
            .focus()
            .extendMarkRange(ETiptapMarkTypes.Link)
            .unsetLink()
            .run()

            return;
        }

        // update link
        this.tiptapInstance
            .chain()
            .extendMarkRange(ETiptapMarkTypes.Link)
            .command(({ tr, dispatch }) => {
                if (!dispatch) return true;

                const { from, to } = tr.selection;

                tr.insertText(linkText, from, to);
                tr.addMark(
                  from,
                  from + linkText.length,
                  this.tiptapInstance.schema.marks.link.create({ href: url }),
                );

                return true;
            })
            .run();
    }

    async insertImage(): Promise<boolean> {
        try {
            const file = await this.multimediaSvc.promptSelectFile({
                mimeTypes: onlyImageAllowed
            });

            const dataURL = await MultimediaService.getFileAsDataURL(file);

            if (dataURL) {
                this.tiptapInstance
                    .chain()
                    .focus()
                    .setImage({ src: dataURL })
                    .run();
            }
        } catch(err) {
            console.log(err);
        }

        return true;
    }

    insertTable() {
        this.tiptapInstance.chain().focus().insertTable({ rows: 3, cols: 3, withHeaderRow: true }).run();
    }

    textFormatSort = () => 0;

    public toggleEmojiBox() {
        this.varEditorUIConfig.showEmojiBox = !this.varEditorUIConfig.showEmojiBox;
    }

    updateTextSize(value: number): void {
        this.varEditorUIConfig.fontSize = value;
    }

    private mentionsPopoverHandler: ColmeiaPopoverHandler;

    @ViewChild('mentionsListTpl')
    private mentionsListTpl: TemplateRef<unknown>;

    public popoverVariablesList: IEditorVariableClient[] = [];

    public selectedMentionIdx: number = 0;

    private mentionsPopover: ColmeiaPopover;

    private suggestionProps: SuggestionProps;

    @ViewChild("editLinkTpl") editLinkTplRef: TemplateRef<unknown>;

    @ViewChild('emojibox') emojibox: ElementRef;

    constructor(
        private popoverSvc: ColmeiaPopoverService,
        private viewContainerRef: ViewContainerRef,
        private varEditorTextAreaComponent: VarEditorTextAreaComponent,
        private dialogSvc: MatDialog,
        private multimediaSvc: MultimediaService
    ) { }

    ngOnInit() {
        this.internalRawContent = this.content;
        console.log('INITIALIZING TIP TAP', {
            internalRawContent: this.internalRawContent
        })
    }
    ngOnDestroy() {
        this.mentionsPopover?.close();
    }
    ngOnChanges(changes: SimpleChanges): void { }
    ngAfterViewInit(): void {
        this.fullTextLength = this.content.length;
        this.charactersCount = GenericSharedService.charCount(this.content);
        this.initTipTapInstance();
    }

    get maxCharsLength(): number {
        return this.limitCharacters ?? this.parameters.limitCharacters ?? constant.maxAssetTextLength;
    }

    public insertEmoji(value: string) {
        this.tiptapInstance.chain().focus().insertContent(value).run();
    }

    private initTipTapInstance() {
        const element = this.tiptapContainer.nativeElement;
        const extensions = this.getTiptapExtensions();

        this.tiptapInstance = new Editor({
            element,
            extensions,
            content: this.templateToHTML(),
            autofocus: true,
            editable: true,
            injectCSS: false,
        });

        this.tiptapInstance.on('blur', async (e) => {
            setTimeout(() => {
                this.resetSuggestions();
            }, 100)
        });

        this.tiptapInstance.on('create', e => {
            const rootContent = e.editor.getJSON();

            this.cleanupVariables(rootContent);

            if (!isEqual(e.editor.getJSON(), rootContent)) {
                this.tiptapInstance.commands.setContent(rootContent);
            }
        });

        this.tiptapInstance.on('update', e => {
            const editor = e.editor;
            const rootContent = editor.getJSON();

            this.cleanupVariables(rootContent);
            this.cleanupUnsuportedLines(rootContent);

            if (this.varEditorTextAreaComponent.hasTextLengthExceededLimit(this.generateAppOutput(rootContent))) {
                // reverte para o estado anterior
                editor.commands.setContent(this.templateToHTML(this.content));
                return;
            }

            const { from, to } = editor.state.selection;

            if (!isEqual(editor.getJSON(), rootContent)) {
                editor
                    .chain()
                    .focus()
                    .setContent(rootContent, false)
                    .setTextSelection({ from, to })
                    .run();
            }

            this.log('tiptapHTML', e.editor.getHTML());

            const output = this.generateAppOutput(rootContent);
            console.log({output})
            this.internalRawContent = output;

            this.fullTextLength = output.length;
            this.charactersCount = GenericSharedService.charCount(output);

            this.log({ output, whatsapp: BBCode.parseWhatsApp(output) });

            // editor
            //     .chain()
            //     .focus()
            //     .setContent(this.templateToHTML(output), false)
            //     .setTextSelection({ from, to })
            //     .run();

            this.contentChange.next(output)
        })
    }

    private getTiptapExtensions(): Extensions {
        const extensions: Extensions = [
            Document,
            Gapcursor,
            Text,
            Bold,
            Italic,
            Strike,
            Code,
            Paragraph,
            History,
            // CharacterCount.configure({
            //     limit: this.maxCharsLength
            // }),
            Mention.configure({
                HTMLAttributes: {
                    class: 'var-editor-variable',
                },
                renderLabel: ({ node }) => {
                    return `${node.attrs.label ?? node.attrs.id}`
                },
                suggestion: this.suggestion
            }),
            Heading.configure({
                levels: [1, 2],
            }),
            BulletList,
            OrderedList,
            ListItem,
            Image.configure({
                allowBase64: true,
                HTMLAttributes: {
                    class: "editor-image"
                }
            }),
            Table,
            TableRow,
            TableHeader,
            TableCell,
        ];

        if (this.parameters.elementButtons?.link) {
            extensions.push(
                Link.configure({
                    openOnClick: false,
                    autolink: false
                })
            );
        }

        return extensions;
    }

    private cleanupUnsuportedLines(rootContent: JSONContent) {
        if (!isValidNumber(this.parameters.limitRows)) return;

        const maxLines = this.parameters.limitRows;
        const { content } = rootContent;

        if (content.length > maxLines) {
            rootContent.content = content.slice(0, maxLines);
        }

    }

    private templateToHTML(content: string = this.content): string {
        this.log({ input: content });
        const html = BBCode.parseHTML(content);
        const withVariables = html.replace(regexDoubleBrackets, (a) => {
            const varName = getNormalizedValue(removeBracket(a), false);

            return `<span data-type="mention" class="var-editor-variable" data-id="${a}" data-label="${varName}" contenteditable="false">${varName}</span>`;
        });

        const parseLines = this.wrapTextNodesWithParagraph(withVariables);

        this.log({ parseLines });

        return parseLines;
    }

    private wrapTextNodesWithParagraph(html: string): string {
        // Parse the input string as a DOM
        const parser = new DOMParser();
        const doc = parser.parseFromString(html, "text/html");

        // Function to recursively process nodes
        function processNode(node: Node): void {
            if (tipTapBlockHTMLElements.includes(node.nodeName)) {
                Array.from(node.childNodes).forEach(processNode);
                return;
            }

            if (!node.nextSibling || tipTapBlockHTMLElements.includes(node.nextSibling.nodeName)) {
                const parent = node.parentElement;
                const nextSibling = node.nextSibling;
                const paragraph = doc.createElement("p");
                const wrappedInParagraphElements = [node];
                let element = node;

                while (element.previousSibling && !tipTapBlockHTMLElements.includes(element.previousSibling.nodeName)) {
                    wrappedInParagraphElements.push(element.previousSibling);
                    element = element.previousSibling;
                }

                for (const child of wrappedInParagraphElements) {
                    parent.removeChild(child);
                }

                paragraph.append(...wrappedInParagraphElements.reverse());

                if (nextSibling) {
                    parent.insertBefore(paragraph, nextSibling);
                } else {
                    parent.appendChild(paragraph);
                }
            }
        }

        // Process all child nodes of the body
        Array.from(doc.body.childNodes).forEach(processNode);

        const allParagraphs = doc.body.querySelectorAll('p');

        // splits into separate paragraphs whenever there's a line break
        allParagraphs.forEach(p => {
            const differentParagraphsTxt = p.innerHTML.split('\n');
            const paragraphs = differentParagraphsTxt.map(pTxt => {
                const pElement = doc.createElement("p");

                pElement.innerHTML = pTxt;

                return pElement;
            });

            p.replaceWith(...paragraphs);
        });

        return doc.body.innerHTML;
    }

    private cleanupVariables(rootContent: JSONContent) {
        rootContent.content?.forEach(content => {

            switch (content.type) {
                case ETiptapNodeNoBBCodeTypes.Doc:
                case ETiptapNodeNoBBCodeTypes.Paragraph:
                    this.cleanupVariables(content);
                    break;
                case ETiptapNodeNoBBCodeTypes.Mention:
                    const isAllowed = this.allVariables.some(v => v.variable === content.attrs.id);

                    if (!isAllowed) {
                        content.type = ETiptapNodeNoBBCodeTypes.Text;
                        content.text = content.attrs.label;
                        content.marks = [{
                            type: ETiptapMarkTypes.Strike
                        }]
                    }
            }

        });
    }

    private generateAppOutput(rootContent: JSONContent): string {

        const generate = (rootContent: JSONContent): string => {
            let finalString: string = '';

            rootContent.content?.forEach((content, idx, array) => {
                const bbTag = tiptapTextStyleMarkToBBTag[content.type]?.tag;

                switch (content.type) {
                    case ETiptapNodeNoBBCodeTypes.Doc:
                        return generate(content);
                    case ETiptapNodeNoBBCodeTypes.Paragraph:
                        const isNextContentParagraph = array[idx + 1]?.type === ETiptapNodeNoBBCodeTypes.Paragraph;

                        finalString += generate(content) + (isNextContentParagraph ? '\n' : '');
                        break;
                    case ETiptapNodeNoBBCodeTypes.Text:
                        finalString += this.applyTextFormat(content.text, content.marks);
                        break;
                    case ETiptapNodeNoBBCodeTypes.Mention:
                        finalString += this.applyTextFormat(content.attrs.id, content.marks);
                        break;
                    case ETiptapNodeHasBBCodeTypes.Heading:
                        const level = content.attrs.level;

                        finalString += `[h${level}]${generate(content)}[/h${level}]`;
                        break;
                    case ETiptapNodeHasBBCodeTypes.Image:
                        const fileBase64 = content.attrs.src.split(':')[1];

                        const tagText = `[${bbTag}]${fileBase64}[/${bbTag}]`;

                        finalString += tagText;
                        break;
                    case ETiptapNodeHasBBCodeTypes.BulletList:
                    case ETiptapNodeHasBBCodeTypes.OrderedList:
                    case ETiptapNodeHasBBCodeTypes.ListItem:
                    case ETiptapNodeHasBBCodeTypes.Table:
                    case ETiptapNodeHasBBCodeTypes.TableRow:
                    case ETiptapNodeHasBBCodeTypes.TableHeader:
                    case ETiptapNodeHasBBCodeTypes.TableCell:
                        finalString += `[${bbTag}]${generate(content)}[/${bbTag}]`;
                        break;
                }
            });

            return finalString;
        }

        return generate(rootContent);
    }

    private applyTextFormat(initial: string, marks?: JSONContent['marks']): string {
        if (!marks) return initial;

        for (const mark of marks) {
            const bbTag = tiptapTextStyleMarkToBBTag[mark.type].tag;
            const isParameterized = tiptapTextStyleMarkToBBTag[mark.type].isParameterized;

            if (bbTag) {
                const startWithSpace = spaceRegExp.test(initial[0])
                const endsWithSpace = spaceRegExp.test(initial[initial.length - 1]);
                let parameter = '';

                if (startWithSpace) {
                    initial = initial.slice(1);
                }

                if (endsWithSpace) {
                    initial = initial.slice(0, -1);
                }

                if (initial === '') {
                    return ''
                }

                if (isParameterized) {
                    const attr = tiptapTextStyleMarkToBBTag[mark.type].tiptapAttrNameForParameter;
                    const value = mark.attrs[attr];

                    parameter = `=${value}`;
                }

                initial = `[${bbTag}${parameter}]${initial}[/${bbTag}]`;

                if (startWithSpace) {
                    initial = ' ' + initial;
                }

                if (endsWithSpace) {
                    initial += ' ';
                }
            }
        }

        return initial;
    }

    suggestion: Omit<SuggestionOptions, 'editor'> = {
        allowSpaces: false,
        items: ({ query }) => {
            let list = [...this.allVariables];
            if (isValidString(query)) {
                const filteredItems = list.filter((word) => {
                    const cleanOption = word.variableWithoutBrackets.toLocaleLowerCase().replaceAll(/\s/g, "");
                    if (cleanOption.includes(query.toLocaleLowerCase())) return true;
                    return false;
                })

                list = filteredItems;
            }

            list.forEach(v => (v.variableWithoutBrackets = removeBracket(v.variable)));

            this.popoverVariablesList = list;
            this.selectedMentionIdx = 0;

            return list;
        },
        render: () => {
            return {
                onStart: props => {
                    this.suggestionProps = props;
                    this.mentionsPopoverHandler = {
                        elementTrigger: props.decorationNode as HTMLElement,
                        template: this.mentionsListTpl,
                        viewContainerRef: this.viewContainerRef,
                    };

                    this.mentionsPopover = this.popoverSvc.create(this.mentionsPopoverHandler);

                    this.mentionsPopover.open(false);
                },
                onUpdate: props => {
                    this.mentionsPopover.elementTrigger = props.decorationNode as HTMLElement;
                    this.suggestionProps = props;

                    if (isInvalidArray(props.items) && this.mentionsPopover?.isVisible)
                        this.resetSuggestions();

                    else if (isValidArray(props.items) && !this.mentionsPopover?.isVisible)
                        this.mentionsPopover?.open(false);
                },
                onKeyDown: props => {
                    const response = this.variablesMentionsKeyHandlers[props.event.key]?.();

                    return response ?? false;
                },
                onExit: props => {
                    this.mentionsPopover?.close();
                }
            }
        }
    }

    private variablesMentionsKeyHandlers = {
        'ArrowUp': () => {
            let next: number = this.selectedMentionIdx - 1;

            if (next === - 1) {
                next = this.popoverVariablesList.length - 1
            }

            this.selectedMentionIdx = next;
            return true;

        },
        'ArrowDown': () => {
            let next: number = this.selectedMentionIdx + 1;

            if (next > this.popoverVariablesList.length - 1) {
                next = 0;
            }

            this.selectedMentionIdx = next;

            return true;
        },
        'Enter': () => {
            const item = this.popoverVariablesList[this.selectedMentionIdx];

            this.selectVariable(item);

            return true;
        },
        'Esc': () => {
            this.resetSuggestions();
            return false;
        },
        'Escape': () => {
            this.resetSuggestions();
            return false;
        }
    }

    private async resetSuggestions() {
        await this.mentionsPopover?.close();
        this.selectedMentionIdx = 0;
        this.popoverVariablesList = [];
    }

    public selectVariable(item: IEditorVariableClient) {
        this.suggestionProps.command({ id: item.variable, label: item.variableWithoutBrackets });
    }

    public hoverSuggestion(idx: number) {
        this.selectedMentionIdx = idx;
    }

    insertVariable(variable: IEditorVariable) {
        this.tiptapInstance.chain().focus().insertContent({
            type: ETiptapNodeNoBBCodeTypes.Mention,
            attrs: { id: variable.variable, label: removeBracket(variable.variable) },
        }).run();
    }

    get internalRawGetSet(): string {
        return this.internalRawContent;
    }

    set internalRawGetSet(value: string) {
        this.internalRawContent = value;

        const html = this.templateToHTML(value);

        this.tiptapInstance.commands.setContent(html, false, { from: 0 });
        this.contentChange.next(value);
    }

    toggleViewRaw(): void {
        this.varEditorUIConfig.viewRawCode = !this.varEditorUIConfig.viewRawCode;
    }

    shouldShowCanonicalAlert(): boolean {
        return this.varEditorTextAreaComponent.shouldShowCanonicalAlert();
    }

    evaluateValueOrFunction(value: any) {
        if (isValidFunction(value)) {
            return value();
        }

        return value;
    }

    hasSomeButtonEnabled(buttons: TTiptapHeaderButton[]): boolean {
        return buttons.some(btn => this.evaluateValueOrFunction(btn.isEnabled));
    }
}
