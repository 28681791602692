<div class="btn__more-options">
    <button *ngFor="let option of getSideMenuOptions();" (click)="onOptionClicked(option)" mat-menu-item>

        <mat-icon *ngIf="isValid(option.getIcon())">{{ option.getIcon() }}</mat-icon>

        <i class="emoji" *ngIf="canShowEmoji(option)">{{ option.getEmoji() }}</i>

        <!-- <span>{{ option.getName() }}</span> -->

    </button>

    <!-- <button (click)="onOptionClicked(getMenuEditOption())" mat-menu-item>
        <mat-icon *ngIf="isValid(getMenuEditOption().getIcon())">{{ getMenuEditOption().getIcon() }}</mat-icon>
        <i class="emoji" *ngIf="canShowEmoji(getMenuEditOption())">{{ getMenuEditOption().getEmoji() }}</i>
        <span>{{ getMenuEditOption().getName() }}</span>
    </button> -->
</div>
