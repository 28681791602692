import { TPostgresPrimaryKey } from '@colmeia/core/src/core-constants/types';
import { TGlobalUID } from '../../business/constant';
import { asConst } from '../../tools/type-utils';
import { getClock, isInvalidString } from '../../tools/utility';
import {
    ENonSerializableObjectType, INonSerializable, INonSerializableHeader, INserMigrationOperation, TNonSerializableArray
} from '../non-serializable-id/non-serializable-id-interfaces';
import { IdDep } from '../non-serializable-id/non-serializable-types';


export function createEmptyPatch(): IPatchDeployModel {
    return {
        nName: '',
        description: '',
        nsType: ENonSerializableObjectType.patchHeader,
        status: [{
            clockTick: getClock(),
            status: EDeployedProdStatus.notDeployed
        }]
    }
}
export enum EDeployedProdStatus {
    notDeployed = 'notDeployed',
    deployed = 'deployed',
    error = 'error',
}

export type TDeployStatusArray = Array<IDeployedStatus>;

export interface IDeployedStatus {
    status: EDeployedProdStatus,
    clockTick: number;
}

export interface IPatchDeployModel extends INonSerializableHeader {
    nsType: ENonSerializableObjectType.patchHeader,
    description: string;
    status: TDeployStatusArray;
}

export interface IProdPatchModelServer extends INonSerializable, IPatchDeployModel {
    nsType: ENonSerializableObjectType.patchHeader,
    idNS?: IdDep<ENonSerializableObjectType.patchHeader>;
};



export interface IProdPatchItem extends INonSerializable, INserMigrationOperation {
    idDeployedNSItem: string;
    itemNsType: ENonSerializableObjectType;
    notes: string;
    versionName: string;
    versionID: TPostgresPrimaryKey;
}

export type TProdPatchItemArray = Array<IProdPatchItem>;

export type TDeployedItemServerArray = Array<IPatchItemServer>;
export interface IPatchItemServer extends INonSerializable, IProdPatchItem {
    idNS?: IdDep<ENonSerializableObjectType.patchItem>;
};




// Deploy Para Produção
export interface IDeployedProduction extends INonSerializableHeader {
    patchHeader: IProdPatchModelServer;
}

export interface IDeployedProductionServer extends IDeployedProduction, INonSerializable {
    description: string;
}

export interface IDeployedItemProduction extends INonSerializableHeader {
    rollback: INonSerializable;
    toProd: INonSerializable;
    toBeDeleted: TNonSerializableArray;
}

export interface IDeployedItemProductionServer extends IDeployedItemProduction, INonSerializable { };




export interface IProdSocialNetworkDeployConfig extends INonSerializableHeader {
    idAvatarProduction: TGlobalUID;
    idSocialNetworkProduction: TGlobalUID;
}

// Mapeamento de Correlações entre Beta e Prod.. PENSAR..
// Avatar para Avatar.. Grupo Para Grupo..
// Social Network para SocialNetwork
export interface IProdSocialNetworkDeployConfigServer extends IProdSocialNetworkDeployConfig, INonSerializable { }

export enum EColmeiaEnvironment {
    Local = 'local',
    QA = 'qa',

    Development = 'dev',
    Beta = 'beta',
    Production = 'prod'
}

export type TColmeiaMainEnvironments =
    | EColmeiaEnvironment.Development
    | EColmeiaEnvironment.QA
    | EColmeiaEnvironment.Beta
    | EColmeiaEnvironment.Production
    ;
export enum EAppBaseURL {
    local = 'http://localhost:4200',
    dev = 'https://dev-app.colmeia.me',
    qa = 'https://qa-app.colmeia.me',
    beta = 'https://beta-app.colmeia.me',
    prod = 'https://app.colmeia.me'
}

export enum EAppBaseURLNew {
    local = 'http://localhost:4200',
    dev = 'https://dev-app.colmeia.cx',
    qa = 'https://qa-app.colmeia.cx',
    beta = 'https://beta-app.colmeia.cx',
    prod = 'https://app.colmeia.cx'
}

export type EnvironmentBaseURLDefinition = { [key in EColmeiaEnvironment]: string };
export type appURLToEnvironmentDefinition = { [key in EAppBaseURL]: EColmeiaEnvironment };

export const environmentBaseURL = asConst<EnvironmentBaseURLDefinition>()({
    [EColmeiaEnvironment.Local]: 'http://localhost:3000',
    [EColmeiaEnvironment.Development]: 'https://dev-api.colmeia.me',
    [EColmeiaEnvironment.QA]: 'https://qa-api.colmeia.cx',
    [EColmeiaEnvironment.Beta]: 'https://beta-api.colmeia.me',
    [EColmeiaEnvironment.Production]: 'https://api.colmeia.me',
} as const);


export const environmentBaseURLNew = asConst<EnvironmentBaseURLDefinition>()({
    [EColmeiaEnvironment.Local]: 'http://localhost:3000',
    [EColmeiaEnvironment.Development]: 'https://dev-api.colmeia.cx',
    [EColmeiaEnvironment.QA]: 'https://qa-api.colmeia.cx',
    [EColmeiaEnvironment.Beta]: 'https://beta-api.colmeia.cx',
    [EColmeiaEnvironment.Production]: 'https://api.colmeia.cx',
} as const);

export const environmentToAppFrontEndURL = asConst<EnvironmentBaseURLDefinition>()({
    [EColmeiaEnvironment.Local]: EAppBaseURL.local,
    [EColmeiaEnvironment.Development]: EAppBaseURL.dev,
    [EColmeiaEnvironment.QA]: EAppBaseURL.qa,
    [EColmeiaEnvironment.Beta]: EAppBaseURL.beta,
    [EColmeiaEnvironment.Production]: EAppBaseURL.prod,
} as const);

export const environmentAppURL = asConst<EnvironmentBaseURLDefinition>()({
    [EColmeiaEnvironment.Local]: EAppBaseURL.local,
    [EColmeiaEnvironment.Development]: EAppBaseURL.dev,
    [EColmeiaEnvironment.QA]: EAppBaseURL.qa,
    [EColmeiaEnvironment.Beta]: EAppBaseURL.beta,
    [EColmeiaEnvironment.Production]: EAppBaseURL.prod,
} as const);

export const environmentAppURLNew = asConst<EnvironmentBaseURLDefinition>()({
    [EColmeiaEnvironment.Local]: EAppBaseURLNew.local,
    [EColmeiaEnvironment.Development]: EAppBaseURLNew.dev,
    [EColmeiaEnvironment.QA]: EAppBaseURLNew.qa,
    [EColmeiaEnvironment.Beta]: EAppBaseURLNew.beta,
    [EColmeiaEnvironment.Production]: EAppBaseURLNew.prod,
} as const);

/**
 * @deprecated
 * use the function: getEnvironmentByAppUrl
 */
export const appURLToEnvironment = asConst<appURLToEnvironmentDefinition>()({
    [EAppBaseURL.local]: EColmeiaEnvironment.Local,
    [EAppBaseURL.dev]: EColmeiaEnvironment.Development,
    [EAppBaseURL.qa]: EColmeiaEnvironment.QA,
    [EAppBaseURL.beta]: EColmeiaEnvironment.Beta,
    [EAppBaseURL.prod]: EColmeiaEnvironment.Production,
} as const);

export const environmentToLoader = asConst()({
    // [EColmeiaEnvironment.Local]: 'http://dev-embedded.colmeia.me/widgets/loader.js',
    // [EColmeiaEnvironment.Beta]: 'http://beta-embedded.colmeia.me/widgets/loader.js',
    // [EColmeiaEnvironment.Development]: 'http://dev-embedded.colmeia.me/widgets/loader.js',
    [EColmeiaEnvironment.Local]: 'https://storage.googleapis.com/dev-embedded.colmeia.me/widgets/loader.js',// 'http://localhost:8080/loader.js',
    [EColmeiaEnvironment.Development]: 'https://storage.googleapis.com/dev-embedded.colmeia.me/widgets/loader.js',
    [EColmeiaEnvironment.QA]: 'https://storage.googleapis.com/dev-embedded.colmeia.me/widgets/loader.js',
    [EColmeiaEnvironment.Beta]: 'https://storage.googleapis.com/beta-embedded.colmeia.me/widgets/loader.js',
    [EColmeiaEnvironment.Production]: 'https://embedded.colmeia.me/widgets/loader.js',
} as const)

enum EAppURLEnv {
    local = 'localhost',
    dev = 'dev-app',
    qa = 'qa-app',
    beta = 'beta-app',
    prod = 'app'
}

const partialEnvToEnvironment = asConst<{ [key in EAppURLEnv]: EColmeiaEnvironment }>()({
    [EAppURLEnv.local]: EColmeiaEnvironment.Local,
    [EAppURLEnv.dev]: EColmeiaEnvironment.Development,
    [EAppURLEnv.qa]: EColmeiaEnvironment.QA,
    [EAppURLEnv.beta]: EColmeiaEnvironment.Beta,
    [EAppURLEnv.prod]: EColmeiaEnvironment.Production,
} as const);


export const getEnvironmentByAppUrl = (url: string): EColmeiaEnvironment | undefined => {
    if (isInvalidString(url)) return;
    const url_ = new URL(url);
    const hostname = url_.hostname;
    for (const [key, value] of Object.entries(partialEnvToEnvironment)) {
        if (hostname.startsWith(key)) return value;
    }
}

export function isLocalEnvironment(): boolean {
    const currentUrl = window.location.origin
    const localFrontendURL = environmentAppURL[EColmeiaEnvironment.Local]
    return currentUrl.startsWith(localFrontendURL)
}
