import { Component, Inject, OnInit } from "@angular/core";

import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { IContactListServer } from "@colmeia/core/src/shared-business-rules/contact-list/contact-list.model";
import { ENonSerializableObjectType } from "@colmeia/core/src/shared-business-rules/non-serializable-id/non-serializable-id-interfaces";
import { GenericDashboardEditHandler, IGenericDashboardEditPageClientCallback } from "app/handlers/generic-dashboard-edit.handler";
import { RequestBuilderServices } from "app/services/request-builder.services";
import { ContactListService } from "../contact-list.service";

export interface IConctacTListCreateParameters {
    nser: IContactListServer;
}

export interface IConctacTListCreateResponse {
    saved: boolean;
}

@Component({
    selector: "contact-list-create",
    styleUrls: ["./contact-list-create.component.scss"],
    templateUrl: "./contact-list-create.component.html",
})
export class ContactListCreateComponent implements OnInit, IGenericDashboardEditPageClientCallback {

    private nsType: ENonSerializableObjectType = ENonSerializableObjectType.contactList;

    public genericEditHandler: GenericDashboardEditHandler;

    public nser: IContactListServer;

    constructor(
        private rbs: RequestBuilderServices,
        private matDialog: MatDialogRef<IConctacTListCreateParameters, IConctacTListCreateResponse>,
        @Inject(MAT_DIALOG_DATA) private dialogData: IConctacTListCreateParameters,
        private contactListSvc: ContactListService,
    ) { }

    ngOnInit() {
        this.nser = this.dialogData.nser;
        this.initGenericDashboardEditHandler();
    }

    private initGenericDashboardEditHandler() {
        this.genericEditHandler = new GenericDashboardEditHandler({
            nsType: this.nsType,
            clientCallback: this,
            nser: this.nser,
            allowEditTitleInHeader: true,
            autoValidate: true,
        })
    }

    async onGenericBackButtonPressed?(): Promise<void> {
        this.close();
    }

    async onGenericSaveButtonPressed?(): Promise<void | boolean> {
        this.save();
    }

    public close(response: IConctacTListCreateResponse = { saved: false }) {
        this.matDialog.close(response)
    }

    async save() {
        const savedNs = await this.contactListSvc.save(this.nser);
        this.close({ saved: !!savedNs });
    }
}