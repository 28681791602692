<div
  class="l-container"
  (click)="goToLink()"
  [class.is-file-mode]="isFileMode"
  [class.has-url]="!isDefaultUrl()"
  [class.nice-scroll]="visualizationType === HexagonVisualizationType.PDF"
  [class.pdf-preview]="visualizationType === HexagonVisualizationType.PDF"
  [class.unknown-preview]="visualizationType === HexagonVisualizationType.Unknown"
  [ngClass]="visualizationType"
>
  <ng-container [ngSwitch]="visualizationType">

    <div *ngSwitchCase="HexagonVisualizationType.Image" class="image-container" [ngClass]="wrapperClass">

      <a *ngIf="isFileMode" class="preview-link preview--file" [href]="safeResourceUrl" target="_blank">
        <img class="image-cover" [src]="safeResourceUrl">
      </a>
      <img *ngIf="!isFileMode" class="image-cover" [ngClass]="{'show-border': previewShouldShowBorder}" [src]="safeResourceUrl">
      <div class="icon__container">
        <mat-icon *ngIf="showIcon">{{ icon }}</mat-icon>
      </div>
    </div>

    <ng-container *ngSwitchCase="HexagonVisualizationType.PDF">
      <a class="preview-link preview--pdf" [href]="safeResourceUrl" target="_blank">
        <mat-icon>launch</mat-icon>
      </a>
      <iframe class="pdf-preview-container nice-scroll" [src]="safeResourceUrl" frameborder="0"></iframe>
    </ng-container>

    <ng-container *ngSwitchCase="HexagonVisualizationType.Unknown">
      <a class="preview-link preview--unknown" [href]="safeResourceUrl" [attr.download]="getDownloadName()">
        {{ urlMimeType }}&nbsp; <mat-icon>download</mat-icon>
      </a>
      <ng-content select="[hexagon-custom-preview]"></ng-content>
    </ng-container>

  </ng-container>
</div>
