import { Injectable } from "@angular/core";
import { TGlobalUID } from "@colmeia/core/src/business/constant";
import { getBlankNonSerializableHeader } from "@colmeia/core/src/nser/blank-ns";
import { ISaveGenericNSRequest } from "@colmeia/core/src/request-interfaces/lookup-ns-request";
import { apiRequestType } from "@colmeia/core/src/request-interfaces/message-types";
import { IResponse } from "@colmeia/core/src/request-interfaces/response-interfaces";
import { ECopilotContext, ICopilotServer } from "@colmeia/core/src/shared-business-rules/copilot/copilot.model";
import { ENonSerializableObjectType } from "@colmeia/core/src/shared-business-rules/non-serializable-id/non-serializable-id-interfaces";
import { isValidRef } from "@colmeia/core/src/tools/barrel-tools";
import { ColmeiaWindowRef } from "app/components/dashboard/dashboard-foundation/colmeia-window/colmeia-window-ref";
import { Subscription } from "rxjs";
import { filter, take } from "rxjs/operators";
import { GroupNavigationEnd } from "./controllers-services/navigator/navigator";
import { NavigatorServices } from "./controllers-services/navigator/navigator.service";
import { GenericDashboardRequester } from "./generic-dashboard-requester.service";
import { RequestBuilderServices } from "./request-builder.services";
import { SessionService } from "./session.service";


@Injectable({
    providedIn: "root",
})
export class AttendanceCopilotService {
    public readonly nsType: ENonSerializableObjectType = ENonSerializableObjectType.attCopilot;
    private navigationSubscription: Subscription;
    private copilotWindowRefMap: Map<TGlobalUID, Record<string, ColmeiaWindowRef>> = new Map();

    constructor(
        private requester: GenericDashboardRequester,
        private rbs: RequestBuilderServices,
        private session: SessionService,
        private navigator: NavigatorServices,
    ) {
        this.navigationSubscription = this.navigator.groupNavigation$.pipe(filter((event) => event instanceof GroupNavigationEnd)).subscribe((navEvent: GroupNavigationEnd) => {
            const copilotWindowRef = this.copilotWindowRefMap.get(navEvent.idGroup);

            const entries = this.copilotWindowRefMap.entries();
            Array.from(entries).forEach(([id,record]) => {
                Object.entries(record).forEach(([_,value]) => {
                    if (value.isVisible) {
                        value?.minimize();
                    }
                })
            })
            if (isValidRef(copilotWindowRef)) {
                Object.entries(copilotWindowRef).forEach(([_, ref]) => {
                    ref?.restore()
                });
            }
        })
    }

    setCopilotWindowRef(groupId: TGlobalUID, ref: ColmeiaWindowRef) {
        ref.afterClosed().pipe(take(1)).subscribe(() => this.copilotWindowRefMap.delete(groupId))

        if (this.copilotWindowRefMap.has(groupId)) {
            const refs = this.getCopilotWindowRefs(groupId);
            const updatedRefs = {...refs, [ref.windowIdentifier]: ref}
            this.copilotWindowRefMap.set(groupId, updatedRefs);
        }
        else {
            this.copilotWindowRefMap.set(groupId, {[ref.windowIdentifier]: ref});
        }
    }
    getCopilotWindowRefs(groupId: TGlobalUID) {
        return this.copilotWindowRefMap.get(groupId);
    }
    deleteCopilotWindowRefs(groupId: TGlobalUID) {
        this.copilotWindowRefMap.delete(groupId)
    }

    async saveCopilot(nser: ICopilotServer): Promise<boolean> {
        const request: ISaveGenericNSRequest = {
            ...this.rbs.secureBasicRequest(apiRequestType.nonSerializable.genericNSSave),
            nserList: [nser]
        };

        return this.requester.request(
            request,
            false
        ).then((response: IResponse | undefined) => {
            return !!response;
        });
    }

    initBlankCopilot(): ICopilotServer {
        return {
            deployedServiceId: '',
            copilotContext: ECopilotContext.perAttendance,
            ...getBlankNonSerializableHeader('', false),
            nsType: this.nsType,
            //@ts-ignore
            ident: null,
            tags: [{
                idAvatar: this.session.getAvatarID(),
                idTag: "uF4rnU1BDPiHlpWwQQRY",
                clockTick: new Date().getTime()
            }]
        };
    }
}
