import { MainHandler } from "../main-handler";
import { IComponentParameter, IRootCallBack } from '../../model/component-comm/basic';
import { ITranslationConfig } from "@colmeia/core/src/shared-business-rules/translation/translation-engine";
import { HandlerHexagonon } from "../hexagono.handler";
import { gTranslations } from "@colmeia/core/src/shared-business-rules/const-text/translations";
import { Serializable } from "@colmeia/core/src/business/serializable";
import { lowerCase } from 'lodash';
import { MultimediaObject } from "@colmeia/core/src/multi-media/multi-media-object";
import { MMconstant, MultimediaInstance } from "@colmeia/core/src/multi-media/barrel-multimedia";
import { GD_MultipleEntityButton } from "app/components/dashboard/dashboard-foundation/generic-dashboard-home/generic-dashboard-home.model";
import { genericTypedSuggestions } from "@colmeia/core/src/tools/type-utils";
import { isInvalid, printTimeInfo, isValidRef } from "@colmeia/core/src/tools/utility";
import { TPrintTimeInfoShouldHideOptions } from "@colmeia/core/src/tools/utility-types";
import { DynamicComponentHandler } from 'app/components/dashboard/dashboard-data-extractor/cm-dynamic-component/cm-dynamic-component.handler'


// ROW FIELD

export enum EGenericTableGenericRow {
    TinyIcon = 'TinyIcon',
    Hexagon = 'Avatar',
    Text = 'Text',
    DateTime = 'DateTime',
    Date = 'Date',
    Select = 'Select',
    Button = 'Outlet',
    IconButton = 'IconButton',
    Handler = 'Handler',
    Dropdown = 'Dropdown'
}

export interface IGenericTableGenericRowField {
    type: EGenericTableGenericRow;
    enableOnClick?: true;
    onClick?(): void;
    onHoverShowText?: string;
    isVisible?(): boolean;
    style?: Record<string, string>;
    helperInfo?: string;
}
export enum GenericTableRowFieldIconType {
    Image = 'Image',
    Svg = 'Svg',
}

// essa interface serve para ser extendida, portanto não será implementada
interface IGenericTableRowFieldIcon {
    icon: string;
    iconType?: GenericTableRowFieldIconType;
}

// FIELD > ICON
export interface IGenericTableRowFieldTinyIcon extends IGenericTableGenericRowField, IGenericTableRowFieldIcon {
    type: EGenericTableGenericRow.TinyIcon;
    color?: string;
}

export interface IGenericTableRowFieldIconButton extends IGenericTableGenericRowField, IGenericTableRowFieldIcon {
    type: EGenericTableGenericRow.IconButton;
}


export interface IGenericTableRowFieldHandler<Handler extends MainHandler = MainHandler> extends IGenericTableGenericRowField {
    type: EGenericTableGenericRow.Handler;
    handler: DynamicComponentHandler<Handler>;
}


export interface IGenericTableRowFieldHexagon extends IGenericTableGenericRowField {
    type: EGenericTableGenericRow.Hexagon;
    handler: HandlerHexagonon;
    text?: string;
}
export interface IGenericTableRowFieldSelect extends IGenericTableGenericRowField {
    type: EGenericTableGenericRow.Select;
    options: {
        text: string,
        value: any
    }[]
}

export interface IGenericTableRowFieldButton extends IGenericTableGenericRowField {
    type: EGenericTableGenericRow.Button;

};


export interface IGenericTableRowFieldText extends IGenericTableGenericRowField {
    type: EGenericTableGenericRow.Text;
    value: string;
    link?: string;
    status?: EGenericTableRowFieldTextStatus;
    shouldUseBBCode?: boolean;
    helpIconInfo?: string;
};

export interface IGenericTableRowFieldDateTime extends IGenericTableGenericRowField {
    type: EGenericTableGenericRow.DateTime;
    time: number;
};
export interface IGenericTableRowFieldDate extends IGenericTableGenericRowField {
    type: EGenericTableGenericRow.Date;
    time: number;
};

export interface IDropdownOptions {
    icon: string,
    label: string,
    onClick: () => void
    helperText?: string,
}

export type TDropdownOptions = Array<IDropdownOptions>;
export interface IGenericTableRowFieldDropdown extends IGenericTableGenericRowField {
    type: EGenericTableGenericRow.Dropdown;
    options: TDropdownOptions;
};


type DefineTableRowField<T extends {
    [key in EGenericTableGenericRow]: IGenericTableGenericRowField
}> = T;

export type TGenericTableTableRowValue = DefineTableRowField<{
    [EGenericTableGenericRow.Text]: IGenericTableRowFieldText;
    [EGenericTableGenericRow.Hexagon]: IGenericTableRowFieldHexagon;
    [EGenericTableGenericRow.TinyIcon]: IGenericTableRowFieldTinyIcon;
    [EGenericTableGenericRow.DateTime]: IGenericTableRowFieldDateTime;
    [EGenericTableGenericRow.Date]: IGenericTableRowFieldDate;
    [EGenericTableGenericRow.Select]: IGenericTableRowFieldSelect;
    [EGenericTableGenericRow.Button]: IGenericTableRowFieldButton;
    [EGenericTableGenericRow.IconButton]: IGenericTableRowFieldIconButton;
    [EGenericTableGenericRow.Handler]: IGenericTableRowFieldHandler;
    [EGenericTableGenericRow.Dropdown]: IGenericTableRowFieldDropdown;
}>;

export enum EGenericTableRowFieldTextStatus {
    Green = 'Green',
    Orange = 'Orange',
    Disabled = 'Disabled',
}
//


export interface IStatusConfig {
    color: string;
}
export const statusConfigDB = genericTypedSuggestions<{ [key in EGenericTableRowFieldTextStatus]: IStatusConfig }>()({
    [EGenericTableRowFieldTextStatus.Green]: {
        color: 'green',
    },
    [EGenericTableRowFieldTextStatus.Orange]: {
        color: 'orange',
    },
    [EGenericTableRowFieldTextStatus.Disabled]: {
        color: 'rgba(0, 0, 0, 0.5)',
    },
});

export type TGenericTableRow = {
    // entity?: {};
};

enum EGetRowsAction {
    next = 'next',
    refresh = 'refresh',
}
export interface GetRowsOptions {
    action: EGetRowsAction;
}


export interface IOnRowFieldClick<Row, Entity extends {} = {}> {
    field: IGenericTableGenericRowField;
    row: Row;
    columnName: keyof Row;
    entity?: Entity;
}
export interface IGenericTableMovePositionMovement {
    from: number;
    to: number;
}

export interface IGenericTableHandlerClientCallback<Row extends TGenericTableRow, Entity extends {} = {}> extends IRootCallBack {
    onRowClick?(row: Row): void | Promise<void>;

    /**
     * @deprecated
    */
    onRowFieldClick?(information: IOnRowFieldClick<Row, Entity>): void | Promise<void>;
    onRowFieldSelect?(field: Row, value: any): void | Promise<void>;

    getRows?(options?: GetRowsOptions): Row[] | Promise<Row[]>;

    entityMode?: {
        mapEntityToRow(entity: Entity): Row;
        getEntities(): Promise<Entity[]>;
    };
    onMultipleRowSelectedButtonClick?(rows: Row[]): void | Promise<void>;
    onChangePagination?(value: EPageSizeOption): void;
    onGenericTableMovePosition?(row: Row, movement: IGenericTableMovePositionMovement): void | Promise<void>;
}

export type TGenericTableHandlerText = ITranslationConfig | string;
export interface IExtractColumn {
    text: TGenericTableHandlerText,
    size: number
    color?: string; // implement
}

export enum EPageSizeOption {
    ten = '10',
    fifteen = '15',
    twenty = '20',
    fifty = '50',
    hundred = '100',
    hundredFifty = '150',
    twoHundred = '200',
}
export interface IGenericTableTextFixed {
    pre?: string;
    su?: string;
}

export interface IGenericTableHandlerParameters<Row extends TGenericTableRow, Entity extends {} = {}> {
    title?: TGenericTableHandlerText;
    clientCallback: IGenericTableHandlerClientCallback<Row, Entity>;
    rowNames: { [key in keyof Row]?: TGenericTableHandlerText };
    multiplePickAble?: {
        callbacks: GD_MultipleEntityButton[];
    },
    movePositions?: { originalElements: object[]; };
    extraColumns?: IExtractColumn[];
    pagination?: EPageSizeOption;
    enableRefresh?: true;
    enableGoBackButton?: true;
    executeCallbackOnly?: boolean;
    goBackButtonCB?: () => void;
    hideNavigation?: true;
    enableEqualSize?: true;
    removeShadows?: true;
    minWidth?: number;
    newDesign?: boolean;
    rowsHeight?: string;
    filter?: (row: Row) => boolean;

    allRows?: Row[];
}

export type TGenericTableHandlerParameters<Row extends TGenericTableRow, Entity extends {} = {}> = IGenericTableHandlerParameters<Row, Entity>;

function defaultParameters<Row>(): Partial<IGenericTableHandlerParameters<Row>> {
    return {
        pagination: EPageSizeOption.hundred,
    };
}

//


export interface IGenericTableSlave {
    onRefresh(): Promise<void>;
    localRefreshRows(): void
}
export class GenericTableHandler<Row extends TGenericTableRow, Entity extends {} = {}> extends MainHandler {

    private columns: (keyof Row)[];

    private constructor(parameters: TGenericTableHandlerParameters<Row, Entity>) {
        type TempParameters = TGenericTableHandlerParameters<Row> & IComponentParameter; // use direcly parameters istead of this type if already use clientCallback
        const defaultParams = defaultParameters<Row>();
        for (let field in defaultParams) if (isInvalid(parameters[field])) parameters[field] = defaultParams[field];
        super(parameters as TempParameters);
    }

    public static factory<Row extends TGenericTableRow, Entity extends {} = {}>(parameters: TGenericTableHandlerParameters<Row, Entity>): GenericTableHandler<Row, Entity> {
        return new GenericTableHandler<Row, Entity>(parameters);
    }

    public getComponentParameter(): TGenericTableHandlerParameters<Row, Entity> {
        return super.getComponentParameter() as TGenericTableHandlerParameters<Row, Entity>;
    }

    public static field<T extends EGenericTableGenericRow>(type: T) {
        type FoundField = TGenericTableTableRowValue[T];

        return <T extends Omit<FoundField, 'type'>>(partialField: T) => ({
            ...partialField,
            type,
        } as unknown as FoundField);
    }


    private slave: IGenericTableSlave;

    public setSlave(slave: IGenericTableSlave): void {
        this.slave = slave;
    }
    public getSlave(): IGenericTableSlave {
        return this.slave;
    }

    public refresh(): Promise<void> {
        return this.slave.onRefresh();
    }

    public localRefreshRows(): void {
        this.slave?.localRefreshRows?.();
    }

    public static numberField(number: number, defaultText?: string): IGenericTableRowFieldText {
        return GenericTableHandler.field(EGenericTableGenericRow.Text)({
            value: number ? String(number) : defaultText
        });
    }

    public static textField(text: string, defaultText?: string, fixed: IGenericTableTextFixed = { su: '', pre: '' }, status?: EGenericTableRowFieldTextStatus, helpIconInfo?: string): IGenericTableRowFieldText {
        return GenericTableHandler.field(EGenericTableGenericRow.Text)({
            value: text ? `${fixed.pre}${text}${fixed.su}` : defaultText,
            status,
            helpIconInfo,
        });
    }

    public static textFromMS(time: number, shouldHide?: TPrintTimeInfoShouldHideOptions) {
        return printTimeInfo(time, {
            minutes: {
                singular: GenericTableHandler.getLowerCaseTranslation(gTranslations.common.minute),
                plural: GenericTableHandler.getLowerCaseTranslation(gTranslations.common.minutes)
            },
            seconds: {
                singular: GenericTableHandler.getLowerCaseTranslation(gTranslations.common.second),
                plural: GenericTableHandler.getLowerCaseTranslation(gTranslations.common.seconds)
            },
            hours: {
                singular: GenericTableHandler.getLowerCaseTranslation(gTranslations.common.hour),
                plural: GenericTableHandler.getLowerCaseTranslation(gTranslations.common.hours)
            },
            days: {
                singular: GenericTableHandler.getLowerCaseTranslation(gTranslations.common.day),
                plural: GenericTableHandler.getLowerCaseTranslation(gTranslations.common.days)
            },
            months: {
                singular: GenericTableHandler.getLowerCaseTranslation(gTranslations.common.month),
                plural: GenericTableHandler.getLowerCaseTranslation(gTranslations.common.months)
            },
            and: GenericTableHandler.getLowerCaseTranslation(gTranslations.common.and),
        }, shouldHide)
    }

    public static textFieldByMiliseconds(time: number, options: { fixed?: IGenericTableTextFixed, from?: number, shouldHide?: TPrintTimeInfoShouldHideOptions, emptyTimePhrase?: string, defaultText?: string, suffix?: string }): IGenericTableRowFieldText {
        const calculatedTime: number = isValidRef(options.from) ? options.from - time : time;
        const text = (time ? GenericTableHandler.textFromMS(calculatedTime, { seconds: true, ...options.shouldHide }) : options.emptyTimePhrase);
        return GenericTableHandler.textField(text, options.defaultText, options.fixed);
    }

    public static getLowerCaseTranslation(field: ITranslationConfig): string {
        return lowerCase(Serializable.getTranslation(field));
    }

    public static tinyIconField(icon: string, color?: string): IGenericTableRowFieldTinyIcon {
        return GenericTableHandler.field(EGenericTableGenericRow.TinyIcon)({
            icon,
            color,
        });
    }

    public static iconButtonField(icon: string): IGenericTableRowFieldIconButton {
        return GenericTableHandler.field(EGenericTableGenericRow.IconButton)({
            icon
        });
    }

    public static hexagonField(idMedia: string, text?: string): IGenericTableRowFieldHexagon {
        return GenericTableHandler.field(EGenericTableGenericRow.Hexagon)({
            handler: HandlerHexagonon.newHandler({
                forceImage: idMedia || MMconstant.profilePhoto.avatar,
                size: "smd",
            }),
            text,
        });
    }

    public getColumns(): (keyof Row)[] {
        if (isInvalid(this.columns)) this.loadColumns();
        return this.columns;
    }

    private loadColumns(): void {
        const columns: { [key: string]: true } = {};
        for (let key in this.getComponentParameter().rowNames) columns[key] = true;
        this.columns = Object.keys(columns).filter(item => item !== 'entity') as (keyof Row)[];
    }


    public enableExtraColumns(): boolean {
        return isValidRef(this.getComponentParameter().extraColumns);
    }

}

//// ///



// interface IDate
export class GenericTableFieldCreator {

    static dateTime(time: number): IGenericTableRowFieldDateTime {
        return {
            type: EGenericTableGenericRow.DateTime,
            time
        }
    }

    static icon = GenericTableHandler.tinyIconField
    static doneIcon = GenericTableFieldCreator.icon('done', '#33d52d');

    static iconButton = GenericTableHandler.iconButtonField

    static date(time: number): IGenericTableRowFieldDateTime {
        return {
            type: EGenericTableGenericRow.DateTime,
            time
        }
    }
    static dateTimeFromString(date: string): IGenericTableRowFieldDateTime {
        return {
            type: EGenericTableGenericRow.DateTime,
            time: new Date(date).getTime(),
        }
    }

    public static hexagon(idMedia: string, text?: string): IGenericTableRowFieldHexagon {
        const instance: MultimediaInstance = idMedia ? MultimediaObject.getNewMultimediaObjectFromIdMedia(idMedia).getAllMultimediaInstance()[0] : undefined;

        return {
            type: EGenericTableGenericRow.Hexagon,
            handler: HandlerHexagonon.newHandler({
                multimediaInstance: instance,
                size: "smd",
                forceImage: ''
            }),
            text,
        }
    }


    static text(text: string, defaultText?: string): IGenericTableRowFieldText {
        return {
            type: EGenericTableGenericRow.Text,
            value: text || defaultText,
        }
    }

    static select(options: {
        text: string,
        value: any
    }[]): IGenericTableRowFieldSelect {
        return {
            type: EGenericTableGenericRow.Select,
            options
        }
    }

    static handler<Handler extends MainHandler = MainHandler>(handler: DynamicComponentHandler<Handler>): IGenericTableRowFieldHandler<Handler> {
        return {
            type: EGenericTableGenericRow.Handler,
            handler,
        }
    }

    static dropdown(options: TDropdownOptions): IGenericTableRowFieldDropdown {
        return {
            type: EGenericTableGenericRow.Dropdown,
            options
        }
    }

}


// SPANNER
interface ISpannerAgentSummarySQLResult {
    status: string;
    action: string;
    login_time: number;
}

type GeneratedPreview = TGenericTableConversor<ISpannerAgentSummarySQLResult>

// //


type TFieldConversor<T> =
    T extends number ? IGenericTableRowFieldText :
    T extends string ? IGenericTableRowFieldText :
    never;

export type TGenericTableConversor<T> = {
    [key in keyof T]: TFieldConversor<T[key]>
};

export type TSimpleGenericTableConversor<T extends object, ToOmit extends keyof T = never, Excluded extends keyof T = Exclude<keyof T, ToOmit>> = {
    [key in Excluded]: IGenericTableGenericRowField
};

// //


