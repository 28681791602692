import { Avatar } from "@colmeia/core/src/business/avatar";
import { EChatViewStyle } from "../../backbone/chat-backbone.component";
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from "@angular/core";
import { IListenerSecuredContext, IListenerSubscription } from "../../../model/signal/ps-interfaces";
import { canGroupTypeShowParticipantList } from "@colmeia/core/src/rules/filters";
import { TGroupArray } from "@colmeia/core/src/core-constants/types";
import { SubscriptionSignal } from "../../../model/signal/subscription-signal";
import { Serializable } from "@colmeia/core/src/business/serializable";
import { IChatBackboneRightBar } from "../../../model/chat-backbone.model";
import { SignalListenerService } from "../../../services/signal/signal-listener";
import { SessionService } from "../../../services/session.service";
import { RouterManagerService } from "../../../services/router-manager.service";
import { SecurityContextSignal } from "../../../model/signal/state-signals/sec-contex-signal";
import { Group } from "@colmeia/core/src/business/group";
import { routeID } from "../../../model/routes/route-constants";
import { Memoize } from "typescript-memoize";
import { Interaction } from "@colmeia/core/src/interaction/interaction";
import { TThreadArray } from "@colmeia/core/src/interaction/thread";
import { ITasksFilter } from "@colmeia/core/src/rules/dynamic-filter/dynamic-filter"
import { ColmeiaDialogService } from "app/services/dialog/dialog.service";
import { SignalPublisherService } from "app/services/signal/signal-publisher"
import { ESearchAllowedType } from "@colmeia/core/src/shared-business-rules/search";
import { EChatSideBarViewMode, EChatSideBarChannels, EChatSideBarSpecialsFilters, EChatSideBarTasks } from '@colmeia/core/src/shared-business-rules/const-text/another'
import {groupChatRightSideBarsId} from "@colmeia/core/src/shared-business-rules/visual-constants";
import { AttendanceService } from "app/services/attendance.service";
import { HardwareLayerService } from "app/services/hardware";
import { MacroMenuToggleService } from "../macro-menu-toggle.service";
import { Subscription } from "rxjs";

enum ESelectionType {
    None,
    Channel,
    MultipleChoice,
    MutuallyExclusive
}

type TAvatarViewList = IAvatarView[]

export interface IAvatarView {
    avatar: Avatar;
    selected: boolean;
}

interface INameable {
    name: string;
}

interface IUnique {
    id: number;
}

interface IOption extends INameable, IUnique {
    selected?: boolean;
}

interface IOptionMutualExclusive extends INameable {
    value: number;
}

interface ISection extends INameable, IUnique {
    type: ESelectionType;
    options: TOptionList;
}

interface ISectionMutualExclusive extends ISection {
    valueSelected: number;
}

interface IOptionToChatDisplay {
    [key: number]: EChatViewStyle | EChatSideBarViewMode | number
}

type TSection = ISection | ISectionMutualExclusive;
type TOption = IOption | IOptionMutualExclusive;
type TOptionList = Array<TOption>;
type TSectionList = Array<TSection>;

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'app-group-chat-right-side-bar',
    templateUrl: './group-chat-right-side-bar.component.html',
    styleUrls: ['./group-chat-right-side-bar.component.scss'],
})
export class GroupChatRightNavSideComponent implements OnInit, OnDestroy, IListenerSubscription, IListenerSecuredContext {
    private participantList: TAvatarViewList = [];
    private currentTasksSelected: ITasksFilter;
    private sections: TSectionList;
    private chatGroupList: TGroupArray = [];
    private subsSignal: SubscriptionSignal;
    private sidebarLabels: Serializable;
    private subscription: Subscription
    private optionChatDisplayMap: IOptionToChatDisplay = {
        [EChatSideBarViewMode.chat]: EChatViewStyle.chat,
        [EChatSideBarViewMode.forum]: EChatViewStyle.forum,
        [EChatSideBarViewMode.topic]: EChatViewStyle.topic,
        [EChatViewStyle.chat]: EChatSideBarViewMode.chat,
        [EChatViewStyle.forum]: EChatSideBarViewMode.forum,
        [EChatViewStyle.topic]: EChatSideBarViewMode.topic,
    };

    public specialFilters: TSection;
    public hasServices: boolean = false;
    public isMobile: boolean;

    @Input() private chatBackBoneModelInstance: IChatBackboneRightBar

    constructor(
        private cdr: ChangeDetectorRef,
        private routingMgr: RouterManagerService,
        private listener: SignalListenerService,
        private sessionSvc: SessionService,
        private dialogSvc: ColmeiaDialogService,
        private publisher: SignalPublisherService,
        private attendanceSvc: AttendanceService,
        private hw: HardwareLayerService,
        public macroMenuService: MacroMenuToggleService
    ) {
        this.isMobile = this.hw.isMobile();
        this.subscription = this.macroMenuService.macroMenuEnabled$.subscribe((enabled) => {
            this.isMacroMenuEnabled()
        })
    }

    ngOnInit() {
        this.currentTasksSelected = {};
        this.sidebarLabels = Serializable.staticFactory(groupChatRightSideBarsId);
        this.initSections();
        this.listener.listenSubscriptionChanges(this);
        this.listener.listenToSecurityChangedContext(this);
    }

    public ngOnDestroy(): void {
        this.listener.destroySubscriptions(this);
        this.subscription.unsubscribe()
    };

    canShowParticipants(): boolean {
        const isAdmin = this.sessionSvc.isAdminOnCurrentGroup();
        return isAdmin || canGroupTypeShowParticipantList(this.subsSignal.getGroup());
    }

    public isMacroMenuEnabled(): boolean {
        return this.macroMenuService.isMacroMenuEnabled()
    }

    //#region Callbacks
    changeOnSecureContextSignCallback(context: SecurityContextSignal) {
        this.chatGroupList = context.getMyChanels(this.subsSignal.getGroupID());
        this.cdr.markForCheck();
    };

    changeSubscriptionSignCallback(subscriptionSignal: SubscriptionSignal): void {
        if (!subscriptionSignal.getGroup().getGroupType().isRoot()) {
            this.subsSignal = subscriptionSignal;
            this.chatGroupList = this.subsSignal.getAllChannels();
            const uniqueAvatars = [...new Set(this.subsSignal.getParticipants().map(par => par.getAvatar()))]
            this.participantList = uniqueAvatars.map(avatar => ({
                avatar: avatar,
                selected: false,
            }));
            this.specialFilters = this.getServiceFilter();

            this.cdr.markForCheck();
        }
    };
    //#endregion

    //#region Filoers



    goToChannel(channel: Group): void {
        this.routingMgr.goToGroup(
            channel.getGroupID(),
            routeID.groups.chat)
    }

    hasParticipants(): boolean {
        return this.getParticipants().length > 0;
    }

    hasSomeChannel(): boolean {
        return this.getChannelList().length > 0
    }

    @Memoize()
    getPeopleLabel(): string {
        return Serializable.staticFactory(ESearchAllowedType.people).getName()
    }

    @Memoize()
    getChannelLabel(): string {
        return this.sidebarLabels.getSerializableText(EChatSideBarChannels.id)
    }

    initSections(): void {
        this.sections = [
            this.getViewModeSection(),
            this.getTasksSection()
        ];
        this.specialFilters = {
            id: EChatSideBarSpecialsFilters.id,
            type: ESelectionType.MultipleChoice,
            name: this.sidebarLabels.getSerializableText(EChatSideBarSpecialsFilters.id),
            options: [],
        }
        this.specialFilters = this.getServiceFilter();
    }

    getChannelsSection(): TSection {
        return {
            id: EChatSideBarChannels.id,
            type: ESelectionType.Channel,
            name: this.sidebarLabels.getSerializableText(EChatSideBarChannels.id),
            options: this.getChannelList().map((channel, idx) => ({
                id: idx,
                name: channel.getName()
            }))
        }
    }

    getServiceFilter(): TSection {
        const serviceSection = {
            // special filters
            id: EChatSideBarSpecialsFilters.id,
            type: ESelectionType.MultipleChoice,
            name: this.sidebarLabels.getSerializableText(EChatSideBarSpecialsFilters.id),
            options: [
                {
                    id: EChatSideBarSpecialsFilters.invert,
                    name: this.sidebarLabels.getSerializableText(EChatSideBarSpecialsFilters.invert),
                }
            ]
        }

        if (this.sessionSvc.getSelectedParticipant().isAdmin()) {
            serviceSection.options.push(
                {//@TODO Jurgilas ver o porque desse erro do id
                    id: EChatSideBarSpecialsFilters.service,
                    name: this.sidebarLabels.getSerializableText(EChatSideBarSpecialsFilters.service),
                }
            )
        }

        return serviceSection;
    }

    getTasksSection(): TSection {
        return {
            // tasks
            id: EChatSideBarTasks.id,
            type: ESelectionType.MultipleChoice,
            name: this.sidebarLabels.getSerializableText(EChatSideBarTasks.id),
            options: [{
                id: EChatSideBarTasks.pendency,
                name: this.sidebarLabels.getSerializableText(EChatSideBarTasks.pendency),
            },
            {
                id: EChatSideBarTasks.demand,
                name: this.sidebarLabels.getSerializableText(EChatSideBarTasks.demand),
            },
            {
                id: EChatSideBarTasks.opened,
                name: this.sidebarLabels.getSerializableText(EChatSideBarTasks.opened),
            }]
        }
    }

    getViewModeSection(): TSection {
        return {
            // viewMode
            id: EChatSideBarViewMode.id,
            type: ESelectionType.MutuallyExclusive,
            valueSelected: this.optionChatDisplayMap[this.chatBackBoneModelInstance.getDisplayOptions().viewStyle],
            name: this.sidebarLabels.getSerializableText(EChatSideBarViewMode.id),
            options: [{
                id: EChatSideBarViewMode.topic,
                name: this.sidebarLabels.getSerializableText(EChatSideBarViewMode.topic),
            },
            {
                id: EChatSideBarViewMode.chat,
                name: this.sidebarLabels.getSerializableText(EChatSideBarViewMode.chat),
            },
            {
                id: EChatSideBarViewMode.forum,
                name: this.sidebarLabels.getSerializableText(EChatSideBarViewMode.forum),
            }]
        }
    }

    filterInteraction(interaction: Interaction, filter: any): boolean {
        return false;
    }


    onChangedMultipleChoiceSection(value: boolean, option: IOption, section: ISection): void {
        option.selected = value;
        if (section.id === EChatSideBarTasks.id) {
            this.handleTaskChanged(option)
        } else if (section.id === EChatSideBarSpecialsFilters.id) {
            switch (option.id) {
                case EChatSideBarSpecialsFilters.invert:
                    this.chatBackBoneModelInstance.onInversorClickedCallback(option.selected);
                    break;
                case EChatSideBarSpecialsFilters.service:
                    this.chatBackBoneModelInstance.setAdminInteractionView(value);
                    break;
            }

        }
    }

    onChangedMutualExclusiveSection(value: number, section: ISectionMutualExclusive): void {
        section.valueSelected = value;

        if (section.id === EChatSideBarViewMode.id) {
            this.chatBackBoneModelInstance.onViewModeChangedCallback(this.optionChatDisplayMap[section.valueSelected])
        };


        console.log('section > channels', section)
    };

    handleTaskChanged(option: IOption): void {
        switch (option.id) {
            case EChatSideBarTasks.pendency:
                this.currentTasksSelected.isPendency = option.selected
                break;
            case EChatSideBarTasks.demand:
                this.currentTasksSelected.isDemand = option.selected
                break;
            case EChatSideBarTasks.opened:
                this.currentTasksSelected.isOpened = option.selected
                break;
            default:
                break;
        }
        this.chatBackBoneModelInstance.onTaskChangedCallback(this.currentTasksSelected)

    }

    isMutuallyExclusive(section: ISection): boolean {
        return section.type === ESelectionType.MutuallyExclusive
    }

    isMultipleChoice(section): boolean {
        return section.type === ESelectionType.MultipleChoice
    }

    getSections(): TSectionList {
        return this.sections;
    }

    //#endregion

    onParticipantClicked(itemClicked: IAvatarView): void {
        itemClicked.selected = !itemClicked.selected
        this.chatBackBoneModelInstance.onAvatarClickedCallback(itemClicked)

        console.log('section > itemClicked', itemClicked)
    }

    public getParticipants(): TAvatarViewList {
        return this.participantList;
    }

    public getThreadList(): TThreadArray {
        return this.subsSignal.getAllThreads();
    }

    public getChannelList(): TGroupArray {
        return this.chatGroupList;
    }

    public shouldShowAttendanceOptions(): boolean {
        const ready: boolean = this.attendanceSvc.isReadyToAttend();
        const attending: boolean = this.sessionSvc.iAmAttendingOnCurrentGroup();

        return ready && attending;
    }
}
