
<button mat-stroked-button [loading]="loading" color="primary" class="dense" [matMenuTriggerFor]="fieldsMenu">
    <mat-icon class="mat-18">sort</mat-icon>
        &nbsp;
    <span style="font-size: 12px;font-weight: bold;align-items: center;display: inline-flex">
        {{ sortFields[sortConfig.field] }}&nbsp;
        <mat-icon class="mat-16">{{ sortDirections[sortConfig.direction].icon }}</mat-icon>
    </span>
    <mat-icon iconPositionEnd>arrow_drop_down</mat-icon>
</button>


<mat-menu #fieldsMenu="matMenu" class="dense">
    <ng-container *ngFor="let sortField of sortFields | keyvalue">
        <button mat-menu-item class="gs-ticket-menu-btn" [matMenuTriggerFor]="directionMenu">
            <span [class.selected]="sortConfig.field === sortField.key" class="group-label">{{ sortField.value }}</span>
        </button>


        <mat-menu #directionMenu="matMenu" class="dense">
            <ng-container *ngFor="let sortDirection of sortDirections | keyvalue">
                <button mat-menu-item class="gs-ticket-menu-btn" (click)="setSortConfig(sortConfig, sortField.key, sortDirection.key)">
                    <mat-icon>{{ sortDirection.value.icon }}</mat-icon><span class="group-label">{{ sortDirection.value.label }}</span>
                </button>
            </ng-container>
        </mat-menu>

    </ng-container>
</mat-menu>