<section class="container nice-scroll">
  <div class="loading-overlay" *ngIf="dataLoading">
    <mat-spinner diameter="18"></mat-spinner>
  </div>

  <div class="ignore-empty-fields-container">
    <mat-slide-toggle
    [checked]="ignoreEmptyFields"
    (change)="onToggleIgnoreEmptyFieldsButtom()"
    >
      Ignorar campos não preenchidos
    </mat-slide-toggle>
  </div>

  <ng-container *ngIf="generalFormFieldViewerHandlers">
    <div class="field-container" *ngFor="let handler of generalFormFieldViewerHandlers">
      <ng-container *ngIf="emptyFormFieldRenderControl(handler)">
        <app-general-form-field-viewer
          [handler]="handler"
          [value]="propValues[handler.getSchemaProperty().idProperty]"
        ></app-general-form-field-viewer>
      </ng-container>
    </div>
  </ng-container>
  
</section>
<div class="controls" *ngIf="!hideActions">
  <button
    mat-flat-button
    color="primary"
    (click)="saveAnswer()"
    [loading]="loading || internalLoading"
    [disabled]="loading || internalLoading"
    *ngIf="viewType !== viewTypeEnum.view && viewTypeEnum.callback !== viewType"
  >
    {{ getButtonText() }}
  </button>
</div>
