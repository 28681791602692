<cm-generic-dashboard-edit *ngIf="genericEdit" [handler]="genericEdit">

  <app-campaign-action-picker
    #actionPicker
    *ngIf="campaignActionPickerHandler"
    [handler]="campaignActionPickerHandler"
  ></app-campaign-action-picker>

  <cm-generic-dashboard-home *ngIf="genericHome" [handler]="genericHome">
  </cm-generic-dashboard-home>
</cm-generic-dashboard-edit>
