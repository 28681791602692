import { Injectable } from "@angular/core";
import { TGlobalUID } from "@colmeia/core/src/business/constant";
import { Interaction } from "@colmeia/core/src/interaction/interaction";
import { IAttendentServicePackClient } from "@colmeia/core/src/shared-business-rules/attendent-service-pack/attendente-service-pack";
import { IServerLocalCanonical } from "@colmeia/core/src/shared-business-rules/canonical-model/local-canonical";
import { IServerColmeiaTag } from "@colmeia/core/src/shared-business-rules/colmeia-tags/tags";
import { getGreeting } from "@colmeia/core/src/shared-business-rules/const-text/functions/getGreeting";
import { customerNamesMetadataTypes, EMetadataNames } from "@colmeia/core/src/shared-business-rules/metadata/metadata-db";
import { TComputedInfo } from "@colmeia/core/src/shared-business-rules/metadata/metadata-utils";
import { captalizeFirstLetter, isValidArray, isValidRef, isValidString } from "@colmeia/core/src/tools/utility";
import { IMacrosHandlerParameter, MacrosHandler } from "app/handlers/macros";
import { AttendanceService } from "./attendance.service";
import { LookupService } from "./lookup.service";
import { SessionService } from "./session.service";
import { TimeZone } from "@colmeia/core/src/time/timezone";

@Injectable({
    providedIn: 'root'
})
export class MacrosService {
    constructor(
        private sessionSvc: SessionService,
        private lookupSvc: LookupService,
        private attendanceSvc: AttendanceService
    ) {
    }

    async getMacros() {
        return
    }

    private actionBarComponentContext: any
    setActionBarComponentContext(context) {
        this.actionBarComponentContext = context
    }

    async buildMacrosHandlerByContext(searchString?: string): Promise<MacrosHandler> {
        const actionBarComponentContext = this.actionBarComponentContext
        const idGroup: TGlobalUID = this.sessionSvc.getSelectedGroupID();

        const attendanceInteraction: Interaction = Interaction.staticFactory(
            this.attendanceSvc.getReplyInteractionForGroup(idGroup)
        );

        const macros: IAttendentServicePackClient = this.attendanceSvc.getAttendentServicePackForGroup(idGroup);
        const canonicalIds = isValidArray(macros.canonicalIds) ? macros.canonicalIds : macros.canonicals.map( c => c.idCanonical );
        const variableIds: string[] = [...macros.variableTags, ...canonicalIds];
        const variables: IServerColmeiaTag[] = this.attendanceSvc.getVariablesFromCache(variableIds);
        const computed: TComputedInfo = this.attendanceSvc.getAllComputedInfoForAttendance(idGroup);
        const clientName: string | null = attendanceInteraction.getParticipant().getAvatar().getNickName();

        this.fillComputed(
            variables,
            computed,
            clientName && captalizeFirstLetter(attendanceInteraction.getParticipant().getAvatar().getNickName()),
            await this.attendanceSvc.getCurrentAttendanceTargetWithDDD(),
        );

        const parameter: IMacrosHandlerParameter = {
            callback: actionBarComponentContext,
            list: macros.macros,
            variables,
            computed,
            variableIds,
            searchString
        };

        return new MacrosHandler(parameter);
    }

    private fillComputed(variables: IServerColmeiaTag[], computed: TComputedInfo, customerName: string, customerAddress?: string): void {
        const att: IServerLocalCanonical  = variables.find(variable => (variable as unknown as IServerLocalCanonical).globalCanonical === EMetadataNames.attendent) as unknown as IServerLocalCanonical;
        if (isValidRef(att)) {
            computed[att.idNS] = this.attendanceSvc.getAgentNameFromCurrentAttance(); //this.sessionSvc.getSelectedAvatar().getName();
        }

        if(isValidString(customerName)){
            const allCustomerNameVariables: IServerColmeiaTag[] = variables.filter( v => customerNamesMetadataTypes.includes((v as unknown as IServerLocalCanonical).globalCanonical));

            allCustomerNameVariables.forEach((v) => {
                computed[v.idNS] = customerName;
            });
        }

        const greeting: IServerLocalCanonical  = variables.find(variable => (variable as unknown as IServerLocalCanonical).globalCanonical === EMetadataNames.envGreetingDaily) as unknown as IServerLocalCanonical;

        if(isValidRef(greeting)) {
            computed[greeting.idNS] = getGreeting(this.getTimezoneOffset(customerAddress));
        }
    }

    private getTimezoneOffset(customerAddress?: string) {
        try {
            return isValidString(customerAddress)
                ? TimeZone.getTimeZoneOffsetByPhone(customerAddress)
                : -3;
        } catch(e) {
            return -3;
        }
    }
}
