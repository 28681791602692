<app-webchat-header *ngIf="isWidgetsApi && !isCopilotWebchat" hideDescription></app-webchat-header>

<div id="screen" [class.is-embedded]="isEmbedded" [class.disable-pointer-events]="isEmbedded && isFinishingEmbeddedConversation()">
  @if (canShowNav()) {
    <header class="chat-header">
        <app-chat-top-bar style="width: 100%;"/>
    </header>
  }

  <div class="chat-content">
    <mat-drawer-container>
      <mat-drawer-content>
        <!-- messages -->
        <div
          id="chat-screen"
          [ngClass]="{ 'embedded': isEmbedded }"
        >
          <ng-container *ngIf="lastAttendanceEvent | async as attendanceEvent">
            <div *ngIf="attendanceEvent.visibleFor(currentGroupID) && !attendanceEvent.isStart" class="attendance-event-overlay">
              <h4 class="ev-message">
                <ng-container *ngIf="attendanceEvent.type !== 'end'">
                  <mat-spinner color="primary" diameter="28"></mat-spinner>&nbsp;
                </ng-container>
                <ng-container *ngIf="attendanceEvent.type === 'end'">
                  <mat-icon style="color: var(--success-default);" class="mat-28">check</mat-icon>&nbsp;
                </ng-container>
                {{ attendanceEvent.message }}
              </h4>
            </div>
          </ng-container>
          <div class="breadcrumb" *ngIf="canShowBreadCrumb()">
            <div class="breadcrumb__container">
              <i class="material-icons icon-home">home</i>
              <cm-breadcrumb [descriptor]="breadCrumbDescriptor"></cm-breadcrumb>
            </div>
            <div class="chat-right-bar-icon">
              <button (click)="openRightSideBar()" tapFeedback="circular">
                <i class="material-icons">chevron_left</i>
              </button>
            </div>
          </div>
          <div
            class="message-container__list"
            *ngFor="let model of getAllChatBackboneModels()"
            [ngClass]="{
              'height--full': model.isActive(),
              'disable-padding-top': isEmbedded
            }"
          >
            <app-message-container
              class="talking"
              *ngIf="model.isActive()"
              [handler]="model.getChatContainerHandler()"
              [chatBackboneHandler]="handler"
              (click)="closeSidebars()"
            >
            </app-message-container>
          </div>
        </div>
      </mat-drawer-content>

      <mat-drawer
        position="end"
        [opened]="canShowRightSideBarOnMobile() || isNotHidingRightBar()"
        [mode]="screenIsBiggerThanMobile() ? 'side' : 'over'"
        (openedChange)="onRightSidebarToggle($event)"
        #rightSideBar
        *ngIf="canShowRightSideBar()"
        (keydown)="handleKeyDown($event)"
        disableClose>
        <!-- Features / Menu that appears on the right side of the screen-->
        <div class="right__sidebar-container">
          <div class="right__sidebar chat-features">

            <ng-container *ngIf="lastAttendanceEvent | async as attendanceEvent">
              <div *ngIf="attendanceEvent.visibleFor(currentGroupID) && !attendanceEvent.isStart" class="attendance-event-overlay">
              </div>
            </ng-container>

            <app-group-chat-right-side-bar [chatBackBoneModelInstance]="getActiveBackboneModel()"/>

          </div>
        </div>
      </mat-drawer>
    </mat-drawer-container>
  </div>

  <!-- <div
    id="back-right-chat-features"
    class="right__backbar"
    [class.is-visible]="!isNotHidingRightBar()"
    *ngIf="canShowRightSideBar()"
    >
    <div class="back-button" (click)="toggleRightBarIfIsNotMobile()" matRipple>
      <mat-icon>chevron_left</mat-icon>
    </div>
  </div> -->
</div>

