import { T360MediaArray } from "@colmeia/core/src/core-constants/bot";
import { TGlobalUID } from "@colmeia/core/src/core-constants/types";
import { IRequest } from "@colmeia/core/src/request-interfaces/request-interfaces";
import { IResponse } from "@colmeia/core/src/request-interfaces/response-interfaces";
import { TServiceIslandArray } from "../../attendance-island/attendance-island";
import { ENonSerializableObjectType } from "../../non-serializable-id/non-serializable-id-interfaces";
import { IdDep } from "../../non-serializable-id/non-serializable-types";
import { ICRMCTicketStatusInteractionInsert, ICRMTicketDataWithCustomerName, TICRMTicketDataBasicArray, TICRMTicketItems, TTicketInteracitonArray } from "../crm-entities";
import { ICRMExecuteActionParams, ICRMExecuteActionResultPayload } from "./crm-bpm-action.model";
import { TICRMProjectArray } from "./crm-config.model";
import { IGetAttendanceTicketsParams, TICRMTicketSortConfigArray, TTicketStatusFilters } from "./crm-runtime-interfaces";
import { ICRMTimeWindowConfig } from "./crm-time-window.model";


export enum ECRMTicketRuntimeRequests {
    getAgentTickets = 'crm.getAllTickets',
    getAllTicketInteractions = 'crm.getAllTicketInteractions',
    getAgentIslands = 'crm.getAgentIslands',
    getAvatarPackages = 'crm.getAvatarPackages',
    insertTicketInteraction = 'crm.insertTicketInteraction',
    getAttendanceTickets = 'crm.getAttendanceTickets',
    getTicketData = 'crm.getTicketData',
    getPackages = 'crm.getPackages',
    executeAction = 'crm.executeAction',
    sendAgentMessageToCustomer = 'crm.sendAgentMessage',
}

export interface ICRMGetAgentTicketsRequest extends IRequest {
    requestType: ECRMTicketRuntimeRequests.getAgentTickets;
    timeWindow: ICRMTimeWindowConfig;
    fields?: {},
    filters: TTicketStatusFilters;
    sort: TICRMTicketSortConfigArray;
}


export interface ICRMGetAgentTicketsResponse extends IResponse {
    responseType: ECRMTicketRuntimeRequests.getAgentTickets;
    tickets: TICRMTicketItems;
    packages: TICRMProjectArray;
}

export interface ICRMGetAllTicketInteractionsRequest extends IRequest {
    requestType: ECRMTicketRuntimeRequests.getAllTicketInteractions;
    idTicket: string;
}


export interface ICRMGetAllTicketInteractionsResponse extends IResponse {
    responseType: ECRMTicketRuntimeRequests.getAllTicketInteractions;
    ticketInteractions: TTicketInteracitonArray;
}


export interface ICRMGetAgentIslandsRequest extends IRequest {
    requestType: ECRMTicketRuntimeRequests.getAgentIslands;
}

export interface ICRMGetAgentIslandsResponse extends IResponse {
    responseType: ECRMTicketRuntimeRequests.getAgentIslands;
    islands: TServiceIslandArray;
}

export interface ICRMGetAvatarPackagesRequest extends IRequest {
    requestType: ECRMTicketRuntimeRequests.getAvatarPackages;
}

export interface ICRMGetAvatarPackagesResponse extends IResponse {
    responseType: ECRMTicketRuntimeRequests.getAvatarPackages;
    packages: TICRMProjectArray;
}


export interface ICRMInsertTicketInteractionRequest extends IRequest {
    requestType: ECRMTicketRuntimeRequests.insertTicketInteraction;
    ticketId: TGlobalUID;
    statusInteraction?: ICRMCTicketStatusInteractionInsert;
    content?: {
        comment?: string;
        idMedias?: string[];
    }
    answer?: { idAnswer: TGlobalUID };
}

export interface ICRMInsertTicketInteractionResponse extends IResponse {
    responseType: ECRMTicketRuntimeRequests.insertTicketInteraction;
}




export interface ICRMTicketGetAttendanceTicketsRequest extends IRequest, IGetAttendanceTicketsParams {
    requestType: ECRMTicketRuntimeRequests.getAttendanceTickets;
}


export interface ICRMTicketGetAttendanceTicketsResponse extends IResponse {
    responseType: ECRMTicketRuntimeRequests.getAttendanceTickets;
    tickets: TICRMTicketDataBasicArray;
}





export interface ICRMGetTicketDataRequest extends IRequest {
    requestType: ECRMTicketRuntimeRequests.getTicketData;
    idTicket: TGlobalUID;
}


export interface ICRMGetTicketDataResponse extends IResponse {
    responseType: ECRMTicketRuntimeRequests.getTicketData;
    ticket: ICRMTicketDataWithCustomerName;
}

export interface ICRMGetPackagesRequest extends IRequest {
    requestType: ECRMTicketRuntimeRequests.getPackages;
    idPackages: TGlobalUID[]
}


export interface ICRMGetPackagesResponse extends IResponse {
    responseType: ECRMTicketRuntimeRequests.getPackages;
    packages: TICRMProjectArray;
}


export interface ICRMExecuteTicketActionRequest extends IRequest, ICRMExecuteActionParams {
    requestType: ECRMTicketRuntimeRequests.executeAction;

}

export interface ICRMExecuteTicketActionResponse extends IResponse {
    responseType: ECRMTicketRuntimeRequests.executeAction;
    payload: ICRMExecuteActionResultPayload;
}

export interface ICRMendAgentMessageParameters {
    messageText: string;
    /**
     * Channel used to send the message
     * Considered the SENDER on the new message
     * Determines the channel type
     */
    channelId: IdDep<ENonSerializableObjectType.socialMediaConnection>;
    idTicket: string;
    /**
     * Target address of the message
     * Considered the RECEIVER on the new message
     * Must match the channelId type
     */
    target: string;
    /**
     * List of media to be attached to the message
     * Sent as attachments to the email
     */
    medias: T360MediaArray;
}

export interface ICRMendAgentMessageParametersServer extends ICRMendAgentMessageParameters {
    /**
     * Author of the message passed by the client
     */
    idAvatarAuthor: string;
}

export interface ICRMSendAgentMessageRequest extends IRequest, ICRMendAgentMessageParameters {
    requestType: ECRMTicketRuntimeRequests.sendAgentMessageToCustomer;

}

export interface ICRMSendAgentMessageResponse extends IResponse {
    responseType: ECRMTicketRuntimeRequests.sendAgentMessageToCustomer;
}
