import { Component, Inject, OnDestroy, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { IContactListItemServer } from "@colmeia/core/src/shared-business-rules/contact-list/contact-list.model";
import { ENonSerializableObjectType } from "@colmeia/core/src/shared-business-rules/non-serializable-id/non-serializable-id-interfaces";
import { GenericDashboardEditHandler } from "app/handlers/generic-dashboard-edit.handler";
import { ContactListService } from "../contact-list.service";
import { ISaveGenericNSRequest } from "@colmeia/core/src/request-interfaces/lookup-ns-request";
import { apiRequestType } from "@colmeia/core/src/request-interfaces/message-types";
import { IResponse } from "@colmeia/core/src/request-interfaces/response-interfaces";
import { GenericDashboardRequester } from "app/services/generic-dashboard-requester.service";
import { RequestBuilderServices } from "app/services/request-builder.services";
import { SubscriptionGroup } from "app/model/client-utility";

export interface IContactListViewItemParameters {
    contact: IContactListItemServer
}

export interface IContactListViewItemResponse {
    changed: boolean
}

@Component({
    selector: "contact-list-view-item",
    styleUrls: ["./contact-list-view-item.component.scss"],
    templateUrl: "./contact-list-view-item.component.html",
})
export class ContactListViewItemComponent implements OnInit, OnDestroy {
    nser: IContactListItemServer;
    public nsType: ENonSerializableObjectType = ENonSerializableObjectType.contactListItem;
    public changed: boolean = false;

    public genericEditHandler: GenericDashboardEditHandler;

    private subs: SubscriptionGroup = new SubscriptionGroup();

    constructor(
        private dialogRef: MatDialogRef<ContactListViewItemComponent, IContactListViewItemResponse>,
        @Inject(MAT_DIALOG_DATA) private dialogData: IContactListViewItemParameters,
        private contactListSvc: ContactListService,
        private requester: GenericDashboardRequester,
        private rbs: RequestBuilderServices,
    ) {
        this.nser = dialogData.contact;

    }

    ngOnInit() {
        this.dialogRef.disableClose = true;

        this.subs.from(this.dialogRef.backdropClick()).subscribe(() => {
            this.onGenericBackButtonPressed();
        });

        this.initGenericEditHandler();
    }

    ngOnDestroy(): void {
        this.subs.destroy();
    }

    private initGenericEditHandler() {
        this.genericEditHandler = new GenericDashboardEditHandler({
            nsType: this.nsType,
            clientCallback: this,
            nser: this.nser,
            allowEditTitleInHeader: true,
            autoValidate: true,
        })
    }

    async onGenericBackButtonPressed?(): Promise<void> {
        this.dialogRef.close({
            changed: this.changed,
        });
    }

    async onGenericSaveButtonPressed(): Promise<boolean> {
        const request: ISaveGenericNSRequest = {
            ...this.rbs.secureBasicRequest(apiRequestType.nonSerializable.genericNSSave),
            nserList: [this.nser]
        };

        return this.requester.request(
            request,
            false
        ).then((response: IResponse) => {
            const success = !!response;

            if (!this.changed) {
                this.changed = success;
            }

            return success;
        });
    }
}
