import { secToMS, minToMS, hoursToSeconds } from '../time/time-utl';
import { apiRequestType } from '../request-interfaces/message-types';
import { ROUTES } from './routes-shared';


export type TCurrentSubscription = typeof socketConfig.client.currentSubscription;
export const socketConfig = {
    socketTimeout: secToMS(7),
    pingInterval: secToMS(15),
    client: {
        receiveParameters: {
            accumulatorWait: secToMS(2),
            waitBeforeNewBatch: secToMS(2),
            batchSize: 30
        },
        queuName: 'nameQueu',
        currentSubscription: 'curSubs',
        forceRecover: {
            socketWarmUp: 250,
            waitForMessage: 300,
        },
        socketConfig: {   //// https://github.com/socketio/socket.io/issues/2476
            reconnection: true,
            reconnectionDelay: 5000,
            reconnectionDelayMax: 5000,
            reconnectionAttempts: 9999999,
        },
        recovery: {
            numberOfInternalDeployToComponentsTries: 5,
            lockBatchProcess: 'bProcess',
            timeout: secToMS(10),
            batchTimer: minToMS(1),
            batchableTimeoutRatio: 4
        },
        delay: {
            ackDelay: 50,
            waitForSocketConfirmation: 2000,
        },
    },
    clienConnection: {
        server: '/api/socket.io',
        bot: ROUTES.webhook.webChat.socket,
    },
    responseTypes: {
        normalInteraction: apiRequestType.participantInteraction,
        batchSentInteraction: 'batchSent',
        initSocket: 'sck.init',
        error: 'sck.error',
        checkIfPlayerOnline: 'sck.chkOnline',
        killOtherPlayerSessions: 'sck.killOth',
        sendNotifications: 'sck.sendNotifications'
    },
    socketEventNames: {
        packet: {
            packet: 'packet',
            ping: 'ping',
        },
        sendDataToClient: 'client_data',
        receiveDataFromServer: 'client_data', // O mesmo evento vem do server para o client, por isso o sinonimo para legibilidade
        sendDataToServer: 'server_data',
        receiveDataFromClient: 'server_data',
        receive_focus_info: 'focus_info',
        socketReady: 'socketReady',
        connectError: 'connect_error',
        disconnect: 'disconnect',
        serverDisconnecting: 'disconnecting',
        connect: 'connect',
        serverConnectionEvent: 'connection',
        reconnect: 'reconnect',
        reconnectFailed: 'reconnect_failed',
        forceClientSync: 'force_sync',
        forceSynch: 'force_rabbit',
        versionControl: 'v_control',
        betweenServers: {
            sendDataBetweenServers: 'sendDataToClient',
        },
        onlineConfirmation: 'oConfirm',
        readConfirmation: 'r_conf',
        receiveFromSocketConfirmation: 'r_socket',
        arakiri: 'r_araki',
        notificationMessages: 'notification_messages',
        testServer: {
            toServer: 'areYouThere',
            toClient: 'yesIAm'
        },
        adminMode: {
            on: 'adminModeOn',
            off: 'adminModeOff',
        }
    },
} as const;
