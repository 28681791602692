<div class="l-container">
  <ng-container *ngIf="isOnline() else mustBeOnlineDude">
    <ng-container *ngIf="hasPreviousState()">
      <div class="preview-container" [class.is-viewing-previous]="isViewPreviousState()">
        <button class="previous-state-view" (click)="toggleViewPreviousState()">
          <div class="l-previous">
            <mat-icon class="mat-14 l-ps-toggle">{{ getIsViewPreviousStateIcon() }}</mat-icon>
            {{ getPreviousStateButtonLabel() }}
          </div>
        </button>
        <div class="last-state-date">
          Resultado em <span class="state-time">{{ previousStateDate() }}h</span>
        </div>
      </div>
    </ng-container>

    <header>
      <h4 class="l-title">
        Atendimentos na fila:
        <span class="counter">{{ attendancesIslandCountHashTotalCases }}</span>
      </h4>

      <div class="button-container">
        <button
          class="call-to-action button-w-icon dense"
          mat-flat-button
          [disabled]="!attendancesIslandCountHashTotalCases"
          [loading]="!isInCacheInvalidationAfterStatusUpdate"
          color="accent"
          (click)="catchOne($event)"
        >
          Atender
          <mat-icon iconPositionEnd>send</mat-icon>
        </button>
      </div>
    </header>

    <div *ngIf="attendancesIslandCountHashTotalCases" [@verticalAppear]="'in'">
      <hr>
      <div class="islands-list nice-scroll scroll-accent">
        <div *ngFor="let islandCase of attendancesIslandCountHash.islandCases; islandTrackBy"
          class="island-item"
          [class.highlighted-island]="getHighlightStatusTheme(islandCase.idIsland)"
          [ngClass]="getHighlightStatusTheme(islandCase.idIsland)">
          <span class="island-name">
            {{ islandCase.name }}
            <mat-icon *ngIf="getHighlightStatusIcon(islandCase.idIsland) as matIcon" class="mat-12 status-icon" style="margin-right: 4px;">{{ matIcon }}</mat-icon>
          </span>
          <span class="cases-count">
            <span class="circle">{{ islandCase.casesLength }}</span>
          </span>
        </div>
      </div>
    </div>
    <div class="button-alert-container" [visible-horizontal]="isButtonAlertVisible()">
      <div class="button-alert-wrapper">
        <button-alert #buttonAlert wrap [config]="initialButtonAlertState"></button-alert>
      </div>
    </div>
  </ng-container>
  <ng-template #mustBeOnlineDude>
    <app-alert type="accent" matIcon="info">Você precisa estar online para atender</app-alert>
  </ng-template>
</div>
