import { BreakpointObserver, Breakpoints, BreakpointState } from '@angular/cdk/layout';
import { GlobalPositionStrategy, Overlay, OverlayConfig, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import {
    ChangeDetectorRef,
    Component,
    ElementRef,
    Host,
    HostListener,
    InjectionToken,
    Injector,
    Input,
    OnInit,
    ViewChild
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { Avatar } from '@colmeia/core/src/business/avatar';
import { constant, TGlobalUID } from '@colmeia/core/src/business/constant';
import { EServiceGroupIntType } from '@colmeia/core/src/business/constant.enums';
import { Group } from '@colmeia/core/src/business/group';
import { Serializable, TSerializableArray } from '@colmeia/core/src/business/serializable';
import { TExtendedParticipant, TExtendedParticipantArray } from '@colmeia/core/src/core-constants/types';
import { Interaction } from '@colmeia/core/src/interaction/interaction';
import { MMconstant } from '@colmeia/core/src/multi-media/barrel-multimedia';
import { EAllSerializable } from '@colmeia/core/src/shared-business-rules/const-text/all-serializables';
import { gTranslations } from "@colmeia/core/src/shared-business-rules/const-text/translations";
import { EJOBType } from '@colmeia/core/src/shared-business-rules/jobs/jobs-model';
import {
    ESearchAllowedType,
    ESearchScope
} from "@colmeia/core/src/shared-business-rules/search";
import {
    AppBarShortcuts, EProfileItemClick,
    ESNConfigClick,
    EYourColmeiaClick
} from "@colmeia/core/src/shared-business-rules/visual-constants";
import { isInvalidString, isValidArray, isValidRef, isValidString, LodashMemoize, narrow } from '@colmeia/core/src/tools/utility';
import { JobService } from 'app/components/dashboard/jobs-page/service/job.service';
import { RootComponent } from 'app/components/foundation/root/root.component';
import { NotificationDialogBoxComponent } from 'app/components/notification-dialog-box/notification-dialog-box.component';
import { IAttendantLogin } from 'app/model/attendence.model';
import { AttendanceService } from 'app/services/attendance.service';
import { NavigatorServices } from 'app/services/controllers-services/navigator/navigator.service';
import { DevService } from 'app/services/dev.service';
import { INotificationCounter, NewNotificationsService } from 'app/services/new-notifications.service';
import { ScreenSpinnerService, SpinType } from 'app/services/screen-spinner.service';
import { SearchService } from 'app/services/search.service';
import { SnackMessageService } from 'app/services/snack-bar';
import { environment } from 'environments/environment-client';
import * as _ from 'lodash';
import { debounce } from 'lodash';
import { Observable, Subject, Subscription } from 'rxjs';
import { skip, take } from 'rxjs/operators';
import { serializableWithPhotoToHexagonHandler } from "../../../handlers/business-handlers";
import { CmModalHandler } from "../../../handlers/cm-modal-handler";
import { HandlerHexagonon } from "../../../handlers/hexagono.handler";
import { EParticipantClickTypeMode } from "../../../handlers/participant-selector.handler";
import { HandlerSearch, IClientSearchCallback, ISearchComponentParamater } from "../../../handlers/search-handler";
import { ECmModalState, ICmModalParameter, ICmModalSettings } from "../../../model/cm-modal.model";
import { clientConstants } from "../../../model/constants/client.constants";
import { IDropdownMenuItem, TIDropdownMenuItemArray } from "../../../model/misc.model";
import { allProfileItems, IProfileItemClick } from "../../../model/profile-item-click";
import { routeID, routeList } from "../../../model/routes/route-constants";
import { InterfaceInfoSignal } from "../../../model/signal/interface-signal";
import { IChangeInterfaceListener, IListenerSubscription } from "../../../model/signal/ps-interfaces";
import { ISellItemListener } from "../../../model/signal/sell-item.signal";
import { SubscriptionSignal } from "../../../model/signal/subscription-signal";
import { EToolsVisualizationMode, IToolsHanlder, ToolSection, TToolsData } from "../../../model/tools.model";
import { AuthService } from "../../../services/auth/auth.service";
import { TExecutableItemArray } from "../../../services/controllers-services/security-controller/executable-item";
import { ColmeiaDialogService } from "../../../services/dialog/dialog.service";
import { GroupEditorCreatorService } from "../../../services/group-editor-creator.service";
import { MultimediaService } from "../../../services/multimedia.service";
import { RouterManagerService } from "../../../services/router-manager.service";
import { RoutingBuilder } from "../../../services/routing-builder";
import { RoutingService } from "../../../services/routing.service";
import { SessionService } from "../../../services/session.service";
import { SignalListenerService } from "../../../services/signal/signal-listener";
import { SignalPublisherService } from "../../../services/signal/signal-publisher";
import { SocialNetworkDatabaseService } from "../../../services/social-network-database.service";
import { SettingsModalComponent } from "../../settings/settings-modal/settings-modal.component";
import { NShare } from "../../share/share.handler";
import { SocketService } from './../../../services/socket.service';
import { AboutDialogComponent } from "./about-dialog/about-dialog.component";
import { NewMainHeaderService } from './services/new-main-header.service';
import { AttendanceActiveEditHelperService } from 'app/services/attendance-active-edit-call-init.service';
import { ESearchContentType, ISearchTypeContent } from 'app/components/foundation/search/search.component';
import { MainHeaderViewComponent } from 'app/views/main-header-view/main-header-view.component';
import { ITranslationConfig } from '@colmeia/core/src/shared-business-rules/translation/translation-engine';
import { ProfileAvatarStoreService } from 'app/services/profile-avatar-store.service';
import { MigrationVersioningJournalingService } from 'app/services/migration-journaling.service';
import { IMigrationVersionComm } from 'app/model/journaling-client.model';
import { IMigrationVersionData } from '@colmeia/core/src/shared-business-rules/journaling/journaling.model';
import { ColmeiaExternalNotificationService } from 'app/services/colmeia-external-notification.service';

export const minimumCharLengthSearchFire = 3;
export const OVERLAY_REF_DATA = new InjectionToken<OverlayRef | undefined>('overlayRef');
@Component({
    selector: 'app-new-main-header',
    templateUrl: './new-main-header.component.html',
    styleUrls: ['./new-main-header.component.scss'],
    providers: [SearchService],
    // changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NewMainHeaderComponent extends RootComponent<
    'yourColmeia'
    | 'snManagement'
    | 'searchPlaceholder'
    | 'chat'
    | 'tools'
    | 'share'
    | 'yourSN'
    | 'yourProfiles'
    | 'application'
    | 'group'
> implements
    OnInit,
    IListenerSubscription,
    ISellItemListener,
    IChangeInterfaceListener,
    IClientSearchCallback {

    public hexagonHandler: HandlerHexagonon;
    public currentAvatar: Avatar;
    public currentSelectedGroup: Group;
    public isMobileSearch: boolean = false;
    public searchHandler: HandlerSearch;
    @ViewChild('searchInput', { static: true }) searchInput: ElementRef;
    private headerInput = new Subject<string>();
    public shareCount: number;
    shareHandler: NShare.ShareHandler;
    public shareVisible: boolean = false;
    public canShowSocialNetworkTools: boolean = false;
    public settingsCmModalHandler: CmModalHandler;
    public canShowSearch: boolean = false;
    public featuresDropdownList: TIDropdownMenuItemArray = [];
    public toolsData: TToolsData;
    public socialNetworksToolsData: TToolsData = undefined;
    public logoUrl: string;
    public logoUrlIsAuthenticated: boolean = false;
    public snName: string;
    public toolsOpen: boolean = false;
    public showShare: boolean = false;
    public attendentData$: Observable<IAttendantLogin>;
    public showLoading$: Observable<boolean>;
    attDataSubs: Subscription
    socialNetworksToolsDataAppToolsHandler: IToolsHanlder
    atendimentoClickOptions: TSerializableArray;
    allAtendimentoClickOptions: TSerializableArray;
    notificationCounter: INotificationCounter;
    private notificationCounterSubscription: Subscription;
    public lastSocketConnStatus$: Observable<string>;

    public _expandSearchInput: boolean = false;
    public readonly minimumCharLengthSearchFire: number = minimumCharLengthSearchFire;

    public get expandSearchInput(): boolean {
        return isValidString(this.searchInput.nativeElement.value) || this._expandSearchInput;
    }

    public set expandSearchInput(value: boolean) {
        this._expandSearchInput = value;
    }

    public rightItemsExpanded: boolean = false;
    public isSearchLoading: boolean = false;
    public showDebounceIndicator: boolean = false;

    private searchRequestingStateObservable: Subscription;

    public readonly groupRoute: string = "/" + RoutingBuilder.groupHome(this.sessionSvc.getCurrentSocialNetworkID());

    public selectedSearchType: ESearchContentType = ESearchContentType.SocialNetworkContent;
    public searchTypeList: ISearchTypeContent[] = [
        { type: ESearchContentType.GlobalContent, name: 'Busca no Aplicativo' },
        { type: ESearchContentType.SocialNetworkContent, name: 'Rede Social' }
    ]
    currentVersionData: IMigrationVersionComm | null | undefined = null;

    public allMigrationVersions: IMigrationVersionData[] = [];
    private migrationVersionSubscription: Subscription;

    isLoadingVersions = false;
    cursor: string = (new Date()).toISOString();
    pageSize = 10;

    constructor(
        private overlay: Overlay,
        public sessionSvc: SessionService,
        private routingSvc: RoutingService,
        private listener: SignalListenerService,
        private publisher: SignalPublisherService,
        private routerManagerSvc: RouterManagerService,
        public breakpointObserver: BreakpointObserver,
        private cdRef: ChangeDetectorRef,
        private authService: AuthService,
        private socialNetworkSvc: SocialNetworkDatabaseService,
        private gcreatorSVC: GroupEditorCreatorService,
        private mmSvc: MultimediaService,
        private router: Router,
        private dialog: ColmeiaDialogService,
        private attSvc: AttendanceService,
        private matDialog: MatDialog,
        private attendanceSvc: AttendanceService,
        private newMainHeaderSvc: NewMainHeaderService,
        private jobsService: JobService,
        private searchService: SearchService,
        private snackBar: MatSnackBar,
        private notificationSvc: NewNotificationsService,
        private screenLoadingSvc: ScreenSpinnerService,
        private devSvc: DevService,
        private socketSvc: SocketService,
        private spinnerSvc: ScreenSpinnerService,
        private navigatorSvc: NavigatorServices,
        private snackMsg: SnackMessageService,
        private activeCallService: AttendanceActiveEditHelperService,
        @Host() private mainHeaderViewComponent: MainHeaderViewComponent,
        private profileAvatarStoreSvc: ProfileAvatarStoreService,
        private migrationSvc: MigrationVersioningJournalingService,
        private clmExtNotifSvc: ColmeiaExternalNotificationService,
    ) {
        super({
            yourProfiles: gTranslations.fragments.yourProfiles,
            yourSN: gTranslations.fragments.yourSN,
            yourColmeia: gTranslations.dashboard.yourColmeia,
            snManagement: gTranslations.fragments.snManagement,
            searchPlaceholder: gTranslations.search.placeHolderAll,
            chat: gTranslations.chat.chat,
            tools: gTranslations.fragments.tools,
            share: gTranslations.share.share,
            application: gTranslations.dashboard.application,
            group: gTranslations.common.group
        });
    }

    @LodashMemoize
    getTranslation(translation: ITranslationConfig) {
        return super.getTranslation(translation);
    }

    ngOnInit() {
        this.lastSocketConnStatus$ = this.socketSvc.lastSocketConnStatus$;
        this.initAtendimentoClickOptions()
        this.initSocialNetworkTools()
        this.searchHandler = new HandlerSearch(this.buildSearchParameter());
        this.listener.listenSubscriptionChanges(this);
        this.listener.listenToInterfaceChanges(this);
        this.buildHexagonHandler();

        this.searchRequestingStateObservable = this.searchService.searchRequestingStateSubject.subscribe((state) => {
            this.isSearchLoading = state;
        })

        this.breakpointObserver
            .observe([Breakpoints.XSmall])
            .subscribe((state: BreakpointState) => {
                this.closeAllInteractions();
            });
        this.setupAttendenceLoginListener()
        if (this.isDevMode()) {
            this.execTests()
        }

        this.notificationCounterSubscription = this.notificationSvc.getNotificationCount().subscribe(counter => {
            this.notificationCounter = counter;
        });

        this.profileAvatarStoreSvc.subscribeToAvatarChanges((avatar) => {
            this.currentAvatar = avatar
            this.buildHexagonHandler()
            this.cdRef.markForCheck()
        });

        this.loadCurrentVersionLogics();

        this.migrationVersionSubscription = this.migrationSvc.listenVersionSelected()
        .subscribe((versionComm: IMigrationVersionComm) => {
            if (versionComm.isSelected) {
                this.currentVersionData = versionComm;
            } else {
                this.currentVersionData = null
            }
        });
    }

    ngOnDestroy() {
        this.searchRequestingStateObservable.unsubscribe();
        this.attDataSubs.unsubscribe()
        this.notificationCounterSubscription.unsubscribe();
        if (this.migrationVersionSubscription) {
            this.migrationVersionSubscription.unsubscribe();
        }
    }

    setupAttendenceLoginListener() {
        this.attendentData$ = this.attSvc.loggedDataListener();
        this.attDataSubs = this.attendentData$.subscribe((attendantLoginData: IAttendantLogin) => {
            this.changeAtendimentoClickOptions(attendantLoginData)
        })
    }


    //versioning1
    private loadCurrentVersionLogics() {
      this.loadAllMigrationVersions();

      this.currentVersionData = this.migrationSvc.getCurrentVersionPersisted();
    }

    public async loadAllMigrationVersions() {
        if (isInvalidString(this.cursor)) return;

        const res = await this.migrationSvc.getAllVersions({cursor: {lastTimestamp: this.cursor}, limit: this.pageSize});
        const hasMoreData = isValidArray(res) && res!.length === this.pageSize;
        this.cursor = hasMoreData ? res!.at(-1)!.timeStamp : ''
        this.allMigrationVersions = [...this.allMigrationVersions, ...(res || [])];
    }

    get allVersions() {
      return this.allMigrationVersions;
    }

    private updateCurrentVersion(versionComm: IMigrationVersionComm) {
      this.currentVersionData = versionComm;
      this.migrationSvc.setCurrentVersion(versionComm);
    }

    public isTheDefinedVersion(migrationVersion: IMigrationVersionData): boolean {
      if(!isValidRef(migrationVersion)) {
        return false;
      }
      if(!isValidRef(this.currentVersionData)) {
        return false;
      }
      return migrationVersion?.idVersion === this.currentVersionData?.version?.idVersion;
    }

    public getMenuItemVersionName(migrationVersion: IMigrationVersionData): string {
      return migrationVersion?.name || '';
    }

    get currentVersionName(): string {
      return this.isThereADefinedVersion
        ? this.currentVersionData?.version?.name || ''
        : 'Clique para selecionar uma versão';
    }

    get isThereADefinedVersion(): boolean {
      return this.currentVersionData?.isSelected as boolean;
    }

    get matBadgeText(): string {
      return this.isThereADefinedVersion
        ? '✔'
        : '' //<< '–'
    }

    versionButtonHovered: boolean = false;
    getUnsetVersionMatIconText(migrationVersion: IMigrationVersionData): string {
      return this.versionButtonHovered && this.isTheDefinedVersion(migrationVersion)
        ? 'close'
        : 'check'
    }

    setSelectedVersion(migrationVersion: IMigrationVersionData) {
      const versionCommData: IMigrationVersionComm = {
        isSelected: true,
        version: migrationVersion
      }
      this.updateCurrentVersion(versionCommData);
    }

    public onMigrationVersionChangeClick(migrationVersion: IMigrationVersionData) {
      this.setSelectedVersion(migrationVersion);
    }

    unsetSelectedVersion() {
      const versionCommData: IMigrationVersionComm = {
        isSelected: false,
      }
      this.updateCurrentVersion(versionCommData);
    }


    @ViewChild("notificationsTriggerButton")
    notificationsTriggerButton: ElementRef<HTMLButtonElement>;

    notificationOverlayRef: OverlayRef | undefined;

    getPositionStrategyForNotificationButton(): GlobalPositionStrategy {

        const { top, left, width, height } = this.notificationsTriggerButton.nativeElement.getBoundingClientRect();

        const positionStrategy = this.overlay.position().global();

        positionStrategy.top(`${top + height + 10}px`);

        if (window.innerWidth > 600) {
            positionStrategy.left(`${left + width}px`);
        } else {
            positionStrategy.left(`${window.innerWidth}px`);
        }

        return positionStrategy;
    }

    onNotificationsClicked() {
        const overlayConfig = new OverlayConfig({
            positionStrategy: this.getPositionStrategyForNotificationButton(),
            hasBackdrop: true,
            disposeOnNavigation: true,
            // backdropClass: ["no-backdrop-filter", "transparent-backdrop"]
        });

        this.notificationOverlayRef = this.overlay.create(overlayConfig);
        const portalInjector = Injector.create({
            providers: [{ provide: OVERLAY_REF_DATA, useValue: this.notificationOverlayRef }],
        });

        const notificationBoxPortal = new ComponentPortal(NotificationDialogBoxComponent, null, portalInjector);

        this.notificationOverlayRef.attach(notificationBoxPortal);

        this.notificationOverlayRef.backdropClick().pipe(take(1)).subscribe(() => {
            this.notificationOverlayRef?.dispose();
            this.notificationOverlayRef = undefined;
        });
    }

    changeAtendimentoClickOptions(attendantLoginData: IAttendantLogin) {
        if (attendantLoginData.isAttendantLoggedIn) {
            this.atendimentoClickOptions = [...this.allAtendimentoClickOptions]
        } else {
            _.remove(this.atendimentoClickOptions, option => option.is(EAllSerializable.AcOnTools))
        }
    }

    initAtendimentoClickOptions() {
        this.allAtendimentoClickOptions = [
            Serializable.staticFactory(EYourColmeiaClick.updateServiceStatus),
            Serializable.staticFactory(EServiceGroupIntType.activities),
            // Serializable.staticFactory(EAllSerializable.AcOnTools)
        ]
        this.atendimentoClickOptions = [...this.allAtendimentoClickOptions]
    }

    initSocialNetworkTools() {
        this.socialNetworksToolsDataAppToolsHandler = {
            sections: [],
            includeArrowMobile: true,
            name: 'socialNetworks',
            selector: '.main-header__brand',
            visualizationMode: EToolsVisualizationMode.List,
            onVisibilityChange: this.onToolsVisibilityChange,
        };
    }

    private updateDisplayableItens() {
        this.showShare = this.canShowShare();
    }

    private resetLogoUrl() {
        this.logoUrl = './assets/img/icons/colmeia_selecao.png';
        this.clmExtNotifSvc.logoUrl = this.logoUrl;
        this.logoUrlIsAuthenticated = false;
    }

    private loadSocialNetworkData() {
        const selectedGroup: Group = this.sessionSvc.getSelectedGroup();
        let socialNetwork: Group = selectedGroup;

        const snID: string = selectedGroup.getMySocialNetworkID();
        if (isValidString(snID)) {
            socialNetwork = this.socialNetworkSvc.getGroup(snID);
        }

        const mmObject = socialNetwork.getMultimediaObject();

        if (isValidRef(mmObject)) {
            const logo = mmObject.getMultimediaInstanceByTag(MMconstant.tag.photo);
            if (isValidRef(logo)) {
                this.logoUrl = this.mmSvc.getMultimediaBestUrl(logo);
                this.clmExtNotifSvc.logoUrl = this.logoUrl;
                this.logoUrlIsAuthenticated = true;
            } else {
                this.resetLogoUrl();
            }
        } else {
            this.resetLogoUrl();
        }
        this.snName = socialNetwork.getName();
        // this.navigatorSvc.runExecuteSearch();
        // a funcao acima comentada ta quebrando a call stack. verificar onde posso chamá-la
    };

    loadPlayerSocialNetworks() {
        const socialNetworks = this.socialNetworkSvc.getPlayerSocialNetworksExceptPersonal();
        const socialNetworkTools: ToolSection = {
            header: this.translations.yourSN.value,
            headerMobileOnly: false,
            centerHeader: true,
            serializables: socialNetworks,
            onItemClick: this.onSocialNetworkClick,
            getItemURL: this.getSocialNetwordURL,
            rightButtonIcon: {
                url: './assets/img/icons/colmeia_selecao.png',
                backgroundColor: 'black'
            },
            onRightIconClick: () => {
                this.canShowSocialNetworkTools = false;
                this.routingSvc.goToGroupHome(constant.entity.rootGroups.root);
            }
        };
        this.socialNetworksToolsData = [socialNetworkTools];
    }

    onToolsVisibilityChange = (visible: boolean) => {
        this.toolsOpen = visible;
        this.cdRef.markForCheck();
    }

    public get rootGroup() {
        return narrow<string>()(constant.entity.rootGroups.root);
    }

    public get isRootGroup(): boolean {
        return constant.entity.rootGroups.root === this.currentSelectedGroup.getPrimaryID();
    }

    // MARK
    onShortcurtClick = (hexagon: HandlerHexagonon) => {
        const serializable: Serializable = hexagon.getEditingSerializable();

        switch (serializable.getPrimaryID()) {
            case AppBarShortcuts.JobsStatus:
                this.jobsService.showJobsList(EJOBType.patchApply);
                break;
            case AppBarShortcuts.Corporate:
            case AppBarShortcuts.Tribe:
                this.gcreatorSVC.create(
                    hexagon.getEditingSerializable(),
                    this.currentSelectedGroup,
                    this
                );
                break;
            default:
                throw new Error("Shortuct click handler not defined")
        }
    }

    onGroupSaved() {
        this.closeAllInteractions();
    }

    onSNConfigClick = (hexagon: HandlerHexagonon) => {
        switch (hexagon.getEditingSerializable().getPrimaryID()) {
            case ESNConfigClick.tags:
                return this.routingSvc.navigateToId(
                    routeID.dashboard,
                    routeList.dashboard.children.smartFlow.path,
                    routeList.dashboard.children.smartFlow.children.tags.path,
                );

            case ESNConfigClick.users:
                return this.routingSvc.navigateToId(
                    routeID.dashboard,
                    routeList.dashboard.children.menuSN.path,
                    routeList.dashboard.children.menuSN.children.users.path,
                    routeList.dashboard.children.menuSN.children.users.children.list
                );
            case ESNConfigClick.kb:
                return this.routingSvc.navigateToId(
                    routeID.dashboard,
                    routeList.dashboard.children.ai.path,
                    routeList.dashboard.children.ai.children.knowledgeBase.path
                )
        }
    }

    onYourColmeiaClick = (hexagon: HandlerHexagonon) => {
        switch (hexagon.getEditingSerializable().getPrimaryID()) {
            case EYourColmeiaClick.updateServiceStatus:
                this.routingSvc.navigateToId(
                    routeID.dashboard,
                    routeList.dashboard.children.serviceStatus.path,
                    routeList.dashboard.children.serviceStatus.children.current
                );
                break;
            case EAllSerializable.AcOnTools:
                this.initCall()
                break;
            default:
                break;
        }
    }

    goToDashboard() {
        this.routingSvc.navigateToId(routeID.dashboard);
    }

    onSocialNetworkClick = async (hexagon: HandlerHexagonon) => {
        const isCurrentSN: boolean = hexagon.getEditingSerializable().getPrimaryID() === this.sessionSvc.getCurrentSocialNetworkID();

        if (isCurrentSN) {
            this.snackMsg.openInfo(`Você já está na rede [b]${hexagon.getEditingSerializable().getName()}[/b].`, 5000, {
                options: {
                    verticalPosition: 'top',
                    horizontalPosition: 'left',
                }
            });
            return;
        }

        this.canShowSocialNetworkTools = false;
        this.spinnerSvc.show({ spinType: SpinType.socialNetworkChange });

        this.routingSvc.goToGroupHome(
            hexagon.getEditingSerializable().getPrimaryID()
        );

        this.navigatorSvc.executedNavigation$.pipe(take(1)).subscribe(() => {
            this.spinnerSvc.hide({ spinType: SpinType.socialNetworkChange });
        });
        this.loadSocialNetworkData();
    }

    getSocialNetwordURL = (hexagon: HandlerHexagonon) => {
        if (!hexagon.itemURL) {
            hexagon.itemURL = this.routingSvc.getURLToNavigateToId(
                routeID.groups.home,
                hexagon.getEditingSerializable().getPrimaryID()
            );
        }

        return hexagon.itemURL;
    }

    onAvatarClick = (hexagon: HandlerHexagonon) => {
        this.canShowSocialNetworkTools = false;
        this.routingSvc.navigateToId(
            routeID.groups.home,
            this.sessionSvc.getPersonalGroup().getPrimaryID()
        );
    };

    public buildSearchParameter(): ISearchComponentParamater {
        const seachType: ISearchComponentParamater = {
            searchTypes: [
                ESearchAllowedType.all,
                ESearchAllowedType.people,
                ESearchAllowedType.groups,
                ESearchAllowedType.fromAvatar,
                ESearchAllowedType.myStuff,
                ESearchAllowedType.posts
            ],
            clickAction: EParticipantClickTypeMode.GotoTarget,
            maxSelectionableNumber: clientConstants.maxParticipantsPerGroup,
            defaultOption: ESearchAllowedType.all,
            clientCallback: this,
            showCloseBtn: true,
            isEditableTypes: true,
            searchScope: ESearchScope.allSocialNetwork,
            observableForTyping: this.headerInput,
            selectedSearchType: this.selectedSearchType
        };
        return seachType;
    }

    public onSelectedSearchItem(selectionable: TExtendedParticipant): void { };
    public onRemoveSearchItem(selectionable: TExtendedParticipant): void { };
    public onFinishSelection(selectionableArray: TExtendedParticipantArray): void {
        this.onSearchClose();
    };

    public onSearchClose() {
        this.searchInput.nativeElement.value = '';
        this.canShowSearch = false;
        this.isMobileSearch = false;
        this.isSearchLoading = false;
        this.cdRef.markForCheck();
    }

    // # endregion

    receiveChangeInterfaceCallback(sign: InterfaceInfoSignal) {
        this.verifySignShare(sign);
        this.updateDisplayableItens();
    }

    public buildHexagonHandler() {
        this.hexagonHandler = this.getCurrentAvatar() ?
            serializableWithPhotoToHexagonHandler(
                this.getCurrentAvatar(),
                'xs'
            ) :
            null;
    }

    public getHexagonHandler(): HandlerHexagonon {
        return this.hexagonHandler;
    }

    public getCurrentAvatar(): Avatar {
        if (this.sessionSvc.hasSubscription()) {
            return this.sessionSvc.getSelectedAvatar();
        }
    }

    // MARK
    changeSubscriptionSignCallback(subscriptionSignal: SubscriptionSignal): void {
        this.loadPlayerSocialNetworks();
        this.currentAvatar = subscriptionSignal.getSubscriptionAvatar();
        this.currentSelectedGroup = subscriptionSignal.getGroup();

        this.updateFeaturesDropdown(subscriptionSignal.getDropDownListMenu());

        this.loadSocialNetworkData();
        this.updateDisplayableItens();
        this.buildHexagonHandler();
        this.updateSocialNetworkToolsHandler()
        this.cdRef.markForCheck();
    }

    updateSocialNetworkToolsHandler() {
        this.socialNetworksToolsDataAppToolsHandler = {
            ...this.socialNetworksToolsDataAppToolsHandler,
            sections: this.socialNetworksToolsData,
        }
    }

    openSellItemModal(interaction: Interaction): void {
        return;
        // this.generateSellingItemModalHandler(true, interaction);
    }

    // canShowChatBtn(): boolean {
    //     if (!this.currentSelectedGroup) {
    //         return false;
    //     }
    //     const currentGroupType: GroupType = this.sessionSvc.getSelectedGroup().getGroupType();
    //     const currentGroupIsRoot: boolean = currentGroupType.isRoot();
    //     const currentGroupIsFunctionalRoot: boolean = currentGroupType.isFunctionalRoot();
    //     const currentGroupisPersonalRoot: boolean = currentGroupType.isPersonalRoot();
    //     const isNotFunctionalRootOrRootOrPernsonalRoot: boolean = (!currentGroupIsRoot && !currentGroupIsFunctionalRoot && !currentGroupisPersonalRoot);

    //     return isNotFunctionalRootOrRootOrPernsonalRoot || this.isInsideChat();
    // }
    // isInsideChat(): boolean {
    //     return isValidRef(this.currentSelectedGroup) && this.routingSvc.isInsideChat(this.currentSelectedGroup.getGroupID());
    // }

    // goToChat(): void {
    //     this.closeAllInteractions();
    //     this.routerManagerSvc.goToGroup(
    //         this.currentSelectedGroup.getGroupID(),
    //         routeID.groups.chat,
    //     );
    // }

    initCall(): void {
        this.activeCallService.openDialog();
    }

    private amIAtChatPage(): boolean {
        const idGroup: TGlobalUID = (this.currentSelectedGroup) ? this.currentSelectedGroup.getPrimaryID() : null;
        const chatRouteForGroup: string = `/${RoutingBuilder.getRoute(routeID.groups.chat, idGroup)}`;
        return this.routingSvc.amIAtThisPath(chatRouteForGroup);
    }

    private screenIsBiggerThanMobile(): boolean {
        return window.innerWidth >= 600;
    }

    closeAllInteractions(): void {
        this.canShowSocialNetworkTools = false;
        this.shareVisible = false;
        this.canShowSearch = false;
    }

    _canShowSearch(): boolean {
        return this.canShowSearch && this.searchInput.nativeElement.value.length >= this.minimumCharLengthSearchFire
    }

    // #region Search

    showSearchWithOwnInputWrapper() {
        const searchText = this.searchInput.nativeElement.value;
        const shouldFireSearch = searchText.length >= this.minimumCharLengthSearchFire;

        if (!shouldFireSearch) {
            this.headerInput.next(searchText);
        }


        if (shouldFireSearch) this.showDebounceIndicator = false;
        this.cdRef.detectChanges();
        if (shouldFireSearch) this.showDebounceIndicator = true;

        if (shouldFireSearch) this.showSearchWithOwnInput();
    }

    showSearchWithOwnInput = debounce((): void => {
        this.closeAllInteractions();

        const searchText = this.searchInput.nativeElement.value;

        this.canShowSearch = true;
        this.showDebounceIndicator = false;
        this.headerInput.next(searchText);
    }, 500);

    showMobileSearch(): void {
        this.showSearchWithOwnInput();
        this.isMobileSearch = true;
        setTimeout(() => {
            this.searchInput.nativeElement.focus();
        }, 100);
    }

    public canShowSearchCloseButton(): boolean {
        return !!this.searchInput.nativeElement.value.length;
    }

    // #endregion

    //#region Tools

    private updateFeaturesDropdown(items: TExecutableItemArray): void {
        this.featuresDropdownList = [];
        items.forEach(createDropdownItem =>
            this.featuresDropdownList.push({
                title: createDropdownItem.getName(),
                pageId: createDropdownItem.getRoute(),
                item: createDropdownItem.getSerializable(),
                itemEventHandler: new Subject<IDropdownMenuItem>()
            })
        );
        this.featuresDropdownList.forEach(dropdownMenuItem =>
            dropdownMenuItem.itemEventHandler.subscribe(
                (menuItem: IDropdownMenuItem) => {
                    this.routerManagerSvc.goToGroup(
                        this.currentSelectedGroup.getGroupID()
                    );
                }
            )
        );

        const hasCreateSNFeature = this.featuresDropdownList.some(feature => feature.item?.getPrimaryID() === AppBarShortcuts.Corporate);
        this.updateCreateSNProfileItem(hasCreateSNFeature);
    }

    /**
     * Remove ou adiciona opção de criar rede social
     */
    private updateCreateSNProfileItem(hasCreateSNFeature: boolean) {
        this.profileItems = [...allProfileItems];

        if (hasCreateSNFeature) {
            this.profileItems.push({
                option: EProfileItemClick.createSN,
                translation: gTranslations.fragments.newSocialNetwork
            });
        }
    }

    toggleSocialNetworkTools(event: PointerEvent): void {
        event.preventDefault();

        if (this.attSvc.isPlayerAnAgentLoggedInInCurrentSocialNetwork()) {
            this.snackBar.open("Você não pode mudar de rede quando em atendimento.", "Fechar", {
                duration: 5000,
                panelClass: "high-contrast",
                horizontalPosition: "left",
                verticalPosition: "top",
            });
            return;
        }

        this.canShowSocialNetworkTools = !this.canShowSocialNetworkTools;
    }

    closeSocialNetworkTools() {
        this.closeAllInteractions();
    }

    toggleShareVisibility(): void {
        this.closeAllInteractions();
        this.shareVisible = true;
    }

    getShareCount(): number {
        return this.shareCount;
    }

    canShowShare(): boolean {
        return this.getShareCount() > 0;
    }

    canShowInitCallBtn(): boolean {
        return this.attSvc.isLoggedIn;
    }

    getNotificationsCount(): number {
        // TODO: Notifications
        return 0;
    }

    getMenuCount(): any {
        const menuCount: number = this.getShareCount() + this.getNotificationsCount();

        if (menuCount > 0) {
            return menuCount;
        }

        return;
    }

    // #region Share
    getShareHandler(): NShare.ShareHandler {
        if (!this.shareHandler) {
            const parameter: NShare.IShareParameter = {
                clientCallback: this,
            };
            this.shareHandler = new NShare.ShareHandler(parameter);
        }
        return this.shareHandler;
    }

    setSharingAmountCallback(count: number): void {
        this.shareCount = count;
        this.interfaceSignalEmissor(new InterfaceInfoSignal({ shareCount: count }));
    }
    closeShareWindowCallback(): void {
        this.shareVisible = false;
    }

    verifySignShare(sign: InterfaceInfoSignal): void {
        if (sign.isShareOpen()) {
            this.closeAllInteractions();
            this.shareVisible = true;
            this.cdRef.markForCheck();
        } else {
            if (!sign.getShareCount()) {
                this.shareVisible = false;
                this.cdRef.markForCheck();
            }
        }
    }

    public isShareVisible(): boolean {
        return this.shareVisible;
    }

    public hideShare(): void {
        this.shareVisible = false;
    }
    // #endregion

    xpto() {

    }

    profileItems: IProfileItemClick[] = [...allProfileItems];

    //# region player
    public profileItemClick(optionClicked: EProfileItemClick) {
        switch (optionClicked) {
            case EProfileItemClick.logout:
                this.authService.logout();
                break;
            case EProfileItemClick.messages:
                this.routerManagerSvc.goToGroup(
                    this.sessionSvc.getSelectedAvatarPersonalGroup(),
                    routeID.groups.chat,
                );
                break;
            case EProfileItemClick.profile:
                this.routingSvc.navigateToId(
                    routeID.player.profile,
                    this.sessionSvc.getAvatarID()
                );
                break;
            case EProfileItemClick.settings:
                this.openSettings();
                break;
            case EProfileItemClick.notifications:
            case EProfileItemClick.bindSocialMedia:
                console.error("not implemented yet");
                break;
            case EProfileItemClick.about:
                this.dialog.open({
                    componentRef: AboutDialogComponent
                });
                break;
            case EProfileItemClick.rootGroup:
                this.router.navigateByUrl(this.groupRoute);
                break;
            case EProfileItemClick.createSN:
                const createSNFeature = this.featuresDropdownList.find(feature => feature.item?.getPrimaryID() === AppBarShortcuts.Corporate);

                if (createSNFeature?.item) {
                    this.gcreatorSVC.create(
                        createSNFeature.item,
                        this.currentSelectedGroup,
                        this
                    );
                }
                break;
        }
    }

    generateSettingsCmModalHandler(open: boolean) {
        const settings: ICmModalSettings = {
            width: `500px`,
            maxWidth: `90vw`,
            maxHeight: `90vh`,
            id: `mh-settings-modal`,
            panelClass: [
                `mmodal-10-rounded-border`,
                `mmodal-15-padding`,
                `mmodal-clear-grey-background`
            ]
        };
        const parameter: ICmModalParameter = {
            state: (open) ? ECmModalState.Open : ECmModalState.Closed,
            componentProjectionInstance: SettingsModalComponent,
            settings
        };
        this.settingsCmModalHandler = new CmModalHandler(parameter);
    }

    openSettings() {
        this.generateSettingsCmModalHandler(true);
        this.cdRef.markForCheck();
    }
    // #endregion

    interfaceSignalEmissor(signal: InterfaceInfoSignal) {
        this.publisher.specificSignalEmissorOnGenericStream(signal);
    }

    @HostListener('window:resize', ['$event'])
    onWindowResize(event: Event) {
        this.notificationOverlayRef?.updatePositionStrategy(this.getPositionStrategyForNotificationButton());
        this.notificationOverlayRef?.updatePosition();
    }


    execTests() {
        this.newMainHeaderSvc.execTests()
    }

    isDevMode() {
        return !environment.production
    }

    public handleSearchInputFocus() {
        this.canShowSearch = true;
        this.expandSearchInput = true;

        if (this.selectedSearchType === ESearchContentType.GlobalContent && !this.searchHandler.getSlave().hasAnyGlobalResults?.()) {
            this.searchHandler.getSlave().resetGlobalSearchResults?.();
            return;
        }
    }

    public handleSearchInputBlur() {
        this.expandSearchInput = false;

        if (this.selectedSearchType === ESearchContentType.SocialNetworkContent && !this.searchHandler.getSlave().hasAnySNResults?.()) {
            this.searchHandler.getSlave().closeSearchBox();
            return;
        }
        if (this.selectedSearchType === ESearchContentType.GlobalContent && !this.searchHandler.getSlave().hasAnyGlobalResults?.()) {
            this.searchHandler.getSlave().closeSearchBox();
            this.searchHandler.getSlave().resetGlobalSearchResults?.();
            return;
        }

    }

    public getUnreadNotificationsCount(): number {
        return this.notificationSvc.getUnreadCount();
    }

    public handleSearcTypeSelec(searchType: ISearchTypeContent) {
        this.selectedSearchType = searchType.type;
        this.searchHandler = new HandlerSearch(this.buildSearchParameter());
    }

    public toggleMenu(): void {
        this.mainHeaderViewComponent.toggleMenu();
    }

    public isMenuOpen(): boolean {
        return this.mainHeaderViewComponent?.sideNav?.opened;
    }

    public async loadMoreVersions() {
        console.log('loadmoreVersions')
        this.isLoadingVersions = true;
        await this.loadAllMigrationVersions()
        this.isLoadingVersions = false;
    }
}
