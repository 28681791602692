import { ENonSerializableObjectType } from "@colmeia/core/src/shared-business-rules/non-serializable-id/non-serializable-id-interfaces";
import { getUniqueStringID } from "../../../tools/utility";
import { IBasicElementCreate, ISimpleElementCreate } from "./basic-element";
import { basicElementRootLatestVersion, IBasicElementClient } from "./graph-basic-element-interfaces";
import { IRuleProcessorBasicJSON } from "./graph-interfaces";
import { EGraphElementType, gDefaultRenderData } from "./graph-types";

export function getCreateParameter(simple: ISimpleElementCreate): IBasicElementCreate {
    return simple;
}

export function initRuleProcessorBasicJSON(): IRuleProcessorBasicJSON {
    return {
        allGraphElements: {},
        fromConnections: {},
        toConnections: {},
    }
}

export function initEmptyBasicElementClient(elementType: EGraphElementType, idParent: string): IBasicElementClient {
    const client: IBasicElementClient = {
        nsType: ENonSerializableObjectType.graphElement,
        nName: '',
        idParent,
        element: {
            elementType: elementType,
            renderData: gDefaultRenderData,
            idGraphElement: getUniqueStringID(),
        },
        version: basicElementRootLatestVersion
    };
    return client;
}
