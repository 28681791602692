import { asConst } from "../../../tools/type-utils";
import { IntersectPartialExplicit } from "../../../tools/utility/types";
import { EMetadataNames } from "../../metadata/metadata-db";
import { EAllSerializable, getTranslationsFields } from "../all-serializables";
import { IScreenOptions } from "../atext-types";

export const metadataNamesOptions1 = getTranslationsFields(asConst<IntersectPartialExplicit<IScreenOptions, { fields: { [key in EMetadataNames]?: number } }>>()({
    idSerializable: EAllSerializable.MetadataNames,
    fields: {
        [EMetadataNames.botDestiny]: 5,
        [EMetadataNames.cellular]: 4,
        [EMetadataNames.email]: 2,
        [EMetadataNames.errorMessage]: 9,
        [EMetadataNames.facebookID]: 11,
        [EMetadataNames.fullAdress]: 7,
        [EMetadataNames.idUser]: 8,
        [EMetadataNames.mainDoc]: 1,
        [EMetadataNames.name]: 3,
        [EMetadataNames.openMediaLink]: 6,
        [EMetadataNames.textMessage]: 10,
        [EMetadataNames.envGreetingDaily]: 12,
        [EMetadataNames.envInFrontOfMe]: 13,
        [EMetadataNames.envDispatch]: 14,
        [EMetadataNames.lastUserInput]: 15,
        [EMetadataNames.client]: 16,
        [EMetadataNames.date]: 17,
        [EMetadataNames.attendent]: 18,
        [EMetadataNames.crmTypeOfCase]: 19,
        [EMetadataNames.attendentID]: 20,
        [EMetadataNames.colmeiaID]: 21,
        [EMetadataNames.urlShakingHands]: 22,
        [EMetadataNames.logConversation]: 23,
        [EMetadataNames.optOut]: 25,
        [EMetadataNames.GPS]: 26,
        [EMetadataNames.ZipCode]: 27,
        [EMetadataNames.customerProvideName]: 28,
        [EMetadataNames.channelAddress]: 29,
        [EMetadataNames.channetlType]: 30,
        [EMetadataNames.customerAddress]: 31,
        [EMetadataNames.instagramID]: 32,
        [EMetadataNames.nestedFormToGo]: 33,
        [EMetadataNames.nestedFormCompleted]: 34,
        [EMetadataNames.telegramID]: 35,
        [EMetadataNames.channelId]: 36,
        [EMetadataNames.ProductId]: 37,
        [EMetadataNames.ProductName]: 38,
        [EMetadataNames.ProductCategory]: 39,
        [EMetadataNames.ProductCurrency]: 40,
        [EMetadataNames.ProductImageURL]: 41,
        [EMetadataNames.ProductPrice]: 42,
        [EMetadataNames.ProductDescription]: 43,
        [EMetadataNames.ProductURL]: 44,
        [EMetadataNames.ProductGTIN]: 45,
        [EMetadataNames.firstMessage]: 46,
        [EMetadataNames.numberOfOpenTickets]: 47,
        [EMetadataNames.ticketTitle]: 48,
        [EMetadataNames.ticketProtocol]: 49,
        [EMetadataNames.ticketSeverity]: 50,
    }
}));

export const metadataNamesOptions2 = getTranslationsFields(asConst<IntersectPartialExplicit<IScreenOptions, { fields: { [key in EMetadataNames]?: number } }>>()({
    idSerializable: EAllSerializable.MetadataNames2,
    fields: {
        [EMetadataNames.ticketUrgency]: 1,
        [EMetadataNames.ticketSupportLevel]: 2,
        [EMetadataNames.ticketServicePhase]: 3,
        [EMetadataNames.ticketCloseState]: 4,
        [EMetadataNames.ticketTicketState]: 5,
        [EMetadataNames.AI_examplePairs]: 6,
        [EMetadataNames.AI_queryText]: 7,
        [EMetadataNames.AI_intentSummarization]: 8,
        [EMetadataNames.AI_intentRewrite]: 9,
        [EMetadataNames.AI_classificationResult]: 10,
        [EMetadataNames.AI_intentNames]: 11,
        [EMetadataNames.AI_priorClassification]: 12,
        [EMetadataNames.AI_priorReasoning]: 13,
        [EMetadataNames.AI_lastPrompt]: 14,
        [EMetadataNames.dynamicIsland]: 15,
    }
}));

export const metadataNamesTranslations = {
    ...metadataNamesOptions1,
    ...metadataNamesOptions2,
};
