import { constant } from "@colmeia/core/src/business/constant";
import { IFormSchema } from "@colmeia/core/src/general-form/general-form-interface";
import { EAllSerializable } from "@colmeia/core/src/shared-business-rules/const-text/all-serializables";
import { IMetadataRegister, IMetaEngagement } from "@colmeia/core/src/shared-business-rules/metadata/meta-engagement";
import { isValidFunction, isValidRef } from "@colmeia/core/src/tools/utility";
import { ChatOptionsAttendanceComponent } from "app/components/chat-options/chat-options-attendance/chat-options-attendance.component";
import { ChatOptionsAttendantRegistrationComponent } from "app/components/chat-options/chat-options-attendant-registration/chat-options-attendant-registration.component";
import { ChatOptionsDynamicListRegistrationComponent } from "app/components/chat-options/chat-options-dynamic-list-registration/chat-options-dynamic-list-registration.component";
import { HandlerHexagonon } from "app/handlers/hexagono.handler";

export interface IChatOptionMapper {
    [serializableId: string]: any;
}

export const TEMP_conversationHistory = "conversationHistoryId";
export const TEMP_initCall = "initCall";
export const TEMP_campaignList = "campaignList";
export const TEMP_openContactList = "openContactList";
export const TEMP_initCopilot = 'initCopilot';
export const TEMP_goToAgentDash = 'goToAgentDash';

export interface IFormSchemmaAndEngagement {
    form: IFormSchema;
    engagement: IMetaEngagement;
    metaRegister: IMetadataRegister;
}
export interface IFormSchemmaAndEngagementWithMedia extends IFormSchemmaAndEngagement {
    idMedia: string,
    hexagon?: HandlerHexagonon
}

export enum EChatOptionAction {
    /** @deprecated */
    filter = 'filter',
    goToDashboard = 'goToDashboard',
    conversationHistory = "conversationHistory",
    initCall = "initCall",
    campaignList = "campaignList",
    openContactList = "openContactList",
    // initCopilot = 'initCopilot'
}

export const ChatOptionsViewsMap: IChatOptionMapper = {
    [EAllSerializable.ChatOptionsGoToDashboard]: EChatOptionAction.goToDashboard,
    // [constant.chatOption.visualization]: () => ChatOptionsVisualizationComponent,
    [constant.chatFilter.serializableId]: EChatOptionAction.filter,
    [constant.chatOption.attendance]: () => ChatOptionsAttendanceComponent,
    [TEMP_conversationHistory]: EChatOptionAction.conversationHistory,
    [TEMP_initCall]: EChatOptionAction.initCall,
    [TEMP_campaignList]: EChatOptionAction.campaignList,
    [TEMP_openContactList]: EChatOptionAction.openContactList,
    // [TEMP_initCopilot]: EChatOptionAction.initCopilot,
    [TEMP_goToAgentDash]: EChatOptionAction.goToDashboard
}

export const ChatAttendanceViews: IChatOptionView[] = [
    // {
    //     id: 'transferable islands',
    //     text: 'Ilhas Transferíveis',
    //     target: ChatOptionsAttendantTransferableIslandsComponent,
    //     isMinimized: false,
    // },
    // {
    //     id: 'macros',
    //     text: 'Macros',
    //     target: ChatOptionsAttendantMacrosComponent,
    //     isMinimized: false,
    // },
    // {
    //     id: 'support',
    //     text: 'Grupos de suporte interno',
    //     target: ChatOptionsInternalServiceGroupsComponent,
    //     isMinimized: false,
    // },
    // {
    //     id: constant.chatOption.clientRegistration,
    //     text: 'Registro de informação adicional',
    //     target: ChatOptionsClientRegistrationComponent,
    //     isMinimized: false,
    // },
    {
        id: constant.chatOption.attendantRegistration,
        text: 'Registro Atendentes',
        target: ChatOptionsAttendantRegistrationComponent,
        isMinimized: false,
    },
    {
        id: constant.chatOption.attendantDynamicListRegistration,
        text: 'Listas Dinâmicas',
        target: ChatOptionsDynamicListRegistrationComponent,
        isMinimized: false,
    },
]


export type IChatOptionViewTargetComponent = { component: Function; };
export type IChatOptionViewTargetOnClick = { onClick: Function; };

export type TChatOptionViewTarget = IChatOptionViewTargetComponent | IChatOptionViewTargetOnClick;


export function chatOptionViewTargetComponentGuard(target: TChatOptionViewTarget): target is IChatOptionViewTargetComponent {
    return isValidRef(target) && isValidFunction((target as IChatOptionViewTargetComponent).component);
}
export function chatOptionViewTargetOnClickGuard(target: TChatOptionViewTarget): target is IChatOptionViewTargetOnClick {
    return isValidRef(target) && isValidFunction((target as IChatOptionViewTargetOnClick).onClick);
}

export interface IChatOptionView {
    text: string;
    id: string;
    icon?: string;
    injector?: any;
    injectorData?: any;
    isMinimized?: boolean
    disableStartCase?: true;

    target: any;
}
