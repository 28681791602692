import { IComponentParameter } from "app/model/component-comm/basic";
import { MainHandler } from "app/handlers/main-handler";
import { INewAgentCallBatchServer } from "@colmeia/core/src/shared-business-rules/active-1x1-call/active-1x1-model";



export interface IAttendanceActiveCallNewContactListParameters extends IComponentParameter {
    hasSaved?: boolean;
    batchNS?: INewAgentCallBatchServer;
}

export class AttendanceActiveCallNewContactListHandler extends MainHandler {
    private constructor(parameter: IAttendanceActiveCallNewContactListParameters) { 
        super(parameter) 
    }

    public static factory(parameter: IAttendanceActiveCallNewContactListParameters) { 
        return new AttendanceActiveCallNewContactListHandler(parameter); 
    }

    public getComponentParameter() { 
        return super.getComponentParameter() as IAttendanceActiveCallNewContactListParameters 
    }
}
