import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, Injector, OnDestroy, OnInit } from '@angular/core';
import { constant } from '@colmeia/core/src/business/constant';
import { TBasicUniveralInfoArray } from '@colmeia/core/src/comm-interfaces/aux-interfaces';
import { IListNonSerializablesResponse } from '@colmeia/core/src/dashboard-control/dashboard-response-interfaces';
import {
    IFormSchema,
    INonserializableSchemaResponseServer, TFormSchemmaArray
} from '@colmeia/core/src/general-form/general-form-interface';
import { IBatchNonSerializableResponse } from '@colmeia/core/src/request-interfaces/lookup-ns-request';
import { IGetLookupResponse } from '@colmeia/core/src/request-interfaces/lookup-request';
import { IAttendentServicePackClient, IMacroPackageServer } from '@colmeia/core/src/shared-business-rules/attendent-service-pack/attendente-service-pack';
import { EDefaultTag } from '@colmeia/core/src/shared-business-rules/colmeia-tags/tags';
import { IMetadataRegister, TIMetadataServiceRegisterArray } from "@colmeia/core/src/shared-business-rules/metadata/meta-engagement";
import { ENonSerializableObjectType, INonSerializable } from '@colmeia/core/src/shared-business-rules/non-serializable-id/non-serializable-id-interfaces';
import { arrayUnique, isEmptyObject, isInvalid, isValidArray, isValidRef } from '@colmeia/core/src/tools/utility';
import { AttendantDynamicListRegistrationHandler } from 'app/handlers/chat-right-side-bar/attendant-dynamic-list-registration.handler';
import { AttendantRegistrationHandler } from 'app/handlers/chat-right-side-bar/attendant-registration.handler';
import { ClientRegistrationHandler } from 'app/handlers/chat-right-side-bar/client-registration.handler';
import { InternalServiceGroupsHandler } from 'app/handlers/chat-right-side-bar/internal-service-groups';
import { ChatAttendanceViews, IChatOptionView, IFormSchemmaAndEngagement, IFormSchemmaAndEngagementWithMedia } from 'app/model/chat-options.model';
import { AttendanceAnnotationsService } from 'app/services/attendance-annotations.service';
import { AttendanceService } from 'app/services/attendance.service';
import { GenericDashboardRequester } from 'app/services/generic-dashboard-requester.service';
import { LookupService } from 'app/services/lookup.service';
import { SessionService } from 'app/services/session.service';
import { environment } from 'environments/environment-client';
import { startCase } from 'lodash';
import { Subject, Subscription, from } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { NavigatorServices } from 'app/services/controllers-services/navigator/navigator.service';
import { GroupNavigationEnd, GroupNavigationStart } from 'app/services/controllers-services/navigator/navigator';
import { HardwareLayerService } from 'app/services/hardware';
import { ChatBackboneModel } from 'app/model/chat-backbone.model';
import { Group } from '@colmeia/core/src/business/group';
import { AttendanceConversationHistorySearchComponent } from 'app/components/dashboard/attendance-conversation-history-search/attendance-conversation-history-search.component';
import { ColmeiaWindowService } from 'app/components/dashboard/dashboard-foundation/colmeia-window/colmeia-window.service';
import { AttendanceActiveEditHelperService } from 'app/services/attendance-active-edit-call-init.service';
import { CustomerFinderComponent } from 'app/components/customer-finder/customer-finder.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ISearchActiveCallResult, TISearchActiveCallResultArray } from '@colmeia/core/src/shared-business-rules/active-1x1-call/active-1x1-model';
import { ContactListService } from 'app/components/dashboard/contact-list/contact-list.service';
import { v4 as uuidv4 } from 'uuid';
import { AttendanceCopilotComponent, TAttendanceCopilotData } from 'app/components/copilot/attendance-copilot/attendance-copilot.component';
import { AttendanceCopilotService } from 'app/services/attendance-copilot.service';
import { AttendanceEvent, EAttEventType } from 'app/model/attendence.model';

interface ICreateInjector {
    handler: any
    value?: any
    params?: any[]
}

export class MacroPackageHandler {
    constructor(private macropackage: IAttendentServicePackClient) { }

    getMacroPackage() {
        return this.macropackage
    }
}

export type ThasNotAttendantRegisterMetadata = boolean;

@Component({
    selector: 'chat-options-attendance',
    templateUrl: './chat-options-attendance.component.html',
    styleUrls: ['./chat-options-attendance.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChatOptionsAttendanceComponent implements OnInit, AfterViewInit, OnDestroy {

    public loadingView: boolean = true;
    public servicePackNotFound: boolean = false;
    public views: IChatOptionView[];
    public clientRegisterMetadatas: TFormSchemmaArray;
    private macroPkg: IAttendentServicePackClient;
    private dynamicListRegisterMedatadas: IFormSchemmaAndEngagement[];
    private componentDestroy$ = new Subject();
    // public buttonColor: string;
    private attendanceEventSubscription: Subscription;

    constructor(
        private session: SessionService,
        private attendanceSvc: AttendanceService,
        private _injector: Injector,
        private cdr: ChangeDetectorRef,
        private lookupSvc: LookupService,
        private nsRequester: GenericDashboardRequester,
        private hw: HardwareLayerService,
        private attSvc: AttendanceService,
        private attendanceAnnotationsSvc: AttendanceAnnotationsService,
        private navigatorSvc: NavigatorServices,
        private colmeiaWindow: ColmeiaWindowService,
        private activeCallService: AttendanceActiveEditHelperService,
        private dialog: MatDialog,
        private contactListSvc: ContactListService,
        private copilotSvc: AttendanceCopilotService,
    ) {
        this.attendanceEventSubscription = this.attendanceSvc.events$.subscribe((event: AttendanceEvent) => {
            if (event?.type === EAttEventType.end) {
                const groupdIdMap = this.copilotSvc.getCopilotWindowRefs(event.idGroup);
                if (!isEmptyObject(groupdIdMap)) {
                    Object.values(groupdIdMap!).forEach(ref => ref.close());
                    this.copilotSvc.deleteCopilotWindowRefs(event.idGroup);
                }
            }
        })
    }

    groupsBasicInfoLookups: any

    public ngOnInit() {
        // this.buttonColor = "color-mix(in srgb, var(--info-800) 30%, white 0%)";
        this.navigatorSvc.groupNavigation$.pipe(takeUntil(this.componentDestroy$)).subscribe((ev) => {
            if (ev instanceof GroupNavigationStart) {
                this.views = [];
                this.loadingView = true;
                this.markForCheck();
            } else if (ev instanceof GroupNavigationEnd) {
                this.ngAfterViewInit()
            }
        });
    }

    public ngOnDestroy() {
        this.attendanceEventSubscription.unsubscribe();
        this.attendanceAnnotationsSvc.attendanceFormData = undefined;
        this.componentDestroy$.next();
        this.componentDestroy$.complete();
    }

    public ngAfterViewInit(): void {
        const currentGroupID = this.session.getSelectedGroupID();
        const isNotAttendanceGroup = !this.attSvc.isInAttendDB(currentGroupID)

        if (isNotAttendanceGroup) return;

        // TOP
        /**
         * Cria um observable a partir da chamada do initAsync e usa o operador takeUntil para
         * fazer o unsubscribe quando o componente é destruído.
         * Isso impede que o código assíncrono continue executando depois do componente ser destruído
         */
        from(this.initAsync())
            .pipe(takeUntil(this.componentDestroy$))
            .subscribe(
                _ => _,
                error => {
                    throw error;
                }
            );
    }

    public getCurrentChatGroup(): Group {
        return ChatBackboneModel.getActiveBackBoneModel().getGroup();
    }

    private async initAsync(): Promise<void> {
        await this.loadServicePack();
        this.macroPkg = await this.getMacroPackageAsync();
        await this.fetchSchemas();
        this.attendanceAnnotationsSvc.attendanceFormData = await this.getFormData(this.macroPackage.attendantRegisterMedatadas);
        if (this.macroPackage?.allowUsageOnAttRegister) {
            const finalizationData = await this.getFormData([this.macroPackage.finalizationMetadata]);
            this.attendanceAnnotationsSvc.attendanceFormData.push(...finalizationData);
        }

        this.dynamicListRegisterMedatadas = isValidArray(this.macroPackage?.attendantDynamicListRegisterMedatadas)
            ? await this.getFormData(this.macroPackage.attendantDynamicListRegisterMedatadas)
            : [];
        await this.initViews();

        this.markForCheck();
    }

    private async loadServicePack() {
        await this.attendanceSvc.updateAttendentServicePackForGroup(this.session.getSelectedGroupID());
    }

    private formsHash: { [key: string]: IFormSchema } = {};

    private async fetchSchemas(): Promise<void> {
        const allIDS: string[] = [];
        const macroPkg = await this.getMacroPackageAsync();

        if (!isValidRef(macroPkg)) return;

        const { clientRegisterMetadatas, attendantRegisterMedatadas, attendantDynamicListRegisterMedatadas, finalizationMetadata } = macroPkg;

        if (isValidArray(clientRegisterMetadatas)) {
            allIDS.push(...clientRegisterMetadatas.map(register => register.idMetadata));
        }

        if (isValidArray(attendantRegisterMedatadas)) {
            allIDS.push(...attendantRegisterMedatadas.map(register => register.idMetadata));
        }

        if (isValidArray(attendantDynamicListRegisterMedatadas)) {
            allIDS.push(...attendantDynamicListRegisterMedatadas.map(register => register.idMetadata));
        }

        if (isValidRef(finalizationMetadata)) {
            allIDS.push(finalizationMetadata.idMetadata);
        }

        const allForms: INonserializableSchemaResponseServer[] = await this.lookupSvc.getBatchNonSerializables<INonserializableSchemaResponseServer[]>(arrayUnique(allIDS));

        for (const form of allForms) {
            this.formsHash[form.schemma.idSchemma] = form.schemma;
        }
    }

    get macroPackage(): IAttendentServicePackClient {
        return this.attendanceSvc.getAttendentServicePackForGroup(this.session.getSelectedGroupID())
    }

    async getMacroPackageAsync(): Promise<IAttendentServicePackClient> {
        if (!isValidRef(this.macroPackage)) {
            await this.loadServicePack();
        }

        return this.macroPackage;
    }

    public isCopilotEnabled(): boolean {
        // console.log({COPILOT: this.macroPkg?.copilotId})
        return !!this.macroPkg?.copilotId
    }

async openCopilotWindow() {
        const currentGroupId = this.session.getSelectedGroupID();
        const attTarget = await this.attendanceSvc.getCurrentAttendanceTarget();

        const windowIdentifier = uuidv4()+ '-' + currentGroupId;

        const copilotWindowRef = this.colmeiaWindow.open(AttendanceCopilotComponent, {
            minWidth: '200px',
            minHeight: '300px',
            group: 'Copilot',
            panelClass: ['no-padding', 'copilot-container'],
            windowIdentifier,
            title: 'Copilot'+( attTarget ? ' p/ ' + attTarget : '' ),
            hasBackdrop: false,
            data: <TAttendanceCopilotData>{
                copilotId: this.macroPkg.copilotId,
            },
            disableMinizeAllWhileVisible: true,
        })
        this.copilotSvc.setCopilotWindowRef(currentGroupId, copilotWindowRef);
    }

    public async initViews(): Promise<void> {
        this.loadingView = true;
        this.markForCheck();
        this.views = []
        const viewsPromiseList: IChatOptionView[] = ChatAttendanceViews
            .filter(view => (view.id !== "support") || environment.allDevFeatures)
            .filter(view => (view.id !== constant.chatOption.clientRegistration) || environment.allDevFeatures)
            .filter(view => (view.id === constant.chatOption.attendantDynamicListRegistration)
                ? isValidArray(this.macroPackage?.attendantDynamicListRegisterMedatadas)
                : true
            )
            .map(view => this.addInjector(view))
            .filter(view => isValidRef(view) && view.text !== "Macros")
            .map(view => this.transformTitleToCaseCaseIfAllowed(view));

        this.views = viewsPromiseList;
        console.log({VIEWS: this.views})
        this.loadingView = false;

        if (this.hw.isMobile()) {
            this.setMinimizeOnAllViews(true);
        }

        this.markForCheck();
    }

    public transformTitleToCaseCaseIfAllowed(view: IChatOptionView): IChatOptionView {
        if (isInvalid(view.disableStartCase)) view.text = startCase(view.text);
        return view;
    }

    public switchMinimizeOnAllViews() {
        this.setMinimizeOnAllViews(!this.isAllViewsMinimized)
    }

    public setMinimizeOnAllViews(value = true) {
        for (let view of this.views) this.setMinimizeOnView(view, value);

        this.markForCheck();
    }

    public setMinimizeOnView(view: IChatOptionView, value = true) {
        view.isMinimized = value
    }

    public handleViewClick(view: IChatOptionView) {
        this.switchMinimizeOnView(view);
        this.markForCheck();
    }

    public switchMinimizeOnView(view: IChatOptionView) {
        view.isMinimized = !view.isMinimized;
    }

    // códito bonito
    get isAllViewsMinimized() {
        const { views = [] } = this

        return views.every(view => view.isMinimized);
    }
    // /códito bonito

    private getSchemasWithMetaEngagement(registers: IMetadataRegister[]): IFormSchemmaAndEngagement[] {
        const result: IFormSchemmaAndEngagement[] = [];
        for (const reg of registers) {
            result.push({
                engagement: reg.engagement,
                form: this.formsHash[reg.idMetadata],
                metaRegister: reg,
            });
        }
        return result;
    }

    private addInjector = (view: IChatOptionView): IChatOptionView => {
        let injector: Injector;
        // return

        if (!isValidRef(this.macroPkg) || this.servicePackNotFound) {
            this.servicePackNotFound = true;
            this.markForCheck();
            return;
        }

        switch (view.id) {
            case constant.chatOption.clientRegistration:
                injector = this.createInjector({
                    handler: ClientRegistrationHandler,
                    value: this.getSchemasWithMetaEngagement(this.macroPkg.clientRegisterMetadatas)
                });
                break;
            case constant.chatOption.attendantRegistration: {
                injector = this.createInjector({
                    handler: AttendantRegistrationHandler,
                    value: this.attendanceAnnotationsSvc.attendanceFormData
                });
                break;
            }

            case constant.chatOption.support:
                injector = this.createInjector({
                    handler: InternalServiceGroupsHandler,
                    params: [this.macroPkg.serviceGroups, this.groupsBasicInfoLookups,],
                });
                break;

            case constant.chatOption.attendantDynamicListRegistration:
                injector = this.createInjector({
                    handler: AttendantDynamicListRegistrationHandler,
                    params: [this.dynamicListRegisterMedatadas],
                });
                break;

            default: {
                injector = this.createInjector({
                    handler: MacroPackageHandler,
                    value: this.macroPkg
                })
            }
        }

        return {
            ...view,
            injector
        };
    };

    async getFormData(metadaEngagement: TIMetadataServiceRegisterArray): Promise<IFormSchemmaAndEngagement[]> {
        const schemas: IFormSchemmaAndEngagement[] = this.getSchemasWithMetaEngagement(metadaEngagement);

        schemas.sort((a, b) => a.form.idSchemma > b.form.idSchemma ? 1 : -1)
        // console.log({ attendantRegisterMedatadas: this.macroPackage.attendantRegisterMedatadas, schemas });

        const formNserList: INonSerializable[] = (await this.getFormNserList(schemas.map(schema => schema.form.idSchemma)))
        formNserList.sort((a, b) => a.idNS > b.idNS ? 1 : -1)

        const formDataWithMedia: IFormSchemmaAndEngagementWithMedia[] = schemas
            .map((schema, idx) => ({
                idMedia: formNserList[idx].medias?.[0]?.idMedia,
                engagement: schema.engagement,
                form: schema.form,
                metaRegister: schema.metaRegister,
            }))
        return formDataWithMedia
    }

    public getFormNserList = async (idNSList: string[]): Promise<INonSerializable[]> => {
        const nsReq = this.nsRequester.getRequestForGenericNS({
            nsType: ENonSerializableObjectType.formSchemma,
            taggable: {
                demandedTag: EDefaultTag.serviceAttendent,
                searchedTags: []
            },
            idsNS: idNSList,
        });

        const res: IListNonSerializablesResponse = await this.nsRequester.request(
            nsReq,
            false
        ) as IListNonSerializablesResponse;

        return (<IBatchNonSerializableResponse><unknown>res).nonSerializables;
    }

    async getGroupsBasicInfoLookups(servicePack: IMacroPackageServer): Promise<TBasicUniveralInfoArray> {
        const response: IGetLookupResponse = await this.lookupSvc.getServicePackGroups(servicePack.idNS)
        return response.lookups ? response.lookups : []
    }

    createInjector({ handler, value, params = [value] }: ICreateInjector) {
        return Injector.create({
            providers: [
                {
                    provide: handler,
                    useFactory: () => new handler(...params),
                    deps: []
                }
            ],
            parent: this._injector
        });
    }

    public markForCheck() {
        this.cdr.markForCheck();
    }

    //#region Actions

    public async openActiveCall() {
        let target = await this.attendanceSvc.getCurrentAttendanceTarget()
        const dialogRef: MatDialogRef<CustomerFinderComponent> = this.dialog.open(CustomerFinderComponent, {
            panelClass: "avarage-size",
            minWidth: "70vw",
        })

        dialogRef.componentInstance.handler = {
            target,
            allowedSearchSources: {
                standard: {},
                infoSquare: {},
            },
            onResult: (result: TISearchActiveCallResultArray) => {
                if (result.length === 1) {
                    const contact = result[0]
                    this.contactListSvc.addContactToSendLists({
                        name: contact.name,
                        target: contact.target,
                        idAvatar: contact.idAvatar,
                        channel: contact.channel,
                    });

                    dialogRef.close()

                }

            },
            onCustomerSelected: async ([contact]: ISearchActiveCallResult[]) => {
                if (!contact) return

                this.contactListSvc.addContactToSendLists(contact)

                dialogRef.close()
            }
        };
    }


    public async addContactToList() {
        let target = await this.attendanceSvc.getCurrentAttendanceTarget()
        const dialogRef: MatDialogRef<CustomerFinderComponent> = this.dialog.open(CustomerFinderComponent, {
            panelClass: "average-size",
            minWidth: "70vw"
        })

        dialogRef.componentInstance.handler = {
            target,
            allowedSearchSources: {
                standard: {},
                infoSquare: {},
            },
            onResult: (result: TISearchActiveCallResultArray) => {
                if (result.length === 1) {
                    const contact = result[0];
                    this.contactListSvc.addContactToLists(
                        contact.name,
                        {
                            target: contact.target,
                            idAvatar: contact.idAvatar,
                            idSocialKey: contact.idSocialKey,
                            channel: contact.channel,
                        }
                    )

                    dialogRef.close()

                }

            },
            onCustomerSelected: async ([contact]: ISearchActiveCallResult[]) => {
                if (!contact) return

                this.contactListSvc.addContactToLists(
                    contact.name,
                    {
                        target: contact.target,
                        idAvatar: contact.idAvatar,
                        idSocialKey: contact.idSocialKey,
                        channel: contact.channel,
                    }
                );

                dialogRef.close()
            }
        };
    }

    public async openConversationHistory() {
        const target = await this.attendanceSvc.getCurrentAttendanceTarget()

        const windowRef = this.colmeiaWindow.open(AttendanceConversationHistorySearchComponent, {
            data: { target: String(target), autoOpenFirstSearchWithUniqueRow: true },
            width: "80vw",
            height: "80vh",
            windowIdentifier: 'chat-options-history-att',
            title: 'Busca',
            group: "Filtro Histórico de Conversas"
        })

        windowRef.afterRestore().subscribe(async () => {
            const target = await this.attendanceSvc.getCurrentAttendanceTarget()

            if (target) {
                if (windowRef.data.target !== target) {
                    windowRef.componentInstance.firstRun = true;
                    windowRef.componentInstance.customerFinder.target = target
                    windowRef.componentInstance.customerFinder.onSearchInput(target)
                }

                windowRef.data.target = target
            }
        })
    }

    public async sendCampaign() {
        const idGroup = this.session.getSelectedGroupID()
        let target = null

        if (this.attendanceSvc.isAttendingOnGroup(idGroup)) {
            const startServiceInteraction = this.attendanceSvc.getInitInteractionServiceOnCurrentGroup()

            if (this.attendanceSvc.canServiceMakeActiveCall(startServiceInteraction)) {
                target = await this.attendanceSvc.getCurrentAttendanceTarget()
            }
        }

        await this.activeCallService.openActiveCallInfo(
            { idParentContactList: null, contactNS: null, contactList: null, initialSearchInputValue: target, parentContactListIdCampaignAction: undefined, refreshListCallback: undefined, sendToAllWithCampaignActionId: undefined, directToActiveCall: true }
        ).promise
    }

}
