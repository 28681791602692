import { Component, OnDestroy, OnInit, Optional } from "@angular/core";
import { ENonSerializableObjectType, INonSerializable } from "@colmeia/core/src/shared-business-rules/non-serializable-id/non-serializable-id-interfaces";
import { ETooltipInfoKey } from "@colmeia/core/src/shared-business-rules/tooltip-json/tooltip-json.model";
import { IContactListServer } from "@colmeia/core/src/shared-business-rules/contact-list/contact-list.model";
import { GenericHomeHandler } from "../../dashboard-foundation/generic-dashboard-home/generic-home.handler";
import { GenericDashboardRowFilter, IDashboardClientCallback, IGenericDashboardRequestParameters, IGenericHomeRow, IMapResponseGeneric } from "../../dashboard-foundation/generic-dashboard-home/generic-dashboard-home.model";
import { IRequest } from "@colmeia/core/src/request-interfaces/request-interfaces";
import { IResponse } from "@colmeia/core/src/request-interfaces/response-interfaces";
import { IListNonSerializablesMatch, IListNonSerializablesRequest } from "@colmeia/core/src/dashboard-control/dashboard-request-interfaces";
import { apiRequestType } from "@colmeia/core/src/request-interfaces/message-types";
import { RequestBuilderServices } from "app/services/request-builder.services";
import { MatDialog } from "@angular/material/dialog";
import { ContactListCreateComponent, IConctacTListCreateParameters, IConctacTListCreateResponse } from "../contact-list-create/contact-list-create.component";
import { take, takeUntil } from "rxjs/operators";
import { SubscriptionGroup } from "app/model/client-utility";
import { ContactListService } from "../contact-list.service";
import { RoutingService } from "app/services/routing.service";
import { ENextGenBotElementType } from "@colmeia/core/src/shared-business-rules/bot/bot-model";
import { useClientPredicates } from "@colmeia/core/src/tools/utility-types";
import { SessionService } from "app/services/session.service";
import { ColmeiaWindowRef } from "../../dashboard-foundation/colmeia-window/colmeia-window-ref";
import { ContactListViewComponent, IContactListViewDialogParameters } from "../contact-list-view/contact-list-view.component";
import { EDefaultTag } from "@colmeia/core/src/shared-business-rules/colmeia-tags/tags";
import { isValidArray } from "@colmeia/core/src/tools/barrel-tools";
import { DashBoardService } from "app/services/dashboard/dashboard.service";

@Component({
    selector: "contact-list-page",
    styleUrls: ["./contact-list-page.component.scss"],
    templateUrl: "./contact-list-page.component.html",
})
export class ContactListPageComponent implements OnInit, OnDestroy, IDashboardClientCallback {

    private nsType: ENonSerializableObjectType = ENonSerializableObjectType.contactList;

    homeHandler: GenericHomeHandler<IContactListServer>;

    private subscriptions: SubscriptionGroup = new SubscriptionGroup();

    public isInWindow: boolean = false;

    constructor(
        private rbs: RequestBuilderServices,
        private matDialog: MatDialog,
        private contactListSvc: ContactListService,
        private routingSvc: RoutingService,
        private sessionSvc: SessionService,
        private dashboardSvc: DashBoardService,
        @Optional() private windowRef: ColmeiaWindowRef,
    ) {
        this.isInWindow = !!this.windowRef;
    }

    ngOnInit() {
        this.initGenericHomeHandler();
    }

    ngOnDestroy(): void {
        this.subscriptions.destroy();
    }

    private initGenericHomeHandler() {
        this.homeHandler = new GenericHomeHandler<IContactListServer>({
            loadPlaceholdRowsLength: this.isInWindow ? 3 : undefined,
            labels: {
                title: "Listas de contatos",
            },
            fixedCallbacks: {
                create: {
                    callback: () => {
                        this.openCreateDialog();
                    }
                }
            },
            fixedRowCallbacks: {
                edit: {
                    callback: async (item) => {
                        this.gotoList(item);
                    }
                },
                delete: true,
                copyID: true,
            },
            customRowCallbacks: {
                delete: async (ns: IContactListServer) => { }
            },
            nsType: this.nsType,
            clientCallback: this,
            defaultTag: EDefaultTag.serviceAttendent,
        })
    }

    private gotoList(ns: IContactListServer) {
        if (this.isInWindow) {
            const dialogRef = this.matDialog.open(ContactListViewComponent, {
                data: <IContactListViewDialogParameters>{ nser: ns },
                panelClass: 'avarage-size'
            });

            dialogRef.afterClosed().pipe(take(1)).subscribe(() => {
                this.windowRef.focus();
            });

            return;
        }

        this.routingSvc.gotoContactList(ns.idNS);
    }

    getRequest({ taggable }: IGenericDashboardRequestParameters): IListNonSerializablesRequest {

        const match = useClientPredicates<IContactListServer>()($ => ({
            [$.ident.idAvatar]: this.sessionSvc.getSelectedAvatarID(),
        }));

        return {
            ...this.rbs.secureBasicRequestForCurrentSN(apiRequestType.nonSerializable.list),
            requestType: apiRequestType.nonSerializable.list,
            nsType: this.nsType,
            taggable,
            match: [match],
            multipleCursor: null,
        }
    }

    private openCreateDialog(nser?: IContactListServer) {
        nser ||= this.contactListSvc.factoryContactListNser();

        if (!isValidArray(nser.tags)) {
            nser.tags = this.dashboardSvc.factoryTags(EDefaultTag.serviceAttendent);
        }

        const dialogRef = this.matDialog.open<ContactListCreateComponent, IConctacTListCreateParameters, IConctacTListCreateResponse>(ContactListCreateComponent, {
            panelClass: "small-size",
            data: {
                nser,
            }
        });

        dialogRef.afterClosed().pipe(takeUntil(this.subscriptions.destroyed$), take(1)).subscribe((r) => {
            if (r.saved) {
                this.homeHandler.refresh();
            }
        });
    }
}
