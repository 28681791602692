import { Injectable } from '@angular/core';
import { ENextGenBotElementType } from "@colmeia/core/src/shared-business-rules/bot/bot-model";
import { EBotActionType, ENodeAssetIcons } from "@colmeia/core/src/shared-business-rules/bot/new-bot-action";
import { EAdditionalNestedAIActions, TReferencedObject } from "@colmeia/core/src/shared-business-rules/graph-transaction/toolbar/config-toolbar.types";
import { EGeneratorTransactionType, IFormTransactionServer, INLPTransactionServer, ITransactionServer, initBotTransaction } from '@colmeia/core/src/shared-business-rules/knowledge-base/bot-transaction/bot-transaction';
import { BotTransactionBaseDataComponent } from 'app/components/dashboard/ai-pages/bot-transaction-edit/bot-transaction-edit.component';
import { BotTransactionBaseDataComponentDiagramDialogHandler } from 'app/components/dashboard/ai-pages/bot-transaction-edit/bot-transaction-edit.handler';
import { EBpmGraphElementInfoMode } from "app/components/dashboard/ai-pages/bpm-graph/bpm-graph-element-info/bpm-graph-element-info/bpm-graph-element-info.handler";
import { IUpserterComponentBehavior, IUpserterComponentReturn, IUpserterNSComponentInfo } from './bpm-rules.types';
import { INTENT_TAB_INDEX } from './bpm-rules-nested-ai.service';
import { isValidString } from '@colmeia/core/src/tools/barrel-tools';
import { ToolbarElementMapToBotAction, ToolbarElementMapToRenderedElement } from '@colmeia/core/src/shared-business-rules/graph-transaction/host/nested-ai/nested-ai-root.host';
import { isColmeiaForms, isNLP } from '@colmeia/core/src/shared-business-rules/bot/bot-function-model-helper';

@Injectable({
    providedIn: 'root'
})
export class BpmRulesNestedAIDataService {

    constructor() { }

    getComponentAndHandler(info: IUpserterNSComponentInfo): (info: IUpserterNSComponentInfo) => IUpserterComponentReturn {
        if (info.mode === EBpmGraphElementInfoMode.Create)
            return this.typeToComponentAndHandler[ToolbarElementMapToRenderedElement[info.type]];

        const isEditingNLP = info.iconClicked?.type === ENodeAssetIcons.edit ||
            info.iconClicked?.type === EAdditionalNestedAIActions.openIntentsTab
        if (isEditingNLP) {
            const openIntentsTab = info.iconClicked.type === EAdditionalNestedAIActions.openIntentsTab ? INTENT_TAB_INDEX : 0;
            const expandedIntentsIDs = isValidString(info.iconClicked.intentId) ? [info.iconClicked.intentId] : [];
            const idParentHosted = info.entityInfo.idParentHosted
            const preSelectedValue: EBotActionType = isValidString(idParentHosted) ? ToolbarElementMapToBotAction[info.type] : undefined
            const infoType = info.type as EGeneratorTransactionType;
            const nsPickerSelectedProperty = isNLP(infoType) || isColmeiaForms(infoType) ?
                infoType : undefined

            return (info: IUpserterNSComponentInfo) => (
                {
                    behavior: IUpserterComponentBehavior.Dialog,
                    component: BotTransactionBaseDataComponent,
                    handler: new BotTransactionBaseDataComponentDiagramDialogHandler({
                        transaction: info.entityInfo.entity as ITransactionServer,
                        clientCallback: undefined,
                        selectedTabIndex: openIntentsTab,
                        expandedIntentsIDs: expandedIntentsIDs,
                        preSelectedValue: preSelectedValue,
                        nsPickerSelectedProperty: nsPickerSelectedProperty as EGeneratorTransactionType
                    })
                }
            );
        }

        return;
    }

    private typeToComponentAndHandler: { [key in TReferencedObject]?: (info: IUpserterNSComponentInfo) => IUpserterComponentReturn } = {
        [ENextGenBotElementType.nlpTransaction]: (info: IUpserterNSComponentInfo) => ( // Creating a Content Generator NLP
            {
                behavior: IUpserterComponentBehavior.Dialog,
                component: BotTransactionBaseDataComponent,
                handler: new BotTransactionBaseDataComponentDiagramDialogHandler({
                    transaction: {
                        ...initBotTransaction(ENextGenBotElementType.nlpTransaction),
                        idParent: info.entityInfo.idParentHosted
                    } as INLPTransactionServer,
                    clientCallback: undefined,
                })
            }
        ),
        [ENextGenBotElementType.formTransaction]: (info: IUpserterNSComponentInfo) => ( // Creating Colmeia Forms
            {
                behavior: IUpserterComponentBehavior.Dialog,
                component: BotTransactionBaseDataComponent,
                handler: new BotTransactionBaseDataComponentDiagramDialogHandler({
                    transaction: initBotTransaction() as IFormTransactionServer,
                    clientCallback: undefined,
                })
            }
        ),
    };
}
