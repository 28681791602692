import { EDelivery360Action } from "@colmeia/core/src/comm-interfaces/barrel-comm-interfaces";

interface ISVGIcon {
    name: string;
    url: string;
}

export const socialMediaTypeToIcon: { [key in EDelivery360Action]: `360${key}` } = {
    [EDelivery360Action.Delivery360WhatsApp]: `360${EDelivery360Action.Delivery360WhatsApp}`,
    [EDelivery360Action.Delivery360SMS]: `360${EDelivery360Action.Delivery360SMS}`,
    [EDelivery360Action.Delivery360FBMessenger]: `360${EDelivery360Action.Delivery360FBMessenger}`,
    [EDelivery360Action.DeliveryInstagram]: `360${EDelivery360Action.DeliveryInstagram}`,
    [EDelivery360Action.Delivery360Email]: `360${EDelivery360Action.Delivery360Email}`,
    [EDelivery360Action.Delivery360Voice]: `360${EDelivery360Action.Delivery360Voice}`,
    [EDelivery360Action.DeliveryTelegram]: `360${EDelivery360Action.DeliveryTelegram}`,
    [EDelivery360Action.DeliveryColmeia]: `360${EDelivery360Action.DeliveryColmeia}`,
    [EDelivery360Action.Delivery360RCS]: `360${EDelivery360Action.Delivery360RCS}`,
};

export const customSvgIcons: ISVGIcon[] = [
    { name: "facebook", url: "assets/icons/common/fb.svg" },
    { name: "google", url: "assets/icons/common/google.svg" },
    { name: "twitter", url: "assets/icons/common/twitter.svg" },
    { name: "linkedin", url: "assets/icons/common/linkedin.svg" },
    { name: socialMediaTypeToIcon[EDelivery360Action.Delivery360WhatsApp], url: "assets/icons/delivery-360/chat_icon_whats.svg" },
    { name: socialMediaTypeToIcon[EDelivery360Action.Delivery360SMS], url: "assets/icons/delivery-360/chat_icon_sms.svg" },
    { name: socialMediaTypeToIcon[EDelivery360Action.Delivery360RCS], url: "assets/icons/delivery-360/chat_icon_sms.svg" },
    { name: socialMediaTypeToIcon[EDelivery360Action.Delivery360FBMessenger], url: "assets/icons/delivery-360/chat_icon_messenger.svg" },
    { name: socialMediaTypeToIcon[EDelivery360Action.DeliveryInstagram], url: "assets/icons/delivery-360/chat_icon_instagram.svg" },
    { name: socialMediaTypeToIcon[EDelivery360Action.Delivery360Email], url: "assets/icons/delivery-360/chat_icon_mail.svg" },
    { name: socialMediaTypeToIcon[EDelivery360Action.Delivery360Voice], url: "assets/icons/delivery-360/chat_icon_voice.svg" },
    { name: socialMediaTypeToIcon[EDelivery360Action.DeliveryColmeia], url: "assets/icons/delivery-360/chat_icon_embed.svg" },
    { name: socialMediaTypeToIcon[EDelivery360Action.DeliveryTelegram], url: "assets/icons/delivery-360/chat_icon_telegram.svg" },
    { name: "bot", url: "assets/icons/chat/bot.svg" },
    { name: "writing-form", url: "assets/icons/chat/writing-form.svg"},
    { name: "info-sheet", url: "assets/icons/chat/info-sheet.svg" },
    { name: "add-list", url: "assets/icons/chat/add-list.svg" },
    { name: "go-to-panel", url: "assets/icons/chat-options/go-to-panel.svg" },
    { name: "active-call", url: "assets/icons/chat-options/active-call.svg" },
    { name: "campaign-list", url: "assets/icons/chat-options/campaign-list.svg" },
    { name: "contact-list", url: "assets/icons/chat-options/contact-list.svg" },
    { name: "at-a-glance", url: "assets/icons/chat/at-a-glance.svg" },
    { name: "send-plane", url: "assets/icons/chat/send-plane.svg" },
];
