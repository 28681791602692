<div class="group-service-wrapper">
    <section class="mat-card, group-service-body">

            <div class="channel-icon-group">
                <span>Canal:</span>
                <img class="channel-icon" [src]="iconUrl" aria-hidden="true">
            </div>

            <span class="chat-item-separator"></span>

            <div class="channel-time-group">
                <span>{{ time }}</span>
                &nbsp;
                <mat-icon class="clock-icon">watch_later</mat-icon>
            </div>

            <span class="chat-item-separator"></span>

            <div class="info-group">
                <mat-icon *ngIf="wasTransfered" class="mat-18">sync_alt</mat-icon>
                <span *ngIf="island" class="island-name">
                    {{ island.nName }}
                </span>
            </div>

            <span class="chat-item-separator"></span>

            <div *ngIf="agentName" class="agent-name-group">
                <mat-icon class="generic-icons">person_outline</mat-icon>
                &nbsp;
                <span class="agent-name">&#64;{{ agentName }}</span>
            </div>

            <span class="chat-item-separator"></span>

            <div class="history-buttons" [visible-horizontal]="botThreadId">
                <ng-container *ngIf="!isMobile else historyButtonsMobile">
                    <button class="forms-history-button" color="primary" (click)="openFormHistory()" [matTooltip]="translations.formHistory" matRipple>
                        <mat-icon class="generic-icons">list_alt</mat-icon>
                    </button>

                    <button class="bot-history-button" color="primary" (click)="openBotHistory()" [matTooltip]="translations.botHistory" matRipple>
                        <span *ngIf="hasQueueMessages" class="has-queue-message-ind" matTooltip="Contém mensagens enviadas durante o tempo em fila"></span>
                        <mat-icon class="generic-icons" svgIcon="bot" style="vertical-align: 1px;"></mat-icon>
                    </button>
                </ng-container>
            </div>

            <!-- <ng-container *ngIf="isInternalSupportMessage">
                <section class="footer" >
                    <div class="footer-line, user-container">
                        <span class="user-container__hexagon">
                            <hexagon [hexagonHandler]="hexagonHandler"> </hexagon>
                            <span class="user-name">&#64;{{ nickName }}</span>
                        </span>
                    </div>

                    <div class="message-container">
                        <span>{{ message }}</span>
                    </div>
                </section>
            </ng-container> -->

    </section>
</div>

<div class="message-footer-content">
  <ng-content></ng-content>
</div>

<!-- mobile stuff -->

<ng-template #historyButtonsMobile>
  <div class="history-buttons" [visible-horizontal]="botThreadId">
    <button mat-stroked-button color="primary" class="button-w-icon" (click)="openBotHistory()">
      <mat-icon style="margin-right: 4px;">smart_toy</mat-icon>
      {{ translations.botHistory }}
    </button>
    <button mat-stroked-button color="primary" class="button-w-icon" (click)="openFormHistory()">
      <mat-icon style="margin-right: 4px;">assignment</mat-icon>
      {{ translations.formHistory }}
    </button>
  </div>
</ng-template>
