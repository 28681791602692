<!-- <section class="chat-attendant">

  <section class="default-view" *ngFor="let metadata of metadatas">
    <text-icon-white-box
      [title]="metadata.form.name"
      [idMedia]="metadata.idMedia"
      (click)="openAnnotations(metadata, $event)"
    ></text-icon-white-box>
  </section>

</section> -->


<button class="attendance-action-button dyamic-attendance-button" *ngFor="let metadata of metadatas" (click)="openAnnotations(metadata, $event)" style="margin-top: 8px;" matRipple>
  <div class="action-icon-container">
    <mat-icon class="attendance-action-icon dynamic-svg-icon" svgIcon="info-sheet"></mat-icon>
  </div>
  <p class="button-text">
    {{metadata.form.name}}
  </p>
</button>
