import { Hours } from "@colmeia/core/src/time/time-utl";
import { colmeiaServiceRequestType, IColmeiaRequestAPI, IColmeiaResponseAPI } from "../../comm-interfaces/colmeia-services-req-res";
import { IGeneralFormAnswer, IGeneralFormAnswerJson, TGeneralFormFieldAnswerValue } from "../../general-form/general-form-answer";
import { SchemaPropertyServer } from "../files/files";
import { IMetadataRegister } from "../metadata/meta-engagement";
import { ValidatePropError } from "../metadata/metadata-util-interfaces";
import { IBasicConversationInfo } from "../../core-constants/types";
import { IContentBasicAsset, TContentAssetArray } from "../bot/bot-content-model";
import { EWebChatEmbeddedType } from "@colmeia/core/src/shared-business-rules/embedded/webchat-embedded.model";
import { IResponse } from "../../request-interfaces/response-interfaces";
import { IRequest } from "../../request-interfaces/request-interfaces";
import { IBrandTheme } from "../brand-theme/brand-theme.model";
import { IEmbeddedUserExitControl } from "../deployed-services/deployed-services";

export interface IBasicHandshakeRequest extends IColmeiaRequestAPI {
    idUserToken: string;
    idBrowserFingertip: string;
}

export interface IEmbeddedChatHandsShakeRequest extends IBasicHandshakeRequest {
    type?: EWebChatEmbeddedType,
    timezoneHR: Hours;
    idService: string;
    sdkAnswer: IGeneralFormAnswerJson;
    idBot?: string,
}

export interface IEmbeddedErrorResponse extends IColmeiaResponseAPI {
    generalErrorMessage?: string;
    validateErrors: ValidatePropError;
    isAbleToLogin: boolean;
    serviceIsNotAllowedFromReferrer?: true;
    domainNotAllowed?: string
}

export interface IEmbeddedChatHandsShakeResponse extends IEmbeddedErrorResponse {
    idHandShake: string;
    firstForm: IGeneralFormAnswer;
    firstMetadata: SchemaPropertyServer;
    hasOpenConversation: boolean;
    customerFormulary: IMetadataRegister;
    goNoFormTrack: boolean;
    changeSubscriberToBrowserID: boolean;
    conversationStatus?: {
        status: IBasicConversationInfo;
        idCustomerChatGroup: string;
    };
    title?: IContentBasicAsset;
    description?: TContentAssetArray;
    welcomePhrases?: TContentAssetArray;
    startButtons?: TContentAssetArray;
    theme: IBrandTheme;
    customerExitControl: IEmbeddedUserExitControl;

};

export interface IEmbeddedChatFirstFormAnswerRequest extends IBasicHandshakeRequest {
    idHandShake: string;
    answer: IGeneralFormAnswer;
};

export interface IEmbeddedChatFirstFormAnswerResponse extends IEmbeddedErrorResponse {
    idHandShake: string;
    idChatGroup: string;

}


export interface IEmbeddedChatNoFormTrackRequest extends IBasicHandshakeRequest {
    idHandShake: string;
}

export type IEmbeddedNoFormTrackResponse = IEmbeddedChatFirstFormAnswerResponse;


export interface IEmbeddedChatTokenReq {
    idHandShake: string;
    idProperty: string;
    value: string;
}
export interface IEmbeddedChatTokenRes {
    isSuccess: boolean
}

//
export interface IEmbeddedChatSendTokenRequest extends IBasicHandshakeRequest, IEmbeddedChatTokenReq {
    requestType: typeof colmeiaServiceRequestType.embedded.sendToken;
}

export interface IEmbeddedChatSendTokenResponse extends IEmbeddedErrorResponse, IEmbeddedChatTokenRes {
    requestType: typeof colmeiaServiceRequestType.embedded.sendToken;
    isSuccess: boolean;
}

//
export interface IEmbeddedChatValidateTokenRequest extends IBasicHandshakeRequest, IEmbeddedChatTokenReq {
    requestType: typeof colmeiaServiceRequestType.embedded.validateToken;

}


export enum ETokenStatus {
    Expired = 'Expired',
    Used = 'Used',
    Nonexistent = 'Nonexistent',
    Available = 'Available',
}
export interface IEmbeddedChatValidateTokenRes extends IEmbeddedChatTokenRes {
    status: ETokenStatus;
}

export interface IEmbeddedChatValidateTokenResponse extends IEmbeddedErrorResponse, IEmbeddedChatValidateTokenRes {
    requestType: typeof colmeiaServiceRequestType.embedded.validateToken;
}


export enum EConversationFinishingActions {
    confirmOnPrompt = "confirmOnPrompt",
    exitPage = "exitPage",
}

export interface IEmbeddedChatFinishConversationRequest extends IRequest {
    requestType: typeof colmeiaServiceRequestType.embedded.finishConversation;
    idHandShake: string;
    finishingAction?: EConversationFinishingActions;
}

export interface IEmbeddedChatFinishConversationResponse extends IResponse {
    responseType: typeof colmeiaServiceRequestType.embedded.finishConversation;
}