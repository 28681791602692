import { AfterViewInit, Component, Inject, Input, Optional, ViewChild } from "@angular/core";
import { MatSelectChange } from "@angular/material/select";
import { EDelivery360Action, ETypeOfContact } from "@colmeia/core/src/comm-interfaces/barrel-comm-interfaces";
import { TArrayID } from "@colmeia/core/src/core-constants/types";
import { ENonSerializableListMode } from "@colmeia/core/src/dashboard-control/dashboard-request-interfaces";
import { initActiveCallContact, initActiveCampaignMessage } from "@colmeia/core/src/shared-business-rules/active-1x1-call/active-1x1-functions";
import { ESendActiveMethod, IActiveCampaignMessage, INewAgentCallContactServer, ISearchActiveCallResult, TISearchActiveCallResultArray } from "@colmeia/core/src/shared-business-rules/active-1x1-call/active-1x1-model";
import { IBasic1x1CallContact, IGetActiveCustomerStatusResponse, IRunActionCampaignForActiveCall, IRunActionCampaignForActiveCallResponseBase, ISaveContactActiveCallResponse } from "@colmeia/core/src/shared-business-rules/active-1x1-call/active-1x1-req-resp";
import { IBasicAsset, KBAssetType, KBAssetTypeClientOnly, TBasicAssetArray } from "@colmeia/core/src/shared-business-rules/bot/bot-asset-model";
import { ETypeOfCustomerContact, TICustomerContactRegisterArray } from "@colmeia/core/src/shared-business-rules/bot/bot-interfaces";
import { ICMassCommunicationAction, TCMassCommActionArray, TCampaigActions } from "@colmeia/core/src/shared-business-rules/campaigns/campaign-type-model";
import { ICAWhatsAppSchedule } from "@colmeia/core/src/shared-business-rules/campaigns/campaing-comm-strategy";
import { getChannelCommFromAction, getKeyVariablesOfAssetArray } from "@colmeia/core/src/shared-business-rules/campaigns/mkt-utils";
import { sendActiveMethodTranslations } from "@colmeia/core/src/shared-business-rules/const-text/views/active-call";
import { SchemaPropertyServer } from "@colmeia/core/src/shared-business-rules/files/files";
import { IVariable, TPropertyKeyArray } from "@colmeia/core/src/shared-business-rules/metadata/metadata-util-interfaces";
import { ENonSerializableObjectType, INonSerializable } from "@colmeia/core/src/shared-business-rules/non-serializable-id/non-serializable-id-interfaces";
import { ECustomerSearchContactType } from "@colmeia/core/src/shared-business-rules/search";
import { NSSharedService } from "@colmeia/core/src/shared-business-rules/shared-services/services/ns.shared.service";
import { TemplateSharedService } from "@colmeia/core/src/shared-business-rules/shared-services/services/template.shared.service";
import { IWhatsAppSocialMediaConnectionServer, IWhatsAppTemplate } from "@colmeia/core/src/shared-business-rules/social-media/social-media.model";
import { INSWhatsAppTemplateServer } from "@colmeia/core/src/shared-business-rules/social-media/template-model";
import { TemplateProcessor } from "@colmeia/core/src/shared-business-rules/template/template-processor";
import { ETooltipInfoKey } from "@colmeia/core/src/shared-business-rules/tooltip-json/tooltip-json.model";
import { getCoundown } from "@colmeia/core/src/time/time-utl";
import { createOnePerTimeFunction, getClock, isValidArray, isValidFunction, isValidNumber, isValidRef, isValidString, typedClone, values } from "@colmeia/core/src/tools/utility";
import { checkEarlyReturnSwitch, Nullish } from "@colmeia/core/src/tools/utility-types";
import { CustomerFinderComponent, TCustomerResultArray } from "app/components/customer-finder/customer-finder.component";
import { ECustomerFinderConsumers, ICustomerCreationFullfiledFields, ICustomerFindHandler } from "app/components/customer-finder/customer-finder.handler.def";
import { RootComponent } from "app/components/foundation/root/root.component";
import { DatePickerHandler } from "app/handlers/date-picker.handler";
import { GenericDashboardEditHandler, IGenericDashboardEditPageClientCallback } from "app/handlers/generic-dashboard-edit.handler";
import { NSPickerHandler } from "app/handlers/ns-picker/ns-picker.handler";
import { EVarEditorEntityType } from "app/handlers/var-editor.handler";
import { IAssetAdderHandler } from "app/model/dashboard/asset-adder.model";
import { AttendanceActiveEditHelperService, ICampaignVariablesInfo, TAttendanceActiveEditPrompt } from "app/services/attendance-active-edit-call-init.service";
import { AttendanceActiveEditCallApiService } from "app/services/attendance-active-edit-call.service";
import { DashBoardService } from "app/services/dashboard/dashboard.service";
import { SnackMessageService } from "app/services/snack-bar";
import { DashboardActiveCallCreateContactHandler, IDashboardActiveCallCreateContactParameters } from "../dashboard-active-call-create-contact/dashboard-active-call-create-contact.handler";
import { DashboardActiveCallInfoHandler, IDashboardActiveCallInfoParameters, TCampaignActionId } from "./dashboard-active-call-info.handler";
import { ITemplatePreviewHandler } from 'app/components/dashboard/dashboard-social-media/whatsapp-templates/whatsapp-template-message-preview/whatsapp-template-message-preview-handler';
import { DashboardWindowEditorRef } from 'app/components/dashboard/dashboard-foundation/colmeia-window/colmeia-window-edit-ref';
import { ColmeiaWindowRef } from 'app/components/dashboard/dashboard-foundation/colmeia-window/colmeia-window-ref';
import { DashboardActiveCallCreateContactComponent } from 'app/components/dashboard/dashboard-attendance-active-call/dashboard-active-call-create-contact/dashboard-active-call-create-contact.component';
import { ICmInputProgessHandler, CmInputProgessComponent } from 'app/components/cm-input-progress/cm-input-progress.component';
import { IServerColmeiaTag, EDefaultTag } from '@colmeia/core/src/shared-business-rules/colmeia-tags/tags';
import { ETagPickerHandlerMode, ITagPickerHandlerParameter } from 'app/handlers/tag-picker-handler';
import { ITagableSearch } from '@colmeia/core/src/shared-business-rules/non-serializable-id/non-serializable-req-resp';
import { DashboardCampaignsService } from "app/services/dashboard-campaigns.service";
import { CanonicalService } from "app/services/canonical.service";
import { EMetadataNames } from "@colmeia/core/src/shared-business-rules/metadata/metadata-db";
import { TGlobalToFieldMap } from "@colmeia/core/src/shared-business-rules/metadata/metadata-utils";
import { ICRMTicketDataWithCustomerName } from "@colmeia/core/src/shared-business-rules/crm/crm-entities";
import { WINDOW_DATA } from "../../dashboard-foundation/colmeia-window/colmeia-window.service";
import { IBPMMarketingRootServer } from "@colmeia/core/src/shared-business-rules/BPM/marketing/bpm-marketing.model";
import { TemplatesService } from "../../marketing/dashboard-channels-templates-list/templates.service";

enum EActiveActionMethod {
    AddToList = "addToList",
    Schedule = "schedule",
    SendNow = "sendNow"
}

@Component({
    selector: 'app-dashboard-active-call-info',
    templateUrl: './dashboard-active-call-info.component.html',
    styleUrls: ['./dashboard-active-call-info.component.scss']
})
export class DashboardActiveCallInfoComponent extends RootComponent<ESendActiveMethod> implements AfterViewInit {

    public actions: { [key in EActiveActionMethod]?: string } = {
        [EActiveActionMethod.SendNow]: "Enviar agora",
        [EActiveActionMethod.Schedule]: "Agendar"
    };

    public action: EActiveActionMethod = EActiveActionMethod.SendNow
    public _handler: DashboardActiveCallInfoHandler;
    public templateSelectedFromCampaign: IWhatsAppTemplate | undefined;
    public templatePreviewHandler: ITemplatePreviewHandler;
    public customerIdAvatar: string;
    public contactIds: TArrayID;
    public get handler(): DashboardActiveCallInfoHandler { return this._handler; }
    @Input()
    public set handler(value: DashboardActiveCallInfoHandler) { this._handler = value; }
    public get parameters(): IDashboardActiveCallInfoParameters { return this.handler.getComponentParameter() }
    public availableActionList: TCMassCommActionArray = [];
    public filteredActionList: TCMassCommActionArray = [];
    public isLoadingActions: boolean;
    public selectedAction: ICMassCommunicationAction | null = null
    public customerWithTargetFound: IGetActiveCustomerStatusResponse;
    public customerClicked: ISearchActiveCallResult & Partial<INewAgentCallContactServer>;
    public $ESendActiveMethod: typeof ESendActiveMethod = typedClone(ESendActiveMethod);
    public customer: IGetActiveCustomerStatusResponse;
    public title: string = 'Lista';
    public genericEdit: GenericDashboardEditHandler;
    public customerContact: INewAgentCallContactServer;
    public get idParentContact(): string { return this.parameters.idParentContactList }
    public set idParentContact(idParentContactList: string) { this.parameters.idParentContactList = idParentContactList }
    public getNSs = this.activeCallAPI.createNSCacheImplementation();
    public form: SchemaPropertyServer;
    public allPrompts: TAttendanceActiveEditPrompt = {};
    public scheduleBeginning: number;

    private campaingMessageVariables: TPropertyKeyArray = []
    private templateLocalCanonicals: TPropertyKeyArray = []
    public actionsChannelsOpenTime: { [targetAndActionCampaingId: string]: number } = {};
    public responseToMe: boolean = true;
    public lastCreateTemplateResult: boolean;
    public loading: boolean = false;
    public initialSearchInputValue: string;
    public serviceHistory: TICustomerContactRegisterArray = [];
    public isLoadingHistory: boolean;
    public rtfBodyPickerHandler: NSPickerHandler;
    public idRTFBody: string;

    public customerFinderHandler: ICustomerFindHandler = {
        allowedSearchSources: {
            standard: {
                customersWithOpenCaseOnly: false
            },
            infoSquare: {}
        },
        onCustomerSelected: (rows) => this.onCustomerResultClicked(rows),
        onCustomerCreationRequest: (fulfilledFields: ICustomerCreationFullfiledFields) => {

            this.openCreateContactDialog(fulfilledFields);
        },
        helpTipKey: ETooltipInfoKey.attendanceActiveCall,
        enableEditContact: true,
        openedFrom: ECustomerFinderConsumers.ActiveCall,
        isLoading: () => this.isAddingContactToList,

    }

    public cfSearchByRadio: ECustomerSearchContactType;
    public target: string;

    public campaignActionTagPickerParameter: ITagPickerHandlerParameter;
    public isSearchingName = false;
    public isShowingHistory = false;
    public nameSearch = "";
    public nameSearchHandler: ICmInputProgessHandler;
    public isAlphabeticalOrder = null;

    private sendToAllWithCampaignActionId: TCampaignActionId;

    public async onCreateContactClick() {
        this.openCreateContactDialog({ phoneNumber: this.target });
    }

    private openCreateContactDialog(fulfilledFields?: ICustomerCreationFullfiledFields, isEditing?: boolean) {
        const handler = this.createActiveCallCreateContactHandler(fulfilledFields, isEditing);

        this.attendanceActiveEditHelperSvc.dynamicDialog(
            DashboardActiveCallCreateContactComponent,
            handler
        );
    }

    public constructor(
        private activeCallAPI: AttendanceActiveEditCallApiService,
        private attendanceActiveEditHelperSvc: AttendanceActiveEditHelperService,
        private snackSvc: SnackMessageService,
        private dashboardSvc: DashBoardService,
        private windowRef: ColmeiaWindowRef<DashboardActiveCallInfoComponent, void, DashboardActiveCallInfoHandler>,
        private templateSvc: TemplatesService,
        @Optional() @Inject(WINDOW_DATA) private editor: DashboardActiveCallInfoHandler,
        private dashboardCampaignsSvc: DashboardCampaignsService,
        private canonicalSvc: CanonicalService,
    ) {
        super({
            ...sendActiveMethodTranslations
        });

        this.handler = this.editor as DashboardActiveCallInfoHandler;

        const { contactNS, initialSearchInputValue, contactList, sendToAllWithCampaignActionId } = this.handler.getComponentParameter();

        this.sendToAllWithCampaignActionId = sendToAllWithCampaignActionId;

        if (contactNS) {
            this.onCustomerResultClicked([contactNS]);
        } else if (isValidString(initialSearchInputValue)) {
            this.initialSearchInputValue = initialSearchInputValue;
        } else if (isValidRef(contactList)) {
            this.onPartialSelected(contactList);
        }

        this.customerFinderHandler.listHasIdCampaignAction = {
            listIdCampaignAction: this.parameters.parentContactListIdCampaignAction || '',
            addContactToListCallback: async (customer: ISearchActiveCallResult) => await this.addContactToListCallback(customer)
        };
    }

    public ngOnInit(): void {
        this.initEntity();
        this.initGenericEditHandler();
        this.getAvailableCampaignList()
        this.initScheduleBeginningHandler();
        this.refreshWindowTitle();
        this.initCampaignActionListHandlers();
        this.setAddToListIfSavable();

        if (this._handler.getComponentParameter().directToActiveCall) {
            this.setupDirectToActiveCall();
        }
    }

    private setupDirectToActiveCall() {
        this.customerFinderHandler.disableStandardSearchOnInfoSquareClick = true;
        this.customerFinderHandler.onInfoSquareRowClick = async (row, metadata) => {
            const channel: EDelivery360Action = EDelivery360Action.Delivery360WhatsApp;
            const globalMap: TGlobalToFieldMap = await this.canonicalSvc.mapGlobalToField(metadata.form);

            const targetIdProperty: string = globalMap[EMetadataNames.cellular].idProperty;
            const emailIdProperty: string = globalMap[EMetadataNames.email]?.idProperty;
            const nameIdProperty: string = globalMap[EMetadataNames.name]?.idProperty;

            const target: string = row.row[targetIdProperty].value;
            const email: string = row.row[emailIdProperty]?.value;
            const name: string = row.row[nameIdProperty]?.value;

            this.isLoadingHistory = true;

            const customerWithTargetFound = await this.activeCallAPI.searchForTarget(
                target,
                EDelivery360Action.Delivery360WhatsApp
            );

            this.isLoadingHistory = false;

            this.customerClicked = {
                target,
                channel,
                email,
                name,
                idAvatar: undefined,
                idSocialKey: undefined,
            };
            this.refreshWindowTitle();
            this.serviceHistory = [];
            this.customerWithTargetFound = {
                contactName: name,
                contact: [
                    {
                        address: target,
                        type: ETypeOfContact.mobile,
                        isDefault: true,
                        isChecked: !customerWithTargetFound.noSocialSub
                    }
                ],
                email,
                idAvatar: '',
                friendlyError: undefined,
                responseType: undefined,
                serviceHistory: []
            };
            this.customerIdAvatar = undefined;
        };
    }

    public get isPartialSend(): boolean {
        return isValidRef(this.handler.getComponentParameter().contactList);
    }
    public setAddToListIfSavable() {
        if (!this.isPartialSend) {
            this.actions = {
                ...this.actions,
                [EActiveActionMethod.AddToList]: "Adicionar a lista",
            };
        }
        if (this.isActiveCallEdit) {
            this.action = EActiveActionMethod.AddToList;
        }
    }

    public async addPartialActiveCall(customer?: ISearchActiveCallResult) {
        const _customer = customer || this.customerClicked;
        let partialActiveCall: INewAgentCallContactServer = {
            target: _customer.target,
            nsType: ENonSerializableObjectType.activeCall1x1,
            ident: undefined,
            nName: _customer.name,
            idAvatarContact: _customer.idAvatar,
            isPartial: true,
            channel: _customer.channel,
        };

        partialActiveCall = this.attendanceActiveEditHelperSvc.addTagToNonSerializablePure(partialActiveCall);
        await this.activeCallAPI.saveChildContact(this.idParentContact, _customer.idAvatar, partialActiveCall);

        isValidRef(customer)
        ? this.parameters.clientCallback.refreshList?.()
        : this.parameters.clientCallback.onUpdateActiveCallInfo();
    }

    public ngAfterViewInit(): void {
        this.cfSearchByRadio = this.customerFinderComponent.searchByRadio;
    }

    get searchByRadio(): ECustomerSearchContactType | undefined {
        return this.customerFinderComponent?.searchByRadio;
    }

    get isActiveCallEdit(): boolean {
        return isValidRef(this.customerClicked?.idNS);
    }

    getResults(): TCustomerResultArray | undefined {
        return this.customerFinderComponent?.searchResult;
    }

    get isPhoneSearch() {
        return this.searchByRadio === ECustomerSearchContactType.phone;
    }

    get showAddContact() {
        return true;
        // const target = this.customerFinderComponent?.target;
        // return (isValidPartialPhoneNumber(target) && isInvalidArray(this.getResults())) && !this.customerFinderComponent?.isFetchingResults;
    }

    public createActiveCallCreateContactHandler(
        fulfilledFields?: ICustomerCreationFullfiledFields,
        isEditing?: boolean
    ): DashboardActiveCallCreateContactHandler {
        return DashboardActiveCallCreateContactHandler.factory({
            fulfilledFields,
            internationContact: this.target?.length !== 9,
            clientCallback: {
                onContactCreated: (contact: IBasic1x1CallContact, response: IGetActiveCustomerStatusResponse) => {
                    this.onContactCreated(contact, response);
                },
                onContactEdited: (contact: IBasic1x1CallContact, response: IGetActiveCustomerStatusResponse) => {
                    this.customerFinderComponent?.onChangeTarget();
                },
                getCustomerFinderComponent: () => this.customerFinderComponent,
            },
            isEditing: !!isEditing,
            contactsList: this.customerFinderComponent?.searchResult as TISearchActiveCallResultArray
        });
    }

    @ViewChild(CustomerFinderComponent)
    customerFinderComponent?: CustomerFinderComponent;

    public onContactCreated(contact: IBasic1x1CallContact, response: IGetActiveCustomerStatusResponse): void {
        this.customerClicked = {
            target: contact.target,
            name: contact.name,
            channel: EDelivery360Action.Delivery360WhatsApp,
            idAvatar: response.idAvatar,
            email: contact.email,
            idSocialKey: ''
        };
        this.refreshWindowTitle();
        this.setCustomerWithTargetFound(response);
    }

    public initEntity(): void {
        if (!isValidRef(this.customerClicked?.idNS)) {
            this.customerContact = initActiveCallContact();
        } else {
            this.customerContact = {
                nsType: this.customerClicked.nsType,
                ident: this.customerClicked.ident,
                nName: this.customerClicked.nName,
                sentAt: this.customerClicked.sentAt,
                idCampaigAction: this.customerClicked.idCampaigAction,
                sendMethod: this.customerClicked.sendMethod ?? ESendActiveMethod.templateOnly,
                scheduleBeginning: this.customerClicked.scheduleBeginning,
                activeMessage: this.customerClicked.activeMessage,
                responseToMe: this.customerClicked.responseToMe,
                target: this.customerClicked.target,
                channel: this.customerClicked.channel
            };
        }
        this.customerContact = this.attendanceActiveEditHelperSvc.addTagToNonSerializablePure(this.customerContact);
    }

    public async getNS<NonSerializable extends INonSerializable>(id: string): Promise<NonSerializable> {
        const ns: INonSerializable = (await this.getNSs([id]))[0];
        return ns as NonSerializable;
    }

    public initGenericEditHandler(): void {
        this.genericEdit = new GenericDashboardEditHandler({
            title: this.title,
            nsType: ENonSerializableObjectType.activeCall1x1,
            clientCallback: this as {} as IGenericDashboardEditPageClientCallback,
            moreOptions: {},
            nser: this.customerContact,
            removeGoBackButton: true,
            removeSaveButton: true,
            removeEntireBox: true,
        });
    }

    public mapIdNSToNS: Map<string, INonSerializable> = new Map();
    public getLocalCanonicalName(idProperty: string): string {
        if (!this.mapIdNSToNS.has(idProperty)) this.fetchNS(idProperty);
        return this.mapIdNSToNS.get(idProperty) && this.mapIdNSToNS.get(idProperty).nName;
    }

    public activeCampaignMessage: IActiveCampaignMessage;

    public initActiveCampaignMessage(): void {
        this.activeCampaignMessage = initActiveCampaignMessage();
    }

    public async fetchNS(idNS: string): Promise<void> {
        const ns = await this.getNS(idNS);
        if (isValidRef(ns)) this.mapIdNSToNS.set(idNS, ns);
    }

    async onSendMethodChange(value: ESendActiveMethod) {
        this.customerContact.sendMethod = value;
        this.updateCampaignAndOrTemplateVariables();
        this.initActiveMethodHandlers();
    }

    async updateCampaignAndOrTemplateVariables(): Promise<void> {
        this.loading = true;
        const allPromptsValues = values(this.allPrompts);
        const previousFullfiledVariables = typedClone(allPromptsValues);

        this.allPrompts = {};
        this.attendanceActiveEditHelperSvc.resetData();
        let allreadyFullfiled: TArrayID = [];


        this.campaingMessageVariables = []
        this.templateLocalCanonicals = []

        this.form = await this.getNS(this.selectedAction.idSchemma) as SchemaPropertyServer;


        // Atenção a ordem dessas três chamadas é fundamental
        // pois representa a prioridade de onde
        // será salvo os dados das variaveis

        if (this.customerContact.sendMethod === ESendActiveMethod.campaignOnly) {
            this.campaingMessageVariables = getKeyVariablesOfAssetArray(this.selectedAction.messages);
            allreadyFullfiled = await this.attendanceActiveEditHelperSvc.processCampaignVariables(
                this.campaingMessageVariables,
                this.allPrompts,
                this.form,
                this.customerWithTargetFound
            )
        }

        this.lastCreateTemplateResult = await this.attendanceActiveEditHelperSvc.createTemplateStructure(
            allreadyFullfiled,
            this.selectedAction,
            this.templateLocalCanonicals,
            this.allPrompts,
            this.customerClicked
        );

        await this.attendanceActiveEditHelperSvc.addCampaingRunInformation(
            this.getCampaignVariablesInfo(),
            this.form,
            this.selectedAction,
            this.customerClicked,
            this.customerWithTargetFound
        );

        values(this.allPrompts).filter(isValidRef).forEach(item => {
            const hasPreviouslyFullfiled = previousFullfiledVariables.find(v => v.idProperty === item.idProperty);
            if (!isValidRef(hasPreviouslyFullfiled?.value)) return;

            this.allPrompts[item.idProperty].value = hasPreviouslyFullfiled?.value;

        });

        // console.log({
        //     templateLocalCanonicals: this.templateLocalCanonicals,
        //     allPrompts: this.allPrompts,
        //     customerClicked: this.customerClicked,
        //     campaignVariable: this.campaingMessageVariables
        // });

        this.loading = false;
    }

    async updateCampaignAndOrTemplateVariablesOnAddingToList(): Promise<void> {
        const allPromptsValues = values(this.allPrompts);
        const previousFullfiledVariables = typedClone(allPromptsValues);

        this.allPrompts = {};
        this.attendanceActiveEditHelperSvc.resetData();
        let allreadyFullfiled: TArrayID = [];


        this.campaingMessageVariables = []
        this.templateLocalCanonicals = []

        this.form = await this.getNS(this.listAction!.idSchemma) as SchemaPropertyServer;


        // Atenção a ordem dessas três chamadas é fundamental
        // pois representa a prioridade de onde
        // será salvo os dados das variaveis

        if (this.customerContact.sendMethod === ESendActiveMethod.campaignOnly) {
            this.campaingMessageVariables = getKeyVariablesOfAssetArray(this.listAction!.messages);
            allreadyFullfiled = await this.attendanceActiveEditHelperSvc.processCampaignVariables(
                this.campaingMessageVariables,
                this.allPrompts,
                this.form,
                this.customerWithTargetFoundToBeAdded
            )
        }

        this.lastCreateTemplateResult = await this.attendanceActiveEditHelperSvc.createTemplateStructure(
            allreadyFullfiled,
            this.listAction!,
            this.templateLocalCanonicals,
            this.allPrompts,
            this.customerClickedAboutToBeAdded
        );

        await this.attendanceActiveEditHelperSvc.addCampaingRunInformation(
            this.getCampaignVariablesInfo(),
            this.form,
            this.listAction!,
            this.customerClickedAboutToBeAdded,
            this.customerWithTargetFoundToBeAdded
        );

        values(this.allPrompts).filter(isValidRef).forEach(item => {
            const hasPreviouslyFullfiled = previousFullfiledVariables.find(v => v.idProperty === item.idProperty);
            if (!isValidRef(hasPreviouslyFullfiled?.value)) return;

            this.allPrompts[item.idProperty].value = hasPreviouslyFullfiled?.value;

        });
    }



    public scheduleBeginningHandler: DatePickerHandler;
    public initScheduleBeginningHandler(): void {
        this.scheduleBeginningHandler = new DatePickerHandler({
            onPick: (number: number) => this.scheduleBeginning = number,
            current: this.scheduleBeginning,
        });
    }

    public getActionDescription(action: ICMassCommunicationAction): string {
        return action.messages.map(message => message.content).join('. ');
    }

    public actionAssetAdder: IAssetAdderHandler;
    public initActionAssetAdder(): void {
        if (!this.selectedAction) return;

        const assetAdder: IAssetAdderHandler = {
            assets: this.isCmpaignOnly()
                ? typedClone(this.selectedAction?.messages || [])
                : this.activeCampaignMessage?.customMessage || []
            ,
            assetTypesEnabled: this.isCustomMessage() ? [KBAssetType.content, KBAssetType.media, KBAssetTypeClientOnly.sticker] : [KBAssetType.content],
            maxAssets: Number.MAX_SAFE_INTEGER,
            onlyText: true,
            saveAsset: (asset, assets, isEdit) => this.saveAsset(asset, assets, isEdit),
            removeAsset: (index, assets) => this.removeAsset(index, assets),
            varEditorMode: EVarEditorEntityType.SchemaProperty,
            disableFallback: true,
            removeEdit: !this.isCustomMessage(),
            removeClose: !this.isCustomMessage(),
            removeAdd: !this.isCustomMessage(),
            removeDelete: !this.isCustomMessage(),
            removeDrag: !this.isCustomMessage(),
            enableChangePositions: this.isCustomMessage(),
        };

        this.actionAssetAdder = assetAdder;
    }

    public isCustomMessage(): boolean {
        return this.customerContact.sendMethod === ESendActiveMethod.customMessage;
    }

    public isCmpaignOnly(): boolean {
        return this.customerContact.sendMethod === ESendActiveMethod.campaignOnly;
    }

    public isChannelPrompt(variable: IVariable): boolean {
        const isChannelPrompt = this.attendanceActiveEditHelperSvc.isChannelPrompt(variable.idProperty, this.getCampaignVariablesInfo());
        return isChannelPrompt;
    }

    public async saveAsset(newAsset: IBasicAsset, assets: TBasicAssetArray, isEdit?: boolean): Promise<boolean> {
        const index = assets.findIndex(ast => ast.idAsset === newAsset.idAsset);
        if (index > -1) {
            assets[index] = newAsset;
        } else {
            assets.push(newAsset);
        }
        return true;
    }

    public async removeAsset(index: number, assets: TBasicAssetArray): Promise<boolean> {
        assets.splice(index, 1);
        return true;
    }

    async onCustomerResultClicked([customerSelected]: ISearchActiveCallResult[]) {
        this.customerClicked = customerSelected;
        this.refreshWindowTitle();
        this.serviceHistory = [];
        this.isLoadingHistory = true;
        // console.log({customerSelected})
        const customerWithTargetFound = await this.activeCallAPI.searchForTarget(
            customerSelected.target,
            customerSelected.channel
        );
        // console.log({customerWithTargetFound})

        this.isLoadingHistory = false;
        this.setCustomerWithTargetFound(customerWithTargetFound);
    }

    onPartialSelected(contactList: TArrayID) {
        this.customerClicked = {
            ...this.getCustomerCallContactConfig(),
            name: '',
            idAvatar: '',
            email: '',
            idSocialKey: '',
            channel: EDelivery360Action.Delivery360WhatsApp
        };
        this.refreshWindowTitle();
        this.serviceHistory = [];
        this.contactIds = contactList;
        this.customerWithTargetFound = {
            contactName: 'Vários',
            contact: [],
            email: '',
            idAvatar: '',
            friendlyError: undefined,
            responseType: undefined,
            serviceHistory: []
        };
        this.customerIdAvatar = '';
    }

    setCustomerWithTargetFound(target: IGetActiveCustomerStatusResponse) {
        this.customerWithTargetFound = target;
        this.customerIdAvatar = target.idAvatar;
        const serviceHistory = this.customerWithTargetFound?.serviceHistory || [];

        this.serviceHistory = serviceHistory.filter(entry => {
            return entry.typeOfCustomerContact === ETypeOfCustomerContact.outbound
        });
    }

    async getAvailableCampaignList(taggable?: ITagableSearch) {
        this.isLoadingActions = true;

        this.availableActionList = isValidFunction(this.parameters.customActionsLoader)
            ? await this.parameters.customActionsLoader()
            : await this.activeCallAPI.searchForAvailableCampaignList(taggable, this.isPartialSend);

        if (isValidArray(this.parameters.channelsType)) {
            this.availableActionList = this.availableActionList.filter(
                act => act.commStrategy.some(str => this.parameters.channelsType?.includes(str.channelType))
            )
            console.log({availableActionList: this.availableActionList})
        }

        if (this.sendToAllWithCampaignActionId) {
            const actPredicate = (a: ICMassCommunicationAction) => a.idCampaigAction === this.sendToAllWithCampaignActionId;
            const preSelectedAction = this.availableActionList.find(actPredicate);
            this.onCampaignClicked(preSelectedAction);

            this.availableActionList = this.availableActionList.filter(actPredicate)
        } else {
            this.selectActionIfEditing();
        }

        this.refreshActionList();
        this.isLoadingActions = false;
    }

    private selectActionIfEditing() {
        if (isValidRef(this.customerClicked?.idNS)) {
            const action = this.filteredActionList.find(a => a.idCampaigAction === this.customerClicked.idCampaigAction);
            if (action) this.onCampaignClicked(action);
        }
    }

    public getTargetAndActionCampaingId(campaign: ICMassCommunicationAction): string {
        return this.customerClicked.target + campaign?.idCampaigAction;
    }

    public getChannelOpenTime(campaign: ICMassCommunicationAction): number {
        return this.actionsChannelsOpenTime[this.getTargetAndActionCampaingId(campaign)];
    }

    public async onCampaignClicked(campaign: ICMassCommunicationAction): Promise<void> {

        const alreadySelected = isValidRef(this.selectedAction) && (this.selectedAction.idCampaigAction === campaign.idCampaigAction);
        this.selectedAction = campaign;

        if (!alreadySelected) {
            await this.updateCampaignAndOrTemplateVariables()
        }

        const targetAndActionCampaingId = this.getTargetAndActionCampaingId(campaign);

        if (!(targetAndActionCampaingId in this.actionsChannelsOpenTime)) {
            const openChannelTimestamp = await this.activeCallAPI.getChannelOpenTime(
                this.customerClicked.target,
                campaign,
                EDelivery360Action.Delivery360WhatsApp
            );

            if (isValidNumber(openChannelTimestamp) && openChannelTimestamp > getClock()) {
                this.actionsChannelsOpenTime[targetAndActionCampaingId] = openChannelTimestamp;
            };
        }

        this.initActiveMethodHandlers();
        this.initRTFPickerIfAllowed();
    }

    private initRTFPickerIfAllowed() {
        this.rtfBodyPickerHandler = undefined;

        if (!this.selectedAction.allowActiveCallRTFSelect) return;

        this.rtfBodyPickerHandler = new NSPickerHandler({
            nsType: ENonSerializableObjectType.smartFlowRTFParamBody,
            // selectedId: undefined,
            listMode: ENonSerializableListMode.all,
            title: "Selecione o RTF",
            demandedTag: undefined,
            genericNonSerializableService: this.dashboardSvc.getGenericNonSerializableService(),
            maxSelections: 1,
            clientCallback: {
                onSaveCallback: ([ns]) => {
                    this.idRTFBody = ns?.idNS;
                }
            }
        });
    }



    public async initTemplateErrors(
        connection: IWhatsAppSocialMediaConnectionServer | undefined,
        whatsTemplateNS: INSWhatsAppTemplateServer | undefined,
    ) {
        this.errors = [];

        if (!connection) return;
        if (!whatsTemplateNS) return;

        const providerInfo = await this.templateSvc.getUpdatedProvider(
            connection!,
            whatsTemplateNS!,
        );

        // const providerInfo = await TemplateSharedService.getUpdatedProvider(
        //     connection!,
        //     whatsTemplateNS!,
        // );

        const processor = TemplateProcessor.factory(whatsTemplateNS!);

        if (!providerInfo) {
            this.templateError = 'Template não enviado';
            return;
        }

        const error = processor.getProviderError(providerInfo);
        this.templateError = error;

        if (!this.templateError) return;

        this.errors.push(this.templateError);
    }


    errors: string[] = []
    public templateError?: string;

    public async initActiveMethodHandlers(): Promise<void> {
        this.initActiveCampaignMessage();

        if (!this.selectedAction) return;

        const schedule: ICAWhatsAppSchedule = getChannelCommFromAction<ICAWhatsAppSchedule>(
            this.selectedAction,
            EDelivery360Action.Delivery360WhatsApp
        );

        let connection: IWhatsAppSocialMediaConnectionServer | undefined;
        let whatsTemplateNS: INSWhatsAppTemplateServer | undefined;

        [
            connection,
            whatsTemplateNS,
        ] = await Promise.all([
            NSSharedService.getNS<IWhatsAppSocialMediaConnectionServer>(schedule.idSocialMediaConnection),
            this.activeCallAPI.getWhatsappChannelTemplateNS(this.selectedAction),
        ]);
        //
        //

        this.initTemplateErrors(connection, whatsTemplateNS);
        this.templateSelectedFromCampaign = whatsTemplateNS?.parameter;

        const whatsTemplate: IWhatsAppTemplate | undefined = whatsTemplateNS?.parameter
        this.templatePreviewHandler = {
            bodyContent: whatsTemplate?.template,
            footerContent: whatsTemplate?.footer?.content?.content,
            headerContent: whatsTemplate?.header?.content,
            headerFormat: whatsTemplate?.header?.format,
            quickReply: whatsTemplate?.advancedOption
        }


        switch (this.customerContact.sendMethod) {
            case ESendActiveMethod.campaignOnly: {
                this.activeCampaignMessage.campaingMessageVariables = undefined;
            };
                break;
            case ESendActiveMethod.customMessage: {
                this.activeCampaignMessage.customMessage = [];
            };
                break;
        }

        if (isValidRef(this.customerClicked?.idNS)) {
            this.activeCampaignMessage = this.customerClicked.activeMessage;
        }

        this.initActionAssetAdder();
    }

    public get showAssetAdder(): boolean {
        return isValidRef(this.actionAssetAdder) && [ESendActiveMethod.campaignOnly, ESendActiveMethod.customMessage].includes(this.customerContact.sendMethod);
    }

    public getCampaignVariablesInfo(): ICampaignVariablesInfo {
        return {
            allPrompts: this.allPrompts,
            campaingMessageVariables: this.campaingMessageVariables,
            templateLocalCanonicals: this.templateLocalCanonicals,
            responseToMe: this.responseToMe,
            form: this.form
        }
    }

    onBackButtonClickedResetFields(event: Event) {

        if (this.parameters.onBackButtonClick) {
            this.parameters.onBackButtonClick(event);
        } else {
            this.customerClicked = null
            this.selectedAction = null
            this.refreshWindowTitle();
            this.closeNameSearch();
        }

    }

    getTypeOfCustomerContactIcon(typeOfCustomerContact: ETypeOfCustomerContact): string {
        switch (typeOfCustomerContact) {
            case ETypeOfCustomerContact.inbound:
                return 'arrow_downward';
            case ETypeOfCustomerContact.outbound:
                return 'arrow_upward';
            default:
                return 'close';
        }
    }

    getDelivery360Icon(chanel: EDelivery360Action): string {
        switch (chanel) {
            case EDelivery360Action.Delivery360Email:
                return './assets/icons/delivery-360/chat_icon_mail.svg';
            case EDelivery360Action.Delivery360FBMessenger:
                return './assets/icons/delivery-360/chat_icon_messenger.svg';
            case EDelivery360Action.Delivery360SMS:
                return './assets/icons/delivery-360/chat_icon_sms.svg';
            case EDelivery360Action.Delivery360RCS:
                return './assets/icons/delivery-360/chat_icon_sms.svg';
            case EDelivery360Action.Delivery360Voice:
                return './assets/icons/delivery-360/chat_icon_voice.svg';
            case EDelivery360Action.Delivery360WhatsApp:
                return './assets/icons/delivery-360/chat_icon_whats.svg';
            case EDelivery360Action.DeliveryTelegram:
                return './assets/icons/delivery-360/chat_icon_telegram.svg';
            case EDelivery360Action.DeliveryInstagram:
                return './assets/icons/delivery-360/chat_icon_instagram.svg';
        }

        checkEarlyReturnSwitch<EDelivery360Action.DeliveryColmeia>()(chanel)
    }

    getMissingTimeToCloseChannelFormated(time: number): string {
        return getCoundown(getClock(), time)
    }

    private getCustomerCallContactConfig(): INewAgentCallContactServer {
        const scheduleBeginning: number = this.isScheduledSend() && isValidRef(this.scheduleBeginning) ? this.scheduleBeginning : 0;
        const { campaingMessageVariables, templateLocalCanonicals } = this.attendanceActiveEditHelperSvc.getPredefinedCampaignMessage(
            this.getCampaignVariablesInfo(),
        );

        return {
            scheduleBeginning,
            sentAt: undefined,
            idNS: this.customerClicked?.idNS,
            nName: this.customerClicked?.name,
            target: this.customerClicked?.target,
            channel: this.customerClicked?.channel,
            idAvatarContact: this.customerClicked?.idAvatar || this.customerIdAvatar,
            idCampaigAction: this.selectedAction?.idCampaigAction,
            tags: this.customerContact?.tags,
            sendMethod: this.customerContact?.sendMethod,
            responseToMe: this.responseToMe,
            activeMessage: {
                campaingMessageVariables,
                templateLocalCanonicals,
                customMessage: this.activeCampaignMessage?.customMessage,
            },
            idRTFBody: this.idRTFBody,
            nsType: ENonSerializableObjectType.activeCall1x1,
            ident: undefined
        };
    }

    public fullSizeActionDropDown() {
        return this.action === EActiveActionMethod.SendNow;
    }

    public isScheduledSend() {
        return this.action === EActiveActionMethod.Schedule;
    }

    public isAddToList() {
        return this.action === EActiveActionMethod.AddToList;
    }

    public showRedirectToMeCheck() {
        return this.action === EActiveActionMethod.Schedule || this.action === EActiveActionMethod.SendNow;
    }

    public selectionChange(event: MatSelectChange) {
        const value = event.value as EActiveActionMethod;

        if (value === EActiveActionMethod.Schedule && !isValidRef(this.scheduleBeginning)) {
            this.scheduleBeginningHandler.open();
        }
    }

    public verifyIfIsValidAndShowError(): boolean {
        if (
            (this.customerContact.sendMethod === ESendActiveMethod.customMessage)
            && !this.activeCampaignMessage.customMessage.length
        ) {
            this.snackSvc.openError('É preciso cadastrar pelo menos uma mensagem.');
            return false;
        }
        return true;
    }

    public doAction = createOnePerTimeFunction(async (): Promise<void> => {

        if (!this.verifyIfIsValidAndShowError()) return;

        const contactConfig = this.getCustomerCallContactConfig();
        if (!this.parameters.ticketData && (this.isPartialSend || this.sendToAllWithCampaignActionId)) {
            const result = await this.activeCallAPI.runCampaignForPartial(this.contactIds, contactConfig, this.parameters.idParentContactList);

            if (result) {
                this.parameters.clientCallback.onUpdateActiveCallInfo();
                this.snackSvc.openInfo(
                    this.sendToAllWithCampaignActionId
                    ? "O disparo para todos os contatos foi iniciado"
                    : "O disparo para os contatos selecionados foi iniciado"
                );
            }
            return;
        }
        let saveContactResponse: ISaveContactActiveCallResponse

        if (this.action === EActiveActionMethod.AddToList) {
            if (!isValidString(this.idParentContact)) {
                this.snackSvc.openWarning("É necessário ter uma lista selecionada.");
                return;
            }

            saveContactResponse = await this.activeCallAPI.saveChildContact(
                this.idParentContact,
                this.customerClicked.idAvatar || contactConfig.idAvatarContact,
                contactConfig,
            );
        }

        this.handleIfErrorResponse(saveContactResponse);

        if (this.action !== EActiveActionMethod.AddToList) {
            const activeCall: INewAgentCallContactServer = saveContactResponse?.nser || contactConfig;
            const req: IRunActionCampaignForActiveCall = { activeCall };
            const mktRule = this.getAttachedMktRuleByActionID(activeCall.idCampaigAction);

            activeCall.idMktRule = mktRule?.idNS

            let res: IRunActionCampaignForActiveCallResponseBase;

            if(isValidFunction(this.parameters.requestInterceptor)) {
                res = await this.parameters.requestInterceptor(req);
            } else {
                res = await this.activeCallAPI.saveCampaign(req);
            }

            this.handleIfErrorResponse(res);
        }

        this.parameters.clientCallback.onUpdateActiveCallInfo();
    })


    get ticketData(): ICRMTicketDataWithCustomerName {
        return this.parameters.ticketData;
    }

    isAddingContactToList: boolean = false;
    customerClickedAboutToBeAdded: ISearchActiveCallResult & Partial<INewAgentCallContactServer> | undefined;
    customerWithTargetFoundToBeAdded: IGetActiveCustomerStatusResponse | undefined;
    customerIdAvatarToBeAdded: string | undefined;
    listAction: ICMassCommunicationAction | null = null;
    async addContactToListCallback(customer: ISearchActiveCallResult) {
        this.loading = true;
        this.isAddingContactToList = true;

        const listIdCampaignAction = this.parameters.parentContactListIdCampaignAction;

        this.customerClickedAboutToBeAdded = customer;
        // this.refreshWindowTitle();
        // this.serviceHistory = [];
        // this.isLoadingHistory = true;

        const customerWithTargetFound = await this.activeCallAPI.searchForTarget(
            customer.target,
            EDelivery360Action.Delivery360WhatsApp);

        // this.setCustomerWithTargetFound(customerWithTargetFound);
        this.customerWithTargetFoundToBeAdded = customerWithTargetFound;
        this.customerIdAvatarToBeAdded = customerWithTargetFound.idAvatar;
        // const serviceHistory = this.customerWithTargetFound?.serviceHistory || [];

        // this.serviceHistory = serviceHistory.filter(entry => {
        //     return entry.typeOfCustomerContact === ETypeOfCustomerContact.outbound
        // });


        if (isValidString(listIdCampaignAction)) {
            await this.addContactToListUsingListIdCampaignAction(customer, listIdCampaignAction);
        } else {
            await this.addPartialActiveCall(customer);
        }

        this.parameters.clientCallback.refreshList?.();
        // ---
        this.resetCustomer();
        this.isAddingContactToList = false;
        this.loading = false;
    }

    resetCustomer() {
        this.customerIdAvatarToBeAdded = undefined;
        this.customerWithTargetFoundToBeAdded = undefined;
        this.customerClickedAboutToBeAdded = undefined;
        this.listAction = null;
    }

    async addContactToListUsingListIdCampaignAction(customer: ISearchActiveCallResult, listIdCampaignAction: string) {


        let action: ICMassCommunicationAction | undefined = this.filteredActionList.find(campaignAction => campaignAction.idCampaigAction === this.parameters.parentContactListIdCampaignAction);
        if (!action) {
            const response = await this.dashboardCampaignsSvc.getCampaignByIdAction(listIdCampaignAction);

            action = <ICMassCommunicationAction | undefined>response?.campaingActions?.find(campaignAction => campaignAction.idCampaigAction === listIdCampaignAction)
        }

        if (!isValidRef(action)) return;
        //--
        // await this.onCampaignClicked(action);

        const alreadySelected = isValidRef(this.selectedAction) && (this.listAction?.idCampaigAction === action.idCampaigAction);
        this.listAction = action;

        if (!alreadySelected) {
            await this.updateCampaignAndOrTemplateVariablesOnAddingToList()
        }

        const targetAndActionCampaingId = this.customerClickedAboutToBeAdded.target + action.idCampaigAction;

        if (!(targetAndActionCampaingId in this.actionsChannelsOpenTime)) {
            const openChannelTimestamp = await this.activeCallAPI.getChannelOpenTime(
                this.customerClickedAboutToBeAdded.target,
                action,
                EDelivery360Action.Delivery360WhatsApp
            );

            if (isValidNumber(openChannelTimestamp) && openChannelTimestamp > getClock()) {
                this.actionsChannelsOpenTime[targetAndActionCampaingId] = openChannelTimestamp;
            };
        }

        // this.initActiveMethodHandlers();
        // this.initRTFPickerIfAllowed();
        // --
        this.action = EActiveActionMethod.AddToList;

        // ---
        // await this.doAction();

        if (!this.verifyIfIsValidAndShowError()) return;

        const { campaingMessageVariables, templateLocalCanonicals } = this.attendanceActiveEditHelperSvc.getPredefinedCampaignMessage(
            this.getCampaignVariablesInfo(),
        );

        const contactConfig: INewAgentCallContactServer = {
            scheduleBeginning: 0,
            sentAt: undefined,
            idNS: this.customerClickedAboutToBeAdded?.idNS,
            nName: this.customerClickedAboutToBeAdded?.name,
            target: this.customerClickedAboutToBeAdded?.target,
            channel: this.customerClickedAboutToBeAdded?.channel,
            idAvatarContact: this.customerClickedAboutToBeAdded?.idAvatar || this.customerIdAvatarToBeAdded,
            idCampaigAction: this.listAction.idCampaigAction,
            tags: this.customerContact?.tags,
            sendMethod: this.customerContact?.sendMethod,
            responseToMe: this.responseToMe,
            activeMessage: {
                campaingMessageVariables,
                templateLocalCanonicals,
                customMessage: this.activeCampaignMessage?.customMessage,
            },
            idRTFBody: this.idRTFBody,
            nsType: ENonSerializableObjectType.activeCall1x1,
            ident: undefined
        };

        const saveContactResponse: ISaveContactActiveCallResponse = await this.activeCallAPI.saveChildContact(
            this.idParentContact,
            this.customerClickedAboutToBeAdded.idAvatar || contactConfig.idAvatarContact || '',
            contactConfig,
        );

        this.handleIfErrorResponse(saveContactResponse);
    }

    private handleIfErrorResponse(res) {
        if (isValidRef(res) && !res.friendlyError.okState) {
            this.snackSvc.openError("Desculpa, ocorreu algum problema.");
        }
    }

    public hasVariables(): boolean {
        return values(this.allPrompts).some(v => !this.isChannelPrompt(v));
    }

    public hasErrorInTemplateStructureCreation(): boolean {
        return this.lastCreateTemplateResult === false;
    }

    public hasSomeVariableEmpty(): boolean {
        return values(this.allPrompts).filter(v => !this.isChannelPrompt(v)).some(v => !isValidString(v.value));
    }

    public shouldDisableSave(): boolean {
        const isCampaign: boolean = this.customerContact.sendMethod === ESendActiveMethod.campaignOnly;
        const isTemplate: boolean = this.customerContact.sendMethod === ESendActiveMethod.templateOnly;
        return (isCampaign || isTemplate) && this.hasSomeVariableEmpty() && !this.isPartialSend;
    }

    public handleSearchByRadioChange($event: ECustomerSearchContactType) {
        this.cfSearchByRadio = $event;
    }

    public isSearchByPhone(): boolean {
        return this.cfSearchByRadio === ECustomerSearchContactType.phone;
    }

    public handleTargetChange(target_: string | ISearchActiveCallResult) {
        if (!isValidRef(target_)) {
            this.target = '';
            return;
        };

        if (isValidString(target_, 0)) {
            this.target = target_;
        } else {
            const { email, name } = target_;
            const contact: ICustomerCreationFullfiledFields = {
                email: email || '',
                colmeiaId: '',
                fbId: '',
                name: name || '',
                phoneNumber: this.checkIfIsPhoneNumber(target_),
                idAvatar: target_.idAvatar
            }

            this.openCreateContactDialog(contact, true);
        }
    }

    checkIfIsPhoneNumber(customer: ISearchActiveCallResult): string {
        const { channel, target } = customer;
        switch (channel) {
            case EDelivery360Action.Delivery360Email:
            case EDelivery360Action.Delivery360FBMessenger:
            case EDelivery360Action.DeliveryInstagram:
            case EDelivery360Action.DeliveryColmeia:
                return '';

            case EDelivery360Action.Delivery360SMS:
            case EDelivery360Action.Delivery360Voice:
            case EDelivery360Action.Delivery360WhatsApp:
            case EDelivery360Action.DeliveryTelegram:
                return target;

            default:
                return '';
        }
    }

    public isPhoneTargetOk() {
        return this.target?.length > 5;
    }

    public get windowTitle(): string {
        let title = 'Nova chamada ativa';

        if (this.customerClicked && !this.isPartialSend) {
            title += ': ' + (this.customerClicked.name || this.customerClicked.target);
        }

        return title;
    }

    private refreshWindowTitle(): void {
        this.windowRef.title = this.windowTitle;
    }

    private initCampaignActionListHandlers(): void {
        this.campaignActionTagPickerParameter = {
            tagType: undefined,
            tagsData: {
                nsType: ENonSerializableObjectType.campaing,
                demandedTag: EDefaultTag.marketing
            },
            mode: ETagPickerHandlerMode.query,
            selectedTags: [],
            clientCallback: this,
            compactAllowPreview: false
        };

        this.nameSearchHandler = {
            inputType: "text",
            initialValue: this.nameSearch,
            onChangeValueCallback: async (value) => {
                this.nameSearch = value.toUpperCase();
                this.refreshActionList();
            },
            placeholderText: "Buscar por título",
            disableClearButton: true
        }
    }

    async onFinishSelectionCallback(selectedTags: IServerColmeiaTag[]) {
        const taggableSearch = this.getTaggableSearch(selectedTags);

        this.getAvailableCampaignList(taggableSearch);
    }

    private getTaggableSearch(selectedTags: IServerColmeiaTag[]): ITagableSearch {
        return {
            searchedTags: selectedTags.map(tag => tag.idNS),
            demandedTag: this.dashboardSvc.defaultTag
        };
    }

    private async refreshActionList(): Promise<void> {
        let actions = [...this.availableActionList];

        if (isValidString(this.nameSearch)) {
            actions = actions.filter(action => action.actionName.toUpperCase().indexOf(this.nameSearch) > -1);
        }

        if (this.isAlphabeticalOrder !== null) {
            if (this.isAlphabeticalOrder === true) {
                actions = actions.sort((a, b) => a.actionName.localeCompare(b.actionName));
            } else {
                actions = actions.sort((b, a) => a.actionName.localeCompare(b.actionName));
            }
        }

        this.filteredActionList = actions;
    }

    private searchComponent: CmInputProgessComponent;
    @ViewChild('searchNameInput') set setSearchComponent(searchComponent: CmInputProgessComponent | Nullish) {
        this.searchComponent = searchComponent;

        if (searchComponent) {
            this.searchComponent.inputElement.nativeElement.focus();
        }
    }

    public openNameSearch(): void {
        this.isSearchingName = true;
    }

    public closeNameSearch(): void {
        this.isSearchingName = false;
        this.nameSearch = "";
        this.refreshActionList();
    }

    public toggleActionsSorting(): void {
        if (this.isAlphabeticalOrder === null) {
            this.isAlphabeticalOrder = true;
        } else {
            if (this.isAlphabeticalOrder === true) {
                this.isAlphabeticalOrder = false;
            } else if (this.isAlphabeticalOrder === false) {
                this.isAlphabeticalOrder = true;
            }
        }

        this.refreshActionList();
    }

    public getAttachedMktRuleByActionID(idCampaigAction: string): IBPMMarketingRootServer {
        const action = this.filteredActionList.find( act => act.idCampaigAction === idCampaigAction);

        return this.getAttachedMktRule(action)
    }

    public getAttachedMktRule(action: ICMassCommunicationAction): IBPMMarketingRootServer {
        const bpmRuleRoot = this.parameters.mktRuleMap?.[this.parameters.mktRuleAttachedCampaignActions?.[action?.idCampaigAction]];

        return bpmRuleRoot;
    }

    get hideDetailsBackButton() {
        return this.parameters.hideDetailsBackButton === true
    }
}
