import { Injectable } from "@angular/core";
import { IBPMDialogResult, IOpenDialogParams } from "../diagram-editor/diagram-editor.types";
import { BehaviorSubject, Subject } from "rxjs";
import { MainHandler } from "app/handlers/main-handler";
import { filter, take } from "rxjs/operators";
import { BpmGraphElementInfoHandler, TWorkingElements } from "../bpm-graph-element-info/bpm-graph-element-info/bpm-graph-element-info.handler";

export interface IBPMSidebarState {
    isOpen: boolean;
    handler?: MainHandler; // has value when isOpen is true
    closeResult?: IBPMDialogResult; // has value when isOpen is false
}

const initialState: IBPMSidebarState = {
    isOpen: false
};

@Injectable({
    providedIn: 'root',
})
export class BpmGraphNodeDetailsService {
    private state$ = new BehaviorSubject<IBPMSidebarState>(initialState);

    openSidebar(handler: MainHandler): Promise<IBPMDialogResult> {
        const currentWorkElement: TWorkingElements = (<BpmGraphElementInfoHandler>this.state$.value.handler)?.getComponentParameter()?.workElement;

        const newWorkElement: TWorkingElements = (<BpmGraphElementInfoHandler>handler)?.getComponentParameter()?.workElement;

        // if (this.state$.value.isOpen && currentWorkElement !== newWorkElement) {
        //     this.closeSidebar({ userHasClickedSave: false, nonSerializable: undefined })
        //     return 
        // }

        // if (!this.state$.value.isOpen) {
            this.state$.next({
                isOpen: true,
                handler
            });
        // }

        // returns IBPMDialogResult after the sidebar is closed
        const result = new Promise<IBPMDialogResult>((resolve) => {
            this.state$
                .pipe(
                    filter(event => event.isOpen === false),
                    take(1),
                )
                .subscribe(result => {
                    console.log('openSidebar', result);
                    resolve(result.closeResult);
                });
        });
     
        return result;
    }

    closeSidebar(result: IBPMDialogResult): void {
        if (this.state$.value) {
            this.state$.next({
                isOpen: false,
                closeResult: result
            });
        }
    }
    
    getState(): BehaviorSubject<IBPMSidebarState> {
        return this.state$;
    }
    
    isOpen(): boolean {
        return this.state$.value.isOpen;
    }
}
