import { MainHandler } from "../../../handlers/main-handler";
import { IComponentParameter } from "../../../model/component-comm/basic";
import { TEnumPickerFieldMaps, IEnumPickerOption } from "@colmeia/core/src/shared-business-rules/enum-db";
import { ITranslationConfig, ITranslationHash } from "@colmeia/core/src/shared-business-rules/translation/translation-engine";
import { MatFormFieldAppearance } from "@angular/material/form-field";
import { $ValueOf, TDeepMap, ValueOf } from "@colmeia/core/src/tools/utility-types";
import * as _ from "lodash";
import { EnumValue, objectShallowReplace, typedClone, values } from "@colmeia/core/src/tools/utility";

type $EnumValue = EnumValue

export interface IEnumPickerClientCallback<EnumValue> {
    onSingleEnumSelection?(val: EnumValue): void;
    onMultipleEnumSelection?(values: EnumValue[]): void;
    mapOption?(option: IEnumPickerOption): IEnumPickerOption;
}

export enum EEnumPickerMode {
    Single = 's',
    Multiple = 'm'
}

export interface IDescriptionObject {
    separator: string;
    matchingEnum: string;
    description: string;
}

export interface IEnumPickerHandlerParameter<EnumValue extends ValueOf<EnumObject>, EnumObject = { [key in string]: EnumValue }> extends IComponentParameter {
    client: IEnumPickerClientCallback<EnumValue>;
    mode: EEnumPickerMode;
    translations: ITranslationHash | { [key in Extract<EnumValue, string>]: string };
    inputTitle: string;
    enum: EnumObject;
    /**
     * @property selectAllMultiple
     * Used to show an option to select all propeties of a multiselect enum
     * The label is the string passed in this property
     * @example 'Todos'
     */
    selectAllMultiple?: string;
    clientCallback: {};
    current?: EnumValue;
    currents?: EnumValue[];
    disabled?: boolean;
    ignoreValues?: string[];
    appearance?: MatFormFieldAppearance;
    buttonMode?: boolean;
    allowClear?: boolean;
    useActionBtns?: boolean;

    descriptions?: IDescriptionObject[];
}

export class EnumPickerHandler<EnumValue extends ValueOf<EnumObject>, EnumObject = { [key in string]: EnumValue }> extends MainHandler<IEnumPickerHandlerParameter<EnumValue, EnumObject>> {

    constructor(private parameters: IEnumPickerHandlerParameter<EnumValue, EnumObject>) {
        super(parameters);

    }

    public static createFakeEnum<T, Value extends Extract<$ValueOf<T>, $EnumValue>, Key extends Extract<$ValueOf<T>, $EnumValue>>(
        items: T[],
        mapKeys: (item: T) => Key,
        mapValues: (item: T, key: Key, source: { [key in Key]: T }) => Value,
        mapTranslations?: (item: T) => string,
    ) {
        type $Enum = { [key in Key]: Value };
        type $Translations = { [key in Value]: string };
        type $EnumBeforeMapValues = { [key in Key]: T };
        const map: TDeepMap<[value: Value, item: T]> = new Map()
        const $Enum = _.chain(items)
            .mapKeys(mapKeys)
            .mapValues((item: T, key: Key, source: $EnumBeforeMapValues) => {
                const value = mapValues(item, key, source);
                map.set(value, item);
                return value;
            })
            .value() as {} as $Enum
            ;

        const translations = _.chain(values($Enum))
            .mapKeys(item => item)
            .mapValues((item: Value) => mapTranslations?.(map.get(item)!) ?? item)
            .values() as {} as $Translations
            ;

        return {
            $Enum,
            map,
            translations,
        }
    }

}
