import { Component, Input } from '@angular/core';
import { INewAgentCallBatchServer, INewAgentCallBatch } from '@colmeia/core/src/shared-business-rules/active-1x1-call/active-1x1-model';
import { GenericDashboardEditHandler, IGenericDashboardEditPageClientCallback } from 'app/handlers/generic-dashboard-edit.handler';
import { ENonSerializableObjectType } from '@colmeia/core/src/shared-business-rules/non-serializable-id/non-serializable-id-interfaces';
import { AttendanceActiveCallNewContactListHandler } from './attendance-active-call-new-contact-list.handler';
import { AttendanceActiveEditCallApiService } from 'app/services/attendance-active-edit-call.service';
import { UntypedFormControl, Validators } from '@angular/forms';
import { ColmeiaWindowRef } from '../../dashboard-foundation/colmeia-window/colmeia-window-ref';
import { ICampaignActionPicker } from '../../campaign-action-picker/campaign-action-picker.component';
import { ICampaignServer, ICampaingActionHeader } from '@colmeia/core/src/shared-business-rules/campaigns/campaign-type-model';
import { isInvalidString } from '@colmeia/core/src/tools/barrel-tools';
import { SnackMessageService } from 'app/services/snack-bar';
import { DashBoardService } from 'app/services/dashboard/dashboard.service';
import { EDefaultTag } from '@colmeia/core/src/shared-business-rules/colmeia-tags/tags';

@Component({
    selector: 'app-attendance-active-call-new-contact-list',
    templateUrl: './attendance-active-call-new-contact-list.component.html',
    styleUrls: ['./attendance-active-call-new-contact-list.component.css']
})
export class AttendanceActiveCallNewContactListComponent implements IGenericDashboardEditPageClientCallback {

    _handler: AttendanceActiveCallNewContactListHandler;
    @Input()
    public get handler(): AttendanceActiveCallNewContactListHandler { return this._handler; }
    public set handler(value: AttendanceActiveCallNewContactListHandler) {
        this._handler = value;
        this.init();
    }

    public genericEdit: GenericDashboardEditHandler;
    public entity: INewAgentCallBatch;
    public actionPickerHandler: ICampaignActionPicker;

    constructor(
        private ref: ColmeiaWindowRef<AttendanceActiveCallNewContactListHandler>,
        private attendanceActiveCallEditSvc: AttendanceActiveEditCallApiService,
        private snackSvc: SnackMessageService,
        private dashboardSvc: DashBoardService
    ) {
        this.handler = ref.data;
        this.initCampaignActionPicker();
    }

    public initGenericEdit(): void {
        this.genericEdit = new GenericDashboardEditHandler({
            nsType: ENonSerializableObjectType.activeCallBatch,
            allowEditTitleInHeader: true,
            clientCallback: this,
            moreOptions: {},
            nser: this.entity as INewAgentCallBatchServer,
        });
    }

    public init(): void {
        this.initEntity();
        this.initGenericEdit();
    }

    private initEntity() {
        this.entity = {
            nsType: ENonSerializableObjectType.activeCallBatch,
            nName: '',
            tags: this.dashboardSvc.factoryTags(EDefaultTag.serviceAttendent)
        };
    }

    public async onGenericSaveButtonPressed(): Promise<void> {
        if (isInvalidString(this.entity.nName)) {
            this.snackSvc.openError('Não é possível salvar a Lista sem nome.');
            return;
        }

        this.handler.getComponentParameter().batchNS = await this.attendanceActiveCallEditSvc.saveBatch(this.entity);
        this.handler.getComponentParameter().hasSaved = true;
        this.ref.close(this.handler.getComponentParameter().batchNS);
    }

    public async onGenericBackButtonPressed() {
        this.ref.close();
    }

    private initCampaignActionPicker() {
        this.actionPickerHandler = {
            valueChange: (_, [act]) => {
                this.entity.idCampaignAction = act.idCampaigAction;
            },
            filters: {
                actionFilter: (action: ICampaingActionHeader) => !!action.allowActionToActiveCall,
                campaignFilter: (campaign: ICampaignServer) => {
                    return campaign.campaingActions.some(action => !!action.allowActionToActiveCall)
                }
            }
        }
    }

}
