import { IBotActionAsset, IBotActionAssetContainer, TIBotActionAssetContainerArray } from "../../../bot/bot-action-model";
import { TKAssetTypeArray } from "../../../bot/bot-asset-model";
import { ENextGenBotElementType } from "../../../bot/bot-model";
import { EBotActionType } from "../../../bot/new-bot-action";
import { GraphElement } from "../../../graph/essential/graph-element";
import { IGraphConnectionData, TIGraphConnectionDataArray } from "../../../graph/essential/graph-types";
import { IHostObjectInterface } from "../../../graph/essential/host-object";
import { ITransactionServer } from "../../../knowledge-base/bot-transaction/bot-transaction";
import { ENonSerializableObjectType, INonSerializable } from "../../../non-serializable-id/non-serializable-id-interfaces";
import { TBPMBOTElementTypes, TReferencedObject } from "../../toolbar/config-toolbar.types";
import { NSGenericHost } from "../ns/ns.host";
import { IAddedConnection, INodeDeletedConnection } from "../ns/ns.host.types";
import { ITransactionFactoryInfo, THandleHostUpdateActions } from "./bot-host.types";
import { BotHostNode } from "./bot.host";

export class BotTransactionHostNode extends BotHostNode {

    constructor(root: ITransactionServer) {
        super(root);
    }

    public isRoot(): boolean {
        return false
    }

    public onDragged(_host: IHostObjectInterface) {
        // im from
        // -> transaction
        // -> menu
    }

    public onDragIntoMe(): void {
    }

    public hasValidAssets(): boolean {
        return;
    }

    public handleHostUpdate(_graphElement: GraphElement, _beforeNSData?: INonSerializable): THandleHostUpdateActions {
        return {}
    }

    public isDraggableIntoMe(_nodeTypeToDrag: TReferencedObject, _edgesToOthersCount: number): boolean {
        return false;
    }

    public static factory(info: ITransactionFactoryInfo): BotTransactionHostNode {
        return new BotTransactionHostNode(info.ns as ITransactionServer);
    }

    public getHostedType(): TBPMBOTElementTypes {
        return ENonSerializableObjectType.contentGenerator; // talvez pegar o tipo do non serializable?
    }

    public getHostedSubType(): TReferencedObject {
        return super.getNonSerializable().botLevel;
    }

    public isEditable(_graphElement: GraphElement) {
        return true;
    }

    public handleRemoveSubTree(graphElement: GraphElement): INonSerializable[] {
        return []
    }

    public isActionPredicateDrawable(_targetType: TReferencedObject, _action: EBotActionType): boolean {
        return false;
    }

    public isParenthoodPredicateDrawable(type: TReferencedObject): boolean {
        return type === ENextGenBotElementType.menuContainer;
    }

    public getActionContainer(): TIBotActionAssetContainerArray {
        return [{
            action: undefined
        }]
    }

    public getPredicateTypesFromState(): TKAssetTypeArray {
        return []
    }

    public getActionAvailableByConnectingManuallyToOtherNode(): EBotActionType {
        return EBotActionType.contentGenerator
    }

    public isActionAvailableByConnectingManuallyToOtherNode(): boolean {
        return true
    }

    public setAction(_actionAsset: IBotActionAsset): void { }

    public mustUpdateConnections(connectionType: TReferencedObject): boolean {
        return super.mustUpdateConnections(connectionType);
    }

    public getConnectionTargetData(): TIGraphConnectionDataArray {
        return [{
            connectionType: undefined,
            targetHostId: undefined,
            subElementId: undefined
        }]
    }

    public addConnectionTo(_target: NSGenericHost, _connectionType: TReferencedObject): IAddedConnection {
        return {
            isConnectionAdded: false
        }
    }

    public deleteConnectionTo(anotherHost: NSGenericHost, connectionType: TReferencedObject): INodeDeletedConnection {

        return { deletedConnection: false }
    }
}
