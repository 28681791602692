<div 
  class="main__container" 
  [class.file-mode]="handler.isFileMode()" 
  [ngClass]="newIconClass">
  <div
    class="hexagon-uploader-container"
    (drop)="onDrop($event)"
    (dragover)="onDragOver($event)"
    (click)="upload()"
    [class.no-border]="shouldHideBorder()"
    [ngClass]="newIconClass"
    matTooltip="Upload de arquivo"
    >
    <hexagon 
      class="preview-mode" 
      [previewShouldShowBorder]="previewShouldShowBorder"
      [hexagonHandler]="handler.getHexagonHandler()"
    ></hexagon>
    <div class="content" [ngClass]="newIconClass" *ngIf="canUpload">
      <mat-icon *ngIf="!useNewIcon" class="upload-icon" [ngClass]="newIconClass">file_upload</mat-icon>
      <svg *ngIf="useNewIcon" width="42" height="44" viewBox="0 0 42 44" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="20.7037" cy="20.7037" r="20.2037" fill="white" stroke="#34927E"/>
        <g clip-path="url(#clip0_2854_3003)">
        <path d="M26.785 16.8007L28.783 12.8081C28.9567 12.4189 28.8264 11.9418 28.5007 11.741C28.3477 11.6488 28.1705 11.626 28.0037 11.6771C27.837 11.7282 27.6928 11.8495 27.5994 12.0172L25.558 16.0851C24.0224 15.3332 22.3718 14.9457 20.7042 14.9457C19.0366 14.9457 17.386 15.3332 15.8504 16.0851L13.809 12.0172C13.7103 11.842 13.5567 11.718 13.3805 11.6712C13.2043 11.6243 13.0193 11.6584 12.8643 11.7661C12.5603 11.9921 12.4625 12.444 12.6254 12.8081L14.6234 16.8007C12.9657 17.8957 11.5648 19.4428 10.5427 21.3073C9.52069 23.1718 8.90859 25.2971 8.75977 27.4978H32.6487C32.4998 25.2971 31.8877 23.1718 30.8657 21.3073C29.8437 19.4428 28.4427 17.8957 26.785 16.8007ZM15.2749 24.0451C14.9149 24.0451 14.5697 23.8797 14.3151 23.5854C14.0606 23.2911 13.9176 22.8919 13.9176 22.4757C13.9176 22.0594 14.0606 21.6603 14.3151 21.3659C14.5697 21.0716 14.9149 20.9063 15.2749 20.9063C15.6349 20.9063 15.9801 21.0716 16.2347 21.3659C16.4892 21.6603 16.6322 22.0594 16.6322 22.4757C16.6322 22.8919 16.4892 23.2911 16.2347 23.5854C15.9801 23.8797 15.6349 24.0451 15.2749 24.0451ZM26.1335 24.0451C25.7735 24.0451 25.4283 23.8797 25.1737 23.5854C24.9192 23.2911 24.7762 22.8919 24.7762 22.4757C24.7762 22.0594 24.9192 21.6603 25.1737 21.3659C25.4283 21.0716 25.7735 20.9063 26.1335 20.9063C26.4935 20.9063 26.8387 21.0716 27.0933 21.3659C27.3478 21.6603 27.4908 22.0594 27.4908 22.4757C27.4908 22.8919 27.3478 23.2911 27.0933 23.5854C26.8387 23.8797 26.4935 24.0451 26.1335 24.0451Z" fill="black"/>
        </g>
        <circle cx="34.1667" cy="36.1667" r="7.16667" fill="#00D6AE"/>
        <path d="M34.0423 37.3335V33.5981L32.8507 34.7897L32.209 34.1252L34.5007 31.8335L36.7923 34.1252L36.1507 34.7897L34.959 33.5981V37.3335H34.0423ZM31.7507 39.1668C31.4986 39.1668 31.2828 39.0771 31.1035 38.8978C30.9241 38.7184 30.8343 38.5026 30.834 38.2502V36.8752H31.7507V38.2502H37.2507V36.8752H38.1673V38.2502C38.1673 38.5022 38.0776 38.7181 37.8983 38.8978C37.7189 39.0775 37.503 39.1671 37.2507 39.1668H31.7507Z" fill="black"/>
        <defs>
        <clipPath id="clip0_2854_3003">
        <rect width="25.4815" height="29.463" fill="white" transform="translate(7.96289 5.57422)"/>
        </clipPath>
        </defs>
        </svg>
      <p *ngIf="handler.isFileMode()" class="upload-description">Selecione ou "solte" arquivos aqui para fazer upload.</p>
    </div>
  </div>
  <button *ngIf="canDelete" class="remove-button" [ngClass]="newIconClass" (click)="delete()">
    <mat-icon>remove_circle</mat-icon>
  </button>
</div>
<app-multimedia-instance-uploader #uploader [multimediaHandler]="multimediaHandler"></app-multimedia-instance-uploader>
