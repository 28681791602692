import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { AfterViewInit, Component, Input, OnInit, ViewChild } from "@angular/core";
import { MenuInteraction } from "@colmeia/core/src/interaction/menu-interaction/menu-interaction";
import { SessionService } from "app/services/session.service";
import { GroupChatServices } from "app/services/group-chat.services";
import { IBotMenuOption, TBotMenuOptions } from "@colmeia/core/src/shared-business-rules/bot/bot-interfaces";
import { MenuReply } from "@colmeia/core/src/interaction/menu-interaction/menu-reply";
import { constant } from "@colmeia/core/src/business/constant";
import { isValidRef, isValidString } from "@colmeia/core/src/tools/utility";
import { EMenuMode } from "@colmeia/core/src/shared-business-rules/bot/bot-model";
import SwiperCore, { Navigation, Pagination, Swiper, Virtual } from 'swiper';
import { BBCode } from "@colmeia/core/src/shared-business-rules/bbcode/bbcode-main";
import { MultimediaObject } from '@colmeia/core/src/multi-media/barrel-multimedia';
import { MultimediaService } from 'app/services/multimedia.service';
import { SwiperComponent } from 'swiper/angular';
import { EmbeddedChatService } from 'app/services/embedded-chat.service';
import { ReadableClocktickService } from 'app/services/readable-clocktick.service';

SwiperCore.use([Navigation, Pagination, Virtual]);

type TBotMenuItem = IBotMenuOption & { fileUrl?: string };
type TBotMenuItems = TBotMenuItem[];

@Component({
    selector: "app-menu-print",
    templateUrl: "./menu-print.component.html",
    styleUrls: ["./menu-print.component.scss"],
})
export class MenuPrintComponent implements OnInit, AfterViewInit {
    public EMenuMode: typeof EMenuMode = EMenuMode;
    public hasChoice: boolean = false;
    public choicedReply: string;
    public loading: boolean = false;
    public viewInitialized: boolean = false;
    public menuMode: EMenuMode;

    @Input() interaction: MenuInteraction;
    titleHTML: SafeHtml;

    public readableDate: string;
    public menuOptions: TBotMenuItems = [];
    public menuOptionsWithoutMedia: TBotMenuItems = [];
    public selectedOptionIndex: number = 0;

    @ViewChild('swiper', { static: false }) swiper?: SwiperComponent;

    constructor(
        private session: SessionService,
        private groupChatSvc: GroupChatServices,
        private sanitizer: DomSanitizer,
        private mmSvc: MultimediaService,
        private embeddedChatSvc: EmbeddedChatService,
        private clocktick: ReadableClocktickService,

    ) { }

    public ngOnInit() {
        this.menuMode = this.interaction.getMenuStyle();
        this.readableDate = this.getReadableDate();
        this.titleHTML = this.sanitizer.bypassSecurityTrustHtml(BBCode.parseHTML(this.title));

        const options = this.getOptions();
        if (options) {
            if (this.menuMode === EMenuMode.Full) {
                this.menuOptions = options.filter(mo => isValidRef(mo.media));
                this.menuOptionsWithoutMedia = options.filter(mo => !isValidRef(mo.media));

                this.menuOptions.forEach(async op => {
                    op.fileUrl = await this.mmSvc.getMultimediaSignedTempURL(op.media.idMedia);
                });

            } else {
                this.menuOptions = options;
            }
        }

    }

    public isDisabled() {
        return !this.isLastActiveMenu();
    }

    private isLastActiveMenu(): boolean {
        const lastMenu: MenuInteraction = this.embeddedChatSvc.getLastInteractionIfItIsEmbeddedMenu();

        return lastMenu === this.interaction
    }

    public ngAfterViewInit() {
        this.viewInitialized = true;
    }

    private getReply(): MenuReply {
        const interaction = this.interaction.getChild(this.session.getSelectedGroupID(), constant.interactionType.menu.menuReply);
        return interaction as MenuReply;
    }

    get hasReply(): boolean {
        const interaction = this.getReply();
        return isValidRef(interaction);
    }

    get title(): string {
        return this.interaction.getMessage();
    }

    private isCurrentReply(option: IBotMenuOption): boolean {
        return option.expectedReply === this.choicedReply || this.getReply()?.getSelectedAnswer() === option.expectedReply;
    }

    showOption(option: IBotMenuOption): boolean {
        return !this.hasReply || this.isCurrentReply(option);
    }

    async chooseOption(option: IBotMenuOption): Promise<void> {
        if (!this.hasReply && !this.hasChoice && !this.isDisabled()) {
            this.hasChoice = true;
            this.choicedReply = option.expectedReply;
            this.loading = true;
            this.swiper?.swiperRef.disable();
            await this.groupChatSvc.saveInteraction(
                MenuReply.factory(this.session.getSelectedParticipant(), this.interaction, option.expectedReply)
            );
            this.loading = false;
        }
    }

    public getOptions(): TBotMenuOptions {
        return this.interaction.getMenuOptions();
    }

    onSlideChange([swiper]: Swiper[]) {
        this.selectedOptionIndex = swiper.activeIndex
    }

    public choiceSelected() {
        this.chooseOption(this.menuOptions[this.selectedOptionIndex]);
    }

    private getReadableDate(): string {
        return this.clocktick.getReadable(this.interaction.getClockTick());
    }
}
