import { Avatar } from "@colmeia/core/src/business/avatar";
import { TGlobalUID } from "@colmeia/core/src/business/constant";
import { Serializable } from '@colmeia/core/src/business/serializable';
import { EInteractionSentType, IInteractionJSON } from "@colmeia/core/src/comm-interfaces/barrel-comm-interfaces";
import { TArrayID } from "@colmeia/core/src/core-constants/types";
import { ISearchActiveCallResult } from "@colmeia/core/src/shared-business-rules/active-1x1-call/active-1x1-model";
import { EFilterConversationsOptions, IAllCustomerActivityResponse, IBotAnswerDSRegisterCampaign, IConversationDetails } from "@colmeia/core/src/shared-business-rules/attendent-service-pack/attendent-sp-req-resp";
import { IBotLogContent } from "@colmeia/core/src/shared-business-rules/bot/bot-interfaces";


export interface IAttendanceConversationHistoryDialogData {
    allConversation?: IAllCustomerActivityResponse,
    oneConversation?: IConversationDetails,
    idNS: TGlobalUID,
    idAvatarCustomer?: string;
    customerAvatarName?: string;
    target?: string;
    customerClicked?: ISearchActiveCallResult;
    loadMoreConversations?(idSocialKey: string, cursor?: string, perPage?: number, filterConversationsBy?: EFilterConversationsOptions): Promise<IAllCustomerActivityResponse>;
}

export enum EConversationItemType {
    HumanInteractions = 'interaction',
    BotConversation = 'bot-conversation'
}

export interface IConversationItemBase {
    type: EConversationItemType;
    clockTick: number;
}

export interface IConversationHumanIterationInteractionItems {
    readableDate: string,
    serializable: Serializable,
    avatar: Avatar,
    json: IInteractionJSON,
    clockTick: number,
    hasMultimedia: boolean
}

export interface IConversationHumanIterationsContainer extends IConversationItemBase {
    type: EConversationItemType.HumanInteractions,
    interactions: IConversationHumanIterationInteractionItems[]
}

export interface TAttHistoryBotLogItem extends IBotLogContent {
    readableDate: string;
    isBot?: boolean;
};
export type TAttHistoryBotLogArray = TAttHistoryBotLogItem[];

export interface IConversationBotConversationContainer extends IConversationItemBase {
    type: EConversationItemType.BotConversation;
    botLogArray: TAttHistoryBotLogArray;
}

export type TConversationItem = IConversationHumanIterationsContainer | IConversationBotConversationContainer


export enum EConversationMessageType {
    Human = 'human',
    Bot = 'bot',
    Template = 'template',
    IslandTransfer = 'islandTransfer',
}

export interface IConversationMessageItem {
    iterationID: string,
    type: EConversationMessageType;
    content: string;
    readableDate: string;
    clockTick: number;
    isCustomerMessage: boolean;

    userName: string;
    idMultimedia?: TGlobalUID;
    idMedias?: TArrayID;
    sentType?: EInteractionSentType;

    isTranscription?: boolean;

    isTemplate?: boolean;
    /**
     * Template message only
     * type = EConversationMessageType.Template
     */
    templateClickedBtnLabel?: string

    /**
    * Island Transference only
    * type = EConversationMessageType.IslandTransfer
    */
    islandName?: string
    sourceIslandName?: string

    agentName?: string;

    campaign?: IBotAnswerDSRegisterCampaign;

    hasPayment?: boolean;
    paymentTransactionId?: string;
}

export type TConversationMessageItemArray = IConversationMessageItem[];

export interface IFilterConfig {
    showBotConversation: boolean;
    showInteractions: boolean;
    filterConversationsBy: EFilterConversationsOptions
    order: 'asc' | 'desc';
}

export function getDefaultConversationFilterConfig(): IFilterConfig {
    return {
        showBotConversation: true,
        showInteractions: true,
        filterConversationsBy: EFilterConversationsOptions.withHumanAttendance,
        order: 'asc'
    }
}
