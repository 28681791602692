import { Component, OnInit, Inject } from '@angular/core';
import { IFilterlet } from '@colmeia/core/src/rules/dynamic-filter/dynamic-filter-interfaces';
import { filterletToken, feedbackAndInteractionPromiseToken, TUsedFeedbackAndInteractionPromise, chatFilterEventsToken, IChatFilterEvents, InteractionTypeIcons } from 'app/model/chat-filter.model';
import { InteractionType } from '@colmeia/core/src/interaction/interaction-type';
import { isInvalidArray } from '@colmeia/core/src/tools/utility';
import { isAllowedInChatFilter } from '@colmeia/core/src/shared-business-rules/interaction-type/interaction-type-rules';
import { IBasicUniversalInfo } from '@colmeia/core/src/comm-interfaces/aux-interfaces';
import { first } from 'rxjs/operators';

@Component({
	selector: 'app-chat-interaction-type-filter',
	templateUrl: './chat-interaction-type-filter.component.html',
	styleUrls: ['./chat-interaction-type-filter.component.scss']
})
export class ChatInteractionTypeFilterComponent implements OnInit {

	interactionTypes: IBasicUniversalInfo[]

	selectedInteractions: string[];
	
	constructor(
		@Inject(filterletToken) public filterlet: IFilterlet,
		@Inject(feedbackAndInteractionPromiseToken) private interactionTypePromise: TUsedFeedbackAndInteractionPromise,
		@Inject(chatFilterEventsToken) private events: IChatFilterEvents,
	) { }

	async ngOnInit() {
		if (isInvalidArray(this.filterlet.interactionTypes)) 
			this.filterlet.interactionTypes = [];

		this.selectedInteractions = Array.from(this.filterlet.interactionTypes)

		this.interactionTypes = (await this.interactionTypePromise)
			.lookupInteractionType
			.filter(info => isAllowedInChatFilter(info.primaryID))
		;
		
		this.interactionTypes.forEach(interactionType => interactionType.idMedia = InteractionTypeIcons[interactionType.primaryID])


		this.events.applied.pipe(first()).subscribe(() => this.filterlet.interactionTypes = this.selectedInteractions);
	}

	toggleInteraction(interactionType: IBasicUniversalInfo) {
		const index = this.selectedInteractions.indexOf(interactionType.primaryID)

		if (index !== -1) return this.selectedInteractions.splice(index, 1)
		
		this.selectedInteractions.push(interactionType.primaryID)
	}

}
