<ng-container *ngIf="channelStatus.isClosed">
    <div class="container">
        <app-alert type="info" light dense>
            <span class="message">O canal de comunicação com o cliente está fechado.</span>
            <ng-container *ngIf="isAllowedSendTemplate()">
                <button [loading]="sending" [disabled]="!getCanSendTemplate()" class="send-button button-w-icon" mat-flat-button (click)="sendTemplate()">
                    <ng-container *ngIf="!getCanSendTemplate()">
                        {{ getCoundown() }}&nbsp;
                    </ng-container>
                    <mat-icon *ngIf="getCanSendTemplate()">send</mat-icon>&nbsp;
                    Enviar chamada
                </button>
            </ng-container>
        </app-alert>
    </div>
</ng-container>
