import { constant, TGlobalUID } from '../business/constant';
import { ObjectType } from '../business/object-type';
import { Serializable } from '../business/serializable';
import { IMultimediaInstanceJSON, IMultimediaObjectJSON } from '../comm-interfaces/multi-media-interfaces';
import { T360MediaArray } from '../core-constants/bot';
import { TArrayID } from '../core-constants/types';
import { UberCache } from '../persistency/uber-cache';
import { isValidArray, isValidRef, isValidString } from '../tools/utility';
import { MultimediaInstance, TMultimediaInstanceArray } from './multi-media-instance';
import { MultimediaTag } from './multi-media-tag';
import { EMultimediaNature, MultiMediaType } from './multi-media-type';
import { MMconstant } from './multimedia-constant';

export type TMultiMediaObjectArray = Array<MultimediaObject>;

export class MultimediaObject extends Serializable {

    private multimediaInstanceArray: TMultimediaInstanceArray;
    private objectType: ObjectType;
    private pkTable: TGlobalUID;

    private constructor(primaryID: TGlobalUID, idObjectType: TGlobalUID, pkTable: TGlobalUID) {
        super(constant.objectType.multiMediaObject, primaryID);
        this.pkTable = pkTable;
        this.multimediaInstanceArray = [];
        this.objectType = ObjectType.staticFactory(idObjectType);
    };

    public static getTemplate(): MultimediaObject {
        return new MultimediaObject('TEMPLATE MM', '1', 'XXXXX');
    };

    public getContainerPKTable(): TGlobalUID {return this.pkTable};

    public getMultimediasName(): string {
        const multimediaInstances = this.multimediaInstanceArray.map(instance => instance.toJSON());
        if (!multimediaInstances || multimediaInstances.length === 0) {
            return '';
        }
        return multimediaInstances.map(instance => instance.fileName).join(' - ');
    }

    public toJSON(): IMultimediaObjectJSON {
        let json: IMultimediaObjectJSON = <IMultimediaObjectJSON>super.toJSON();
        json.primaryID = super.getPrimaryID();
        json.idContainerObject = this.objectType.getObjectID();
        json.multiMediaInstanceArray = [];
        json.multimediaObject = null; // não tem ele mesmo um multimedia object
        json.pkTable = this.pkTable;
        for (let mmInstance of this.multimediaInstanceArray) {
            json.multiMediaInstanceArray.push(mmInstance.toJSON());
        };
        return json;
    };

    public rehydrate(json: IMultimediaObjectJSON): void {
        super.rehydrate(json);
        this.objectType = ObjectType.staticFactory(json.idContainerObject);
        this.pkTable = json.pkTable;
        this.multimediaInstanceArray = [];
        for (let mmInstance of json.multiMediaInstanceArray) {
            this.addMultimediaInstanceIfNotExist(MultimediaInstance.factoryMessage(mmInstance));
        };
    };

    public isMultimediaVideo(idMultimediaKey: TGlobalUID): boolean {
        let is: boolean = false;
        const mInstance: MultimediaInstance = this.getAllMultimediaInstance().find((m) => {return m.is(idMultimediaKey)} );
        if (mInstance) {
            const mime = mInstance.getMimeType();
            const multimediaMime = MultiMediaType.getMutimediaTypeFromMime(mime);
            return multimediaMime.isVideo();
        };
        return is;
    };

    public getThumbnailOfaParentOrMainMultimedia(mulimediaKey: TGlobalUID): MultimediaInstance {
        const thumbInstance: MultimediaInstance = this.getAllMultimediaInstanceWithTag(MMconstant.tag.thumbnail)
                .find((th) => {return th.getThumbnailParentKey() == mulimediaKey});
        if(thumbInstance) {
            return thumbInstance;
        } else {
            return this.getAllMultimediaInstance().find(mi => mi.getMultmediaKey() === mulimediaKey);
        }
    };


    public static createMultimediaForSerializable(serializable: Serializable): MultimediaObject {
        return MultimediaObject.getNewMultimediaObject(ObjectType.staticFactory(serializable.getObjectTypeID()), serializable.getPrimaryID());
    };
    public addMultimediaInstanceIfNotExist(multiMediaInstance: MultimediaInstance): number {
        let idx: number;
        if ((idx = this.multimediaInstanceArray.findIndex((m) => {return m.getMultiMediaInstanceUID() == multiMediaInstance.getMultiMediaInstanceUID()})) == -1) {
            this.multimediaInstanceArray.push(multiMediaInstance);
        };
        return idx;
    };

    public getAllMultimediaIDMediaArray(): TArrayID {
        return this.getAllMultimediaInstance().map((i) => {return i.getBestMedia()});
    }

    //ATENÇÃO.. LÓGICA TIRADA DE getBestMultimediaFromMultimediaObject
    // SEMPRE MANTER SÍNCRONO OS CRITÉRIOS
    public getBestIDMedia(): string {
        if (isValidArray(this.multimediaInstanceArray)) {

            for (const thumb of this.multimediaInstanceArray.filter((i) => {return i.getMultimediaTag().is(MMconstant.tag.thumbnail)})) {
                const mmInstance: MultimediaInstance = this.multimediaInstanceArray.find((i) => {
                    return i.is(thumb.getThumbnailParentKey())
                        && i.getMultimediaTag().is(MMconstant.tag.photo)
                        && MultiMediaType.getMutimediaTypeFromMime(i.getMimeType()).isImage()
                });
                if (mmInstance) {
                    return thumb.getIdMedia();
                };
            };

            // chegou aqui, não tem thumb para a memsagem
            const mInstance: MultimediaInstance = this.multimediaInstanceArray.find((i) => {
                return (i.getMultimediaTag().is(MMconstant.tag.photo)
                        || i.getMultimediaTag().is(MMconstant.tag.thumbnail)
                    && MultiMediaType.getMutimediaTypeFromMime(i.getMimeType()).isImage())
            });
            if (mInstance) {
                return mInstance.getIdMedia();
            };
        };
        return null;
    };

    public addMultimediaInstance(multiMediaInstance: MultimediaInstance): void {
        this.multimediaInstanceArray.push(multiMediaInstance);
    };

    public getAllMultimediaInstance(): TMultimediaInstanceArray {
        return this.multimediaInstanceArray ? this.multimediaInstanceArray : [];
    };

    public getAllMultimediaInstanceWithTag(idTag: TGlobalUID): TMultimediaInstanceArray {
        return this.getAllMultimediaInstance().filter((m) => {return m.getMultimediaTag().is(idTag)})
    };

    public getAllMultimediaInstanceWithNature(mmNature: EMultimediaNature): TMultimediaInstanceArray {
        return this.getAllMultimediaInstance().filter((mm: MultimediaInstance) => {
            return mm.hasNature(mmNature);
        });
    }

    public getMultimediaInstanceToUpload(): TMultimediaInstanceArray {
        return this.multimediaInstanceArray.filter((f) => {return f.canUpload()});
    }

    public getMultimediaInstanceByTag(idTag: TGlobalUID): MultimediaInstance {
       return this.multimediaInstanceArray.find((t) => {return t.getMultimediaTag().getMultiMediaTagID() == idTag})
    }

    public getMultimediaInstanceByIdMedia(idMedia: TGlobalUID): MultimediaInstance {
        return this.multimediaInstanceArray.find(mmObj => mmObj.getIdMedia() === idMedia);
    }

    public getMultimediaInstanceByHash(hash: TGlobalUID): MultimediaInstance {
        return this.multimediaInstanceArray.find(mmObj => mmObj.getHashFromSelectedFile() === hash);
    }

    public getFileIDMediaByTag(idTag: TGlobalUID): TGlobalUID {
        const mmInstance = this.getMultimediaInstanceByTag(idTag);
        let idMedia: TGlobalUID;
        if (mmInstance) {
            idMedia = mmInstance.getFileIdMedia();
        };
        return idMedia;
    }

    public getNewMultimediaInstance(idTag: TGlobalUID,  idMedia: string): MultimediaInstance {
        let mmInstance: MultimediaInstance = MultimediaInstance.getNewMultimediaInstance(MultimediaTag.staticFactory(idTag), idMedia);
        this.addMultimediaInstanceIfNotExist(mmInstance);
        return mmInstance;
    };

    public factoryMultimediaAndAdd(mmInstanceJson: IMultimediaInstanceJSON): MultimediaInstance {
        let mmInstance: MultimediaInstance = MultimediaInstance.factoryMessage(mmInstanceJson);
        this.addMultimediaInstanceIfNotExist(mmInstance);
        return mmInstance;
    };

    public removeMultimediaInstance(mmInstance: MultimediaInstance): void {
        let index: number = this.multimediaInstanceArray.findIndex((t) => {return t.getMultimediaTag().getMultiMediaTagID() == mmInstance.getMultimediaTag().getMultiMediaTagID()})
        if (index > -1) {
            this.multimediaInstanceArray.splice(index,1)
        };
    };

    public replaceMultimediaInstanceArray(instanceArray: TMultimediaInstanceArray): void {
        this.multimediaInstanceArray = instanceArray;
    };


    public static factoryMessage(json: IMultimediaObjectJSON): MultimediaObject {
        let mmObject: MultimediaObject;
        mmObject = <MultimediaObject>UberCache.uberFactory(json.primaryID, constant.objectType.multiMediaObject, false);
        if (!mmObject)
            mmObject = new MultimediaObject(json.primaryID, json.idContainerObject, json.pkTable);
        mmObject.rehydrate(json);
        return mmObject;
    };

    public static getNewMultimediaObject(objectType: ObjectType, pkTable: TGlobalUID): MultimediaObject {
        return new MultimediaObject(null, objectType.getObjectID(), pkTable);
    };

    public static getNewMultimediaObjectFrom360(medias: T360MediaArray, idObjectType: TGlobalUID = constant.objectType.visualElement): MultimediaObject {
        const mmObject: MultimediaObject = MultimediaObject.getNewMultimediaObject(ObjectType.staticFactory(idObjectType), null);
        medias.filter(media => isValidRef(media)).forEach(media => {
            const mmInstance = mmObject.getNewMultimediaInstance(MMconstant.tag.photo, media.idMedia);
            mmInstance._setFileSize(media.size);
            mmInstance.setMimeType(media.mymeType);
        });
        mmObject.removeUberCache();
        return mmObject;
    }

    public static getNewMultimediaObjectFromMultimediaInstanceJSON(mmInstanceJson: IMultimediaInstanceJSON[], idObjectType: TGlobalUID = constant.objectType.visualElement): MultimediaObject {
        const mmObject: MultimediaObject = MultimediaObject.getNewMultimediaObject(ObjectType.staticFactory(idObjectType), null);
        mmInstanceJson.filter(media => isValidRef(media)).forEach(media => {
            mmObject.factoryMultimediaAndAdd(media);
        });
        mmObject.removeUberCache();
        return mmObject;
    }

    public static getNewMultimediaObjectFromIdMedia(idMedia: string): MultimediaObject {
        const mmObject: MultimediaObject = MultimediaObject.getNewMultimediaObject(ObjectType.staticFactory(constant.objectType.visualElement), null);
        if (isValidString(idMedia)) {
            mmObject.getNewMultimediaInstance(MMconstant.tag.photo, idMedia);
        }
        mmObject.removeUberCache();
        return mmObject;
    }

    public static getMultiMediaObjectArray(): TMultiMediaObjectArray {
       return <TMultiMediaObjectArray>UberCache.getSerializableArray(constant.objectType.multiMediaObject);
    };

    public static getIDMediaFromJSON(mmObject: IMultimediaObjectJSON, idTag: TGlobalUID): TGlobalUID {
        let idMedia: TGlobalUID;
        if (mmObject && mmObject.multiMediaInstanceArray) {
            const mmInstance = mmObject.multiMediaInstanceArray.find((instance) => {return instance.idMultiMediaTag == idTag});
            if (mmInstance) {
                idMedia = mmInstance.idMedia;
            };
        };
        return idMedia;
    };
};
