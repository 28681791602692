import { sharedUriFragments } from "@colmeia/core/src/core-constants/routes-shared";
import { EGroupType, mainHeaderChat, seeAllGroupsConstant } from "@colmeia/core/src/shared-business-rules/visual-constants";
import { DeepFindValuesOfType } from "@colmeia/core/src/tools/utility-types";
import { environment } from "../../../environments/environment-client";


/**
 * create a routeId when we dont want to associate a route to a serializable
 */


export const integration = {
    path: 'integration',
    children: {
        list: 'list',
        create: 'create',
        fileDetail: {
            path: 'file/:idFile',
            routeParam: ':idFile'
        },
    }
} as const

export const forms = {
    path: 'forms',
    children: {
        list: 'list',
        create: 'create',
        edit: {
            path: ':idSchema/edit',
            routeParam: ':idSchema'
        }
    }
} as const

export const userFunctions = {
    path: 'functions',
    children: {
        create: "create",
        edit: "edit/:idNS"
    }
} as const;

export const transformerRoutes = {
    path: 'visualizer',
    children: {
        create: "create",
        edit: "edit/:idNS"
    }
} as const;



export type RouteID = DeepFindValuesOfType<typeof routeID, string>;
export const routeID = {
    home: 'home',
    menu: 'menu',
    auth: {
        parent: 'auth',
        login: 'login',
        signIn: {
            id: 'signIn'
        },
        forgotPass: sharedUriFragments.signIn.forgotPass.id,
        signUp: sharedUriFragments.signUp.id,
        socialNetwork: {
            linkedin: 'Linkedin'
        }
    },
    landing: sharedUriFragments.landing,
    player: {
        profile: 'player/myProfile',
        notifications: 'notifications/view',
        avatars: {
            path: 'avatars',
            id: 'idAvatar'
        }
    },
    groups: {
        faceCompany: 'company-face',
        home: 'groupHome',
        chat: mainHeaderChat,
        members: 'groupMembers',
        createPersonalGroup: 'personalGroup',
        subgroups: seeAllGroupsConstant,
        groupProfile: {
            path: 'groupProfile',
            about: 'about',
            people: 'people',
            resources: 'resources',
            subgroups: 'subgroups',
            editGroup: 'edit-group'
        },
        avatarProfile: 'avatarProfile'
    },
    location: {
        locationViewer: {
            id: 'locationviewer',
            avatars: 'avatars',
            idAvatar: 'idAvatar',
            service: 'service'
        },
    },
    // featuresNonCreate: {
    //     newVotation: riveScreen.features.votation,
    // },
    features: {
        newCorporate: EGroupType.Corporate,
        newTribe: EGroupType.Tribe,
        newDistributionList: EGroupType.Distribution,
        newBroadcaster: EGroupType.Broadcaster,
        newSpokenGroup: EGroupType.SpokePerson,
        newSubGroup: EGroupType.SubGroup,
        newChannel: EGroupType.Channel,
        newAnonymous: EGroupType.NewAnonymous,
        newMassCommunication: EGroupType.MassCommunication,
        newInventory: EGroupType.NewInventory,
        newFeatureCreator: EGroupType.NewFeature,
        editSubGroup: EGroupType.SubGroupEdit,
    },
    dashboard: 'dashboard',
    integrations: {
        salesforce: 'salesforceIntegration'
    },
    deploy: {
        list: 'dep.prod',
    },
    deleteAccount: 'delete-account',
    privacyPolicy: 'privacy-policy',
} as const;



export const contentGenerator = {
    path: 'bot-transaction',
    children: {
        list: { path: '' },
        create: { path: 'create' },
        details: {
            path: ':idNS/details',
            routeParam: ':idNS'
        }
    }
} as const;

export interface IBaseRouteListPathPattern {
    path: string;
    param?: string;
    idMenu?: string;
    children?: TRouteListPathContainer;
};


export type TRouteListPathContainer = {
    [key in string]: TRouteListPathContainerItem
};


export type TRouteListPathContainerObjectItem =
    | IBaseRouteListPathPattern
    | TRouteListPathContainer
    ;

export type TRouteListPathContainerItem =
    | string
    | TRouteListPathContainerObjectItem
    ;


export type TExtractChildren<PC> = {
    [key in keyof PC]:
    PC[key] extends { path: infer P, children?: infer C } ?
    P extends string ?
    C extends object ?
    C extends TRouteListPathContainer ? TExtractChildren<C> : P
    : unknown
    : unknown
    :
    PC[key] extends string ?
    PC[key]
    : unknown
};

const calendarRoutes = {
    path: 'calendar',
    children: {
        list: '',
        edit: 'edit/:idNS',
        create: 'create'
    }
};

export type IdBotParam = {
    idBot: string;
};
export const compiledIdBotParam = 'idBot' as const;

export const defaultNSRoute = ({
    list: { path: 'list' },
    details: { path: 'details', routeParam: ':id' },
    create: { path: 'create' },
} as const)

// Object.freeze(defaultNSRoute);

export const bpmBotsChildrenPathsConfigs = {
    create: { path: 'create' },
    edit: {
        path: 'edit',
        idGraphElement: 'idGraphElement',
    },
    routeParamBpmType: 'bpmType',
} as const;

export type TBPMRoutesConfig = {
    path: string;
    children: typeof bpmBotsChildrenPathsConfigs
}

export const routeList = {
    parameters: {
        groupID: ':idGroup',
        featureName: ':featureName',
    },
    menu: {
        path: 'menu'
    },
    notification: {

    },
    auth: {
        login: routeID.auth.parent + '/' + routeID.auth.login,
        signIn: routeID.auth.parent + '/' + routeID.auth.signIn.id,
        signUp: routeID.auth.parent + '/' + sharedUriFragments,
        linkedin: routeID.auth.parent + '/' + routeID.auth.socialNetwork.linkedin,
    },
    landing: {
        path: routeID.landing
    },
    player: {
        path: 'players',
        children: {
            avatars: {
                path: 'avatar',
                id: ':idAvatar'
            }
        }
    },
    maps: {
        path: 'maps',
        children: {
            locationViewer: {
                path: 'location-viewer',
                children: {
                    avatars: {
                        path: 'avatars',
                        children: {
                            idAvatar: ':idAvatar'
                        }
                    }
                }
            }
        }
    },
    admin: {
        path: 'admin'
    },
    test: {
        path: 'test'
    },
    notifications: {
        path: 'notifications',
        children: {
            view: 'view'
        }
    },
    home: {
        path: 'home'
    },
    groups: {
        path: 'groups',
        children: {
            createGroup: 'create',
            idGroup: {
                path: ':idGroup',
                children: {
                    companyFace: 'company-face',
                    home: 'home',
                    subgroups: 'subgroups',
                    members: 'members',
                    features: 'features',
                    featuresList: {
                        survey: '/survey',
                        form: '/form'
                    },
                    featureNonCreateGroup: 'featureNonCreateGroup',
                    broadcast: routeID.features.newBroadcaster,
                    distributionList: routeID.features.newDistributionList,
                    spokenGroup: routeID.features.newSpokenGroup,
                    personalGroup: 'features/personalGroup',
                    standardGroup: 'features/standardGroup',
                    administrator: 'features/administrator',
                    distribution: 'distribution',
                    chat: 'chat',
                    create: {
                        path: 'create',
                        children: {
                            survey: 'survey',
                            poll: 'poll',
                            form: 'form'
                        },
                    },
                    view: {
                        path: 'view',
                        children: {
                            survey: 'survey'
                        }
                    },
                    groupProfile: {
                        path: routeID.groups.groupProfile.path,
                        about: routeID.groups.groupProfile.about,
                        resources: routeID.groups.groupProfile.resources,
                        people: routeID.groups.groupProfile.people,
                        subgroups: routeID.groups.groupProfile.subgroups,
                        editGroup: routeID.groups.groupProfile.editGroup
                    },
                    spokesperson: 'spokesperson'
                }
            }
        }
    },
    thirdPartyIntegration: {
        path: `vendor`,
        vendors: {
            salesforce: {
                path: 'salesforce',
                pages: {
                    chat: {
                        path: `chat`,
                        params: {
                            idTopic: `idTopic`,
                            nickName: `nickName`
                        }
                    }
                }
            }
        }
    },
    groupAdministrative: {
        path: 'group-administrative',
        children: {
            edit: 'edit',
            groupID: `:idGroup`
        }
    },
    embed: {
        path: 'embedded',
        children: {},
    },
    dashboard: {
        path: 'dashboard',
        children: {
            smartFlow: {
                path: 'smart-flow',
                children: {
                    bpmGraph: generateBPMRoutes('bots'),
                    canonical: {
                        path: 'canonical',
                        children: {
                            list: { path: 'list' },
                        }
                    },
                    tags: {
                        path: 'tags',
                        children: {
                            list: { path: 'list' },
                            details: {
                                path: ':idTag/detail',
                                routeParam: ':idTag'
                            }
                        }
                    },
                    integration,
                    forms,
                    botTransactions: contentGenerator,

                    bots: {
                        path: 'bots',
                        children: {
                            list: {
                                path: 'list'
                            },
                            create: {
                                path: 'create'
                            },
                            details: {
                                path: `:${compiledIdBotParam}/details`,
                                routeParam: `:${compiledIdBotParam}`,
                                _path: 'details'
                            },
                            nodes: {
                                path: `:${compiledIdBotParam}/nodes`,
                                routeParam: `:${compiledIdBotParam}`,
                                children: {
                                    create: {
                                        path: `:elemType/create`,
                                        routeParam: ':elemType',
                                    },
                                    details: {
                                        path: ':idNode/details',
                                        routeParam: ':idNode',
                                    },
                                    leafs: {
                                        path: ':idNode/leafs',
                                        routeParam: ':idNode',
                                        children: {
                                            create: {
                                                path: 'create'
                                            },
                                            details: {
                                                path: ':idLeaf/details',
                                                routeParam: ':idLeaf'
                                            },
                                        }
                                    }
                                },
                            }
                        }
                    },
                    indexedSearch: {
                        path: 'indexed-search'
                    },

                    functions: {
                        path: 'functions',
                        children: {
                            create: "create",
                            edit: "edit/:idNS"
                        }
                    },
                    rtrParameterizable: {
                        path: "rtf-params",
                        children: {
                            create: "create",
                            edit: {
                                path: "edit/:header_idNS",
                                children: {
                                    body: {
                                        path: "body",
                                        children: {
                                            create: "create",
                                            edit: "edit/:body_idNS"
                                        }
                                    }
                                }
                            },
                        }
                    },
                    whatsappFlows: {
                        path: "whatsapp-flows",
                        children: {
                            list: 'list',
                            create: 'create',
                            edit: {
                                path: 'edit/:idNS',
                                routeParam: ':idNS',
                                children: {
                                    schemas: {
                                        path: 'schemas',
                                        children: {
                                            create: 'create',
                                            edit: 'edit/:idNS',
                                            routeParam: ':idNS'
                                        }
                                    }
                                },
                            }
                        }
                    }
                }
            },
            home: 'home',
            services: 'services',
            serviceId: 'services/:idService',
            environments: {
                path: 'environments',
                children: {
                    patches: {
                        path: 'patches',
                        children: {
                            list: 'list',
                            edit: 'edit',
                            id: ':idDeploy'
                        }
                    },
                    changes: {
                        path: 'changes',
                        children: {
                            list: 'list'
                        }
                    },
                    transformer: {
                        path: 'transformer',
                        children: {
                            list: 'list'
                        }
                    },
                    trackingTags: {
                        path: 'tracking-tags',
                        children: {
                            list: {
                                path: 'list',
                                children: {
                                    all: 'all',
                                    idNS: ':idNS',
                                }
                            }
                        }
                    },
                    promoted: {
                        path: 'promoted',
                        children: {
                            list: 'list'
                        }
                    },
                    versioning: {
                        path: 'env-versioning',
                        children: {
                            list: 'list'
                        }
                    },
                    backup: {
                        path: 'backup',
                        children: {
                            package: {
                                path: 'package',
                                children: {
                                    edit: ':idNS',
                                },
                            },
                        }
                    },
                }
            },
            colmeia: {
                path: 'colmeia',
                children: {
                    contracts: {
                        path: 'contracts',
                        children: {
                            list: {
                                path: '',
                            },
                            edit: {
                                path: '',
                                param: ':idNS',
                                children: {
                                    param: ':idNS',
                                }
                            },
                        }
                    },
                    visualElements: 'visual-elements',
                    menuCreator: 'menu-creator',
                    broker: 'colmeia-broker',
                    widget: 'widget',
                    scripts: 'colmeia-scripts',
                    tools: 'colmeia-tools',
                    dashboardsConfig: 'dashboards-config',
                    socialNetworkConfig: 'sn-configurations',
                    helpTips: 'colmeia-help-tips',
                    dashboardsHomologation: {
                        path: 'dashboards-homologation',
                        children: {
                            view: "report-id/:reportId"
                        }
                    },
                    houseKeeping: 'house-keeping',
                    products: {
                        path: 'products',
                        children: {
                            list: 'list',
                            create: 'create',
                            edit: 'edit/:idNS',
                            routeParam: ':idNS'
                        }
                    },
                    coordinatorDeletion: {
                        path: 'coordinator-deletion',
                    },
                    businessContracts: {
                        path: 'business-contracts',
                        children: {
                            list: 'list',
                            create: 'create',
                            edit: {
                                path: 'edit/:idNS',
                                routeParam: ':idNS',
                                children: {
                                    productSKUs: {
                                        path: 'productSKUs',
                                        children: {
                                            list: 'list',
                                            create: 'create',
                                            edit: 'edit/:idSKU',
                                            routeParam: ':idSKU'
                                        }
                                    }
                                },
                            }
                        }
                    },
                    billingClose: {
                        path: 'billing-close',
                        children: {
                            list: 'list',
                            create: 'create',
                            details: 'details/:idNS',
                            routeParam: ':idNS'
                        }
                    }
                },
            },
            serviceStatus: {
                path: 'service-status',
                children: {
                    current: 'status',
                    history: 'history',
                    calendar: calendarRoutes,
                    functions: {
                        path: 'functions',
                        children: {
                            create: "create",
                            edit: "edit/:idNS"
                        }
                    },
                    attendenceFollowUp: {
                        path: 'attendence-follow-up'
                    },
                    attSupport: {
                        path: 'attSupport',
                        children: {
                            servicePackList: 'servicePackList',
                            createServicePack: 'create',
                            editServicePack: {
                                path: ':idServicePack/edit',
                                routeParam: ':idServicePack',
                            }
                        }
                    },

                    attendanceEvents: {
                        path: 'attendance-events',
                        children: {
                            list: { path: 'list' },
                            details: { path: 'details', routeParam: ':id' },
                            create: { path: 'create' },
                        }
                    },

                    activeCall: {
                        path: 'active-call',
                        children: {
                            list: { path: 'list' },
                            details: { path: 'details', routeParam: ':id' },
                            create: { path: 'create' },
                        }
                    },

                    contactList: {
                        path: 'contact-list',
                        children: {
                            list: { path: 'list' },
                            details: { path: 'view', routeParam: ':id' },
                            create: { path: 'create' },
                        }
                    },

                    activeSends: {
                        path: 'send-active',
                        children: {
                            list: { path: 'list' },
                            details: { path: 'details', routeParam: ':id' },
                            create: { path: 'create' },
                        }
                    },

                    serviceIslands: {
                        path: 'attendance-island',
                        children: {
                            list: { path: 'list' },
                            create: { path: 'create' },
                            details: {
                                path: ':idServiceIsland/edit',
                                routeParam: ':idServiceIsland'
                            },
                        }
                    },

                    agents: {
                        path: 'agents',
                        children: {
                            list: 'list',
                            edit: { path: 'edit', children: { idNS: ':idNS' } },
                            create: 'create',
                            // items
                            all: 'all',
                            queue: { path: 'queue', idIsland: ':idIsland' },
                            work: { path: 'work', idIsland: ':idIsland' },
                        }
                    },

                    agentStatus: {
                        path: 'agent-status',
                        children: {
                            list: 'list',
                            create: 'create',
                            edit: 'edit/:idNS'
                        }
                    },
                    agentsFile: {
                        path: 'agent-file',
                        children: {
                            list: 'list',
                            edit: 'edit',
                            idNS: ':idNS',
                        }
                    },
                    integration,
                    forms,
                    conversationHistory: {
                        path: 'conversation-history',
                        children: {
                            search: 'search',
                            view: {
                                path: 'view',
                                children: {
                                    idSocialKey: 'idSocialKey/:idSocialKey',
                                    idConversation: 'idConversation/:idConversation',
                                }
                            },
                            print: {
                                path: 'print',
                                children: {
                                    idConversation: 'idConversation/:idConversation',
                                }
                            }
                        }
                    },
                    configurationSet: {
                        path: 'configuration-set',
                        children: {
                            edit: {
                                path: 'edit/:idNS',
                            }
                        }
                    },

                    agentsOnIsland: {
                        path: 'agents-on-island',
                        children: {
                            list: { path: 'list' },
                            details: {
                                path: ':idServiceIsland/edit',
                                routeParam: ':idServiceIsland'
                            },
                        }
                    },

                    attCopilot: {
                        path: 'copilot',
                        children: {
                            list: { path: 'list' },
                            create: 'create',
                            details: {
                                path: ':copilotId/edit',
                                routeParam: ':copilotId'
                            }
                        }
                    },

                    tickets: {
                        path: 'tickets',
                        children: {
                            view: {
                                protocolParam: ':ticketProtocol',
                                idTicketParams: ':idTicket',
                                path: 'view/:ticketProtocol/:idTicket',
                            }
                        }
                    }
                },

            } as const,
            marketing: {
                path: 'marketing',
                children: {
                    integration,
                    forms,
                    campaigns: {
                        path: 'campaigns',
                        children: {
                            list: 'list',
                            create: 'create',
                            edit: {
                                path: 'edit/:idCampaign',
                                routeParam: ':idCampaign',
                            }
                        }
                    },
                    functions: {
                        path: 'functions',
                        children: {
                            create: "create",
                            edit: "edit/:idNS"
                        }
                    },
                    dataExtractor: {
                        path: 'data-extractor',
                        children: {
                            list: 'list',
                            create: 'create',
                            edit: 'edit/:idNS',
                            routeParam: ':idNS'
                        }
                    },
                    sendCampaign: {
                        path: 'send-campaign',
                        children: {
                            list: { path: 'list' },
                            create: { path: 'create' },
                            details: { path: 'edit/:idSentCampaign', routeParam: ':idSentCampaign' }
                        }
                    },
                    channelsTemplates: {
                        path: "channels-templates",
                        children: {
                            list: { path: 'list' },
                            create: { path: 'create' },
                            details: { path: "details", param: ':idNS' }
                        }
                    },
                    rescheduleCalendar: {
                        ...calendarRoutes,
                        path: "reschedule-calendar"
                    },
                    marketingBPM: generateBPMRoutes('marketing-bpm'),
                    wabaQuality: {
                        path: 'marketing-waba-quality',
                        children: {
                            list: { path: 'list' },
                            channelWhitTemplate: {
                                path: 'channel-template',
                                routeParam: ':channelIdNS'
                            },
                            wabaNotifications:{
                                path: 'waba-notifications',
                                routeParam: ':wabaIdNS'
                            }
                        }
                    }
                }
            } as const,
            crmServices: {
                path: 'crm-services',
                children: {
                    phases: {
                        path: 'phases',
                        children: {
                            edit: {
                                path: ":id",
                                routeParam: ":id"
                            },
                        }
                    },
                    severity: {
                        path: 'severity',
                        children: {
                            edit: {
                                path: ":id",
                                routeParam: ":id"
                            }
                        }
                    },
                    closedState: {
                        path: 'closed-state',
                        children: {
                            edit: {
                                path: ":id",
                                routeParam: ":id"
                            }
                        }
                    },
                    ticketState: {
                        path: 'ticket-state',
                        children: {
                            edit: {
                                path: ":id",
                                routeParam: ":id"
                            }
                        }
                    },
                    supportLevel: {
                        path: 'support-level',
                        children: {
                            edit: {
                                path: ":id",
                                routeParam: ":id"
                            }
                        }
                    },
                    urgencyLevel: {
                        path: 'urgency-level',
                        children: {
                            edit: {
                                path: ":id",
                                routeParam: ":id"
                            }
                        }
                    },
                    packages: {
                        path: 'packages',
                        children: {
                            edit: {
                                path: ":id",
                                routeParam: ":id"
                            }
                        }
                    },
                    helpdesk: {
                        path: 'help-desk',
                        children: {
                            view: {
                                path: ":id",
                                routeParam: ":id"
                            }
                        }
                    },
                    servicesConfigs: generateBPMRoutes('services-configs')
                },
            },
            jobs: {
                path: 'jobs',
                children: {
                    environmentDataMigration: 'environment-data-migration',
                    machineLearning: "jobs-machine-learning",
                    fileUpload: "file-upload",
                    fileDownload: "database-file-download",
                    deleteAvatarFromSN: "delete-avatar-from-sn",
                    campaignExecute: "campaign-execute",
                    cancelCampaignSchedules: "campaign-cancel-schedules",
                    campaignReschedules: "campaign-reschedules",
                    corporateSearchProcess: "corporate-search-process",
                    deleteNS: 'delete-ns',
                    deleteFileContents: 'delete-file-contents',
                    patchDependencyResolution: 'patch-dependency-resolution',
                    sendAllProvidersToReview: 'send-all-providers-to-review',
                    checkTemplateApprovals: 'check-template-approvals',
                    processUntrainedUtterances: 'delete-process-untrained-utterances',
                    backupCreateSnapshot: 'backup-create-snapshot',
                    backupRestoreSnapshot: 'backup-restore-snapshot',
                    userGroupProfileUpdate: 'user-group-profile-update',
                    generativeTrainingIntentProcessing: 'generative-training-intent-processing',
                    approveSocialNetworkParticipationInBatch: 'social-network-batch-approval',
                    deleteFile: 'delete-file',
                    wafSchemaUpdate: 'waf-schema-update',
                    dataExtraction: 'data-extraction',
                    migrateWhatsAppSMProvider: 'migrate-whatsapp-sm-provider',
                    fetchContentFromURLsInVector: 'fetch-content-from-urls-in-vector',
                },
            },
            communication: {
                path: 'communication',
                children: {
                    connections: {
                        path: 'connections',
                        pathIntegrations: 'integrations',
                        pathPaymentConnection: 'payments',
                        pathPaymentGatewayConnection: 'paymentsGateways',
                        id: ':id',
                        children: {
                            create: 'create',
                            edit: 'edit',
                            // auth: 'edit-auth',
                            // domains: 'edit-domains',
                            // routes: 'edit-routes'
                        }
                    },
                    connectionRoutes: {
                        path: 'connection-routes',
                        idNS: ':idNS',
                        children: {
                            list: 'list',
                            create: 'create',
                            edit: { path: 'edit' },
                        },
                    },
                    socialMedias: {
                        path: 'socialMedias',
                        children: {
                            list: 'list',
                            create: 'create',
                            edit: {
                                path: 'edit/:idSocialMedia',
                                routeParam: ':idSocialMedia',
                            }
                        }
                    },
                    forms,
                    integration,
                    api: {
                        path: 'api',
                        children: {
                            list: { path: 'list' },
                            edit: 'edit/:idNS',
                            create: "create"
                        }
                    },
                    waba: {
                        path: 'waba',
                        children: {
                            list: 'list',
                            create: 'create',
                            edit: {
                                path: 'edit/:wabaId',
                                routeParam: ':wabaId',
                            }
                        }
                    }

                },
            } as const,
            service: {
                path: 'service',
                children: {
                    deployedServices: {
                        path: 'deployedServices',
                        children: {
                            list: 'list',
                            create: 'create',
                            edit: {
                                path: 'edit/:idDeployedService',
                                routeParam: ':idDeployedService',
                            }
                        }
                    },

                    functions: {
                        path: 'functions',
                        children: {
                            create: "create",
                            edit: "edit/:idNS"
                        }
                    },

                    namedAccounts: {
                        path: 'named-account',
                        children: {
                            list: 'list',
                            edit: 'edit',
                            idNS: ':idNS',
                        }
                    },
                    integration
                },
            },
            ai: {
                path: 'ai',
                children: {
                    functions: {
                        path: 'functions',
                        children: {
                            create: "create",
                            edit: "edit/:idNS"
                        }
                    },
                    nlpInputs: {
                        path: 'nlp-inputs'
                    },
                    aiAgents: {
                        path: 'ai-agents',
                        children: {
                            list: { path: 'list' },
                            create: { path: 'create' },
                            edit: {
                                path: ':agentId/edit',
                                routeParam: ':agentId'
                            },
                        }
                    },
                    chainedBases: {
                        path: 'chained-bases',
                        children: {
                            list: 'list',
                        },
                    },
                    knowledgeBase: {
                        path: 'knowledge-base',
                        children: {
                            list: { path: 'list' },
                            create: { path: 'create' },
                            edit: {
                                path: ':idKnowledgeBase/edit',
                                routeParam: ':idKnowledgeBase'
                            },
                            details: {
                                path: ':idKnowledgeBase/details',
                                routeParam: ':idKnowledgeBase',
                                children: {
                                    intents: {
                                        path: 'intents',
                                        children: {
                                            list: '',
                                            detail: 'detail'
                                        }
                                    }
                                }
                            },
                        }
                    },
                    kbCorporateEntity: {
                        path: 'kb-corporate-entity',
                        children: {
                            edit: ':idNS',
                            create: 'create',
                        }
                    },
                    botTransactions: contentGenerator,
                    nestedAI: generateBPMRoutes('nested-ai-bpm'),
                    vectors: {
                        path: 'vectors',
                        children: {
                            create: { path: 'create' },
                            details: {
                                path: ":idContentVector",
                                routeParam: ":idContentVector",
                                children: {
                                    createChunk: {
                                        path: 'chunk/create',
                                    },
                                    editChunk: {
                                        path: "chunk/:idChunk",
                                        routeParam: ":idChunk"
                                    }
                                }
                            },
                        }
                    },
                    forms,
                    integration,
                    prompts: {
                        path: 'prompts',
                        children: {
                            create: { path: 'create' },
                            edit: {
                                path: ":idPrompt",
                                routeParam: ":idPrompt",
                            },
                        }
                    },
                }
            } as const,
            menuSN: {
                path: 'menu-sn',
                children: {

                    users: {
                        path: 'users',
                        children: {
                            list: '',
                            userDetails: {
                                path: ':userId',
                                routeParam: ':userId'
                            }
                        }
                    },
                    groupContainer: {
                        path: 'group-container',
                        children: {
                            list: 'list',
                            edit: 'edit',
                            create: 'create',
                            idNS: ':idNS'
                        }
                    },
                    groupsListContainer: {
                        path: 'groups-list-container',
                        children: {
                            list: 'list',
                            edit: 'edit',
                            create: 'create',
                            idNS: ':idNS'
                        }
                    },
                    shareServices: {
                        path: 'share-services',
                        children: {
                            list: 'list',
                            edit: 'edit',
                            create: 'create',
                            idNS: ':idNS'
                        }
                    },
                    shareFacecompany: {
                        path: 'share-facecompany',
                        children: {
                            list: 'list',
                            edit: 'edit',
                            create: 'create',
                            idNS: ':idNS',
                        }
                    },
                    userSettings: {
                        path: 'user-settings'
                    },
                    userProfiles: {
                        path: 'user-profiles',
                        children: {
                            list: 'list',
                            edit: 'edit',
                            create: 'create',
                            idNS: ':idNS'
                        }
                    }
                }
            } as const,
            security: {
                path: 'security',
                children: {
                    tasks: {
                        path: 'tasks',
                        children: {
                            edit: {
                                path: ":idNS/edit",
                                routeParam: ":idNS"
                            },
                            create: 'create',
                        }
                    },
                },
            },
            finance: {
                path: 'finance',
                children: {
                    financialBilling: {
                        path: 'financial-billing',
                        children: {}
                    },
                }
            },
            conversationalCommerce: {
                path: 'conversational-commerce',
                children: {
                    catalog: {
                        path: 'catalog',
                        children: {
                            edit: {
                                path: "edit",
                                routeParam: ":id"
                            },
                            create: 'create',
                        }
                    },
                    integration,
                    forms,
                },
            },
            analytics: {
                path: 'analytics',
                children: {
                    attendance: {
                        path: "attendance",
                        children: {
                            view: "report-id/:reportId"
                        }
                    },
                    agent: {
                        path: "agent",
                        children: {
                            view: "report-id/:reportId"
                        }
                    },
                    bot: {
                        path: "bot",
                        children: {
                            view: "report-id/:reportId"
                        }
                    },
                    forms: {
                        path: "forms",
                        children: {
                            view: "report-id/:reportId"
                        }
                    },
                    lgpd: {
                        path: "lgpd",
                        children: {
                            view: "report-id/:reportId"
                        }
                    },
                    macroView: {
                        path: "macroView",
                        children: {
                            view: "report-id/:reportId"
                        }
                    },
                    marketing: {
                        path: "marketing",
                        children: {
                            view: "report-id/:reportId"
                        }
                    },
                    "analytics-ai": {
                        path: "analytics-ai",
                        children: {
                            view: "report-id/:reportId"
                        }
                    },
                    "custom-reports": {
                        path: "custom-reports",
                        children: {
                            view: "report-id/:reportId"
                        }
                    },
                    "corporate-attendance": {
                        path: "corporate-attendance",
                        children: {
                            view: "report-id/:reportId"
                        }
                    },
                    "corporate-marketing": {
                        path: "corporate-marketing",
                        children: {
                            view: "report-id/:reportId"
                        }
                    },
                    crm: {
                        path: "crm",
                        children: {
                            view: "report-id/:reportId"
                        }
                    },
                }
            },
            analyticsHistory: {
                path: 'analytics-history',
                children: {
                    attendance: {
                        path: "attendance",
                        children: {
                            view: "report-id/:reportId"
                        }
                    },
                    agent: {
                        path: "agent",
                        children: {
                            view: "report-id/:reportId"
                        }
                    },
                    bot: {
                        path: "bot",
                        children: {
                            view: "report-id/:reportId"
                        }
                    },
                    forms: {
                        path: "forms",
                        children: {
                            view: "report-id/:reportId"
                        }
                    },
                    lgpd: {
                        path: "lgpd",
                        children: {
                            view: "report-id/:reportId"
                        }
                    },
                    macroView: {
                        path: "macroView",
                        children: {
                            view: "report-id/:reportId"
                        }
                    },
                    marketing: {
                        path: "marketing",
                        children: {
                            view: "report-id/:reportId"
                        }
                    },
                    "analytics-ai": {
                        path: "analytics-ai",
                        children: {
                            view: "report-id/:reportId"
                        }
                    },
                    "custom-reports": {
                        path: "custom-reports",
                        children: {
                            view: "report-id/:reportId"
                        }
                    },
                    "corporate-attendance": {
                        path: "corporate-attendance",
                        children: {
                            view: "report-id/:reportId"
                        }
                    },
                    "corporate-marketing": {
                        path: "corporate-marketing",
                        children: {
                            view: "report-id/:reportId"
                        }
                    },
                    crm: {
                        path: "crm",
                        children: {
                            view: "report-id/:reportId"
                        }
                    },
                }
            },
            tools: {
                path: 'tools',
                children: {
                    dataExtractor: {
                        path: 'data-extractor',
                        children: {
                            list: { path: 'list' },
                            create: { path: 'create' },
                            details: {
                                path: ':idExtraction/details',
                                routeParam: ':idExtraction'
                            },
                        }
                    },
                    asyncProcessors: {
                        path: 'async-processors',
                        children: {
                            list: { path: 'list' },
                        }
                    }
                },
            },

            marketingPerformance: {
                path: 'marketing-performance',
                children: {
                    businessAccount: {
                        path: 'business-account',
                        children: {
                            list: 'list',
                        },
                    },
                    ads: {
                        path: 'ads',
                        children: {
                            list: 'list',
                        },
                    },
                }
            }
        }
    } as const
} as const;

const typedRouteList: TRouteListPathContainer = routeList;

export type TMapClientRouteToIdMenu = Map<DeepFindValuesOfType<typeof routeList, string>, string>;

export function replaceDot(route: string): string {
    return route.replace('.', '/');
}

export interface IRouteControl {
    enterGroup?: boolean;
    specialFeature?: boolean;
    logout?: boolean;
};

export interface IRouteSet {
    [routeID: string]: IRouteControl;
};

export const routSet: IRouteSet = {
    [routeID.groups.chat]: { enterGroup: true },
};

export const ROUTES_PARAMS = {
    idGroup: ':idGroup',
};

export function serverRoute(path: string) {
    return `${environment.ROOT_URL}${path}`;
}

export function generateBPMRoutes(path: string) {
    return {
        path,
        children: bpmBotsChildrenPathsConfigs
    }
}
