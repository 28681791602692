import { Component, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';

@Component({
  selector: 'app-scrolling-paginator',
  templateUrl: './scrolling-paginator.component.html',
  styleUrl: './scrolling-paginator.component.scss'
})
export class ScrollingPaginatorComponent {
    @Input() disabled = false;
    @Input() height: string;

    @Input() isLoading  = false;
    @Output() loadMore = new EventEmitter<void>();

    @ViewChild('scrollContainer') scrollContainer!: ElementRef<HTMLElement>;

    onScroll(event: Event): void {
        if (this.disabled) {
            return;
        }
      const target = event.target as HTMLElement;

      const scrollPosition = target.scrollTop + target.clientHeight;
      const scrollHeight = target.scrollHeight;

      if (scrollPosition >= (scrollHeight * 0.90) && !this.isLoading ) {
        this.loadMore.emit(); // Trigger loadMore event
      }
    }

    // private checkScrollability(): void {
    //     if (!this.isLoading ) {
    //         const container = this.scrollContainer.nativeElement;
    //         const isScrollable = container.scrollHeight > container.clientHeight;
    //         if (!isScrollable) {
    //             this.loadMore.emit(); // Trigger loadMore to load additional items
    //         }
    //     }
    // }
}
