<header class="title-heading">
  <span>
    Conversas
  </span>

  <chat-options></chat-options>
</header>

<app-attendance-broadcasting-catcher *ngIf="hasAnyBroadcastIsland()"></app-attendance-broadcasting-catcher>

<div class="bar-main-content">
  <div [visible-horizontal]="channelClosedCount > 0">
    <app-attendance-type-filters (filterSelected)="onSelectFilter($event)" [channelClosedCount]="channelClosedCount"></app-attendance-type-filters>
  </div>

  <app-group-order-and-search *ngIf="showGroupOrderSelector()" [(searchToken)]="searchToken" (onSearch)="emitSearch()"></app-group-order-and-search>

  <div class="all-talking" [ngClass]="{'restricted-width': restrictedWidth}">
    <div class="group-list-container">
      <cdk-virtual-scroll-viewport
        style="height: 100%;"
        itemSize="76"
      >
        <ng-container *cdkVirtualFor="let handler of filteredShortcuts; trackBy: groupTrackBy; templateCacheSize: 10">
          <a
            class="group-list clickable"
            (click)="goToGroupChat($event, handler.getGroup())"
            [href]="getURL(handler)"
          >
            <app-group-selector-shortcut
              [handler]="handler"
              [restrictedWidth]="restrictedWidth"
              [isCurrentGroup]="isGroupSelected(handler.getGroup())"
            >
            </app-group-selector-shortcut>
          </a>
        </ng-container>
      </cdk-virtual-scroll-viewport>
    </div>
  </div>
</div>
