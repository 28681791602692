import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnChanges, OnInit, Renderer2, ViewContainerRef, ViewEncapsulation } from "@angular/core";
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";
import { BBCode } from "@colmeia/core/src/shared-business-rules/bbcode/bbcode-main";
import { EBBCodeStyles } from "@colmeia/core/src/shared-business-rules/bbcode/bbcode-types";
import { WhatsAppParser } from "@colmeia/core/src/shared-business-rules/social-media/whatsapp-constants";

const urlRegex = /(https?:\/\/)?(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&\/=]*)/;

@Component({
    selector: "app-bbcode-viewer",
    templateUrl: "./bbcode-viewer.component.html",
    styleUrls: ["./bbcode-viewer.component.scss"],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class BBCodeViewerComponent implements OnInit, OnChanges {

    @Input() text: string;
    @Input() style?: { [key in EBBCodeStyles]?: Partial<CSSStyleDeclaration> };
    html: string;
    safeHtml: SafeHtml
    @Input() searchTerm: string = '';
    @Input() caseSensitive: boolean = false;
    @Input() isWhatsApp: boolean = false;
    @Input() smallSizeClass: string = '';
    
    constructor(
        private cdr: ChangeDetectorRef,
        private sanitizer: DomSanitizer,
        ) { }

    public ngOnInit() {
        this.sanitizeHTML();
        console.log('smallSize', this.smallSizeClass);
    }

    public ngOnChanges() {
        this.sanitizeHTML();
    }

    private sanitizeHTML() {
        this.html = BBCode.parseHTML(this.isWhatsApp
            ? WhatsAppParser.formatMessage(this.text)
            : this.text
        );
        this.applyHighlights();
        this.html = this.html.replace(urlRegex, (url) => {
            return `<a href="${url}" target="_blank">${url}</a>`
        });
        this.safeHtml = this.sanitizer.bypassSecurityTrustHtml(this.html);
        this.cdr.markForCheck();
    }

    private applyHighlights() {
        if (this.searchTerm) {
            const flags = this.caseSensitive ? 'g' : 'gi';
            const regex = new RegExp(`(${this.searchTerm})`, flags);
            this.html = this.html.replace(regex, '<span class="highlighted-text" #highlightedText>$1</span>');
        }
    }
}
