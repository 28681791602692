import { TNserValidator } from "../validate-nser";
import { FriendlyMessage } from "../../../../error-control/friendly-message";
import { screenOptionToError } from "../error-messages";
import { isValidRef, isValidRegex, isValidString, keys } from "../../../../tools/utility";
import { corporateSearchErrors } from "../error-fields";
import { defaultNserValidator } from "./nser";
import { EESTypes, EPostgresDataTypes, ICorporateSearchBodyServer, ICorporateSearchHeaderServer, ICSDisplayField, ICSearchFieldConfiguration, ICSSortField, TICSDisplayFieldArray, TICSearchFieldConfigurationArray, TICSSortFieldArray } from "../../../corporate-search/corporate-search-model";
import { isValidContentArray } from "../../../bot/asset-functions";

const errors = screenOptionToError(corporateSearchErrors);

export const corporateSearchHeaderValidator: TNserValidator = (nser: ICorporateSearchHeaderServer, friendly: FriendlyMessage) => {
  defaultNserValidator(nser, friendly);

    if(!isValidString(nser.idMetadata)) {
      friendly.addReturnResponse(errors.emptyMetadata);
    }

    // if(!isValidString(nser.idDatabase)) {
    //   friendly.addReturnResponse(errors.emptyDatabase);
    // }

    if(!isValidHeaderSearchFieldArray(nser.configureFieldList)) {
      friendly.addReturnResponse(errors.searchFieldsInvalid);
    }


    return friendly;
};
  export const corporateSearchBodyValidator: TNserValidator = (nser: ICorporateSearchBodyServer, friendly: FriendlyMessage) => {
    defaultNserValidator(nser, friendly);

    if(!isValidRef(nser.returningField)) {
      friendly.addReturnResponse(errors.emptyReturnField);
    }

    if(!isValidContentArray(nser.title)) {
      friendly.addReturnResponse(errors.invalidTitle);
    }

    // if(!isValidContentArray(nser.messageOnSuccess)) {
    //   friendly.addReturnResponse(errors.invalidSuccessMessage);
    // }

    if(!isValidContentArray(nser.messageOnEmptyResult)) {
      friendly.addReturnResponse(errors.invalidEmptyResultMessage);
    }

    // if(!isValidSearchFieldArray(nser.searchFields)) {
    //   friendly.addReturnResponse(errors.searchFieldsInvalid);
    // }

    // if(!isValidSearchFieldArray(nser.searchFields)) {
    //   friendly.addReturnResponse(errors.searchFieldsInvalid);
    // }

    if(!isValidDisplayFields(nser.displayFields)) {
      friendly.addReturnResponse(errors.displayFieldsInvalid);
    }

    if(!isValidSortByArray(nser.sortBy)) {
      friendly.addReturnResponse(errors.sortByInvalid);
    }

    return friendly;
}

const allESTypesMap: Record<EESTypes, boolean> = {
  [EESTypes.date]: true,
  [EESTypes.geo_point]: true,
  [EESTypes.keyword]: true,
  [EESTypes.text]: true,
  [EESTypes.ZipCode]: true,
  [EESTypes.long]: true,
};

const allESTypesArray: EESTypes[] = keys(allESTypesMap);

const allPostgresDataTypesMap: Record<EPostgresDataTypes, boolean> = {
    [EPostgresDataTypes.text]: true,
    [EPostgresDataTypes.bigint]: true,
    [EPostgresDataTypes.date]: true,
    [EPostgresDataTypes.point]: true,
};

const allPostgresDataTypesArray: EPostgresDataTypes[] = keys(allPostgresDataTypesMap);

function isValidHeaderSearchField(searchField: ICSearchFieldConfiguration): boolean {
  // if(!searchField.useOnSearch) {
  //   return true;
  // }

  if(searchField.type === EESTypes.ZipCode && !isValidRegex(searchField.zipCodeRegex)) {
    return false;
  }

  return (
    isValidString(searchField.idPropertyField) &&
    allESTypesArray.includes(searchField.type as EESTypes) ||
    allPostgresDataTypesArray.includes(searchField.type as EPostgresDataTypes)
  );
}

function isValidHeaderSearchFieldArray(searchFields: TICSearchFieldConfigurationArray): boolean {
  return searchFields.every(isValidHeaderSearchField);
}

function isValidDisplayField(displayField: ICSDisplayField): boolean {
  return isValidString(displayField.idPropertyField) && isValidString(displayField.columnName);
}

export function isValidDisplayFields(displayFields: TICSDisplayFieldArray): boolean {
  return displayFields.every(isValidDisplayField);
}

function isValidSortByField(sortBy: ICSSortField): boolean {
  return isValidString(sortBy.idPropertyField) && isValidRef(sortBy.ascending);
}

function isValidSortByArray(sortBy: TICSSortFieldArray): boolean {
  return sortBy.every(isValidSortByField);
}
