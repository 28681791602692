import { Component, Optional } from "@angular/core";
import { EListNonSerializablesSortOrder } from "@colmeia/core/src/dashboard-control/dashboard-request-interfaces";
import { apiRequestType } from "@colmeia/core/src/request-interfaces/message-types";
import { IRequest } from "@colmeia/core/src/request-interfaces/request-interfaces";
import {
    INewAgentCallBatch,
    INewAgentCallBatchServer,
    INewAgentCallContactServer
} from "@colmeia/core/src/shared-business-rules/active-1x1-call/active-1x1-model";
import { EDefaultTag } from "@colmeia/core/src/shared-business-rules/colmeia-tags/tags";
import { gTranslations } from "@colmeia/core/src/shared-business-rules/const-text/translations";
import {
    ENonSerializableObjectType,
    INonSerializable,
} from "@colmeia/core/src/shared-business-rules/non-serializable-id/non-serializable-id-interfaces";
import { ETooltipInfoKey } from "@colmeia/core/src/shared-business-rules/tooltip-json/tooltip-json.model";
import { isInvalidArray, isValidRef } from "@colmeia/core/src/tools/barrel-tools";
import { AttendanceActiveCallEditComponent } from "app/components/dashboard/dashboard-attendance-active-call/attendance-active-call-edit/attendance-active-call-edit.component";
import { ColmeiaWindowRef } from "app/components/dashboard/dashboard-foundation/colmeia-window/colmeia-window-ref";
import { ColmeiaWindowService } from "app/components/dashboard/dashboard-foundation/colmeia-window/colmeia-window.service";
import { RootComponent } from "app/components/foundation/root/root.component";
import { AttendanceActiveEditHelperService } from "app/services/attendance-active-edit-call-init.service";
import { DashBoardService } from "app/services/dashboard/dashboard.service";
import { GenericDashboardRequester } from "app/services/generic-dashboard-requester.service";
import { RoutingService } from "app/services/routing.service";
import { take } from "rxjs/operators";
import { v4 as uuidv4 } from 'uuid';
import { GenericTableComponent } from "../../call-center/generic-table/generic-table.component";
import { DynamicDialogComponent } from "../../dashboard-data-extractor/dynamic-dialog/dynamic-dialog.component";
import {
    EGenericDashboardColumnType,
    IDashboardClientCallback,
    IGenericDashboardIDColumn,
    IGenericDashboardRequestParameters,
    IGenericDashboardTextColumn,
    IGenericHomeRow,
} from "../../dashboard-foundation/generic-dashboard-home/generic-dashboard-home.model";
import { GenericHomeHandler } from "../../dashboard-foundation/generic-dashboard-home/generic-home.handler";
import { AttendanceActiveCallNewContactListComponent } from "../attendance-active-call-new-contact-list/attendance-active-call-new-contact-list.component";
import { AttendanceActiveCallNewContactListHandler } from "../attendance-active-call-new-contact-list/attendance-active-call-new-contact-list.handler";
import { DashboardActiveCallInfoComponent } from "../dashboard-active-call-info/dashboard-active-call-info.component";
import { SnackMessageService } from "app/services/snack-bar";

@Component({
    selector: "app-attendance-active-call",
    templateUrl: "./attendance-active-call.component.html",
    styleUrls: ["./attendance-active-call.component.scss"],
})
export class AttendanceActiveCallComponent extends RootComponent<"activeCall"> {
    public genericHome: GenericHomeHandler;
    public nsType: ENonSerializableObjectType =
        ENonSerializableObjectType.activeCallBatch;

    public isInWindow: boolean = false;

    public constructor(
        private routingSvc: RoutingService,
        private requester: GenericDashboardRequester,
        @Optional() private colmeiaWindowRef: ColmeiaWindowRef<AttendanceActiveCallComponent>,
        private attendanceActiveEditCallInitService: AttendanceActiveEditHelperService,
        private colmeiaWindow: ColmeiaWindowService,
        private dashboardSvc: DashBoardService,
        private snackBarMessageSvc: SnackMessageService,
    ) {
        super({
            activeCall: gTranslations.activeCall.activeCall,
        });
        this.attendanceActiveEditCallInitService.setComponents({
            dynamicDialog: DynamicDialogComponent,
            genericTable: GenericTableComponent,
            activeCallInfo: DashboardActiveCallInfoComponent,
        });
        this.isInWindow = isValidRef(colmeiaWindowRef);
    }

    public ngOnInit(): void {
        this.init();
    }

    public init(): void {
        this.initGenericHomeHandler();
    }

    public initGenericHomeHandler(): void {
        this.genericHome = new GenericHomeHandler({
            nsType: this.nsType,
            defaultTag: EDefaultTag.serviceAttendent,
            fixedRowCallbacks: {
                custom: [{
                    custom: {
                        icon: 'send',
                        text: 'Enviar',
                    },
                    callback: async (nser: INewAgentCallBatchServer) => {
                        this.sendToAllContacts(nser);
                    },
                    show: (nser: INewAgentCallBatchServer) => {
                        return !!nser.idCampaignAction
                    }
                }],
                edit: {
                    callback: (ns) => this.onEditClick(ns),
                },
                checkDependencies: true,
                delete: true,
                copyID: true,
            },
            fixedCallbacks: {
                create: {
                    callback: this.onCreateClick,
                },
            },
            labels: {
                title: this.isInWindow ? 'Listas de envio' : this.translations.activeCall.value,
                helpTipKey: ETooltipInfoKey.attendanceActiveCall
            },
            forcedTitles: ["Nome", "Id da Ação de Campanha"],
            clientCallback: <IDashboardClientCallback>this,
            ordination: {
                default: {
                    clockTick: EListNonSerializablesSortOrder.Descending,
                },
                allow: true
            }
        });
    }

    private async sendToAllContacts(nser: INewAgentCallBatchServer) {
        const children = await this.dashboardSvc.genericNonSerializableService.getChildren(nser.idNS, ENonSerializableObjectType.activeCall1x1);

        if (isInvalidArray(children)) {
            this.snackBarMessageSvc.openWarning("A lista esta vazia.");
            return;
        }

        await this.attendanceActiveEditCallInitService.sendForContactsSelection({ idParent: nser.idNS, nsers: children as INewAgentCallContactServer[], selectedCampaignAction: nser.idCampaignAction });
    }

    public onEditClick = async (ns: INonSerializable): Promise<void> => {
        if (isValidRef(this.colmeiaWindowRef)) {
            // abrir outro dialog
            this.colmeiaWindow.open(AttendanceActiveCallEditComponent, {
                data: { 
                    idActiveBatch: ns.idNS, 
                    refreshList: () => this.genericHome.refresh() 
                },
                width: "80vw",
                height: "80vh",
                windowIdentifier: 'chat-options-active-call-att-edit',
                title: 'Lista de contatos',
                group: 'Chamadas ativas'
            });
        } else {
            this.routingSvc.goToActiveCall(ns.idNS);
        }
    };

    public onCreateClick = async (): Promise<void> => {
        await this.createNewContactList();
        this.genericHome.refresh();
    };

    public async createNewContactList(): Promise<INewAgentCallBatchServer | void> {
        const handler: AttendanceActiveCallNewContactListHandler =
            AttendanceActiveCallNewContactListHandler.factory({
                clientCallback: undefined,
            });
       
        const windowRef: ColmeiaWindowRef = this.colmeiaWindow.open<
        AttendanceActiveCallNewContactListComponent, 
        AttendanceActiveCallNewContactListHandler
        >(AttendanceActiveCallNewContactListComponent, {
            group: 'Chamadas Ativas',
            title: 'Nova Lista de envio',
            data: handler,
            panelClass: ["average-size"],
            windowIdentifier: uuidv4(),
        });
        windowRef.afterClosed().pipe(take(1)).subscribe((item: INonSerializable) => {
            if (item) {
                this.genericHome?.refresh();
                if (this.genericHome) {

                    // Open the created list
                    this.onEditClick(item)
                }
            }
        });   
        return handler.getComponentParameter().batchNS;
    }

    public getRequest({
        taggable,
    }: IGenericDashboardRequestParameters): IRequest {
        return this.requester.getRequestForNS({
            apiRequestType:
                apiRequestType.dashboardExtra.attendance.activeCallBatch,
            nsType: this.nsType,
            cursor: null,
            taggable,
        });
    }

    public mapEntity = (nser: INonSerializable): IGenericHomeRow => {
        return {
            nser,
            columns: [
                <IGenericDashboardTextColumn>{
                    type: EGenericDashboardColumnType.Text,
                    value: nser.nName,
                    title: "Nome",
                    isItemName: true,
                },
                <IGenericDashboardIDColumn>{
                    type: EGenericDashboardColumnType.ID,
                    value: (nser as INewAgentCallBatch).idCampaignAction,
                    title: "Id da Ação de Campanha",
                },
            ],
        };
    };
}
