import { I360Media, T360MediaArray } from '../../../core-constants/bot';
import { IBotActionAsset, IBotActionAssetContainer, TIBotActionAssetContainerArray } from '../../bot/bot-action-model';
import { NSGenericHost } from '../../graph-transaction/host/ns/ns.host';
import { IAddedConnection, INodeDeletedConnection } from '../../graph-transaction/host/ns/ns.host.types';
import { TReferencedObject, TReferencedObjectArray } from '../../graph-transaction/toolbar/config-toolbar.types';
import { IGraphConnectionData, TGraphPropertyData, TIGraphConnectionDataArray } from './graph-types';
import { HostObject, IHostObjectInterface } from './host-object';

export const dummyHostObjectType = 'dummy';

export class DummyHostObject<T> extends HostObject {
    private id: string;

    constructor() {
        super(null);
        this.id = undefined//getUniqueStringID();
    };

    public addElementAction(type: TReferencedObject, hostedId: string): void {
        throw new Error('Method not implemented.');
    }
    public mustUpdateConnections(connectionType: TReferencedObject): boolean {
        throw new Error('Method not implemented.');
    }
    public getConnectionTargetData(): TIGraphConnectionDataArray {
        throw new Error('Method not implemented.');
    }
    public deleteConnectionTo(anotherHost: NSGenericHost, connectionType: TReferencedObject): INodeDeletedConnection {
        throw new Error('Method not implemented.');
    }
    public addConnectionTo(target: NSGenericHost, predicateType: TReferencedObject): IAddedConnection {
        throw new Error('Method not implemented.');
    }

    public isActionAvailableByConnectingManuallyToOtherNode(): boolean {
        throw new Error('Method not implemented.');
    }
    public getActionAvailableByConnectingManuallyToOtherNode(): TReferencedObject {
        throw new Error('Method not implemented.');
    }

    public isParenthoodPredicateDrawable(targetType: TReferencedObject): boolean {
        throw new Error('Method not implemented.');
    }

    public isActionPredicateDrawable(targetType: TReferencedObject, action: TReferencedObject): boolean {
        throw new Error('Method not implemented.');
    }

    public setAction(newMenuItemActionAsset: IBotActionAsset): void {
        throw new Error('Method not implemented.');
    }

    public getActionContainer(): TIBotActionAssetContainerArray {
        throw new Error('Method not implemented.');
    }
    public canConnectTo(toType: TReferencedObject): boolean {
        throw new Error('Method not implemented.');
    }

    public isPredicateDrawable(): boolean {
        throw new Error('Method not implemented.');
    }

    public getPredicateTypesFromState(): TReferencedObjectArray {
        throw new Error('Method not implemented.');
    }

    public onDragged(host: IHostObjectInterface): void {
        throw new Error("Method not implemented.");
    }
    public onDragIntoMe(hostObject: IHostObjectInterface): void {
        throw new Error("Method not implemented.");
    }
    public onRemove(hostObject: IHostObjectInterface): void {
        throw new Error("Method not implemented.");
    }
    public isDraggableIntoMe(nodeTypeToDrag: TReferencedObject, edgesToOthersCount: number): boolean {
        throw new Error('Method not implemented.');
    }
    public getHostedObject<T>(): T { return <T>null; };
    public isDummy(): boolean { return true };
    public getHostedID(): string { return this.id; }
    public getMedias(): T360MediaArray { return [] }
    public getHostedMedia(): I360Media { return null; }
    public getHostedType(): string { return null; }
    public getHostedSubType(): TReferencedObject { return null; }
    public getHostedName(): string { return dummyHostObjectType }
    public getHostedValue<T extends TGraphPropertyData>(): T { return null; }
    public isEditable(): boolean { return false; };
    public toJSON(): Object {
        return null;
    }

    public rehydrate(json: Object) {

    }

    public getSequencialPosition() { return null; }
    public handleRemoveSubTree() { return [] }
};

