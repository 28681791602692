import { Component, Inject, OnDestroy, OnInit, Optional, ViewChild } from "@angular/core";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { ActivatedRoute } from "@angular/router";
import { IListNonSerializablesRequest } from "@colmeia/core/src/dashboard-control/dashboard-request-interfaces";
import { apiRequestType } from "@colmeia/core/src/request-interfaces/message-types";
import { INewAgentCallContactItem, ISearchActiveCallResult } from "@colmeia/core/src/shared-business-rules/active-1x1-call/active-1x1-model";
import { EDefaultTag } from "@colmeia/core/src/shared-business-rules/colmeia-tags/tags";
import { IContactListItemServer, IContactListServer } from "@colmeia/core/src/shared-business-rules/contact-list/contact-list.model";
import { ENonSerializableObjectType } from "@colmeia/core/src/shared-business-rules/non-serializable-id/non-serializable-id-interfaces";
import { CustomerFinderComponent } from "app/components/customer-finder/customer-finder.component";
import { GenericDashboardEditHandler } from "app/handlers/generic-dashboard-edit.handler";
import { SubscriptionGroup } from "app/model/client-utility";
import { routeList } from "app/model/routes/route-constants";
import { AttendanceActiveEditHelperService } from "app/services/attendance-active-edit-call-init.service";
import { RequestBuilderServices } from "app/services/request-builder.services";
import { RoutingService } from "app/services/routing.service";
import { AttendanceConversationHistorySearchComponent } from "../../attendance-conversation-history-search/attendance-conversation-history-search.component";
import { EGenericDashboardColumnType, IDashboardClientCallback, IGenericDashboardRequestParameters, IGenericDashboardTextColumn, IGenericHomeRow } from "../../dashboard-foundation/generic-dashboard-home/generic-dashboard-home.model";
import { GenericHomeHandler } from "../../dashboard-foundation/generic-dashboard-home/generic-home.handler";
import { ContactListViewItemComponent, IContactListViewItemParameters, IContactListViewItemResponse } from "../contact-list-view-item/contact-list-view-item.component";
import { ContactListService } from "../contact-list.service";
import { GenericDashboardRequester } from "app/services/generic-dashboard-requester.service";
import { ISaveGenericNSRequest } from "@colmeia/core/src/request-interfaces/lookup-ns-request";
import { IResponse } from "@colmeia/core/src/request-interfaces/response-interfaces";
import { clientConstants } from "app/model/constants/client.constants";

export interface IContactListViewDialogParameters {
    nser: IContactListServer
}

@Component({
    selector: "contact-list-view",
    styleUrls: ["./contact-list-view.component.scss"],
    templateUrl: "./contact-list-view.component.html",
})
export class ContactListViewComponent implements OnInit, OnDestroy, IDashboardClientCallback {

    public nsType: ENonSerializableObjectType = ENonSerializableObjectType.contactList;
    public genericEditHandler: GenericDashboardEditHandler;
    public nser: IContactListServer;
    private isDialog: boolean = false;
    public isLoading: boolean = true;

    private subscriptions: SubscriptionGroup = new SubscriptionGroup();
    contactsHomeHandler: GenericHomeHandler<IContactListItemServer>;

    @ViewChild("attConvHistory") attConvHistory: AttendanceConversationHistorySearchComponent;

    constructor(
        @Optional() private dialogRef: MatDialogRef<ContactListViewComponent>,
        @Optional() @Inject(MAT_DIALOG_DATA) private dialgoData: IContactListViewDialogParameters,
        @Optional() private activatedRoute: ActivatedRoute,
        private rbs: RequestBuilderServices,
        private routeSvc: RoutingService,
        private contactListSvc: ContactListService,
        private dialogSvc: MatDialog,
        private activeCallInitSvc: AttendanceActiveEditHelperService,
        private requester: GenericDashboardRequester,
    ) {
        this.isDialog = !!dialogRef;
    }

    async ngOnInit() {
        await this.loadConatctListNser();
        this.initGenericEditHandler();
        this.initGenericHome();
        this.isLoading = false;
        this.attConvHistory.isInDialog = true;
        this.attConvHistory.ngOnInit();
    }

    private async loadConatctListNser() {
        if (this.isDialog) {
            this.nser = this.dialgoData.nser;
            return;
        }

        const idNSContactList: string = this.activatedRoute.snapshot.paramMap.get(routeList.dashboard.children.serviceStatus.children.contactList.children.details.routeParam.slice(1))

        this.nser = await this.contactListSvc.getContactList(idNSContactList);
    }

    private initGenericEditHandler() {
        this.genericEditHandler = new GenericDashboardEditHandler({
            nsType: this.nsType,
            clientCallback: this,
            nser: this.nser,
            allowEditTitleInHeader: true,
            autoValidate: true,
        })
    }


    async onGenericSaveButtonPressed(): Promise<boolean> {
        const request: ISaveGenericNSRequest = {
            ...this.rbs.secureBasicRequest(apiRequestType.nonSerializable.genericNSSave),
            nserList: [this.nser]
        };

        return this.requester.request(
            request,
            false
        ).then((response: IResponse) => {
            const success = !!response;
            return success;
        });
    }

    private initGenericHome() {
        this.contactsHomeHandler = new GenericHomeHandler<IContactListItemServer>({
            loadPlaceholdRowsLength: this.isDialog ? 3 : undefined,
            defaultTag: EDefaultTag.serviceAttendent,
            labels: {
                title: "Contatos",
            },
            fixedCallbacks: {
                create: {
                    custom: {
                        text: "Adicionar",
                        icon: "add",
                    },
                    callback: () => {
                        this.openAddContact();
                    }
                }
            },
            fixedRowCallbacks: {
                edit: {
                    callback: async (item) => {
                        this.viewContact(item);
                    },
                },
                delete: true,
                copyID: true,
                custom: [
                    {
                        custom: {
                            icon: "notifications_active",
                            text: "Chamada ativa"
                        },
                        callback: async (contact) => this.openActiveCallInfo(contact)
                    },
                    {
                        custom: {
                            icon: "forum",
                            text: "Histórico de conversação"
                        },
                        callback: async (contact) => this.openConversationHistory(contact)
                    },
                    {
                        custom: {
                            icon: "playlist_add",
                            text: "Adiciona a lista de envio"
                        },
                        callback: async (contact) => {
                            this.addContactToList(contact);
                        }
                    }
                ]
            },
            nsType: this.nsType,
            clientCallback: this
        })
    }

    private viewContact(contact: IContactListItemServer) {
        const dialogRef = this.dialogSvc.open<ContactListViewItemComponent, IContactListViewItemParameters, IContactListViewItemResponse>(ContactListViewItemComponent, {
            data: { contact },
        });

        dialogRef.afterClosed().subscribe((response) => {
            if (response?.changed) {
                this.contactsHomeHandler.refresh();
            }
        });
    }

    mapEntity = (nser: IContactListItemServer): IGenericHomeRow => {
        return {
            nser,
            columns: [
                <IGenericDashboardTextColumn>{
                    type: EGenericDashboardColumnType.Text,
                    value: nser.nName,
                    title: 'Nome',
                    isItemName: true,
                },
                <IGenericDashboardTextColumn>{
                    type: EGenericDashboardColumnType.Text,
                    value: nser.contactDetail.target,
                    title: 'Alvo',
                    valueMask: clientConstants.UI.phoneMask,
                },
            ]
        };
    };

    private openActiveCallInfo(contact: IContactListItemServer) {
        this.activeCallInitSvc.openActiveCallInfo(
            {
                idParentContactList: undefined, contactNS: {
                    nName: contact.nName,
                    target: contact.contactDetail.target,
                    channel: contact.contactDetail.channel,
                    name: contact.nName,
                    idAvatar: contact.contactDetail.idAvatar,
                    email: "",
                    idSocialKey: contact.contactDetail.idSocialKey
                } as INewAgentCallContactItem
            },
        );
    }

    private openConversationHistory(contact: IContactListItemServer) {
        this.attConvHistory.handleCustomerClicked({
            target: contact.contactDetail.target,
            channel: contact.contactDetail.channel,
            name: contact.nName,
            idAvatar: contact.contactDetail.idAvatar,
            email: "",
            idSocialKey: contact.contactDetail.idSocialKey
        });
    }

    private addContactToList(contact: IContactListItemServer) {
        this.contactListSvc.addContactToSendLists({
            name: contact.nName,
            target: contact.contactDetail.target,
            channel: contact.contactDetail.channel,
            idAvatar: contact.contactDetail.idAvatar,
        });
    }

    getRequest({ taggable }: IGenericDashboardRequestParameters): IListNonSerializablesRequest {
        return {
            ...this.rbs.secureBasicRequestForCurrentSN(apiRequestType.nonSerializable.list),
            requestType: apiRequestType.nonSerializable.list,
            nsType: ENonSerializableObjectType.contactListItem,
            taggable: {
                demandedTag: null,
                searchedTags: taggable.searchedTags,
            },
            idParent: this.nser.idNS,
            multipleCursor: null,
        }
    }

    ngOnDestroy(): void {
        this.subscriptions.destroy();
    }

    async onGenericBackButtonPressed?(): Promise<void> {
        if (this.isDialog) {
            this.dialogRef.close();
            return;
        }

        this.routeSvc.gotoContactList();
    }

    private openAddContact() {
        const dialogRef: MatDialogRef<CustomerFinderComponent> = this.dialogSvc.open(CustomerFinderComponent, {
            panelClass: "avarage-size",
            minWidth: "70vw"
        });

        dialogRef.componentInstance.handler = {
            allowedSearchSources: {
                standard: {},
                infoSquare: {},
            },
            onCustomerSelected: async ([selectedContact]: ISearchActiveCallResult[]) => {
                if (!selectedContact) return;

                const contact = await this.addContact(selectedContact);

                if (!!contact) {
                    dialogRef.close();
                    this.contactsHomeHandler.refresh()
                }
            }
        };
    }

    private async addContact(contact: ISearchActiveCallResult): Promise<IContactListItemServer> {
        return this.contactListSvc.saveContactToList(this.nser, contact.name, {
            target: contact.target,
            idAvatar: contact.idAvatar,
            idSocialKey: contact.idSocialKey,
            channel: contact.channel,
        });
    }

}
