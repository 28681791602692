import { asConst } from "../tools/type-utils";

export enum EIdMultimediaType {
    // sticker = 'sticker',
    icon = '1@8',
    image = '2@8',
    video = '3@8',
    audio = '4@8',
    GIF = '6@8',
    PDF = '7@8',
    Texto = '8@8',
    Planilha = '9@8',
    quicktime = 'image/webp',
    MP3 = 'audio/mp3',
    json = 'application/json',
    vcard = 'text/vcard',
    unknonw = 'MMunknown',
}

export const MMconstant = asConst<{}>()({
    type: {
        ...EIdMultimediaType,
    },
    tag: {
        header: '1@26',
        footer: '2@26',
        photo: '3@26',
        thumbnail: '4@26',
        icone: '5@26',
        default: '6@26',
        banner: 'banner',
        background: 'background',
        voiceMessage: 'voiceMessage',
        csvUpload: 'bulkUploadCSV',
        hexagonon: 'tagHexagonon',
    },
    extension: {
        jpg: 'jpg',
        jpeg: 'jpeg',
        gif: 'gif',
        png: 'png',
        svg: 'svg',
        mp3: 'mp3',
        json: 'json',
        mp4: 'mp4',
        webm: 'webm',
        webp: 'webp',
        wav: 'wav',
    },
    defaultAvatarObejct: '  ',
    defaultGroupObject: '   ',

    thumnailPrefix: 'TH_',
    thumbnailExtension: 'PNG',
    thumbPixels: '200x200',
    mp3suffix: 'mp3',
    profilePhoto: {
        avatar: 'default_photo_avatar.png',
        group: 'default_photo_group.png'
    }
} as const);

export const idMultimediaTypeTransalations: { [key in EIdMultimediaType]: string } = {
    [EIdMultimediaType.icon]: 'Ícone',
    [EIdMultimediaType.image]: 'Imagem',
    [EIdMultimediaType.video]: 'Video',
    [EIdMultimediaType.audio]: 'Audio',
    [EIdMultimediaType.GIF]: 'GIF',
    [EIdMultimediaType.PDF]: 'PDF',
    [EIdMultimediaType.Texto]: 'Texto',
    [EIdMultimediaType.Planilha]: 'Planilha (.xls, .xlsx, .csv)',
    [EIdMultimediaType.quicktime]: 'Quicktime',
    [EIdMultimediaType.MP3]: 'MP3',
    [EIdMultimediaType.json]: 'JSON',
    [EIdMultimediaType.vcard]: 'Vcard',
    [EIdMultimediaType.unknonw]: 'Unknonw',
};


export enum EMultimediaTypeMeta {
    TXT = 'txt',
    TXTV2 = 'text/plain',
    XLS = 'xls',
    XLSX = 'xlsx',
    DOC = 'doc',
    DOCX = 'docx',
    PPT = 'ppt',
    PPTX = 'pptx',
    PDF = 'pdf',
    JPEG = 'jpeg',
    PNG = 'png',
    WEBP = 'webp',
    '3GP' = '3gp',
    MP4 = 'mp4',
    AAC = 'aac',
    AMR = 'amr',
    MP3 = 'mp3',
    M4A = 'm4a',
    OGG = 'ogg',
    CSV = 'text/csv',
}


export const MultimediaTypeMeta: { [key in EIdMultimediaType]: EMultimediaTypeMeta[] } = {
    [EIdMultimediaType.PDF]: [EMultimediaTypeMeta.PDF],
    [EIdMultimediaType.Texto]: [EMultimediaTypeMeta.TXT, EMultimediaTypeMeta.TXTV2],
    [EIdMultimediaType.Planilha]: [
        EMultimediaTypeMeta.XLS,
        EMultimediaTypeMeta.XLSX,
        // EMultimediaTypeMeta.DOC,
        // EMultimediaTypeMeta.DOCX,
        // EMultimediaTypeMeta.PPT,
        // EMultimediaTypeMeta.PPTX,
        EMultimediaTypeMeta.CSV
    ],
    [EIdMultimediaType.icon]: [],
    [EIdMultimediaType.image]: [EMultimediaTypeMeta.JPEG, EMultimediaTypeMeta.PNG],
    [EIdMultimediaType.video]: [EMultimediaTypeMeta["3GP"], EMultimediaTypeMeta.MP4],
    [EIdMultimediaType.audio]: [
        EMultimediaTypeMeta.AAC,
        EMultimediaTypeMeta.AMR,
        EMultimediaTypeMeta.MP3,
        EMultimediaTypeMeta.M4A,
        EMultimediaTypeMeta.OGG
    ],
    [EIdMultimediaType.GIF]: [],
    [EIdMultimediaType.MP3]: [EMultimediaTypeMeta.MP3],
    [EIdMultimediaType.json]: [],
    [EIdMultimediaType.vcard]: [],
    [EIdMultimediaType.unknonw]: [],
    [EIdMultimediaType.quicktime]: [],
};

