import { TISODateString } from "@colmeia/core/src/tools/date/date-utils.types";
import { isValidFunction } from "@colmeia/core/src/tools/utility";
import { IComponentParameter } from "app/model/component-comm/basic";
import {
    DatePickerComponent,
    DateRange,
} from "../components/foundation/date-picker/date-picker.component";
import { MainHandler } from "./main-handler";

export enum EDatePickerMode {
    DATE = "calendar",
    TIME = "timer",
    DATETIME = "both",
    MONTHANDYEAR = 'monthAndYear'
}

export enum EWeekDay {
    MONDAY = 1,
    TUESDAY = 2,
    WEDNESDAY = 3,
    THURSDAY = 4,
    FRIDAY = 5,
    SATURDAY = 6,
    SUNDAY = 0,
}

export enum EHourFormat {
    TWENTYFOUR = 0,
    TWELVEHOURS = 1,
}

export enum EDatePickerType {
    Single = "single",
    Range = "range",
}

export enum EDatePickerColor {
    None,
    White,
    Grey,
}

export type TDateRangeTick = Array<number>;

export type TDatePickerDialgoData = { handler: DatePickerHandler };
export type TDatePickerDialgoReturn = Date | [Date, Date];

export interface IDatePickerHandlerParameter {
    type?: EDatePickerType;
    onPick?: (time: number) => void;
    onRangePick?: (start: number, end: number) => void;
    onPickISODate?: (time: TISODateString) => void;
    onRangePickISODate?: (start: TISODateString, end: TISODateString) => void;
    mode?: EDatePickerMode;
    firstWeekDay?: EWeekDay;
    hourFormat?: EHourFormat;
    current?: number;
    currentRange?: TDateRangeTick;
    min?: number;
    max?: number;
    allowEmptyRangeValues?: boolean;
    showSeconds?: boolean;
    showOtherMonths?: boolean;
    stepHour?: number;
    stepMinute?: number;
    stepSecond?: number;
    enabled?: boolean;
    colorType?: EDatePickerColor;
    directOpen?: boolean;
    labelInputStyle?: Partial<CSSStyleDeclaration>;
    onOpenCallback?: () => void;
    onCloseCallback?: () => void;
    onYearSelectedCallback?: (time: number) => void;
    onMonthSelectedCallback?: (time: number) => void;
    title?: string;
    hideLabel?: boolean;
    smallDateIcon?: boolean;
}

const noop = () => undefined;
const noopTime = (_time: number) => undefined;

type DatePickerHandlerParameter = IDatePickerHandlerParameter &
    IComponentParameter;
export class DatePickerHandler extends MainHandler<DatePickerHandlerParameter> {
    private get parameters(): DatePickerHandlerParameter {
        return super.getComponentParameter();
    }

    constructor(parameter: IDatePickerHandlerParameter) {
        super(parameter as DatePickerHandlerParameter);
    }

    static new(
        parameter: Partial<IDatePickerHandlerParameter>
    ): DatePickerHandler {
        return new DatePickerHandler({
            onPick: (time: number) =>
                console.log("newDatePicker.onPick time: ", time),
            colorType: EDatePickerColor.White,
            ...parameter,
        });
    }

    private _inParameter(key: string): boolean {
        return key in this.parameters;
    }

    get labelInputStyle(): Partial<CSSStyleDeclaration> {
        return this.parameters.labelInputStyle;
    }

    get current(): Date {
        return this.parameters.current
            ? new Date(this.parameters.current)
            : null;
    }

    get currentRange(): DateRange {
        return this.parameters.currentRange
            ? <DateRange>(
                this.parameters.currentRange.map((range) => new Date(range))
            )
            : null;
    }

    getColor(): EDatePickerColor {
        return this.parameters.colorType;
    }

    get directOpen(): boolean {
        return this.parameters.directOpen || false;
    }

    getDatePickerType(): EDatePickerType {
        return this.parameters.type
            ? this.parameters.type
            : EDatePickerType.Single;
    }

    getDataPickerMode(): EDatePickerMode {
        return this.parameters.mode;
    }

    dateChanged(time: number) {
        if ("function" === typeof this.parameters.onPick)
            this.parameters.onPick(time);
    }

    dateRangeChanged(start: number, end: number) {
        if (isValidFunction(this.parameters.onRangePick)) {
            this.parameters.onRangePick(start, end);
        }
        if (isValidFunction(this.parameters.onRangePickISODate)) {
            const startIso = new Date(start).toISOString();
            const endIso = new Date(end).toISOString();

            this.parameters.onRangePickISODate(startIso, endIso);
        }
    }

    onOpenCallback(): () => void {
        return this._inParameter("onOpenCallback")
            ? this.parameters.onOpenCallback
            : noop;
    }

    onCloseCallback(): () => void {
        return this._inParameter("onCloseCallback")
            ? this.parameters.onCloseCallback
            : noop;
    }

    onYearSelectedCallback(): (time: number) => void {
        return this._inParameter("onYearSelected")
            ? this.parameters.onYearSelectedCallback
            : noopTime;
    }

    onMonthSelectedCallback(): (time: number) => void {
        return this._inParameter("onYearSelected")
            ? this.parameters.onMonthSelectedCallback
            : noopTime;
    }

    firstDayOfWeek(): number {
        return this._inParameter("firstWeekDay")
            ? this.parameters.firstWeekDay
            : 0;
    }

    mode(): EDatePickerMode {
        return this._inParameter("mode")
            ? this.parameters.mode
            : EDatePickerMode.DATETIME;
    }

    hourFormat(): boolean {
        return this._inParameter("hourFormat")
            ? !!this.parameters.hourFormat
            : !!EHourFormat.TWENTYFOUR;
    }

    min(): Date | null {
        return this._inParameter("min") ? new Date(this.parameters.min) : null;
    }

    max(): Date | null {
        return this._inParameter("max") ? new Date(this.parameters.max) : null;
    }

    showSeconds(): boolean {
        return this._inParameter("showSeconds")
            ? this.parameters.showSeconds
            : false;
    }

    showOtherMonths(): boolean {
        return this._inParameter("showOtherMonths")
            ? this.parameters.showOtherMonths
            : false;
    }

    stepHour(): number {
        return this._inParameter("stepHour") ? this.parameters.stepHour : 1;
    }

    stepMinute(): number {
        return this._inParameter("stepMinute") ? this.parameters.stepMinute : 1;
    }

    stepSecond(): number {
        return this._inParameter("stepSecond") ? this.parameters.stepSecond : 1;
    }

    enabled(): boolean {
        return this._inParameter("enabled") ? this.parameters.enabled : true;
    }

    open() {
        this.instance.open();
    }

    get isRange(): boolean {
        return this.getDatePickerType() !== EDatePickerType.Single;
    }

    private instance: DatePickerComponent;

    setInstance(instance: DatePickerComponent) {
        this.instance = instance;
    }

    get title() {
        return this.parameters.title || 'Data'
    }

    get hideLabel() {
        return this.parameters.hideLabel || false;
    }

    get smallDateIcon() {
        return this.parameters.smallDateIcon || false;
    }
}
