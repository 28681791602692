<section class="chat-filter-container">
  <header>
    Filtros
  </header>

  <section *ngIf="isDesktop; else mobileView">
    <mat-tab-group mat-align-tabs="center">
      <mat-tab *ngFor="let filter of filters" [label]="filter.name">
        <ng-template matTabContent>
          <ng-container
            *ngComponentOutlet="filter.component; injector: chatFilterInjector"
          ></ng-container>
        </ng-template>
      </mat-tab>
    </mat-tab-group>
  </section>

  <ng-template #mobileView>
    <section class="mobile-chat-filter" *ngFor="let filter of filters">
      <mat-expansion-panel
        [hideToggle]="true"
        (closed)="filter.onClosed()"
        (opened)="filter.onOpened()"
      >
        <mat-expansion-panel-header>
          <mat-panel-title>
            <div
              class="panel-header"
              [ngClass]="{ expanded: filter.isExpanded }"
            >
              <div class="title-container">
                <img [src]="filter.icon" class="title-icon" />
                <div class="title">{{ filter.name }}</div>
              </div>
              <mat-icon>keyboard_arrow_down</mat-icon>
            </div>
          </mat-panel-title>
        </mat-expansion-panel-header>

        <ng-template matExpansionPanelContent>
          <ng-container
            *ngComponentOutlet="filter.component; injector: chatFilterInjector"
          ></ng-container>
        </ng-template>
      </mat-expansion-panel>
    </section>
  </ng-template>

  <section class="actions">
    <section class="invert-container">
      <mat-slide-toggle
        [checked]="!data.filterlet.positiveMatch"
        (change)="invertChange($event)"
      >
        Inverter filtros
      </mat-slide-toggle>
    </section>
    <section class="match-container">
      <mat-radio-group
        color="primary"
        [(ngModel)]="data.filterlet.match"
        class="chat-radios"
      >
        <mat-radio-button *ngFor="let type of matchNames" [value]="type">
          {{ translations[type] }}
        </mat-radio-button>
      </mat-radio-group>
    </section>
    <section class="apply-container">
      <button (click)="applyFilters()" class="apply-filters-button">
        Aplicar
      </button>
    </section>
  </section>
</section>
