import { Component, Inject } from '@angular/core';
import { IFilterlet } from '@colmeia/core/src/rules/dynamic-filter/dynamic-filter-interfaces';
import { IBasicUniversalInfo } from '@colmeia/core/src/comm-interfaces/aux-interfaces';
import { IAvatarHandlerHash } from 'app/components/annotation/annotations-list/annotations-list.component';
import { filterletToken, TUsedFeedbackAndInteractionPromise, feedbackAndInteractionPromiseToken, IChatFilterEvents, chatFilterEventsToken } from 'app/model/chat-filter.model';
import { RootComponent } from 'app/components/foundation/root/root.component';
import { isInvalidArray } from '@colmeia/core/src/tools/utility';
import { Feedback } from '@colmeia/core/src/interaction/feedback';

@Component({
	selector: 'app-chat-feedback-filter',
	templateUrl: './chat-feedback-filter.component.html',
	styleUrls: ['./chat-feedback-filter.component.scss']
})
//@TODO jurgilas componente usando metodo antigo de traducao
export class ChatFeedbackFilterComponent extends RootComponent<null> {

	handlers: IAvatarHandlerHash = {};
	feedbacks: IBasicUniversalInfo[];
	selectedFeedbacks: string[];

	constructor(
		@Inject(filterletToken) public filterlet: IFilterlet,
		@Inject(feedbackAndInteractionPromiseToken) private feedbackPromise: TUsedFeedbackAndInteractionPromise,
		@Inject(chatFilterEventsToken) private events: IChatFilterEvents,
	) {
		super({

		});
	}

	async ngOnInit() {
		if (isInvalidArray(this.filterlet.feedbacks)) {
			this.filterlet.feedbacks = [];
		}
		this.selectedFeedbacks = Array.from(this.filterlet.feedbacks);

		this.feedbacks = (await this.feedbackPromise).lookupFeedback;

		this.feedbacks.forEach(feedback => feedback.idMedia = Feedback.staticFactory(feedback.primaryID).getEmoji())

		const sub = this.events.applied.subscribe(() => {
			this.filterlet.feedbacks = this.selectedFeedbacks;
			sub.unsubscribe();
		});
	}

	toggleFeedback(feedback: IBasicUniversalInfo) {
		const index = this.selectedFeedbacks.indexOf(feedback.primaryID);
		if (index !== -1) {
			this.selectedFeedbacks.splice(index, 1);
		} else {
			this.selectedFeedbacks.push(feedback.primaryID);
		}
	}

}
