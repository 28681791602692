import { EBPMType } from "@colmeia/core/src/shared-business-rules/BPM/bpm-model"
import { ICRMRootServer, ICRMAgentEventServer, ICRMProcessingNodeEventServer, ICRMNotificationServer } from "@colmeia/core/src/shared-business-rules/crm/crm-services/crm-bpm-model"
import { ICRMHostFactoryInfo } from "@colmeia/core/src/shared-business-rules/graph-transaction/host/ns/ns.host.types"
import { gDefaultRenderData } from "@colmeia/core/src/shared-business-rules/graph/essential/graph-types"
import { ENonSerializableObjectType } from "@colmeia/core/src/shared-business-rules/non-serializable-id/non-serializable-id-interfaces"
import { CrmGraphCreateNotificationComponent } from "app/components/dashboard/crm-service-bpm/crm-graph-create-notification/crm-graph-create-notification.component"
import { CrmGraphCreateRootElementComponent } from "app/components/dashboard/crm-service-bpm/crm-graph-create-root-element/crm-graph-create-root-element.component"
import { CrmGraphCreateUserEventElementComponent } from "app/components/dashboard/crm-service-bpm/crm-graph-create-user-even/crm-graph-create-user-event.component"
import { CrmGraphProcessNodeComponent } from "app/components/dashboard/crm-service-bpm/crm-graph-process-node/crm-graph-process-node.component"
import { CRMNotificationBpmGraphCreateHandler, CRMProcessingNodeBpmGraphCreateHandler, CRMRootBpmGraphCreateHandler, CRMUserEventBpmGraphCreateHandler, IBpmGraphCreateParameters } from "app/model/bpm-graph-handler.model"
import { IUpserterComponentBehavior } from "../bpm-rules.types"
import { ITransactionServer } from "@colmeia/core/src/shared-business-rules/knowledge-base/bot-transaction/bot-transaction"
import { BotTransactionBaseDataComponent } from "app/components/dashboard/ai-pages/bot-transaction-edit/bot-transaction-edit.component"
import { BotTransactionBaseDataComponentDiagramDialogHandler } from "app/components/dashboard/ai-pages/bot-transaction-edit/bot-transaction-edit.handler"


export interface ICRMFrontFactoryInfo extends ICRMHostFactoryInfo {
    idParent: string
}

export class CrmFrontEndDialogFactory {
    static create(info: ICRMFrontFactoryInfo) {
        const defaultParams: IBpmGraphCreateParameters = {
            bpmType: EBPMType.crm,
            renderData: gDefaultRenderData,
            clientCallback: undefined,
        }
        console.log({ infoIdParent: info.idParent });

        switch (info.hostType) {
            case ENonSerializableObjectType.crmServiceConfigRoot:
                return {
                    behavior: IUpserterComponentBehavior.Dialog,
                    component: CrmGraphCreateRootElementComponent,
                    handler: CRMRootBpmGraphCreateHandler.create({
                        ...defaultParams,
                        crmRoot: <ICRMRootServer>(info.ns),
                    })
                }
            case ENonSerializableObjectType.crmServiceConfigAgentEvent:
                return {
                    behavior: IUpserterComponentBehavior.Dialog,
                    component: CrmGraphCreateUserEventElementComponent,
                    handler: CRMUserEventBpmGraphCreateHandler.create({
                        ...defaultParams,
                        crmUserEvent: <ICRMAgentEventServer>(info.ns),
                        idParent: info.idParent
                    })
                }
            case ENonSerializableObjectType.crmServiceConfigProcessingNode:
                return {
                    behavior: IUpserterComponentBehavior.Dialog,
                    component: CrmGraphProcessNodeComponent,
                    handler: CRMProcessingNodeBpmGraphCreateHandler.create({
                        ...defaultParams,
                        crmProcessNode: <ICRMProcessingNodeEventServer>(info.ns),
                        idParent: info.idParent
                    })
                }
            case ENonSerializableObjectType.crmServiceConfigNotification:
                return {
                    behavior: IUpserterComponentBehavior.Dialog,
                    component: CrmGraphCreateNotificationComponent,
                    handler: CRMNotificationBpmGraphCreateHandler.create({
                        ...defaultParams,
                        crmNotification: <ICRMNotificationServer>(info.ns),
                        idParent: info.idParent
                    })
                }
            case ENonSerializableObjectType.contentGenerator:
                return {
                    behavior: IUpserterComponentBehavior.Dialog,
                    component: BotTransactionBaseDataComponent,
                    handler: BotTransactionBaseDataComponentDiagramDialogHandler.create({
                        transaction: <ITransactionServer>(info.ns),
                        clientCallback: undefined,
                    })
                }
            default:
                throw new Error('getUpserterDialogComponent info.type not found');
        }
    }
}
