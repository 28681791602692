import { Component, Input, OnInit } from '@angular/core';
import { KBAssetType } from '@colmeia/core/src/shared-business-rules/bot/bot-asset-model';
import { ICRMRoot, ICRMRootServer } from '@colmeia/core/src/shared-business-rules/crm/crm-services/crm-bpm-model';
import { ICRMProjectServer } from '@colmeia/core/src/shared-business-rules/crm/crm-services/crm-config-ds-model';
import { SchemaPropertyServer } from '@colmeia/core/src/shared-business-rules/files/files';
import { TIVariablesArray } from '@colmeia/core/src/shared-business-rules/metadata/metadata-util-interfaces';
import { TIEditorVariableArray, addBracket, getLocalCanonicalsFromForm } from '@colmeia/core/src/shared-business-rules/metadata/metadata-utils';
import { ENonSerializableObjectType } from '@colmeia/core/src/shared-business-rules/non-serializable-id/non-serializable-id-interfaces';
import { ETimestmapPickerElements, TimestampPickerHandler } from 'app/components/foundation/timestamp-picker/timestamp-picker.component';
import { GenericDashboardEditHandler, IGenericDashboardEditPageClientCallback } from 'app/handlers/generic-dashboard-edit.handler';
import { ENsMoreOptions } from 'app/handlers/ns-more-options.handler';
import { INSPickerHandlerParameter, NSPickerHandler } from 'app/handlers/ns-picker/ns-picker.handler';
import { EVarEditorEntityType } from 'app/handlers/var-editor.handler';
import { CRMRootBpmGraphCreateHandler } from 'app/model/bpm-graph-handler.model';
import { BpmCrmApiService } from 'app/services/bpm/api/bpm-crm-api.service';
import { BpmService } from 'app/services/bpm/bpm.service';
import { CanonicalService } from 'app/services/canonical.service';
import { DashBoardService } from 'app/services/dashboard/dashboard.service';
import { NonSerializableFactory } from '../../../../../../../core/src/nser/non-serializable.factory';
import { isValidRef, isValidString } from '../../../../../../../core/src/tools/utility';
import { NewConditionEditorHandler } from '../../new-condition-editor/new-condition-editor.component';
import { ISingleContentAssetAdderHandler } from '../../single-content-asset-editor/single-content-asset-editor.handler';
import { ICRMGraphBasicData } from '../crm-graph-basic-data/crm-graph-basic-data.component';
import { IFormTransactionServer } from '@colmeia/core/src/shared-business-rules/knowledge-base/bot-transaction/bot-transaction';
import { unionBy, uniqBy } from 'lodash';

enum EMode {
    edit,
    create
}
type TCrmRootContainer = ICrmRootEditContainer | ICrmRootCreateContainer
interface ICrmRootEditContainer {
    mode: EMode.edit
    crmRoot: ICRMRootServer
}
interface ICrmRootCreateContainer {
    mode: EMode.create
    crmRoot: ICRMRoot
}

@Component({
    selector: 'app-crm-graph-create-root-element',
    templateUrl: './crm-graph-create-root-element.component.html',
    styleUrls: ['./crm-graph-create-root-element.component.scss']
})
export class CrmGraphCreateRootElementComponent implements OnInit {
    @Input() handler: CRMRootBpmGraphCreateHandler;
    crmRootContainer: TCrmRootContainer
    crmConfigPackageNSPickerHandler: NSPickerHandler

    public genericEdit: GenericDashboardEditHandler;
    public basicCRMHandler: ICRMGraphBasicData;
    public bpmTimerActionHandler: undefined;
    public conditionTimerActionHandler: NewConditionEditorHandler;
    public timestampHandler: TimestampPickerHandler;
    private crmItemsPackage: ICRMProjectServer;
    public titleAssetHadler: ISingleContentAssetAdderHandler;
    public notifyMessageAssetHadler: ISingleContentAssetAdderHandler;
    private colmeiaFormMetadataVariables: TIEditorVariableArray = [];
    public schemaVariables: TIVariablesArray = [];

    public visualizerPickerHandler: NSPickerHandler;

    constructor(
        private dashboardSvc: DashBoardService,
        private bpmCrmApiService: BpmCrmApiService,
        private bpmSvc: BpmService,
        private canoniclSvc: CanonicalService,
    ) { }

    ngOnInit(): void {
        this.initMode()
        this.initCrmRootNserEdit()
        this.initCrmPacoteConfigNserPicker()
        if (this.hasPackageConfigured()) {
            // this.initBasicCRMHandler()
            this.initTimestampPicker();
        }
        const { crmRoot } = this.crmRootContainer;

        if (!isValidRef(crmRoot.useCustomTitleGen) && isValidRef(crmRoot.titleTemplate)) {
            this.crmRootContainer.crmRoot.useCustomTitleGen = true;
        }

        this.initTitleAssetHandler();
        this.initVisualizerPicker();
        this.initNotifyMessage();
    }

    private initTitleAssetHandler() {
        const { crmRoot } = this.crmRootContainer;
        console.log({ colmeiaFormMetadataVariables: this.colmeiaFormMetadataVariables });
        this.titleAssetHadler = {
            assetTypeEnabled: [KBAssetType.content],
            title: "Estrutura do título",
            content: crmRoot.titleTemplate ? [crmRoot.titleTemplate] : [],
            saveCallBack: ([content]) => {
                crmRoot.titleTemplate = content;
            },
            variables: {
                [EVarEditorEntityType.NonSerializable]: this.colmeiaFormMetadataVariables,
            },
        }
    }

    private initNotifyMessage() {
        const { crmRoot } = this.crmRootContainer;

        this.notifyMessageAssetHadler = {
            assetTypeEnabled: [KBAssetType.content],
            title: "Mensagem de notificação",
            content: crmRoot.notifyUserMessage ? [crmRoot.notifyUserMessage] : [],
            saveCallBack: ([content]) => {
                crmRoot.notifyUserMessage = content;
            },
            variables: {
                [EVarEditorEntityType.NonSerializable]: this.colmeiaFormMetadataVariables,
            },
        }
    }

    private initVisualizerPicker() {
        const crmRoot = this.crmRootContainer.crmRoot
        this.visualizerPickerHandler = new NSPickerHandler({
            title: 'Transformador',
            nsType: ENonSerializableObjectType.transformer,
            clientCallback: {
                onSaveNSCallback: (ns) => crmRoot.idVisualizer = ns?.idNS
            },
            idParent: null,
            demandedTag: undefined,
            genericNonSerializableService: this.dashboardSvc.getGenericNonSerializableService(),
            maxSelections: 1,
            nonSerializablesIds: crmRoot.idVisualizer ? [crmRoot.idVisualizer] : undefined
        })
    }

    private initTimestampPicker() {
        this.timestampHandler = new TimestampPickerHandler({
            label: 'Expirar em',
            elements: {
                [ETimestmapPickerElements.Days]: {
                    min: 0,
                    max: 365
                },
                [ETimestmapPickerElements.Hours]: {
                    min: 0,
                    max: 23
                },
                [ETimestmapPickerElements.Minutes]: {
                    min: 0,
                    max: 59
                },
            },
            time: this.crmRootContainer.crmRoot.bpmScheduleMS,
            clientCallback: {
                onChangeTime: (value: number) => {
                    this.crmRootContainer.crmRoot.bpmScheduleMS = value;
                },
            },
        });
    }

    initMode() {
        const nser: ICRMRootServer = this.handler.getCrmRoot()
        if (isValidRef(nser)) {
            this.crmRootContainer = {
                mode: EMode.edit,
                crmRoot: nser
            }
        } else {
            this.crmRootContainer = {
                mode: EMode.create,
                crmRoot: <ICRMRoot>NonSerializableFactory.create(ENonSerializableObjectType.crmServiceConfigRoot, { name: '' })
            }
        }
    }

    initBasicCRMHandler() {

        const updater = (ns) => {
            console.log("@@@", { ns });
            if (ns.nsType === ENonSerializableObjectType.formSchemma) {
                const metadata = (ns as SchemaPropertyServer);
                this.updateAssetsHandlersWithMetadata(metadata);
            } else if (ns.nsType === ENonSerializableObjectType.contentGenerator) {
                this.updateAssetsHandlersWithColmeiaForm(ns);
            }
        };

        this.basicCRMHandler = {
            element: <ICRMRootServer>this.crmRootContainer.crmRoot,
            saveNSCallback: updater,
            onLoadNSCallback: updater,
            configureAction: true,
            crmRoot: this.crmRootContainer.crmRoot as ICRMRootServer,
            crmItemsPackage: this.crmItemsPackage,
        }
    }

    private updateAssetsHandlersWithColmeiaForm(colmeiaForm: IFormTransactionServer) {

        colmeiaForm.canonicals.forEach(c => {
            this.colmeiaFormMetadataVariables.push({
                idProperty: c.idCanonical,
                variable: '',
                isSafe: c.isSafe,
            })
        });

        uniqBy(this.colmeiaFormMetadataVariables, 'idProperty');

    }

    private async updateAssetsHandlersWithMetadata(metadata: SchemaPropertyServer) {
        this.titleAssetHadler = undefined;
        const canonicalsIds = getLocalCanonicalsFromForm(metadata.schemma.form);

        canonicalsIds.forEach(c => {
            this.colmeiaFormMetadataVariables.push({
                idProperty: c,
                variable: '',
                isSafe: true,
            })
        });

        uniqBy(this.colmeiaFormMetadataVariables, 'idProperty');

        this.schemaVariables = metadata.schemma.form.map(prop => {
            return {
                idProperty: prop.idProperty,
                text: addBracket(prop.propertyName),
                isSafe: true,
            }
        });

        setTimeout(() => {
            this.initTitleAssetHandler();
            this.initNotifyMessage();
        }, 1)
    }

    private initCrmRootNserEdit(): void {
        this.genericEdit = new GenericDashboardEditHandler({
            nsType: ENonSerializableObjectType.crmServiceConfigRoot,
            autoValidate: true,
            clientCallback: <IGenericDashboardEditPageClientCallback>{
                onGenericSaveButtonPressed: this.saveCrmRoot,
                onGenericBackButtonPressed: () => {
                    this.handler.getDialogRef().close();
                }
            },
            nser: this.crmRootContainer.crmRoot,
            allowEditTitleInHeader: true,
            moreOptions: {
                [ENsMoreOptions.CopyID]: true,
                [ENsMoreOptions.CheckDependencies]: true,
            }
        })
    }

    initCrmPacoteConfigNserPicker() {
        const nsPickerParameter: INSPickerHandlerParameter = {
            title: "Escolha uma configuração de projeto",
            nsType: ENonSerializableObjectType.crmConfigPackage,
            nonSerializablesIds: this.hasPackageConfigured()
                ? [this.crmRootContainer.crmRoot.idConfigurationPackage]
                : [],
            clientCallback: {
                onSaveNSCallback: (packageSelected: ICRMProjectServer) => {
                    this.crmItemsPackage = packageSelected;
                    this.crmRootContainer.crmRoot.idConfigurationPackage = packageSelected?.idNS;

                    if (packageSelected?.idNS) {
                        this.initBasicCRMHandler();
                    } else {
                        this.basicCRMHandler = undefined;
                    }
                },
                onLoadNonSerializables: ([ns]: ICRMProjectServer[]) => {
                    this.crmItemsPackage = ns;
                    this.initBasicCRMHandler();
                }
            },
            idParent: null,
            demandedTag: undefined,
            genericNonSerializableService: this.dashboardSvc.getGenericNonSerializableService(),
            maxSelections: 1
        };

        this.crmConfigPackageNSPickerHandler = new NSPickerHandler(nsPickerParameter);
    }

    private saveCrmRoot = async () => {
        const [result] = await this.bpmCrmApiService.save(<ICRMRootServer>this.crmRootContainer.crmRoot);

        if (!result) return;

        this.handler.closeDialogAndSendResultToParentComponent({ userHasClickedSave: true, nonSerializable: result });
    }

    private hasPackageConfigured() {
        return isValidString(this.crmRootContainer.crmRoot.idConfigurationPackage)
    }

}
