import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Injector, OnDestroy, Renderer2 } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { IAttendentServicePackClient } from '@colmeia/core/src/shared-business-rules/attendent-service-pack/attendente-service-pack';
import { EIdMenus } from '@colmeia/core/src/shared-business-rules/colmeia-tags/id-menus';
import { EDefaultTag } from '@colmeia/core/src/shared-business-rules/colmeia-tags/tags';
import { gTranslations } from '@colmeia/core/src/shared-business-rules/const-text/translations';
import { ISocialNetworkSettingsServer } from '@colmeia/core/src/shared-business-rules/social-network/social-network-user-settings.model';

import { secToMS } from '@colmeia/core/src/time/time-utl';
import { genericTypedSuggestions } from '@colmeia/core/src/tools/type-utils';
import { isValidRef } from "@colmeia/core/src/tools/utility";
import { AttendanceConversationHistorySearchComponent } from 'app/components/dashboard/attendance-conversation-history-search/attendance-conversation-history-search.component';
import { GenericTableComponent } from 'app/components/dashboard/call-center/generic-table/generic-table.component';
import { AttendanceActiveCallComponent } from 'app/components/dashboard/dashboard-attendance-active-call/attendance-active-call/attendance-active-call.component';
import { DashboardActiveCallInfoComponent } from 'app/components/dashboard/dashboard-attendance-active-call/dashboard-active-call-info/dashboard-active-call-info.component';
import { DynamicDialogComponent } from 'app/components/dashboard/dashboard-data-extractor/dynamic-dialog/dynamic-dialog.component';
import { ColmeiaWindowService } from 'app/components/dashboard/dashboard-foundation/colmeia-window/colmeia-window.service';
import { SNConfigService } from 'app/components/dashboard/social-network-config/sn-config.service';
import { RootComponent } from 'app/components/foundation/root/root.component';
import { ChatOptionsViewsMap, EChatOptionAction, IChatOptionView, TEMP_openContactList, TEMP_campaignList, TEMP_initCall, TEMP_goToAgentDash } from 'app/model/chat-options.model';
import { routeList } from 'app/model/routes/route-constants';
import { InterfaceInfoSignal } from 'app/model/signal/interface-signal';
import { IListenerSubscription } from 'app/model/signal/ps-interfaces';
import { SubscriptionSignal } from 'app/model/signal/subscription-signal';
import { AttendanceActiveEditHelperService } from 'app/services/attendance-active-edit-call-init.service';
import { AttendanceService } from 'app/services/attendance.service';
import { ChatFilterService } from 'app/services/chat-filter.service';
import { GroupNavigationStart } from 'app/services/controllers-services/navigator/navigator';
import { NavigatorServices } from 'app/services/controllers-services/navigator/navigator.service';
import { RoutingBuilder } from 'app/services/routing-builder';
import { SpinType } from 'app/services/screen-spinner.service';
import { SessionService } from 'app/services/session.service';
import { SignalListenerService } from 'app/services/signal/signal-listener';
import { BehaviorSubject, merge, Observable, Subject, Subscription } from 'rxjs';
import { delay, filter, map, skip } from 'rxjs/operators';
import { ExpirableEvent } from './../../../model/client-utility';
import { ScreenSpinnerService } from './../../../services/screen-spinner.service';
import { ContactListService } from 'app/components/dashboard/contact-list/contact-list.service';
import { CustomerFinderComponent } from 'app/components/customer-finder/customer-finder.component';
import { ISearchActiveCallResult, TISearchActiveCallResultArray } from '@colmeia/core/src/shared-business-rules/active-1x1-call/active-1x1-model';
import { ContactListPageComponent } from 'app/components/dashboard/contact-list/contact-list-page/contact-list-page.component';
import { ChatOptionsService } from 'app/components/chat-options/chat-options/chat-options.service';
import { EmbeddedChatService } from 'app/services/embedded-chat.service';

interface IViewStage {
    id: string;
    icon: string;
    iconSize?: number;
    text: string;
    target?: () => any;
    canSee?: () => boolean;
    injector?: Injector;
}

class LoadingEvent extends ExpirableEvent {
    static lifetimeMS: number = secToMS(12);
    constructor(public readonly loading: boolean) {
        super(LoadingEvent.lifetimeMS);
    }

    public isVisible(): boolean {
        return !this.expired && this.loading
    }
}

@Component({
    selector: 'chat-options',
    templateUrl: './chat-options.component.html',
    styleUrls: ['./chat-options.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class ChatOptionsComponent extends RootComponent<'defaultTitle'> implements IListenerSubscription, OnDestroy, AfterViewInit {
    views: IViewStage[] = [];
    private attendentServicePack: IAttendentServicePackClient;
    private currentGroupID: string;

    private _loading: Subject<LoadingEvent> = new BehaviorSubject(new LoadingEvent(true));
    public loading$: Observable<LoadingEvent> = this._loading.asObservable();
    public showOverlay$: Observable<ExpirableEvent>;
    private overlayLTCheckSubscription: Subscription;


    constructor(
        private chatFilterSvc: ChatFilterService,
        private session: SessionService,
        private listener: SignalListenerService,
        private attendanceSvc: AttendanceService,
        public dialog: MatDialog,
        private router: Router,
        private cdr: ChangeDetectorRef,
        private navigator: NavigatorServices,

        private colmeiaWindow: ColmeiaWindowService,
        private spinSvc: ScreenSpinnerService,
        private activeCallService: AttendanceActiveEditHelperService,
        private snConfigSvc: SNConfigService,
        private chatOptionsSvc: ChatOptionsService,
        private contactListSvc: ContactListService,
        private renderer: Renderer2,
        private el: ElementRef,
        private embededSvc: EmbeddedChatService

    ) {
        super({
            defaultTitle: gTranslations.fragments.chatOptions
        });
        this.listener.listenSubscriptionChanges(this);
        this.listener.listenToInterfaceChanges(this);

        this.showOverlay$ = merge(
            this._loading,
            this.spinSvc.lastStatus$.pipe(
                skip(1),
                filter(ev => ev.spinType === SpinType.groupNav && !ev.show),
                map(() => new LoadingEvent(false))
            ),
            this.navigator.groupNavigation$.pipe(
                filter(ev => ev instanceof GroupNavigationStart),
                map(() => new LoadingEvent(true))
            )
        );

        this.overlayLTCheckSubscription = this.showOverlay$.pipe(delay(LoadingEvent.lifetimeMS + 1)).subscribe(() => {
            this.cdr.markForCheck();
        })

        this.activeCallService.setComponents({
            dynamicDialog: DynamicDialogComponent,
            genericTable: GenericTableComponent,
            activeCallInfo: DashboardActiveCallInfoComponent,
        });
    }

    async receiveChangeInterfaceCallback(sign: InterfaceInfoSignal) {
        if (sign.getInterfaceSignal().changeLanguage) {
            this.resetViewsAndLoad();
        }
    }

    ngOnDestroy() {
        this.overlayLTCheckSubscription.unsubscribe();
        super.ngOnDestroy();
    }

    async resetViewsAndLoad() {
        try {
            this._loading.next(new LoadingEvent(true));
            this.cdr.markForCheck();
            this.getAttendentPack();
            await this.loadViews();
        } finally {
            this._loading.next(new LoadingEvent(false));
            this.cdr.markForCheck();
        }
    }

    get snSettings(): ISocialNetworkSettingsServer {
        return this.chatOptionsSvc.snSettings;
    }

    async ngOnInit() {
        await Promise.all([
            this.chatOptionsSvc.init(),
            this.snConfigSvc.initConfigAndLoadAccess()
        ]);
        this.listener.listenSubscriptionChanges(this);
        this.listener.listenToInterfaceChanges(this);
        this.currentGroupID = this.session.getSelectedGroupID();
        await this.resetViewsAndLoad();
    }

    ngAfterViewInit(): void {
        this.embededSvc.initColmeiaChatScript(this.el, this.renderer);
    }

    async changeSubscriptionSignCallback(subscriptionSignal: SubscriptionSignal): Promise<void> {
        if (isValidRef(this.currentGroupID) && subscriptionSignal.getGroupID() !== this.currentGroupID) {
            this.currentGroupID = subscriptionSignal.getGroupID();
            await this.resetViewsAndLoad();
        }
    }

    public async goToDashboard(): Promise<void> {
        await this.router.navigateByUrl(RoutingBuilder.dashboardPage([
            routeList.dashboard.children.serviceStatus.path,
            routeList.dashboard.children.serviceStatus.children.current,
        ]));
    }

    private addTarget(view: IViewStage): IViewStage {
        let target = ChatOptionsViewsMap[view.id];

        if (Object.values(EChatOptionAction).includes(target)) {
            target = genericTypedSuggestions<{ [key in EChatOptionAction]: () => void }>()({
                [EChatOptionAction.filter]: () => this.chatFilterSvc.addFilter(),
                [EChatOptionAction.goToDashboard]: () => this.goToDashboard(),
                [EChatOptionAction.conversationHistory]: () => this.openConversationHistory(),
                [EChatOptionAction.initCall]: () => this.initCall(),
                [EChatOptionAction.campaignList]: () => this.openCampaignList(),
                [EChatOptionAction.openContactList]: () => this.openContactList(),
                // [EChatOptionAction.initCopilot]: () => this.openCopilotWindow()
            })[target];
        }

        return {
            ...view,
            target,
        }
    }

    public async openCampaignList() {
        this.colmeiaWindow.open(AttendanceActiveCallComponent, {
            data: { defaultTag: EDefaultTag.serviceAttendent },
            width: "80vw",
            height: "80vh",
            windowIdentifier: 'chat-options-active-call-att',
            title: 'Listas de envio',
            group: 'Chamadas ativas'
        });
    }

    public openContactList() {
        this.colmeiaWindow.open(ContactListPageComponent, {
            windowIdentifier: 'contact-list',
            panelClass: 'avarage-size',
            title: "Listas de contato"
        });
    }

    public async addContactToList() {
        let target = await this.attendanceSvc.getCurrentAttendanceTarget();
        const dialogRef: MatDialogRef<CustomerFinderComponent> = this.dialog.open(CustomerFinderComponent, {
            panelClass: "avarage-size",
            minWidth: "70vw"
        });

        dialogRef.componentInstance.handler = {
            target,
            allowedSearchSources: {
                standard: {},
                infoSquare: {},
            },
            onResult: (result: TISearchActiveCallResultArray) => {
                if (result.length === 1) {
                    const contact = result[0];
                    this.contactListSvc.addContactToLists(
                        contact.name,
                        {
                            target: contact.target,
                            idAvatar: contact.idAvatar,
                            idSocialKey: contact.idSocialKey,
                            channel: contact.channel,
                        }
                    );

                    dialogRef.close();

                }

            },
            onCustomerSelected: async ([contact]: ISearchActiveCallResult[]) => {
                if (!contact) return;

                this.contactListSvc.addContactToLists(
                    contact.name,
                    {
                        target: contact.target,
                        idAvatar: contact.idAvatar,
                        idSocialKey: contact.idSocialKey,
                        channel: contact.channel,
                    }
                );

                dialogRef.close();
            }
        };
    }

    public async openConversationHistory() {
        const target = await this.attendanceSvc.getCurrentAttendanceTarget();

        // console.log({ target });

        const windowRef = this.colmeiaWindow.open(AttendanceConversationHistorySearchComponent, {
            data: { target: String(target), autoOpenFirstSearchWithUniqueRow: true },
            width: "80vw",
            height: "80vh",
            windowIdentifier: 'chat-options-history-att',
            title: 'Busca',
            group: "Históricos de conversação"
        });

        windowRef.afterRestore().subscribe(async () => {
            const target = await this.attendanceSvc.getCurrentAttendanceTarget();
            console.log({ target: target.concat(''), windowTarget: windowRef.data.target });

            if (target) {
                if (windowRef.data.target !== target) {
                    windowRef.componentInstance.firstRun = true;
                    windowRef.componentInstance.customerFinder.target = target;
                    windowRef.componentInstance.customerFinder.onSearchInput(target);
                }

                windowRef.data.target = target;
            }
        });
    }

    private async initCall() {
        await this.activeCallService.openActiveCallInfo(
            { idParentContactList: null, contactNS: null, contactList: null, parentContactListIdCampaignAction: undefined, refreshListCallback: undefined, sendToAllWithCampaignActionId: undefined, directToActiveCall: true }).promise;
    }

    protected async loadViews(): Promise<void> {
        this.views = [];

        if (this.attendanceSvc.isLoggedIn) {
            const snSettings = this.snSettings;

            const goToAgentDashView = this.addTarget({
                id: TEMP_goToAgentDash,
                icon: "go-to-panel",
                text: "Ir para painel de atendimento",
                canSee: () => true,
            });
            this.views.push(goToAgentDashView);

            // if (this.chatOptionsSvc.shouldAllowConversationHistoryButton()) {
            //     const conversationHistoryView = this.addTarget({
            //         id: TEMP_conversationHistory,
            //         icon: "./assets/icons/chat-options/history-conv-icon.svg",
            //         text: "Históricos de conversação",
            //         canSee: () => this.canAccessConversationHistory,
            //     });
            //     this.views.push(conversationHistoryView);
            // }

            if (this.chatOptionsSvc.shouldAllowActiveCallButton()) {
                const initCallView = this.addTarget({
                    id: TEMP_initCall,
                    icon: "active-call",
                    text: "Iniciar conversa",
                    canSee: () => this.canAccessConversationHistory,
                });
                this.views.push(initCallView);
                const campaignList = this.addTarget({
                    id: TEMP_campaignList,
                    icon: "campaign-list",
                    iconSize: 20,
                    text: "Listas de envio",
                    canSee: () => true,
                });
                this.views.push(campaignList);
            }

            if (this.chatOptionsSvc.shouldAllowContactsListsForAgentButton()) {
                const contactList = this.addTarget({
                    id: TEMP_openContactList,
                    icon: "contact-list",
                    iconSize: 20,
                    text: "Abrir listas de contato",
                    canSee: () => true,
                });
                this.views.push(contactList);
            }
        }

        this.cdr.markForCheck();
    }

    public get canAccessConversationHistory() {
        return this.snConfigSvc.canAccess(EIdMenus.ConversationHistory)
    }

    getAttendentPack() {
        const pack: IAttendentServicePackClient = this.attendanceSvc.getAttendentServicePackForCurrentGroup();
        this.attendentServicePack = pack;
    }

    open = async (view: IChatOptionView, $event: PointerEvent) => {
        $event.stopPropagation();
        const { target } = view;
        // console.log({target,objectValuesOptions: Object.values(EChatOptionAction), ChatOptionsViewsMap, view, includes: Object.values(EChatOptionAction).includes(ChatOptionsViewsMap[view.id])})
        if (Object.values(EChatOptionAction).includes(ChatOptionsViewsMap[view.id])) {
            return target();
        }
    }
}
