import { EDelivery360Action } from '@colmeia/core/src/comm-interfaces/interaction-interfaces';
import { GroupSelectorShortcutComponent } from "../components/chat/group-selector-shortcut/group-selector-shortcut.component";
import { Group } from "@colmeia/core/src/business/group";
import { TGlobalUID } from "@colmeia/core/src/core-constants/types";
import {
    GroupShortcutHandlerService,
    IGroupShortcutStats,
    ILastMessage
} from "../services/group-shortcut-handler.service";
import { isValidRef } from "@colmeia/core/src/tools/utility";
import { removeUniquizer } from "@colmeia/core/src/shared-business-rules/social-cc/social-cc-rules";
import { Interaction } from '@colmeia/core/src/interaction/interaction';
import { StartServiceChat } from '@colmeia/core/src/interaction/service-group/start-service-chat';
import { AttendanceService } from 'app/services/attendance.service';
import { EAttendanceChannelStatusTypeFilter, TSelectedAttendanceChannelStatusType } from 'app/components/chat/attendance-type-filters/attendance-type-filters.component';

export interface IShortcutParentInstance {
    mustResortGroups(): void;
}

export interface IGroupSelectorShortcutHandlerParameter {
    service: GroupShortcutHandlerService;
    group: Group;
    attendanceService: AttendanceService;
}

export class GroupSelectorShortcutHandler {
    private parameter: IGroupSelectorShortcutHandlerParameter;
    private slave: GroupSelectorShortcutComponent;
    private _island: unknown;
    private _channel: EDelivery360Action;

    get island(): unknown { return this._island };
    get channel(): EDelivery360Action { return this._channel };

    constructor(parameter: IGroupSelectorShortcutHandlerParameter){
        this.parameter = parameter;
    }

    public getLastMessage(): ILastMessage {
        return this.getStats().lastMessage;
    }

    public getStats(): IGroupShortcutStats {
        return this.parameter.service.getStatsForGroup(this.getGroupID());
    }

    public setSlaveInstance(instance: GroupSelectorShortcutComponent): void {
        this.slave = instance;
        this.mustResortGroups();
    }

    public getLastInteractionTimeStamp(): number {
        const lastMsg = this.getLastMessage();
        return isValidRef(lastMsg) ? lastMsg.clocktick : 0;
    }

    public mustResortGroups(): void {
        this.parameter.service.mustResortGroups();
    }

    public getLastInteraction(): Interaction {
        // ordem de chegada de service request
        // ordem de quanto tempo não se responde ao cliente
        return undefined;
    }


    public getOpenServiceRequest(): StartServiceChat {
        const root = <StartServiceChat>this.getLastInteraction().getRoot();
        return root;
    }

    public getGroup(): Group {
        return this.parameter.group;
    }

    public getGroupID(): TGlobalUID {
        return this.getGroup().getPrimaryID();
    }

    public getGroupName(): string {
        return removeUniquizer(this.parameter.group.getName());
    }

    public setIsland(island: unknown): void {
        this._island = island;
    }

    public setChannel(channel: EDelivery360Action): void {
        this._channel = channel;
    }

    public getChannelStatusType(): TSelectedAttendanceChannelStatusType {
        const channelOpenUntil = this.parameter.attendanceService.getChannelOpenUntilForGroup(this.getGroupID());

        if (isNaN(channelOpenUntil)) {
            return null;
        }

        if (channelOpenUntil < Date.now()) {
            return EAttendanceChannelStatusTypeFilter.ChannelClosed;
        } else {
            return EAttendanceChannelStatusTypeFilter.Active;
        }
    }
}
