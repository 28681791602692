import { NsTypeToInterface } from "@colmeia/core/src/shared-business-rules/non-serializable-id/non-serializable-interface-mapper";
import { kbValidator } from "@colmeia/core/src/shared-business-rules/non-serializable-id/validation/implementations/kb-validator";
import { utteranceValidator } from "@colmeia/core/src/shared-business-rules/non-serializable-id/validation/implementations/utterance.validator";
import { $Extends } from "@colmeia/core/src/tools/utility/types/validate";
import { FriendlyMessage } from "../../../error-control/friendly-message";
import { ENonSerializableObjectType, INonSerializableHeader } from "../non-serializable-id-interfaces";
import { nserValidationNOOP } from "./helpers";
import { accessRoleValidator } from "./implementations/access-role";
import { agentFileValidator } from "./implementations/agent-file.validator";
import { attendantCalendarValidator, attendantStatus, serviceIslandValidator } from "./implementations/attendance";
import { attendantServicePackValidator } from "./implementations/attendant-service-pack.validator";
import { attendanceCopilotValidator } from "./implementations/attendance-copilot.validator";
import { botValidator } from "./implementations/bot";
import { botTransactionValidator } from "./implementations/bot-transactions";
import { campaignValidator } from "./implementations/campaign";
import { catalogNserValidator } from "./implementations/catalog-validator";
import { colmeiaApiModelValidator } from "./implementations/colmeia-api-model";
import { channelNserValidator } from "./implementations/communication";
import { wabaNserValidator } from "./implementations/waba.validator";
import { configurationSetValidator } from "./implementations/configuration-set.validator";
import { corporateSearchBodyValidator, corporateSearchHeaderValidator } from "./implementations/corporate-search";
import { crmConfigValidator, crmNotificationBPMValidator, crmProcessNodeBPMValidator, crmRootBPMValidator, crmUserEventBPMValidator } from "./implementations/crm-config";
import { validateCustomEvents } from "./implementations/custom-events";
import { deployedServicesValidator } from "./implementations/deployed-services";
import { graphElementValidator } from "./implementations/graph-element";
import { sendCampaignValidator } from "./implementations/send-campaign";
import { smartFlowBodyValidator, smartFlowHeaderValidator } from "./implementations/smartflow-rtf-param";
import { socialNetworkConfigValidator } from "./implementations/social-network-config";
import { srPackageValidator } from "./implementations/sr-package";
import { templateNserValidator } from "./implementations/template";
import { userFunctionValidator } from "./implementations/user-function.validator";
import { backupSnapshotValidator } from "./implementations/backup-snapshot.validator";
import { kbCorporateEntityValidator } from "@colmeia/core/src/shared-business-rules/non-serializable-id/validation/kb-corporate-entity-validator";
import { contentVectorValidator } from "@colmeia/core/src/shared-business-rules/non-serializable-id/validation/implementations/content-vector.validator";
import { contentVectorChunkValidator } from "@colmeia/core/src/shared-business-rules/non-serializable-id/validation/implementations/content-vector-chunk.validator";
import { generativePromptValidator } from "@colmeia/core/src/shared-business-rules/non-serializable-id/validation/implementations/generative-prompt.validator";
import { transformerNSValidator } from "./implementations/transformer.validator";
import { asyncProcessorNSValidator } from "@colmeia/core/src/shared-business-rules/non-serializable-id/validation/implementations/async-listener.validator";

export type TNserValidator<NS extends INonSerializableHeader = INonSerializableHeader> = (nser: NS, friendly: FriendlyMessage) => FriendlyMessage;


function defineConfig<Input extends $Extends<Input, Output>, Output extends {}>(config: Input): Output
function defineConfig<Input extends Output, Output extends {}>(config: Input): Output {
    return config;
}


const NserValidatorDB = defineConfig<{ [NSType in ENonSerializableObjectType]: TNserValidator<NsTypeToInterface[NSType]> }, { [key in ENonSerializableObjectType]: TNserValidator | typeof nserValidationNOOP }>({
    [ENonSerializableObjectType.generativePrompt]: generativePromptValidator,
    [ENonSerializableObjectType.contentVectorChunk]: contentVectorChunkValidator,
    [ENonSerializableObjectType.contentVector]: contentVectorValidator,
    [ENonSerializableObjectType.kbCorporateEntity]: kbCorporateEntityValidator,
    [ENonSerializableObjectType.backupSnapshot]: backupSnapshotValidator,
    [ENonSerializableObjectType.backupPackage]: nserValidationNOOP,
    [ENonSerializableObjectType.userApprovalProfile]: nserValidationNOOP,

    [ENonSerializableObjectType.analyticsBiReportItem]: nserValidationNOOP,
    [ENonSerializableObjectType.productModule]: nserValidationNOOP,
    [ENonSerializableObjectType.product]: nserValidationNOOP,
    [ENonSerializableObjectType.billingContract]: nserValidationNOOP,
    [ENonSerializableObjectType.billingContractItem]: nserValidationNOOP,
    [ENonSerializableObjectType.billingClosing]: nserValidationNOOP,
    [ENonSerializableObjectType.billingItem]: nserValidationNOOP,

    [ENonSerializableObjectType.catalog]: catalogNserValidator,
    [ENonSerializableObjectType.channelTemplate]: templateNserValidator,
    [ENonSerializableObjectType.crmConfigPackage]: srPackageValidator,
    [ENonSerializableObjectType.socialNetworkConfig]: nserValidationNOOP,
    [ENonSerializableObjectType.socialNetworkUserSettings]: socialNetworkConfigValidator,
    [ENonSerializableObjectType.customEvents]: validateCustomEvents,
    [ENonSerializableObjectType.serviceIsland]: serviceIslandValidator,
    [ENonSerializableObjectType.bot]: botValidator,
    [ENonSerializableObjectType.contentGenerator]: botTransactionValidator,
    [ENonSerializableObjectType.deployedServices]: deployedServicesValidator,
    [ENonSerializableObjectType.securityControl]: accessRoleValidator,
    [ENonSerializableObjectType.socialMediaConnection]: channelNserValidator,
    [ENonSerializableObjectType.waba]: wabaNserValidator,
    [ENonSerializableObjectType.attendanceCalendar]: attendantCalendarValidator,
    [ENonSerializableObjectType.graphElement]: graphElementValidator,
    [ENonSerializableObjectType.campaing]: campaignValidator,
    [ENonSerializableObjectType.runCampaing]: sendCampaignValidator,
    [ENonSerializableObjectType.knowledgeBase]: kbValidator,

    [ENonSerializableObjectType.attendantAllowedAvatar]: nserValidationNOOP,
    [ENonSerializableObjectType.attendentServicePack]: attendantServicePackValidator,
    [ENonSerializableObjectType.canonical]: nserValidationNOOP,
    [ENonSerializableObjectType.clientGeneralAnswer]: nserValidationNOOP,
    [ENonSerializableObjectType.colmeiaTags]: nserValidationNOOP,
    [ENonSerializableObjectType.connection]: nserValidationNOOP,
    [ENonSerializableObjectType.connectionRoute]: nserValidationNOOP,
    [ENonSerializableObjectType.deployHeader]: nserValidationNOOP,
    [ENonSerializableObjectType.deployProdItem]: nserValidationNOOP,
    [ENonSerializableObjectType.deployTransformer]: nserValidationNOOP,
    [ENonSerializableObjectType.faceCompany]: nserValidationNOOP,
    [ENonSerializableObjectType.featureDeployedGroup]: nserValidationNOOP,
    [ENonSerializableObjectType.formSchemma]: nserValidationNOOP,
    [ENonSerializableObjectType.fileMetadata]: nserValidationNOOP,
    [ENonSerializableObjectType.patchHeader]: nserValidationNOOP,
    [ENonSerializableObjectType.patchItem]: nserValidationNOOP,
    [ENonSerializableObjectType.utterance]: utteranceValidator,
    [ENonSerializableObjectType.callcenterAtt]: agentFileValidator,
    [ENonSerializableObjectType.presenceStatus]: attendantStatus,
    [ENonSerializableObjectType.NLPActivity]: nserValidationNOOP,
    [ENonSerializableObjectType.realTimeFusion]: nserValidationNOOP,
    [ENonSerializableObjectType.activeCall1x1]: nserValidationNOOP,
    [ENonSerializableObjectType.activeCallBatch]: nserValidationNOOP,
    [ENonSerializableObjectType.userFunction]: userFunctionValidator,
    [ENonSerializableObjectType.colmeiaAPIModel]: colmeiaApiModelValidator,
    [ENonSerializableObjectType.job]: nserValidationNOOP,
    [ENonSerializableObjectType.crmHeaderCloseState]: crmConfigValidator(ENonSerializableObjectType.crmHeaderCloseState),
    [ENonSerializableObjectType.crmItemCloseState]: crmConfigValidator(ENonSerializableObjectType.crmItemCloseState),
    [ENonSerializableObjectType.crmHeaderPhase]: crmConfigValidator(ENonSerializableObjectType.crmHeaderPhase),
    [ENonSerializableObjectType.crmItemPhase]: crmConfigValidator(ENonSerializableObjectType.crmItemPhase),
    [ENonSerializableObjectType.crmHeaderSeverity]: crmConfigValidator(ENonSerializableObjectType.crmHeaderSeverity),
    [ENonSerializableObjectType.crmItemSeverity]: crmConfigValidator(ENonSerializableObjectType.crmItemSeverity),
    [ENonSerializableObjectType.crmHeaderState]: crmConfigValidator(ENonSerializableObjectType.crmHeaderState),
    [ENonSerializableObjectType.crmItemState]: crmConfigValidator(ENonSerializableObjectType.crmItemState),
    [ENonSerializableObjectType.crmHeaderSupportLevel]: nserValidationNOOP,
    [ENonSerializableObjectType.crmItemSupportLevel]: nserValidationNOOP,
    [ENonSerializableObjectType.crmHeaderUrgencyHeaderLevel]: nserValidationNOOP,
    [ENonSerializableObjectType.crmItemUrgencyLevel]: nserValidationNOOP,
    [ENonSerializableObjectType.crmServiceConfigRoot]: crmRootBPMValidator,
    [ENonSerializableObjectType.crmServiceConfigAgentEvent]: crmUserEventBPMValidator,
    [ENonSerializableObjectType.crmServiceConfigNotification]: crmNotificationBPMValidator,
    [ENonSerializableObjectType.crmServiceConfigProcessingNode]: crmProcessNodeBPMValidator,
    [ENonSerializableObjectType.corporateSearchHeader]: corporateSearchHeaderValidator,
    [ENonSerializableObjectType.corporateSearchBody]: corporateSearchBodyValidator,
    [ENonSerializableObjectType.smartFlowRTFParamHeader]: smartFlowHeaderValidator,
    [ENonSerializableObjectType.smartFlowRTFParamBody]: smartFlowBodyValidator,
    [ENonSerializableObjectType.crmExecution]: nserValidationNOOP,
    [ENonSerializableObjectType.configurationSet]: configurationSetValidator,
    [ENonSerializableObjectType.bpmMarketingRoot]: nserValidationNOOP,
    [ENonSerializableObjectType.bpmMarketingAction]: nserValidationNOOP,
    [ENonSerializableObjectType.bpmMarketingPause]: nserValidationNOOP,
    [ENonSerializableObjectType.bpmMarketingAcknowledge]: nserValidationNOOP,
    [ENonSerializableObjectType.bpmMarketingListener]: nserValidationNOOP,
    [ENonSerializableObjectType.bpmMarketingEnd]: nserValidationNOOP,
    [ENonSerializableObjectType.yourTasks]: nserValidationNOOP,
    [ENonSerializableObjectType.metaBusinessPages]: nserValidationNOOP,
    [ENonSerializableObjectType.contactList]: nserValidationNOOP,
    [ENonSerializableObjectType.contactListItem]: nserValidationNOOP,
    [ENonSerializableObjectType.dataExtractionQuery]: nserValidationNOOP,
    [ENonSerializableObjectType.wafSchema]: nserValidationNOOP,
    [ENonSerializableObjectType.waf]: nserValidationNOOP,
    [ENonSerializableObjectType.attCopilot]: attendanceCopilotValidator,
    [ENonSerializableObjectType.transformer]: transformerNSValidator,
    [ENonSerializableObjectType.paymentAccount]: transformerNSValidator,
    [ENonSerializableObjectType.paymentGateway]: transformerNSValidator,
    [ENonSerializableObjectType.asyncProcessor]: asyncProcessorNSValidator,
    [ENonSerializableObjectType.supremacyAgent]: nserValidationNOOP,

});


export function validateNonserializable(nser: INonSerializableHeader, type: ENonSerializableObjectType): FriendlyMessage {
    const friendly: FriendlyMessage = new FriendlyMessage('validateNonserializable', true);
    const validator = NserValidatorDB[type];
    return validator(nser, friendly);
}
