import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    SecurityContext
} from '@angular/core';
import { DomSanitizer, SafeResourceUrl, SafeStyle } from '@angular/platform-browser';
import { Serializable } from '@colmeia/core/src/business/serializable';
import { getBestIDMEdiaFromUniversal, getBestMultimediaFromMultimediaObject } from "@colmeia/core/src/rules/mm-functions";
import {
    EHexagonFormat,
    HandlerHexagonon,
    IHexagononParameter,
    TvalidExagononSize
} from "../../../handlers/hexagono.handler";
import {ImgSecureDownloadService} from "../../../services/auth/img-secure-download.service";
import {ConstantService} from "../../../services/constant.service";
import {isValidRef, isInvalid, isValidString} from "@colmeia/core/src/tools/utility";
import { Observable, ReplaySubject, Subject } from 'rxjs';
import { first } from 'rxjs/operators';
import { HexagononMimetypeExtensionMap } from './hexagon.constants';

export enum HexagonVisualizationType {
    Image = "image",
    PDF = "pdf",
    Unknown = "unknown"
}

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'hexagon',
    templateUrl: './hexagon.component.html',
    styleUrls: ['./hexagon.component.scss'],
    host: {
        '[class.wrap--xxs]':  "isXxs()",
        '[class.wrap--xs]':   "isXs()",
        '[class.wrap--xsm]':   "isXsm()",
        '[class.wrap--smd]':  "isSmd()",
        '[class.wrap--xsmd]': "isXsmd()",
        '[class.wrap--sm]':   "isSm()",
        '[class.wrap--xmd]':  "isXmd()",
        '[class.wrap--md]':   "isMd()",
        '[class.wrap--lg]':   "isLg()",
        '[class.wrap--xlg]':  "isXlg()",
        '[class.wrap--xxlg]': "isXxlg()",
        '[class.clickable]': "isClickable()"
    }
})
export class HexagonComponent implements OnInit, OnChanges, OnDestroy {
    private static Mime: Map<string, ReplaySubject<string>> = new Map();

    HexagonVisualizationType: typeof HexagonVisualizationType = HexagonVisualizationType;

    private static defaultBackground = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABAQMAAAAl21bKAAAAA1BMVEUHGBbU+gZXAAAACklEQVQI12NgAAAAAgAB4iG8MwAAAABJRU5ErkJggg==';
    private static hiddenPlaceholderBackground = 'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7';

    /* NEW */
    private _hexagonHandler: HandlerHexagonon;
    public _url: any;
    private parameter: IHexagononParameter;

    public style: SafeStyle;
    public showIcon: boolean = false;
    public icon: string;
    public wrapperClass: string;
    public urlMimeType: string;
    public safeResourceUrl: SafeResourceUrl = this.sanitizer.bypassSecurityTrustResourceUrl(HexagonComponent.hiddenPlaceholderBackground);
    public visualizationType: HexagonVisualizationType = HexagonVisualizationType.Image;

    constructor(
        private cdr: ChangeDetectorRef,
        private imgDownloadSvc: ImgSecureDownloadService,
        private constantSvc: ConstantService,
        private sanitizer: DomSanitizer
    ) { };

    ngOnInit() {}

    ngOnDestroy(): void {
        if (this.hexagonHandler) {
            this.hexagonHandler.destroyComponent();
        };
    };

    ngOnChanges() {
        this.updatestyle();
    }

    isDefaultUrl(): boolean {
        return [HexagonComponent.defaultBackground, HexagonComponent.hiddenPlaceholderBackground].includes(this._url);
    }

    @Input()
    set hexagonHandler(value: HandlerHexagonon) {
        if (isInvalid(value)) {
            value = HandlerHexagonon.newEmpty();
        }

        if (value.getComponentParameter() !== this.parameter) {
            this._hexagonHandler = value;
            this.parameter = this._hexagonHandler.getComponentParameter();
            this.visualizationType = this.parameter.visualizationType ?? this.visualizationType;
            
            this.downloadImageIfNeeded();
        }

        this.updatestyle();
        this.showIcon = this.canShowIcon();
        this.icon = this.getIcon();
        this.wrapperClass = this.getWrapperClass();
        this.cdr.markForCheck();
    }

    @Input() previewShouldShowBorder: boolean;

    public static getDefaultBackground() {
        return HexagonComponent.defaultBackground;
    }

    get hexagonHandler(): HandlerHexagonon {
        return this._hexagonHandler;
    }

    private async downloadImageIfNeeded(): Promise<void> {
        const old = this._url;
        let url = this.getPlaceholderBackground();
        if (isValidRef(this.parameter.forceURL)) {
            url = this.parameter.forceURL;
        } else if(isValidRef(this.parameter.forceImage)) {
            const fUrl = await this.imgDownloadSvc.download(this.constantSvc.getFileUrl() + this.parameter.forceImage).toPromise();
            url = (<any>fUrl[SecurityContext.URL]).changingThisBreaksApplicationSecurity;
        } else if(isValidRef(this.parameter.multimediaInstance)) {
            const idMediaFromInstance = this.parameter.multimediaInstance.getIdMedia();
            if (isValidRef(idMediaFromInstance)) {
                const mUrl = await this.imgDownloadSvc.download(this.constantSvc.getFileUrl() + idMediaFromInstance).toPromise();
                url = (<any>mUrl[SecurityContext.URL]).changingThisBreaksApplicationSecurity;
            } else if(isValidRef(this.parameter.multimediaInstance.getClientFileInfo())){
                url = this.parameter.multimediaInstance.getClientFileInfo().getClientCachedFile().getFileUrl();
            }
        } else if(isValidRef(this.parameter.serializable)) {
            if (isValidRef(this.parameter.serializable.getMultimediaObject())) {
                const mediaInstanceByTag = this.parameter.serializable.getMultimediaObject().getMultimediaInstanceByTag(this.parameter.idMultimediaTag);
                const idMediaWithTag = (mediaInstanceByTag) ? mediaInstanceByTag.getIdMedia() : null;
                if (isValidRef(idMediaWithTag)) {
                    const mtUrl = await this.imgDownloadSvc.download(this.constantSvc.getFileUrl() + idMediaWithTag).toPromise();
                    url = (<any>mtUrl[SecurityContext.URL]).changingThisBreaksApplicationSecurity;
                } else {
                    const bestMediaOrDefault = this.parameter.serializable.getBestMediaID();
                    if (isValidRef(bestMediaOrDefault)) {
                        const bmUrl = await this.imgDownloadSvc.download(this.constantSvc.getFileUrl() + bestMediaOrDefault).toPromise();
                        url = (<any>bmUrl[SecurityContext.URL]).changingThisBreaksApplicationSecurity;
                    } else {
                        const bbmUrl = await this.imgDownloadSvc.download(this.constantSvc.getFileUrl() + getBestIDMEdiaFromUniversal(this.parameter.serializable.toJSON())).toPromise();
                        url = (<any>bbmUrl[SecurityContext.URL]).changingThisBreaksApplicationSecurity;
                    }
                }
            } else {
                const idMediaWithoutMultimediaObj = this.parameter.serializable.getBestMediaID() || getBestMultimediaFromMultimediaObject(null, this.parameter.serializable.getSerializableObjectTypeID());
                const wmoUrl = await this.imgDownloadSvc.download(this.constantSvc.getFileUrl() + idMediaWithoutMultimediaObj).toPromise();
                url = (<any>wmoUrl[SecurityContext.URL]).changingThisBreaksApplicationSecurity;
            }
        }

        if (old !== url) {
            this._url = url;
            this.safeResourceUrl = this.sanitizer.bypassSecurityTrustResourceUrl(url);
            this.updatestyle();
        }
    }

    private async updatestyle() {
        if(this.parameter.fileMode) {
            const url = this._url;
            if(!url) return;

            const type = await this.getMimetype(url).pipe(first()).toPromise();

            this.urlMimeType = type;

            if(type.startsWith("image/")) {
                this.defineImageVisualizationType();
            } else {
                switch(type) {
                    case "application/pdf":
                        this.visualizationType = HexagonVisualizationType.PDF;
                        break;
                    default:
                        this.visualizationType = HexagonVisualizationType.Unknown;
                        break;
                }
            }
        } else {
            this.defineImageVisualizationType();
        }
        return this.cdr.markForCheck();
    }

    private getPlaceholderBackground(): string {
        return this.parameter.fileMode ? HexagonComponent.hiddenPlaceholderBackground : HexagonComponent.defaultBackground;
    }

    private defineImageVisualizationType() {
        this.visualizationType = HexagonVisualizationType.Image;
    }

    private isHexSizeDefined(): boolean {
        return this.parameter && !!this.parameter.size;
    }

    private isHexOfType(hexType: TvalidExagononSize): boolean { { return this.isHexSizeDefined() ? this.parameter.size === hexType : false }}

    isXxs(): boolean { return this.isHexSizeDefined() ? this.isHexOfType('xxs') : false }
    isXs(): boolean { return this.isHexSizeDefined() ? this.parameter.size === 'xs' : true };
    isXsm(): boolean { return this.isHexSizeDefined() ? this.parameter.size === 'xsm' : true };
    isSmd(): boolean { return this.isHexSizeDefined() ? this.isHexOfType('smd') : false }
    isXsmd(): boolean { return this.isHexSizeDefined() ? this.isHexOfType('xsmd') : false }
    isSm(): boolean { return this.isHexSizeDefined() ? this.isHexOfType('sm') : false }
    isXmd(): boolean { return this.isHexSizeDefined() ? this.isHexOfType('xmd') : false }
    isMd(): boolean { return this.isHexSizeDefined() ? this.isHexOfType('md') : false }
    isLg(): boolean { return this.isHexSizeDefined() ? this.isHexOfType('lg') : false }
    isXlg(): boolean { return this.isHexSizeDefined() ? this.isHexOfType('xlg') : false }
    isXxlg(): boolean { return this.isHexSizeDefined() ? this.isHexOfType('xxlg') : false }

    public goToLink(): void {};

    public isSelectable(): boolean { return this.parameter && this.parameter.isSelectionable; };
    public isClickable(): boolean { return this.isSelectable(); }

    public markForCheck(): void {this.cdr.markForCheck()};

    canShowIcon(): boolean {
        return this.hexagonHandler &&
            this.hexagonHandler.getEditingSerializable() &&
            !!this.hexagonHandler.getEditingSerializable().getIcon()
            && !this.hexagonHandler.getComponentParameter().forceURL;
    }

    getIcon(): string {
        const serializable = this.hexagonHandler.getEditingSerializable();
        if (isValidRef(serializable)) {
            return this.hexagonHandler.getEditingSerializable().getIcon();
        }
    }

    getWrapperClass(): string {
        const classes = [];

        if (isValidString(this.hexagonHandler.getWrapperClass())){
            classes.push(this.hexagonHandler.getWrapperClass());
        }

        classes.push(
            isValidRef(this.parameter.format) && this.parameter.format === EHexagonFormat.RoundedSquare ? 'rounded-square' : 'circle'
        );

        return classes.join(' ');
    }

    private getMimetype(url: string): Observable<string> {
        let subject: ReplaySubject<string> = HexagonComponent.Mime.get(url);
        if(subject) { return subject; }

        subject = new ReplaySubject<string>(1);

        HexagonComponent.Mime.set(url, subject);

        fetch(url).then(response => {
            response.blob().then( blob => {
                subject.next(blob.type);
            });
        });

        return subject;
    }

    public getDownloadName(): string {
        if(!this.parameter.fileMode || this.visualizationType !== HexagonVisualizationType.Unknown) return;

        const extension = HexagononMimetypeExtensionMap[this.urlMimeType]?.[0]
        const fileName = this.parameter.multimediaInstance
            ? this.parameter.multimediaInstance.getFileName()
            : `${(this._url as string).split("/").pop()}.${ extension }`;

        return fileName;
    }

    get isFileMode(): boolean {
        return this.parameter.fileMode;
    }
}
