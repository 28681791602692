import { Injectable } from '@angular/core';
import { EIdMenus } from '@colmeia/core/src/shared-business-rules/colmeia-tags/id-menus';
import { ISocialNetworkSettingsServer } from '@colmeia/core/src/shared-business-rules/social-network/social-network-user-settings.model';
import { SNConfigService } from 'app/components/dashboard/social-network-config/sn-config.service';

@Injectable({
    providedIn: 'root'
})
export class ChatOptionsService {

    /**
     * @todo Checar possibilidade de implementação
     * Possível implementação futura
     * @example
     * get isSupervisor() {
     *     return true;
     * }
     */
    public isSupervisor?: never;

    public snSettings: ISocialNetworkSettingsServer;

    constructor(
        private snConfigSvc: SNConfigService,
    ) {
        this.init();
    }

    public async init() {
        this.snSettings = await this.snConfigSvc.fetchUserSettings();
    }

    public get canAccessConversationHistory() {
        return this.snConfigSvc.canAccess(EIdMenus.ConversationHistory)
    }

    public shouldAllowConversationHistoryButton() {
        return !this.snSettings?.agent?.useMenuSecurityToAllowConversationHistory || this.canAccessConversationHistory;
    }

    public shouldAllowActiveCallButton() {
        return !this.snSettings?.agent?.useMenuSecurityToAllowActiveCall || this.canAccessConversationHistory;
    }

    public shouldAllowContactsListsForAgentButton() {
        return !this.snSettings?.agent?.useMenuSecurityToAllowContactsLists || (this.snConfigSvc.canAccess(EIdMenus.ContactList))
    }


}
