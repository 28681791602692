import { EConfirmationType, TGlobalUID } from "@colmeia/core/src/core-constants/types";
import { ENonSerializableObjectType, INonSerializable } from "../../non-serializable-id/non-serializable-id-interfaces";
import { TIBPConditionalEvaluatorArray } from "../bpm-model";
import { values } from "@colmeia/core/src/tools/barrel-tools";

export type TBPMListenerItems =
    | ENonSerializableObjectType.contentGenerator
    | ENonSerializableObjectType.bot
    | ENonSerializableObjectType.campaing

export enum ESubscribedTo {
    // groups = 'grps', // segunda etapa
    // individual = 'indv', // segunda etapa - Uma pessoa irá receber notificações
    // crmPhase = 'crmPhase',
    botElement = 'crmBot',
    contentGenerator = 'crmCont',
    campaignAction = 'camp',
    attRegistry = 'attRegistry',
    // formulary = 'form',
    asyncProcessor = 'asyncProcessor',
}
export interface IListenerElement {
    type: ESubscribedTo;
    /**
     * ID do elemento subscrito, pode ser uma ação de campanha
     * resposta de formulário(gerador de conteúdo) ou item de menu
     * de um bot.
     */
    idSubscribedTo: string;
    /**
     * Caso o tipo seja ESubscribedTo.attRegistry
     * Essa informação é útil para:
     * Client: uma busca mais rápida do nome do registro
     * Server: Gerar a depêndencia
     */
    idMacroPackge?: string;
}
// trocar para listener
export type TListeners = Array<IListenerElement>;

export interface IBPMNodeModelBase extends IBPMTimerModelBase, IBPMListenerModelBase, IBPMActionModelBase {
}

export type TIBPMBaseNodeServerArray = Array<IBPMBaseNodeServer>

export interface IBPMRootBase {
    allSubscriptions?: TGlobalUID[];
}

export interface IBPMBaseNodeServer extends IBPMNodeModelBase, INonSerializable { }
export interface IBPMRootBaseNodeServer extends IBPMRootBase, IBPMBaseNodeServer { }

export interface IBPMListenerModelBase<ConditionsForms extends TIBPConditionalEvaluatorArray = TIBPConditionalEvaluatorArray> {
    listenTo: IListenerElement;  // Quem essa etapa será Listener
    /**
     * Condições que determinam a execução ou não do próximo nó
     */
    listenConditions: ConditionsForms;
}

export interface IBPMTimerModelBase {
    /**
     * Tempo em milisegundos, usado como uma "pausa"
     * para a execução do próximo nó.
     */
    bpmScheduleMS: number;

    // No Marketing, por enquanto sem uso
    actionEnabled?: boolean;
    timerAction?: TIBPConditionalEvaluatorArray;
}

export interface IBPMActionModelBase<FormsAction extends TIBPConditionalEvaluatorArray = TIBPConditionalEvaluatorArray> {
    /**
     * Condições que determinam a execução da ação de negócio(bpmAction).
     */
    condFormsAction: FormsAction;
};

export type TIBPMActionModelServerArray = Array<IBPMBaseNodeServer>;
export type TBPMNodesNser = IBPMBaseNodeServer//IBPMActionModelServer | IBPMTimerBaseServer | IBasicListenerModelServer;
export type TBPMNodesNSerArray = Array<TBPMNodesNser>;

export type TBPMBusinessEventTypes = EConfirmationType | ESubscribedTo | EBPMSubEvent;

export type TBPMBusinessEventTypesArray = Array<TBPMBusinessEventTypes>;

export enum EBPMSubEvent {
    expire = 'exp'
}

interface IBPMBusinessTypeConfig {
}

export const processableBPMBusinessEventTypes: TBPMBusinessEventTypes[] = [
    ...values(ESubscribedTo),
    EConfirmationType.colmeiaSent,
    EConfirmationType.read,
    EConfirmationType.respond,
    EConfirmationType.notRead,
    EConfirmationType.notAnswered,
    EConfirmationType.respond,
    EBPMSubEvent.expire,
];

export const bpmBusinessTypeConfigDB: { [key in TBPMBusinessEventTypes]?: IBPMBusinessTypeConfig } = {
    [EConfirmationType.receive]: {},
};
