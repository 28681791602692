import { IRFieldResponse, TGeneralFieldArray } from "../../general-form/general-form-answer";
import { TSchemaPropertyArray } from "../../general-form/general-form-interface";
import { isValidString, typedClone } from "../../tools/utility";
import { getAnswerForProperty, getPropertyWithLocalCanonical, TComputedInfo } from "./metadata-utils";

export function mergeFormsByCanonical(sourceResponses: TGeneralFieldArray, targetProperties: TSchemaPropertyArray, targetResponses: TGeneralFieldArray): boolean {
    let targetFilled: boolean = false;

    sourceResponses.forEach(response => {
        if (response.idLocalCanonical) {
            const property = getPropertyWithLocalCanonical(targetProperties, response.idLocalCanonical);
            if (property) {
                const answer: IRFieldResponse = getAnswerForProperty(targetResponses, property, response.idGlobalCanonical);
                answer.value = response.value;
                answer.raw = response.raw;
                targetFilled = true;
            }
        }
    });
    return targetFilled;
}


export function mergeIdenticalForm(sourceResponses: TGeneralFieldArray, targetResponses: TGeneralFieldArray,  targetProperties: TSchemaPropertyArray): boolean {
    let targetFilled: boolean = false;

    sourceResponses.forEach(response => {
        
        const index: number = targetResponses.findIndex((r) => {return r.idProperty === response.idProperty});
        const clone =  typedClone<IRFieldResponse>(response);
        if (index > 0) {
            targetResponses[index] = clone;
            targetFilled = true;
        } else {
            if (! targetFilled) {
                // se fossem dois formulários completamente distintos não iria ter campos em comum
                targetFilled = targetProperties.some((p) => {return p.idProperty === response.idProperty})
            }
            targetResponses.push(clone)
        }
    });
    return targetFilled;
}

export function mergeResponseWithBotComputedState(sourceResponses: TGeneralFieldArray, allFieldMap?: TComputedInfo) {
    sourceResponses.forEach( res => {
        const isFieldAlreadyResponded = isValidString(res.raw);
        if(isFieldAlreadyResponded) return;

        const valueByIdCanonical = allFieldMap[res.idLocalCanonical];
        const valueByIdGlobal = allFieldMap[res.idGlobalCanonical];
        const valueByIdProperty = allFieldMap[res.idProperty];

        res.value = valueByIdCanonical;
        res.value ||= valueByIdGlobal;
        res.value ||= valueByIdProperty;
    });

}