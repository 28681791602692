import { Component, Input, Inject } from '@angular/core';
import { IFilterlet } from '@colmeia/core/src/rules/dynamic-filter/dynamic-filter-interfaces';
import { isInvalid } from '@colmeia/core/src/tools/utility';
import { RootComponent } from 'app/components/foundation/root/root.component';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { filterletToken, chatFilterEventsToken, IChatFilterEvents } from 'app/model/chat-filter.model';
import { ITasksFilter } from '@colmeia/core/src/rules/dynamic-filter/dynamic-filter';
import { first } from 'rxjs/operators';
import { commonScreenOptions } from '@colmeia/core/src/shared-business-rules/const-text/views/txt-common';
import { gTranslations } from '@colmeia/core/src/shared-business-rules/const-text/translations';

@Component({
	selector: 'app-chat-task-filter',
	templateUrl: './chat-task-filter.component.html',
	styleUrls: ['./chat-task-filter.component.scss']
})
export class ChatTaskFilterComponent extends RootComponent< 'pendency' | 'demand' | 'opened'  > {

	tasks: ITasksFilter;

	constructor(
		@Inject(filterletToken) public filterlet: IFilterlet,
		@Inject(chatFilterEventsToken) private events: IChatFilterEvents,
	) {
		super({
			pendency: gTranslations.common.pendency,
			demand: gTranslations.common.demand,
			opened: gTranslations.common.opened,
		});
	}

	ngOnInit() {
		if (isInvalid(this.filterlet.tasks)) {
			this.filterlet.tasks = {
				isDemand: false,
				isOpened: false,
				isPendency: false,
			}
		}
		this.tasks = { ...this.filterlet.tasks };
		this.events.applied.pipe(first()).subscribe(() => this.filterlet.tasks = this.tasks);
	}

	pendencyChange(event: MatCheckboxChange) {
		this.tasks.isPendency = event.checked;
	}

	demandChange(event: MatCheckboxChange) {
		this.tasks.isDemand = event.checked;
	}

	openedChange(event: MatCheckboxChange) {
		this.tasks.isOpened = event.checked;
	}

}
