import { VisibleHorizontalComponent } from './../visible/visible-horizontal.component';
import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Inject, Input, OnChanges, OnInit, Optional, Output, ChangeDetectionStrategy } from '@angular/core';
import { MatSnackBarRef, MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';
import { isValidRef } from '@colmeia/core/src/tools/utility';
import { UniqueSelectionDispatcher } from '@angular/cdk/collections';

export enum EAppAlertTypes {
    Primary = "primary",
    Accent = "accent",
    Information = "info",
    Success = "success",
    Warning = "warning",
    Error = "error"
}

const typesMatIconsMap: { [key in EAppAlertTypes]: string | null } = {
    [EAppAlertTypes.Primary]: null,
    [EAppAlertTypes.Accent]: null,
    [EAppAlertTypes.Information]: "info",
    [EAppAlertTypes.Success]: "task_alt",
    [EAppAlertTypes.Warning]: "warning_amber",
    [EAppAlertTypes.Error]: "error_outline",
}

export interface IAppAlertSettings {
    type: EAppAlertTypes;
    stroked?: boolean;
    outline?: boolean;
    dense?: boolean;
    hideIcon?: boolean;
    darker?: boolean;
    message?: string;
    action?: string;
    actionCallback?: () => void;
}

@Component({
    selector: 'app-alert',
    templateUrl: './alert.component.html',
    styleUrls: ['./alert.component.scss'],
    exportAs: "appAlert",
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AlertComponent extends VisibleHorizontalComponent implements OnInit, OnChanges, IAppAlertSettings {
    classes: string[] = [];

    @Input() type: EAppAlertTypes;
    @Input() title: string;
    @Input() matIcon: string;
    @Input() action: string;
    @Output() onAction = new EventEmitter<void>();

    /** Flasg */
    private _stroked: boolean = false;
    private _closable: boolean = false;
    private _dense: boolean = false;
    private _hideIcon: boolean = false;
    private _darker: boolean = false;
    private _light: boolean = false;
    private _preFormatted = false;
    private _fadedBackground = false;

    @Input() set stroked(value: boolean) { this._stroked = coerceBooleanProperty(value) };
    @Input() set outline(value: boolean) { this._stroked = coerceBooleanProperty(value) }; // stroked alias
    @Input() set dense(value: boolean) { this._dense = coerceBooleanProperty(value) };
    @Input() set hideIcon(value: boolean) { this._hideIcon = coerceBooleanProperty(value) };
    @Input() set darker(value: boolean) { this._darker = coerceBooleanProperty(value) };
    @Input() set light(value: boolean) { this._light = coerceBooleanProperty(value) };
    @Input() set closable(value: boolean) { this._closable = coerceBooleanProperty(value) };
    @Input() set preFormatted(value: boolean) { this._preFormatted = coerceBooleanProperty(value) };
    @Input() set fadedBackground(value: boolean) { this._fadedBackground = coerceBooleanProperty(value) };


    get stroked() { return this._stroked };
    get dense() { return this._dense };
    get hideIcon() { return this._hideIcon };
    get darker() { return this._darker };
    get light() { return this._light };
    get closable() { return this._closable };
    get preFormatted() { return this._preFormatted };
    get fadedBackground() { return this._fadedBackground };

    public message: string;
    public isASnack: boolean = false;

    constructor(
        public ref: ElementRef,
        private _cdr: ChangeDetectorRef,
        readonly elementRef: ElementRef,
        protected _expansionDispatcher: UniqueSelectionDispatcher,
        @Optional() private snackBarRef: MatSnackBarRef<AlertComponent>,
        @Optional() @Inject(MAT_SNACK_BAR_DATA) snackData: IAppAlertSettings) {
        super(_cdr, elementRef, _expansionDispatcher);

        if (isValidRef(snackBarRef) && isValidRef(snackData)) {
            Object.assign(this, snackData);
            this.isASnack = true;
        }

    }

    ngOnInit() {
        this.update();

        this.visible = true;
    }

    ngOnChanges() {
        this.update();
    }

    private update() {
        this.setIcon();
        this.classes = [`app-alert-${this.type}`];

        if (this.darker) this.classes.push('alert-darker')
        if (this.light) this.classes.push('alert-light')
        if (this.stroked) this.classes.push('alert-stroked')
        if (this.hideIcon) this.classes.push('alert-hide-icon')
        if (this.dense) this.classes.push('alert-dense')
        if (this.fadedBackground) this.classes.push('alert-faded-background')

        this._cdr.markForCheck();
    }

    private setIcon() {
        this.matIcon = this.matIcon || typesMatIconsMap[this.type];
    }

    public onActionClick() {
        if (this.isASnack) {
            this.snackBarRef.dismissWithAction();
            return;
        }

        if (this.closable) {
            this.hide();
            return;
        }

        this.onAction.emit();
    }

    static ngAcceptInputType_stroked: BooleanInput
    static ngAcceptInputType_outline: BooleanInput
    static ngAcceptInputType_dense: BooleanInput
    static ngAcceptInputType_hideIcon: BooleanInput
}
