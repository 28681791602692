import { EBPMType } from "@colmeia/core/src/shared-business-rules/BPM/bpm-model";
import { ESubscribedTo } from "@colmeia/core/src/shared-business-rules/BPM/common/common-bpm";
import { IBPMMarketingAcknowledgeServer, IBPMMarketingActionServer, IBPMMarketingEndServer, IBPMMarketingListenerServer, IBPMMarketingPauseServer, IBPMMarketingRootServer } from "@colmeia/core/src/shared-business-rules/BPM/marketing/bpm-marketing.model";
import { IMKTHostFactoryInfo } from "@colmeia/core/src/shared-business-rules/graph-transaction/host/ns/ns.host.types";
import { gDefaultRenderData } from "@colmeia/core/src/shared-business-rules/graph/essential/graph-types";
import { ENonSerializableObjectType } from "@colmeia/core/src/shared-business-rules/non-serializable-id/non-serializable-id-interfaces";
import { MarketingBpmAckComponent } from "app/components/dashboard/marketing-bpm/marketing-bpm-ack/marketing-bpm-ack.component";
import { MarketingBpmActionComponent } from "app/components/dashboard/marketing-bpm/marketing-bpm-action/marketing-bpm-action.component";
import { MarketingBpmEndComponent } from "app/components/dashboard/marketing-bpm/marketing-bpm-end/marketing-bpm-end.component";
import { MarketingBpmListenerComponent } from "app/components/dashboard/marketing-bpm/marketing-bpm-listener/marketing-bpm-listener.component";
import { MarketingBpmPauseComponent } from "app/components/dashboard/marketing-bpm/marketing-bpm-pause/marketing-bpm-pause.component";
import { MarketingBpmRootComponent } from "app/components/dashboard/marketing-bpm/marketing-bpm-root/marketing-bpm-root.component";
import { IBpmGraphCreateParameters, MKTAckBpmGraphCreateHandler, MKTActionBpmGraphCreateHandler, MKTEndBpmGraphCreateHandler, MKTListenerBpmGraphCreateHandler, MKTPauseBpmGraphCreateHandler, MKTRootBpmGraphCreateHandler } from "app/model/bpm-graph-handler.model";
import { IUpserterComponentBehavior } from "../bpm-rules.types";


export interface IMKTFrontFactoryInfo extends IMKTHostFactoryInfo {
    idParent: string;
}

export class MktFrontEndDialogFactory {
    static create(info: IMKTFrontFactoryInfo) {
        const defaultParams: IBpmGraphCreateParameters = {
            bpmType: EBPMType.marketing,
            renderData: gDefaultRenderData,
            clientCallback: undefined,
        }

        switch (info.hostType) {
            case ENonSerializableObjectType.bpmMarketingRoot:
                return {
                    behavior: IUpserterComponentBehavior.Dialog,
                    component: MarketingBpmRootComponent,
                    handler: MKTRootBpmGraphCreateHandler.create({
                        ...defaultParams,
                        mktRoot: info.ns as IBPMMarketingRootServer
                    })
                }
            case ENonSerializableObjectType.bpmMarketingAction:
                return {
                    behavior: IUpserterComponentBehavior.Dialog,
                    component: MarketingBpmActionComponent,
                    handler: MKTActionBpmGraphCreateHandler.create({
                        ...defaultParams,
                        mktAction: info.ns as IBPMMarketingActionServer,
                        idParent: info.idParent,
                    })
                }
            case ENonSerializableObjectType.bpmMarketingPause:
                return {
                    behavior: IUpserterComponentBehavior.Dialog,
                    component: MarketingBpmPauseComponent,
                    handler: MKTPauseBpmGraphCreateHandler.create({
                        ...defaultParams,
                        mktPause: info.ns as IBPMMarketingPauseServer,
                        idParent: info.idParent,
                    })
                }
            case ENonSerializableObjectType.bpmMarketingListener:
                return {
                    behavior: IUpserterComponentBehavior.Dialog,
                    component: MarketingBpmListenerComponent,
                    handler: MKTListenerBpmGraphCreateHandler.create({
                        ...defaultParams,
                        idParent: info.idParent,
                        mktListener: info.ns as IBPMMarketingListenerServer
                    })
                }
            case ENonSerializableObjectType.bpmMarketingEnd:
                return {
                    behavior: IUpserterComponentBehavior.Dialog,
                    component: MarketingBpmEndComponent,
                    handler: MKTEndBpmGraphCreateHandler.create({
                        ...defaultParams,
                        idParent: info.idParent,
                        mktEnd: info.ns as IBPMMarketingEndServer
                    })
                }
            case ENonSerializableObjectType.contentGenerator:
            case ENonSerializableObjectType.bpmMarketingAcknowledge:
                return {
                    behavior: IUpserterComponentBehavior.Dialog,
                    component: MarketingBpmAckComponent,
                    handler: MKTAckBpmGraphCreateHandler.create({
                        ...defaultParams,
                        idParent: info.idParent,
                        mktAck: info.ns as IBPMMarketingAcknowledgeServer,
                        eSubscribeType: info.hostType === ENonSerializableObjectType.contentGenerator && ESubscribedTo.contentGenerator,
                    })
                }
            default:
                throw new Error('getUpserterDialogComponent info.type not found');
        }
    }
}
