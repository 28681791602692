import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MacroMenuToggleService {

    // private macroMenuEnabled = false
    private macroMenuEnabledSubject = new BehaviorSubject<boolean>(false)
    macroMenuEnabled$ = this.macroMenuEnabledSubject.asObservable()

    toggleMacroMenu(): void {
        this.macroMenuEnabledSubject.next(!this.macroMenuEnabledSubject.value)
    }

    setMacroMenuState(value: boolean): void {
        this.macroMenuEnabledSubject.next(value)
    }

    isMacroMenuEnabled(): boolean {
        return this.macroMenuEnabledSubject.value
    }

  constructor() { }


}
