import { EBPMType } from "@colmeia/core/src/shared-business-rules/BPM/bpm-model";
import { HostNodeFactory } from "@colmeia/core/src/shared-business-rules/graph-transaction/host/factory/host-node.factory";
import { NSGenericHost } from "@colmeia/core/src/shared-business-rules/graph-transaction/host/ns/ns.host";
import { IHostNodeDeletionResult } from "@colmeia/core/src/shared-business-rules/graph-transaction/host/ns/ns.host.types";
import { BPMConfigToolbarSelector } from "@colmeia/core/src/shared-business-rules/graph-transaction/toolbar/bpm-config-toolbar-selector";
import { EAdditionalPredicateType, EBPMElementMode, IBasicToolbarElement, IToolbarElement, TReferencedObject } from '@colmeia/core/src/shared-business-rules/graph-transaction/toolbar/config-toolbar.types';
import { TBasicElementArray } from "@colmeia/core/src/shared-business-rules/graph/essential/basic-element";
import { IGraphElementRenderData } from "@colmeia/core/src/shared-business-rules/graph/essential/diagram.interfaces";
import { IBasicElementClient } from "@colmeia/core/src/shared-business-rules/graph/essential/graph-basic-element-interfaces";
import { graphElementContainerSize } from "@colmeia/core/src/shared-business-rules/graph/essential/graph-constants";
import { GraphElement } from "@colmeia/core/src/shared-business-rules/graph/essential/graph-element";
import { EGraphElementAction, GraphElementActionSaver, TGraphElementActionDescriptorList } from '@colmeia/core/src/shared-business-rules/graph/essential/graph-element-action.types';
import { IGraphElementJSON } from "@colmeia/core/src/shared-business-rules/graph/essential/graph-interfaces";
import { GraphRulesProcessor, IVisualBPMComponentCallback } from "@colmeia/core/src/shared-business-rules/graph/essential/graph-rules-processor";
import { ECommonEvents, EGraphElementType, IGraphConnectionData, IInternalElementRenderData, INextStepInfoRenderData, TIGraphConnectionDataArray, TRenderData } from "@colmeia/core/src/shared-business-rules/graph/essential/graph-types";
import { findOffsetsFor } from "@colmeia/core/src/shared-business-rules/graph/essential/graph.functions";
import { GraphPredicate, TGraphPredicateArray } from "@colmeia/core/src/shared-business-rules/graph/essential/predicate";
import { INonSerializable, TINonSerializableArray } from "@colmeia/core/src/shared-business-rules/non-serializable-id/non-serializable-id-interfaces";
import { arrayUnique, flat, flatMap, isEqual, isInvalid, isInvalidArray, isValidArray, isValidRef, isValidString, keys, objectShallowReplace, typedClone, values } from "@colmeia/core/src/tools/utility";
import { LookupService } from "app/services/lookup.service";
import { RoutingService } from "app/services/routing.service";
import { SnackMessageService } from "app/services/snack-bar";
import { BpmApiService } from "../api/bpm-bot-api.service";
import { BpmRulesBotImportGraphService } from "./bpm-rules-bot-import-graph.service";
import { IBPMDragInto, IBPMRulesBase, IBPMSubjectRulesListener, IBPMSubjectRulesProcessorMethods, ICanRemoveReturn, IDraggableReturn, IExtraDialogData, INewGraphElementFromDraggedToolbarIcon, INewGraphElementFromDraggedToolbarIconBasic, IToolbarElementDraggedOnExistingNode, IUpserterComponentBehavior, IUpserterComponentReturn, IUpserterNSComponentInfo, TBpmRulesDataService, TConnectElementsResult } from "./bpm-rules.types";
import { ENodeRenderType } from 'app/components/dashboard/ai-pages/bpm-graph/diagram-editor/react-diagram-editor/react-nodes.model';
import { TNserUID } from '@colmeia/core/src/core-constants/types';
import { IBPMDialogResult } from "app/components/dashboard/ai-pages/bpm-graph/diagram-editor/diagram-editor.types";
import { DiagramEditorDialogService } from "app/components/dashboard/ai-pages/bpm-graph/diagram-editor/services/diagram-editor-dialog.service";
import { BpmGraphNodeDetailsService } from "app/components/dashboard/ai-pages/bpm-graph/bpm-graph-node-details/bpm-graph-node-details.service";


export abstract class BPMRulesBase implements IBPMSubjectRulesProcessorMethods {

    private params: IBPMRulesBase
    public graphProcessor: GraphRulesProcessor
    public listener: IBPMSubjectRulesListener;
    public mapIdToSavedRenderData: Map<string, IBasicElementClient> = new Map();

    public constructor(
        readonly bpmType: EBPMType,
        bpmBotApiService: BpmApiService,
        bpmRulesBotDataService: TBpmRulesDataService,
        bpmRulesBotImportGraphSvc: BpmRulesBotImportGraphService,
        snackSvc: SnackMessageService,
        nserLookupService: LookupService,
        protected routingSvc: RoutingService,
        protected diagramEditorDialogSvc: DiagramEditorDialogService,
        protected bpmGraphNodeDetailsSvc: BpmGraphNodeDetailsService
    ) {
        this.params = {
            bpmType,
            bpmBotApiService,
            bpmRulesBotDataService,
            bpmRulesBotImportGraphSvc,
            snackSvc,
            nserLookupService
        }
    }

    public abstract onToolbarElementDraggedOnExistingNode(info: IToolbarElementDraggedOnExistingNode);
    public abstract refreshPosActionState(
        isRoot: boolean, nser: INonSerializable, oldNser: INonSerializable,
        updatedElement: GraphElement, batchSaver: GraphElementActionSaver, connectionType: TReferencedObject
    ): Promise<void>;
    public abstract updateSequencedGraphNodes(sequencedGraphList: GraphElement[]): NSGenericHost[];
    public abstract createNewGraphNode(info: INewGraphElementFromDraggedToolbarIconBasic): Promise<GraphElement>;
    public abstract getUpserterComponent(info: IUpserterNSComponentInfo): Promise<IUpserterComponentReturn>;
    public abstract performGraphImportSteps(idBotRoot: string, idRootGraphElement: string);
    public abstract handleEditedNserFromDialogByUser(graphElement: GraphElement, updatedElementNser?: INonSerializable): Promise<void>;
    public abstract onUpdateElement(element: GraphElement, renderData: TRenderData): void;
    public abstract handleConnectionAdditionByUser(from: GraphElement, to: GraphElement, connectionData: IGraphConnectionData): Promise<void>; // toolbarType = actionType on bot
    public abstract canConnectElements(from: GraphElement, to: GraphElement): TConnectElementsResult; // toolbarType = actionType on bot
    public abstract onToolbarElementDraggedOnDiagram(info: IUpserterNSComponentInfo): Promise<void>;
    public abstract updatePosActionRemoval(graphElementList: TBasicElementArray): TGraphElementActionDescriptorList
    public abstract getInternalIconsFromNode(graphElement: GraphElement): IInternalElementRenderData[];
    public abstract getNextStepInfoFromNode(graphElement: GraphElement): INextStepInfoRenderData;
    public abstract getNodeColor(graphElement: GraphElement): string;
    public abstract canRemovePredicate(predicate: GraphPredicate): boolean;
    public abstract handleConnectionRemovalByUser(predicate: GraphPredicate): Promise<void>;
    public abstract dragInto(drag: IBPMDragInto): Promise<IDraggableReturn>;
    public abstract isDraggableOnDiagram(botType: TReferencedObject): boolean;
    public abstract updateOrderedPosition(graphElement: GraphElement): TGraphElementActionDescriptorList;
    public abstract importPostProcessor(): Promise<void>
    public abstract isDraggableInto(graphElement: GraphElement, droppedType: TReferencedObject): boolean;
    public abstract handleInternalToolbarElementDragOnElementByUser(
        targetElement: GraphElement,
        draggedType: TReferencedObject,
        draggedNS?: INonSerializable,
        extraData?: IExtraDialogData,
    ): void
    public abstract needLeftPushOnAutoImport(type: TReferencedObject): boolean;
    public abstract getAdditionalElementToBeRemoved(updatedElement: GraphElement, connectionData?: IGraphConnectionData, connectionsToRemove?: TIGraphConnectionDataArray): TBasicElementArray;
    public abstract onDraggedToolbarElementExternalOnDiagramByUser(): void;
    public abstract onDraggedToolbarElementInternalOnDiagramByUser(): void;
    public abstract onDraggedToolbarElementOnExistingNodeByUser(): void;
    public abstract onEditedNodeByUser(): void;
    public abstract onDeletedNodeByUser(): void;

    private isNewGraph: boolean = true;
    public setGraphRulesProcessor(graphRulesProcessor: GraphRulesProcessor): void {
        this.graphProcessor = graphRulesProcessor
        this.isNewGraph = !this.hasRoot()
    }

    public getGraphRulesProcessor(): GraphRulesProcessor {
        return this.graphProcessor
    }

    isValidConnectionInDB(fromType: TReferencedObject, toType: TReferencedObject): boolean {
        return BPMConfigToolbarSelector.isValidConnectionInBPM(this.params.bpmType, fromType, toType)
    }

    getOneToolbarElement(bpmObj: TReferencedObject): IBasicToolbarElement<TReferencedObject> {
        return BPMConfigToolbarSelector.getOneToolbarElement(this.params.bpmType, bpmObj);
    }

    getAllToolbarElements(): IBasicToolbarElement[] {
        const result = BPMConfigToolbarSelector.getConfigToolbarElements(this.params.bpmType);

        return result;
    }

    getAllToolbarElementsCloned(): IBasicToolbarElement[] {
        return typedClone(BPMConfigToolbarSelector.getConfigToolbarElements(this.bpmType))
    }

    public async handleElementPositionChangedByUser(graphElement: GraphElement, updatedPosition: Pick<IBasicToolbarElement["renderData"], "offsetX" | "offsetY">): Promise<void> {
        const actionSaver = GraphElementActionSaver.create();
        const elementToolbarConfig: IBasicToolbarElement = this.getOneToolbarElement(graphElement.getHostedType());

        const {
            isSequenced,
            moveChildrenTogether,
        } = elementToolbarConfig;

        if (moveChildrenTogether) {
            actionSaver.concatChangedNodes(this.moveChildren(graphElement, updatedPosition));
        }

        graphElement.setRenderData({
            ...graphElement.getRenderData(),
            offsetX: updatedPosition.offsetX,
            offsetY: updatedPosition.offsetY
        });

        if (isSequenced) {
            actionSaver.concatChangedNodes(this.updateOrderedPosition(graphElement));
        }

        actionSaver.addSaveActionToElementList(graphElement)
        await this.finishTransaction(actionSaver.getChangedNodes())
    }

    public async handleElementPositionChangedProgramatically(graphElements: GraphElement[]): Promise<void> {
        const actionSaver = GraphElementActionSaver.create();

        actionSaver.addSaveActionToElementList(graphElements);

        await this.finishTransaction(actionSaver.getChangedNodes())
    }

    public moveChildren(
        parentElement: GraphElement,
        updatedPosition: Pick<IBasicToolbarElement["renderData"], "offsetX" | "offsetY">
    ): TGraphElementActionDescriptorList {
        const actionSaver = GraphElementActionSaver.create();
        const parentRenderData = parentElement.getRenderData();
        const offsetXDiff = updatedPosition.offsetX - parentRenderData.offsetX;
        const offsetYDiff = updatedPosition.offsetY - parentRenderData.offsetY;
        const childrenNodes = parentElement.getNeighborsThatIPointTo();

        childrenNodes.forEach((element) => {
            const renderData = element.getRenderData();

            element.setRenderData({
                ...renderData,
                offsetX: renderData.offsetX + offsetXDiff,
                offsetY: renderData.offsetY + offsetYDiff
            });

            actionSaver.addSaveActionToElementList(element);
        });

        return actionSaver.getChangedNodes();
    }

    public async createGraphElementAndPersistToServer(info: INewGraphElementFromDraggedToolbarIcon) {
        const actionSaver = GraphElementActionSaver.create()

        const newGraphNode = await this.createNewGraphNode(info)
        actionSaver.addSaveActionToElementList(newGraphNode)

        const graphNodesToChange = await this.createAndConnectToNewNodeByAction(newGraphNode)
        actionSaver.concatChangedNodes(graphNodesToChange)

        await this.finishTransaction(actionSaver.getChangedNodes())
    }

    async finishTransaction(changedNodes: TGraphElementActionDescriptorList): Promise<void> {
        try {

            this.syncPredicatesInputOutputNames(changedNodes);

            const result = await this.params.bpmBotApiService.batchPersistElements(
                this.bpmType,
                changedNodes,
                this.graphProcessor
            );

            if (this.isNewGraph) {
                this.routingSvc.goToRootGraphElement(
                    this.graphProcessor.getRootElementId(),
                    this.bpmType,
                    false,
                    this.graphProcessor.getRootElement().getHostedID(),
                    // rootElementSkaved.element.idHostedObject,
                    true,
                );
                return;
            }

            this.updateAllNodesHostedObjects(values(result.savedElements));

            this.getDiagramComponentInstance().renderBatchElements(changedNodes)

        } catch (err) {
            console.error("finishTransaction", err);
            this.params.snackSvc.openError('Ocorreu um erro ao tentar salvar ou renderizar componentes na tela');
            this.getDiagramComponentInstance().rollbackDiagramState();
        }
    }

    private syncPredicatesInputOutputNames(descriptors: TGraphElementActionDescriptorList) {

        for (const descriptor of descriptors) {

            if (!isValidArray(descriptor.elementList)) continue;

            const allGraphNodes = <GraphElement[]>(descriptor.elementList.filter(el => el instanceof GraphElement));

            for (const graphElement of allGraphNodes) {
                const predicatesToOthers: TGraphPredicateArray = graphElement.getDrawableConnectionsToOthers();
                for (const predicate of predicatesToOthers) {
                    const nameChanged = predicate.getInputName() !== graphElement.getName();

                    if (nameChanged) {
                        predicate.setInputName(graphElement.getName());
                        descriptor.elementList.push(predicate);
                    }
                }
                const predicatesFromOthers: TGraphPredicateArray = graphElement.getDrawableConnectionsFromOthers();
                for (const predicate of predicatesFromOthers) {
                    const nameChanged = predicate.getOutputName() !== graphElement.getName();

                    if (nameChanged) {
                        predicate.setOutputName(graphElement.getName());
                        descriptor.elementList.push(predicate);
                    }
                }
            }

        }



    }

    private getDiagramComponentInstance(): IVisualBPMComponentCallback {
        return this.getGraphRulesProcessor().getVisualComponentCallback()
    }

    //#region graph delete methods

    public async removeGraphElementNodeByUser(graphElementList: TBasicElementArray): Promise<void> {
        const actionSaver = GraphElementActionSaver.create()

        const changedNodesPosAction = this.updatePosActionRemoval(graphElementList)
        actionSaver.concatChangedNodes(changedNodesPosAction)

        graphElementList.forEach(node => {
            const changedNodesRemoval = this.removeGraphElementNode(<GraphElement>node)
            actionSaver.concatChangedNodes(changedNodesRemoval)
        });

        const changedParent = this.deleteNeighboursPointingToMeAndCleanUpParentNodeState(graphElementList)
        actionSaver.concatChangedNodes(changedParent)

        const elementsToRemove = this.removeGraphNodeElementsFromModel(graphElementList)
        actionSaver.concatChangedNodes(elementsToRemove)

        await this.finishTransaction(actionSaver.getChangedNodes())
    }

    removeGraphElementNode(node: GraphElement) {
        const actionSaver = GraphElementActionSaver.create()

        if (BPMConfigToolbarSelector.isSequencedNode(this.params.bpmType, node)) {
            actionSaver.concatChangedNodes(this.updateElementsSequenceModel(node, true));
        }

        const aditionalNodesToRemove = this.getAdditionalElementToBeRemoved(node)
        actionSaver.addRemoveActionToElementList(aditionalNodesToRemove);

        this.graphProcessor.removeElementList(aditionalNodesToRemove);

        return actionSaver.getChangedNodes()
    }

    public removeGraphNodeElementsFromModel(graphElementList: TBasicElementArray): TGraphElementActionDescriptorList {
        const actionSaver = GraphElementActionSaver.create()

        const rootElement = this.graphProcessor.getRootElement();
        const [firstGraphElement] = graphElementList;
        const isRootRemoval = graphElementList.length === 1 && firstGraphElement.getGraphElementID() === rootElement.getGraphElementID();

        if (isRootRemoval) {
            actionSaver.addRemoveActionToElementList(rootElement);
        } else {
            const predicatesToRemove = this.graphProcessor.removeElementList(graphElementList)
                .map(deleted => [deleted.fromConnections, deleted.toConnections])
            const allPredicatesToRemove = flatMap(predicatesToRemove, x => flatMap(x, x => x))
            actionSaver.addRemoveActionToElementList(graphElementList)
            actionSaver.addRemoveActionToElementList(allPredicatesToRemove)
        }

        return actionSaver.getChangedNodes()
    }

    updateElementsSequenceModel(graphElement: GraphElement, ignoredSelf?: boolean): TGraphElementActionDescriptorList {
        const neighborsPointingToMe: GraphElement[] = graphElement.getNeighborsPointingToMe().map(el => el.neighbor)
        if (!isValidArray(neighborsPointingToMe)) {
            return []
        }

        const parentGraphElement: GraphElement = neighborsPointingToMe[0];
        const neighborsGraphElement: GraphElement[] = parentGraphElement.getNeighborsThatIPointTo();

        const graphNodesChanged: TGraphElementActionDescriptorList = []
        const sequencedGraphList: GraphElement[] = this.mountChildrenSequenceArray(neighborsGraphElement)
            .filter(element => ignoredSelf ? element.getGraphElementID() !== graphElement.getGraphElementID() : true);
        const updatedModels = this.updateSequencedGraphNodes(sequencedGraphList)

        graphNodesChanged.push({
            action: EGraphElementAction.Save,
            nsList: updatedModels,
            elementList: sequencedGraphList
        });
        return graphNodesChanged;
    }

    deleteNeighboursPointingToMeAndCleanUpParentNodeState(graphElementListToDelete: TBasicElementArray) {
        const actionSaver = GraphElementActionSaver.create()

        graphElementListToDelete.forEach((nodeToDelete: GraphElement) => {
            nodeToDelete.deleteNeighboursPointingToMe().forEach(deletedNode => {
                actionSaver.addSaveActionToHostedAndNode(deletedNode)
            })
        })

        return actionSaver.getChangedNodes()
    }

    private mountChildrenSequenceArray(graphElements: GraphElement[]): GraphElement[] {
        return graphElements
            .sort((nodeA, nodeB) => {
                const nodeARenderData = nodeA.getRenderData();
                const nodeBRenderData = nodeB.getRenderData();

                if (nodeARenderData.offsetY < nodeBRenderData.offsetY) {
                    return -1;
                }

                if (nodeARenderData.offsetY > nodeBRenderData.offsetY) {
                    return 1;
                }

                return 0;
            });
    }

    //#endregion

    private isElementChangedAndMustUpdateConnections(oldElement: GraphElement, editedElement: NSGenericHost) {
        const oldActions = typedClone(oldElement.getHostObject().getActionContainer());
        const newActions = typedClone(editedElement.getActionContainer());

        const isRoot: boolean = oldElement.getHostObject().isRoot()
        const isRootElementNameEdited: boolean = isRoot
            && oldElement.getName() !== editedElement.getHostedName()
        if (isRootElementNameEdited) oldElement.setName(editedElement.getHostedName())
        oldElement.setHostObject(editedElement);

        const updatedElement: GraphElement = oldElement;
        const updatedHostObject = updatedElement.getHostObject();

        const hasActionsChanged = !isEqual(oldActions, newActions)
        const connectionsData = updatedHostObject.getConnectionTargetData();
        const anyActionDemandsAnUpdate = () => connectionsData.some(
            connection => (updatedHostObject.mustUpdateConnections(connection.connectionType))
        );
        const isElementChangedAndMustUpdateConnections = hasActionsChanged && (connectionsData.length === 0 || anyActionDemandsAnUpdate());

        return { isElementChangedAndMustUpdateConnections, connectionsData, updatedElement }
    }

    public async handleEditedNodeNserModelUpdateGraph(oldElement: GraphElement, editedElement: NSGenericHost): Promise<TGraphElementActionDescriptorList> {
        const batchSaver = GraphElementActionSaver.create();
        const oldNser: INonSerializable = oldElement.getHostObject().getNonSerializable()
        const oldConnections = oldElement.getHostObject().getConnectionTargetData();
        const {
            isElementChangedAndMustUpdateConnections, connectionsData,
            updatedElement
        } = this.isElementChangedAndMustUpdateConnections(oldElement, editedElement)

        const { removed, newOrUpdated } = this.getRemovedNewAndUpdatedConnections(oldConnections, connectionsData);
        const changedConnectionsData = [...removed, ...newOrUpdated];

        if (isElementChangedAndMustUpdateConnections) {
            for (const connectionData of changedConnectionsData) {
                const targetElement: GraphElement = this.getGraphRulesProcessor()
                    .findNodeElementByHostedId(connectionData.targetHostId);
                const isElementPresentInTheGraph: boolean = isValidRef(targetElement)

                const elementsToRemove = this.removeAllNecessaryChildrenEdgesAndNodes(updatedElement, targetElement, connectionData, removed)
                batchSaver.concatChangedNodes(elementsToRemove);

                const isRemovalOperating = removed.some(connectionToRemove => isEqual(connectionToRemove, connectionData));
                if (!isRemovalOperating) {
                    if (isElementPresentInTheGraph) {
                        const changedElements = this.upsertConnections(updatedElement, targetElement, connectionData);
                        batchSaver.concatChangedNodes(changedElements);
                    } else {
                        const newElements = await this.createAndConnectToNewNodeByAction(updatedElement, connectionData)
                        batchSaver.concatChangedNodes(newElements);
                    }
                }
                await this.refreshPosActionState(oldElement.getHostObject().isRoot(),
                    updatedElement.getHostObject().getNonSerializable(), oldNser,
                    updatedElement, batchSaver, connectionData.connectionType)
            }
        }
        batchSaver.addSaveActionToElementList(updatedElement)
        batchSaver.addSaveActionToNserList(updatedElement.getHostObject())
        const result = batchSaver.getChangedNodes()
        return result
    }

    private getRemovedNewAndUpdatedConnections(oldConnections: TIGraphConnectionDataArray, newConnections: TIGraphConnectionDataArray) {
        const removed = oldConnections.filter(connection => !newConnections.find(con => isEqual(con, connection)));
        const newOrUpdated = [];
        newConnections.forEach(newConnection => {
            const isAnOldConnection = oldConnections.find(
                oldConnection => isEqual(oldConnection, newConnection)
            )
            if (!isAnOldConnection) newOrUpdated.push(newConnection);
        });
        return { removed, newOrUpdated };
    }

    async createAndConnectToNewNodeByAction(sourceNode: GraphElement, optionalConnectionData?: IGraphConnectionData) {
        const batchSaverExternal: GraphElementActionSaver = GraphElementActionSaver.create();
        const connectionsData = isValidRef(optionalConnectionData)
            ? [optionalConnectionData]
            : sourceNode.getHostObject().getConnectionTargetData();

        for (const targetElementConnectionData of connectionsData) {
            const batchSaver: GraphElementActionSaver = GraphElementActionSaver.create();
            const isNeededToCreateChildNodeAndConnectToIt = isValidRef(targetElementConnectionData)
                && isValidRef(targetElementConnectionData.targetHostId)
                && isValidRef(targetElementConnectionData.connectionType)
            if (isNeededToCreateChildNodeAndConnectToIt) {
                const newElements = await this.createRenderDataAndChildrenNodes(sourceNode, targetElementConnectionData)
                batchSaver.concatChangedNodes(newElements);
            } else {
                const removed = this.removeAllNecessaryChildrenEdgesAndNodes(sourceNode)
                batchSaver.concatChangedNodes(removed);
            }
            batchSaverExternal.concatChangedNodes(batchSaver.getChangedNodes());
        }

        return batchSaverExternal.getChangedNodes()
    }

    removeAllNecessaryChildrenEdgesAndNodes(sourceNode: GraphElement, existingTargetNode?: GraphElement, currentConnectionData?: IGraphConnectionData, connectionsToRemove?: TIGraphConnectionDataArray) {
        const batchSaver = GraphElementActionSaver.create();

        let nodesAndEdgesToRemove: TBasicElementArray = this.getAdditionalElementToBeRemoved(sourceNode, currentConnectionData, connectionsToRemove);
        if (isValidRef(existingTargetNode)) {
            let keepNode = true;
            if (isValidRef(currentConnectionData) && isValidArray(connectionsToRemove))
                keepNode = this.nodeHasAtleastOneConnectionOrIsTargetOfFutureOperation(existingTargetNode, connectionsToRemove, currentConnectionData);
            if (keepNode) nodesAndEdgesToRemove = nodesAndEdgesToRemove.filter(
                node => node.getGraphElementID() !== existingTargetNode.getGraphElementID()
            )
        }
        batchSaver.addRemoveActionToElementList(nodesAndEdgesToRemove)

        const removedConnections = this.getPredicatesToRemoveFromModel(sourceNode, currentConnectionData);
        batchSaver.concatChangedNodes(removedConnections);

        this.removeElementsFromRulesProcessorDBByDescriptor(batchSaver.getChangedNodes());
        return batchSaver.getChangedNodes();
    }

    protected nodeHasAtleastOneConnectionOrIsTargetOfFutureOperation(existingTargetNode: GraphElement, connetionsToOperateOn: TIGraphConnectionDataArray, currentConnectionData: IGraphConnectionData, ignoreSameOrigin: boolean = false, originElementId?: string) {
        let connectionsFromOthers = existingTargetNode.getConnectionsFromOthers().slice(1); // I remove one connection to account for the predicate we are already removing
        if (ignoreSameOrigin) connectionsFromOthers = connectionsFromOthers.filter(connection => !connection.isSameOrigin(originElementId));
        const nodeHasAtleastOneConnection = isValidArray(connectionsFromOthers);
        if (nodeHasAtleastOneConnection) return true;

        if (isInvalidArray(connetionsToOperateOn)) return false;
        const currConnIndex = connetionsToOperateOn.indexOf(currentConnectionData);
        const furtherConnections = connetionsToOperateOn.slice(currConnIndex + 1);
        const futureConnIndex = furtherConnections.findIndex(connection => connection.targetHostId === existingTargetNode.getHostedID());
        const nodeIsTargetOfFutureOperation = futureConnIndex !== -1;
        return nodeIsTargetOfFutureOperation; // Second check delays operating on a node if it is mentioned in a future operation
    }

    public upsertConnections(from: GraphElement, to: GraphElement, connectionData: IGraphConnectionData): TGraphElementActionDescriptorList {
        const actionSaver = GraphElementActionSaver.create()
        actionSaver.addSaveActionToElementList(from);

        const [, graphNodesToChange]: [GraphPredicate, TGraphElementActionDescriptorList] = this.updateConnections(
            from, to, connectionData)
        actionSaver.concatChangedNodes(graphNodesToChange)

        return actionSaver.getChangedNodes()
    }

    public updateConnections(
        from: GraphElement,
        to: GraphElement,
        connectionData: IGraphConnectionData,
    ): [GraphPredicate, TGraphElementActionDescriptorList] {
        const actionSaver = GraphElementActionSaver.create()
        const [graphPredicate, changedElementsFromConnect] = this.connectElementsModel(from,
            to,
            connectionData)
        actionSaver.concatChangedNodes(changedElementsFromConnect)

        return [graphPredicate, actionSaver.getChangedNodes()];
    }

    public connectElementsModel(
        from: GraphElement,
        to: GraphElement,
        connectionData: IGraphConnectionData
    ): [GraphPredicate, TGraphElementActionDescriptorList] {
        const actionSaver = GraphElementActionSaver.create()

        const graphPredicate = from.connectToOtherExistingNode(to, connectionData);
        graphPredicate.setSubElementId(connectionData.subElementId);
        actionSaver.addSaveActionToElementList(graphPredicate)

        return [graphPredicate, actionSaver.getChangedNodes()];
    }

    public abstract removeOldConnections(
        from: GraphElement,
        newPredicateType: TReferencedObject,
    ): TGraphElementActionDescriptorList

    public removeElementsFromRulesProcessorDBByDescriptor(descriptor: TGraphElementActionDescriptorList) {
        descriptor
            .filter(item => item.action === EGraphElementAction.Remove)
            .forEach((item) => {
                this.graphProcessor.removeElementList(item.elementList)
            });
    }

    public getPredicatesToRemoveFromModel(from: GraphElement, connectionData?: IGraphConnectionData): TGraphElementActionDescriptorList {
        const actionSaver = GraphElementActionSaver.create()
        const drawableToOthers: TGraphPredicateArray = from.getConnectionsToRemove()
        const hasSameOrigin = predicate => predicate.getSubElementId() === connectionData.subElementId;

        let predicatesToBeRemoved = drawableToOthers;
        if (isValidRef(connectionData) && isValidString(connectionData.subElementId))
            predicatesToBeRemoved = drawableToOthers.filter(hasSameOrigin);

        predicatesToBeRemoved.forEach(predicate => actionSaver.addRemoveActionToElementList(predicate))
        return actionSaver.getChangedNodes()
    }

    public getAllAvailableToolbarElementsForSelectedGraphElement(graphElementList: GraphElement[]): IBasicToolbarElement[] {
        return arrayUnique(
            flat(graphElementList.filter(g => !g.isExternalElementOnDiagram()).map((graphElement) => {
                const refObjType: TReferencedObject = graphElement.getHostedType();
                const element: IBasicToolbarElement = this.getOneToolbarElement(refObjType);
                const { canConnectTo } = element;
                const allowedConnections = keys(canConnectTo).map(key => this.getOneToolbarElement(key));
                const allowedDrags = BPMConfigToolbarSelector.getConfigToolbarElements(this.bpmType).filter((item) => {
                    return (
                        (isValidArray(item.canDragOn) && item.canDragOn.includes(refObjType))
                    );
                });

                return [...allowedConnections, ...allowedDrags];
            }))
        );
    }

    public getAvailableToolbarElement(from: TReferencedObject, to: TReferencedObject): IToolbarElement[] {
        const targetElement: IBasicToolbarElement = this.getOneToolbarElement(from)
        const referencedObjects =
            isValidRef(targetElement) &&
                isValidRef(targetElement.canConnectTo) &&
                isValidRef(targetElement.canConnectTo[to])
                ? targetElement.canConnectTo[to]
                : [];

        return referencedObjects.map(refObj => this.getOneToolbarElement(refObj) as IToolbarElement);
    }

    public getDiagramToolbar(mode: EBPMElementMode): IBasicToolbarElement[] {
        return this.getAllToolbarElements().filter((element) => {
            return element.mode === mode;
        });
    }

    public async canRemoveGraphNode(graphElement: GraphElement): Promise<ICanRemoveReturn> {
        const isExternalElement = graphElement.isExternalElementOnDiagram()
        const noConnectionsFromMeToOthers = graphElement.getDrawableConnectionsToOthers().length == 0
        const numOfConnectionsFromNeighboursToMe = graphElement.getConnectionsFromOthers().length
        const canRemoveNode: IHostNodeDeletionResult = graphElement.getHostObject().canRemoveNode({
            numOfConnectionsFromNeighboursToMe,
            isExternalElement
        })
        if (!canRemoveNode.allowed) {
            return {
                allowed: false,
                errorMessage: canRemoveNode.explanationIfNotAllowed
            };
        }

        if (isExternalElement) {
            return { allowed: true };
        }

        const canDelete = noConnectionsFromMeToOthers
        if (!canDelete) {
            return {
                allowed: false,
                errorMessage: 'Uma das dependências não pode ser automaticamente removida, por favor remova sua referência manualmente'
            };
        }

        return {
            allowed: true
        }
    }

    public getCustomToolbarElement(type: TReferencedObject, renderData: TRenderData): IToolbarElement {
        const element: IBasicToolbarElement = this.getOneToolbarElement(type)
        return {
            ...typedClone(element),
            renderData,
        };
    }

    public handleAssetClick(
        graphElement: GraphElement,
        asset: IInternalElementRenderData
    ): void {
        console.log('internalElementsGroupClick: ', { graphElement, asset });

        graphElement.emit(ECommonEvents.internalElementsGroupClick, asset);
    }

    public handleElementEditClick(graphElement: GraphElement): void {
        graphElement.emit(ECommonEvents.editClick, undefined);
    }

    public hasRoot(): boolean {
        return isValidRef(this.graphProcessor.getRootElement());
    }

    public isAnExternalElement(graph: GraphElement): boolean {
        return graph.isExternalElementOnDiagram();
    }

    private get diagramComponentInstance(): IVisualBPMComponentCallback {
        return this.graphProcessor.getVisualComponentCallback()
    }

    private async createRenderDataAndChildrenNodes( // From source to connectionData.target
        sourceNode: GraphElement,
        connectionData: IGraphConnectionData
    ): Promise<TGraphElementActionDescriptorList> {
        let newElementRenderData: IGraphElementRenderData
        const nodeModelOfSource = this.diagramComponentInstance.findNodeModelById(sourceNode.getGraphElementID());
        const parentNodeElementExistsOnGraph: boolean = isValidRef(nodeModelOfSource)

        if (parentNodeElementExistsOnGraph) {
            const toolbarElementFromConstants: IToolbarElement = this.getCustomToolbarElement(
                connectionData.connectionType,
                this.diagramComponentInstance.getElementCoordinatesToRenderData(nodeModelOfSource)
            );
            const safeDropArea = this.diagramComponentInstance.findDropAreaFrom(nodeModelOfSource);
            newElementRenderData = {
                ...toolbarElementFromConstants.renderData,
                ...safeDropArea
            };
        } else {
            const rootRenderData: IGraphElementRenderData = sourceNode.getRenderData()
            newElementRenderData = findOffsetsFor(rootRenderData, [])
        }

        const ns: INonSerializable = await this.params.nserLookupService.getSingleLookupElement(connectionData.targetHostId);

        if (isInvalid(ns)) return [];

        const elementsToSave = await this.createChildrenNodesAndConnections(
            sourceNode,
            ns,
            newElementRenderData,
            connectionData
        )
        return elementsToSave
    }

    async createChildrenNodesAndConnections(
        targetElement: GraphElement,
        draggedElementNS: INonSerializable,
        renderData: TRenderData,
        targetConnectionData: IGraphConnectionData,
    ): Promise<TGraphElementActionDescriptorList> {
        const actionSaver = GraphElementActionSaver.create();
        let draggedGraphElement: GraphElement = this.getGraphRulesProcessor().findNodeElementByHostedId(draggedElementNS.idNS);
        let shouldCreateNewChildNode = false
        const hosted: NSGenericHost = HostNodeFactory.create({ bpmType: this.bpmType, ns: draggedElementNS })

        if (hosted?.isNodeCreatable()) {
            shouldCreateNewChildNode = isInvalid(draggedGraphElement)
            if (shouldCreateNewChildNode) {
                draggedGraphElement = await this.createNewGraphNode({ draggedElementNS, renderData, isExternalElement: targetElement.isExternalElementOnDiagram() })
                actionSaver.addSaveActionToElementList(draggedGraphElement)
            }

            const updatedElements = this.upsertConnections(targetElement, draggedGraphElement, targetConnectionData)
            actionSaver.concatChangedNodes(updatedElements)

            if (draggedGraphElement.isExternalElementOnDiagram()) {
                const externalChanges = await this.getExternalChildrenNodes(draggedGraphElement, targetConnectionData);
                actionSaver.concatChangedNodes(externalChanges);
            }
        }

        // chamada recursiva pro caso de um novo node precisar criar mais 2 filhos ex: menu-container -> menu-item -> content-generator
        if (shouldCreateNewChildNode && draggedGraphElement.thereIsMoreChildrenNodesToCreate()) {
            const newChildrenNodes = await this.createAndConnectToNewNodeByAction(draggedGraphElement)
            actionSaver.concatChangedNodes(newChildrenNodes)
        }

        return actionSaver.getChangedNodes()
    }

    public async getExternalChildrenNodes(node: GraphElement, targetConnectionData: IGraphConnectionData): Promise<TGraphElementActionDescriptorList> {
        const idNS = node.getHostedID();
        const [item] = await this.params.bpmBotApiService.getAllPrintableExternalElements(this.bpmType, [idNS]);

        if (!isValidRef(item)) {
            return [];
        }

        const alreadyOnGraph: GraphElement[] = item.linkedTo
            .map(ns => {
                const node = this.graphProcessor.findNodeElementByHostedId(ns.idNS)
                return node
            })
            .filter(el => isValidRef(el) && [EGraphElementType.node, EGraphElementType.root].includes(el.getElementType()))
        const toCreate = item.linkedTo
            .filter(ns => !this.graphProcessor.findNodeElementByHostedId(ns.idNS))
        const actionSaver = GraphElementActionSaver.create()
        alreadyOnGraph.forEach((el: GraphElement) => {
            const connectionData: IGraphConnectionData = {
                connectionType: EAdditionalPredicateType.Parent,
                targetHostId: el.getHostedID(),
                subElementId: undefined,
            };
            const predicate = node.connectToOtherExistingNode(el, connectionData);
            console.log({ predicate });
            actionSaver.addSaveActionToElementList(predicate);
        });

        const allGraphsJSON: IGraphElementJSON[] = this.graphProcessor.getAllNodes().map(n => n.toJSON());
        toCreate.forEach(async ns => {
            const newNode = await this.createNewGraphNode({
                draggedElementNS: ns,
                renderData: {
                    ...graphElementContainerSize,
                    ...findOffsetsFor(node.getRenderData(), allGraphsJSON),
                    id: ns.idNS,
                }
            });
            const connectionData: IGraphConnectionData = {
                connectionType: EAdditionalPredicateType.Parent,
                targetHostId: ns.idNS,
                subElementId: undefined,
            };
            const predicate = node.connectToOtherExistingNode(newNode, connectionData);
            actionSaver.addSaveActionToElementList([newNode, predicate]);
            allGraphsJSON.push(newNode.toJSON());
        });

        return actionSaver.getChangedNodes();
    }

    updateAllNodesHostedObjects(nss: TINonSerializableArray) {
        nss.forEach(ns => {
            const graphElement: GraphElement = this.graphProcessor.findNodeElementByHostedId(ns.idNS);

            if (isValidRef(graphElement)) {
                const actualObj: INonSerializable = graphElement.getHostedObject();

                if (graphElement.isExternalElementOnDiagram()) {
                    objectShallowReplace(actualObj, ns);
                } else {
                    actualObj.lastTouch = ns.lastTouch;
                }
            }
        });

    }

    public getNodeRenderType(graphElement?: GraphElement): ENodeRenderType {
        return ENodeRenderType.standard;
    }

    public async checkElementIsBPMRoot(idHostedObject: TNserUID): Promise<boolean> {
        const response = await this.params.bpmBotApiService.isRootOfAnotherBPM(idHostedObject);
        return response;
    }

    public openUpserterComponent(upserterData: IUpserterComponentReturn): Promise<IBPMDialogResult> {
        const dialogparams = {
            componentToOpen: upserterData.component,
            componentHandler: upserterData.handler,
        }
        console.log({dialogparams});
        
        return this.diagramEditorDialogSvc.openDialog(dialogparams);
    }
}
