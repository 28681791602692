import { MatMenuTrigger, MenuPositionX, MenuPositionY } from '@angular/material/menu';
import { secToMS } from '@colmeia/core/src/time/time-utl';
import { first, timeout } from 'rxjs/operators';
import { SnackMessageService } from "../../../services/snack-bar";
import { isValidRef } from "@colmeia/core/src/tools/utility";
import { Serializable } from '@colmeia/core/src/business/serializable';
import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { InterfaceInfoSignal } from "../../../model/signal/interface-signal";
import { HardwareLayerService } from "../../../services/hardware";
import { SignalListenerService } from "../../../services/signal/signal-listener";
import { IChangeInterfaceListener } from "../../../model/signal/ps-interfaces";
import { SocketService } from './../../../services/socket.service';
import {
    MoreOptionsThreeDotsHandler, TMoreOptionsThreeDotsOption,
    TMoreOptionsThreeDotsOptionList
} from "../../../handlers/more-options-three-dots.handler";

@Component({
  selector: 'app-message-side-options',
  templateUrl: './message-side-options.component.html',
  styleUrl: './message-side-options.component.scss'
})
export class MessageSideOptionsComponent implements OnInit, OnDestroy, IChangeInterfaceListener {
    @Input() handler: MoreOptionsThreeDotsHandler;

    // @Input() isWhiteMoreOptions: boolean = false;
    // @Input() isGrayMoreOptions: boolean = false;

    // @Input()
    // xPosition: MenuPositionX = "after";

    // @Input()
    // yPosition: MenuPositionY = "below";

    // @ViewChild("menuTrigger")
    // menuTrigger: MatMenuTrigger;

    // public waitingReconnect: boolean = false;

    constructor(
        private hw: HardwareLayerService,
        private listener: SignalListenerService,
        private cdr: ChangeDetectorRef,
        // private socketSvc: SocketService
    ) {}

    ngOnInit() {
        this.listener.listenToInterfaceChanges(this);
    }

    ngOnDestroy(): void {
        this.listener.destroySubscriptions(this);
    }

    get hasNetwork(): boolean {
        return this.hw.getNetwork().isOnline();
    }

    receiveChangeInterfaceCallback(sign: InterfaceInfoSignal) {
        if(isValidRef((sign.getInterfaceSignal().isOffLine))) {
            this.cdr.markForCheck();
        }
    }

    getSideMenuOptions(): TMoreOptionsThreeDotsOptionList {
        // We only get the messageEdit as of now
        return [this.handler.getMenuOptions().find((option) => option.getPrimaryID() === 'chatMessage.edit')]
    }

    onOptionClicked(option: TMoreOptionsThreeDotsOption) {
        this.handler.optionSelected(option)
    }

    isValid(item: Serializable): boolean {
        return isValidRef(item);
    }

    canShowEmoji(item: Serializable): boolean {
        return isValidRef(item.getEmoji())
    }

    // trackByFn(_, item: Serializable) {
    //     return item.getName();
    // }
    // it's breaking for some reason and I don't even know what it does

    // onClick(): void {
    //     if (this.hw.getNetwork().isOffline()) {
    //         this.menuTrigger.closeMenu();
    //         this.waitingReconnect = true;
    //         this.socketSvc.socketReconnect$.pipe(first(), timeout(secToMS(10))).subscribe(() => {
    //             this.waitingReconnect = false;
    //             this.menuTrigger.openMenu();
    //         }, () => {
    //             this.waitingReconnect = false;
    //         });
    //     }
    //     else {
    //         this.menuTrigger.openMenu();
    //     }
    // }
}
