export interface IRandomizedColorParam {
    /**
     * Base value: the randomized parameter will never be lower than this value
     */
    base: number;
    /**
     * Randomized range: the parameter will be randomized from 0 to this value
     * e.g. if 15 is passed a random value between 0 and 15 will be generated
     */
    randomizedRange?: number;
}

export interface IGenerateRandomColorParams {
    saturation: IRandomizedColorParam;
    lightness: IRandomizedColorParam;
}

export function stringToColor(string: string, params: IGenerateRandomColorParams): string {
    const hash = sdbm(string)
    const h = hash % 360;
    const s = randomizeParameter(hash, params.saturation);
    const l = randomizeParameter(hash, params.lightness);

    return `hsl(${h},${s}%,${l}%)`;
}

function randomizeParameter(hash: number, param: IRandomizedColorParam): number {
    const randomizedRange = param.randomizedRange || 1;

    return param.base + (hash % randomizedRange)
}

/**
 * algoritmo de hashing SDBM (http://www.cse.yorku.ca/~oz/hash.html)
 */
function sdbm(string: string): number {
    let hash = 0;

    for (let i = 0; i < string.length; i++) {
        hash = string.charCodeAt(i) + (hash << 6) + (hash << 16) - hash;
    }

    return hash >>> 0;
}
