import { BBCodeParser } from "@affilicon/bbcode-parser";
import { EDelivery360Action } from "../../comm-interfaces/interaction-interfaces";
import { isValidRef, mapValues } from "../../tools/utility";
import { whatsAppMessageTemplate } from "../metadata/metadata-utils";
import { bbCodeTagPattern, getParsersDefinition } from "./bbcode-constants";
import { BBCodeDelivery360ActionParsers, EBBCodeStyles } from "./bbcode-types";
import { emptyStylesParser, initHTMLParser, initParsers, noopParser } from "./functions/bbcode-functions-composed";
import { defaultBBCodeParseStringOptions, htmlToBBCodeTagMap, IBBCodeParseStringOptions, parseString, regexHTMLToBBCodeKeysReplace } from "./functions/bbcode-functions-simple";


function raw(message: TemplateStringsArray | string, ...inputs: string[]) {
    if (typeof message === 'string') return [message, ...inputs].join('');
    return String.raw(message, ...inputs);
}


type TemplateStringInput = [message: TemplateStringsArray | string, ...inputs: string[]];
type DefineTemplateTags = { [key in keyof typeof EBBCodeStyles]: (...inputs: TemplateStringInput) => `[${typeof EBBCodeStyles[key]}]${string}[/${typeof EBBCodeStyles[key]}]`};


export class BBCode {
    private static parsers: BBCodeDelivery360ActionParsers = initParsers();
    private static htmlParser: BBCodeParser = initHTMLParser();

    static tags: DefineTemplateTags = mapValues(EBBCodeStyles, (item, key) => (...inputs: TemplateStringInput) => `[${item}]${raw(...inputs)}[/${item}]`);

    public static parseString(delivery: EDelivery360Action, content: string, options: IBBCodeParseStringOptions = defaultBBCodeParseStringOptions): string {
        const definiton = getParsersDefinition(delivery);
        if (definiton?.interceptor) {
            content = definiton.interceptor(content);
        }
        if (isValidRef(BBCode.parsers[delivery])) {
            return parseString(BBCode.parsers[delivery], content, options)
        }
        return content;
    }

    public static isValidText(text: string): boolean {
        const parsedText: string = parseString(emptyStylesParser, text);
        const hasTagsOnParsedText: boolean = bbCodeTagPattern.test(parsedText);
        return !hasTagsOnParsedText;
    }

    public static parseNoop(text: string, options: IBBCodeParseStringOptions = defaultBBCodeParseStringOptions): string {
        const parsedText: string = parseString(noopParser, text, options);
        return parsedText;
    }

    public static parseHTML(text: string, options: IBBCodeParseStringOptions = defaultBBCodeParseStringOptions): string {
        return parseString(BBCode.htmlParser, text);
    }

    public static parseWhatsApp<T extends string | undefined>(message: T): T
    public static parseWhatsApp(message: string | undefined): string | undefined {
        if (!message) return;
		const text: string = BBCode.parseString(EDelivery360Action.Delivery360WhatsApp, (message));
        return text;
    }

    public static parseWhatsAppTemplate(message: string | undefined, shouldPreserveOriginalVariables?: boolean) {
		const text: string | undefined = message && BBCode.parseWhatsApp(shouldPreserveOriginalVariables ? message: whatsAppMessageTemplate(message));
        return text;
    }

    public static htmlToBBCode(htmlMarkup: string): string {
        return htmlMarkup.replace(
            regexHTMLToBBCodeKeysReplace,
                (fullTag,tagName) => {
                    const htmlTag: string = htmlToBBCodeTagMap[tagName];
                    const isEnd: boolean = fullTag.includes('/');
                    return isEnd ? `[/${htmlTag}]` : `[${htmlTag}]`;
                }
            )
    }
}


