import { Component, OnInit, Inject, HostListener, Injector } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { IColmeiaDialogComponentData } from 'app/components/dialogs/dialog/dialog.component';
import { IChatFilterData, ChatFilter, filterletToken, IChatFilterParameters, feedbackAndInteractionPromiseToken, TUsedFeedbackAndInteractionPromise, IChatFilterEvents, chatFilterEventsToken, FilterMatchTranslations } from 'app/model/chat-filter.model';
import { RootComponent } from 'app/components/foundation/root/root.component';
import { ChatPeopleFilterComponent } from '../chat-people-filter/chat-people-filter.component';
import { ChatFeedbackFilterComponent } from '../chat-feedback-filter/chat-feedback-filter.component';
import { ChatInteractionTypeFilterComponent } from '../chat-interaction-type-filter/chat-interaction-type-filter.component';
import { ChatTaskFilterComponent } from '../chat-task-filter/chat-task-filter.component';
import { ChatDateFilterComponent } from '../chat-date-filter/chat-date-filter.component';
import { EDynamicMatch, IGetUsedFeedbackAndTypeLookupResponse } from '@colmeia/core/src/rules/dynamic-filter/dynamic-filter-interfaces';
import { constant } from '@colmeia/core/src/business/constant';
import { LookupService } from 'app/services/lookup.service';
import { ELookupType } from '@colmeia/core/src/request-interfaces/lookup-request';
import { Subject } from 'rxjs';
import { gTranslations } from '@colmeia/core/src/shared-business-rules/const-text/translations';

interface IFilter {
	component: any;
	expanded: boolean;
}

@Component({
    selector: 'app-chat-filter-modal',
    templateUrl: './chat-filter-modal.component.html',
    styleUrls: ['./chat-filter-modal.component.scss']
})
export class ChatFilterModalComponent extends RootComponent<
	EDynamicMatch.first |
	EDynamicMatch.last |
	EDynamicMatch.any |
	'people' |
	'reactions' |
	'messages' |
	'tasks' |
	'interval'
> {

    isDesktop: boolean = true;
    
	data: IChatFilterData;
	
	filters: ChatFilter[];

	chatFilterInjector: Injector;

	public matchNames: Array<string>;

	private events: IChatFilterEvents = {
		applied: new Subject()
	}

    constructor(
		@Inject(MAT_DIALOG_DATA) public dataToComponent: IColmeiaDialogComponentData<IChatFilterData>,
		private injector: Injector,
		private matDialogSvc: MatDialog,
		private lookupSvc: LookupService,
    ) { 
        super({
			...FilterMatchTranslations,
			people: gTranslations.common.people, 
			interval: gTranslations.common.interval, 
			reactions: gTranslations.common.reactions,
			messages: gTranslations.chat.messages,
			tasks: gTranslations.chat.tasks,
		});
		
        this.data = dataToComponent.getParamsToChildComponent();
    }

    ngOnInit() {
		this.checkWindowSize();

		const defaultFilterInfo: IChatFilterParameters[] = [{
			component: ChatPeopleFilterComponent,
			iconFile: 'chat-filter-people.svg',
			name: this.translations.people.value
		}, {
			component: ChatFeedbackFilterComponent,
			iconFile: 'chat-filter-feedback.svg',
			name: this.translations.reactions.value
		}, {
			component: ChatInteractionTypeFilterComponent,
			iconFile: 'chat-filter-interaction-type.svg',
			name: this.translations.messages.value
		}, {
			component: ChatTaskFilterComponent,
			iconFile: 'chat-filter-task.svg',
			name: this.translations.tasks.value
		}];

		if (this.data.canShowInterval) {
			defaultFilterInfo.push({
				component: ChatDateFilterComponent,
				iconFile: 'chat-filter-date.svg',
				name: this.translations.interval.value
			});
		}

		const allFilters = [...defaultFilterInfo];

		this.filters = allFilters.map(filterInfo => new ChatFilter(filterInfo));


		this.chatFilterInjector = Injector.create([{
			provide: filterletToken,
			useValue: this.data.filterlet
		}, {
			provide: feedbackAndInteractionPromiseToken,
			useValue: this.getUsedFeedbackAndInterationTypes() 
		}, {
			provide: chatFilterEventsToken,
			useValue: this.events,
		}], this.injector);
		
		this.matchNames = Object.values(EDynamicMatch)
    }

	async getUsedFeedbackAndInterationTypes(): Promise<IGetUsedFeedbackAndTypeLookupResponse> {
		return await this.lookupSvc.getLookup(ELookupType.getUsedFeedbackAndInteraction) as IGetUsedFeedbackAndTypeLookupResponse;
	}

	getComponent(chatFilter: ChatFilter): any {
		return chatFilter.component;
	}

	@HostListener('window:resize')
	checkWindowSize() {
		this.isDesktop = window.innerWidth > 600;
	}



	
    applyFilters() {
		this.events.applied.next()
		this.matDialogSvc.closeAll()


		if (typeof this.data.onFilterApply === 'function')
			this.data.onFilterApply(this.data.filterlet)
    }

    invertChange(event: MatSlideToggleChange) {
        this.data.filterlet.positiveMatch = !event.checked;
	}
	
}